import styled from 'styled-components';

const StyledNoResults = styled.div`
  text-align: center;
`;

type NoResultsProps = {
  className?: string;
  title?: string;
  description?: string;
  isDescriptionEnabled?: boolean;
};

export const NoResults = ({ title, isDescriptionEnabled, description, className }: NoResultsProps) => (
  <StyledNoResults className={className}>
    <h4>{title}</h4>
    {isDescriptionEnabled && description && <span dangerouslySetInnerHTML={{ __html: description }} />}
  </StyledNoResults>
);

NoResults.defaultProps = {
  title: 'Sorry, no results',
  description: 'If you have applied filters, try removing some to widen your search.',
  isDescriptionEnabled: true,
};
