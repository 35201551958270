import { useEffect, useState } from 'react';

import { TpiUserActionsPanelWrapper } from './TpiUserActionsPanel.styles';
import { ButtonPrimary } from '../../common/components/ButtonPrimary/ButtonPrimary';
import moment from 'moment';

type TpiUserActionsPanelProps = {
  selectedTpi: Common.IExpandedTpiUserOption;
  gridData: Array<Api.ITpiUser>;
  checkedItems: Array<number>;
  selectedRow: number | null;
  handleAddUser: Function;
  handleDisable: Function;
  handleEnable: Function;
  handleGeneratePasswordReset: Function;
};

const defaultProps = {
  selectedTpi: {},
  gridData: [],
  checkedItems: [],
  selectedRow: null,
  handleAddUser: undefined,
  handleDisable: undefined,
  handleEnable: undefined,
  handleGeneratePasswordReset: undefined,
};

const TpiUserActionsPanel = (props: TpiUserActionsPanelProps) => {
  const [generatePasswordResetBtnEnabled, setGeneratePasswordResetBtnEnabled] = useState<boolean>(false);
  const [enableBtnEnabled, setEnabledBtnEnabled] = useState<boolean>(false);
  const [disableBtnEnabled, setDisableBtnEnabled] = useState<boolean>(false);

  const setButtons = (item: Api.ITpiUser) => {
    if (item.status === 'Enabled') {
      setDisableBtnEnabled(true);
    }

    const isLocked: boolean = item.lockoutEnd != null && moment.utc(item.lockoutEnd).isAfter(moment.utc());

    if (props.checkedItems.length <= 1) {
      if (item.status === 'Enabled' || item.status === 'Invited') {
        setGeneratePasswordResetBtnEnabled(true);
      }
    }

    if (item.status === 'Invited') {
      setDisableBtnEnabled(true);
    }

    if (item.status === 'Disabled' || isLocked) {
      setEnabledBtnEnabled(true);
    }

    if (isLocked && item.status !== 'Disabled') {
      setDisableBtnEnabled(true);
    }
  };

  useEffect(() => {
    setGeneratePasswordResetBtnEnabled(false);
    setEnabledBtnEnabled(false);
    setDisableBtnEnabled(false);

    if (props.selectedTpi?.enabled) {
      if (props.gridData.length > 0) {
        if (props.selectedRow != null) {
          setButtons(props.gridData[props.selectedRow]);
        }

        props.checkedItems.map((index: number) => {
          setButtons(props.gridData[index]);
        });
      }
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTpi?.enabled, props.checkedItems, props.selectedRow]);

  const handleAddUser = (event: any) => {
    props.handleAddUser(event);
  };

  const handleGeneratePasswordReset = (item: any) => {
    props.handleGeneratePasswordReset(item);
  };

  const handleDisable = (event: any) => {
    props.handleDisable(event);
  };

  const handleEnable = (event: any) => {
    props.handleEnable(event);
  };

  return (
    <>
      <TpiUserActionsPanelWrapper className='d-flex justify-content-end grid-settings'>
        <ButtonPrimary
          title={'Add User'}
          disabled={!props.selectedTpi?.enabled}
          className='mb-4 mt-0'
          onClick={event => {
            handleAddUser(event);
            event.preventDefault();
          }}
        />
        <ButtonPrimary
          title={'Generate password reset'}
          disabled={!generatePasswordResetBtnEnabled}
          className='mb-4 mt-0'
          onClick={event => {
            if (props.selectedRow != null) {
              const item = props.gridData[props.selectedRow];
              handleGeneratePasswordReset(item);
            } else if (props.checkedItems.length === 1) {
              const item = props.gridData[props.checkedItems[0]];
              handleGeneratePasswordReset(item);
            }
            event.preventDefault();
          }}
        />
        <ButtonPrimary
          title={'Enable'}
          disabled={!enableBtnEnabled}
          className='mb-4 mt-0'
          onClick={event => {
            handleEnable(event);
            event.preventDefault();
          }}
        />
        <ButtonPrimary
          title={'Disable'}
          disabled={!disableBtnEnabled}
          className='mb-4 mt-0'
          onClick={event => {
            handleDisable(event);
            event.preventDefault();
          }}
        />
      </TpiUserActionsPanelWrapper>
    </>
  );
};

TpiUserActionsPanel.defaultProps = defaultProps;

export default TpiUserActionsPanel;
