import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { SuccessSubmitMessage } from './SuccessSubmitMessage';

import { ButtonPrimary, ModalClose } from '../../../../common/components';

type SuccessSubmitModalProps = {
  onClose: () => void;
};

export const ContentWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.text.secondary};
    text-decoration: none;
  }
`;

export const SuccessSubmitModal = (props: SuccessSubmitModalProps) => {
  const { onClose } = props;

  return (
    <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Meter read validation successful</Modal.Title>
        <ModalClose onClick={onClose} />
      </Modal.Header>

      <Modal.Body className='pl-lg-4'>
        <ContentWrapper>{SuccessSubmitMessage}</ContentWrapper>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={onClose} className={'mr-0'} title='Okay' route='#' />
      </Modal.Footer>
    </Modal>
  );
};
