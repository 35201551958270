import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { customerUserProfile, getTpiAccessId } from '../../common/api/homeApi';
import { TPI_ACCESSID, TPI_CUSTOMERS } from '../../common/constants';
import { CUSTOMER_TYPES } from '../../consts';

import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { temporary } from '../../helpers/axiosWrapper';
import { useAuthState } from '../../providers/authProvider';
import BrokerLandingPage from './BrokerLandingPage';

const BrokerLandingPageIndex = () => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [customers, setCustomers] = useState<Array<Common.IExpandedCustomerOption>>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Array<Common.IExpandedCustomerOption>>([]);
  const authContext = useAuthState();

  useEffect(() => {
    setCustomers(userDetail.customers);
    setSelectedCustomers(userDetail.selectedCustomers);
  }, [userDetail.customers, userDetail.selectedCustomers]);

  const handleViewDetails = (customer: Common.IExpandedCustomerOption) => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      showBrokerLandingPage: false,
    });

    // log for 35984
    console.log('Brokerlandingpage handleViewDetails');

    // set to customer
    setUserDetail({
      ...userDetail,
      essCustomerId: customer.value,
      hasRenewable: customer.hasRenewable,
      hasElectricity: customer.hasElectricity,
      hasGas: customer.hasGas,
      viewAsCustomerSelected: true,
      viewAsCustomerToggle: true,
      selectedCustomers: [],
    });
  };

  const handleViewCustomers = async (customers: Array<Common.IExpandedCustomerOption>) => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      showBrokerLandingPage: false,
    });

    const customersList: Array<string> = customers.map(customer => {
      return customer.value;
    });

    temporary.accessId = '';
    const tpiAccessId = await getTpiAccessId(process.env.REACT_APP_API_URL, authContext, customersList);

    let tpiAccess: any = {};

    if (tpiAccessId) {
      temporary.accessId = tpiAccessId['access-id'];

      localStorage.setItem(TPI_CUSTOMERS, JSON.stringify(customers));
      
      localStorage.setItem(TPI_ACCESSID, temporary.accessId);

      tpiAccess = {
        accessId: tpiAccessId['access-id'],
        username: tpiAccessId.username,
        userId: tpiAccessId.userId,
        emailAddress: tpiAccessId.emailAddress,
      };
    } else {
      toast.error('CANNOT GET ACCESS ID', { position: 'bottom-right' });
      return;
    }

    // using first customer selected
    const customer = customers[0];

    // log for 35984
    console.log('brokerlandingpage handleViewCustomers');

    let customerAccounts: Api.IEssAccountData[] = [];
    customers.map((customer: Common.IExpandedCustomerOption) => {
      let customerAccount: Api.IEssAccountData = {
        essAccountID: customer.value,
        accountName: customer.display,
        product: 'Electricity',
        renewable: false,
      };
      customerAccounts.push(customerAccount);
    });

    let customerIds: Array<Api.ICustomer> = [];
    customers.map((customer: Common.IExpandedCustomerOption) => {
      const customerId: Api.ICustomer = {
        customerId: customer.value,
        description: customer.display,
      };
      customerIds.push(customerId);
    });

    const userProfile: Api.IUserProfile = {
      id: null,
      accountManager: 'Portal Support',
      email: 'myenergysupply@drax.com',
      phone: '01473 707797',
      description: '',
      numberOfCustomers: customerAccounts.length,
      customerIds,
    };

    setUserDetail({
      ...userDetail,
      customerType: CUSTOMER_TYPES.CORPORATE,
      essCustomerId: customer.value,
      hasRenewable: false,
      hasElectricity: true,
      hasGas: false,
      viewAsCustomerSelected: true,
      viewAsCustomerToggle: true,
      selectedCustomers: customers,
      selectedCustomerId: customer.value,
      loggedInDetails: {
        essCustomerId: customer.value,
        hasRenewable: true,
        hasElectricity: false,
        hasGas: false,
      },
      customerAccounts,
      forename: tpiAccess.username,
      lastName: tpiAccess.username,
      userId: tpiAccess.userId,
      emailAddress: tpiAccess.emailAddress,
      // isPrimaryContact
      // isAccountSignatory
      userProfile,
    });
  };

  return (
    <>
      {globalContext.globalDetail.showBrokerLandingPage && (
        <BrokerLandingPage
          contactEmail={'myenergysupply@drax.com'}
          contactPhone={'01473 707797'}
          selectedCustomerId={userDetail.essCustomerId}
          customers={customers}
          selectedCustomers={selectedCustomers}
          handleViewDetails={handleViewDetails}
          handleViewCustomers={handleViewCustomers}
        />
      )}
    </>
  );
};
export default BrokerLandingPageIndex;
