import { ReactElement } from 'react';

import { SelectedAccounts } from './SelectedAccounts';
import { BillingForm } from './BillingForm';

type DDFillFormProps = {
  stepsBar: ReactElement;
};

export const DDFillForm = ({ stepsBar }: DDFillFormProps) => (
  <>
    {stepsBar}
    <SelectedAccounts />
    <BillingForm />
  </>
);
