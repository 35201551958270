import { IDENTITY_CONFIG, METADATA_OIDC } from '../utils/authConst';
import { UserManager, WebStorageStateStore, Log, User } from 'oidc-client';
// import { setAuthHeader } from "../helpers/axiosWrapper";

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    // Logger
    Log.logger = console;
    Log.level = Log.NONE;
    this.UserManager.events.addUserLoaded(user => {
      // Update Token
      localStorage.setItem('id_token', user.id_token);

      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError(e => {
      console.log('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpiring(() => {
      console.log('addAccessTokenExpiring:' + Date.now);
      if (!window.location.href.endsWith('/expiring')) window.location.replace('/expiring');
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.logout();
    });
  }

  signinRedirectCallback = async () => {
    try {
      await this.UserManager.signinRedirectCallback().then(() => {
        ('');
      });
    } catch (error: any) {
      // Throws error if Login page has been bookmarked.
      // Need to redirect back to home page.
      await this.UserManager.signinRedirect();
    }
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  getClaims = () => {
    const token = this.getToken();
    var jwt = this.parseJwt(token);

    const email = jwt.email;
    const userId = jwt.sub;
    const tpiReferenceId = jwt.tpiReferenceId;

    return {
      email,
      userId,
      tpiReferenceId,
    };
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    window.location.replace('/home');
  };

  isAuthenticated = () => {
    const tokenStorageName = `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`;
    const token = sessionStorage.getItem(tokenStorageName);
    if (token) {
      const oidcStorage = JSON.parse(token);
      return !!oidcStorage && !!oidcStorage.access_token;
    }
    return false;
  };

  getToken = () => {
    const tokenStorageName = `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`;
    const token = sessionStorage.getItem(tokenStorageName);

    if (token) {
      const oidcStorage = JSON.parse(token);
      if (oidcStorage) return oidcStorage.access_token;
    }
    return '';
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        console.log('signed in', user);
      })
      .catch(err => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      const publicURL = process.env.REACT_APP_PUBLIC_URL;
      if (!publicURL) {
        throw new Error('REACT_APP_PUBLIC_URL not setup correctly');
      }
      window.location.replace(publicURL);
    });
    this.UserManager.clearStaleState();
  };

  refreshToken = () => {
    this.UserManager.signinSilentCallback()
      .then(() => {
        this.UserManager.signinSilent();
        this.UserManager.getUser().then((user: User | null) => {
          if (user) {
            // TODO - Remove after successful testing
            console.log('access_token: ' + user.access_token);
            console.log('expires_at: ' + user.expires_at);
            console.log('id_token: ' + user.id_token);

            // Update Token
            localStorage.setItem('id_token', user.id_token);
          }
        });
      })
      .catch((reason: any) => {
        console.error('Handle renew callback failed: ' + reason);
      });
  };
}
