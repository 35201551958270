import styled from 'styled-components';

export const DownloadFileFormatContainer = styled.div`
  min-width: 412px;

  .select__text {
    text-align: center;
    font-size: 28px;
  }

  a {
    min-width: 155px;
  }
`;
