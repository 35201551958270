import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonPrimary, ModalClose } from '../../../common/components';
import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

import * as colors from '../../../common/styles/colors';
import { sortAccountsByDisplay } from '../../../utils/sortFilters';

type HealthCheckModalProps = {
  onHide: Function;
  show: boolean;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  messagevalues: {},
};

export const HealthCheckModalWrapper = styled.div`
  .link {
    &:link {
      text-decoration: none;
    }
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
    color: ${colors.hydroBlue};
  }
  .accountNameNoWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const HealthCheckModal = (props: HealthCheckModalProps) => {
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const history = useHistory();

  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  let uniqueAccounts: Array<string> = [];

  let customersList: Array<Common.IOption> = [];

  const valueName =
    props.messagevalues.callToAction === 'Smart meter consent'
      ? 'customerAccountReference'
      : 'customer-account-reference';

  if (props.messagevalues && props.messagevalues.json) {
    uniqueAccounts = Array.from(new Set(props.messagevalues.json.map((item: any) => item[valueName])));

    uniqueAccounts.map((account: string, index: number) => {
      const customer = userDetail.customerAccounts.find(c => c.essAccountID === account);
      const cust: Common.IOption = {
        value: account,
        display: customer ? customer.accountName : '',
      };

      customersList.push(cust);
    });

    customersList = sortAccountsByDisplay(customersList);
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.messagevalues.route) {
      history.push(props.messagevalues.route);
    }
  };

  const onAccountClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const accountId: string = event.currentTarget.innerHTML;

    searchContext.setSearchDetail({
      ...searchContext.searchDetail,
      type: 'account',
      accountId: accountId,
      routingTo: props.messagevalues.routingTo,
      searchText: accountId,
    });
    setUserDetail({
      ...userDetail,
      selectedCustomerAccount: accountId,
    });

    if (props.messagevalues.route) {
      history.push(props.messagevalues.route);
    }
  };

  return (
    <HealthCheckModalWrapper>
      <Modal.Header className='pt-0'>
        <Modal.Title>{props.messagevalues.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        {customersList.map((customer: Common.IOption, index: number) => {
          return (
            <div key={index}>
              <div className='container'>
                <div className='row'>
                  <div className='accountNameNoWrap'>
                    <Link className='link' to={'/'} onClick={onAccountClick}>
                      {customer.value}
                    </Link>
                    <span className='mx-1'>-</span>
                    {customer.display}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          route='/'
          className='m-0'
          title={props.messagevalues.callToAction}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            handleClick(event);
            props.onHide();
          }}
        ></ButtonPrimary>
      </Modal.Footer>
    </HealthCheckModalWrapper>
  );
};

HealthCheckModal.defaultProps = defaultProps;
