import Modal from 'react-bootstrap/Modal';
import { isMobile } from 'react-device-detect';
import { Calendar, DateObject } from 'react-multi-date-picker';

import { CalendarWrapper } from '../SubmitMeterReadingModals.styles';

type DatepickerModalModalProps = {
  onSelect: (selectedDate: DateObject | DateObject[]) => void;
  range?: boolean;
  onHide?: () => void;
  value?: string | DateObject | DateObject[];
};
const defaultProps = {
  range: false,
};

export const DatepickerModal = (props: DatepickerModalModalProps) => {
  // Props
  const { onHide, value } = props;

  return (
    <>
      <Modal
        centered
        show={true}
        size={'lg'}
        animation={false}
        className={'calendar-modal'}
        onHide={onHide}
        backdrop={!!onHide || 'static'}
      >
        <Modal.Body>
          <CalendarWrapper>
            <Calendar
              range={props.range}
              value={value || new Date()}
              minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
              maxDate={new Date()}
              numberOfMonths={isMobile ? 1 : 2}
              onChange={props.onSelect}
            >
              <span
                className='closeDatePicker'
                onClick={() => {
                  onHide && onHide();
                }}
              />
            </Calendar>
          </CalendarWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
};

DatepickerModal.defaultProps = defaultProps;
