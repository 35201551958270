import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuthState } from '../../../providers/authProvider';
import { getPrimaryContactDetails, updatePrimaryContactDetails, markContactUpdated } from '../../../common/api/homeApi';
import { PrimaryContactModal } from '../PrimaryContactModal/PrimaryContactModal';
import { ContactDetails } from '../PrimaryContactModal/ContactDetails';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { isOpusBrand } from '../../../utils/common';

export const PrimaryContactModalProcess = () => {
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [primaryContactModalOpened, setPrimaryContactModalOpened] = useState(false);
  const [contactDetails, setContactDetails] = useState<ContactDetails | undefined>();
  const authContext = useAuthState();

  const getContactDetails = async () => {
    const result: Common.IResult<Api.IPrimaryContactDetails> = await getPrimaryContactDetails(
      process.env.REACT_APP_API_URL,
      authContext
    );

    if (result?.success && result?.data) {
      // log for 35984
      console.log('GetContactDetails');

      setUserDetail({
        ...userDetail,
        isPrimaryContact: result?.data.isPrimaryContact,
      });

      const { renderPopup, essCustomerID, ...details } = result?.data;

      if (details.isPrimaryContact) {
        setContactDetails({
          ...details,
          title: {
            value: details.title,
            label: details.title,
          },
        });
      }

      if (renderPopup) {
        setPrimaryContactModalOpened(true);
      }
    }
  };

  useEffect(() => {
    if (isOpusBrand) {
      getContactDetails();
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: ContactDetails) => {
    const result = await updatePrimaryContactDetails(process.env.REACT_APP_API_URL, authContext, data);

    if (result?.data.success) {
      await markContactUpdated(process.env.REACT_APP_API_URL, authContext);
    } else {
      const message = 'API error calling: primarycontact';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }

    setPrimaryContactModalOpened(false);
  };

  return (
    <PrimaryContactModal
      show={primaryContactModalOpened}
      contactDetails={contactDetails}
      updateMode={!!contactDetails}
      onSubmit={onSubmit}
    />
  );
};
