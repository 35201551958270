const User = ({ width, height, className, fillColor, style }: UserProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 28 28'
    fill='none'
    style={style}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='14' cy='14' r='14' fill='#dde4e9' />
    <g clipPath='url(#clip0)'>
      <rect width='10.8182' height='11.4545' transform='translate(8.27274 8.27271)' fill='#DDE4E9' />
      <path
        d='M13.6818 14.5727C11.9566 14.5727 10.5502 13.1581 10.5502 11.4227C10.5502 9.68734 11.9566 8.27271 13.6818 8.27271C15.407 8.27271 16.8134 9.68734 16.8134 11.4227C16.8134 13.1581 15.407 14.5727 13.6818 14.5727ZM13.6818 8.84543C12.2698 8.84543 11.1196 10.0023 11.1196 11.4227C11.1196 12.8431 12.2698 14 13.6818 14C15.0939 14 16.244 12.8431 16.244 11.4227C16.244 10.0023 15.0939 8.84543 13.6818 8.84543Z'
        fill='#2A3745'
      />
      <path
        d='M18.2369 19.7272H9.1268C8.65422 19.7272 8.27274 19.3435 8.27274 18.8681C8.27274 18.828 8.27843 17.9059 8.96738 16.9781C9.37164 16.4398 9.91824 16.0102 10.6015 15.7066C11.4328 15.3344 12.4691 15.1454 13.6818 15.1454C14.8889 15.1454 15.9252 15.3344 16.7622 15.7066C17.4454 16.0102 17.992 16.4398 18.3963 16.9781C19.0852 17.9059 19.0909 18.828 19.0909 18.8681C19.0909 19.3435 18.7094 19.7272 18.2369 19.7272ZM13.6818 15.7181C11.6947 15.7181 10.2314 16.2622 9.44565 17.2988C8.8535 18.072 8.84211 18.8624 8.84211 18.8738C8.84211 19.0285 8.96738 19.1602 9.1268 19.1602H18.2369C18.3963 19.1602 18.5215 19.0342 18.5215 18.8738C18.5215 18.8681 18.5102 18.0778 17.918 17.3046C17.1323 16.2622 15.669 15.7181 13.6818 15.7181Z'
        fill='#2A3745'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='10.8182' height='11.4545' fill='white' transform='translate(8.27274 8.27271)' />
      </clipPath>
    </defs>
  </svg>
);

export type UserProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

User.defaultProps = {
  width: 28,
  height: 28,
  fillColor: '#dde4e9',
};

export default User;
