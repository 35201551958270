const Upload = ({ width, height, className, fillColor, style }: UploadProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 13 15'
    style={style}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.49999 3.75C6.71548 3.75 6.92214 3.84877 7.07451 4.02459L10.3245 7.77459C10.6418 8.1407 10.6418 8.7343 10.3245 9.10041C10.0072 9.46653 9.49277 9.46653 9.17547 9.10041L7.31249 6.95083L7.31249 14.0625C7.31249 14.5803 6.94872 15 6.49999 15C6.05126 15 5.68749 14.5803 5.68749 14.0625L5.68749 6.95083L3.82452 9.10041C3.50722 9.46653 2.99278 9.46653 2.67548 9.10041C2.35817 8.7343 2.35817 8.14071 2.67547 7.77459L5.92546 4.02459C6.07784 3.84877 6.2845 3.75 6.49999 3.75Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 0.9375C13 1.45527 12.6362 1.875 12.1875 1.875L0.8125 1.875C0.363769 1.875 0 1.45527 0 0.9375C0 0.419733 0.363769 0 0.8125 0L12.1875 0C12.6362 0 13 0.419733 13 0.9375Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.49999 3.75C6.71548 3.75 6.92214 3.84877 7.07451 4.02459L10.3245 7.77459C10.6418 8.1407 10.6418 8.7343 10.3245 9.10041C10.0072 9.46653 9.49277 9.46653 9.17547 9.10041L7.31249 6.95083L7.31249 14.0625C7.31249 14.5803 6.94872 15 6.49999 15C6.05126 15 5.68749 14.5803 5.68749 14.0625L5.68749 6.95083L3.82452 9.10041C3.50722 9.46653 2.99278 9.46653 2.67548 9.10041C2.35817 8.7343 2.35817 8.14071 2.67547 7.77459L5.92546 4.02459C6.07784 3.84877 6.2845 3.75 6.49999 3.75Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 0.9375C13 1.45527 12.6362 1.875 12.1875 1.875L0.8125 1.875C0.363769 1.875 0 1.45527 0 0.9375C0 0.419733 0.363769 0 0.8125 0L12.1875 0C12.6362 0 13 0.419733 13 0.9375Z'
      fill='white'
    />
  </svg>
);

export type UploadProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

Upload.defaultProps = {
  width: 28,
  height: 28,
};

export default Upload;
