// Styles
import { iconsToUse } from '../../../common/styles/themes';
import * as variables from '../../../common/styles/variables';
import { Icon } from '../../../common/components';
import { Link, useHistory } from 'react-router-dom';

import { HealthItemWrapper } from './HealthItem.styles';

type HealthItemProps = {
  text: string;
  route: string;
  visible: boolean;
  status: 'loading' | 'healthy' | 'notHealthy';
  response: any;
  onMoreInfoClick: any;
  customers: Array<any>;
};

const defaultProps = {
  text: '',
  route: '',
  status: '',
  visible: false,
  response: null,
  onMoreInfoClick: null,
  customers: [],
};

export const HealthItem = (props: HealthItemProps) => {
  const history = useHistory();

  const onMoreInfoClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    props.onMoreInfoClick();
  };

  return (
    <>
      {props.visible && (
        <HealthItemWrapper className={`${props.status === 'notHealthy' ? 'notHealthy' : ''} ${props.status === 'healthy' ? 'healthy' : ''}`}>
          <div className='p-0 descCol mr-auto'>
            {props.status === 'notHealthy' && <Icon icon={iconsToUse.warning} size={variables.iconSizeBase} />}
            {props.status === 'healthy' && <Icon icon={iconsToUse.success} size={variables.iconSizeBase} />}
            <span className='pl-2'>&nbsp;{props.text}</span>
          </div>
          <div className='pl-2 pr-4 moreInfoCol'>
            &nbsp;
            {props.route && (
              <Link className='moreInfo' to={'/'} onClick={onMoreInfoClick}>
                More info
              </Link>
            )}
          </div>
        </HealthItemWrapper>
      )}
    </>
  );
};

HealthItem.defaultProps = defaultProps;
