import { BRANDS } from '../../../consts';

const trimAndLowerCase = val => val.toLowerCase().replace(/\s/g, '');

const documentTitle = () => {
  let title = 'Opus Self Service Portal';
  if (process.env.REACT_APP_BRAND === BRANDS.DRAX) {
    title = 'Drax Energy';
  }
  let secondaryDir = document.location.pathname.split('/')[2];
  if (secondaryDir) {
    secondaryDir = secondaryDir.replace(/-/g, ' ');
    secondaryDir = `${secondaryDir[0].toUpperCase()}${secondaryDir.slice(1)}`;
  }
  if (!secondaryDir) {
    return title;
  } else {
    title = `${title} - ${secondaryDir}`;
  }
  return title;
};

const pageServiceName = () => {
  let pathname = document.location.pathname;
  if (pathname[pathname.length - 1] === '/') {
    pathname = pathname.slice(0, -1);
  }

  const paths = pathname.split('/');
  const currentPage = paths[paths.length - 1];

  if (currentPage) {
    switch (currentPage) {
      case 'home':
        return 'HomePage';
      case 'messages':
        return 'messages';
      case 'invoices':
        return 'invoices';
      case 'make-a-payment':
        return 'makeapayment';
      case 'payment-history':
        return 'paymenthistory';
      case 'data':
        return 'energyusagedata';
      case 'downloadhhdata':
        return 'downloadhhdata';
      case 'smartmeterconsent':
        return 'smartmeterconsent';
      case 'mpan-details':
        return 'mpandetails';
      case 'meter-reads':
        return 'submitmeterreads';
      case 'previous-meter-readings':
        return 'meterreadhistory';
      case 'contract-summary':
        return 'contractsummary';
      case 'site-details':
        return 'sitedetails';
      case 'moving-premises':
        return 'movingpremises';
      case 'exclusive-offers':
        return 'exclusiveoffers';
      case 'tpi-admin':
        return 'tpiadmin';
      case 'loa':
        return 'loa';
      case 'broker-tpi':
        return 'brokertpi';
      default:
        return '';
    }
  }
  if (document.location.pathname === '/') return 'HomePage';
  else return '';
};

const documentClassName = () => {
  let title = 'indexPage';
  const secondaryDir = document.location.pathname.split('/')[2];
  if (secondaryDir) {
    title = secondaryDir;
  }
  return title;
};

const onlyLettersNumbersAndCommas = str => {
  return Boolean(str.match(/^[A-Za-z0-9,]*$/));
};

export { trimAndLowerCase, documentTitle, pageServiceName, documentClassName, onlyLettersNumbersAndCommas };
