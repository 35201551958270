const OpusLogo = ({ width, height, className, fillColor }: OpusLogoProps) => (
  <svg width={width} height={height} className={className} viewBox='0 0 964 460'>
    <path
      d='M459.13,335.38c13.18,0,22.19-5.83,29.2-13.35l5.67,5c-8.68,9.33-18.86,16-35.21,16-22.35,0-41.71-17.86-41.71-44.39v-.33c0-24.69,17.36-44.37,40.39-44.37,23.85,0,39,19.34,39,44.53v.34a19.88,19.88,0,0,1-.16,3H425.77c1.5,21.18,16.68,33.53,33.36,33.53m28.52-40.87c-1.16-17.18-11-33-30.53-33-16.84,0-29.85,14.17-31.35,33Z'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M575.27,341.06V290.5c0-17.51-9.85-29-27-29-16.67,0-29.53,12.68-29.53,30.36v49.22h-8.18V256h8.18v15.84c5.68-10,15.2-17.84,30.71-17.84,21.51,0,34,14.67,34,35v52.05Z'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M639.06,335.38c13.19,0,22.18-5.83,29.19-13.35l5.68,5c-8.67,9.33-18.86,16-35.2,16-22.35,0-41.71-17.86-41.71-44.39v-.33C597,273.66,614.38,254,637.4,254c23.84,0,39,19.34,39,44.53v.34a19.88,19.88,0,0,1-.16,3H605.7c1.51,21.18,16.68,33.53,33.36,33.53m28.53-40.87c-1.17-17.18-11-33-30.52-33-16.85,0-29.86,14.17-31.37,33Z'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M735.51,263.65c-19.67,0-36.86,14.85-36.86,42.55v34.86h-8.17V256h8.17v24.2c6.67-15.19,20.85-26.2,37.7-25.54v9Z'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M780.32,368.07a64.12,64.12,0,0,1-39-13l4.83-6.68a55.5,55.5,0,0,0,34.35,12c19.52,0,33.37-11.17,33.37-33V315c-7.35,10.51-18.68,19.68-35.54,19.68-20.52,0-40.86-15.68-40.86-40v-.34c0-24.51,20.34-40.36,40.86-40.36,17,0,28.52,9,35.54,19V256H822v71.9c0,25.85-17.51,40.19-41.7,40.19m34-73.9c0-19.67-17.51-32.35-35.2-32.35-17.85,0-32.86,12.35-32.86,32.2v.32c0,19.19,15.34,32.53,32.86,32.53,17.69,0,35.2-13,35.2-32.36Z'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M880,344.06c-7.51,17.85-16.19,24.18-28.36,24.18a38.22,38.22,0,0,1-16.84-3.66l2.82-7.18a29.71,29.71,0,0,0,14.53,3.18c8.84,0,14.68-5.18,21-20L832.34,256h9.49l35.38,76.07L907.91,256h9Z'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M482.37,215c-41.91,0-71.15-31.75-71.15-70.36s29.64-70.75,71.54-70.75,71.14,31.77,71.14,70.37S524.28,215,482.37,215m0-119.08c-27.09,0-46,21.63-46,48.34S455.68,193,482.76,193s46-21.63,46-48.34-19.3-48.72-46.4-48.72'
      className='pink'
      fill={fillColor || '#dc004d'}
    />
    <path
      d='M623.33,168.8H596.24v43.86h-24V76.22h53.8c31.76,0,51.83,17.93,51.83,45.81,0,30.79-24.55,46.77-54.56,46.77m.77-70.75H596.24v49.12H624.1c18.14,0,29.43-9.95,29.43-24.56,0-16.18-11.49-24.56-29.43-24.56'
      className='pink'
      fill={fillColor || '#dc004d'}
    />
    <path
      d='M805.23,153.6c0,40.73-23,61.2-58.66,61.2-35.48,0-58.09-20.47-58.09-60V76.22h24v77.57c0,25.35,13,39,34.49,39,21.25,0,34.31-12.86,34.31-38V76.22h24Z'
      className='pink'
      fill={fillColor || '#dc004d'}
    />
    <path
      d='M879.41,133.52c28.84,7,43.86,17.34,43.86,40.16,0,25.72-20.09,40.93-48.73,40.93a84,84,0,0,1-56.91-21.84l14.42-17.14c13.06,11.3,26.11,17.74,43.08,17.74,14.81,0,24.17-6.83,24.17-17.35,0-9.94-5.47-15.21-30.8-21.05-29-7-45.42-15.59-45.42-40.93,0-23.78,19.49-39.76,46.58-39.76,19.88,0,35.68,6,49.51,17.14l-12.86,18.14c-12.28-9.16-24.56-14-37-14-14,0-22.21,7.21-22.21,16.38,0,10.71,6.23,15.39,32.35,21.62'
      className='pink'
      fill={fillColor || '#dc004d'}
    />
    <path
      d='M81.39,229.53c-18.69,10.23-35.8,7.37-43.68.65C51,260,94.42,289,124.72,289c32.58,0,55.75-13.12,75.41-27.75-13.32-.56-49.08-17.93-86.41-60.34C101.45,216.17,95.2,222,81.39,229.53Z'
      className='pink'
      fill={fillColor || '#dc004d'}
    />
    <path
      d='M257.64,212.81c28,31,51.45,53.47,77.67,24.71-8.83,50-70,119-146.18,119s-127-54.43-145.71-101.48c18.84,23.06,50.12,42.47,84.67,42.47,40.1,0,79.1-19.41,129.55-84.74'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M243.2,107.39c-32.22,0-59.89,21.44-70.57,28.33,33,7.9,48.27,24.63,75.5,53.63s44.9,51.71,61.29,51.71c15.64,0,31.53-13.12,31.53-45.15,0-60.27-65.91-88.52-97.75-88.52'
      className='pink'
      fill={fillColor || '#dc004d'}
    />
    <path
      d='M183.32,40.56c-61.8,0-107.94,48.42-119.8,63.55s-31.52,51-31.52,88,21.92,35.05,25.48,35.05c4.63,0,26-1.83,48.35-30.55C127,169.48,174.51,99.75,244.74,99.75c45.25,0,79.18,35.36,84.66,42.68C308.91,92.26,258.23,40.56,183.32,40.56'
      className='blue'
      fill={fillColor || '#283244'}
    />
    <path
      d='M443.9,391.28c0,6.33-4.46,10-10.61,10h-8.05v10.06a1.93,1.93,0,0,1-1.85,1.91,1.89,1.89,0,0,1-1.84-1.91v-26.9a2.74,2.74,0,0,1,3.06-2.94h9.07C439.21,381.45,443.9,385.05,443.9,391.28ZM425.24,385V397.7h8.12c4.89,0,6.82-2.64,6.82-6.37,0-4.64-2.68-6.35-8.39-6.35Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M466.19,397.45v12a1.59,1.59,0,0,1-.74,1.48,16.61,16.61,0,0,1-9.14,2.46c-5.9,0-8.89-3.09-8.89-7.52,0-4,2.9-7.32,8.89-7.32a22,22,0,0,1,6.3.95v-1.85c0-3.58-1.71-5.2-5.56-5.2a12.69,12.69,0,0,0-5.24,1.17,2.53,2.53,0,0,1-1,.23,1.72,1.72,0,0,1-1.71-1.69,1.83,1.83,0,0,1,1.17-1.69,16.61,16.61,0,0,1,6.79-1.46C463,389,466.19,391.53,466.19,397.45ZM451,405.87c0,2.7,1.92,4.27,5.74,4.27a11,11,0,0,0,5.92-1.78v-5.74a21.13,21.13,0,0,0-5.94-1C452.75,401.63,451,403.52,451,405.87Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M484.48,389.46a1.82,1.82,0,0,1,1.51,1.78,1.74,1.74,0,0,1-1.71,1.8,11.36,11.36,0,0,1-1.51-.25,9.7,9.7,0,0,0-1.89-.16c-2.61,0-3.92,1.19-3.92,4v14.72a1.84,1.84,0,0,1-1.84,1.89,1.88,1.88,0,0,1-1.83-1.89V396.17c0-4.46,2.59-7.14,7.34-7.14A15.54,15.54,0,0,1,484.48,389.46Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M493.21,385.81v3.63H500a1.73,1.73,0,1,1,0,3.46h-6.77V406c0,2.3,1,3.87,3.62,3.87a8.68,8.68,0,0,0,3.24-.63,2.94,2.94,0,0,1,.86-.18,1.69,1.69,0,0,1,1.64,1.69,1.87,1.87,0,0,1-1.3,1.73,11.42,11.42,0,0,1-4.71.91c-4.93,0-7-3.27-7-7.39V385.81a1.86,1.86,0,0,1,1.78-1.93A1.89,1.89,0,0,1,493.21,385.81Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M518.38,404.27a34.52,34.52,0,0,1,0-6.1,10.1,10.1,0,0,1,20.1,0,34.52,34.52,0,0,1,0,6.1,10.1,10.1,0,0,1-20.1,0Zm16.48-.23a31,31,0,0,0,0-5.62,6.46,6.46,0,0,0-12.85,0,31,31,0,0,0,0,5.62,6.46,6.46,0,0,0,12.85,0Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M557.31,381.31a1.67,1.67,0,0,1,1.29,1.62,1.65,1.65,0,0,1-1.72,1.67,4.23,4.23,0,0,1-.92-.16,11.08,11.08,0,0,0-2.68-.32c-2.49,0-3.55,1.58-3.55,4.19v1.49H556a1.7,1.7,0,1,1,0,3.39h-6.3v18.12a1.81,1.81,0,0,1-1.83,1.89,1.88,1.88,0,0,1-1.82-1.89V393.19h-1.53a1.7,1.7,0,1,1,0-3.39h1.53V388c0-4.43,2.43-7.34,7.14-7.34A13.67,13.67,0,0,1,557.31,381.31Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M599,393.6a48.74,48.74,0,0,1,0,7.25C598.45,408,592.91,413,585.64,413h-8.22a2.9,2.9,0,0,1-3.1-3.08V384.51a2.89,2.89,0,0,1,3.1-3.06h8.11C592.91,381.45,598.45,386.55,599,393.6Zm-21-8.51v24.26h7.49c5.56,0,9.28-3.51,9.75-8.84a37.25,37.25,0,0,0,0-6.51c-.47-5.35-4.21-8.91-9.77-8.91Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M616.76,389.46a1.82,1.82,0,0,1,1.5,1.78,1.74,1.74,0,0,1-1.71,1.8,11.62,11.62,0,0,1-1.51-.25,9.7,9.7,0,0,0-1.89-.16c-2.61,0-3.91,1.19-3.91,4v14.72a1.85,1.85,0,0,1-1.85,1.89,1.87,1.87,0,0,1-1.82-1.89V396.17c0-4.46,2.59-7.14,7.34-7.14A15.6,15.6,0,0,1,616.76,389.46Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M639.3,397.45v12a1.59,1.59,0,0,1-.74,1.48,16.58,16.58,0,0,1-9.14,2.46c-5.89,0-8.89-3.09-8.89-7.52,0-4,2.91-7.32,8.89-7.32a21.91,21.91,0,0,1,6.3.95v-1.85c0-3.58-1.71-5.2-5.55-5.2a12.74,12.74,0,0,0-5.25,1.17,2.53,2.53,0,0,1-1,.23,1.72,1.72,0,0,1-1.71-1.69,1.83,1.83,0,0,1,1.17-1.69,16.63,16.63,0,0,1,6.8-1.46C636.11,389,639.3,391.53,639.3,397.45Zm-15.23,8.42c0,2.7,1.91,4.27,5.73,4.27a11,11,0,0,0,5.92-1.78v-5.74a21.07,21.07,0,0,0-5.94-1C625.87,401.63,624.07,403.52,624.07,405.87Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M664.19,391.06a1.8,1.8,0,0,1-.5,1.23l-6.8,8.8,7.16,9.05a2,2,0,0,1,.48,1.24,1.82,1.82,0,0,1-1.81,1.82,1.85,1.85,0,0,1-1.46-.72l-6.71-8.6-6.73,8.6a1.89,1.89,0,0,1-1.46.72,1.81,1.81,0,0,1-1.82-1.82,2,2,0,0,1,.47-1.24l7.18-9.09-6.84-8.76a1.92,1.92,0,0,1-.47-1.23,1.82,1.82,0,0,1,1.82-1.83,1.84,1.84,0,0,1,1.46.75l6.37,8.23L660.9,390a1.82,1.82,0,0,1,3.29,1.08Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M705.34,387.93a2.15,2.15,0,0,1,.25,1,1.8,1.8,0,0,1-3.45.7c-1.21-3.11-4.25-5-8.08-5-5.13,0-9,3.62-9.45,9.27a33.47,33.47,0,0,0,0,7c.68,5.36,3.67,9,9.52,9,5.09,0,8.47-3.06,8.47-7.29v-2.9h-6.53a1.8,1.8,0,0,1-1.8-1.8,1.72,1.72,0,0,1,1.8-1.73h7.13a2.78,2.78,0,0,1,3,2.9v3.13c0,6-4.82,11.21-12.16,11.21-7.51,0-12.64-5.45-13.16-12.59a48.07,48.07,0,0,1,0-7.2A13.12,13.12,0,0,1,694.06,381C699.35,381,703.54,383.63,705.34,387.93Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M723.9,389.46a1.83,1.83,0,0,1,1.51,1.78,1.75,1.75,0,0,1-1.71,1.8,11.1,11.1,0,0,1-1.51-.25,9.87,9.87,0,0,0-1.89-.16c-2.61,0-3.92,1.19-3.92,4v14.72a1.85,1.85,0,0,1-1.84,1.89,1.87,1.87,0,0,1-1.83-1.89V396.17c0-4.46,2.59-7.14,7.34-7.14A15.6,15.6,0,0,1,723.9,389.46Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M728.21,404.27a36.54,36.54,0,0,1,0-6.1,10.1,10.1,0,0,1,20.1,0,34.52,34.52,0,0,1,0,6.1,10.1,10.1,0,0,1-20.1,0Zm16.48-.23a31,31,0,0,0,0-5.62,6.46,6.46,0,0,0-12.85,0,31,31,0,0,0,0,5.62,6.46,6.46,0,0,0,12.85,0Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M774.21,391.12v18.14a1.59,1.59,0,0,1-.72,1.51,15.87,15.87,0,0,1-9.11,2.64c-5.74,0-9.66-3.31-9.66-9.23v-13a1.8,1.8,0,0,1,1.82-1.92,1.85,1.85,0,0,1,1.8,1.92v13c0,3.51,2.28,5.64,6.06,5.64a11.23,11.23,0,0,0,6.16-1.71v-17a1.83,1.83,0,0,1,1.83-1.89A1.85,1.85,0,0,1,774.21,391.12Z'
      className='black'
      fill={fillColor || '#000'}
    />
    <path
      d='M801.35,398a36.58,36.58,0,0,1,0,6.28c-.48,5.24-4.24,9.11-9.8,9.11a15.45,15.45,0,0,1-6.12-1.44v7.88a1.89,1.89,0,0,1-1.84,1.87,1.82,1.82,0,0,1-1.83-1.87V393.1a1.42,1.42,0,0,1,.68-1.37,16.68,16.68,0,0,1,8.89-2.7C797.05,389,800.85,392.68,801.35,398Zm-15.92-3.6v13.79a12,12,0,0,0,6,1.62,6,6,0,0,0,6.34-5.78,33,33,0,0,0,0-5.63,6.06,6.06,0,0,0-6.41-5.8A11.15,11.15,0,0,0,785.43,394.39Z'
      className='black'
      fill={fillColor || '#000'}
    />
  </svg>
);

type OpusLogoProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

OpusLogo.defaultProps = {
  width: 180,
  height: 117,
  fillColor: '',
};

export default OpusLogo;
