const Warning = ({ width, height, className, fillColor, style }: WarningProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='32' height='32' fill='#F6BE2E' />
    <line x1='16' y1='12' x2='16' y2='16.8554' stroke='white' strokeWidth='2' strokeLinecap='round' />
    <circle cx='16' cy='20.2' r='1.2' fill='white' />
    <path
      d='M16.1879 24.8121C21.0547 24.7083 24.9159 20.6788 24.8121 15.8121C24.7083 10.9453 20.6788 7.08413 15.8121 7.18793C10.9453 7.29172 7.08414 11.3212 7.18793 16.1879C7.29172 21.0547 11.3212 24.9159 16.1879 24.8121Z'
      stroke='white'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export type WarningProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

Warning.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#F6BE2E',
};

export default Warning;
