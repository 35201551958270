import { useContext, useState } from 'react';

// Styles
import { MeterHealthWrapper } from './MeterHealth.styles';

import { Health, IHealthCheck } from '../Health/Health';
import { useFetchHealthChecks } from '../hooks/useFetchHealthChecks';
import { useHistory } from 'react-router-dom';
import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { RoutingToTypes } from '../../search/SearchActions/SearchActions';
import { HealthCheckCountModal } from '../../mymessages/modalTemplates/HealthCheckCountModal';
import { ModalTemplate } from '../../mymessages/modalTemplates/ModalTemplate';
import { HealthWidget } from '../HealthWidget/HealthWidget';

type MeterHealthProps = {
  status: 'loading' | 'healthy' | 'notHealthy';
};

const defaultProps = {
  status: 'loading',
};

export const MeterHealth = (props: MeterHealthProps) => {
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const history = useHistory();

  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [messagevalues, setMessageValues] = useState<Messages.IMessagesValues>();
  const [mpanCountValues, setMpanCountValues] = useState<Array<Common.IKeyValue>>([]);
  const [showCount, setShowCount] = useState<boolean>(false);

  const hc: Array<IHealthCheck> = [
    {
      type: 'MeterReadReminder',
      display: 'Meter read reminder',
      route: '/home/meter-reads',
      redirect: RoutingToTypes.meterReads,
      status: 'loading',
      url: '/ersgatewayadapter/meter-health',
    },
    {
      type: 'FailureToObtainRead',
      display: 'Failed to obtain meter read',
      route: '/home/meter-reads',
      redirect: RoutingToTypes.meterReads,
      status: 'loading',
      url: '/ersgatewayadapter/meter-health',
    },
    // 52222 - Temporarily removed due to data issues.
    // {
    //   type: 'SmartMeterEligible',
    //   display: `You're eligible for a smart meter`,
    //   route: '/home/mpan-details',
    //   redirect: RoutingToTypes.mpanDetails,
    //   status: 'loading',
    //   url: '/ersgatewayadapter/meter-health',
    // },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onMoreInfoClick = (healthCheck: IHealthCheck) => {
    if (!healthCheck.response) return;

    const uniqueAccounts = Array.from(
      new Set(healthCheck.response.map((item: any) => item['customer-account-reference']))
    );

    if (uniqueAccounts.length === 1) {
      const accountId = healthCheck.response[0]['customer-account-reference'];

      searchContext.setSearchDetail({
        ...searchContext.searchDetail,
        type: 'account',
        accountId: accountId,
        routingTo: healthCheck.redirect,
        searchText: accountId,
      });
      setUserDetail({
        ...userDetail,
        selectedCustomerAccount: accountId,
      });

      history.push(healthCheck.route);
    } else {
      let response = '';
      if (healthCheck.response) {
        response = JSON.stringify(healthCheck.response);
        response = JSON.parse(response);
      }

      const messageValues = {
        ids: [],
        date: '',
        time: '',
        title: '',
        callToAction: '',
        route: healthCheck.route,
        subject: '',
        accountReference: '',
        json: response,
        routingTo: healthCheck.redirect,
      };

      const mpanCounts = Array.from(
        new Set(
          healthCheck.response.map((item: any) => {
            return {
              key: item['customer-account-reference'],
              value: `#${item['mpans']?.length} Meters`,
            };
          })
        )
      );

      setMpanCountValues(mpanCounts);

      switch (healthCheck.type) {
        case 'MeterReadReminder': {
          setMessageValues({
            ...messageValues,
            title: `It's been a while since we received reads for meters under the following accounts`,
            callToAction: 'Submit meter read',
          });
          setShowCount(true);
          setShowModal(true);
          break;
        }
        case 'FailureToObtainRead': {
          setMessageValues({
            ...messageValues,
            title: `We've failed to obtain reads for meters under the following accounts`,
            callToAction: 'Submit meter read',
          });
          setShowCount(true);
          setShowModal(true);
          break;
        }
        case 'SmartMeterEligible': {
          setMessageValues({
            ...messageValues,
            title: `The following accounts are eligible for a smart meter`,
            callToAction: 'MPAN details',
          });
          setShowCount(false);
          setShowModal(true);
          break;
        }
      }
    }
  };

  const { healthChecks } = useFetchHealthChecks(hc, userDetail.customerAccounts, props.status);

  return (
    <MeterHealthWrapper>
      <HealthWidget
        widgetKey='Meter'
        title='Meter health'
        type='MeterHealth'
        healthChecks={healthChecks}
        checkingText='Meter Health'
        healthyText='Meter health check complete'
        customers={userDetail.customerAccounts}
        onMoreInfoClick={onMoreInfoClick}
      ></HealthWidget>

      {showCount ? (
        <HealthCheckCountModal
          messagevalues={messagevalues}
          show={showModal}
          onHide={handleCloseModal}
          counts={mpanCountValues}
        />
      ) : (
        <ModalTemplate
          name={'HealthCheckModal'}
          messagevalues={messagevalues}
          show={showModal}
          onHide={handleCloseModal}
        />
      )}
    </MeterHealthWrapper>
  );
};

MeterHealth.defaultProps = defaultProps;
