import styled from 'styled-components';

import * as variables from '../../common/styles/variables';
import * as mixins from '../../common/styles/mixins';
import { respondTo } from '../../common/styles/mixins';

export const MyMessagesWrapper = styled.div`
  padding: ${variables.defaultSpacer} 0;
  position: relative;

  .title {
    color: ${({ theme }) => theme.text.darkBlue};
    padding-bottom: 14px;
    cursor: default;

    span {
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  ${respondTo.tablet`
    padding: 0 33px 0 0;
  `};

  .primary {
    text-align: center;

    ${respondTo.tablet`
      text-align: left;
    `};
  }

  .no-messages {
    height: 50vh;
  }

  .messages-list {
    .heading {
      color: ${({ theme }) => theme.text.secondary};
    }

    .filter {
      width: 300%;

      ${respondTo.tablet`
        width: 200%;
        max-width: 500px;
    `};
    }

    .actions-wrapper {
      opacity: 0;
      vertical-align: middle;
      min-width: 112px;

      ${respondTo.desktop`
        opacity: 1;
      `};

      a {
        padding-right: 0.3em;
        pointer-events: none;

        ${respondTo.tablet`
          padding-right: 0.7em;
        `};

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  tr {
    ${mixins.transitionFast};
    cursor: pointer;

    &.selected {
      .actions-wrapper {
        opacity: 1;
        pointer-events: all;

        a {
          pointer-events: all;
        }
      }
    }

    th {
      position: sticky;
      top: 0;
      z-index: ${variables.zIndexHigh};
    }
  }

  .item {
    cursor: pointer;
  }

  .icon-trash {
    fill: ${props => props.theme.text.secondary};
  }

  .checkbox {
    label {
      display: inline;
    }

    span {
      bottom: 0;
    }
  }

  .no-sort {
    color: black !important;
  }

  .warning-icon {
    width: 25px;
    padding-left: 0;
    padding-right: 0;
  }
`;
