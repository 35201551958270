import { ButtonPrimary, Loading } from '../../common/components';
import { AdminUserSearchWrapper } from './AdminLandingPageStyles';
import { useEffect, useState } from 'react';
import FormElement from '../../common/components/FormElement';

type UserSearchProps = {
  roles?: Array<string>;
  handleCheckClick: Function;
  handleErrorReset: Function;
  responseErrorReceived: boolean;
  isLoading?: boolean;
};

const defaultProps = {
  roles: [],
  responseErrorReceived: false,
};

export const UserSearch = (props: UserSearchProps) => {
  const [searchPreference, setSearchPreference] = useState('Email address');
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState('EmailAddress');
  const [responseError, setResponseError] = useState<boolean>(false);

  const emailSearchError =
    'User email has not been set up for Portal access. Please check Billing Party ID to make sure the email is correctly aligned. Please email myenergysupply@drax.com with any issues.';
  const bpidSearchError =
    'Billing Party ID not set up for Portal access. Please check email to see if correctly aligned. Please email myenergysupply@drax.com with any issues.';

  useEffect(() => {
    setResponseError(props.responseErrorReceived);
  }, [props.responseErrorReceived]);

  const handleCheckClick = () => {
    props.handleCheckClick(searchType, searchText);
  };

  const handleSearchPreference = (preference: string) => {
    props.handleErrorReset();
    setSearchText('');
    setSearchPreference(preference);
    if (preference === 'Email address') {
      setSearchType('EmailAddress');
    } else {
      setSearchType('BillingPartyId');
    }
  };

  return (
    <AdminUserSearchWrapper>
      <>
        <div className='d-flex align-items-center'>
          <div className='d-flex w-100'>
            <div className='d-flex flex-column w-100'>
              <div className='searchTitle'>Check user status</div>
              <div className='pt-1 d-flex'>
                <FormElement.Radio
                  id='user-search-preference'
                  className={''}
                  name='linkPref'
                  elements={['Email address', 'Billing Party reference']}
                  value={searchPreference}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleSearchPreference(e.target.value);
                  }}
                />
              </div>

              <div className='d-flex'>
                <div className='searchBox'>
                  <FormElement.Textarea
                    id='externalPage'
                    placeholder={''}
                    className='c-form__input no-resize'
                    label={''}
                    name='externalPage'
                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setSearchText(e.target.value);
                    }}
                    rows={1}
                    maxLength={150}
                    required={true}
                  />
                </div>

                <ButtonPrimary route='#' className='mt-0 ml-3' title={'Check'} onClick={() => handleCheckClick()} />
              </div>
              {responseError && (
                <div className='errorText'>
                  {searchPreference === 'Email address' ? emailSearchError : bpidSearchError}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
      {props.isLoading && <Loading overlay nowait />}
    </AdminUserSearchWrapper>
  );
};

UserSearch.defaultProps = defaultProps;
