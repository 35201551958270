import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { getMediaSize } from '../../../components/utils/media/media';
import { portalFilterStyles, portalMobileFilterStyles } from './PortalFilterStyles';

export type PortalFilterOption = {
  value: string | number | null;
  label: string;
};

interface IPortalFilter {
  isCustomer?: boolean;
  options: Array<Common.IOption>;
  placeholder?: string;
  icon?: string;
  selectedIndex?: number | undefined;
  isClearable: boolean;
  isSearchable?: boolean;
  defaultValue?: Common.IOption;
  onChange(el: any): void;
  handleMenuOpen?(): void;
  className?: string | undefined;
  isDisabled?: boolean;
}

const PortalFilter: React.FC<IPortalFilter> = ({
  options,
  placeholder,
  isClearable,
  isSearchable,
  defaultValue,
  selectedIndex,
  isDisabled,
  handleMenuOpen,
  ...props
}) => {
  const [filterOptions, setFilterOptions] = useState<Array<PortalFilterOption>>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | undefined>(selectedIndex);

  useEffect(() => {
    setSelectedItemIndex(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    const portalFilterOptions = options.map((option: Common.IOption) => {
      const portalFilter: PortalFilterOption = {
        value: option.value,
        label: option.display,
      };

      return portalFilter;
    });
    setFilterOptions(portalFilterOptions);
  }, [options]);

  const onMenuOpen = () => {
    if (handleMenuOpen) {
      handleMenuOpen();
    }
  };

  const onChange = (newValue: any) => {
    if (newValue) {
      const index = filterOptions.findIndex(o => o.value === newValue.value);
      setSelectedItemIndex(index);
    } else {
      setSelectedItemIndex(-1);
    }

    props.onChange(newValue);
  };

  const isDesktop = getMediaSize() === 'isDesktop';

  return (
    <div className='' {...props}>
      {!props.isCustomer && (
        <ReactSelect
          value={selectedItemIndex != null && selectedItemIndex > -1 ? filterOptions[selectedItemIndex] : null}
          styles={isDesktop ? portalFilterStyles : portalMobileFilterStyles}
          options={filterOptions}
          placeholder={placeholder}
          autoFocus={false}
          onChange={onChange}
          onMenuOpen={onMenuOpen}
          isClearable={isClearable}
          blurInputOnSelect={true}
          isSearchable={isSearchable}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

export default PortalFilter;
