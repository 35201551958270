import { useEffect, useState } from 'react';
import { useAuthState } from '../../../providers/authProvider';

import PortalFilter from '../../../common/components/PortalFilter/PortalFilter';
import { getMetersTwoYearsOld } from '../../../common/api/meterReadsApi';
import { AccountSelectionWrapper } from './styles/AccountSelection.styles';
import { HHDataRequestNoHHModal } from '../../mymessages/modalTemplates/HHDataRequestNoHHModal';

type AccountSelectionProps = {
  accountData: Array<Common.IOption>;
  handleAccountSelected: Function;
  handleAccountMpans: Function;
  handleAccountClear: Function;
  handleLoadingData: Function;
  resetData: boolean;
};

const defaultProps = {
  accountData: [],
};

export const AccountSelection = (props: AccountSelectionProps) => {
  const authContext = useAuthState();
  const [accountSelectedIndex, setAccountSelectedIndex] = useState<number | undefined>(undefined);
  const [accountRefSelected, setAccountRefSelected] = useState<Common.IOption>({ value: '', display: '' });
  const [showHHDataRequestNoHHModal, setShowHHDataRequestNoHHModal] = useState<boolean>(false);

  const amrMeterTypes = ['NCAMR', 'RCAMR', 'RCAMY'];

  useEffect(() => {
    setAccountRefSelected({ value: '', display: '' });
    setAccountSelectedIndex(-1);
  }, [props.resetData]);

  const handleAccountSelection = async (option: any) => {
    let accountRef = { value: '', display: '' };
    let metersArray: any = [];

    if (option) {
      const index = props.accountData.findIndex(i => i.display === option.label);
      setAccountSelectedIndex(index);
      accountRef = { value: option.value, display: option.label };

      metersArray = await meterHHChecker(option.value);
    } else {
      accountRef = { value: '', display: '' };
      props.handleAccountClear();
    }
    setAccountRefSelected(accountRef);
    props.handleAccountSelected(accountRef, metersArray);
  };

  const checkMeterTypes = (meters: Array<any>) => {
    let hhCount = 0;
    let nhhCount = 0;

    for (var item in meters) {
      if (
        meters[item]['hh-or-nhh'] === 'HH' ||
        (meters[item]['smart-meter'] === true && meters[item]['meter-type'].toUpperCase().startsWith('S')) ||
        (meters[item]['smart-meter'] === true && amrMeterTypes.includes(meters[item]['meter-type']))
      ) {
        hhCount = +1;
      } else {
        nhhCount = +1;
      }
    }

    if (hhCount > 0) {
      return true;
    } else {
      return false;
    }
  };

  const addMeterTypeToArray = (unsortedMeters: Array<any>) => {
    if (unsortedMeters) {
      unsortedMeters.map((item: any) => {
        if (item['hh-or-nhh'] === 'HH') {
          item.meterTypeValue = 'HalfHourly';
        } else if (item['smart-meter'] === true && item['meter-type'].toUpperCase().startsWith('S')) {
          item.meterTypeValue = 'Smart';
        } else if (item['smart-meter'] === true && amrMeterTypes.includes(item['meter-type'])) {
          item.meterTypeValue = 'AutomaticMeterRead';
        } else {
          item.meterTypeValue = 'NonHalfHourly';
        }
      });

      return unsortedMeters || [];
    }
  };

  const meterHHChecker = async (accountId: string) => {
    props.handleLoadingData(true);

    const meters = await getMetersTwoYearsOld(process.env.REACT_APP_API_URL, authContext, accountId);
    const sortedMeters = addMeterTypeToArray(meters);
    if (meters.length === 0 || !checkMeterTypes(meters)) {
      setShowHHDataRequestNoHHModal(true);
      setAccountRefSelected({ value: '', display: '' });
      setAccountSelectedIndex(-1);
    }
    props.handleLoadingData(false);
    return sortedMeters;
  };

  return (
    <>
      <AccountSelectionWrapper {...props}>
        <div className='AccountSelectionOverview pb-5'>
          <div className='mt-0 modal-title'>Select account</div>
          <div className='container'>
            <div className='left'>
              <PortalFilter
                className={'dropdown'}
                selectedIndex={accountSelectedIndex}
                options={props.accountData}
                placeholder={'Account ref'}
                onChange={handleAccountSelection}
                isClearable={true}
              />
            </div>
            <div className='right text-center'>
              {accountRefSelected.value == '' ? '' : `${accountRefSelected.display}`}
            </div>
          </div>
        </div>
        <div className='AccountSelectionOverviewMobile'>
          <div className='mt-0 modal-title'>Select account</div>
          <PortalFilter
            className={'dropdown'}
            selectedIndex={accountSelectedIndex}
            options={props.accountData}
            placeholder={'Account ref'}
            onChange={handleAccountSelection}
            isClearable={true}
          />
          <div className='right text-center pt-2'>
            {accountRefSelected.value == '' ? '' : `${accountRefSelected.display}`}
          </div>
        </div>
      </AccountSelectionWrapper>

      <HHDataRequestNoHHModal
        show={showHHDataRequestNoHHModal}
        onHide={() => {
          setShowHHDataRequestNoHHModal(false);
        }}
      />
    </>
  );
};

AccountSelection.defaultProps = defaultProps;
