export const UploadingIssuesMessage = (
  <div>
    <div className='pb-4'>Please check the following:</div>
    <ul>
      <li>
        You've input register ID's in exactly the same way as they appear on the meter (e.g. a register ID of '01' needs
        to feature the zero).
      </li>
      <li>There's no stray information (or spaces within cells) beyond the last row of data.</li>
      <li>You've saved the file in .csv format.</li>
    </ul>
  </div>
);
