import { ReactElement } from 'react';

import { SetupNotification } from './SetupNotification';
import { AccountsReview } from './AccountsReview';

type DDReviewProps = {
  stepsBar: ReactElement;
};

export const DDReview = ({ stepsBar }: DDReviewProps) => (
  <>
    {stepsBar}
    <AccountsReview />
    <SetupNotification />
  </>
);
