// Modules
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

// Styles
import { AccountOverviewWrapper, AccountOverviewText } from './AccountOverview.styles';

// Components
import { ButtonPrimary } from '../../../common/components';
import { Icon } from '../../../common/components';

// Context
import { HomeContext, HomeContextProps } from '../context/homeContext';

// Utils
import { isDraxBrand, isOpusBrand } from '../../../utils/common';

type AccountOverviewProps = {
  accountOverview: Api.IAccountOverviewData;
};

const defaultProps = {
  accountOverview: {},
};

export const AccountOverview = (props: AccountOverviewProps) => {
  // Context
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);
  const theme = useContext<any>(ThemeContext);

  // Handlers
  function toggleActive() {
    let active = true;

    if (homeState.selectedCard === 'AccountOverview') active = !homeState.active;

    setHomeState({ selectedCard: 'AccountOverview', active });
  }

  // Content
  const draxContent = () => {
    return (
      <div className='w-100 h-100 pl-lg-4 mt-3 mt-lg-0'>
        <div className='w-100 h-100 px-lg-4 py-2 d-flex flex-column'>
          <p className='font-open-sans'>Selected accounts</p>
          <div className='mt-2 accounts__list'>
            {props.accountOverview?.customerIds?.map((account: Api.ICustomer, index) => {
              if (!account) {
                return null;
              }

              const essAccountID = account.customerId;
              const accountName = account.description;

              return (
                <div key={`${accountName}-${index}`} className='d-flex align-items-center'>
                  <p>{essAccountID}</p>
                  <span className='mx-1'>-</span>
                  <p>{accountName}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const getDesktopContent = () => {
    if (isOpusBrand) {
      return (
        <>
          <div className='col-md-5 text-center'>
            <h6>Associated IDs</h6>
            <p className='font-xlarge text-bold'>{props.accountOverview.numberOfCustomers}</p>
          </div>
          <div className='col-md-7 font-medium p-0'>
            <div>
              <div>
                <span className='text-bold'>Customer ID:</span>
                <p className='primary'>{props.accountOverview.id}</p>
              </div>
              <div>
                <span className='text-bold'>Organisation name:</span>
                <p className='primary pr-2'>{props.accountOverview.description}</p>
              </div>
            </div>
          </div>
        </>
      );
    } else if (isDraxBrand) {
      return draxContent();
    }

    return null;
  };
  const getMobileContent = () => {
    if (isOpusBrand) {
      return (
        <div className='menu-list row'>
          <div className='col-12 p-0'>
            <div className='innerWrapper row'>
              <div className='col-6'>
                <h6>Customer IDs</h6>
                <p className='font-xlarge text-bold'>{props.accountOverview.numberOfCustomers}</p>
              </div>
              <div className='d-flex col-6 justify-content-center align-items-center'>
                <Icon className='icon-user' color={`${theme.icons.default}`} size={50} icon='users2' />
              </div>
              <div className='col-12 mt-4'>
                <p className='text-bold'>Customer ID:</p>
                <p className='primary'>{props.accountOverview.id}</p>
                <p className='text-bold'>Organisation name:</p>
                <p className='primary'>{props.accountOverview.description}</p>
              </div>
            </div>
            {isOpusBrand && (
              <div className='d-flex usageLink mt-auto'>
                <ButtonPrimary
                  title={AccountOverviewText.ViewMySitesBtn}
                  route='/home/site-details'
                  className='col m-0 mt-2'
                />
              </div>
            )}
          </div>
        </div>
      );
    } else if (isDraxBrand) {
      return draxContent();
    }

    return null;
  };

  return (
    <AccountOverviewWrapper {...props}>
      <div className='accountOverviewOverview'>
        <div className='d-flex align-items-center'>
          <h2 className='mt-0'>{AccountOverviewText.header}</h2>
          {isOpusBrand && <Icon className='icon-user ml-5' size={50} icon='users2' />}
        </div>
        <div className='innerWrapper row white-background'>{getDesktopContent()}</div>
        {isOpusBrand && (
          <div className='d-flex justify-content-end usageLink mt-auto'>
            <ButtonPrimary
              title={AccountOverviewText.ViewMySitesBtn}
              route='/home/site-details'
              className='col-7 m-0 mt-2'
            />
          </div>
        )}
      </div>
      <div className='accountOverviewOverviewMobile'>
        <div onClick={toggleActive}>
          <h2>{AccountOverviewText.headerBtn}</h2>
          <div className='menu-dropdown'>
            <Icon className='itemicon chevron' size={15} icon='chevron-down' />
          </div>
        </div>
        <div
          className={
            homeState.active && homeState.selectedCard === 'AccountOverview' ? 'active-dropdown' : 'hidden-dropdown'
          }
        >
          {getMobileContent()}
        </div>
      </div>
    </AccountOverviewWrapper>
  );
};

AccountOverview.defaultProps = defaultProps;
