import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// TODO: StrictMode
// If your app doesn’t work after upgrading, check whether it’s wrapped in <StrictMode>.
// Strict Mode has gotten stricter in React 18, and not all your components may be resilient to the new checks
// it adds in development mode. If removing Strict Mode fixes your app, you can remove it during the upgrade,
// and then add it back (either at the top or for a part of the tree) after you fix the issues that it’s pointing out.
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode
//
// React Router:
// https://reactrouter.com/docs/en/v6/upgrading/v5#remove-redirects-inside-switch

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
