import moment from 'moment';

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT, REVERSED_DATE_FORMAT_HYPHEN } from '../../../common/constants';

const getCurrentYear = () => new Date().getFullYear();

const formatUtcDateTime = value => {
  var local_date = moment.utc(value).local().format(DATE_TIME_FORMAT);
  return local_date;
};

const formatUtcDate = value => {
  var date = moment.utc(value).format(DATE_FORMAT);
  return date;
};

const formatDate = value => {
  var date = moment(value).format(DATE_FORMAT);
  return date;
};

const formatDateReverse = value => {
  var date = moment(value).format(REVERSED_DATE_FORMAT_HYPHEN);
  return date;
};

const formatTime = value => {
  var date = moment(value).format(TIME_FORMAT);
  return date;
};

const convertMillisecondsAndFormatDate = value => {
  const dateTrim = value.split('(')[1].split(')')[0];
  value = new Date(parseInt(dateTrim));
  value = value.toLocaleString();
  const date = value.slice(0, 8);
  return date;
};

const convertMillisecondsAndFormatTime = value => {
  const dateTrim = value.split('(')[1].split(')')[0];
  value = new Date(parseInt(dateTrim));
  value = value.toLocaleString();
  const time = value.slice(12, 17);
  return time;
};

const formatMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export {
  formatUtcDateTime,
  formatUtcDate,
  formatDate,
  formatDateReverse,
  formatTime,
  getCurrentYear,
  convertMillisecondsAndFormatDate,
  convertMillisecondsAndFormatTime,
  formatMonths,
};
