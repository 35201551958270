import React, { useEffect } from 'react';
import { useState } from 'react';

import FormElement from '../../../common/components/FormElement';
import { ReactSelectStyled } from '../../../common/components/FormSelect/FormSelect.styles';
import { MpanSelectionWrapper } from './styles/MpanSelection.styles';
import { Icon } from '../../../common/components';

type MpanSelectionProps = {
  availableMpans: Array<Common.IOption>;
  selectedMpans: Array<Common.IOption>;
  totalMpansLength: number;
  meterDataType: Common.IOption;
  dropdownDisabled: boolean;
  handleMpanClick: Function;
  handleTrash: Function;
  handleSelectAllMpans: Function;
  clearSelectedMpans: Function;
  resetData: boolean;
};

const defaultProps = {
  availableMpans: [],
  selectedMpans: [],
  totalMpansLength: 0,
  dropdownDisabled: false,
  handleMpanClick: undefined,
};

export const MpanSelection = (props: MpanSelectionProps) => {
  const [preference, setPreference] = useState('');
  const [seeMore, setSeeMore] = useState<boolean>(false);

  const mpanRadioElementsAccSelected: Array<string> = [
    `All Half Hourly MPANs (#${props.totalMpansLength})`,
    'Individual MPANs',
  ];
  const mpanRadioElements: Array<string> = [`All Half Hourly MPANs`, 'Individual MPANs'];
  const allHhSelected = props.totalMpansLength > 0;
  const resolution = window.innerWidth;
  const isZoomed = resolution <= 1250;

  useEffect(() => {
    setPreference('');
  }, [props.resetData]);

  const handleMpanClick = (event: any) => {
    props.handleMpanClick(event);
  };

  const handleTrash = (item: Common.IOption) => {
    props.handleTrash(item);
  };

  const handlePreferenceChange = async (preference: string) => {
    props.clearSelectedMpans();
    if (preference !== '' && preference !== 'Individual MPANs') {
      await props.handleSelectAllMpans();
      setPreference(`All Half Hourly MPANs (#${props.totalMpansLength})`);
    } else {
      setPreference(preference);
    }
  };

  const fullMpanList = [
    props.selectedMpans?.map((customer: Common.IOption, index) => {
      if (!customer) {
        return null;
      }
      return (
        <div key={`${props.selectedMpans}-${index}`} className=' pl-3 customerRow'>
          <React.Fragment key={index}>
            <button className='pillButton'>
              <div className='pillDisplay pr-1'>{customer.value}</div>
              <div
                className='pillTrash'
                onClick={() => {
                  handleTrash(customer);
                }}
              >
                <a className='btn-close-link' aria-label='Close'>
                  &#10006;
                </a>
              </div>
            </button>
          </React.Fragment>
        </div>
      );
    }),
  ];

  const fullMpanListMobile = [
    props.selectedMpans?.map((customer: Common.IOption, index) => {
      if (!customer) {
        return null;
      }
      return (
        <div key={`${props.selectedMpans}-${index}`}>
          <div className='customerRow'>
            <React.Fragment key={index}>
              <button className='pillButtonMobile'>
                <div className='pillDisplay pr-1'>{customer.value}</div>
                <div
                  className='pillTrash'
                  onClick={() => {
                    handleTrash(customer);
                  }}
                >
                  <a className='btn-close-link' aria-label='Close'>
                    &#10006;
                  </a>
                </div>
              </button>
            </React.Fragment>
          </div>
        </div>
      );
    }),
  ];

  const shortMpanList = isZoomed ? fullMpanList[0]?.slice(0, 5) : fullMpanList[0]?.slice(0, 10);
  const shortMpanListMobile = fullMpanListMobile[0]?.slice(0, 10);

  return (
    <>
      <MpanSelectionWrapper {...props}>
        <div className='MpanSelectionOverview'>
          <div className='mt-0 modal-title'>Select MPANs</div>
          <div className='container'>
            <div className=''>
              <FormElement.RadioColumn
                id=''
                name=''
                elements={allHhSelected ? mpanRadioElementsAccSelected : mpanRadioElements}
                value={preference}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handlePreferenceChange(e.target.value);
                }}
              />
              <div className='container'>
                {preference === 'Individual MPANs' && (
                  <div className='left mpanDropdown'>
                    <ReactSelectStyled
                      closeMenuOnSelect={props.availableMpans.length <= 1}
                      options={props.availableMpans}
                      classNamePrefix={'DashboardSelect'}
                      value={null}
                      getOptionLabel={({ display }: any) => `${display}`}
                      onChange={handleMpanClick}
                      placeholder='Select MPANs'
                      isDisabled={props.dropdownDisabled}
                      isSearchable={true}
                    />
                  </div>
                )}
                {preference === 'Individual MPANs' && (
                  <div className='right pill-list pl-4 mr-0 mb-small row'>
                    <div className='mpanBox'>{seeMore ? fullMpanList : shortMpanList}</div>
                  </div>
                )}
                {props.selectedMpans.length > (isZoomed ? 5 : 10) && preference === 'Individual MPANs' && (
                  <div className='seeMoreBtn' onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ? 'See less ' : 'See more '}
                    <Icon size={15} className='chevron' icon={seeMore ? 'chevron-up' : 'chevron-down'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='MpanSelectionOverviewMobile'>
          <div className='mt-0 modal-title'>Select MPANs</div>
          <FormElement.RadioColumn
            id='page-link-preference'
            className={''}
            label={''}
            name='linkPref'
            elements={allHhSelected ? mpanRadioElementsAccSelected : mpanRadioElements}
            value={preference}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handlePreferenceChange(e.target.value);
            }}
          />
          <div className=''>
            {preference === 'Individual MPANs' && (
              <div className='mpanDropdown'>
                <ReactSelectStyled
                  closeMenuOnSelect={props.availableMpans.length <= 1}
                  options={props.availableMpans}
                  classNamePrefix={'DashboardSelect'}
                  value={null}
                  getOptionLabel={({ display }: any) => `${display}`}
                  onChange={handleMpanClick}
                  placeholder='Select MPANs'
                  isDisabled={props.dropdownDisabled}
                  isSearchable={true}
                />
              </div>
            )}
            {preference === 'Individual MPANs' && (
              <div className='pill-list mt-2 mr-0 mb-small row'>
                <div className='mpanBox'>{seeMore ? fullMpanListMobile : shortMpanListMobile}</div>
              </div>
            )}
            {props.selectedMpans.length > 10 && preference === 'Individual MPANs' && (
              <div className='seeMoreBtn' onClick={() => setSeeMore(!seeMore)}>
                {seeMore ? 'See less ' : 'See more '}
                <Icon size={15} className='chevron' icon={seeMore ? 'chevron-up' : 'chevron-down'} />
              </div>
            )}
          </div>
        </div>
      </MpanSelectionWrapper>
    </>
  );
};

MpanSelection.defaultProps = defaultProps;
