// Modules
import React, { useState } from 'react';
import Enumerable from 'linq';

// Components
import { Icon } from '../../../common/components';
import { CircleCheck } from '../../icons';

// Utils
import { formatDate } from '../../../components/utils/date/date';
import { groupedReadHistory } from '../../../utils/groupReads';

type ReadsHistoryAccordionContentProps = {
  registers?: Array<Api.IMeterRead>;
  mpan: number;
};

const defaultProps = {
  registers: [],
};

export const ReadsHistoryAccordionContent = (props: ReadsHistoryAccordionContentProps) => {
  const { registers } = props;
  const [expandedIndex, setExpandedIndex] = useState<number>();
  const data = groupedReadHistory(registers || []);

  const readingSources: { [key: string]: string } = {
    'Derived': 'Estimate',
    'User Input': 'Customer',
    'Data Flow': 'Actual',
  };

  const toggleRow = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    e.preventDefault();

    if (expandedIndex !== index) {
      setExpandedIndex(index);
    } else {
      setExpandedIndex(-1);
    }
  };

  const renderRowContent = (registers: Array<Api.IRegisters>) => {
    const reads: Array<Api.IMeterReads> = [];

    // Removes first read for each Register
    // and adds to a single list ready to group
    Enumerable.from(registers).forEach(register =>
      Enumerable.from(register.reads)
        .skip(1)
        .forEach(read => reads.push(read))
    );

    // Groups by row, so you end up with a collection of readings by register like:
    // [
    //   {R1, R2}
    //   {R1, R2}
    //   {R1, R2}
    //   {R1, R2}
    // ]
    const grouping = Enumerable.from(reads)
      .groupBy(
        r => r.readingDate,
        el => el,
        (read, reads) => ({
          group: reads
            .select(r => ({
              readingSource: r.readingSource,
              readingDate: r.readingDate,
              registerId: r.registerId,
              reading: r.reading,
            }))
            .toArray(),
        })
      )
      .toArray();

    return (
      <>
        {grouping.map((read, index) => (
          <tr key={index} className='readings-historyrow'>
            <td></td>
            <td className='text-center'>
              <Icon icon={CircleCheck} size={20} />
            </td>
            <td>{readingSources[read.group[0].readingSource]}</td>
            <td>{formatDate(read.group[0].readingDate)}</td>
            {read.group.map((r, groupIndex) => (
              <td key={groupIndex}>{r.reading}</td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  return (
    <div>
      {data?.map((register: Api.IMeterReadHistory, index: number) => (
        <div key={index} className={`${index !== 0 ? 'top-divider-border' : ''}`}>
          <table>
            <tbody>
              <tr>
                <td colSpan={2}>
                  Meter serial no. <span className='blue-text'>{register.meterSerialNumber}</span>
                </td>
                <td></td>
                <td></td>
                <td>Register ID</td>
              </tr>
              <tr className='bottom-divider-border'>
                <td></td>
                <td className='text-center'>Status</td>
                <td style={{ width: '100px' }}>Source</td>
                <td>Read date</td>
                {register.registers.map((r, i) => (
                  <td key={i}>{r.id}</td>
                ))}
              </tr>
              <tr>
                <td>Latest read</td>
                <td className='text-center'>
                  <Icon icon={CircleCheck} size={20} />
                </td>
                <td>{readingSources[register.registers[0].reads[0].readingSource]}</td>
                <td>{formatDate(register.registers[0].reads[0].readingDate)}</td>
                {register.registers.map((r, i) => (
                  <td key={i}>{r.reads[0].reading}</td>
                ))}
                <td className='blue-text read-history-expand'>
                  <div className='row justify-content-center'>
                    View Meter read history...
                    <a href='/' className='trigger d-flex pl-2' onClick={(event: any) => toggleRow(event, index)}>
                      <Icon size={20} icon={expandedIndex === index ? 'chevron-up' : 'chevron-down'} />
                    </a>
                  </div>
                </td>
              </tr>
              {expandedIndex === index && <>{renderRowContent(register.registers)}</>}
            </tbody>
          </table>
        </div>
      ))}
      {!data && <p className='pt-4 pl-2'>No data available</p>}
    </div>
  );
};

ReadsHistoryAccordionContent.defaultProps = defaultProps;
