import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ButtonPrimary, Checkbox, FormInput, FormSelect, ModalClose } from '../../../common/components';
import { ActionRowWrapper, AddUserWrapper } from '../AddUserModal.styles';
import { EMAIL_PATTERN, PHONE_PATTERN } from '../../../utils/common';

type AddUserModalProps = {
  onHide: Function;
  handleConfirm: Function;
  show: boolean;
  title: string;
};

const defaultProps = {
  onHide: undefined,
  handleConfirm: undefined,
  show: false,
  title: 'Error',
};

type Inputs = {
  title: { label: string; value: string };
  firstname: string;
  surname: string;
  phoneNumber: string;
  mobile: string;
  email: string;
  jobtitle: string;
  associatedcustomer: string;
  primarycontact: boolean;
};

const TITLES = ['Mr', 'Mrs', 'Miss', 'Ms', 'Other'];

const MOCK_CUSTOMERS = ['Mock Customer A', 'Mock Customer B', 'Mock Customer C', 'Mock Customer D', 'Other'];

const phoneNumberError = 'Enter valid phone number';
const mobilePhoneNumberError = 'Enter valid phone number';
const emailError = 'Enter valid email address';

const AddUserModal = (props: AddUserModalProps) => {
  const methods = useForm<Inputs>({});
  const titleOptions = TITLES.map(title => ({ value: title, label: title }));
  const customerOptions = MOCK_CUSTOMERS.map(title => ({ value: title, label: title }));
  const [primaryContact, setPrimaryContact] = useState<boolean>(false);

  const submitHandler: SubmitHandler<Inputs> = data => {
    data.primarycontact = primaryContact;
    props.handleConfirm(data);
  };

  return (
    <>
      <Modal.Header>
        <p>Complete the details below to create a new user</p>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>

      <Modal.Body>
        <FormProvider {...methods}>
          <form>
            <AddUserWrapper>
              <div className='rowWrapper'>
                <label>Title</label>
                <FormSelect
                  name='title'
                  placeholder='Select a title'
                  options={titleOptions}
                  params={{ required: true }}
                />
              </div>
              <div className='rowWrapper'>
                <label>First Name</label>
                <FormInput name='firstname' placeholder='' params={{ required: true }} />
              </div>
              <div className='rowWrapper'>
                <label>Last Name</label>
                <FormInput name='surname' placeholder='' params={{ required: true }} />
              </div>
              <div className='rowWrapper'>
                <label>Phone number (landline)</label>
                <FormInput
                  name='phoneNumber'
                  placeholder=''
                  params={{
                    required: true,
                    pattern: { value: PHONE_PATTERN, message: phoneNumberError },
                  }}
                />
              </div>
              <div className='rowWrapper'>
                <label>Phone number (mobile)</label>
                <FormInput
                  name='mobile'
                  placeholder=''
                  params={{
                    pattern: { value: PHONE_PATTERN, message: mobilePhoneNumberError },
                  }}
                />
              </div>
              <div className='rowWrapper'>
                <label>Email Address</label>
                <FormInput
                  name='email'
                  placeholder=''
                  params={{
                    required: true,
                    pattern: { value: EMAIL_PATTERN, message: emailError },
                  }}
                />
              </div>
              <div className='rowWrapper'>
                <label>Job Title</label>
                <FormInput name='jobtitle' placeholder='' />
              </div>
              <div className='rowWrapper'>
                <label>Which customer is this account associated with?</label>
                <FormSelect
                  name='associatedcustomer'
                  placeholder='Select an account'
                  options={customerOptions}
                  params={{ required: true }}
                />
              </div>
              <div className='rowWrapper'>
                <Checkbox
                  id='primaryContact'
                  label='User to be setup as the Primary Contact'
                  onChange={(event: any) => setPrimaryContact(event.target.checked)}
                  checked={primaryContact}
                />
              </div>
            </AddUserWrapper>

            <ActionRowWrapper>
              <ButtonPrimary
                title='Add User'
                onClick={event => {
                  event.preventDefault();
                  methods.handleSubmit(submitHandler)();
                }}
                className='m-0 submit-btn'
              ></ButtonPrimary>
            </ActionRowWrapper>
          </form>
        </FormProvider>
      </Modal.Body>
    </>
  );
};

AddUserModal.defaultProps = defaultProps;

export default AddUserModal;
