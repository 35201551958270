import { useContext } from 'react';
import IcomoonReact from 'icomoon-react';

import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';
import { ActionsListWrapper, ActionsBtnWrapper, ActionsPanelWrapper } from './MobileActionsPanelStyles';
import iconSet from '../../../assets/selection.json';
import * as colors from '../../../common/styles/colors';
import { ButtonDownload } from '../../../common/components';

type MobileActionsProps = {
  enableDownloadBtn?: boolean;
  downloadOptions?: Array<Common.IOption>;
  showDownload: boolean;
  handleDownload?: Function;

  enableExportBtn?: boolean;
  exportOptions?: Array<Common.IOption>;
  showExport: boolean;
  handleExport?: Function;

  showAddUser: boolean;
  handleAddUser?: Function;
};

const defaultProps = {
  enableDownloadBtn: false,
  downloadOptions: [],
  showDownload: false,

  enableExportBtn: false,
  exportOptions: [],
  showExport: false,

  showAddUser: false,
};

export const MobileActionsPanel = (props: MobileActionsProps) => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  const handleFilterOpen = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      mobileFilterActive: !globalContext.globalDetail.mobileFilterActive,
    });
  };

  const download = (option: Common.IOption) => {
    if (props.handleDownload) {
      props.handleDownload(option.value);
    }
  };

  const handleExport = (option: Common.IOption) => {
    if (props.handleExport) {
      props.handleExport(option.value);
    }
  };

  const handleAddUser = () => {
    if (props.handleAddUser) {
      props.handleAddUser();
    }
  };

  return (
    <>
      <ActionsListWrapper>
        {props.showDownload && (
          <ActionsBtnWrapper className='d-flex actions-panel d-md-none'>
            <ButtonDownload
              title='Download'
              icon='filezip'
              outline={false}
              disabled={!props.enableDownloadBtn}
              options={props.downloadOptions}
              onClick={download}
            />
          </ActionsBtnWrapper>
        )}

        {props.showAddUser && (
          <ActionsBtnWrapper className='d-md-none'>
            <ButtonDownload
              title='Add User'
              icon='userplus'
              outline={false}
              disabled={false}
              onButtonClick={handleAddUser}
            />
          </ActionsBtnWrapper>
        )}

        {props.showExport && (
          <ActionsBtnWrapper className='d-md-none'>
            <ButtonDownload
              title='Export'
              icon='filezip'
              outline={false}
              disabled={!props.enableExportBtn}
              options={props.exportOptions}
              onClick={handleExport}
            />
          </ActionsBtnWrapper>
        )}
      </ActionsListWrapper>

      <ActionsPanelWrapper className='d-flex actions-panel d-md-none'>
        <li className={`${globalContext.globalDetail.mobileFilterActive ? 'primary' : ''}`} onClick={handleFilterOpen}>
          <IcomoonReact
            className={`icon-eye ${globalContext.globalDetail.mobileFilterActive ? 'primary' : ''}`}
            color={colors.oxfordBlue}
            iconSet={iconSet}
            size={20}
            icon='equalizer'
          />
          <span className='d-none d-md-inline'>Filter</span>
        </li>
      </ActionsPanelWrapper>
    </>
  );
};

MobileActionsPanel.defaultProps = defaultProps;
