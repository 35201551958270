import { StylesConfig } from 'react-select';

const fontStyles = {
  color: '#283244',
  fontSize: '16px',
};

export const selectStyles: StylesConfig<any, false> = {
  control: (base: any, { isFocused, menuIsOpen }) => {
    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        border: 'none',
        boxShadow: 'none',
        borderRadius: '5px 5px 0 0',
      };
    }

    return {
      ...base,
      cursor: 'pointer',
      borderRadius: '5px',
      padding: '3px 5px',
      border: 'none',
      ...additionalStyles,
    };
  },
  valueContainer: (base: any) => ({ ...base, paddingBottom: '6px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#283244',
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: '#283244',
  }),
  singleValue: (base: any) => ({
    ...base,
    ...fontStyles,
  }),
  placeholder: (base: any) => ({
    ...base,
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    overflow: 'hidden',
    maxWidth: 'calc(100% - 16px)',
    width: '100%',
    ...fontStyles,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: '5px',
    backgroundColor: '#f2f2f2',
    zIndex: 10,
    marginTop: 0,
    border: 'none',
    boxShadow: 'none',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    border: 'none',
  }),
  option(base: any, props) {
    const { isFocused } = props;
    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        backgroundColor: '#ffffff',
        color: '#216EF2',
      };
    }

    return {
      ...base,
      ...fontStyles,
      'cursor': 'pointer',
      'backgroundColor': 'transparent',
      'borderBottom': '1px solid #e6e7e8',

      '&:hover': {
        backgroundColor: '#ffffff',
        color: '#216EF2',
      },
      ...additionalStyles,
    };
  },
};
