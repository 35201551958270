// Colors
export const black = '#222222';
export const darkBlue = '#283244';
export const baseBlue = '#35445f';
export const midBlue = '#aeb7c6';
export const lightBlue = '#e4e9f2';
export const darkGrey = '#5f646d';
export const midGrey = '#c7c7c7';
export const lightGrey = '#f2f2f2';
export const lightGreyBg = '#f7f7f7';
export const lightGreyAccent = '#e0e0e0';
export const white = '#ffffff';
export const darkMidGrey = '#6e727b';
export const inputGrey = '#e6e7e8';
export const trueblack = '#000000';

// Primary colour palette
export const razzmatazzPink = '#dc004d';
export const oxfordBlue = '#283244';
export const hydroBlue = '#216EF2';

// Primary accent
export const spearmintGreen = '#1de9b6';

// Secondary colour palette
export const nepalBlue = '#52a1c1';
export const goldSand = '#e59e5e';
export const powderAsh = '#85C69b';
export const tiaraGray = '#c7c9c9';
export const concreteGray = '#f2f2f2'; // TODO same as lightGrey
export const manateeGray = '#87898f';
export const shuttleGray = '#5e6167';

// Highlight palette
export const darkPink = '#ae486d';
export const pink = '#cc0541';
export const pinkFaded = 'rgba(204, 5, 65, 20%)';
export const darkTeal = '#58a092';
export const teal = '#91bab2';
export const success = '#5da058';
export const successLight = '#78d278';
export const warning = '#ce7b3c';
export const warningLight = '#f3ac43';
export const danger = '#ce3c3c';
export const error = '#b41500';
export const errorLight = '#d22f19';
export const warningBackground = '#fbd295';

// Additional Colors
export const draxBtnHover = '#c7db69';
export const draxBtn = '#216EF2';
export const dustyPink = '#e79fb9';
export const draxDarkBlue = '#0A1464';
export const draxLightBg = '#f3f5f9';
export const hyperlinkBlue = '#007bff';

// Form elements Colors
export const inputBg = '#f3f5f9';

// Healthcheck Colors
export const healthy = '#1C8913';
export const unhealthy = '#F6BE2E';
export const healthyLight = '#1C89131A';
export const unhealthyLight = '#F6BE2E1A';