const AccountHealth = ({ width, height, className, fillColor, style }: AccountHealthProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 100 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M72.4746 72.4753L96.8746 96.8757'
      stroke='#0A1464'
      strokeWidth='4'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M43.75 84.3753C66.1869 84.3753 84.3753 66.1869 84.3753 43.75C84.3753 21.3135 66.1869 3.125 43.75 3.125C21.3135 3.125 3.125 21.3135 3.125 43.75C3.125 66.1869 21.3135 84.3753 43.75 84.3753Z'
      stroke='#0A1464'
      strokeWidth='4'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M62.5 62.5V59.4297C62.5 57.2109 61.3359 55.1703 59.4266 54.0406C56.3875 52.2422 51.1188 50 43.75 50C36.2859 50 31.0516 52.2281 28.0437 54.0234C26.15 55.1547 25 57.1891 25 59.3953V62.5H62.5Z'
      stroke='#0A1464'
      strokeWidth='4'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M43.75 40.625C48.9278 40.625 53.125 36.4278 53.125 31.25C53.125 26.0723 48.9278 21.875 43.75 21.875C38.5722 21.875 34.375 26.0723 34.375 31.25C34.375 36.4278 38.5722 40.625 43.75 40.625Z'
      stroke='#0A1464'
      strokeWidth='4'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export type AccountHealthProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

AccountHealth.defaultProps = {
  width: 100,
  height: 100,
};

export default AccountHealth;
