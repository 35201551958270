import { globalApiParams } from './globals';
import { get } from '../../helpers/axiosWrapper';
import { isDraxBrand } from '../../utils/common';

const apiUrl = isDraxBrand ? 'ers/v1/contracts' : 'contract/summary';

export const getContactSummary = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  contractStatus: string,
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.IContactSummaryPagedData>> => {
  return await get(
    `${url}/${apiUrl}?customer-account-reference=${essCustomerId}&contract-status=${contractStatus}&sort=${sort?.field}=${sort?.order}&page=${page}&page-size=${globalApiParams.pageSize}`,
    {},
    context
  ).catch(error => console.error(`Failed to load contract summary data`, error));
};
