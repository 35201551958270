import { DateObject } from 'react-multi-date-picker';
import { get, post } from '../../helpers/axiosWrapper';
import { globalApiParams } from './globals';
import { METER_READS_GRID_CACHE_KEY_BASE, REVERSED_DATE_FORMAT_HYPHEN } from '../../common/constants';
import { formatDateReverse } from '../../components/utils/date/date';
import { deleteCacheByKey } from './commonApi';
import convertArrayToCSV from 'convert-array-to-csv';
import XMLJs from 'xml-js';

export type MeterReadsFiltersObject = {
  essCustomerId?: string;
  essAccountId?: string;
  essSiteId?: number;
  MPAN?: number;
};

export type MetersFilterObject = {
  'site-reference'?: string;
  'mpan-core'?: string;
  'meter-type'?: Api.MeterType;
  'hh-or-nhh'?: 'HH' | 'NHH';
  'SiteReference'?: string;
  'MpanCore'?: string;
  'HhOrNhh'?: 'HH' | 'NHH';
};

export const getElecMeterReads = (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  filters: MeterReadsFiltersObject = {},
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.IMeterReadsResponseData>> => {
  const headers = {
    page,
    pageSize: globalApiParams.pageSize,
    ...filters,
    sortField: sort?.field,
    sortDir: sort?.order,
  };
  return get(`${url}/elecmeter/elecmeterreadings/${essCustomerId}`, { headers }, context);
};

export const getMeters = (
  url: string | undefined,
  context: any,
  customerId: string,
  filters?: MetersFilterObject,
  page?: number,
  sort?: Common.ISort,
  hhOrNhh?: 'NHH' | 'HH' | undefined,
  meteringPointStatus?: 'Active' | 'Inactive' | undefined,
  pageSize?: number | undefined
): Promise<Array<Api.IMeter>> => {
  const headers: Api.IMpanDetailsParams = {
    page: page || 1,
    pageSize: globalApiParams.pageSize,
    sortField: sort?.field,
    sortDir: sort?.order,
    ...filters,
  };

  if (pageSize !== undefined) headers.pageSize = pageSize;
  if (hhOrNhh !== undefined) headers['hh-or-nhh'] = hhOrNhh;
  if (meteringPointStatus !== undefined) headers['metering-point-status'] = meteringPointStatus;

  if (filters?.['meter-type'] !== undefined) {
    // Ignore '<BLANK>' we filter the data for HH after the ajax call
    if (filters?.['meter-type'] !== '<BLANK>') {
      headers['meter-type'] = filters?.['meter-type'];
    } else {
      headers['meter-type'] = undefined;
    }
  }

  return get(`${url}/customer/${customerId}/meters`, { headers }, context);
};

export const getMetersTwoYearsOld = async (url: string | undefined, context: any, customerId: string) => {
  let tempDate = new Date();
  let tempDateReverse = formatDateReverse(tempDate.setFullYear(tempDate.getFullYear() - 2, tempDate.getMonth(), 1));

  let unfilteredResults = await getMeters(
    url,
    context,
    customerId,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    0
  );
  if (!unfilteredResults) {
    return [];
  }

  const filteredResults = unfilteredResults.filter(
    f => f['supply-date-removed'] === null || f['supply-date-removed'] > tempDateReverse
  );
  return filteredResults;
};

export const getHHMetersTwoYearsOld = async (url: string | undefined, context: any, customerId: string) => {
  let tempDate = new Date();
  let tempDateReverse = formatDateReverse(tempDate.setFullYear(tempDate.getFullYear() - 2, tempDate.getMonth(), 1));

  let unfilteredResults = await getMeters(
    url,
    context,
    customerId,
    undefined,
    undefined,
    undefined,
    'HH',
    undefined,
    0
  );
  if (!unfilteredResults) {
    return [];
  }

  const filteredResults = unfilteredResults.filter(
    f => f['supply-date-removed'] === null || f['supply-date-removed'] > tempDateReverse
  );
  return filteredResults;
};

export const getMeteringPoints = (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  pageSize: number,
  page?: number,
  meteringPointStatus?: 'Active' | 'Inactive' | undefined,
  sort?: Common.ISort,
  filters?: MetersFilterObject
): Promise<Array<Api.IMeteringPoint>> => {
  const headers: Api.IMpanDetailsParams = {
    page: page || 1,
    pageSize,
    sortField: sort?.field,
    sortDir: sort?.order,
    ...filters,
  };

  if (meteringPointStatus !== undefined) headers['metering-point-status'] = meteringPointStatus;

  return get(`${url}/elecmeteringsystem/customer-metering-points/${essCustomerId}`, { headers }, context);
};

export const getMeteringPointsBySite = (
  url: string | undefined,
  context: any,
  essSiteId: string,
  page: number
): Promise<Array<Api.IMeteringPoint>> => {
  return get(
    `${url}/ers/v1/customer-metering-points?site-id=${essSiteId}&metering-point-status=Active&page=${page}&page-size=100`,
    {},
    context
  );
};

export const getCustomerMeterReads = (
  url: string | undefined,
  context: any,
  customerAccountReference: string,
  pageSize: number,
  page?: number,
  sort?: Common.ISort,
  filters?: MetersFilterObject
): Promise<Common.IResult<Array<Api.IMeterReadsMissing>>> => {
  const headers = {
    page: page || 1,
    pageSize,
    sortField: sort?.field,
    sortDir: sort?.order,
    ...filters,
  };

  return get(
    `${url}/ersgatewayadapter/meter/customer-meter-readings/${customerAccountReference}`,
    { headers },
    context
  );
};

export const getMeteringPointTarrifData = (
  url: string | undefined,
  context: any,
  mpanCore: number,
  page?: number,
  sort?: Common.ISort
): Promise<Array<Api.IContractTariff>> => {
  const headers = {
    page,
    pageSize: globalApiParams.pageSize,
    sortField: sort?.field,
    sortDir: sort?.order,
  };
  return get(`${url}/elecmeteringsystem/metering-points/${mpanCore}/contract-tariff-lines`, { headers }, context);
};

export const getMetersDetails = (
  url: string | undefined,
  context: any,
  mpan: number,
  meterSerialNumber: string,
  showEstimatedReadings: boolean,
  filters: MeterReadsFiltersObject = {},
  page: number,
  sort?: Common.ISort
): Promise<Array<Api.IMeterRead>> => {
  const headers = {
    page,
    'pageSize': globalApiParams.pageSize,
    ...filters,
    'sortField': sort?.field,
    'sortDir': sort?.order,
    'register-type': 'Settlement',
    'showEstimatedReadings': showEstimatedReadings,
  };
  return get(`${url}/ElecRead/meter-readings/${mpan}/${meterSerialNumber}`, { headers }, context);
};

export const getMetersDetailsV2 = (
  url: string | undefined,
  context: any,
  mpan: number,
  showEstimatedReadings: boolean
): Promise<Array<Api.IMeterRead>> => {
  const headers: any = {
    'page-size': 0, // all
    'register-type': 'Settlement',
    'showEstimatedReadings': showEstimatedReadings,
  };

  return get(`${url}/ElecRead/meter-readings/${mpan}`, { headers }, context);
};

interface IMeterReadingCollection {
  MeterReadings: {
    MeterReading: Array<any>;
  };
}

const convertMeterReadDataToReport = (fileType: string, data: Array<any>) => {
  let result = null;

  if (fileType.toLowerCase() === 'csv') {
    const csv = convertArrayToCSV(data);
    result = csv.toString();
  } else if (fileType.toLowerCase() === 'xml') {
    let meterReadingCollection: IMeterReadingCollection = {
      MeterReadings: {
        MeterReading: data,
      },
    };

    result = XMLJs.js2xml(meterReadingCollection, { compact: true, spaces: 2 });
  }
  return result;
};

const formatMeterReadResponse = (data: Array<Api.IMeterRead>): Array<Api.IMeterReadFormatted> => {
  var result: Array<Api.IMeterReadFormatted> = [];

  data.forEach((responseData: Api.IMeterRead) => {
    const item: Api.IMeterReadFormatted = {
      Mpan: responseData['mpan-core'],
      MeterSerialNumber: responseData['meter-serial-number'],
      RegisterID: responseData['register-id'],
      TimePatternRegime: responseData['time-pattern-regime'],
      Read: responseData.reading,
      ReadDate: responseData['reading-date'],
      ReadType: responseData['reading-type'],
      ReadStatus: responseData['reading-status'],
      CustomerName: responseData['customer-name'],
      CustomerAccountReference: responseData['customer-account-reference'],
      SiteReference: responseData['site-reference'],
      CustomerSiteReference: responseData['customer-site-reference'],
      SiteAddress: responseData['site-address'],
    };
    result.push(item);
  });

  return result;
};

export const getMeterReadsReport = (
  url: string | undefined,
  context: any,
  customerAccountReference: string,
  responseFormat: string,
  readingDateFrom?: DateObject,
  readingDateTo?: DateObject
) => {
  const readingDateFromFormatted = readingDateFrom?.format(REVERSED_DATE_FORMAT_HYPHEN);
  const readingDateToFormatted = readingDateTo?.format(REVERSED_DATE_FORMAT_HYPHEN);

  const query = `CustomerAccountReference=${customerAccountReference}&readingDateFrom=${readingDateFromFormatted}&readingDateTo=${readingDateToFormatted}`;

  const result = get(`${url}/ersgatewayadapter/meter/meter-readings?${query}`, {}, context)
    .then(response => {
      const formattedData = formatMeterReadResponse(response.data);
      var data: any = convertMeterReadDataToReport(responseFormat, formattedData);

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.setAttribute(
        'download',
        `Meter reads for ${customerAccountReference}.${responseFormat?.toLocaleLowerCase() || 'xml'}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error(error);
    });

  return result;
};

export const getGenerationMeterReads = (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  filters: MetersFilterObject = {},
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.IMeterReadsResponseData>> => {
  const headers = {
    page,
    pageSize: globalApiParams.pageSize,
    ...filters,
    sortField: sort?.field,
    sortDir: sort?.order,
  };

  return get(`${url}/elecgenerationread/elecgenerationmeterreadings/${essCustomerId}`, { headers }, context);
};

export type MeterReadingRegisterBody = {
  'meter-serial-number': string;
  'register-id': string;
  'reading': number;
};

export type MeterReadingBody = {
  'mpan-core': number;
  'reading-date': string;
  'cot-reads': boolean;
  'created-by': string;
  'register-readings': Array<MeterReadingRegisterBody>;
};

export const submitMultiMeterReads = (
  url: string | undefined,
  context: any,
  body: MeterReadingBody
): Promise<Common.IResult<any>> => {
  return post(
    `${url}/ElecRead/meter-reads`,
    {
      data: body,
    },
    context
  );
};

export const submitBulkMeterReads = (
  url: string | undefined,
  context: any,
  file: any,
  accountReference: string
): Promise<any> => {
  const headers = {
    accountReference,
  };
  return post(`${url}/bulkupload/bulk-meter-read`, { data: file, headers }, context);
};

export const deleteMeterReadsGridCache = (
  customerAccountReference: string,
  context: any
): Promise<Common.IResult<any>> => {
  return deleteCacheByKey(`${METER_READS_GRID_CACHE_KEY_BASE}-${customerAccountReference}`, context);
};
