import React, { FC } from 'react';
import styled from 'styled-components';

import { Sort, Checkbox } from '../../components';
import { respondTo } from '../../styles/mixins';
import { breakpoints, zIndexHigh } from '../../styles/variables';

const StyledTh = styled.th<any>`
  width: ${({ colWidth }) => colWidth && `${colWidth}px`};
  min-width: ${({ minWidth }) => minWidth && `${minWidth}px`};

  ${respondTo.mobile`
     ${({ responsive }: any) => responsive && `min-width: ${responsive[breakpoints.mobile]}px;`}
   `}

  ${respondTo.tablet`
    ${({ responsive }: any) => responsive && `min-width: ${responsive[breakpoints.tablet]}px;`}
  `}

  ${respondTo.desktop`
     ${({ responsive }: any) => responsive && `min-width: ${responsive[breakpoints.desktop]}px;`}
  `}

  .no-sort {
    cursor: default;
    color: black;
    div {
      display: none;
    }
  }
`;

const StyledThead = styled.thead`
  position: sticky;
  top: 0;
  z-index: ${zIndexHigh};
`;

type TableHeaderProps = {
  isSelectable: boolean;
  isAllSelected: boolean;
  sortDetails: Common.ISort;
  handleSelectAll: () => void;
  headers: Array<Common.HeaderItem>;
  actions?: FC;
  onClickSort: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const TableHeader = ({
  headers,
  actions,
  sortDetails,
  onClickSort,
  isSelectable,
  isAllSelected,
  handleSelectAll,
}: TableHeaderProps) => (
  <StyledThead>
    <tr>
      {isSelectable && (
        <th style={{ verticalAlign: 'middle', width: '60px' }}>
          <Checkbox id='selectAllMessages' onChange={handleSelectAll} checked={isAllSelected} />{' '}
        </th>
      )}
      {headers.map((item: any, index: number) => {
        if (item.component) {
          const Component = item.component;
          return <Component key={`component-${index}`} {...item.props} />;
        }
        if (!item.title) return <th key={`empty-${index}`} className={`${item.className}`} />;
        return (
          <StyledTh
            scope='col'
            colWidth={item.width}
            minWidth={item.minWidth}
            responsive={item.responsive}
            className={`${item.className}`}
            key={`${item.dataValue}-${index}`}
          >
            <Sort
              enableSort={(item.enableSort === true || item.enableSort === undefined) && true}
              title={item.title}
              sortDetails={sortDetails}
              onClick={(e: any) => onClickSort(e)}
              dataValue={item.sortField || item.dataValue}
            />
          </StyledTh>
        );
      })}
      {actions && <StyledTh></StyledTh>}
    </tr>
  </StyledThead>
);
