// Modules
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { ButtonPrimary, Icon, Loading, RowSelector } from '../../../common/components';
import { ReadsHistoryAccordionContent } from './ReadsHistoryAccordionContent';
import { SubmitReadAccordionContent } from './SubmitReadAccordionContent';

// Styles
import { MeterReadingsRowContent } from './MeterReadingsRow.styles';

// Utils
import { isDraxBrand } from '../../../utils/common';
import { iconsToUse } from '../../../common/styles/themes';
import * as variables from '../../../common/styles/variables';

type MeterReadingsRowProps = {
  siteReference: string;
  productType: string;
  isSelected: boolean;
  expandRow: Function;
  onSuccessModalAppear?: () => void;
  siteAddress: string;
  registers?: Array<Api.IMeterRead>;
  mpan: number;
  selectRow: React.MouseEventHandler<HTMLTableRowElement>;
  submitMode: boolean;
  siteId?: number;
  columnsNumber: number;
  onSiteRefBlur?: (value: string) => void;
  isSubmitReadsPage: boolean;
  siteName?: string;
  warningIcon?: boolean;
};

const defaultProps = {
  registers: [],
  submitMode: false,
  isSubmitReadsPage: false,
};

export const MeterReadingsRow = (props: MeterReadingsRowProps) => {
  const {
    onSuccessModalAppear,
    registers,
    columnsNumber,
    siteReference,
    siteAddress,
    productType,
    isSelected,
    submitMode,
    expandRow,
    selectRow,
    mpan,
    isSubmitReadsPage,
    siteName,
    warningIcon,
  } = props;

  const history = useHistory();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [isSelected]);

  const toggleRow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isExpanded) {
      setIsExpanded(false);
    } else {
      expandRow();
      setIsExpanded(true);
    }
  };

  const renderRowContent = () => {
    return submitMode ? (
      <SubmitReadAccordionContent
        registers={registers}
        mpan={mpan}
        productType={productType}
        onSuccessModalAppear={onSuccessModalAppear}
        setIsLoading={setIsLoading}
      />
    ) : (
      <ReadsHistoryAccordionContent registers={registers} mpan={mpan} />
    );
  };

  const renderWarningIcon = (value: any) => (
    <td className='warningIcon'>{value ? <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> : ''}</td>
  );

  const navigateToSubmitMeter = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    history.push('/home/meter-reads');
  };

  return (
    <>
      <RowSelector className={`item ${isSelected ? 'selected' : ''} ${isExpanded ? 'open' : ''}`} onClick={selectRow}>
        {renderWarningIcon(warningIcon)}
        <td>{mpan}</td>
        {isDraxBrand && <td>{siteReference}</td>}
        <td className='site-name-col'>{siteName}</td>
        <td className='site-address-col'>{siteAddress}</td>
        {!isExpanded && !isSubmitReadsPage && <td style={{ width: '250px' }}></td>}
        {isExpanded && !isSubmitReadsPage && (
          <td style={{ width: '250px' }}>
            <ButtonPrimary
              style={{ marginBottom: '0', marginTop: '-6px' }}
              className='d-block'
              title='Submit a meter read'
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => navigateToSubmitMeter(event)}
            />
          </td>
        )}
        <td style={{ opacity: 1 }} className='actions-wrapper'>
          <a href='/' className='trigger d-flex' onClick={(event: any) => toggleRow(event)}>
            <Icon size={20} icon={isExpanded ? 'chevron-up' : 'chevron-down'} />
          </a>
        </td>
      </RowSelector>

      {isLoading && (
        <tr>
          <td>
            <Loading overlay backdrop />
          </td>
        </tr>
      )}

      {isSelected && isExpanded && (
        <tr className='sub-row open'>
          <MeterReadingsRowContent colSpan={columnsNumber + 1} className='pt-0'>
            {renderRowContent()}
          </MeterReadingsRowContent>
        </tr>
      )}
    </>
  );
};

MeterReadingsRow.defaultProps = defaultProps;
