// Modules
import { useContext } from 'react';

// Components
import { ButtonPrimary, Icon } from '../../../common/components';

// Styles
import { MovePremisesWrapper, MovePremisesText } from './MovePremises.styles';

// Context
import { HomeContext, HomeContextProps } from '../context/homeContext';

// Utils
import { TagManager } from '../../utils/analytics/TagManager';

const WIDGET_KEY = 'MovePremises';

export const MovePremises = () => {
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);

  function toggleActive() {
    let active = true;
    if (homeState.selectedCard === WIDGET_KEY) active = !homeState.active;

    setHomeState({ selectedCard: WIDGET_KEY, active: active });
  }
  const onMovePremisesLinkClicked = () => {
    TagManager.pushData({
      event: 'Moving premises (Home page)',
    });
  };

  const isDropdownOpened = homeState.active && homeState.selectedCard === WIDGET_KEY;

  return (
    <MovePremisesWrapper>
      <div className='movePremisesOverview'>
        <h2 className='header mt-0'>{MovePremisesText.header}</h2>

        <div className='d-flex flex-column h-100 align-center-horizontal'>
          <div className='premises__text'>
            Begin the process by providing our Change of Tenancy team with some information.
          </div>
          <div className='d-flex justify-content-end mt-auto'>
            <ButtonPrimary
              onClick={onMovePremisesLinkClicked}
              title={MovePremisesText.movePremisesBtn}
              route='/home/moving-premises'
              className='m-0 mt-2'
            />
          </div>
        </div>
      </div>
      <div className='movePremisesOverviewMobile'>
        <div onClick={toggleActive}>
          <h2>{MovePremisesText.headerBtn}</h2>
          <div className='menu-dropdown'>
            <Icon className='itemicon chevron' size={15} icon={isDropdownOpened ? 'chevron-up' : 'chevron-down'} />
          </div>
        </div>
        <div className={isDropdownOpened ? 'active-dropdown' : 'hidden-dropdown'}>
          <div className='menu-list row'>
            <div className='col-12 item'>
              <div>Begin the process by providing our Change of Tenancy team with some information.</div>
              <div className='d-flex mt-auto'>
                <ButtonPrimary
                  onClick={onMovePremisesLinkClicked}
                  title={MovePremisesText.movePremisesBtn}
                  route='/home/moving-premises'
                  className='col m-0 mt-2'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MovePremisesWrapper>
  );
};
