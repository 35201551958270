import React from 'react';

import { RowSelectorWrapper } from './RowSelector.styles';

type RowSelectorProps = {
  id?: number | null;
  children: React.ReactNode;
  className: string;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
};

const defaultProps = {
  id: null,
  children: '',
  className: 'item',
  onClick: undefined,
};

export const RowSelector = (props: RowSelectorProps) => {
  return (
    <RowSelectorWrapper className={props.className} onClick={props.onClick}>
      {props.children}
    </RowSelectorWrapper>
  );
};

RowSelector.defaultProps = defaultProps;
