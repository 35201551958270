import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { ModalClose, ButtonPrimary, Checkbox } from '../../../common/components';
import { EbillTitle, EbillMessage } from './EbillConfirmationModal.messages';
import { ActionRowWrapper } from './EbillConfirmationModal.styles';

type EbillConfirmationModalProps = {
  show: boolean;
  onSubmit: (isAccepted: boolean) => void;
};

const defaultProps = {
  show: true,
  onSubmit: (isAccepted: boolean) => console.log(isAccepted),
};

export const EbillConfirmationModal = (props: EbillConfirmationModalProps) => {
  const { show, onSubmit } = props;
  const [acceptEbills, setAcceptEbills] = useState(false);

  const onClose = () => onSubmit(false);

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} size={'lg'} backdrop='static' centered>
        <Modal.Header>
          <Modal.Title>{EbillTitle}</Modal.Title>
          <ModalClose onClick={onClose} />
        </Modal.Header>

        <Modal.Body>
          <p>{EbillMessage}</p>
        </Modal.Body>

        <ActionRowWrapper>
          <Checkbox
            id='acceptEbills'
            label='Accept eBills only'
            onChange={(event: any) => setAcceptEbills(event.target.checked)}
            checked={acceptEbills}
          />

          <ButtonPrimary
            disabled={!acceptEbills}
            onClick={event => {
              event.preventDefault();
              onSubmit(acceptEbills);
            }}
            className='submit-btn'
            title={'Submit'}
          ></ButtonPrimary>
        </ActionRowWrapper>
      </Modal>
    </>
  );
};

EbillConfirmationModal.defaultProps = defaultProps;
