import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary, Icon, ModalClose } from '../../../common/components';
import * as colors from '../../../common/styles/colors';

type ContractSummaryModalProps = {
  isDirectCustomer: boolean;
  isDirectSoldByBrokerCustomer: boolean;
  isTpiCustomer: boolean;
  directCustomerContactEmail: string;
  tpiContactEmail: string;
  contactNumber: string;
  fixed?: boolean;
  show: boolean;
  onHide: any;
};

const defaultProps = {
  isDirectCustomer: true,
  isDirectSoldByBrokerCustomer: false,
  isTpiCustomer: false,
  directCustomerContactEmail: '',
  tpiContactEmail: '',
  contactNumber: '',
  show: true,
};

export const ContractSummaryModal = (props: ContractSummaryModalProps) => {
  const { show } = props;

  const directUserTitle = 'To negotiate a new contract please contact the Direct Sales team on:-';
  const directSoldByBrokerUserTitle = 'Please contact your TPI or Broker to discuss your renewal';
  const tpiUserTitle = 'Please speak to your account manager to discuss your customers renewal.';
  const tpiUserHeader =
    'Alternatively, if you do not have a dedicated account manager contact the Core Broker team on :-';

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header className='pt-0 pb-0 pr-0'>
          <ModalClose onClick={() => props.onHide()} />
        </Modal.Header>
        <Modal.Body>
          {props.isDirectCustomer && <h3 className='text-bold'>{directUserTitle}</h3>}
          {props.isDirectSoldByBrokerCustomer && <h3 className='text-bold'>{directSoldByBrokerUserTitle}</h3>}
          {props.isTpiCustomer && <h4 className='text-bold'>{tpiUserTitle}</h4>}

          <p>{props.isTpiCustomer && tpiUserHeader}</p>

          <div className='pb-2'>
            {props.isDirectCustomer && (
              <div className='pl-1'>
                <Icon size={17} icon={'envelope'} />
                <a
                  className='pl-2'
                  href={`mailto:${props.directCustomerContactEmail}`}
                  style={{ color: colors.hyperlinkBlue, textDecoration: 0 }}
                >
                  {props.directCustomerContactEmail}
                </a>
              </div>
            )}

            {props.isTpiCustomer && (
              <div className='pl-1'>
                <Icon size={17} icon={'envelope'} />
                <a
                  className='pl-2'
                  href={`mailto:${props.tpiContactEmail}`}
                  style={{ color: colors.hyperlinkBlue, textDecoration: 0 }}
                >
                  {props.tpiContactEmail}
                </a>
              </div>
            )}

            {props.isDirectCustomer && (
              <div className='pl-1'>
                <Icon size={17} icon={'headset'} />
                <a className='pl-2' href={`tel:${props.contactNumber}`} style={{ textDecoration: 0 }}>
                  {props.contactNumber}
                </a>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary onClick={() => props.onHide()} className={'m-0'} title='Ok' route='#' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

ContractSummaryModal.defaultProps = defaultProps;
