import styled from 'styled-components';

import { hydroBlue, draxDarkBlue, white } from '../../../../common/styles/colors';
import { respondTo } from '../../../../common/styles/mixins';

export const DownloadButtonsWrapper = styled.div`
  a {
    margin: 20px 0;
    font-weight: 600;
    text-decoration: none;
    color: ${white};
    background: ${hydroBlue};
    padding: 30px;

    ${respondTo.tablet`
      margin: 0;
    `}

    &:hover {
      text-decoration: none;
      color: white;
      background: ${draxDarkBlue};
    }

    & > svg {
      margin-right: 10px !important;
      fill: ${white};
      transform: rotate(180deg);
    }
  }
`;
