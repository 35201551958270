import styled from 'styled-components';

import { respondTo } from '../../common/styles/mixins';

export const AdminMessagesWrapper = styled.div`
  .adminMessagesMobile {
    ${respondTo.tablet`
      display: none;
    `};
  }

  .adminMessagesDesktop {
    display: none;
    ${respondTo.tablet`
      display: flex;
      flex-direction: column;
      flex: 1;
    `};
  }

  .displayCenter {
    display: flex;
    margin: 0 auto;
  }
`;

export const AdminMessagesGridWrapper = styled.div`
`;
