import styled from 'styled-components';

import { respondTo } from '../../../../common/styles/mixins';

export const BulkUploadLinkWrapper = styled.div`
  & > a {
    margin: 20px 0;
    font-weight: 600;
    text-decoration: none;
    color: ${({ theme }) => theme.text.secondary};

    ${respondTo.tablet`
      margin: 0;
    `}
    &:hover {
      text-decoration: underline;
    }

    & > svg {
      margin-right: 7px;
    }
  }
`;
