import * as colors from '../../styles/colors';
import styled from 'styled-components';

export const ProgressBarModalContainer = styled.div`
  progress {
    border-radius: 0; 
    width: 100%;
    height: 22px;
  }

  progress::-webkit-progress-bar {
    background-color: ${colors.lightGreyAccent};
    border-radius: 0;
  }

  progress::-webkit-progress-value {
    background-color: ${colors.hydroBlue};
    border-radius: 0;
  }
`;
