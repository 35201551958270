import { TPI_ACCESSID } from '../common/constants';
import { temporary } from './axiosWrapper';

export const isTpiUser = () => {
  if (temporary.accessId && temporary.accessId.length > 0)
    return true;
  else {
    let accessId = localStorage.getItem(TPI_ACCESSID);
    if (accessId) {
      return true;
    }
  }
  return false;
}