import { ReactNode, useState } from 'react';
import { MovingPremisesProcessContext } from './movingPremisesProcessContext';

export interface IMovingPremisesProcessProviderProps {
  children?: ReactNode;
}

export const MovingPremisesProcessProvider = (props: IMovingPremisesProcessProviderProps) => {
  const [selectedSites, setSelectedSites] = useState<any[]>([]);
  const [filledSitesData, setFilledSitesData] = useState<any[]>([]);

  return (
    <MovingPremisesProcessContext.Provider
      value={{
        selectedSites,
        setSelectedSites,
        filledSitesData,
        setFilledSitesData,
      }}
    >
      {props.children}
    </MovingPremisesProcessContext.Provider>
  );
};
