import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';

const StyledTogglePill = styled.div`
    .btn-primary {
        background-color: #BDD4F9 !important;
        border: 0 !important;
        box-shadow: none;
        font-weight: normal;
        padding-left: ${variables.spacingMedium};
        padding-right: ${variables.spacingMedium};

        &:hover, &:active, &:focus {
            border: 0 !important;
            box-shadow: none !important;
            background-color: #BDD4F9 !important;  
        }
    }

    .btn.active.btn-primary {
        background-color: ${colors.draxBtn} !important;
    }

    .left-btn {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .right-btn {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
`;

type TogglePillProps = {
  item1: string;
  item2: string;
  onClick: Function;
};

const defaultProps = {
  item1: '',
  item2: '',
};

export const TogglePill = (props: TogglePillProps) => {

    return (
        <StyledTogglePill>
            <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                <ToggleButton id="tbg-item1" className="left-btn" value={1} onClick={() => props.onClick(props.item1)}>
                    <span className='text-nowrap'>{props.item1}</span>
                </ToggleButton>
                <ToggleButton id="tbg-item2" className="right-btn" value={2} onClick={() => props.onClick(props.item2)}>
                    <span className='text-nowrap'>{props.item2}</span>
                </ToggleButton>
            </ToggleButtonGroup>
        </StyledTogglePill>
    );
};

TogglePill.defaultProps = defaultProps;
