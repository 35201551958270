import React from 'react';

import CheckboxWrapper from './CheckboxStyles';

type CheckboxProps = {
  id: string;
  type: string;
  checked: boolean;
  className?: string;
  label?: string;
  disabled: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const defaultProps = {
  id: '',
  type: 'checkbox',
  checked: false,
  className: 'checkbox',
  label: '',
  disabled: false,
  onChange: undefined,
};

export const Checkbox = (props: CheckboxProps) => {
  return (
    <CheckboxWrapper className={`${props.className} ${props.disabled ? 'disabled' : ''}`}>
      <label htmlFor={props.id}>
        {props.label}
        <input type={props.type} id={props.id} onChange={props.onChange} checked={props.checked} />
        <span></span>
      </label>
    </CheckboxWrapper>
  );
};

Checkbox.defaultProps = defaultProps;
