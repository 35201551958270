export enum BRANDS {
  OPUS = 'opus',
  DRAX = 'drax',
  HAVEN = 'haven',
}

export enum CUSTOMER_TYPES {
  SME = 'sme',
  SME_BROKER = 'smeBroker',
  CORPORATE = 'corporate',
  CORPORATE_BROKER = 'corporateBroker',
}
