import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary } from '../../../../common/components';

type TpiDataRequestedModalProps = {
  show: boolean;
  onHide: Function;
};

const defaultProps = {
  show: false,
};

export const Wrapper = styled.div``;

export const TpiDataRequestedModal = (props: TpiDataRequestedModalProps) => {
  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <Modal animation={false} className={'custom-modal'} show={props.show} backdrop='static' centered>
      <Wrapper>
        <Modal.Title>
          <div className='text-center'>Broker/TPI data requested</div>
        </Modal.Title>

        <Modal.Body>
          <div className='text-center'>
            <div className='pt-3'>
              <p>Thank you for your request.</p>
              <p>Depending on the size of your request, it could take us up to 24 hours to deliver the data.</p>
              <p>We'll email you when the data's available - you'll then be able to access it via 'Messages'.</p>
              <p>We'll let you know via email when it's ready.</p>
            </div>
          </div>
        </Modal.Body>

        <div className='d-flex justify-content-end'>
          <ButtonPrimary className='m-0' title='OK' onClick={handleClose} />
        </div>
      </Wrapper>
    </Modal>
  );
};

TpiDataRequestedModal.defaultProps = defaultProps;
