import styled from 'styled-components';
import * as variables from '../../styles/variables';

export const SortStyles = styled.a`
  color: ${({ theme }) => theme.text.secondary};
  text-decoration: none;
  line-height: 24px;
  white-space: pre;

  &:hover {
    color: ${({ theme }) => theme.text.secondary};
    text-decoration: none;
  }

  .arrows-wrapper {
    display: flex;
    flex-direction: column;

    .active {
      fill: ${({ theme }) => theme.text.secondary};
    }
  }

  svg {
    margin-left: ${variables.spacingSmall};
  }

  &.no-sort {
    cursor: default;
    color: black;
  }
`;
