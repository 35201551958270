import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../common/components';
import { ModalClose } from '../../../common/components';

type SmartMeterModalProps = {
  onClose: () => void;
};

export const ContentWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.text.secondary};
  }
`;

export const SmartMeterModal = (props: SmartMeterModalProps) => (
  <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
    <Modal.Header>
      <Modal.Title>Accessing your Smart Meter data</Modal.Title>
      <ModalClose onClick={() => props.onClose()} />
    </Modal.Header>

    <Modal.Body>
      <ContentWrapper>
        <div className='mr-3'>
          If you have already registered to access Smart meter data, click below and sign in with your username and
          password.
          <br />
          If you would like to register to access Smart meter data,{' '}
          <a href='https://energy.drax.com/drax-ea-portal-registration' rel='noopener noreferrer' target='_blank'>
            click me
          </a>{' '}
          to complete the registration form.
        </div>
      </ContentWrapper>
    </Modal.Body>
    <Modal.Footer>
      <ButtonPrimary
        onClick={() => props.onClose()}
        target={'_blank'}
        className={'w-100 mr-0 mt-3 mb-2'}
        title={'I already have Smart Data access'}
        href='https://assure-drax.glowmarkt.com/#/login'
      />
    </Modal.Footer>
  </Modal>
);
