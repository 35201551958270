import styled from 'styled-components';

// Components
import DirectDebitDraxWidget from './DirectDebitDraxWidget';
import BankTransferDraxWidget from './BankTransferDraxWidget';

import { respondTo } from '../../common/styles/mixins';
import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';

const MakeCardPaymentFormExternal = () => {

  const ContentSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${variables.spacingMedium};

    ${respondTo.desktop`
      flex-direction: row;
    `};

    .customer-details-block {
      background-color: ${colors.lightGrey};
      border-radius: ${variables.inputRadius};
      padding: ${variables.spacingSmall};
      flex: 1 100%;

      &:first-child {
        margin: 15px;
      }

      &:last-child {
        margin: 15px;
      }

      ${respondTo.desktop`
        &:first-child {
          margin: 0 15px 0 0;
        }
      
        &:last-child {
          margin: 0 0 0 15px;
        }
      `};
    }
  `;

  return (
    <ContentSectionWrapper>
      <div className='customer-details-block'>
        <BankTransferDraxWidget />
      </div>
      <div className='customer-details-block'>
        <DirectDebitDraxWidget />
      </div>
    </ContentSectionWrapper>
  );
};

export default MakeCardPaymentFormExternal;