const LOAHealth = ({ width, height, className, fillColor, style }: LOAHealthProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 100 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M17.3398 86.6668V8.3335H78.1732V17.0372' stroke='#0A1464' strokeWidth='4' strokeMiterlimit='10' />
    <path
      d='M34.4826 31.852H52.1017M34.4826 78.1483H65.3154M61.4308 96.6668H25.6731V13.3335H69.7207L87.3398 31.852V96.6668H61.4308Z'
      stroke='#0A1464'
      strokeWidth='4'
      strokeMiterlimit='10'
    />
    <path
      d='M68.5048 12.9888V31.4941H87.0101M31.4941 43.0608H77.7575M31.4941 54.6263H77.7575M31.4941 66.1919H77.7575'
      stroke='#0A1464'
      strokeWidth='4'
      strokeMiterlimit='10'
    />
  </svg>
);

export type LOAHealthProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

LOAHealth.defaultProps = {
  width: 100,
  height: 100,
};

export default LOAHealth;
