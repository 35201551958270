import ReactGTM from 'react-gtm-module';

const dataLayerPageEvents = (userId: string, customerType: string) => {
  ReactGTM.dataLayer({
    dataLayer: {
      'event': 'pageview',
      'page': {
        url: document.location,
        title: document.title,
      },
      'user-id': userId,
      customerType,
    },
  });
};

export { dataLayerPageEvents };
