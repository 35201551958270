import { useContext, useState, useEffect } from 'react';

import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

import { EbillModalProcess } from './EbillModalProcess';
import { PrimaryContactModalProcess } from './PrimaryContactModalProcess';

export const DashboardModals = () => {
  const { isSMECustomer, isCorpCustomer } = useContext<UserDetailContextProps>(UserDetailContext);
  // PrimaryContent Modal is visible for SMECustomer only after E-bill popup.
  // PrimaryContent Modal is visible for CorpCustomer without restriction.
  const [allowPrimaryContactModal, setAllowPrimaryContactModal] = useState(false);
  const onEBillProcessEnd = () => setAllowPrimaryContactModal(true);

  useEffect(() => {
    if (!isSMECustomer() && isCorpCustomer()) {
      setAllowPrimaryContactModal(true);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSMECustomer(), isCorpCustomer()]);

  return (
    <>
      {isSMECustomer() && <EbillModalProcess onEBillProcessEnd={onEBillProcessEnd} />}
      {(isSMECustomer() || isCorpCustomer()) && allowPrimaryContactModal && <PrimaryContactModalProcess />}
    </>
  );
};
