import * as colors from '../colors';
import * as variables from '../variables';

// ********** REMINDER **********
// Don't forget to update other theme file(s) if you're making any changes
export const themeDrax = {
  fontFamily: `'Segoe UI', 'Open Sans', sans-serif`,
  bg: {
    homeHeader: colors.draxDarkBlue,
    secondary: colors.draxLightBg,
  },
  text: {
    secondary: colors.hydroBlue,
  },
  title: {
    fontSize: variables.fontSizeTitle,
    fontWeight: variables.fontWeightRegular,
  },
  primary: {
    bg: colors.draxBtn,
    color: colors.draxBtn,
    border: colors.draxBtn,
    hovercolor: colors.draxDarkBlue,
    btnBorderRadius: variables.btnBorderRadiusRounded,
  },
  secondary: {
    bg: colors.white,
    color: colors.draxBtn,
    border: colors.draxBtn,
    hovercolor: colors.draxDarkBlue,
    btnBorderRadius: variables.btnBorderRadiusRounded,
  },
  terinary: {
    color: colors.draxBtn,
    border: colors.draxBtn,
    hovercolor: colors.draxDarkBlue,
    btnBorderRadius: variables.btnBorderRadiusRounded,
  },
  externalLink: {
    color: colors.draxBtn,
    border: colors.draxBtn,
    hovercolor: colors.draxDarkBlue,
    btnBorderRadius: variables.btnBorderRadiusRounded,
  },
  button: {
    bg: colors.draxBtn,
    border: colors.draxBtn,
    hoverBg: colors.draxDarkBlue,
    hoverBorder: colors.draxDarkBlue,
    textOnlyHoverColor: colors.draxBtn,
    btnBorderRadius: variables.btnBorderRadiusRounded,
    fontWeight: 'normal',
  },
  formSelect: {
    selected: colors.hydroBlue,
  },
  dropdown: {
    bg: colors.white,
    border: 'transparent',
    color: colors.draxDarkBlue,
  },
  filterDropdown: {
    bg: colors.white,
    border: colors.darkGrey,
    color: colors.draxBtn,
  },
  loader: {
    borderBg: colors.lightGrey,
    borderActive: colors.draxBtn,
  },
  amount: {
    color: colors.draxBtn,
  },
  input: {
    bg: colors.white,
    color: colors.manateeGray,
    border: colors.tiaraGray,
  },
  rowinput: {
    border: colors.lightGrey,
  },
  icons: {
    default: colors.draxBtn,
  },
  datepicker: {
    bg: colors.draxBtn,
    text: colors.draxBtn,
    arrow: colors.draxBtn,
    range: colors.draxBtn,
  },
  serviceMessages: {
    high: colors.errorLight,
    medium: colors.warningLight,
    low: colors.successLight,
  },
};

// Check reminder on top of the file
