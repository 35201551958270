import { css } from 'styled-components';

const XXLbreakpoint = '1400px';
export const flexXXLStyles = css`
  @media screen and (min-width: ${XXLbreakpoint}) {
    .flex-xxl-row {
      flex-direction: row !important;
    }

    .col-xxl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .mt-xxl-0 {
      margin-top: 0 !important;
    }
  }
`;
