// Modules
import { get, getExternal, post } from '../../helpers/axiosWrapper';
import {
  IPaymentModel,
  IPaymentResponse,
  ICountry,
  ISuccessPaymentModel,
  IPaymentHistory,
  IPaymentHistoryExpanded,
} from '../../types/payment';

const LOQATE_KEY = 'ZN63-TY57-WW69-UZ97';
const LOCATION_SERVICE = 'https://services.postcodeanywhere.co.uk/CapturePlus/Interactive';
const SEARCH_ENDPOINT = `${LOCATION_SERVICE}/Find/v2.10/json3ex.ws?Key=${LOQATE_KEY}`;
const DETAILS_ENDPOINT = `${LOCATION_SERVICE}/RetrieveFormatted/v2.10/json3ex.ws?Key=${LOQATE_KEY}`;

// Mock for OPUS version
const MOCK_COUNTRIES = [
  { label: 'United Kingdom of Great Britain and Norther Ireland', value: 'GBR' },
  { label: 'Ukraine', value: 'UKR' },
];

export const getCountriesList = async (url: string | undefined, context: any): Promise<any> => {
  const response = await get(`${url}/content/countries`, {}, context);
  return (
    response?.data.map((country: ICountry) => ({ label: country.name, value: country.isO_ThreeDigitCode })) ||
    MOCK_COUNTRIES
  );
};

export const findLocation = async (term: string, id?: string): Promise<any> => {
  return await getExternal(
    `${SEARCH_ENDPOINT}&SearchTerm=${term}&LanguagePreference=en&LastId=${
      id || ''
    }&SearchFor=Everything&OrderBy=UserLocation&$block=true&$cache=true`
  );
};

export const getLocationDetails = async (id: string): Promise<any> => {
  return await getExternal(`${DETAILS_ENDPOINT}&Id=${id}&$cache=true`);
};

export const getPaymentHpp = async (
  url: string | undefined,
  context: any,
  paymentModel: IPaymentModel
): Promise<Common.IResult<IPaymentResponse>> => {
  const model = JSON.stringify(paymentModel);

  const headers: any = {
    paymentModel: model,
    merchantId: process.env.REACT_APP_MERCHANT_ID,
  };

  return await get(url + `/payment/paymentHppJson`, { headers }, context);
};

// 45583 - correspondence type refactor, reinstate once payment method resolved
// export const paymentSuccessful = async (
//   url: string | undefined,
//   context: any,
//   successPaymentModel: ISuccessPaymentModel
// ): Promise<Common.IResult<boolean>> => {
//   const json = JSON.stringify(successPaymentModel);
//   const data = JSON.parse(json);
//   return await post(url + `/payment/paymentSuccessful`, { data }, context);
// };

export const getPaymentHistory = async (
  url: string | undefined,
  context: any,
  customerAccountReference: string
): Promise<Common.IResult<Array<IPaymentHistory>>> => {
  const headers: any = {
    customerAccountReference,
  };

  return await get(url + `/ersgatewayadapter/payment/payment-history`, { headers }, context);
};

export const getPaymentHistoryExpanded = async (
  url: string | undefined,
  context: any,
  customerAccountReference: string,
  transactionReference: string
): Promise<Common.IResult<Array<IPaymentHistoryExpanded>>> => {
  const headers: any = {
    customerAccountReference,
    transactionReference,
  };

  return await get(url + `/ersgatewayadapter/payment/payment-history-expanded`, { headers }, context);
};

export const getPaymentHistoryDownload = async (
  url: string | undefined,
  context: any,
  customerAccountReference: string
): Promise<any> => {
  const headers: any = {
    customerAccountReference,
  };

  return await get(url + `/ersgatewayadapter/payment/payment-history-download`, { headers }, context);
};
