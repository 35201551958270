import { OpusLogo, DraxLogo, OpusMobileLogo, DraxMobileLogo } from '../icons';

const logos: { [key: string]: any } = {
  drax: {
    desktopLogo: DraxLogo,
    mobileLogo: DraxMobileLogo,
  },
  opus: {
    desktopLogo: OpusLogo,
    mobileLogo: OpusMobileLogo,
  },
};

export default logos;
