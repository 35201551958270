import { useState, useEffect, useContext } from 'react';
import { MobileView } from 'react-device-detect';

import { Main } from './Main';
import { MessageCheck } from './components/messagecheck';
import { HildebrandRegister } from './components/hildebrandRegister';
import { SideMenu } from './components/sidemenu/SideMenu';
import { documentClassName } from './components/utils/text/text';
import { SwitchViewBtn } from './common/components/SwitchViewBtn';
import BrokerLandingPageIndex from './components/brokerlandingpage';
import { GlobalContext, GlobalDetailContextProps } from './context/globalContext/globalContext';
import { UserDetailContext, UserDetailContextProps } from './context/userDetail/userDetailContext';

import { LayoutStyles } from './components/layout/Layout.styles';

export const Layout = () => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    // log for 35984
    console.log('Layout is LoggedIn');

    setIsLoggedIn(!!userDetail?.customerType);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail?.customerType, userDetail.customerType]);

  const handleCollapsedChange = (checked: boolean) => {
    setCollapsed(checked);
  };

  return (
    <LayoutStyles className={documentClassName()}>
      <BrokerLandingPageIndex />
      <div
        className={`${
          isLoggedIn && !globalContext.globalDetail.showBrokerLandingPage ? 'container-fluid p-0 h-100vh' : 'hidden'
        }`}
      >
        <div className='indexHeader' />
        <div className={`app row m-0`}>
          <SideMenu />
          <MessageCheck />
          <HildebrandRegister />
          <Main collapsed={collapsed} handleCollapsedChange={handleCollapsedChange} />
          <MobileView>
            <SwitchViewBtn />
          </MobileView>
        </div>
      </div>
    </LayoutStyles>
  );
};
