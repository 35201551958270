import styled from 'styled-components';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';
import { respondTo } from '../../../common/styles/mixins';

export const UsageText = {
  header: 'Usage',
  headerBtn: 'Usage',
  electricitykWh: 'kWh',
  gaskWh: 'kWh',
  usageBtn: 'Usage',
};

export const SmeAccountOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    text-align: left;

    ${respondTo.desktop`

    `};
  }

  .usageOverviewMobile {
    ${mixins.widgetMobile};
  }

  .usageOverview {
    ${mixins.widgetDesktop};
  }

  --totalamountcolor: ${({ theme }) => theme.amount.color};

  .usage {
    justify-content: center;

    ${respondTo.desktop`
      justify-content: left;
    `};

    .category {
      svg {
        fill: ${props => props.theme.text.secondary};
      }

      i {
        top: 22px;
        font-size: 2.7rem;
      }
    }

    .total {
      font-weight: bold;
      padding-left: ${variables.spacingSmall};

      ${respondTo.desktopXl`
        padding-left: 0;
      `};

      ${respondTo.desktopXXl`
        padding-left: ${variables.spacingSmall};
      `};

      .amount {
        color: var(--totalamountcolor);
        font-weight: bold;
        overflow-wrap: anywhere;
        font-size: 2rem;

        ${respondTo.mobile`
          font-size: ${variables.fontSizeXLarge};
        `};

        ${respondTo.desktopXl`
          font-size: 2rem;
        `};

        ${respondTo.desktopXXl`
          font-size: ${variables.fontSizeXLarge};
        `};
      }

      .amountType {
        padding-left: ${variables.spacingSmall};
        font-size: 0.8rem;

        ${respondTo.mobile`
          font-size: 100%;
        `};

        ${respondTo.desktopXl`
          font-size: 0.8rem;
        `};
      }
    }
  }

  .usageLink {
    display: flex !important;
  }

  .hidden-dropdown {
    ~ .usageLink {
      display: none !important;
    }
  }
`;
