import { useContext, useEffect } from 'react';

import { getUnreadMessageCount } from '../../common/api/myMessagesApi';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { useAuthState } from '../../providers/authProvider';
import { isTpiUser } from '../../helpers/tpiUser';

export const MessageCheck = () => {
  const authContext = useAuthState();
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  useEffect(() => {
    if (userDetail.emailAddress.length == 0) return;

    if (userDetail.customerAccounts.length === 0) return;
    
    const fetchUnreadMessageCount = async () => {
      const accountReferences: Array<string> = [];
      let userType: string = 'Customers';
      let references: Array<string> = [];

      if (isTpiUser()) {
        userType = 'TPIUsers';
        userDetail.customerAccounts.map(x => accountReferences.push(x.essAccountID));
        let claims = authContext.getClaims();
        references.push(claims.tpiReferenceId);
      } else {
        let accounts = userDetail.customerAccounts.map(x => x.essAccountID);
        references.push(...accounts);
      }

      const messagesData = await getUnreadMessageCount(
        process.env.REACT_APP_API_URL,
        authContext,
        accountReferences,
        userType,
        references
      );

      if (messagesData?.data > 0) {
        setUserDetail({
          ...userDetail,
          hasUnreadMessages: true,
        });
      } else {
        setUserDetail({
          ...userDetail,
          hasUnreadMessages: false,
        });
      }
    };

    fetchUnreadMessageCount();

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.emailAddress, userDetail.customerAccounts]);

  return null;
};
