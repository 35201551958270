// Modules
import React from 'react';
import { TypeAheadDropDown } from '../../../common/components/TypeAheadDropDown/TypeAheadDropDown';

// Styles
import { ViewAsCustomerWidgetContainer } from './ViewAsCustomerWidget.styles';

type ViewAsCustomerWidgetProps = {};
export function ViewAsCustomerWidget(props: ViewAsCustomerWidgetProps) {
  // State
  const [options] = React.useState<Common.IExpandedOption[]>([]);
  const [selectedValue, setSelectedValue] = React.useState('');

  // Handlers
  const onDropdownItemSelect = (item: Common.IExpandedOption) => {
    setSelectedValue(item.value);
  };

  return (
    <ViewAsCustomerWidgetContainer className='col-12 d-flex flex-column flex-xl-row'>
      <div className='col-12 col-xl-7 p-0 d-flex flex-column'>
        <h4>View as a customer</h4>
        <p>Please search the email address of the user you wish to view as</p>
      </div>
      <div className='col-12 col-xl-5 p-0 d-flex align-items-center'>
        <TypeAheadDropDown
          className='dropdown'
          options={options}
          enabled={!!options.length}
          selectedValue={selectedValue}
          selectText='Search to view as customer'
          handleChange={onDropdownItemSelect}
        />
      </div>
    </ViewAsCustomerWidgetContainer>
  );
}
