import { get, getSingleFile, post, postForm } from '../../helpers/axiosWrapper';

export const getTpiByTpiReference = async (url: string | undefined, context: any): Promise<Common.IResult<any>> => {
  const apiUrl = 'tpis/getTpiByTpiReference';

  return await get(`${url}/${apiUrl}`, {}, context).catch(error => console.error(`Failed to get tpi reference`, error));
};

export const uploadLoaFiles = async (
  url: string | undefined,
  context: any,
  data: Api.ILoaFileUpload,
  onUploadProgress: Function
): Promise<Common.IResult<any>> => {
  var bodyFormData = new FormData();
  bodyFormData.append('tpiReference', data.tpiReference);
  bodyFormData.append('tpiName', data.tpiName);
  bodyFormData.append('emailAddress', data.emailAddress);
  bodyFormData.append('comments', data.comments);

  data.files.map((file: File) => {
    bodyFormData.append('files', file);
  });

  return await postForm(
    url + '/bulkupload/upload-letters-of-authority',
    { data },
    bodyFormData,
    onUploadProgress,
    context
  );
};

export const downloadBrokerTpiReport = async (
  url: string | undefined,
  context: any,
  requestId: string
): Promise<any> => {
  const headers: any = {};
  return await getSingleFile(url + `/tpis/downloadbrokerreport/${requestId}`, { headers }, context);
};
