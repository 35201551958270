import { GuaranteeAdditional } from './DDFillForm.styles';

export const accountNumberError = 'Please provide a valid account number';
export const sortCodeError = 'Please provide a valid sort code';

export const contactInfo = (
  <div className='contactInfo'>
    <p>
      Opus Energy Ltd <br />
      Opus Energy House <br />
      8-10 The Lakes <br />
      Northampton NN4 7YD
    </p>

    <p>
      Contact number: <a href='tel:0843 227 2377'>0843 227 2377</a>
    </p>
    <p>
      Email address: <a href='mailto: contactus@opusenergy.com'>contactus@opusenergy.com</a>
    </p>
  </div>
);

export const authorisedPersonConfirmation = (
  <p>
    If you cannot confirm that you are the only person required to authorise debits from this Account, you will need to
    contact our Customer Service to discuss alternatives on <a href='tel:0843 227 2377'>0843 227 2377</a>.
  </p>
);

export const ddGuarantee = (companyName: string) => (
  <p>
    *This Guarantee is offered by all banks and building Societies that accept instructions to pay Direct Debits
    <br />
    <br />
    *If there are any changes to the amount, date or frequency of your Direct Debit {companyName} will notify you 5
    working days in advance of your account being debited or as otherwise agreed. If you request {companyName} to
    collect a payment, confirmation of the amount and date will be given to you at the time of the request
    <br />
    <br />
    *If an error is made in the payment of your Direct Debit, by {companyName} or your bank or building society, you are
    entitled to a full and immediate refund of the amount paid from your bank or building society
    <br />
    <br />
    *If you receive a refund you are not entitled to, you must pay it back when {companyName} asks you to
    <br />
    <br />
    *You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written confirmation
    may be required. Please also notify us.
  </p>
);

export const ddGuaranteeAdditional = (
  <>
    <GuaranteeAdditional>
      Banks and building societies may not accept Direct Debit Instructions for some types of accountBanks and building
      societies may not accept Direct Debit Instructions for some types of account.
    </GuaranteeAdditional>

    <GuaranteeAdditional>
      Applications or changes take 10 days to take effect, and will not affect existing outstanding account balances.
    </GuaranteeAdditional>
  </>
);
