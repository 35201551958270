import styled from 'styled-components';
import { respondTo, transition } from '../../styles/mixins';
import { lightGrey } from '../../styles/colors';

type TitleProps = {
  selected: boolean;
};

export const StyledTableActionsCell = styled.td<TitleProps>`
  text-align: left;
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  display: ${({ selected }) => (selected ? 'table-cell' : 'none')};
  pointer-events: ${({ selected }) => (selected ? 'all' : 'none')};
  background-color: ${({ selected }) => (selected ? lightGrey : 'inherit')};
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  vertical-align: middle !important;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: all;

    & > :first-child {
      margin-right: 5px;
      ${respondTo.tablet`
         margin-right: 10px;
      `}
    }

    ${respondTo.tablet`
      justify-content: end;
    `}
  }

  a {
    margin: 0;
    display: inline-block;
    pointer-events: ${({ selected }) => (selected ? 'all' : 'none')};
  }

  svg path {
    ${transition};
  }

  ${respondTo.tablet`
    text-align: right;
    display: table-cell;
  `}
`;
