import { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { respondTo } from '../../styles/mixins';
import styled from 'styled-components';

const StyledToolTip = styled.div`
  position: relative;
  width: 50%;

  ${respondTo.desktop`
    width: 100%;
  `}
`;

type ToolTipProps = {
  component?: string | ReactElement;
  text?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
};

const defaultProps = {
  component: '',
  text: '',
  placement: 'top',
};

export const ToolTip = (props: ToolTipProps) => {
  const renderTooltip = () => (
    <Tooltip id='tooltip' {...props} className='tooltip-black'>
      {props.text}
    </Tooltip>
  );

  return (
    <StyledToolTip>
      <OverlayTrigger
        placement={props.placement}
        delay={{ show: 200, hide: 200 }}
        overlay={renderTooltip()}
        trigger={['hover', 'click']}
      >
        <span>{props.component}</span>
      </OverlayTrigger>
    </StyledToolTip>
  );
};

ToolTip.defaultProps = defaultProps;
