import GTM from 'react-gtm-module';
import { toast } from 'react-toastify';

export class TagManager {
  static pushData(dataLayer: object = {}, dontFlush: boolean = false) {
    try {
      // Push data
      GTM.dataLayer({ dataLayer });

      if (dontFlush) {
        return;
      }
      // Flush data after data pushed, so it won't be added to next events
      const keys = Object.keys(dataLayer);
      const clearedDataLayer: { [key: string]: any } = {};

      // In order to flush data, we need to set it to `undefined`
      for (let i = 0; i <= keys.length; i += 1) {
        clearedDataLayer[keys[i]] = undefined;
      }
      // Push cleared layer, it will be shown as `Message` in preview mode
      clearedDataLayer.event = 'Flush data';

      GTM.dataLayer({ dataLayer: clearedDataLayer });
    } catch (error) {
      const message = 'GTM: Failed to push data to data layer.';

      console.error(message, error);
      toast.error(message);
    }
  }
}
