export const ExpandableRow = (props: ExpandableRowProps) => {
  return (
    <tr className={props.className}>
      <td style={{ paddingTop: 0 }} colSpan={props.colSpan || 100}>
        {props.children}
      </td>
    </tr>
  );
};

type ExpandableRowProps = {
  children: any;
  colSpan?: number;
  className?: string;
};
