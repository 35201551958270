import { IRadioInterface } from '../types';
import classnames from 'classnames';

const Radio = (props: IRadioInterface) => {
  const { label, onChange, elements, name, value, className } = props;
  const cx = classnames(`c-form__label c-form__label--radio ${className}`);

  return (
    <div className='c-form__element'>
      <label className={cx} htmlFor='email'>
        {label}
      </label>

      <div className='c-form__radio-wrapper'>
        {elements.map((el, i) => (
          <div className='c-form__radio-element' key={`${el}-${i}`}>
            <input onChange={onChange} id={el} value={el} name={name} type='radio' checked={value === el} />
            <label htmlFor={el}>{el}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radio;
