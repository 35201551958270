import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary, ModalClose } from '../../../common/components';

import { useContext } from 'react';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

export const BulkMessageModalWrapper = styled.div``;

type BulkMessageModalProps = {
  onHide: () => void;
  messagevalues: Messages.IMessagesValues;
  handleMarkAsRead: Function | undefined;
};

export const BulkMessageModal = (props: BulkMessageModalProps) => {
  const { onHide, handleMarkAsRead = () => {} } = props;
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const handleCloseModal = () => {
    if (props.messagevalues && props.messagevalues?.ids) {
      const data = {
        emailAddress: userDetail.emailAddress,
        alertIds: [props.messagevalues.ids[0]],
      };
      handleMarkAsRead(data);
      onHide();
    }
  };

  return (
    <BulkMessageModalWrapper>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Important message</Modal.Title>
        <ModalClose onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body className='pl-lg-4'>
        <div>
          {props.messagevalues?.json?.message}
          <br />
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={handleCloseModal} className={'mr-0'} title='Ok' route='#' />
      </Modal.Footer>
    </BulkMessageModalWrapper>
  );
};
