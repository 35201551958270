import React, { useState, useEffect, useContext, useReducer, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

/* components */
import {
  ButtonPrimary,
  ModalClose,
  Checkbox,
  Sort,
  RowInputItem,
  RowSelector,
  NoResults,
} from '../../../common/components';

/* styles */
import { MyMessagesSettingsModalWrapper } from '../MyMessagesSettingsModal.styles';

/* context */
import { useAuthState } from '../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { myMessagesSettings, myMessagesSettingsMeterReminders } from '../../../common/api/myMessagesApi';
import { putSiteReference } from '../../../common/api/invoicesApi';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../../reducer/gridReducer/gridReducer';
import InfiniteScroll from 'react-infinite-scroll-component';

type MyMessagesSettingsModalProps = {
  onHide: Function;
  handleDelete: Function;
  show: boolean;
  title: string;
  description: string;
  size: string;
};

const defaultProps = {
  onHide: undefined,
  handleDelete: undefined,
  show: false,
  title: 'Message settings',
  description: 'Which sites would you like to receive meter read reminders for:',
  size: 'xl',
};

const MyMessagesSettingsModal = (props: MyMessagesSettingsModalProps) => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [sortDetails] = useState<Common.ISort>({ field: 'essCustomerID', order: 'ASC' });

  const initialState: IGridReducerState = {
    page: 1,
    currentType: '',
    data: {},
    initialSortField: 'essCustomerID',
    initialSortOrder: 'ASC',
    currentSortField: 'essCustomerID',
    currentSortOrder: 'ASC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: userDetail.essCustomerId,
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  useEffect(() => {
    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentType, state.page, state.selectedEssAccountId, state.currentSortField, state.currentSortOrder]);

  const getMyMessagesSettings = async (essCustomerId: string, sort?: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const myMessagesData: Common.IResult<Array<Messages.IAlertData>> = await myMessagesSettings(
      process.env.REACT_APP_API_URL,
      essCustomerId,
      authContext,
      state.page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (myMessagesData && myMessagesData.success) {
      let data = myMessagesData.data;

      dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data, page: state.page } });
      // handleIsChecked(data);
    } else {
      dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
      const message = 'API error calling: activesitesbycustomerid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const putMyMessagesSettingsMeterReminders = async (data: object | null | undefined) => {
    const myMessagesSettingsMeterRemindersData: Common.IResult<Array<any>> = await myMessagesSettingsMeterReminders(
      process.env.REACT_APP_API_URL,
      authContext,
      data
    );
    if (myMessagesSettingsMeterRemindersData && myMessagesSettingsMeterRemindersData.success) {
      loadData({ field: 'essCustomerID', order: 'ASC' });
      props.onHide();
    } else {
      const message = 'API error calling: useralertprofile/items';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const saveSiteReference = async (siteId: number, customerSiteReference: string) => {
    const result: Common.IResult<string> = await putSiteReference(
      process.env.REACT_APP_API_URL,
      authContext,
      siteId,
      customerSiteReference
    );
    if (result && result.success) {
      return true;
    } else {
      const message = 'API error calling: putSiteReference';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
      return false;
    }
  };

  const handleSave = () => {
    let savedItems: { essSiteID: any; receiveReminder: boolean }[] = [];
    if (state.gridData) {
      state.gridData.forEach((item: any, index: number) => {
        const foundIndex = checkedItems.some(elem => elem === index);
        savedItems.push({ essSiteID: item.essSiteID, receiveReminder: foundIndex });
      });
    }
    putMyMessagesSettingsMeterReminders(savedItems);
  };

  const handleItemChange = (index: number) => {
    let items = checkedItems.slice(0, checkedItems.length);
    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);
    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }
    setCheckedItems(items);
  };

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const value = event.currentTarget.dataset.value || '';
    let details = sortDetails;
    details.field = value;
    if (details.order === 'ASC') {
      details.order = 'DESC';
    } else {
      details.order = 'ASC';
    }
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: details });
  };

  const handleSelectAll = (): void => {
    const select = !selectAll;
    setSelectAll(select);
    if (select) {
      let items: Array<number> = [];
      if (state.gridData) {
        state.gridData.map((item, index) => items.push(index));
      }
      setCheckedItems(items);
    } else {
      // unselect
      setCheckedItems([]);
    }
  };

  const handleSiteBlur = async (event: any, id: number, site: string) => {
    event.preventDefault();
    handleRowBlur(state.gridData as Array<any>, id, site);
    // let data = gridData;
    // TODO ????
    // setGridData(data);
  };

  const handleRowBlur = async (data: Array<any>, id: number, siteReference: string) => {
    let index: any = data.findIndex(element => element.essSiteID === id);
    if (data[index].customerSiteReference !== siteReference) {
      data[index].customerSiteReference = siteReference;
      const siteId = data[index].essSiteID;
      await saveSiteReference(siteId, siteReference);
    }
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const loadData = (sort?: Common.ISort) => {
    const essCustomerId = userDetail.essCustomerId;
    getMyMessagesSettings(essCustomerId, sort);
  };

  // const handleIsChecked = (data: any) => {
  //   let items: Array<number> = [];
  //   data.map((item: any, index: number) => (item.hasMeterReadReminder ? items.push(index) : null));
  //   setCheckedItems(items);
  // };

  const handlePagingChange = () => {
    if (selectAll) {
      handleSelectAll();
    }
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const messageRows = () =>
    state.gridData &&
    state.gridData.map((item: any, index: number) => (
      <React.Fragment key={item.id}>
        <RowSelector className='item'>
          <td className='select-item'>
            <Checkbox
              id={item.id}
              onChange={(event: any) => {
                handleItemChange(index);
              }}
              checked={isChecked(index)}
            />
          </td>
          <td>{item.essCustomerID}</td>
          <td>{item.essSiteID}</td>
          <td>{item.siteAddress}</td>
          <td className='primary'>{item.postCode}</td>
          <td className='site-name'>
            {/* TODO: Check if <SiteRefField /> can we used in this case */}
            <RowInputItem
              background={true}
              value={item.customerSiteReference}
              maxLength={20}
              placeholder='Edit'
              onHandleBlur={e => handleSiteBlur(e, item.essSiteID, e.target.value)}
            />
          </td>
        </RowSelector>
      </React.Fragment>
    ));

  const [listItemsContainerRef, setListItemsContainerRef] = useState();

  const onlistItemsContainerRefChange = useCallback((node: any) => {
    if (node !== null) {
      setListItemsContainerRef(node);
    }
  }, []);

  return (
    <>
      <MyMessagesSettingsModalWrapper>
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
          <ModalClose onClick={() => props.onHide()} />
        </Modal.Header>
        <Modal.Body>
          <p>{props.description}</p>

          {!state.isLoading && state.gridData && state.gridData.length === 0 && (
            <NoResults isDescriptionEnabled={false} />
          )}

          {state.gridData && state.gridData.length > 0 && (
            <>
              <div className='table-responsive table-fixed fixed-column columns-1' ref={onlistItemsContainerRefChange}>
                {listItemsContainerRef && (
                  <InfiniteScroll
                    dataLength={state.gridData.length}
                    next={handlePagingChange}
                    hasMore={state.hasMoreData}
                    loader={''}
                    scrollableTarget={listItemsContainerRef}
                    scrollThreshold={0.9}
                    className='message-settings'
                  >
                    <table className='table plain text-left messages-settings'>
                      <thead>
                        <tr>
                          <th scope='col'>
                            <Checkbox id='selectAllMessageSettings' onChange={handleSelectAll} checked={selectAll} />
                          </th>
                          <th scope='col' className='sort'>
                            <Sort
                              title='Cust ID'
                              dataValue={'essCustomerID'}
                              sortDetails={sortDetails}
                              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                handleSort(event);
                              }}
                            />
                          </th>
                          <th scope='col' className='sort'>
                            <Sort
                              title='Site ID'
                              dataValue={'essSiteID'}
                              sortDetails={sortDetails}
                              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                handleSort(event);
                              }}
                            />
                          </th>
                          <th scope='col' className='sort'>
                            <Sort
                              title='Address'
                              dataValue={'siteAddress'}
                              sortDetails={sortDetails}
                              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                handleSort(event);
                              }}
                            />
                          </th>
                          <th scope='col' className='primary'>
                            Site name
                          </th>
                          <th scope='col' className='primary'>
                            Site reference
                          </th>
                        </tr>
                      </thead>
                      <tbody>{messageRows()}</tbody>
                    </table>
                  </InfiniteScroll>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary
            route='/'
            title='Save'
            onClick={event => {
              event.preventDefault();
              handleSave();
            }}
            className='m-0'
          />
        </Modal.Footer>
      </MyMessagesSettingsModalWrapper>
    </>
  );
};

MyMessagesSettingsModal.defaultProps = defaultProps;

export default MyMessagesSettingsModal;
