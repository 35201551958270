import styled from 'styled-components';

import { DirectDebit } from '../icons';
import { respondTo } from '../../common/styles/mixins';
import { ButtonPrimary } from '../../common/components';
import { TagManager } from '../utils/analytics/TagManager';
import { isTpiUser } from '../../helpers/tpiUser';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  & > * {
    margin-right: auto;
  }

  ${respondTo.mobile`
     position: relative;

      svg {
        position: absolute;
        left: 80%;
      }
   `}
`;

const StyledButton = styled(ButtonPrimary)`
  width: 320px;
  display: block;
  margin: 15px 0 0 auto;
`;

const DirectDebitDraxWidget = () => {
  const email = 'majorbusinessenquiries@drax.com';

  const reportEvent = () => {
    if (isTpiUser()) { 
      TagManager.pushData({
        event: 'Download DD form (TPI)',
      });
    } else {
      TagManager.pushData({
        event: 'Download DD form (Customer)',
      });
    }
  };

  return (
    <div className='panel'>
      <div>
        <StyledWrapper>
          <h4>Direct Debit</h4>
          <DirectDebit />
        </StyledWrapper>
        <p>
          Download the Direct Debit form, fill in the relevant details and send the completed form to your Account
          manager at {email}. They’ll handle everything else.
        </p>
      </div>
      <div className='d-flex'>
        <div className='mt-auto ml-auto'>
          <StyledButton
            onClick={reportEvent}
            title='Download Direct Debit form'
            route='/files/dd-mandate-form.pdf'
            target='_blank'
          />
        </div>
      </div>
    </div>
  );
};

export default DirectDebitDraxWidget;
