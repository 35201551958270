import styled from 'styled-components';

import * as mixins from '../../common/styles/mixins';
import * as variables from '../../common/styles/variables';

export const ProcessStepsWrapper = styled.div`
  display: flex;
  //justify-content: space-around;
  //align-items: center;
  margin-bottom: ${variables.spacingLarge};

  // ${mixins.respondTo.tablet`
  //   padding: 0 5%;
  // `};

  // ${mixins.respondTo.desktop`
  //   padding: 0 10%;
  // `};
  // ${mixins.respondTo.desktopXl`
  //   padding: 0 20%;
  // `};
`;
