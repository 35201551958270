// Modules
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DateObject } from 'react-multi-date-picker';

// Types
import {
  EssAccountsTypes,
  IElecRegisterData,
  IGasRegisterData,
  IMeterItemData,
  ISiteData,
} from '../../../../types/account';
import { DATE_FORMAT } from '../../../../common/constants';

// Components
import { ButtonTerinary, Icon, Loading } from '../../../../common/components';
import { SubmitRegisterItem } from '../../../../common/components/SubmitRegisterItem';
import { SuccessSubmitModal } from '../SuccessSubmitModal/SuccessSubmitModal';
import { DateOptionModal } from '../DateOptionModal';
import { DatepickerModal } from '../DatepickerModal';
import { CheckDateModal } from '../CheckDateModal';

// Context
import { HomeContext, HomeContextProps } from '../../context/homeContext';
import { GlobalContext, GlobalDetailContextProps } from '../../../../context/globalContext/globalContext';

// Styles
import { MeterReadingDataWrapper } from './MeterReadingData.styles';
import { white } from '../../../../common/styles/colors';
import { iconSizeBase } from '../../../../common/styles/variables';

// Utils
import { formatDate } from '../../../utils/date/date';
import { TagManager } from '../../../utils/analytics/TagManager';

type MeterReadingData = {
  essAccountId: string | null;
  essAccounts: Api.IEssAccountData[];
  siteId: string | null;
  // sites: ISiteMeterDetail[];
  sites: ISiteData[];
  meterSerialNumber: string | null;
  meters: IMeterItemData[];
  meterRegisters: Array<IGasRegisterData | IElecRegisterData>;
  isLoading: boolean;
};

MeterReadingDataBlock.defaultProps = {
  meters: [],
  sites: [],
  essAccounts: [],
  meterRegisters: [],
  isLoading: false,
};

export function MeterReadingDataBlock(props: MeterReadingData) {
  const history = useHistory();

  // Context
  const { homeState } = useContext<HomeContextProps>(HomeContext);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  // State
  const [firstInputValue, setFirstInputValue] = React.useState('');
  const [secondInputValue, setSecondInputValue] = React.useState('');
  const [isShowingError, setIsShowingError] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);

  const [readDate, setReadDate] = React.useState('');

  const [showDateOptionModal, setShowDateOptionModal] = React.useState(false);
  const [showDatepickerModal, setShowDatepickerModal] = React.useState(false);
  const [showCheckDateModal, setShowCheckDateModal] = React.useState(false);

  // Props
  const { meterSerialNumber, meters, meterRegisters, isLoading } = props;

  // Handlers
  const resetWidgetExpandedState = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      meterWidgetExpanded: false,
    });
  };

  const submitReads = (date: string) => {
    // TODO: POST request here
    console.log('submitReads: ', date);

    setShowSuccessModal(true);
  };

  const handleSubmitClick = (e: any) => {
    e.preventDefault();
    TagManager.pushData({
      event: 'Submit meter read (Home page)',
    });
    // TODO: Submit meter readings
    if (meterRegisters.length) {
      // New values should be higher than last read values
      if (
        !firstInputValue ||
        (meterRegisters[1] && !secondInputValue) ||
        meterRegisters[0]?.lastRead >= parseFloat(firstInputValue) ||
        (meterRegisters[1] && meterRegisters[1]?.lastRead >= parseFloat(secondInputValue))
      ) {
        setIsShowingError(true);
      } else {
        setShowDateOptionModal(true);
      }
    }
    setShowDateOptionModal(true);
  };

  const onSuccessModalAppeared = () => {
    TagManager.pushData({
      event: 'Meter Read Successful (Home page)',
    });
  };

  const handleMeterReadsNavigation = (e: any) => {
    e.preventDefault();

    resetWidgetExpandedState();
    history.push('/home/meter-reads');
  };

  const clearState = () => {
    setFirstInputValue('');
    setSecondInputValue('');
    setIsShowingError(false);
  };

  const onSuccessModalClose = () => {
    setShowSuccessModal(false);
    resetWidgetExpandedState();
    clearState();
  };

  const handleSubmitForToday = () => {
    setShowDateOptionModal(false);
    submitReads(formatDate(new Date()));
  };

  const handleSubmitForNotToday = () => {
    setShowDateOptionModal(false);
    setShowDatepickerModal(true);
  };

  const handleDateSelection = (date: DateObject | DateObject[]) => {
    let dateString = '';

    if (!Array.isArray(date)) {
      dateString = date.format(DATE_FORMAT);
    }
    setReadDate(dateString);
    setShowDatepickerModal(false);
    setShowCheckDateModal(true);
  };

  const getKeyForRegister = (register: IGasRegisterData | IElecRegisterData) => {
    const gasRegister = register as IGasRegisterData;

    if (gasRegister.essGasMeterID) {
      return gasRegister.essGasMeterID;
    } else {
      const elecRegister = register as IElecRegisterData;

      return elecRegister.elecMeterRegisterID;
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (index === 0) {
      setFirstInputValue(e.target.value);
    } else if (index === 1) {
      setSecondInputValue(e.target.value);
    }
  };

  React.useEffect(() => {
    clearState();
  }, [meterRegisters]);

  const isMobileDropdownOpened = homeState.active && homeState.selectedCard === 'SubmitMeterReading';
  const isShowingReadingFields = meterSerialNumber && meters.length <= 2 && meterRegisters.length <= 2;
  let message: string = '';

  if (meters.length > 2) {
    message =
      'Your site has more than two associated meters. Please head to our ‘Submit meter reads’ page using the button below.';
  } else if (meterRegisters.length > 2) {
    message =
      'Your meter has more than two registers. Please head to our ‘Submit meter reads’ page using the button below.';
  }
  const selectedMeter = meters.find(meter => (meter.meterSerialNumber || meter.serialNumber) === meterSerialNumber);
  let meterProductIcon = '';
  let meterType = EssAccountsTypes.Gas;

  if (selectedMeter?.renewable) {
    meterType = EssAccountsTypes.Renewables;
    meterProductIcon = 'renewables';
  } else if (selectedMeter?.essElecMeterID) {
    meterType = EssAccountsTypes.Electricity;
    meterProductIcon = 'power';
  } else if (selectedMeter?.gasMeterID) {
    meterProductIcon = 'gas-02';
  }

  return (
    <MeterReadingDataWrapper
      className={`col-12 col-lg-6 px-0 px-lg-3 mt-4 d-md-flex justify-content-center position-relative ${
        !isMobileDropdownOpened ? 'd-none' : ''
      }`}
    >
      {isLoading ? (
        <Loading
          containerClassName='d-flex justify-content-center align-items-center'
          containerStyles={{ inset: 0, transform: 'none' }}
        />
      ) : (
        <>
          {!message && !meterSerialNumber && (
            <div className='d-flex justify-content-center align-items-center'>
              <h2>No meter selected</h2>
            </div>
          )}
          {message && (
            <div className='w-100 h-100 widget__message'>
              <p className='text-white'>{message}</p>
              <ButtonTerinary
                onClick={handleMeterReadsNavigation}
                className='meter__link'
                title='Submit meter page'
                route='/'
              />
            </div>
          )}
          {isShowingReadingFields && (
            <form className='d-flex-column col-12 reading__fields'>
              <div className='meter__reading'>
                <div className='d-flex'>
                  <Icon color={white} size={iconSizeBase} icon={meterProductIcon} />
                  <p className='mb-0 mb-md-3 font-weight-bold'>Meter serial number: {meterSerialNumber}</p>
                </div>
                <div className='pl-xl-4'>
                  {meterRegisters.map((register, index) => (
                    <SubmitRegisterItem
                      key={getKeyForRegister(register)}
                      meterType={meterType}
                      tooltipId={getKeyForRegister(register)}
                      description={register.description}
                      lastRead={register.lastRead}
                      lastReadDate={register.lastReadDate}
                      containerClassName='px-0 col-12 mt-4'
                      isDescriptionHidden={meterRegisters.length === 1}
                      value={index === 0 ? firstInputValue : secondInputValue}
                      onChange={e => handleInputChange(e, index)}
                    />
                  ))}
                </div>
              </div>
              <div className='px-0 pl-0 pl-xl-4 col-12 d-flex justify-content-end submit__meter__btn'>
                <div>
                  <ButtonTerinary onClick={handleSubmitClick} className='mb-0 col-xl-6 col-12' title='Submit meter' />
                </div>
              </div>
              {isShowingError && (
                <p className='pt-4'>
                  This format looks a bit different to our usual reads. Please check it's correct before you submit it
                </p>
              )}

              {showSuccessModal && <SuccessSubmitModal onClose={onSuccessModalClose} />}
              {showDateOptionModal && (
                <DateOptionModal
                  onClose={() => setShowDateOptionModal(false)}
                  onToday={handleSubmitForToday}
                  onNotToday={handleSubmitForNotToday}
                />
              )}
              {showDatepickerModal && <DatepickerModal onSelect={handleDateSelection} />}
              {showCheckDateModal && (
                <CheckDateModal
                  date={readDate}
                  onClose={() => setShowCheckDateModal(false)}
                  onSubmit={() => {
                    setShowCheckDateModal(false);
                    submitReads(readDate);
                  }}
                  onWrongDate={() => {
                    setReadDate('');
                    setShowCheckDateModal(false);
                    setShowDatepickerModal(true);
                  }}
                />
              )}
            </form>
          )}
        </>
      )}
    </MeterReadingDataWrapper>
  );
}
