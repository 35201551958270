import { useRouteMatch } from 'react-router-dom';

import { ProcessStepIndicatorMovingPremisesWrapper } from './ProcessStepIndicator.styles';

type ProcessStepIndicatorMovingPremisesProps = {
  path: string;
  step: number;
  label: string;
  visited: boolean;
};

export const ProcessStepIndicatorMovingPremises = ({
  path,
  step,
  label,
  visited,
}: ProcessStepIndicatorMovingPremisesProps) => {
  const match = useRouteMatch({ path, exact: true });

  return (
    <ProcessStepIndicatorMovingPremisesWrapper className={''}>
      <ul className={'arrow-steps'}>
        <li className={match ? 'step active' : `step ${visited ? 'visited' : ''}`}>
          <div className='numberCircle mr-2'>
            <p>{step}</p>
          </div>
          {label}
        </li>
      </ul>
    </ProcessStepIndicatorMovingPremisesWrapper>
  );
};
