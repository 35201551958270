import Modal from 'react-bootstrap/Modal';

import { ModalClose, Icon } from '../../common/components';
import { ContactInfoWrapper } from './GetQuoteModal.styles';

type GetQuoteModalProps = {
  show: boolean;
  onClose: () => void;
};

const defaultProps = {
  show: true,
  onClose: () => {},
};

export const GetQuoteModal = (props: GetQuoteModalProps) => {
  const { show, onClose } = props;

  return (
    <>
      <Modal animation={false} className={'mid-size-modal'} show={show} size={'sm'} backdrop='static' centered>
        <Modal.Header>
          <Modal.Title>Get a quote</Modal.Title>
          <ModalClose onClick={() => onClose()} />
        </Modal.Header>

        <Modal.Body>
          <p>Our sales team are on hand to offer competitive tariffs and to review your account options</p>

          <ContactInfoWrapper>
            <div className='info-row'>
              <Icon size={20} icon='headset' />
              <span>0845 330 0915</span>
            </div>

            <div className='info-row'>
              <Icon size={20} icon='envelope' />
              <span>sales@opusenergy.com</span>
            </div>
          </ContactInfoWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
};

GetQuoteModal.defaultProps = defaultProps;
