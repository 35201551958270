import styled from 'styled-components';

import * as variables from '../../common/styles/variables';

export const ContactPreferenceWrapper = styled.div`
  padding-top: ${variables.spacingSmall};
  padding-bottom: ${variables.spacingSmall};

  .table-wrapper {
    overflow: auto;
    width: 100%;
  }

  table {
    min-width: 500px;
  }

  td {
    padding: ${variables.spacingSmall};
    width: 20%;

    &:first-child {
      width: 40%;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;
