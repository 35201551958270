import styled from 'styled-components';

import * as variables from '../../common/styles/variables';

export const HowToGetHelpModalWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.text.secondary};
  }

  .contact-number {
    font-size: ${variables.fontSizeBase};
    font-weight: bold;
  }

  .contact-type {
    color: ${({ theme }) => theme.text.secondary};
  }
`;
