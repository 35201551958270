import React from 'react';

import { StyledLink } from './ButtonPrimary.styles';

type ButtonPrimaryProps = {
  style?: any;
  title: string;
  route?: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled: boolean;
  className?: string | undefined;
  tabIndex?: number;
  target?: string;
  outlined?: boolean;
  textOnly?: boolean;
  children?: React.ReactNode;
  borderRadiusType?: 'squared' | 'none';
};

const defaultProps = {
  route: '',
  title: '',
  onClick: undefined,
  disabled: false,
  outlined: false,
  textOnly: false,
  tabIndex: '',
};

const ButtonPrimary = (props: ButtonPrimaryProps) => {
  const requiredProps: { rel?: string } = {};
  const { borderRadiusType, ...linkProps } = props;

  if (props.target === '_blank') {
    requiredProps.rel = 'noopener noreferrer';
  }
  return (
    <StyledLink {...linkProps} {...requiredProps} to={props.route} onClick={props.onClick} title={''}>
      {props.title || props.children}
    </StyledLink>
  );
};

ButtonPrimary.defaultProps = defaultProps;

export { ButtonPrimary };
