import styled from 'styled-components';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const ScrollTopLink = styled.a`
  ${mixins.transition};
  z-index: ${variables.zIndexHighest};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.5em 1.5em;
  font-size: ${variables.fontSizeSmall};
  background-color: ${variables.transparentWhite};
  color: ${({ theme }) => theme.text.secondary};
  text-decoration: none;
  opacity: 0;

  &:hover {
    color: ${({ theme }) => theme.text.secondary};
  }

  svg {
    fill: ${props => props.theme.text.secondary};
  }

  &.visible {
    ${mixins.transition};
    opacity: 1;
  }
`;
