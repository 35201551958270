import { useContext, useEffect } from 'react';

import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { useAuthState } from '../../providers/authProvider';
import { getHildebrandRegister } from '../../common/api/hildebrandApi';

export const HildebrandRegister = () => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  useEffect(() => {
    if (userDetail.customerAccounts.length === 0) return;

    const fetchConsumptionRegister = async () => {
      let accountReferences: Array<string> = [];

      let accounts = userDetail.customerAccounts.map(x => x.essAccountID);
      accountReferences.push(...accounts);

      const result = await getHildebrandRegister(process.env.REACT_APP_API_URL, authContext, accountReferences);
    };

    fetchConsumptionRegister();

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.customerAccounts]);

  return null;
};
