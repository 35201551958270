// Modules
import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

// Components
import { ElecAndGasDetails } from './ElecAndGasDetails';
import { Loading, Sort, RowSelector, NoResults, SiteRefField, Icon } from '../../common/components';
import { StyledTitle } from '../../common/components';
// import { RenewableDetails } from './RenewableDetails';

// Utils
import { isDraxBrand } from '../../utils/common';
import { TagManager } from '../utils/analytics/TagManager';

// Styles
import { SiteDetailsWrapper } from './SiteDetailsGrid.styles';

// Api
import { globalApiParams } from '../../common/api/globals';

const SUPPLY_END = <Icon className='icon icon-status' size={20} icon='prohibited' />;
const OUT_OF_CONTRACT = <Icon className='icon icon-status' size={20} icon='notification-circle' />;

type SiteDetailsGridComponentProps = {
  gridData: Api.ISiteData[];
  handleSortChange: Function;
  handlePagingChange: Function;
  isLoading?: boolean;
  hasMoreData: boolean;
};

const defaultProps = {
  isLoading: true,
  gridData: [],
  hasMoreData: true,
};

const DEFAULT_SORT = {
  field: isDraxBrand ? 'site-reference' : 'essSiteID',
  order: 'DESC',
} as Common.ISort;

export const SiteDetailsGrid = (props: SiteDetailsGridComponentProps) => {
  const { gridData, isLoading } = props;
  const [sortDetails, setSortDetails] = useState<Common.ISort>(DEFAULT_SORT);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const scrollableTable = useRef<any>();

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }
  }, [props.gridData]);

  useEffect(() => {
    clearSelection();
    setSortDetails(DEFAULT_SORT);
  }, []);

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
  };

  const toggleRow = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    setExpandRow(!expandRow);
  };

  const handleRowSelect = (row: number | null) => {
    if (row !== undefined) {
      setSelectedRow(row);
      setExpandRow(false);

      TagManager.pushData({
        event: 'View Site Details',
      });
    }
  };

  const handleSiteRefBlur = (value: string) => {
    TagManager.pushData({
      event: 'Edit Site Reference (Site Details page)',
      value,
    });
  };

  const gridHeaders = () => {
    return (
      <thead>
        <tr>
          <th scope='col' className='sort'>
            <Sort
              title={`Site ${isDraxBrand ? 'reference' : 'ID'}`}
              dataValue={isDraxBrand ? 'site-reference' : 'essSiteID'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                handleSort(event, isDraxBrand ? 'site-reference' : 'essSiteID')
              }
            />
          </th>
          <th scope='col' className='sort'>
            <Sort
              title='Site address'
              dataValue={'SiteAddress'}
              sortDetails={sortDetails}
              enableSort={false}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'site-address')}
            />
          </th>
          <th scope='col' className='sort'>
            <Sort
              title={`${isDraxBrand ? 'Customer site' : 'Site'} reference`}
              dataValue={'customer-site-reference'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'customer-site-reference')}
            />
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const gridRows = () => {
    const rows: Api.ISiteData[] = gridData as Api.ISiteData[];
    const items = rows.map((item: Api.ISiteData, index: number) => {
      const isDisabled = item.outOfContract || !item.supplyActive;

      return (
        <React.Fragment key={index}>
          <RowSelector
            className={`item ${selectedRow === index ? 'selected' : ''} ${expandRow ? 'open' : ''} ${
              isDisabled ? 'disabled-row' : ''
            }`}
            onClick={() => {
              if (selectedRow !== index) {
                handleRowSelect(index);
              }
            }}
          >
            <td>
              <span>{isDraxBrand ? item.essSiteName : item.essSiteID}</span>
            </td>
            <td className='site-address-col'>
              <span>{item.siteAddress}</span>
            </td>
            <td>
              <SiteRefField
                onBlur={handleSiteRefBlur}
                siteId={item.essSiteID}
                readOnly={!item.supplyActive}
                siteReference={item.customerSiteReference}
              />
            </td>
            <td className='actions-wrapper text-right'>
              {!item.supplyActive && !item.outOfContract && SUPPLY_END}
              {item.supplyActive && item.outOfContract && OUT_OF_CONTRACT}
              {!item.supplyActive && item.outOfContract && SUPPLY_END}

              <a href='/' className='d-md-inline' onClick={(event: any) => toggleRow(event)}>
                {expandRow && selectedRow === index ? (
                  <Icon className='icon' size={20} icon='chevron-up' />
                ) : (
                  <Icon className='icon' size={20} icon='chevron-down' />
                )}
              </a>
            </td>
          </RowSelector>

          {expandRow && selectedRow === index && (
            <tr className={`sub-row ${selectedRow === index ? '' : ''} ${expandRow ? 'open' : ''}`}>
              <td colSpan={4} className='pt-0'>
                {/* TODO: show block based on available renewables or gas/elec */}
                <ElecAndGasDetails item={item} disabled={isDisabled} />
                {/* <RenewableDetails item={item} /> */}
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });

    return items;
  };

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>, typeId: string) => {
    clearSelection();
    let details = sortDetails;
    if (details.field === typeId) {
      details.order = details.order === 'ASC' ? 'DESC' : 'ASC';
      setSortDetails(details);
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }

    props.handleSortChange(details);
    event.preventDefault();
  };

  const handlePagingChange = () => {
    props.handlePagingChange();
  };

  return (
    <SiteDetailsWrapper>
      {!isLoading && gridData.length === 0 && <NoResults />}

      {gridData.length > 0 && (
        <>
          <StyledTitle fontSize='21px'>Your site(s)</StyledTitle>

          <InfiniteScroll
            dataLength={gridData.length}
            next={handlePagingChange}
            hasMore={props.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <div
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1'
            >
              <table className='list table plain'>
                <>
                  {gridHeaders()}
                  <tbody>{gridRows()}</tbody>
                </>
              </table>
            </div>
          </InfiniteScroll>
        </>
      )}

      {isLoading && <Loading overlay />}
    </SiteDetailsWrapper>
  );
};

SiteDetailsGrid.defaultProps = defaultProps;
