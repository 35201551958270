// Styles
import { useContext } from 'react';
import { UserDetailContext, UserDetailContextProps } from '../../../../context/userDetail/userDetailContext';
import { OurInsight } from '../../MarketInsights/OurInsight/OurInsight';
import { InsightHomeWrapper } from './InsightHome.styles';
import { AccountHealth } from '../../../health/AccountHealth/AccountHealth';
import { MeterHealth } from '../../../health/MeterHealth/MeterHealth';
import { LoAHealth } from '../../../health/LoAHealth/LoAHealth';
import { SubmitMeterHealth } from '../../../health/SubmitMeterHealth/SubmitMeterHealth';

type InsightHomeProps = {
  customerUser: boolean;
  tpiUser: boolean;
};

const defaultProps = {
  customerUser: false,
  tpiUser: false,
};

export const InsightHome = (props: InsightHomeProps) => {
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  return (
    <InsightHomeWrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <h1 className='pb-1 text-center'>Account Overview</h1>
            <div className='row pb-2 justify-content-center'>
              <div className='col-xs-12 col-md-12 col-lg-6 col-xl-3'>
                <div className='pb-2'>
                  <AccountHealth></AccountHealth>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6 col-xl-3'>
                <div className='pb-2'>
                  <MeterHealth></MeterHealth>
                </div>
              </div>
              {/* Hide SubmitMeterReading if 'HH' metering point type only */}
              {!userDetail.customersMeteringPointTypes?.hhMeteringPointTypeOnly && (
                <div className='col-xs-12 col-md-12 col-lg-6 col-xl-3'>
                  <div className='pb-2'>
                    <SubmitMeterHealth></SubmitMeterHealth>
                  </div>
                </div>
              )}
              {props.tpiUser && (
                <div className='col-xs-12 col-md-12 col-lg-6 col-xl-3'>
                  <div className='pb-2'>
                    <LoAHealth></LoAHealth>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-sm-12'>
            <OurInsight></OurInsight>
          </div>
        </div>
      </div>
    </InsightHomeWrapper>
  );
};

InsightHome.defaultProps = defaultProps;
