import styled from 'styled-components';

import {
  lightGrey,
  white,
  successLight,
  nepalBlue,
  goldSand,
  error,
  midGrey,
  hydroBlue,
  darkBlue,
} from '../../../common/styles/colors';
import { fontSizeMedium, fontSizeLarge, inputRadius } from '../../../common/styles/variables';

export const MeterReadingsRowContent = styled.td`
  background-color: ${lightGrey};

  .serial-number {
    font-size: ${fontSizeLarge};
  }

  .registers__wrapper {
    .register__item {
      max-width: 435px;
    }
  }

  .date,
  .status {
    font-size: ${fontSizeMedium};
  }

  .status {
    min-width: 103px;
    width: 100%;
    padding: 3px 15px;
    background: ${midGrey};
    color: ${white};
    border-radius: ${inputRadius};

    &--valid {
      background: ${successLight};
    }
    &--pending {
      background: ${goldSand};
    }
    &--failed {
      background: ${error};
    }
    &--estimate {
      background: ${nepalBlue};
    }
  }

  .sub-content-wrapper {
    border-top: 1px solid ${midGrey};
  }

  .action-row {
    display: flex;
    justify-content: flex-end;

    a {
      margin: 0;
    }
  }

  .read-history-expand {
    width: 400px;
  }

  .blue-text {
    color: ${hydroBlue};
  }

  .top-divider-border {
    border-top: solid ${midGrey} 1px;
  }

  .bottom-divider-border td {
    border-bottom: solid ${midGrey} 1px !important;
    padding-top: 0;
  }

  .readings-submitrow {
    td {
      padding-bottom: 0 !important;
    }
  }

  .readings-newread {
    padding-top: 30px;
  }

  .readings-historyrow {
    td {
      padding-top: 0 !important;
    }
  }

  .readingMessage {
    color: ${darkBlue};
    width: 250px;
    white-space: pre-wrap;
    float: right;
    padding-top: 10px;
  }
`;
