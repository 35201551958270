import { useState } from 'react';
import { DateObject } from 'react-multi-date-picker';

export const useNewMessageModalValidation = () => {
  const [subjectError, setSubjectError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [userTypeError, setUserTypeError] = useState<string>('');
  const [priorityError, setPriorityError] = useState<string>('');
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [internalLinkError, setInternalLinkError] = useState<boolean>(false);
  const [externalLinkError, setExternalLinkError] = useState<string>('');

  const validateSubject = (subject: string) => {
    if (!subject.length) {
      setSubjectError('Required field');
    } else {
      setSubjectError('');
    }
  };

  const validateMessage = (message: string) => {
    if (!message.length) {
      setMessageError('Required field');
    } else {
      setMessageError('');
    }
  };

  const validateUserType = (userType: string) => {
    if (!userType.length || userType === 'Select') {
      setUserTypeError('Required field');
    } else {
      setUserTypeError('');
    }
  };

  const validatePriority = (priority: string) => {
    if (!priority.length) {
      setPriorityError('Required field');
    } else {
      setPriorityError('');
    }
  };

  const validateStartDate = (startDate: DateObject) => {
    if (!startDate) {
      setStartDateError('Required field');
    } else {
      setStartDateError('');
    }
  };

  const validateEndDate = (endDate: DateObject) => {
    if (!endDate) {
      setEndDateError('Required field');
    } else {
      setEndDateError('');
    }
  };

  const validateInternalLink = (internalLink: string) => {
    if (internalLink === 'Select' || internalLink === '') {
      setInternalLinkError(true);
    } else {
      setInternalLinkError(false);
    }
  };

  const validateExternalLink = (externalLink: string) => {
    if (!externalLink.length) {
      setExternalLinkError('Required field');
    } else {
      setExternalLinkError('');
    }
  };

  const clearAllValidation = () => {
    setSubjectError('');
    setMessageError('');
    setUserTypeError('');
    setPriorityError('');
    setStartDateError('');
    setEndDateError('');
    setInternalLinkError(false);
    setExternalLinkError('');
  };

  return {
    subjectError,
    messageError,
    userTypeError,
    priorityError,
    startDateError,
    endDateError,
    internalLinkError,
    externalLinkError,
    validateSubject,
    validateMessage,
    validateUserType,
    validatePriority,
    validateStartDate,
    validateEndDate,
    validateInternalLink,
    validateExternalLink,
    clearAllValidation,
  };
};
