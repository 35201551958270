import { useContext } from 'react';

import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { CorporateUsersDirectDebit } from './CorporateUsersDirectDebit';
import { SMECustomerDirectDebit } from './SMECustomerDirectDebit';
import { DirectDebitWrapper } from './DirectDebit.styles';
import { StyledTitle } from '../../common/components';

const DirectDebit = () => {
  const { isCorpBroker, isCorpCustomer, isSMECustomer } = useContext<UserDetailContextProps>(UserDetailContext);

  return (
    <DirectDebitWrapper>
      <StyledTitle>Direct Debit</StyledTitle>
      {(isCorpBroker() || isCorpCustomer()) && <CorporateUsersDirectDebit />}
      {isSMECustomer() && <SMECustomerDirectDebit />}
    </DirectDebitWrapper>
  );
};
export default DirectDebit;
