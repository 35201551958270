import styled from 'styled-components';
import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const DropDownWrapper = styled.div`
  ${mixins.clearfix};
  font-weight: 400;
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  text-overflow: ellipsis;
  color: ${props => props.theme.dropdown.color};
  border: 1px solid ${props => props.theme.dropdown.border};
  background-color: ${props => props.theme.dropdown.bg};
  cursor: pointer;

  .chevron {
    position: absolute;
    right: 0;
    top: 0.3em;
  }

  *,
  *:after,
  *:before {
    ${mixins.borderBox};
    padding: 0;
    margin: 0;
  }

  ::selection {
    background: transparent;
  }

  ::-moz-selection {
    background: transparent;
  }

  .wrapperdropdown {
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    background-position-x: 100%;
    padding-right: 25px;
  }

  .wrapperdropdown .dropdown {
    ${mixins.scrollbars};
    z-index: ${variables.zIndexHighest};
    position: absolute;
    top: 35px;
    left: -16px;
    right: -16px;
    border-radius: 0 0 5px 5px;
    border-top: none;
    border-bottom: none;
    list-style: none;
    max-height: 0;
    overflow-y: auto;
  }

  .wrapperdropdown .dropdown li {
    ${mixins.transition};
    background: ${colors.lightGrey};

    &:hover {
      ${mixins.transition};
      background: ${colors.white};
      color: ${props => props.theme.text.secondary};

      .itemicon {
        ${mixins.transition};
        fill: ${props => props.theme.text.secondary};
      }
    }

    &.selected {
      background-color: ${colors.white};
      color: ${props => props.theme.text.secondary};

      .itemicon {
        fill: ${props => props.theme.text.secondary};
      }
    }

    .dropdownitem {
      padding: 10px;
    }
  }

  .wrapperdropdown .dropdown li .dropdownitem {
    display: block;
    text-decoration: none;
    border-bottom: 1px solid ${colors.inputGrey};
    line-height: 1.5;
  }

  .wrapperdropdown .dropdown li:last-of-type .dropdownitem {
    border: none;
  }

  .selecteditem {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      line-height: 1;
      padding-left: 5px;
    }
  }

  .wrapperdropdown.active {
    border-radius: 5px 5px 0 0;
    box-shadow: none;
    border-bottom: none;
  }

  .wrapperdropdown.active:after {
    border-color: #82d1ff transparent;
  }

  .wrapperdropdown.active .dropdown {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    max-height: 400px;
    z-index: ${variables.zIndexHighest * 10};
  }

  &.plain {
    padding: 0;
    border: none;
    color: ${props => props.theme.text.secondary};
    background-color: transparent;

    .itemicon {
      position: relative;
      left: 0.5em;
    }

    .wrapperdropdown .dropdown {
      ${mixins.boxShadowPrimary};
      border: 0;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      li {
        background-color: ${colors.white};

        &:first-child {
          border-top: 1px solid ${colors.lightGrey};
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        &:hover,
        &.active {
          background-color: ${props => props.theme.primary.bg};
          color: ${colors.white};
        }

        &.selected {
          background-color: ${props => props.theme.primary.bg};
          color: ${colors.white};

          .itemicon {
            fill: ${colors.white};
          }
        }
      }

      .dropdownitem {
        border: 0;
      }
    }

    .selecteditem span {
      padding-left: 0;
    }
  }

  &.filter {
    .itemicon {
      display: none !important;
    }
  }
`;
