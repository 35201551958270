import { css } from 'styled-components';
import * as variables from '../variables';
import * as colors from '../colors';

export const listStyles = css`
  /* Lists */
  .no-list {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      padding: 1.5rem;

      &.bordered {
        border-bottom: 1px solid ${colors.midGrey};
      }

      &:last-child {
        border-bottom: none;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;

        p {
          margin: 0;
        }
      }

      .icon {
        background-color: ${({ theme }) => theme.icons.default};
        padding: 20px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    h4 {
      color: ${colors.baseBlue};
    }

    .heading {
      font-weight: bold;
      font-size: ${variables.fontSizeLarge};
      color: ${colors.baseBlue};

      a {
        color: ${colors.trueblack};
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .pale-bg {
      background-color: ${colors.lightGrey};
    }
  }

  .normal-list {
    li {
      position: relative;
      left: ${variables.spacingMedium};

      &:before {
        color: ${({ theme }) => theme.text.secondary};
        content: '•';
        font-size: ${variables.iconSizeSmall};
        position: absolute;
        left: -27px;
        top: -7px;
      }
    }
  }
  /* end listStyles */
`;
