import { useState, useEffect } from 'react';
import IcomoonReact from 'icomoon-react';

import { ScrollTopLink } from './ScrollTopStyles';
import iconSet from '../../../assets/selection.json';

const ScrollTop = () => {
  const [visible, setVisible] = useState(false);
  const windowObj: any = window;
  useEffect(() => {
    if (windowObj !== null) {
      const f = (event: any) => {
        return windowObj.onscroll(event);
      };
      window.addEventListener('scroll', () => {
        window.scrollY > 300 ? setVisible(!visible) : setVisible(visible);
      });
      return () => {
        window.removeEventListener('scroll', f);
      };
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowObj]);

  return (
    <ScrollTopLink className={`scrolltop ${visible ? 'visible' : ''}`} href='#top'>
      <IcomoonReact iconSet={iconSet} size={25} icon='arrow-up' />
      Back to top
    </ScrollTopLink>
  );
};

export default ScrollTop;
