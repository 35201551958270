import { useFormContext, UseFormReturn } from 'react-hook-form';

import { getValueByPath } from '../../../utils/getValueByPath';
import { Wrapper, Input, Error } from './FormInput.styles';

type FormInputProps = {
  name: string;
  params?: any;
  label?: string;
  hidden?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
};

export const ConnectForm = ({ children }: { children: Function }) => {
  const methods = useFormContext();

  return children({ ...methods });
};

export const FormInput = (props: FormInputProps) => {
  const { name, params, placeholder, label, disabled, hidden } = props;

  return (
    <ConnectForm>
      {({ register, formState: { errors } }: UseFormReturn<any>) => {
        const error = getValueByPath(errors, name);

        const hasError = !!error || (props.error && props.error.length > 0) ? true : false;

        return (
          <Wrapper className={hidden ? 'hidden' : ''}>
            {label && <div className='label'>{label}</div>}
            <Input {...register(name, params)} hasError={hasError} placeholder={placeholder} disabled={disabled} />

            {!!error && (
              <Error>
                {error?.type === 'pattern' && error?.message}
                {error?.type === 'required' && 'Required'}
              </Error>
            )}
          </Wrapper>
        );
      }}
    </ConnectForm>
  );
};
