import { useContext, useEffect, useState } from 'react';

import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { useAuthState } from '../../../providers/authProvider';
import { getEBillingStatus, setEBillingAcceptance } from '../../../common/api/homeApi';
import { EbillConfirmationModal } from '../EbillConfirmation/EbillConfirmationModal';

const EBILL_DISPLAYS_KEY = 'ebillDisplays';
const MAX_DISPLAYS = 3;

type EbillModalProcessProps = {
  onEBillProcessEnd: () => void;
};

export const EbillModalProcess = ({ onEBillProcessEnd }: EbillModalProcessProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [eBillModalOpened, setEBillModalOpened] = useState(false);
  const timesDisplayed = parseInt(localStorage.getItem(EBILL_DISPLAYS_KEY) || '0');
  const authContext = useAuthState();

  const handleEBillStatus = async () => {
    try {
      const { data, success }: Common.IResult<Api.IEBillStatus[]> = await getEBillingStatus(
        process.env.REACT_APP_API_URL,
        authContext,
        userDetail.essCustomerId
      );

      if (data && success) {
        const eBillAccepted = data[0].eBilling;

        if (!eBillAccepted && timesDisplayed < MAX_DISPLAYS) {
          localStorage.setItem(EBILL_DISPLAYS_KEY, `${timesDisplayed + 1}`);
          setEBillModalOpened(true);
        } else {
          onEBillProcessEnd();
        }
      }
    } catch {
      onEBillProcessEnd();
    }
  };

  useEffect(() => {
    handleEBillStatus();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEbillSubmit = async (isAccepted: boolean) => {
    if (isAccepted) {
      const result: Common.IResult<any> = await setEBillingAcceptance(
        process.env.REACT_APP_API_URL,
        authContext,
        userDetail.essCustomerId
      );

      if (result) {
        localStorage.removeItem(EBILL_DISPLAYS_KEY);
        setEBillModalOpened(false);
        onEBillProcessEnd();
      }
    } else {
      setEBillModalOpened(false);
      onEBillProcessEnd();
    }
  };

  return <EbillConfirmationModal show={eBillModalOpened} onSubmit={handleEbillSubmit} />;
};
