// Modules
import { Route, Switch } from 'react-router-dom';

// Components
import { Callback } from '../components/auth/callback';
import { Logout } from '../components/auth/logout';
import { LogoutCallback } from '../components/auth/logoutCallback';
import { PrivateRoute } from './privateRoute';
import { SilentRenew } from '../components/auth/silentRenew';
import { Home } from '../Home';
import { Expiring } from '../components/expiring/Expiring';
import NoMatch from '../components/nomatch';
import { Admin } from '../components/admin/Admin';

export const RoutesAuthenticate = (
  <>
    {process.env.REACT_APP_ADMIN_SITE === 'true' && (
      <Switch>
        <Route path='/' component={Admin} />
        <Route path='*' component={NoMatch} />
      </Switch>
    )}

    {process.env.REACT_APP_ADMIN_SITE !== 'true' && (
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/signout' component={Logout} />
        <Route exact path='/signin-oidc' component={Callback} />
        <Route exact path='/silentrenew' component={SilentRenew} />
        <Route exact path='/logout/callback' component={LogoutCallback} />

        <PrivateRoute path='/expiring' component={Expiring} />
        <PrivateRoute path='/home' component={Home} />
        <PrivateRoute path='/' component={Home} />

        <Route path='*' component={NoMatch} />

        {process.env.REACT_APP_ADMIN_SITE === 'true' && <Route path='/' component={Admin} />}
      </Switch>
    )}
  </>
);
