// Modules
import { useContext, useEffect, useState } from 'react';

// Components
import { Icon, Loading } from '../../../common/components';
import { iconsToUse } from '../../../common/styles/themes';

// Styles
import { HealthWrapper } from './Health.styles';

// Context
import { HomeContext, HomeContextProps } from '../../home/context/homeContext';
import { HealthItem } from '../HealthItem/HealthItem';
import { RoutingToTypes } from '../../search/SearchActions/SearchActions';

export type IHealthCheck = {
  type: string;
  display: string;
  route: string;
  redirect: RoutingToTypes;
  status: 'loading' | 'healthy' | 'notHealthy';
  url: string;
  response?: Array<any>;
};

type HealthProps = {
  widgetKey: 'Health' | 'Meter' | 'LoA';
  type: string;
  healthChecks: Array<IHealthCheck>;
  checkingText: string;
  healthyText: string;
  onMoreInfoClick: Function;
  customers: Array<any>;
};

const defaultProps = {
  widgetKey: 'Health',
  type: 'AccountHealth',
  healthChecks: [],
  checkingText: 'Checking',
  healthyText: 'Account health check complete',
  customers: [],
};

export const Health = (props: HealthProps) => {
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);

  const [status, setStatus] = useState<'loading' | 'healthy' | 'notHealthy'>('loading');

  useEffect(() => {
    const indexLoading = props.healthChecks.findIndex((healthCheck: IHealthCheck) => healthCheck.status === 'loading');
    if (indexLoading >= 0) {
      setStatus('loading');
      return;
    }

    const indexNotHealthy = props.healthChecks.findIndex(
      (healthCheck: IHealthCheck) => healthCheck.status === 'notHealthy'
    );
    if (indexNotHealthy >= 0) {
      setStatus('notHealthy');
      return;
    }

    setStatus('healthy');
  }, [props.healthChecks]);

  function toggleActive() {
    let active = true;
    if (homeState.selectedCard === props.widgetKey) active = !homeState.active;

    setHomeState({ selectedCard: props.widgetKey, active: active });
  }

  const isDropdownOpened = homeState.active && homeState.selectedCard === props.widgetKey;

  const healthItems = () => {
    return (
      <>
        {status === 'loading' && <div className='spinner'>{<Loading message={props.checkingText} nowait />}</div>}

        {status === 'healthy' && (
          <div className='mt-auto mb-auto'>
            <HealthItem status={status} visible={status === 'healthy'} text={props.healthyText}></HealthItem>
          </div>
        )}

        {status === 'notHealthy' && (
          <div className='mt-auto mb-auto'>
            {props.healthChecks?.map((healthCheck: IHealthCheck, index: number) => (
              <div key={index} className='mt-2'>
                <HealthItem
                  visible={status === 'notHealthy' && healthCheck.status === 'notHealthy'}
                  status={healthCheck.status}
                  text={healthCheck.display}
                  route={healthCheck.route}
                  response={healthCheck.response}
                  customers={props.customers}
                  onMoreInfoClick={() => {
                    props.onMoreInfoClick(healthCheck);
                  }}
                ></HealthItem>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <HealthWrapper>
      <div className={`d-flex`}>
        <div className='p-2'>
          <div className='d-flex justify-content-center'>
            {props.type === 'AccountHealth' && <Icon icon={iconsToUse.accountHealth} size={140} />}
            {props.type === 'MeterHealth' && <Icon icon={iconsToUse.meterHealth} size={140} />}
            {props.type === 'LoAHealth' && <Icon icon={iconsToUse.loaHealth} size={140} />}
          </div>

          <div className='mt-auto mb-auto d-md-none'>{healthItems()}</div>
        </div>
        <div className='mr-auto d-none d-md-flex flex-column flex-grow-1 overflow-hidden'>{healthItems()}</div>
      </div>
    </HealthWrapper>
  );
};

Health.defaultProps = defaultProps;
