// Folder paths ============================================================

export const stylesFolder = '/src/common/styles';

//Typography ===============================================================

export const fontRegular = "'avertaregular', helvetica, sans-serif";
export const fontThin = "'avertalight', helvetica, sans-serif";
export const fontBold = "'avertabold',helvetica, sans-serif";
export const fontOpenSans = `'Open Sans', ${fontRegular}`;
export const fontUnit = 1;
export const fontSizeBase = `${fontUnit}rem`;
export const fontSizeMedium = `${fontUnit - 0.125}rem`;
export const fontSizeSmall = `${fontUnit - 0.25}rem`;
export const fontSizeLarge = `${fontUnit * 1.2}rem`;
export const fontSizeLargeX = `${fontUnit * 1.6}rem`;
export const fontSizeLargeXL = '2.375rem';
export const fontSizeLargeXXL = '3rem';
export const fontSizeXLarge = '3.2rem';
export const fontSizeTitle = '38px';

//Icons =====================================================================

export const iconSizeBase = '32px';
export const iconSizeSmall = '25px';
export const iconSizeExtraSmall = '20px';

//Border radius ============================================================

export const btnBorderRadiusSquare = '10px';
export const btnBorderRadiusRounded = '24px';

//Spacing =====================================================================

export const defaultSpacer = '10px';
export const globalRadius = '20px';
export const smallRadius = '16px';
export const inputRadius = '8px';
export const spacer = 0.9735; // 15px in ems;
export const spacingXSmall = `${spacer / 3}em`;
export const spacingSmall = `${spacer}em`;
export const spacingMedium = `${spacer * 2}em`;
export const spacingLarge = `${spacer * 2.6}em`;
export const spacingXLarge = `${spacer * 3.3}em`;
export const menuWidth = '350px';

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

export const breakpoints = {
  min: 0,
  mobileXSm: 360,
  mobileSm: 390,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  desktopXl: 1200,
  desktopXXl: 1600,
};

//Colours
export const transparentWhite = 'rgba(255, 255, 255, 0.8)';

//z-index =====================================================================
export const zIndexLow = -1;
export const zIndexNormal = 0;
export const zIndexHigh = 1;
export const zIndexHighest = zIndexHigh * 10;
export const zIndexSpinner = 1100;

export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;
export const fontWeightExtraBold = 800;
