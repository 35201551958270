import { Route } from 'react-router-dom';
import { TPI_ACCESSID, TPI_CUSTOMERS } from '../common/constants';

import { AuthConsumer } from '../providers/authProvider';
import { isDraxBrand, isOpusBrand } from '../utils/common';

export const PrivateRoute = ({ component, ...rest }: any) => {
  const renderFn = (Component: any) => (props: any) =>
    (
      <AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
          if (!!Component && isAuthenticated()) {
            return <Component {...props} />;
          } else {
            localStorage.removeItem(TPI_CUSTOMERS);
            localStorage.removeItem(TPI_ACCESSID);

            signinRedirect();
            return (
              <>
                {isDraxBrand && <div className='p-4'>Loading Drax Energy...</div>}
                {isOpusBrand && <div className='p-4'>Loading SSP...</div>}
              </>
            );
          }
        }}
      </AuthConsumer>
    );

  return <Route {...rest} render={renderFn(component)} />;
};
