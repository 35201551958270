import React, { ReactElement, useEffect, useState } from 'react';
import IcomoonReact from 'icomoon-react';

import { SearchActionsWrapper } from './SearchActionsStyles';
import iconSet from '../../../assets/selection.json';
import { Link } from 'react-router-dom';

export enum RoutingToTypes {
  'invoices' = 'invoices',
  'siteDetails' = 'siteDetails',
  'contacts' = 'contacts',
  'contracts' = 'contracts',
  'meterReads' = 'meterReads',
  'movingPremises' = 'movingPremises',
  'lettersOfAuthority' = 'lettersOfAuthority',
  'mpanDetails' = 'mpanDetails',
  undefined = '',
}

type SearchActionsProps = {
  type: Common.searchItemType;
  item: any;
  handleSelected: Function;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultProps = {
  item: {},
};

const SearchActions = (props: SearchActionsProps): ReactElement => {
  const [invoicesBtnEnabled, setInvoicesBtnEnabled] = useState<boolean>(false);
  const [siteDetailsBtnEnabled, setSiteDetailsBtnEnabled] = useState<boolean>(false);
  const [contactsBtnEnabled, setContactsBtnEnabled] = useState<boolean>(false);
  const [meterReadsBtnEnabled, setMeterReadsBtnEnabled] = useState<boolean>(false);
  const [movingPremisesBtnEnabled, setMovingPremisesBtnEnabled] = useState<boolean>(false);

  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setInvoicesBtnEnabled(false);
    setSiteDetailsBtnEnabled(false);
    setContactsBtnEnabled(false);
    setMeterReadsBtnEnabled(false);
    setMovingPremisesBtnEnabled(false);

    switch (props.type) {
      case 'account': {
        setInvoicesBtnEnabled(true);
        break;
      }
      case 'invoice': {
        setInvoicesBtnEnabled(true);
        break;
      }

      case 'site': {
        setInvoicesBtnEnabled(true);
        setSiteDetailsBtnEnabled(true);
        setMeterReadsBtnEnabled(true);
        setMovingPremisesBtnEnabled(true);
        break;
      }
      case 'mpan': {
        setInvoicesBtnEnabled(true);
        setSiteDetailsBtnEnabled(true);
        setMeterReadsBtnEnabled(true);
        setMovingPremisesBtnEnabled(true);
        break;
      }
      case 'mprn': {
        setInvoicesBtnEnabled(true);
        setSiteDetailsBtnEnabled(true);
        setMeterReadsBtnEnabled(true);
        setMovingPremisesBtnEnabled(true);
        break;
      }
      case 'statement': {
        setInvoicesBtnEnabled(true);
        break;
      }
    }
  }, [props.type]);

  function toggleActive() {
    // e.stopPropagation();
    setActive(!active);
  }

  // function toggleOff() {
  //   setActive(false);
  // }

  function handleSelected(routingTo: RoutingToTypes) {
    props.handleSelected(props.item, routingTo);
  }

  return (
    <SearchActionsWrapper {...props}>
      {/* dropdown for mobile view */}
      {/* tabIndex={0} onBlur={toggleOff} */}
      <div className='menu-dropdown' onClick={toggleActive}>
        <IcomoonReact
          className='itemicon chevron'
          iconSet={iconSet}
          size={15}
          icon={active ? 'chevron-up' : 'chevron-down'}
        />
        <div className={active ? 'active-dropdown' : 'hidden-dropdown'}>
          <div className='menu-list row'>
            {invoicesBtnEnabled && (
              <div className='col-6 item'>
                <Link to='/home/invoices' onClick={() => handleSelected(RoutingToTypes.invoices)}>
                  Invoices
                </Link>
              </div>
            )}
            {siteDetailsBtnEnabled && (
              <div className='col-6 item'>
                <Link to='/home/site-details' onClick={() => handleSelected(RoutingToTypes.siteDetails)}>
                  Site Details
                </Link>
              </div>
            )}
            {contactsBtnEnabled && (
              <div className='col-6 item'>
                <Link to='/home/contacts' onClick={() => handleSelected(RoutingToTypes.contacts)}>
                  Contacts
                </Link>
              </div>
            )}
            {meterReadsBtnEnabled && (
              <div className='col-6 item'>
                <Link to='/home/meter-reads' onClick={() => handleSelected(RoutingToTypes.meterReads)}>
                  Meter Reads
                </Link>
              </div>
            )}
            {movingPremisesBtnEnabled && (
              <div className='col-6 item'>
                <Link to='/home/moving-premises' onClick={() => handleSelected(RoutingToTypes.movingPremises)}>
                  Moving Premises
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* horizontal menu for desktop view */}
      <div className='container menu-items'>
        <div className='row'>
          {invoicesBtnEnabled && (
            <div className='col'>
              <Link to='/home/invoices' onMouseDown={() => handleSelected(RoutingToTypes.invoices)}>
                Invoices
              </Link>
            </div>
          )}
          {siteDetailsBtnEnabled && (
            <div className='col'>
              <Link to='/home/site-details' onMouseDown={() => handleSelected(RoutingToTypes.siteDetails)}>
                Site Details
              </Link>
            </div>
          )}
          {contactsBtnEnabled && (
            <div className='col'>
              <Link to='/home/contacts' onMouseDown={() => handleSelected(RoutingToTypes.contacts)}>
                Contacts
              </Link>
            </div>
          )}
          {meterReadsBtnEnabled && (
            <div className='col'>
              <Link to='/home/meter-reads' onMouseDown={() => handleSelected(RoutingToTypes.meterReads)}>
                Meter Reads
              </Link>
            </div>
          )}
          {movingPremisesBtnEnabled && (
            <div className='col'>
              <Link to='/home/moving-premises' onMouseDown={() => handleSelected(RoutingToTypes.movingPremises)}>
                Moving Premises
              </Link>
            </div>
          )}
        </div>
      </div>
    </SearchActionsWrapper>
  );
};

SearchActions.defaultProps = defaultProps;

export default SearchActions;
