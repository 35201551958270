import { NavigationTemplateProps } from './NavigationTemplateProps';
import { BRANDS } from '../../../consts';

export const smeBrokerNavigation = ({ brand }: NavigationTemplateProps) => [
  {
    text: 'Messages',
    link: '/messages',
    icon: 'menuMessages',
    classname: '',
    isVisible: true,
  },
  {
    text: 'Reports',
    link: '/reports',
    icon: 'menuReports',
    classname: '',
  },
  {
    text: 'Create contract',
    link: '/create-contract',
    icon: 'menuContract',
    classname: '',
  },
  {
    text: 'In progress',
    link: '/in-progress',
    icon: 'menuInProgress',
    classname: '',
  },
];
