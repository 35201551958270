import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { ButtonPrimary, FormInput, FormSelect } from '../../../common/components';
import { FormRowWrapper, ActionRowWrapper } from './PrimaryContactForm.styles';
import { ContactDetails } from './ContactDetails';

type PrimaryContactFormProps = {
  onSubmit: (data: any) => void;
  initialData?: ContactDetails;
};

type Inputs = {
  title: { label: string; value: string };
  firstname: string;
  surname: string;
  mobile: string;
  phoneNumber: string;
};

const TITLES = ['Mr', 'Mrs', 'Miss', 'Ms', 'Other'];
const PHONE_PATTERN = /^\d{11}$/;

const PrimaryContactForm = ({ onSubmit, initialData }: PrimaryContactFormProps) => {
  const methods = useForm<Inputs>({ defaultValues: initialData || {} });
  const titleOptions = TITLES.map(title => ({ value: title, label: title }));
  const submitHandler: SubmitHandler<Inputs> = data => {
    onSubmit({
      ...data,
      title: data.title.value,
    });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <FormRowWrapper>
          <div className='titleWrapper'>
            <FormSelect name='title' placeholder='Title' options={titleOptions} params={{ required: true }} />
          </div>

          <div className='nameWrapper'>
            <FormInput name='firstname' placeholder='First name' params={{ required: true }} />
          </div>

          <div className='nameWrapper'>
            <FormInput name='surname' placeholder='Last name' params={{ required: true }} />
          </div>
        </FormRowWrapper>

        <FormRowWrapper>
          <FormInput
            name='mobile'
            placeholder='Mobile'
            params={{
              required: true,
              pattern: { value: PHONE_PATTERN },
            }}
          />
        </FormRowWrapper>

        <FormRowWrapper>
          <FormInput
            name='phoneNumber'
            placeholder='Landline'
            params={{
              required: true,
              pattern: { value: PHONE_PATTERN },
            }}
          />
        </FormRowWrapper>

        <ActionRowWrapper>
          <ButtonPrimary
            title='Submit'
            onClick={event => {
              event.preventDefault();
              methods.handleSubmit(submitHandler)();
            }}
            className='m-0 submit-btn'
          ></ButtonPrimary>
        </ActionRowWrapper>
      </form>
    </FormProvider>
  );
};

export default PrimaryContactForm;
