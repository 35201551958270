import styled from 'styled-components';

import { respondTo } from '../../../common/styles/mixins';
import * as variables from '../../../common/styles/variables';

export const FormRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${variables.spacingMedium};

  .titleWrapper,
  .nameWrapper {
    margin-bottom: ${variables.spacingMedium};
  }

  .nameWrapper:last-child {
    margin-bottom: 0;
  }

  ${respondTo.mobile`
    flex-direction: row;

    .titleWrapper {
      width: 20%;
      margin-bottom: 0;
    }

    .nameWrapper {
      width: 40%;
      padding-left: ${variables.spacingMedium};
      margin-bottom: 0;
    }
  `};
`;

export const ActionRowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .submit-btn {
    min-width: 12.5rem;
    max-width: 100%;
  }
`;
