import { createGlobalStyle } from 'styled-components/macro';
import * as variables from './variables';
import * as mixins from './mixins';
import * as colors from './colors';
import { buttonStyles } from './global/button.styles';
import { modalStyles } from './global/modal.styles';
import { widgetStyles } from './global/widget.styles';
import { tableStyles } from './global/table.styles';
import { sortStyles } from './global/sort.styles';
import { animationStyles } from './global/animation.styles';
import { datePickerStyles } from './global/datePicker.styles';
import { filterReset } from './global/filterReset';
import { chartStyles } from './global/chart.styles';
import { whiteTooltipStyles, blackTooltipStyles } from './global/tooltip.styles';
import { flexXXLStyles } from './global/flexXXL.styles';

export default createGlobalStyle`
  *:not(a):not(input):not(button) {
    ${mixins.noTouchSelect};
  }

  html {
    overflow-y: scroll;
  }

  body {
    ${mixins.scrollbars};
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: 400;
    color: ${colors.darkBlue} !important;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${({ theme }) => theme.title.fontWeight};
  }

  ::selection {
    background-color: ${colors.darkBlue};
    color: ${colors.white};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  #root {
    height: 100%;
  }

  a {
    color: ${colors.oxfordBlue};
    ${mixins.transition};
    text-decoration: underline;

    &:hover {
      color: ${colors.oxfordBlue};
      ${mixins.transition};
      text-decoration: none;
    }

    &.plain {
      text-decoration: none;
    }
  }

  .disabled {
    opacity: .4;
    cursor: default;
  }

  .link-primary,
  .primary {
    color: ${({ theme }) => theme.text.secondary} !important;
  }

  // Utils
  .hidden {
    display: none !important;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .error {
    color: ${colors.error};
  }

  .text-bold {
    font-weight: bold;
  }

  .text-regular {
    font-weight: 400;
  }

  .font-large {
    font-size: ${variables.fontSizeLarge};
  }

  .font-xlarge {
    font-size: ${variables.fontSizeXLarge};
  }

  .font-medium {
    font-size: ${variables.fontSizeMedium};
  }

  .font-small {
    font-size: ${variables.fontSizeSmall};
  }

  .font-open-sans {
    font-family: ${variables.fontOpenSans};
  }

  .min-max {
    font-size: calc(28px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  }

  .text-white {
    color: ${colors.white};
  }

  .white-background {
    background-color: ${colors.white};
  }

  .pale-background {
    background-color: ${colors.lightGrey};
  }

  .h-100vh {
    height: 100vh;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .border-none {
    border: none;
  }

  .basis-auto {
    flex-basis: auto;
  }

  // Flex XXL breakpoint styles
  ${flexXXLStyles}

  // Buttons
  ${buttonStyles}

  // Modal
  ${modalStyles}

  // Widgets
  ${widgetStyles}

  // tables
  ${tableStyles}

  // sort
  ${sortStyles}

  // datePicker
  ${datePickerStyles}

  // animation
  ${animationStyles}

  // filter reset
  ${filterReset}

  // chart
  ${chartStyles}

  // bootstrap
  ${whiteTooltipStyles}
  ${blackTooltipStyles}

  .additional-buttons {
    ${mixins.respondTo.desktop`
      margin-left: auto;
    `};
  }

  .additional-buttons-spacing {
    ${mixins.respondTo.mobile`
      margin-top: 15px;
      margin-bottom: 15px;
    `};
  }
`;
