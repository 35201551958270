import { useRouteMatch } from 'react-router-dom';

import { ProcessStepIndicatorWrapper } from './ProcessStepIndicator.styles';

type ProcessStepIndicatorProps = {
  path: string;
  step: string;
  label: string;
};

export const ProcessStepIndicator = ({ path, step, label }: ProcessStepIndicatorProps) => {
  const match = useRouteMatch({ path, exact: true });

  return (
    <ProcessStepIndicatorWrapper className={match ? 'active' : ''}>
      <h4 className='min-max'>{step}</h4>
      <p>{label}</p>
    </ProcessStepIndicatorWrapper>
  );
};
