import styled from 'styled-components';
import * as mixins from '../../../common/styles/mixins';
import * as variables from '../../../common/styles/variables';
import { hydroBlue, draxDarkBlue, white } from '../../../common/styles/colors';
import { respondTo } from '../../../common/styles/mixins';

export const BrokerTpiText = {
  header: 'Download data for your active customers',
  headerBtn: '',
  BrokerTpiTextBtn: 'Download Broker/TPI report',
};

export const BrokerTpiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${variables.spacingMedium};

  ul.details {
    list-style-type: disc;
    padding-left: 30px;
  }

  ${mixins.boxShadowPrimary};

  background-color: ${({ theme }) => theme.bg.secondary};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingMedium};
`;

export const DownloadButtonsWrapper = styled.div`
  a {
    margin: 20px 0;
    font-weight: 600;
    text-decoration: none;
    color: ${white};
    background: ${hydroBlue};
    padding: 30px;

    ${respondTo.tablet`
      margin: 0;
    `}

    &:hover {
      text-decoration: none;
      color: white;
      background: ${draxDarkBlue};
    }

    & > svg {
      margin-right: 10px !important;
      fill: ${white};
      transform: rotate(180deg);
    }
  }
`;
