import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { ButtonPrimary, Checkbox, Icon, StyledTitle } from '../../common/components';
import TableFilter, { TableFilterOption } from '../../common/components/TableFilter/TableFilter';
import { Download } from '../icons';
import { ModalTemplate } from '../mymessages/modalTemplates/ModalTemplate';
import TpiUserActionsPanel from './TpiUserActionsPanel';
import { TpiUserDetailsHeaderWrapper, TpiUserDetailsHeaderSectionWrapper } from './TpiUserDetails.styles';

// import chroma from 'chroma-js';

// import { ColourOption, colourOptions } from '../data';
import Select, { StylesConfig } from 'react-select';

type TpiUserDetailsHeaderProps = {
  tpiDescription: string;
  tpiReference: string;
  status: string;
  contactName: string;
  contactTelephone: string;
  contactEmail: string;
  managerName: string;
  mobileNumber: string;
  options: Common.IExpandedTpiUserOption[];
  tpiAdminSuperUser: boolean;
  gridData: Array<Api.ITpiUser>;
  checkedItems: Array<number>;
  selectedRow: number | null;
  error: string;
  showModal: boolean;

  handleEnableTPI: Function;
  handleDisableTPI: Function;
  handleDownloadTPIInfoReport: Function;
  handleDownloadTPIUserReport: Function;
  handleStatusChange: Function;
  handleTPIChanged: Function;
  handleAddUser: Function;
  handleDisable: Function;
  handleEnable: Function;
  handleGeneratePasswordReset: Function;
};

const defaultProps = {
  tpiDescription: '',
  tpiReference: '',
  status: '',
  contactName: '',
  contactTelephone: '',
  contactEmail: '',
  managerName: '',
  mobileNumber: '',
  options: {},
  tpiAdminSuperUser: false,
  gridData: [],
  checkedItems: [],
  selectedRow: null,
  error: '',
  showModal: false,

  handleTPIChanged: undefined,
  handleEnableTPI: undefined,
  handleDisableTPI: undefined,
  handleDownloadTPIInfoReport: undefined,
  handleDownloadTPIUserReport: undefined,
  handleStatusChange: undefined,
  handleAddUser: undefined,
  handleDisable: undefined,
  handleEnable: undefined,
  handleGeneratePasswordReset: undefined,
};

export interface INewTpiUser {
  email: string;
  firstname: string;
  surname: string;
}

export const TpiUserDetailsHeader = (props: TpiUserDetailsHeaderProps) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedItem, setSelectedItem] = useState<Common.IExpandedTpiUserOption>();
  const [includeOnlyEnabledTPIs, setIncludeOnlyEnabledTPIs] = useState<boolean>(true);

  const [error, setError] = useState(props.error);

  // Modal
  const [modalName, setModalName] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => setShow(false);

  const gridStatuses: TableFilterOption[] = [
    { value: 'All', label: 'All' },
    { value: 'Enabled', label: 'Enabled' },
    { value: 'Locked', label: 'Locked' },
    { value: 'Disabled', label: 'Disabled' },
    { value: 'Invited', label: 'Invited' },
  ];

  useEffect(() => {
    setError(props.error);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  useEffect(() => {
    setShow(props.showModal);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showModal]);

  // Handlers
  const handleStatusChange = (el: TableFilterOption) => {
    props.handleStatusChange(el);
  };

  const handleShowModal = (event: any) => {
    setError('');

    setModalName('AddTpiUserModal');
    setShow(true);
  };

  const handleAddUserShowModal = (event: any) => {
    handleShowModal(event);
  };

  const handleAddUser = (item: INewTpiUser) => {
    props.handleAddUser(item);
  };

  const handleDisable = (item: any) => {
    props.handleDisable(item);
  };

  const handleEnable = (item: any) => {
    props.handleEnable(item);
  };

  const handleGeneratePasswordReset = (item: any) => {
    props.handleGeneratePasswordReset(item);
  };

  const handleDownloadTPIInfoReport = () => {
    props.handleDownloadTPIInfoReport(selectedItem?.value, includeOnlyEnabledTPIs);
  };

  const handleDownloadTPIUserReport = () => {
    props.handleDownloadTPIUserReport(selectedItem?.value);
  };

  const handleTpiChange = (item: any) => {
    if (item) {
      setSelectedValue(item.value);
      setSelectedItem(item);
      props.handleTPIChanged(item);
    } else {
      // Clear
      setSelectedValue('');
      setSelectedItem(undefined);
      props.handleTPIChanged(undefined);
    }
  };

  const handleIncludeOnlyEnabledTPIs = () => {
    setIncludeOnlyEnabledTPIs(!includeOnlyEnabledTPIs);
  };

  const statusContent = (enabled = true) => ({
    ':after': {
      content: enabled ? '"Enabled"' : '"Disabled"',
      display: 'flex',
      alignItems: 'right',
      justifyContent: 'right',
      marginTop: '-25px',
    },
  });

  const selectStyles: StylesConfig<Common.IExpandedTpiUserOption> = {
    control: (styles: any) => ({
      ...styles,

      height: 50,
      minHeight: 50,

      backgroundColor: 'white',
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.enabled ? 'black' : '#ccc',
        ...statusContent(data.enabled),
      };
    },
    singleValue: (styles: any, { data }) => ({ ...styles, ...statusContent(data.enabled) }),
  };

  return (
    <TpiUserDetailsHeaderWrapper>
      <div className='row pb-3'>
        <div className='col-md-6'>
          <div className='d-flex justify-content-start'>
            <div>
              <StyledTitle className='pl-2'>TPI</StyledTitle>
            </div>
            <div className='pl-4 dropdown'>
              <ReactSelect
                autoFocus={false}
                isClearable={true}
                blurInputOnSelect={true}
                placeholder={'Select TPI'}
                value={selectedItem}
                styles={selectStyles}
                options={props.options}
                onChange={handleTpiChange}
              />
            </div>
          </div>
        </div>
        <div className='col-md-6 pt-1'>
          <div className='d-flex justify-content-end pr-4'>
            {props.tpiAdminSuperUser && (
              <div className='pt-2 pr-3'>
                {selectedItem?.enabled && (
                  <ButtonPrimary
                    route='#'
                    className={'btndisabletpi'}
                    title={'Disable TPI'}
                    disabled={!selectedItem?.enabled}
                    onClick={() => props.handleDisableTPI(selectedItem?.value)}
                  />
                )}
                {(!selectedItem || !selectedItem?.enabled) && (
                  <ButtonPrimary
                    route='#'
                    className={'btnenabletpi'}
                    title={'Enable TPI'}
                    disabled={!selectedItem || selectedItem?.enabled}
                    onClick={() => props.handleEnableTPI(selectedItem?.value)}
                  />
                )}
              </div>
            )}
            {props.tpiAdminSuperUser && (
              <div>
                <div className='row'>
                  <div className='pt-2'>
                    <ButtonPrimary className={'btnDownloadReport'} onClick={handleDownloadTPIInfoReport} route='#'>
                      <Icon icon={Download} size={18} className='mr-2' />
                      TPI Info report
                    </ButtonPrimary>
                  </div>

                  <div className='btnDownloaduserReport'>
                    <ButtonPrimary className={'btnDownloadReport'} onClick={handleDownloadTPIUserReport} route='#'>
                      <Icon icon={Download} size={18} className='mr-2' />
                      TPI User report
                    </ButtonPrimary>
                  </div>
                </div>
                <div className='row pt-3 justify-content-end'>
                  <Checkbox
                    id={'chbIncludeOnlyEnabledTPIs'}
                    label={"Include only enabled TPI's in report"}
                    onChange={handleIncludeOnlyEnabledTPIs}
                    checked={includeOnlyEnabledTPIs}
                  ></Checkbox>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <TpiUserDetailsHeaderSectionWrapper>
        <div className='row'>
          <div className='col'>
            <div className='title'>TPI Name:</div>
            <div>{props?.tpiDescription}</div>
          </div>
          <div className='col'>
            <div className='title'>TPI Ref: </div>
            <span>{props?.tpiReference}</span>
          </div>
          <div className='col'>
            <div className='title'>Status: </div>
            <span>{props?.status}</span>
          </div>
        </div>
      </TpiUserDetailsHeaderSectionWrapper>

      <TpiUserDetailsHeaderSectionWrapper>
        <div className='row'>
          <div className='col'>
            <div className='title'>Contact Name: </div>
            <div>{props?.contactName}</div>
          </div>
          <div className='col'>
            <span className='title'>Tel Number: </span>
            <span>{props?.contactTelephone}</span>
          </div>
          <div className='col'>
            <span className='title'>Email: </span>
            <span>{props?.contactEmail}</span>
          </div>
        </div>
      </TpiUserDetailsHeaderSectionWrapper>

      <div className='title'>
        <h4>TPI Users</h4>
      </div>

      <div className='row'>
        <div className='col-md-6 mb-4 mt-4 filter'>
          <div>Filter by Status</div>
          <div className='d-flex'>
            <TableFilter
              options={gridStatuses}
              isClearable={false}
              isSearchable={false}
              defaultValue={gridStatuses[0]}
              onChange={handleStatusChange}
            />
          </div>
        </div>
        <div className='col-lg-6'>
          <TpiUserActionsPanel
            selectedTpi={selectedItem}
            gridData={props.gridData}
            checkedItems={props.checkedItems}
            selectedRow={props.selectedRow}
            handleAddUser={handleAddUserShowModal}
            handleDisable={handleDisable}
            handleEnable={handleEnable}
            handleGeneratePasswordReset={handleGeneratePasswordReset}
          />
        </div>
      </div>
      <ModalTemplate name={modalName} show={show} onHide={handleClose} handleConfirm={handleAddUser} error={error} />
    </TpiUserDetailsHeaderWrapper>
  );
};

TpiUserDetailsHeader.defaultProps = defaultProps;

export default TpiUserDetailsHeader;
