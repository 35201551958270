import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import { ButtonPrimary, Icon } from '../../../common/components';
import moment from 'moment';
import { downloadHHreport } from '../../../common/api/consumptionApi';
import { useAuthState } from '../../../providers/authProvider';
import { TagManager } from '../../utils/analytics/TagManager';

type HalfHourlyDataSuccessModalProps = {
  show: boolean;
  onHide: () => void;
  onError: () => void;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  show: true,
};

const HalfHourlyDataSuccessModalWrapper = styled.div``;

export const HalfHourlyDataSuccessModal = (props: HalfHourlyDataSuccessModalProps) => {
  const authContext = useAuthState();

  const obj = props.messagevalues.json;

  const requestId = obj.RequestId;
  const accountReference = obj.AccountReference;
  const requestDateTime = moment(obj.RequestDateTime);

  const title = 'Half-hourly data success';
  const messageTop = `The data you requested for account ${accountReference} on ${requestDateTime.format(
    'DD MMM YYYY'
  )} is now ready to download.`;
  const messageBottom = 'Choose your file format below.';

  const handleDownloadXML = (e: any) => {
    e.preventDefault();

    TagManager.pushData({
      event: 'Download HH data xml',
    });

    const result = downloadHHreport(process.env.REACT_APP_API_URL, authContext, requestId, 'XML');
    result
      .then((result: any) => {
        props.onHide();
      })
      .catch((error: any) => {
        props.onError();
      });
  };

  const handleDownloadCSV = async (e: any) => {
    e.preventDefault();

    TagManager.pushData({
      event: 'Download HH data csv',
    });

    const result = downloadHHreport(process.env.REACT_APP_API_URL, authContext, requestId, 'CSV');
    result
      .then((result: any) => {
        props.onHide();
      })
      .catch((error: any) => {
        props.onError();
      });
  };

  return (
    <HalfHourlyDataSuccessModalWrapper>
      <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
        <Modal.Title>
          <span className='pr-4'>
            <Icon color={colors.draxBtn} size={50} icon='download2' />
          </span>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
        <p>{messageTop}</p>
        <p>{messageBottom}</p>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <div className='d-flex justify-content-end'>
          <ButtonPrimary className='m-0' title='XML' onClick={handleDownloadXML} />
          <ButtonPrimary className='ml-2' title='CSV' onClick={handleDownloadCSV} />
        </div>
      </Modal.Footer>
    </HalfHourlyDataSuccessModalWrapper>
  );
};

HalfHourlyDataSuccessModal.defaultProps = defaultProps;
