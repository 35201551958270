import Modal from 'react-bootstrap/Modal';

import { BulkInvoicesReady } from './BulkInvoicesReady';
import MeterReadReminder from './MeterReadReminder';
import { MeterExpired } from './MeterExpired';
import { Ebill } from './Ebill';
import { StatementReady } from './Statement';
import TenderReady from './TenderReady';
import { ContractSubmitted } from './ContractSubmitted';
import { BulkAcceptanceReady } from './BulkAcceptanceReady';
import DeleteModal from './DeleteModal';
import MyMessagesSettingsModal from './MyMessagesSettingsModal';
import ErrorModal from './ErrorModal';
import HowToGetHelpModal from './HowToGetHelpModal';
import RevokeUserModal from './RevokeUserModal';
import AddUserModal from './AddUserModal';
import { MeterReadUploadSuccess } from './MeterReadUploadSuccess';
import { MeterReadUploadFailure } from './MeterReadUploadFailure';
import AddTpiUserModal from './AddTpiUserModal';
import { HealthCheckModal } from './HealthCheckModal';
import HelpSupportModal from './HelpSupportModal';
import { BulkMessageModal } from './BulkMessageModal';
import { HalfHourlyDataSuccessModal } from './HalfHourlyDataSuccessModal';
import { HalfHourlyDataFailureModal } from './HalfHourlyDataFailureModal';
import { HalfHourlyDownloadErrorModal } from './HalfHourlyDownloadErrorModal';
import { TpiDataSuccessModal } from './TpiBroker/TpiDataSuccessModal';
import { TpiDataFailureModal } from './TpiBroker/TpiDataFailureModal';

type ModalTemplateProps = {
  className: string;
  title?: string;
  message?: string;
  fixed?: boolean;
  modalSize: 'sm' | 'lg' | 'xl' | undefined;
  centered?: boolean;
  onHide: () => void;
  onError: () => void;
  show: boolean;
  name: string;
  messagevalues: Messages.IMessagesValues;
  multiple?: boolean;
  handleConfirm: Function;
  handleMarkAsRead: Function;
  error: string;
};

const defaultProps = {
  className: 'custom-modal',
  message: '',
  onHide: undefined,
  onError: undefined,
  show: false,
  modalSize: 'md',
  centered: true,
  name: '',
  messagevalues: {},
  multiple: false,
  handleConfirm: undefined,
  handleMarkAsRead: undefined,
  error: '',
};

export const ModalTemplate = (props: ModalTemplateProps) => {
  const getCustomClassName = (name: string) => {
    if (name === 'MyMessagesSettingsModal') {
      return 'mymessages-settings-modal';
    } else if (name === 'AddUserModal') {
      return 'user-modal';
    }
    return '';
  };
  const getModalTemplate = (name: string) => {
    if (props.name === 'BulkInvoicesReady') {
      return <BulkInvoicesReady {...props} />;
    } else if (props.name === 'MeterReadReminder') {
      return <MeterReadReminder {...props} />;
    } else if (props.name === 'MeterExpired') {
      return <MeterExpired {...props} />;
    } else if (props.name === 'Ebill') {
      return <Ebill {...props} />;
    } else if (props.name === 'Statement') {
      return <StatementReady {...props} />;
    } else if (props.name === 'TenderReady') {
      return <TenderReady {...props} />;
    } else if (props.name === 'ContractsSubmitted') {
      return <ContractSubmitted {...props} />;
    } else if (props.name === 'BulkAcceptanceReady') {
      return <BulkAcceptanceReady {...props} />;
    } else if (name === 'MyMessagesSettingsModal') {
      return <MyMessagesSettingsModal {...props} />;
    } else if (name === 'DeleteModal') {
      return <DeleteModal {...props} />;
    } else if (name === 'HowToGetHelpModal') {
      return <HowToGetHelpModal {...props} />;
    } else if (name === 'RevokeUserModal') {
      return <RevokeUserModal {...props} />;
    } else if (name === 'AddUserModal') {
      return <AddUserModal {...props} />;
    } else if (name === 'AddTpiUserModal') {
      return <AddTpiUserModal {...props} />;
    } else if (name === 'MeterReadSuccess') {
      return <MeterReadUploadSuccess {...props} />;
    } else if (name === 'MeterReadFailure') {
      return <MeterReadUploadFailure {...props} />;
    } else if (name === 'HealthCheckModal') {
      return <HealthCheckModal {...props} />;
    } else if (name === 'HelpSupportModal') {
      return <HelpSupportModal {...props} />;
    } else if (name === 'BulkMessageModal') {
      return <BulkMessageModal {...props} />;
    } else if (name === 'HalfHourlyDataSuccess') {
      return <HalfHourlyDataSuccessModal {...props} />;
    } else if (name === 'HalfHourlyDataFailure') {
      return <HalfHourlyDataFailureModal {...props} />;
    } else if (name === 'HalfHourlyDownloadError') {
      return <HalfHourlyDownloadErrorModal {...props} />;
    } else if (name === 'TpiReportDataSuccess') {
      return <TpiDataSuccessModal {...props} />;
    } else if (name === 'TpiReportDataFailure') {
      return <TpiDataFailureModal {...props} />;
    } else {
      return <ErrorModal {...props} />;
    }
  };
  return (
    <>
      <Modal
        animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
        className={`${props.className} ${getCustomClassName(props.name)}`}
        show={props.show}
        onHide={props.onHide}
        size={props.modalSize}
        centered={props.centered}
        name={props.name}
        messagevalues={JSON.stringify(props.messagevalues)}
        multiple={props.multiple}
      >
        {getModalTemplate(props.name)}
      </Modal>
    </>
  );
};

ModalTemplate.defaultProps = defaultProps;
