import { useRef, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary } from '../../../../common/components';

type TimeOutModalProps = {
  message?: string;
  modalSize: 'sm' | 'lg' | 'xl' | undefined;
  onHide: () => void;
  onKeepAlive: Function;
  onTimeout: Function;
  show: boolean;
  countdown: number;
};

const defaultProps = {
  message:
    "We haven't seen any activity from you in the past 30 minutes. For your account security you will shortly be signed out.",
  onHide: undefined,
  onKeepAlive: undefined,
  onTimeout: undefined,
  show: false,
  modalSize: 'lg',
  countdown: 30,
};

export const TimeOut = (props: TimeOutModalProps) => {
  const intervalPointer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [count, setCount] = useState<number>(props.countdown);

  useEffect(() => {
    let seconds = props.countdown;
    setCount(seconds);
    intervalPointer.current = setInterval(function () {
      seconds--;
      setCount(seconds);

      if (seconds <= 0) {
        clearInterval(intervalPointer.current as unknown as number);
        props.onTimeout();
      }
    }, 1000);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeepAlive = () => {
    clearInterval(intervalPointer.current as unknown as number);
    props.onKeepAlive();
  };

  return (
    <>
      <Modal
        animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
        className={'custom-modal mid-size-modal'}
        show={props.show}
        onHide={props.onHide}
        size={props.modalSize}
        centered
      >
        <Modal.Header>
          <Modal.Title>We’ll sign you out in {count} seconds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary
            route='/'
            title='Keep me signed in'
            onClick={event => {
              event.preventDefault();
              onKeepAlive();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

TimeOut.defaultProps = defaultProps;

export default TimeOut;
