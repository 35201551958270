// Modules
import { useState } from 'react';
// Styles
import ServiceUpdateHealth, { ExpandedDetails, UpdateType } from './ServiceUpdateHealth';
type ServiceUpdatesProps = {
  messages: Array<ServiceUpdateType>;
};
type ServiceUpdateType = {
  page: string;
  type: UpdateType;
  message: string;
};
const defaultProps = {
  messages: [],
};
const ServiceUpdates = (props: ServiceUpdatesProps) => {
  const [expandedType, setExpandedType] = useState<ExpandedDetails>({ type: undefined, expanded: false });
  const messages = props.messages;
  const highIssues = messages.filter((message: ServiceUpdateType) => message.type === 'High');
  const mediumIssues = messages.filter((message: ServiceUpdateType) => message.type === 'Medium');
  const handleHealthClick = (type: any) => {
    setExpandedType(type);
  };
  return (
    <>
      {messages && (
        <>
          <h5 className='pb-2 font-weight-bold' style={{ textDecoration: 'underline' }}>
            My Drax Energy - Portal status
          </h5>
          <ServiceUpdateHealth messages={messages} type='NoIssues'></ServiceUpdateHealth>
          <ServiceUpdateHealth
            messages={mediumIssues}
            type='Medium'
            handleHealthClick={handleHealthClick}
            expandedType={expandedType}
            className={`${highIssues.length > 0 && mediumIssues.length > 0 && 'pb-2'}`}
          ></ServiceUpdateHealth>
          <ServiceUpdateHealth
            messages={highIssues}
            type='High'
            handleHealthClick={handleHealthClick}
            expandedType={expandedType}
          ></ServiceUpdateHealth>
        </>
      )}
    </>
  );
};
ServiceUpdates.defaultProps = defaultProps;
export default ServiceUpdates;
