import styled from 'styled-components';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';

export const SubmitMeterReadingText = {
  header: 'Submit meter reading',
  headerBtn: 'Submit meter reading',
  para: 'The energy generated across all your sites in the previous quarter.',
  selectAccount: 'Select account',
  selectSite: 'Select a site',
  selectMeter: 'Select a meter',
  selectMpan: 'Select an MPAN',
};

export const SubmitMeterReadingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(100vw - 30px);
  width: 100%;

  .submitMeterReading {
    & > p {
      margin-bottom: 22px;
    }

    .bulk__upload__link {
      color: ${({ theme }) => theme.text.secondary};
      font-weight: 600;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }

      & > span {
        margin-right: 7px;
      }
    }
  }

  .toggleSubmitMeterReadingMobile {
    z-index: ${variables.zIndexHighest};
    max-height: 55px;
    top: 0;
    left: 0;

    svg.chevron {
      right: 15px !important;
    }
  }

  .dropdown {
    margin-bottom: 20px;
  }
`;
