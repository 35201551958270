// Modules
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';

// Context
import { useAuthState } from '../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../../reducer/gridReducer/gridReducer';

// Types
import { EssAccountsTypes } from '../../../types/account';
import { RoutingToTypes } from '../../search/SearchActions/SearchActions';

// Api
import { MetersFilterObject, getMeteringPoints, getCustomerMeterReads } from '../../../common/api/meterReadsApi';

// Components
import { MeterReadingsRow } from '../MeterReadingsRow';
import { GridFilter } from '../../gridfilter/GridFilter';
import { FilterAdditionalItems } from './FilterAdditionalItems';
import { DownloadForm } from './SubmitMeterDownloadForm/SubmitMeterDownloadForm';
import {
  Loading,
  NoResults,
  TableHeader,
  StyledTitle,
  FilterRowWrapper,
  GridTypeSelector,
  Icon,
} from '../../../common/components';

// Styles
import { MeterReadsGridWrapper } from '../MeterReadsGrid.styles';

// Utils
import { TagManager } from '../../utils/analytics/TagManager';
import { getInitialType } from '../../../utils/getInitialTypes';
import { isDraxBrand } from '../../../utils/common';
import { formatSites, formatMpans, getActiveMeterRegisters } from '../helper';
import { globalApiParams } from '../../../common/api/globals';
import { getSelectedCustomerId } from '../../../helpers/customer';
import { iconsToUse } from '../../../common/styles/themes';
import * as variables from '../../../common/styles/variables';

const draxHeaders = [
  {
    title: '',
    dataValue: 'warningIcon',
    enableSort: false,
  },
  {
    title: 'MPAN',
    dataValue: 'MpanCore',
    enableSort: true,
  },
  {
    title: 'Site reference',
    dataValue: 'SiteReference', // TODO: this filed key should be updated
    enableSort: true,
  },
  {
    title: 'Site name',
    dataValue: 'site-name',
    enableSort: false,
  },
  {
    title: 'Site address',
    dataValue: 'site-address',
    enableSort: false,
  },
  {
    title: '',
  },
];

const opusHeaders = [
  {
    title: 'Site',
    dataValue: 'CustomerSiteReference',
  },
  {
    title: 'Address',
    dataValue: 'siteAddress',
  },
  {
    title: 'MPAN/MPRN',
    dataValue: 'mpan',
  },
  {
    title: '',
  },
];

const SubmitMeterReadings = () => {
  // Context
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const authContext = useAuthState();

  // State
  const [sortDetails] = useState<Common.ISort>({ field: 'MpanCore', order: 'ASC' });
  const [gridTypes, setGridTypes] = useState<EssAccountsTypes[]>();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [meterRegisters, setMeterRegisters] = useState<Array<Api.IMeterRead>>([]);
  const [isWarningIcon, setIsWarningIcon] = useState<boolean>(false);

  const scrollableTable = useRef<any>();

  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(userDetail.hasElectricity, userDetail.hasGas),
    data: {},
    initialSortField: '',
    initialSortOrder: 'DESC',
    currentSortField: '',
    currentSortOrder: 'DESC',
    hasMoreData: true,
    isLoading: false,
    selectedCustomerId: getSelectedCustomerId(userDetail, true),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  // When navigating directly to url
  useEffect(() => {
    if (userDetail.essCustomerId) {
      initialState.currentType = getInitialType(userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable);
      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable]);

  useEffect(() => {
    if (!state.selectedCustomerId) return;
    loadSitesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedCustomerId]);

  const loadSitesData = async () => {
    const filters: MetersFilterObject = {
      'hh-or-nhh': 'NHH',
    };

    const sites = await getMeteringPoints(
      process.env.REACT_APP_API_URL,
      authContext,
      state.selectedCustomerId,
      globalApiParams.maxFilterPageSize,
      state.page,
      'Active',
      undefined,
      filters
    );

    const payload = {
      essCustomerId: state.selectedCustomerId,
      data: {
        customerFilters: [],
        accountFilters: [],
        sitesFilters: formatSites(sites),
        mpansFilters: formatMpans(sites),
      },
    };

    dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload });
  };

  useEffect(() => {
    // Ignore if we are entering component from Search
    if (
      searchContext.searchDetail != null &&
      searchContext.searchDetail.type &&
      searchContext.searchDetail.type.length > 0
    )
      return;

    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.currentType, state.selectedCustomerId, state.currentFilter, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentType,
    state.page,
    state.currentFilter,
    state.currentSortField,
    state.currentSortOrder,
    state.selectedCustomerId,
  ]);

  useEffect(() => {
    if (searchContext.searchDetail != null && searchContext.searchDetail.searchText.length > 0) {
      // filter by search result
      //
      if (searchContext.searchDetail.type && searchContext.searchDetail.routingTo === RoutingToTypes.meterReads) {
        const type = searchContext.searchDetail.type.toLowerCase();
        if (type === 'mpan') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: searchContext.searchDetail.mpan } });
        } else if (type === 'mprn') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Gas });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: searchContext.searchDetail.mprn } });
        } else if (type === 'site') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: searchContext.searchDetail.siteId } });
        } else if (type === 'account') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({
            type: GRID_ACTIONS.FILTER_BY_CUSTOMER,
            payload: { essCustomerId: searchContext.searchDetail.accountId },
          });
        }

        // Clear Search Detail
        searchContext.setSearchDetail({ searchText: '' });
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext.searchDetail.type]);

  const loadData = async (
    gridType: string,
    customerId: string,
    currentFilter?: Common.IFilter | null,
    sort?: Common.ISort
  ) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    let promise = null;
    const filters: MetersFilterObject = {};

    if (state.selectedSiteReference) {
      filters.SiteReference = state.selectedSiteReference;
    }
    if (state.selectedMpanMprn) {
      filters.MpanCore = state.selectedMpanMprn;
    }

    filters.HhOrNhh = 'NHH';

    if (gridType === EssAccountsTypes.Electricity) {
      const meteringPoints = await getCustomerMeterReads(
        process.env.REACT_APP_API_URL,
        authContext,
        customerId,
        globalApiParams.maxFilterPageSize,
        state.page,
        sort,
        filters
      );

      promise = new Promise((resolve, reject) => {
        const gridData: Array<any> = meteringPoints.data.map((item: Api.IMeterReadsMissing) => {
          const meteringPointsData = {
            'mpan-core': item['mpan-core'],
            'site-reference': item['site-reference'],
            'site-name': item['site-name'],
            'site-address': `${item['site-address']}, ${item['site-postcode']}`,

            'warningIcon': item['missing-read'] || item['failure-to-obtain-read'],
          };

          if (meteringPointsData.warningIcon) {
            setIsWarningIcon(true);
          }

          return meteringPointsData;
        });

        resolve(gridData);
      });
    }

    if (promise instanceof Promise) {
      promise
        .then(result => {
          if (result) {
            dispatch({
              type: GRID_ACTIONS.ADD_DATA,
              payload: { data: result, page: state.page },
            });
          } else {
            console.error(`Meter reads request returned errors.`);
          }
        })
        .catch(error => {
          console.error(`Failed to get reads for ${gridType} product type.`, error);
        })
        .finally(() => {
          dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
        });
    }
  };

  useEffect(() => {
    let types: EssAccountsTypes[] = [];
    if (userDetail) {
      if (userDetail.hasElectricity) types.push(EssAccountsTypes.Electricity);
      if (userDetail.hasGas) types.push(EssAccountsTypes.Gas);
      if (userDetail.hasRenewable) types.push(EssAccountsTypes.Renewables);
    }
    setGridTypes(types);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.hasRenewable, userDetail.hasElectricity, userDetail.hasGas]);

  const clearSelection = () => {
    setSelectedRow(null);
  };

  // Handlers
  const handleTypeChange = (product: EssAccountsTypes) => {
    clearSelection();
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: product });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    clearSelection();
    setIsWarningIcon(false);
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    clearSelection();
    const essAccountId = option.value;

    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId: essAccountId } });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    clearSelection();
    const essSiteId = option ? option.value : '';

    dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: essSiteId } });
  };

  const handleMpanMprnFilterChange = (option: Common.IOption) => {
    clearSelection();
    const mprnMpan = option ? option.value : '';

    dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: mprnMpan } });
  };

  const handleClearAllClick = () => {
    clearSelection();
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handleRowSelect = (index: number) => {
    if (index !== selectedRow) {
      setSelectedRow(index);
      TagManager.pushData({
        event: 'View Meter Registers',
        rowIndex: index,
      });
    } else {
      setSelectedRow(null);
    }
  };

  const handleSiteRefBlur = (inputValue: string) => {};

  const onSuccessModalAppear = () => {
    TagManager.pushData({
      event: 'Meter read success (Submit Meter)',
    });
  };

  const onClickSort = (e: any) => {
    e.preventDefault();
    clearSelection();
    const typeId = e.currentTarget.dataset.value || '';
    let details = sortDetails;
    if (details.field === typeId) {
      if (details.order === 'ASC') details.order = 'DESC';
      else details.order = 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: details });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const handleExpandRow = async (mpan: string, siteReference: string) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    setMeterRegisters([]);

    const activeMeterRegisters = await getActiveMeterRegisters(
      authContext,
      state.selectedCustomerId,
      mpan,
      siteReference,
      false
    );
    setMeterRegisters(activeMeterRegisters);

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  };

  const headers = isDraxBrand ? draxHeaders : opusHeaders;

  return (
    <MeterReadsGridWrapper>
      <FilterRowWrapper className='mb-1'>
        <StyledTitle>Submit meter readings</StyledTitle>
      </FilterRowWrapper>
      <GridFilter
        showSites={true}
        showDate={false}
        showMpanMprn={true}
        showClearAll={true}
        showNhhAndMixed={true}
        showMeterWarning={isWarningIcon}
        customerData={state.data?.customers}
        accountData={state.data?.accounts}
        siteData={state.data?.sites}
        selectedType={state.currentType}
        mpanMprnData={state.data?.mpans}
        handleClearAllClick={handleClearAllClick}
        handleSiteFilterChange={handleSiteFilterChange}
        handleAccountFilterChange={handleAccountFilterChange}
        handleMpanMprnFilterChange={handleMpanMprnFilterChange}
        handleCustomerFilterChange={handleCustomerFilterChange}
        renderAdditionalListItems={isDraxBrand ? () => null : FilterAdditionalItems}
        showAccount={!isDraxBrand}
      />

      <GridTypeSelector initialType={state.currentType} types={gridTypes} handleTypeChange={handleTypeChange} />

      <div className='containerText d-inline-block d-sm-inline-flex mt-3'>
        <StyledTitle className='leftText mb-3' fontSize='20px'>
          Either submit reads individually by MPAN or in bulk using our handy template.
        </StyledTitle>
        {isWarningIcon && (
          <div className='rightText d-md-none'>
            <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> Indicates that we haven't received a read
            for this meter recently
          </div>
        )}
      </div>

      {state.gridData && state.gridData.length > 0 && <>{isDraxBrand && <DownloadForm />}</>}

      {!state.isLoading && state.gridData && state.gridData.length === 0 && (
        <NoResults
          className='pt-3'
          title=''
          description='We do not need meter reads for the meter you have linked to the account you chose'
        />
      )}

      {state.gridData && state.gridData.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={state.gridData.length}
            next={handlePagingChange}
            hasMore={state.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <div
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1'
              style={{ maxHeight: '50vh' }}
            >
              <table className='list table plain text-left'>
                <TableHeader
                  headers={headers}
                  isSelectable={false}
                  isAllSelected={false}
                  sortDetails={sortDetails}
                  onClickSort={onClickSort}
                  handleSelectAll={() => {}}
                />
                <tbody>
                  {state.gridData.map((item, index) => (
                    <MeterReadingsRow
                      submitMode
                      key={index}
                      warningIcon={item.warningIcon}
                      mpan={item['mpan-core']}
                      siteId={item?.essSiteID}
                      registers={meterRegisters}
                      siteReference={item['site-reference']}
                      siteAddress={item['site-address']}
                      expandRow={() => handleExpandRow(item['mpan-core'], item['site-reference'])}
                      isSelected={index === selectedRow}
                      productType={state.currentType}
                      selectRow={() => {
                        if (selectedRow !== index) {
                          handleRowSelect(index);
                        }
                      }}
                      columnsNumber={headers.length}
                      onSuccessModalAppear={onSuccessModalAppear}
                      onSiteRefBlur={handleSiteRefBlur}
                      isSubmitReadsPage={true}
                      siteName={item['site-name']}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </>
      )}

      {state.isLoading && <Loading overlay nowait />}
    </MeterReadsGridWrapper>
  );
};

export default SubmitMeterReadings;
