import styled from 'styled-components';

import { lightGrey, white } from '../../common/styles/colors';

export const MeterReadsGridWrapper = styled.div`
  padding: 0 10px;

  .usageLink {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  tr {
    .item {
      cursor: pointer;
    }

    &.sub-row.open {
      border-top: 1px solid black;
      input {
        background: ${white};
      }
      cursor: text;
    }

    td.site-address-col {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td.site-name-col {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td > span > input {
      max-width: 150px;

      &::placeholder {
        color: rgba(94, 97, 103, 0.5);
      }
    }
  }

  button {
    border: none;
    background: none;
  }

  .containerText {
    width: 100%;
    justify-content: space-between;
  }
  
  .leftText {
    float: left;
    width: auto;
  }
  
  .rightText {
    float: right;
    text-align: center;
    width: auto;
  }
`;

export const MeterReadsModalBody = styled.div`
  .modal__dropdown {
    max-width: 340px;
    background-color: ${lightGrey};
  }

  .datepicker__block a.nooutline {
    padding: 0 !important;
  }

  .download__block {
    display: flex;
    justify-content: space-around;
  }
`;
