// Modules
import React from 'react';

// Styles
import { SmartMeterBannerContainer } from './SmartMeterBanner.styles';

// Components
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';

export function SmartMeterBanner() {
  return (
    <SmartMeterBannerContainer>
      <h4 className='text-center text-sm-left'>Don't have a smart meter?</h4>
      <div className='mt-3 d-flex flex-column flex-xl-row justify-content-between align-items-center'>
        <p className='m-0'>
          Smart meters mean you don't have to submit readings manually and you only ever pay for what you use.
        </p>
        <ButtonPrimary
          borderRadiusType='squared'
          className='my-0 mt-3 mt-lg-0'
          href='https://energy.drax.com/smart-meters'
          target='_blank'
          title='Find out more'
        />
      </div>
    </SmartMeterBannerContainer>
  );
}
