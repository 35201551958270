import React, { useContext, useEffect } from 'react';
import IcomoonReact from 'icomoon-react';

import { Filter, MobileActionsPanel, SideMenuFilter } from '../../common/components';
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';
import { ExclusiveOffersCard } from './ExclusiveOffersCard';
import { isDraxBrand } from '../../utils/common';
import iconSet from '../../assets/selection.json';
import * as colors from '../../common/styles/colors';

const DESCRIPTION_OPUS =
  '* This portal is intended to introduce and enable you to access the website content of some of our preferred partners with who you may choose to purchase products and services from, these partners may pay us a commission or other remuneration. Any contract entered into via a third-party website and/or application and/or download is between you and the relevant third party and we shall not be responsible for any loss, damage or cost resulting from you entering into contract with any third party partner.';
const DESCRIPTION_DRAX =
  "We're able to offer you discounts on products and services from some of our preferred partners. Filter these offers by category and select them for more details and to access the partner's website.";
const NOTE =
  "Note: these partners may pay us a commission or other remuneration. Any contract that you enter into on our partners' websites is between you and our partner and we're not responsible for any resulting damages or costs.";

type ExclusiveOffersComponentProps = {
  options: Array<Api.IExclusiveOffersData>;
  alignRight?: boolean;
  handleFilterChange: Function;
};

const defaultProps = {
  options: [],
  alignRight: true,
  handleFilterChange: undefined,
};

export const ExclusiveOffersComponent = (props: ExclusiveOffersComponentProps) => {
  const [options, setOptions] = React.useState([]);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  useEffect(() => {
    if (globalContext.globalDetail.filteredItems) {
      props.handleFilterChange(globalContext.globalDetail.filteredItems);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.globalDetail.filteredItems]);

  const description = isDraxBrand ? DESCRIPTION_DRAX : DESCRIPTION_OPUS;

  const showClearAll = true;

  const handleClearAllClick = (event: any): void => {
    setOptions([]);
    props.handleFilterChange([]);
    handleCloseMenuClick(event);
  };

  const handleCloseMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      mobileFilterActive: false,
    });
  };

  return (
    <>
      <div className='mt-1'>
        <div className='exclusive-offers-list plain w-100 d-none d-md-table'>
          <Filter
            multiSelect
            selectedItems={options}
            alignRight={props.alignRight}
            handleClick={props.handleFilterChange}
            options={globalContext.globalDetail.exclusiveOffersOptions}
          />
        </div>
        <p className='mt-3'>{description}</p>
        {isDraxBrand && <p className='mt-3 mb-5'>{NOTE}</p>}
      </div>
      <ExclusiveOffersCard options={props.options} />
      <MobileActionsPanel showExport={false} showAddUser={false} showDownload={false} />
      <SideMenuFilter>
        <ul className='mobile-grid-filter'>
          <li>
            <Filter
              multiSelect
              selectedItems={options}
              alignRight={props.alignRight}
              handleClick={props.handleFilterChange}
              options={globalContext.globalDetail.exclusiveOffersOptions}
            />
          </li>
          {showClearAll && (
            <li style={{ marginTop: 'calc(100vh - 460px)' }}>
              <button className='close-menu-icon' onClick={handleCloseMenuClick}>
                <IcomoonReact iconSet={iconSet} color={colors.trueblack} size={15} icon='cross2' />
              </button>
            </li>
          )}
          {showClearAll && (
            <li>
              <div className='text-center'>
                <a href='/' className='d-block mb-4 mt-4 plain primary' onClick={handleClearAllClick}>
                  Clear All
                </a>
              </div>
            </li>
          )}
        </ul>
      </SideMenuFilter>
    </>
  );
};

ExclusiveOffersComponent.defaultProps = defaultProps;
