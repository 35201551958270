const Mail = ({ width, height, className, fillColor }: MailProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 34 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.8182 6H6.18182C4.50823 6 3 7.26302 3 9V23C3 24.737 4.50823 26 6.18182 26H25.8182C27.4918 26 29 24.737 29 23V9C29 7.26302 27.4918 6 25.8182 6Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M8 11L16 17L24 11'
      stroke={fillColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type MailProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Mail.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2a3745',
};

export default Mail;
