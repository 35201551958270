import styled from 'styled-components';
import { respondTo } from '../../common/styles/mixins';

export const ExclusiveOffersWrapper = styled.div`
  .filter-wrapper {
    justify-content: center;
    width: 100%;

    ${respondTo.tablet`
      justify-content: flex-end;
      width: initial;
    `};
  }
`;
