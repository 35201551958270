import styled from 'styled-components';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';
import * as colors from '../../../common/styles/colors';

export const BrokerLandingSelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .brokerLandingPageSelectedMobile {
    ${mixins.widgetMobile};
  }

  .brokerLandingPageSelected {
    ${mixins.widgetDesktop};
  }

  .icon-user {
    ${mixins.flipHorizontal};
    position: relative;
    top: -${variables.spacingSmall};
  }

  .selected-customers {
    .selected-list {
      ${mixins.scrollbars};
      overflow: auto;
      border: 1px solid ${colors.tiaraGray};
      max-height: 120px;
      border-radius: ${variables.inputRadius};
      padding: ${variables.spacingSmall};

      margin-left: 0;
      margin-right: 0;

      .row {
        display: flex;
      }

      .columnDisplay {
        flex: 90%;
        padding-bottom: ${variables.spacingXSmall};
      }

      .columnTrash {
        flex: 5%;
        cursor: pointer;
        padding-bottom: ${variables.spacingXSmall};
      }
    }

    .pill-list {
      overflow: auto;
      border-radius: ${variables.inputRadius};
      padding-top: ${variables.spacingSmall};

      margin-left: 0;
      margin-right: 0;

      .row {
        display: flex;
      }

      .pillTrash {
        cursor: pointer;

        .btn-close {
          border: 0;
          width: 2.5em;
          padding: 0;
        }
      }

      .btn-close-link {
        border: 0;
        width: 2.5em;
        padding: 0;
        text-decoration: unset;
      }

      .pillButton {
        border-radius: 15px;
        padding: ${variables.spacingXSmall};
        display: flex;
        cursor: default;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px solid ${colors.tiaraGray};
        background-color: transparent;

        .pillDisplay {
          padding-left: ${variables.spacingXSmall};
        }
      }
    }
  }
`;
