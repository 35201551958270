import styled from 'styled-components';
import * as colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';

export const StyledExternalLink = styled.a`
  font-weight: ${props => props.theme.button.fontWeight};
  ${mixins.borderNone};

  &:hover {
    ${mixins.borderNone};
  }

  &.btn-primary {
    border-radius: ${props => props.theme.externalLink.btnBorderRadius};
    text-decoration: none;
    color: ${colors.white} !important;
    padding: 0.5rem 2rem;
  }

  &.btn-secondary {
    background-color: ${colors.spearmintGreen};
    border: 2px solid ${colors.spearmintGreen};
    border-radius: ${props => props.theme.externalLink.btnBorderRadius};
    text-decoration: none;
    padding: 0.5rem 2rem;

    &:hover,
    &:active {
      background-color: ${colors.spearmintGreen} !important;
      border: 2px solid ${colors.spearmintGreen} !important;
    }
  }

  &.btn-terinary {
    background-color: ${props => props.theme.externalLink.bg};
    border: 2px solid ${props => props.theme.externalLink.border};
    color: ${props => props.theme.externalLink.color};
    border-radius: ${props => props.theme.externalLink.btnBorderRadius};
    text-decoration: none;
    padding: 0.5rem 2rem;

    &:hover {
      color: ${props => props.theme.externalLink.hovercolor};
      border-color: ${props => props.theme.externalLink.hovercolor};
    }
  }
`;
