import React, { FC } from 'react';

import { TableBody } from '../TableBody/TableBody';
import { TableHeader } from '../TableHeader/TableHeader';

type TableProps = {
  rows: any;
  actions?: FC;
  sortDetails: any;
  onClickSort: any;
  actionStyles: any;
  expandableRow?: FC;
  className: string;
  [rest: string]: any;
  handleSelectAll?: any;
  selectedRowIndex: any;
  isSelectable?: boolean;
  isRowExpanded?: boolean;
  isAllSelected?: boolean;
  headers: Array<Common.HeaderItem>;
  isChecked?: (rowIndex: number) => boolean;
  handleCheckRow?: (rowIndex: number) => void;
  handleSelectRow: (action: any, rowIndex: number) => void;
};

export const Table = (props: TableProps) => {
  const {
    rows,
    actions,
    headers,
    className,
    sortDetails,
    onClickSort,
    actionStyles,
    expandableRow,
    handleCheckRow,
    handleSelectRow,
    handleSelectAll,
    selectedRowIndex,
    isSelectable = false,
    isAllSelected = false,
    isRowExpanded = false,
    isChecked = () => false,
    ...rest
  } = props;
  return (
    <table className={className}>
      <TableHeader
        headers={headers}
        actions={actions}
        sortDetails={sortDetails}
        onClickSort={onClickSort}
        isSelectable={isSelectable}
        isAllSelected={isAllSelected}
        handleSelectAll={handleSelectAll}
      />
      <TableBody
        rows={rows}
        headers={headers}
        actions={actions}
        isChecked={isChecked}
        actionStyles={actionStyles}
        isSelectable={isSelectable}
        isRowExpanded={isRowExpanded}
        expandableRow={expandableRow}
        handleCheckRow={handleCheckRow}
        handleSelectRow={handleSelectRow}
        selectedRowIndex={selectedRowIndex}
        {...rest}
      />
    </table>
  );
};

Table.defaultProps = {
  rows: [],
  headers: [],
  isSelectable: false,
  isAllSelected: false,
  isRowExpanded: false,
  isChecked: () => false,
};
