// Modules
import { useContext, useEffect, useState } from 'react';

// Components
import { Loading } from '../../common/components';

// Utils
import { TagManager } from '../utils/analytics/TagManager';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { Button } from 'react-bootstrap';
import FormElement from '../../common/components/FormElement';
import { useSupportFormValidation } from './useSupportFormValidation';
import { submitSupportRequest } from '../../common/api/homeApi';
import { useAuthState } from '../../providers/authProvider';
import { BlankModal } from '../mymessages/modalTemplates/BlankModal';
import { SubmissionFailedModal } from '../mymessages/modalTemplates/SubmissionFailedModal';

type HelpSupportProps = {
  isComponentVisible: boolean;
  handleClose: Function;
};

export const HelpSupport = (props: HelpSupportProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [preference, setPreference] = useState('Email');
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [textAreaTouched, setTextAreaTouched] = useState<boolean>(false);
  const [phoneTouched, setPhoneTouched] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [showThankYouModal, setShowThankYouModal] = useState<boolean>(false);
  const [showFailureModal, setShowFailureModal] = useState<boolean>(false);
  const authContext = useAuthState();

  const modalTitleText = 'Thank you for your request, our support team will be in touch with you shortly.';
  const modalButtonText = 'Ok';

  useEffect(() => {
    clearFormFields();
    if (props.isComponentVisible) {
      TagManager.pushData({
        event: 'Support Icon',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isComponentVisible]);

  useEffect(() => {
    if (textAreaTouched) {
      validateMessageInput(message);
    }
    if (phoneTouched) {
      validatePhoneInput(phone);
    }
    if (preference === 'Email') {
      setPhoneTouched(false);
      setPhone('');
      clearPhoneValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, phone, preference]);

  const {
    phoneError,
    messageError,
    validateMessageInput,
    validatePhoneInput,
    clearPhoneValidation,
    clearAllValidation,
  } = useSupportFormValidation();

  const clearFormFields = () => {
    setMessage('');
    setPhone('');
    setPreference('Email');
    setTextAreaTouched(false);
    setPhoneTouched(false);
    clearAllValidation();
  };

  const handleEnquiry = async () => {
    setSending(true);

    const claims = authContext.getClaims();

    let references = '';
    if (claims.tpiReferenceId === undefined) {
      references = userDetail.customerAccounts.map(x => x.essAccountID).join(', ');
    } else {
      references = claims.tpiReferenceId;
    }

    let request = {
      toAddress: 'myenergysupply@drax.com',
      templateName: 'INC_SupportRequest',
      correspondenceType: 6,
      placeholderValues: [
        {
          key: '{|emailAddress|}',
          value: userDetail.emailAddress,
        },
        {
          key: '{|references|}',
          value: references,
        },
        {
          key: '{|phoneNumber|}',
          value: preference === 'Email' ? 'N/A' : phone,
        },
        {
          key: '{|preferredContact|}',
          value: preference,
        },
        {
          key: '{|requestDetails|}',
          value: message,
        },
      ],
    };

    TagManager.pushData({
      event: 'Support Submit',
    });

    const result = await submitSupportRequest(process.env.REACT_APP_API_URL, request, authContext);

    if (result.status == '200') {
      TagManager.pushData({
        event: 'Success Support Form',
      });

      setShowThankYouModal(true);
    } else if (result.status == '500') {
      // 62413 - Remove Support Email Failure Modal (Temporarily)
      setShowThankYouModal(true);

      // 62413 - Remove Support Email Failure Modal (Temporarily)
      // TagManager.pushData({
      //   event: 'Failure Support Form',
      // });

      // setShowFailureModal(true);
    }

    setSending(false);
    clearFormFields();
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (preference === 'Email') {
      await handleEnquiry();
    } else {
      validatePhoneInput(phone);
      if (phone && !phoneError) {
        await handleEnquiry();
      }
    }
  };

  const handleThankYouOnHide = (event: any) => {
    setShowThankYouModal(false);
    props.handleClose();
  };

  const handleFailureOnHide = (event: any) => {
    setShowFailureModal(false);
    props.handleClose();
  };

  return (
    <div className={`support-popup ${props.isComponentVisible ? '' : 'hidden'}`}>
      <h5>Support</h5>
      <p>Send us a message with your details and we'll get back to you as soon as we are able to.</p>

      <FormElement.Radio
        id='support-contact-preference'
        className='c-form__input'
        label={'How do you wish to be contacted?'}
        name='contactPref'
        elements={['Email', 'Phone']}
        value={preference}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPreference(e.target.value);
        }}
      />

      <form onSubmit={handleSubmit}>
        <FormElement.Input
          id='support-email-address'
          className='c-form__input'
          label={'Your email address'}
          name='email'
          type='email'
          value={userDetail.emailAddress || ''}
          onChange={() => {}}
          errorMessage=''
          disabled={true}
          locked={true}
        />

        {preference === 'Phone' && (
          <FormElement.Input
            id='support-phone-number'
            className='c-form__input'
            label={'Your phone number'}
            name='phone'
            type='number'
            value={phone}
            errorMessage={phoneError}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPhoneTouched(true);
              setPhone(e.target.value);
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => validatePhoneInput(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const invalidKeys = ['e', 'E', '+', '.', '-'];

              if (invalidKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
        )}

        <FormElement.Textarea
          id='support-user-message'
          placeholder={'How can we help? Provide as much detail as you can.'}
          className='c-form__input no-resize'
          label={'How can we help? Provide as much detail as you can.'}
          name='message'
          value={message}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setTextAreaTouched(true);
            setMessage(e.target.value);
          }}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
            validateMessageInput(e.target.value);
          }}
          errorMessage={messageError}
          rows={5}
        />
        <Button
          className='c-form__button'
          onClick={handleSubmit}
          disabled={message.length < 10 || (preference === 'Phone' && phone === '') || phoneError !== '' || sending}
        >
          Submit
          {sending && <Loading overlay nowait />}
        </Button>
      </form>

      <BlankModal
        show={showThankYouModal}
        onHide={handleThankYouOnHide}
        titleText={modalTitleText}
        buttonText={modalButtonText}
      ></BlankModal>

      <SubmissionFailedModal show={showFailureModal} onHide={handleFailureOnHide}></SubmissionFailedModal>
    </div>
  );
};
