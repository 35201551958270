import React, { ReactElement, useContext } from 'react';

// Components
import { Icon } from '../../../common/components';
import ScrollTop from '../../../common/components/ScrollTop/ScrollTop';
import { StyledTitle } from '../../../common/components';
import { SearchItemCollection } from '../SearchItemCollection/SearchItemCollection';

// Styles
import * as colors from '../../../common/styles/colors';
import { SearchResultsWrapper } from './SearchResults.styles';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { SearchResultsProvider } from '../context/searchResultsProvider';

type SearchResultsProps = {
  hidden: boolean;
  items: Api.ISearch;
  handleHide: Function;
  handleSelected: Function;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultProps = {
  hidden: true,
  items: null,
};

export const SearchResults = (props: SearchResultsProps): ReactElement => {
  const { isSMECustomer, isCorpCustomer, isCorpBroker } = useContext<UserDetailContextProps>(UserDetailContext);

  return (
    <>
      <StyledTitle>Search Results</StyledTitle>
      <button className='close' onClick={() => props.handleHide()}>
        <span aria-hidden='true'>
          <Icon color={colors.white} size={25} icon='cross-circle' />
        </span>
      </button>
      <SearchResultsProvider value={{ selectedCollection: '' }}>
        {(isSMECustomer() || isCorpCustomer() || isCorpBroker()) && (
          <SearchResultsWrapper>
            <SearchItemCollection
              name='Accounts'
              type='account'
              items={props?.items?.accountItems}
              handleSelected={props.handleSelected}
            />
            <SearchItemCollection
              name='Sites'
              type='site'
              items={props?.items?.siteItems}
              handleSelected={props.handleSelected}
            />
            <SearchItemCollection
              name='MPANs'
              type='mpan'
              items={props?.items?.mpanItems}
              handleSelected={props.handleSelected}
            />
            <SearchItemCollection
              name='MPRN'
              type='mprn'
              items={props?.items?.mprnItems}
              handleSelected={props.handleSelected}
            />
            <SearchItemCollection
              name='Invoices'
              type='invoice'
              items={props?.items?.invoiceItems}
              handleSelected={props.handleSelected}
            />
            <SearchItemCollection
              name='Statements'
              type='statement'
              items={props?.items?.statementItems}
              handleSelected={props.handleSelected}
            />
            <ScrollTop />
          </SearchResultsWrapper>
        )}
      </SearchResultsProvider>
    </>
  );
};

SearchResults.defaultProps = defaultProps;
