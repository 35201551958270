import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ExternalLink, ModalClose } from '../../../common/components';

type TenderReadyProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Tender ready',
};

const TenderReady = (props: TenderReadyProps) => {
  return (
    <>
      <span className='font-medium'>
        {props.messagevalues.date}
        <span className='ml-2'>{props.messagevalues.time}</span>
      </span>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>Your tender is now ready</p>
        <p>{props.messagevalues.subject}</p>
      </Modal.Body>
      <Modal.Footer>
        <ExternalLink
          onClick={() => {
            props.onHide();
          }}
          className='btn-primary'
          title='View my tender'
          target='_blank'
          href={`${props.messagevalues.callToAction}`}
        />
      </Modal.Footer>
    </>
  );
};

TenderReady.defaultProps = defaultProps;

export default TenderReady;
