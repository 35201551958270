import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';
import * as mixins from '../../common/styles/mixins';
import { respondTo } from '../../common/styles/mixins';
import arrowup from '../../assets/images/arrow-up.png';

export const HeaderWrapper = styled.div`
  .header-wrapper {
    display: initial;
    padding-right: 44px;

    ${respondTo.tablet`
      display: flex;

      .service-message {
        padding-bottom: ${variables.spacingMedium};
      }
    `};
  }

  .mobile-wrapper {
    padding: 13px 13px 21px;
    justify-content: flex-end;

    .mobile-hidden {
      padding-bottom: 40px;
    }

    ${respondTo.tablet`
      padding: 0;
    `};
  }

  .header {
    margin-left: -10px !important;
  }

  .iconButton {
    padding-right: 10px;
    margin-left: 8px;

    ${respondTo.tablet`
      margin-left: 22px;
    `};
  }

  .iconButton:last-of-type {
    padding-right: 0;
  }

  .icon-circle {
    width: 40px;
    height: 40px;
    background-color: ${colors.inputGrey};
    border-radius: 50%;
    left: 100px;
  }

  .icon-circle-help {
    width: 40px;
    height: 40px;
    background-color: ${props => props.theme.primary.bg};
    border-radius: 50%;
    left: 100px;
  }

  .icon-help {
    left: -5.5px;
    top: 5.5px;
  }

  svg path {
    fill: ${colors.darkGrey};
  }

  svg.active {
    fill: #fff;
  }

  svg.active path {
    fill: #fff;
  }

  .help-popup {
    ${mixins.boxShadowPrimary};
    font-size: 14px;
    background-color: ${colors.white};
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${colors.midGrey};
    position: relative;
    left: -220px;
    width: 270px;
    top: 26px;
    z-index: ${variables.zIndexHighest + 1};

    ${respondTo.tablet`
      left: -178px;
    `};

    &:before {
      content: '';
      display: block;
      background-image: url(${arrowup});
      background-repeat: no-repeat;
      background-size: 36px;
      position: absolute;
      width: 36px;
      height: 17px;
      top: -17px;
      left: 221px;

      ${respondTo.tablet`
        left: 180px;
      `};
    }
  }

  .profile-popup {
    ${mixins.boxShadowPrimary};
    font-size: 14px;
    background-color: ${colors.white};
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${colors.midGrey};
    position: relative;
    left: -305px;
    width: ${variables.menuWidth};
    top: 26px;
    z-index: ${variables.zIndexHighest + 2};

    ${respondTo.mobile`
      left: -215px;
      width: 270px;
    `};

    &:before {
      content: '';
      display: block;
      background-image: url(${arrowup});
      background-repeat: no-repeat;
      background-size: 36px;
      position: absolute;
      width: 36px;
      height: 17px;
      top: -17px;
      left: 305px;

      ${respondTo.mobile`
        left: 217px;
      `};
    }

    .btnchangepassword {
      display: block;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .btnsignout {
      display: block;
      margin-top: 0;
      font-size: 16px;
      margin-bottom: 0;
    }

    .profileText {
      a {
        color: ${({ theme }) => theme.text.secondary};
        text-decoration: none;
      }
    }

    .contactText {
      .contact-type {
        color: ${({ theme }) => theme.text.secondary};
      }
      .contact-number {
        font-size: ${variables.fontSizeMedium};
        font-weight: bold;
      }
    }
  }

  .password-expiry {
    color: ${colors.razzmatazzPink};
    font-weight: bold;
  }

  .btn-toggle {
    cursor: pointer;
    color: ${colors.midGrey};
    text-align: center;
    border-radius: 50%;
    font-size: ${variables.fontSizeBase};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app {
    @media (max-width: $breakpoint-md) {
      .btn-toggle-lg {
        display: none;
      }

      &.rtl {
        .btn-toggle {
          margin-left: auto;
        }
      }
    }
  }

  .home-email-address {
    color: ${colors.white};
  }

  .support-popup {
    ${mixins.boxShadowPrimary};
    font-size: 14px;
    background-color: ${colors.white};
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${colors.midGrey};
    position: relative;
    left: -305px;
    width: ${variables.menuWidth};
    top: 26px;
    z-index: ${variables.zIndexHighest + 2};

    ${respondTo.mobile`
      left: -425px;
      width: 480px;
    `};

    &:before {
      content: '';
      display: block;
      background-image: url(${arrowup});
      background-repeat: no-repeat;
      background-size: 36px;
      position: absolute;
      width: 36px;
      height: 17px;
      top: -17px;
      left: 305px;

      ${respondTo.mobile`
        left: 428px;
      `};
    }
  }
`;
