import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../common/components';

type HHDataRequestModalProps = {
  meterType: string;
  onHide: () => void;
  show: boolean;
};

const defaultProps = {
  show: true,
};

export const HHDataRequestModal = (props: HHDataRequestModalProps) => {
  const { show } = props;
  const convertMeterTypeToText = (meterType: string) => {
    switch (meterType) {
      case 'HalfHourly':
        return 'HH';
      case 'Smart':
        return 'Smart meter';
      case 'AutomaticMeterRead':
        return 'AMR';
      default:
        return meterType;
    }
  };

  const title = `${convertMeterTypeToText(props.meterType)} data request`;
  const topActiveRequest = "There's an active request associated with this account.";
  const bottomActiveRequest =
    "You'll be able to raise another request when you've received the data you previously requested.";

  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
          <p>{topActiveRequest}</p>
          <p>{bottomActiveRequest}</p>
        </Modal.Body>
        <Modal.Footer className='p-0'>
          <ButtonPrimary onClick={handleClose} title='OK' className='mt-3 mr-0' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

HHDataRequestModal.defaultProps = defaultProps;
