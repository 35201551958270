import styled from 'styled-components';

import { inputBg, white } from '../../../common/styles/colors';
import { inputRadius } from '../../../common/styles/variables';

export const ViewAsCustomerWidgetContainer = styled.div`
  background-color: ${inputBg};
  padding: 20px 65px 20px 30px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 30px;

  .dropdown {
    max-width: 389px;
    background-color: ${white};
    margin-right: 0;
    border-radius: ${inputRadius};

    .typeAheadDropDown {
      border: none;
      padding: 0;

      input {
        min-height: 54px;
      }
    }
  }
`;
