import { globalApiParams } from './globals';
import { get, postForm } from '../../helpers/axiosWrapper';
import { toast } from 'react-toastify';

export const getSitesForMovingPremises = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  essAccountId: string | null,
  essSiteId: string | null,
  mpan: string | null,
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.ISitesForMovingPremises>> => {
  // TODO: TEMP EDW message check
  if (essAccountId) {
    toast.error('essAccountId FILTER NOT IMPLEMENTED IN EDW ENDPOINT', { position: 'bottom-right' });
  } else if (mpan) {
    toast.error('mpan FILTER NOT IMPLEMENTED IN EDW ENDPOINT', { position: 'bottom-right' });
  }

  let headers: Api.ISitesForMovingPremisesParams = {
    sortField: sort?.field,
    sortDir: sort?.order,
    essAccountId: essAccountId || '',
    siteReference: essSiteId || '',
    mpan: mpan || '',
    page,
    pageSize: globalApiParams.pageSize,
  };

  if (page === 1) {
    return await get(`${url}/site/activesitesbycustomerid/${essCustomerId}?getFilters=true`, { headers }, context);
  } else {
    return await get(`${url}/site/activesitesbycustomerid/${essCustomerId}`, { headers }, context);
  }
};

export const saveSitesMovingPremisesDetails = async (
  url: string | undefined,
  context: any,
  data: any,
  onUploadProgress: Function
): Promise<any> => {
  var bodyFormData = new FormData();
  bodyFormData.append('CotDate', data.CotDate);
  bodyFormData.append('HasNewPremises', data.HasNewPremises);
  bodyFormData.append('SiteAddress', data.SiteAddress);
  bodyFormData.append('FaStreet', data.FaStreet);
  bodyFormData.append('FaTownCity', data.FaTownCity);
  bodyFormData.append('FaPostcode', data.FaPostcode);
  bodyFormData.append('HasLandlordContact', data.HasLandlordContact);
  bodyFormData.append('LandlordName', data.LandlordName);
  bodyFormData.append('LandlordTelephone', data.LandlordTelephone);
  bodyFormData.append('PremisePurpose', data.PremisePurpose);
  bodyFormData.append('NewOwnerKnown', data.NewOwnerKnown);
  bodyFormData.append('NewOwnerBusinessName', data.NewOwnerBusinessName);
  bodyFormData.append('NewOwnerContactName', data.NewOwnerContactName);
  bodyFormData.append('NewOwnerTelephone', data.NewOwnerTelephone);
  bodyFormData.append('Comments', data.Comments);
  bodyFormData.append('ContactName', data.ContactName);
  bodyFormData.append('ContactTelephone', data.ContactTelephone);
  bodyFormData.append('ExternalEmail', data.ExternalEmail);
  bodyFormData.append('CustomerAccountReference', data.CustomerAccountReference);
  bodyFormData.append('RaisedBy', data.RaisedBy);

  data.Mpans.map((mpanArray: any) => {
    bodyFormData.append('Mpans', mpanArray);
  });

  if (data.SupportingFiles) {
    data.SupportingFiles.map((file: File) => {
      bodyFormData.append('SupportingFiles', file);
    });
  } else {
    bodyFormData.append('SupportingFiles', '');
  }

  return await postForm(`${url}/bulkupload/moving-premises-request`, { data }, bodyFormData, onUploadProgress, context);
};
