import styled from 'styled-components';
import * as variables from '../../common/styles/variables';
import * as colors from '../../common/styles/colors';
import { respondTo } from '../../common/styles/mixins';

export const AdminLandingWrapper = styled.div`
  background-color: ${colors.white};
  height: 90vh;

  img {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
  }

  .buttonbox {
    height: 70%;
  }

  .searchTitle {
    color: ${({ theme }) => theme.primary.color};
  }

  .search {
    padding-top: 50px;
    width: 60%;
  }

  .searchBox {
    width: 30%;
  }

  .results {
    width: 60%;
    padding-top: 1rem;
  }
`;

export const AdminUserSearchWrapper = styled.div`
  background-color: ${colors.white};
  width: 100%;

  .search {
    padding-top: 50px;
    width: 60%;
  }

  .errorText {
    color: red;
  }
`;

export const AdminLandingGridWrapper = styled.div`
  height: auto;

  thead {
    border-bottom: 5px solid ${colors.lightGreyAccent};
  }

  tbody {
    background-color: ${({ theme }) => theme.bg.secondary};
    border-radius: 25px;
  }

  .checkbox {
    width: 30px;
  }

  .checkboxSmall {
    height: 18px;
    width: 18px;
  }

  .goodStatus {
    font-weight: ${props => props.theme.button.fontWeight};
    color: ${colors.white} !important;
    text-decoration: none;
    padding: 0.2rem 1rem !important;
    max-width: 100px;
    white-space: nowrap;
    margin-top: -5px;
    text-align: center !important;
    border: 2px solid ${props => props.theme.primary.border};
    background: ${props => props.theme.primary.bg};
    border-radius: 10px;
  }

  .lockedStatus {
    font-weight: ${props => props.theme.button.fontWeight};
    color: red !important;
    padding: 0.2rem 1rem !important;
    max-width: 100px;
    margin-top: -5px;
    text-align: center !important;
    border: 2px solid red;
    background: ${colors.white};
    border-radius: 10px;
  }

  .disabledStatus {
    font-weight: ${props => props.theme.button.fontWeight};
    color: black !important;
    padding: 0.2rem 1rem !important;
    max-width: 100px;
    margin-top: -5px;
    text-align: center !important;
    border: 2px solid black;
    background: ${colors.white};
    border-radius: 10px;
  }
`;

export const ResultsGridWrapper = styled.div`
  position: relative;
  min-height: 200px;

  tbody:after {
    background: none !important;
  }

  .table-fixed {
    ${respondTo.tablet`
      padding-right: 33px;
    `};
    height: calc(100vh - 280px);
  }
`;
