const errors = {
  1: "Please check your contract dates. If your contract's active, please check your read and resubmit.",
  2: "That read doesn't look right for the meter type. Please check and resubmit.",
  3: 'Please provide a more recent read.',
  4: 'We already have a read for this date. You can supply another read from tomorrow onwards.',
  5: "That read's lower than a previous one. Please check and resubmit.",
  6: "We don't recognise that meter. Please check the MPAN and resubmit your read.",
  7: 'We need additional information to be able to process the meter read. Please check additional reads and registers and resubmit.',
};

export const getSubmitMeterError = (error: string) => {
  if (error.includes('No valid registration period for MPAN')) {
    return errors['1'];
  } else if (
    error.includes('Cannot match any configuration to reading') ||
    error.includes('Invalid Read for Meter') ||
    error.includes('Reading greater than maximum for register') ||
    error.includes('Invalid Read: Type') ||
    error.includes('Invalid Advance for register')
  ) {
    return errors['2'];
  } else if (error.includes('Read Date for Meter')) {
    return errors['3'];
  } else if (error.includes('D0086 not used as its Reading Type is a lower priority')) {
    return errors['4'];
  } else if (
    error.includes('Negative Consumption from D0086 ') ||
    error.includes('Negative Consumption for register')
  ) {
    return errors['5'];
  } else if (error.includes('does not exist') || error.includes('Billing Party is not authorised to MPAN')) {
    return errors['6'];
  } else if (error.includes('Missing register reads for MPAN')) {
    return errors['7'];
  }
  return errors['2'];
};
