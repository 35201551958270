// Modules
import React, { useContext } from 'react';
import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';
import { black } from '../../styles/colors';
import { iconSizeSmall } from '../../styles/variables';

// Components
import { Icon } from '../Icon/Icon';

// Styles
import { SwitchViewBtnContainer } from './SwitchViewBtn.styles';

export function SwitchViewBtn() {
  // Context
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  // State
  const [view, setView] = React.useState<'mobile' | 'desktop'>('mobile');

  // Handlers
  const changeView = () => {
    let nextView: 'mobile' | 'desktop' = 'mobile';
    const viewportMetaTag = document.getElementById('viewport');

    if (viewportMetaTag) {
      if (view === 'mobile') {
        viewportMetaTag?.setAttribute(
          'content',
          'width=992, height=device-height, initial-scale=1.0, maximum-scale=2.0, minimum-scale=1.0, user-scalable=yes'
        );
        nextView = 'desktop';
        globalContext.setGlobalDetail({
          ...globalContext.globalDetail,
          isSideMenuActive: true,
        });
      } else {
        viewportMetaTag?.setAttribute('content', 'width=device-width, initial-scale=1');
      }
      setView(nextView);
    }
  };

  let icon = 'smartphone';

  if (view === 'mobile') {
    icon = 'laptop';
  }
  return (
    <SwitchViewBtnContainer onClick={changeView} className='fixed-bottom'>
      <Icon color={black} size={iconSizeSmall} icon={icon} />
    </SwitchViewBtnContainer>
  );
}
