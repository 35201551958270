import { useState, useEffect } from 'react';

import { StyledTitle, Loading } from '../../common/components';
import { ExclusiveOffersComponent } from './ExclusiveOffers';
import { useAuthState } from '../../providers/authProvider';
import { exclusiveoffers } from '../../common/api/exclusiveOffersApi';

import { ExclusiveOffersWrapper } from './ExclusiveOffers.styles';
import { temporary } from '../../helpers/axiosWrapper';

const defaultProps = {
  options: [],
};

export const ExclusiveOffers = () => {
  const [exclusiveOffersData, setExclusiveOffersData] = useState<Array<Api.IExclusiveOffersData>>([]);
  const [exclusiveOffersDataFiltered, setExclusiveOffersDataFiltered] = useState<Array<Api.IExclusiveOffersData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const authContext = useAuthState();

  const getExclusiveOffers = async () => {
    let userType: string = 'Corporate';

    const accessId = temporary.accessId;
    if (accessId && accessId.length > 0) {
      userType = 'TPI';
    }

    const exclusiveOffers: Common.IResult<Array<Api.IExclusiveOffersData>> = await exclusiveoffers(
      process.env.REACT_APP_API_URL,
      authContext,
      userType
    );

    if (exclusiveOffers && typeof exclusiveOffers.success) {
      setExclusiveOffersData(exclusiveOffers.data);
      setExclusiveOffersDataFiltered(exclusiveOffers.data);
    }

    setIsLoading(false);
  };

  const handleFilterChange = (filteredItems: Array<Common.IOption>) => {
    if (filteredItems.length === 0) {
      setExclusiveOffersDataFiltered(exclusiveOffersData);
      return;
    }

    const filteredData: any = exclusiveOffersData.filter(item => {
      const filteredDataIndex = filteredItems.findIndex(
        elem => item.offerType.toLowerCase() === elem.value.toLowerCase()
      );

      return filteredDataIndex >= 0;
    });

    setExclusiveOffersDataFiltered(filteredData);
  };

  useEffect(() => {
    getExclusiveOffers();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getExclusiveOffers();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporary.accessId]);

  return (
    <ExclusiveOffersWrapper>
      <StyledTitle>Exclusive offers</StyledTitle>
      <div className='col' style={{ position: 'initial' }}>
        <ExclusiveOffersComponent options={exclusiveOffersDataFiltered} handleFilterChange={handleFilterChange} />
        {isLoading && <Loading overlay />}
      </div>
    </ExclusiveOffersWrapper>
  );
};

ExclusiveOffers.defaultProps = defaultProps;
