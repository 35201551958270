import { themeDrax } from './drax.theme';
import { themeOpus } from './opus.theme';

import { iconsOpus } from './opus.icons';
import { iconsDrax } from './drax.icons';
import { BRANDS } from '../../../consts';

declare var process: {
  env: {
    REACT_APP_BRAND: BRANDS;
  };
};

const brand = process.env.REACT_APP_BRAND;

export const themesMap = new Map([
  [BRANDS.OPUS, themeOpus],
  [BRANDS.DRAX, themeDrax],
]);

export const iconsMap = new Map([
  [BRANDS.OPUS, iconsOpus],
  [BRANDS.DRAX, iconsDrax],
]);

export const iconsToUse = iconsMap.get(brand) || iconsOpus;
export const themeToUse = themesMap.get(brand) || themeOpus;

export * from './drax.theme';
export * from './opus.theme';

export * from './drax.icons';
export * from './opus.icons';
