// Modules
import { ScriptableTooltipContext, ChartType } from 'chart.js';

// Styles
import { tooltipTransitionDurationSec } from '../../styles/global/chart.styles';

export const getCustomTooltipHandler = (units: string) => {
  let timeoutId: NodeJS.Timeout | null = null;

  return (context: ScriptableTooltipContext<ChartType>) => {
    try {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      const { chart, tooltip } = context;
      const wrapperClassName = 'chartjs-tooltip-wrapper';
      const textBlockClassName = 'chartjs-tooltip-text';
      const tooltipArrowClassName = 'chartjs-tooltip-arrow';
      const tooltipArrowLeftAlignClassName = 'chartjs-tooltip-arrow-align-left';
      // Get tooltip element within canvas parent block
      let tooltipEl = chart.canvas.parentElement?.querySelector?.('.chartjs-tooltip') as HTMLElement;

      if (!tooltipEl) {
        // If tooltip doesn't exist yet, create one and append it to canvas parent block
        tooltipEl = document.createElement('div');
        tooltipEl.className = 'chartjs-tooltip';
        tooltipEl.innerHTML = `
                <div class="${wrapperClassName}">
                    <div class="${tooltipArrowClassName}"></div>
                    <div class="${textBlockClassName}"></div>
                </div>`;
        if (chart.canvas.parentNode) {
          chart.canvas.parentNode.appendChild(tooltipEl);
        }
      }

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.classList.add('chartjs-tooltip-hidden');
        // Hide tooltip after opacity transition ended
        timeoutId = setTimeout(() => {
          tooltipEl.classList.add('chartjs-tooltip-display-none');
        }, tooltipTransitionDurationSec * 1000);
        return;
      }
      tooltipEl.classList.remove('chartjs-tooltip-display-none');
      const textBlock = tooltipEl.querySelector(`.${textBlockClassName}`);

      if (tooltip.body) {
        let innerHtml = '';

        // Add <p /> block with text for each point
        tooltip.dataPoints.forEach(({ dataset, dataIndex }) => {
          const { data } = dataset;

          innerHtml += `<p>${data[dataIndex]} ${units}</p>`;
        });

        textBlock!.innerHTML = innerHtml;
      }
      const arrowEl = chart.canvas.parentElement?.querySelector?.(`.${tooltipArrowClassName}`) as HTMLElement;
      const alignLeft = tooltip.caretX > chart.width / 2;
      const tooltipSideMargin = (textBlock!.clientWidth + 48) / 2;
      const positionY = chart.canvas.offsetTop - tooltip.height / 2;
      const positionX = chart.canvas.offsetLeft;
      const top = positionY + tooltip.caretY;
      let left = positionX + tooltip.caretX;

      if (alignLeft) {
        if (arrowEl) {
          arrowEl.classList.add(tooltipArrowLeftAlignClassName);
        }
        left -= tooltipSideMargin;
      } else {
        if (arrowEl?.classList?.contains?.(tooltipArrowLeftAlignClassName)) {
          arrowEl.classList.remove(tooltipArrowLeftAlignClassName);
        }
        left += tooltipSideMargin;
      }
      // Display and position
      tooltipEl.classList.remove('chartjs-tooltip-hidden');
      tooltipEl.style.left = `${left}px`;
      tooltipEl.style.top = `${top}px`;
    } catch (error) {
      console.error('Error ocurred when tried to display/hide tooltip.', error);
    }
  };
};
