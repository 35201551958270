import styled from 'styled-components';
import * as colors from '../../../../common/styles/colors';

import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary, Icon } from '../../../../common/components';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../assets/selection.json';

type LoAUploadCompleteModalProps = {
  success: boolean;
  show: boolean;
  onHide: any;
};

const defaultProps = {
  show: true,
  success: false,
};

export const Wrapper = styled.div`
  .failure-icon {
    display: inline-block;
    padding: 25px;
    width: 80px;
    height: 80px;
    text-align: center;
    background: #ccc;
    border-radius: 50%;
    margin-bottom: 10px;
  }
`;

export const LoAUploadCompleteModal = (props: LoAUploadCompleteModalProps) => {

  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={props.show} backdrop='static' centered>
        <Wrapper>
          {props.success && <Modal.Title><div className='text-center'>Upload successful</div></Modal.Title>}
          {!props.success && <Modal.Title><div className='text-center'>Submission failed</div></Modal.Title>}

          <Modal.Body>
            <div className='text-center'>
              <div>
                {props.success && <Icon color={colors.draxBtn} size={100} icon='checkmark-circle' />}
                {!props.success && <IcomoonReact className='failure-icon' iconSet={iconSet} color={'white'} size={30} icon='cross' />}
              </div>
              <div className='pt-3'>
                {props.success && <p >You'll be able to access the customer data once we've reviewed, validated and processed the files.</p>}
                {!props.success && <>
                  <p>Unfortunately, there was an error uploading the files.</p>
                  <p>Please try again.</p>
                </>}
              </div>
            </div>
          </Modal.Body>

          <div className='d-flex justify-content-end'>
            <ButtonPrimary
              className='m-0'
              title='OK'
              onClick={handleClose}
            />
          </div>
        </Wrapper>
      </Modal>
    </>
  );
};

LoAUploadCompleteModal.defaultProps = defaultProps;
