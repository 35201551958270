import styled from 'styled-components';
import * as variables from '../../../../common/styles/variables';
import * as mixins from '../../../../common/styles/mixins';
import * as colors from '../../../../common/styles/colors';
import { respondTo } from '../../../../common/styles/mixins';

export const MpanSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 25px;

  .modal-title {
    color: ${colors.hydroBlue};
    margin-bottom: 24px;
    font-size: 1.5rem;
  }

  .dropdown {
    width: auto;
  }

  .MpanSelectionOverviewMobile {
    ${mixins.widgetMobile};
  }

  .MpanSelectionOverview {
    ${mixins.widgetDesktop};

    p {
      margin-bottom: 22px;
    }
  }

  .container {
    width: 100%;
    margin: 0px;
    padding-left: 0px;
  }

  .containerMobile {
    width: 100%;
    margin: 0px;
    padding-left: 0px;
  }
  
  .left {
    float: left;
    padding-right: 20px;
  }
  
  .right {
    text-align: center!important;
  }

  .mpanDropdown {
    width: 100%;

    ${respondTo.tablet`
    width: 35%;
  `}
  }

  .pill-list {
    overflow: auto;
    border-radius: ${variables.inputRadius};
    

    margin-left: 0;
    margin-right: 0;

    .row {
      display: flex;
    }

    .pillTrash {
      cursor: pointer;

      .btn-close {
        border: 0;
        width: 2.5em;
        padding: 0;
      }
    }

    .btn-close-link {
      border: 0;
      width: 2.5em;
      padding: 0;
      text-decoration: unset;
    }

    .pillButton {
      border-radius: 15px;
      padding: ${variables.spacingXSmall};
      display: flex;
      cursor: default;
      margin-right: 20px;
      margin-bottom: 10px;
      border: 1px solid ${colors.tiaraGray};

      .pillDisplay {
        padding-left: ${variables.spacingXSmall};
      }
    }

    .pillButtonMobile {
      border-radius: 15px;
      padding: ${variables.spacingXSmall};
      display: flex;
      cursor: default;
      margin-right: 5px;
      margin-bottom: 10px;
      border: 1px solid ${colors.tiaraGray};

      .pillDisplay {
        
      }
    }
  }

  .mpanBox {
    display: contents;
  }

  .seeMoreBtn {
    margin-left: auto;
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
    padding-right: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    text-align: right;
    cursor: pointer;
  }
`;
