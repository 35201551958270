import { get, post, put } from '../../helpers/axiosWrapper';
import { globalApiParams } from './globals';
import { isTpiUser } from '../../helpers/tpiUser';

export const getMyMessages = async (
  url: string | undefined,
  customerEmail: string,
  context: any,
  read: boolean | undefined,
  page: number,
  references: Array<string>,
  sort?: Common.ISort,
  accountReferences?: Array<string>
): Promise<any> => {
  const tpiUser = isTpiUser();
  const userType = tpiUser ? 'TPIUsers' : 'Customers';

  const headers: Api.IMessagesParams = {
    sortField: sort?.field,
    sortDir: sort?.order,
    page,
    pageSize: globalApiParams.pageSize,
    title: globalApiParams.title,
    accountReferences: accountReferences,
    userType: userType,
    references: references,
  };

  let getMessagesUrl = '';
  if (read === undefined) {
    getMessagesUrl += '/alert/getMessages';
  } else {
    getMessagesUrl += `/alert/getMessages?read=${read}`;
  }

  return await get(url + getMessagesUrl, { headers }, context);
};

export const getCSVFileLink = async (url: string | undefined, context: any, msgId: number): Promise<any> => {
  return await get(url + `/alert/byid/${msgId}`, {}, context);
};

export const getUnreadMessageCount = async (
  url: string | undefined,
  context: any,
  accountReferences: Array<string>,
  userType: string,
  references: Array<string>
): Promise<any> => {
  let headers = {
    accountReferences: accountReferences,
    userType: userType,
    references: references,
  };
  return await get(url + `/alert/unreadcount`, { headers }, context);
};

export const myMessagesSaveAsRead = async (url: string | undefined, context: any, data: any): Promise<any> => {
  return await post(url + `/alert/saveasread`, { data }, context);
};

export const myMessagesSaveAsDeleted = async (url: string | undefined, context: any, data: any): Promise<any> => {
  return await put(url + `/alert/saveasdeleted`, data, context);
};

export const myMessagesSettings = async (
  url: string | undefined,
  essCustomerId: string,
  context: any,
  page: number,
  sort?: Common.ISort
): Promise<any> => {
  let headers: Api.IMessagesParams = {
    sortField: sort?.field,
    sortDir: sort?.order,
    page,
    pageSize: globalApiParams.pageSize,
  };
  return await get(url + `/site/activesitesbycustomerid/${essCustomerId}/`, { headers }, context);
};

export const myMessagesSettingsMeterReminders = async (
  url: string | undefined,
  context: any,
  data: any
): Promise<any> => {
  return await put(url + `/useralertprofile/items/`, data, context);
};

export const getCurrentBulkMessages = async (
  url: string | undefined,
  context: any,
  references: Array<string>
): Promise<any> => {
  const tpiUser = isTpiUser();

  const headers = {
    userType: tpiUser ? 'TPIUsers' : 'Customers',
    references: references,
  };

  return await get(url + `/alert/currentbulkmessages`, { headers }, context);
};
