import styled from 'styled-components';

import { smallRadius } from '../../styles/variables';
import { transitionFast } from '../../styles/mixins';

export const RowSelectorWrapper = styled.tr`
  ${transitionFast};
  cursor: pointer;

  td {
    &:first-child {
      cursor: default;
      border-top-left-radius: ${smallRadius};
      border-bottom-left-radius: ${smallRadius};
    }

    &:last-child {
      border-top-right-radius: ${smallRadius};
      border-bottom-right-radius: ${smallRadius};
    }
  }

  .actions-wrapper {
    opacity: 1;

    a {
      pointer-events: none;
    }
  }

  &.selected {
    td {
      background-color: ${({ theme }) => theme.bg.secondary} !important;
    }

    .actions-wrapper {
      opacity: 1;
      pointer-events: all;

      a {
        pointer-events: all;
      }
    }
  }

  &.open.selected.disabled-row {
    td span {
      opacity: 0.5;
    }
  }

  &.open {
    td {
      &:first-child {
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-bottom-right-radius: 0;
      }
    }
  }
`;
