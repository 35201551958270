import React from 'react';
import SortingArrows from './SortingArrows';

import { SortStyles } from './Sort.styles';

type SortProps = {
  title: string;
  dataValue: string;
  sortDetails?: Common.ISort;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  enableSort: boolean;
};

const defaultProps = {
  title: '',
  dataValue: '',
  onClick: undefined,
  enableSort: true,
};

export const Sort = (props: SortProps): JSX.Element => {
  const { title, sortDetails, dataValue } = props;
  const order = sortDetails?.field === dataValue ? sortDetails?.order : '';

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (props.enableSort && props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <SortStyles
      className={'d-flex align-items-end' + (!props.enableSort ? ' no-sort' : '')}
      href='/'
      data-value={dataValue}
      onClick={onClick}
    >
      {title}
      {props.enableSort && <SortingArrows order={order} onClick={onClick} />}
    </SortStyles>
  );
};

Sort.defaultProps = defaultProps;
