import React, { useState, useEffect, useContext } from 'react';
import IcomoonReact from 'icomoon-react';

import { FilterDropDownWrapper } from './FilterDropDown.styles';
import iconSet from '../../../assets/selection.json';
import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';
import { EssAccountsTypes } from '../../../types/account';

type FilterDropDownProps = {
  className?: string | undefined;
  options: Array<Common.IOption>;
  enabled: boolean;
  selectedIndex: number;
  selectedValue: string;
  selectedOption: string;
  selectText: string;
  showBlankValue: boolean;
  showNameValueFormat: boolean;
  showValue: boolean;
  showSelectedValue?: boolean;
  showSelectedIcon: boolean;
  showDropDownIcon: boolean;
  showIdOnly: boolean;
  handleChange: Function;
  selectedType: string;
};

const defaultProps = {
  handleChange: undefined,
  selectedIndex: undefined,
  className: '',
  selectText: '',
  selectedType: '',
  selectedValue: '',
  selectedOption: '',
  enabled: true,
  showValue: true,
  showIdOnly: false,
  showBlankValue: false,
  showSelectedIcon: true,
  showDropDownIcon: true,
  showSelectedValue: false,
  showNameValueFormat: false,
};

export const FilterDropDown = (props: FilterDropDownProps) => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const [active, setActive] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>(props.selectedValue);
  const [selectedIndex, setSelectedIndex] = useState<number>(props.selectedIndex);
  const [isCleared, setIsCleared] = useState<boolean>(false);

  // Initialise options
  let options = Array.from(props.options);
  if (props.showBlankValue) {
    const arr = options;
    arr.unshift({ value: '', display: props.selectText });
    options = arr;
  }

  useEffect(() => {
    if (props.selectedIndex !== undefined) {
      updateSelectIndex(props.selectedIndex);
    } else {
      const target = options.find(i => i.value === props.selectedValue);

      if (target) {
        setSelectedValue(props.selectedValue);
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const target = options?.[props.selectedIndex];

    setSelectedValue(target?.value || '');
    setSelectedIndex(props.selectedIndex);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedIndex]);

  useEffect(() => {
    if (globalContext.globalDetail.clearAllTrigger === true) {
      setIsCleared(true);
    }
  }, [globalContext.globalDetail.clearAllTrigger]);

  function updateSelectIndex(index: number) {
    // Check the range before updating
    if (index > options.length - 1) return;

    if (index < 0) return;

    setSelectedIndex(index);
  }

  function toggleActive() {
    if (!props.enabled) return;
    setActive(!active);
  }

  function loseFocus() {
    setActive(false);
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      setActive(false);

      // handleChange
      if (selectedIndex > -1) {
        let target = options[selectedIndex];
        props.handleChange(target);
      }

      return;
    }

    if (e.key === 'Escape') {
      setActive(false);
      return;
    }

    if (e.key === 'ArrowDown') {
      if (!active) {
        setActive(true);
        return;
      }
      updateSelectIndex(selectedIndex + 1);
    } else if (e.key === 'ArrowUp') {
      if (!active) {
        setActive(true);
        return;
      }
      updateSelectIndex(selectedIndex - 1);
    }
  }

  function selectItem(item: Common.IOption) {
    setIsCleared(false);
    setSelectedValue(item.value);

    let targetIndex = options.findIndex(i => i.value === item.value);
    if (targetIndex > -1) {
      updateSelectIndex(targetIndex);
      let target = options[targetIndex];
      props.handleChange(target, targetIndex);
    }
  }
  const selectedText = (props.showSelectedValue && selectedValue) || props.selectText;

  return (
    <FilterDropDownWrapper {...props} onClick={toggleActive} onBlur={loseFocus}>
      <div
        className={'wrapperdropdown' + (active ? ' active' : '') + (!props.enabled ? ' disabled' : '')}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
      >
        {props.selectText === 'MPAN/MPRN' && props.className !== undefined && props.className.includes('plain') ? (
          props.selectedType === EssAccountsTypes.Electricity ? (
            <div>
              <span className={'selectText' + (active ? ' active' : '')}>MPAN/</span>
              <span>MPRN</span>
              <IcomoonReact className='itemicon chevron' iconSet={iconSet} size={15} icon='chevrons-expand-vertical' />
            </div>
          ) : (
            <div>
              <span>MPAN</span>
              <span className={'selectText' + (active ? ' active' : '')}>/MPRN</span>
              <IcomoonReact className='itemicon chevron' iconSet={iconSet} size={15} icon='chevrons-expand-vertical' />
            </div>
          )
        ) : (
          <div>
            <div className={'selectText' + (active ? ' active' : '')}>{selectedText}</div>
            <IcomoonReact className='itemicon chevron' iconSet={iconSet} size={15} icon='chevrons-expand-vertical' />
          </div>
        )}

        <ul className='dropdown'>
          {props.enabled &&
            options.map(i =>
              i.value === selectedValue ? (
                <li
                  className={isCleared ? '' : 'selected'}
                  key={i.value}
                  value={i.value}
                  onClick={() => selectItem(i)}
                  title={''}
                >
                  <div className='dropdownitem'>
                    <div>
                      {props.showDropDownIcon && i.icon && (
                        <IcomoonReact className='itemicon' iconSet={iconSet} size={15} icon={i.icon} />
                      )}
                      {props.showValue && i.value}
                    </div>
                    {!props.showIdOnly && i.display && <div>{i.display}</div>}
                  </div>
                </li>
              ) : (
                <li key={i.value} value={i.value} onClick={() => selectItem(i)} title={''}>
                  <div className='dropdownitem'>
                    <div>
                      {props.showDropDownIcon && i.icon && (
                        <IcomoonReact className='itemicon' iconSet={iconSet} size={15} icon={i.icon} />
                      )}
                      {props.showValue && i.value}
                    </div>
                    {!props.showIdOnly && i.display && <div className='item__value'>{i.display}</div>}
                  </div>
                </li>
              )
            )}
        </ul>
      </div>
    </FilterDropDownWrapper>
  );
};

FilterDropDown.defaultProps = defaultProps;
