import styled from 'styled-components';

import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const StyledContentWrapper = styled.div`
  padding-right: 33px;

  padding-left: ${variables.spacingSmall};

  ${mixins.respondTo.tablet`
    padding-left: 0;
  `};
`;
