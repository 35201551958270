import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../common/components';

type FileTypesPermittedModalProps = {
  show: boolean;
  onHide: any;
};

const defaultProps = {
  show: true,
};

export const FileTypesPermittedModal = (props: FileTypesPermittedModalProps) => {
  const { show } = props;

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header className='pt-0 pb-0 pr-0'>
          <Modal.Title>File types permitted for upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='pt-3'>The following file types can be uploaded:</p>
          <div>
            <b>Images - </b>
            {'JPEG (or JPG), GIF, PNG, BMP'}
          </div>
          <div>
            <b>Structured Data files - </b>CSV, XML
          </div>
          <div>
            <b>Microsoft Word and Plain Text - </b>DOC, DOCX, RTF, TXT
          </div>
          <div>
            <b>Microsoft Excel - </b>XLS, XLSX
          </div>
          <div>
            <b>Other - </b>PDF
          </div>
        </Modal.Body>
        <Modal.Footer className='p-0'>
          <ButtonPrimary onClick={() => props.onHide()} className={'mr-0'} title={'Ok'} route='#' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

FileTypesPermittedModal.defaultProps = defaultProps;
