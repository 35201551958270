import { css } from 'styled-components';

import { darkBlue, white } from '../colors';

export const whiteTooltipStyles = css`
  .tooltip-white {
    opacity: 1 !important;

    .tooltip-inner {
      max-width: 270px;
      background-color: transparent;
      color: ${darkBlue} !important;
      text-align: left;
      padding: 0;
      box-shadow: 1px 2px 5px rgba(10, 20, 100, 0.2);
      line-height: 16.8px;

      & > * {
        border-radius: 4px;
      }
    }
  }

  .tooltip-narrow {
    max-width: 190px !important;
  }

  .tooltip-white.bs-tooltip-top .arrow:before {
    border-top-color: ${white};
  }

  .tooltip-white.bs-tooltip-bottom .arrow:before {
    border-bottom-color: ${white};
  }

  .tooltip-white.bs-tooltip-right .arrow:before {
    border-right-color: ${white};
  }

  .tooltip-white.bs-tooltip-left .arrow:before {
    border-left-color: ${white};
  }
`;

export const blackTooltipStyles = css`
  .tooltip-black.tooltip {
    .tooltip-inner {
      padding: 10px 30px;
      border-radius: 8px;
      background-color: ${darkBlue};
    }
  }

  .tooltip-black.tooltip.show {
    opacity: 1;
  }

  .tooltip-black.bs-tooltip-top .arrow:before {
    border-top-color: ${darkBlue};
  }

  .tooltip-black.bs-tooltip-bottom .arrow:before {
    border-bottom-color: ${darkBlue};
  }

  .tooltip-black.bs-tooltip-right .arrow:before {
    border-right-color: ${darkBlue};
  }

  .tooltip-black.bs-tooltip-left .arrow:before {
    border-left-color: ${darkBlue};
  }
`;
