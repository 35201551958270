// Modules
import { get } from '../../helpers/axiosWrapper';
import { globalApiParams } from './globals';

export const fetchBPCustomersEDW = async (url: string | undefined, context: any): Promise<Api.IEDWCustomers[]> =>
  await get(`${url}/user/getBPCustomers`, {}, context);

export const fetchEssAccountsByCustomerId = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.IEssAccountData[]>> =>
  await get(`${url}/account/getessaccountidbyesscustomerid/${essCustomerId}`, {}, context);

export const getAccountForDDSetup = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  essAccountId: string | null,
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.IAccountsForDDSetupPagedData>> => {
  let headers: Api.IAccountsForDDSetupParams = {
    sortField: sort?.field,
    sortDir: sort?.order,
    essAccountId: essAccountId || '',
    page,
    pageSize: globalApiParams.pageSize,
  };
  return await get(`${url}/account/accountsforddsetup/${essCustomerId}`, { headers }, context);
};

export const getAccountForCardPayment = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  essAccountId?: string,
  sort?: Common.ISort
): Promise<Common.IResult<Api.IAccountsForCardPayment>> => {
  let headers: Api.IGlobalParms = {
    sortField: sort?.field,
    sortDir: sort?.order,
  };

  if (essAccountId) {
    return await get(
      `${url}/account/getpaymentforcustomer/${essCustomerId}?essAccountId=${essAccountId}`,
      { headers },
      context
    );
  } else {
    return await get(`${url}/account/getpaymentforcustomer/${essCustomerId}`, { headers }, context);
  }
};

export const getAccountDetailsByCustomer = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Api.IAccountDetailsByCustomer> =>
  await get(`${url}/ers/v1/customers/${essCustomerId}`, {}, context);
