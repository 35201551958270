import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DateObject } from 'react-multi-date-picker';
import { ButtonPrimary, ButtonTerinary } from '../../../common/components';
import { CustomDatePicker } from '..';
import { razzmatazzPink, hydroBlue } from '../../styles/colors';
import { REVERSED_DATE_FORMAT_HYPHEN } from '../../constants';

type FromToDateRangePickerModalProps = {
  isOpusBrand?: boolean;
  title?: string;
  message?: string;
  show: boolean;
  onClose: () => void;
  showIcon?: boolean;
  startDate?: DateObject;
  endDate?: DateObject;
  handlePeriodFilterChange?: Function;
};

const currentDate = new Date();
const startDateWindow = new DateObject({
  year: currentDate.getUTCFullYear() - 2,
  month: currentDate.getUTCMonth() + 1,
  day: 1,
});
const endDateWindow = new DateObject({
  year: currentDate.getUTCFullYear(),
  month: currentDate.getUTCMonth() + 1,
  day: currentDate.getUTCDate(),
});

const defaultProps = {
  title: 'Select Date Range',
  message: 'Please select the dates and click submit',
  show: false,
  showIcon: true,
};

export const FromToDateRangePickerModal = (props: FromToDateRangePickerModalProps) => {
  const [startDate, setStartDate] = useState<any>(props.startDate);
  const [endDate, setEndDate] = useState<any>(props.endDate);

  const [startDateMaxDate, setStartDateMaxDate] = useState<any>(endDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN));
  const [endDateMinDate, setEndDateMinDate] = useState<any>(startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN));

  const onChangeStartDate = (e: any) => {
    setStartDate(e);
    setEndDateMinDate(e);
  };

  const onChangeEndDate = (e: any) => {
    setEndDate(e);
    setStartDateMaxDate(e);
  };

  const onClose = (e: any) => {
    e.preventDefault();
    props.onClose();
  };

  const onSumbit = (e: any) => {
    e.preventDefault();

    const dates: DateObject[] = [startDate, endDate];

    if (!startDate) {
      dates[0] = startDateWindow;
    }

    if (!endDate) {
      dates[1] = endDateWindow;
    }

    if (props.handlePeriodFilterChange) {
      props.handlePeriodFilterChange(dates);
    }
  };

  return (
    <Modal
      animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
      className={'custom-modal'}
      show={props.show}
      centered={true}
    >
      <Modal.Header style={{ justifyContent: 'center' }}>
        <h3 style={props.isOpusBrand ? { color: razzmatazzPink } : { color: hydroBlue }}>{props.title}</h3>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column'>
        <div>
          <p style={{ textAlign: 'center' }}>{props.message}</p>
          <div className='d-flex justify-content-between'>
            <div className='mr-1'>
              <span>From</span>
              <CustomDatePicker
                date={startDate}
                minDate={startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
                maxDate={startDateMaxDate}
                showIcon={props.showIcon}
                fixMainPosition={true}
                onChange={e => onChangeStartDate(e)}
              />
            </div>
            <div className='ml-1'>
              <span>To</span>
              <CustomDatePicker
                disabled={startDate == null}
                date={endDate}
                minDate={endDateMinDate}
                maxDate={endDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
                showIcon={props.showIcon}
                fixMainPosition={true}
                onChange={e => onChangeEndDate(e)}
              />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end mt-5'>
          <ButtonTerinary onClick={e => onClose(e)} title='Cancel' className='my-0 mr-4' />
          <ButtonPrimary onClick={e => onSumbit(e)} title='Submit' className='my-0' />
        </div>
      </Modal.Body>
    </Modal>
  );
};

FromToDateRangePickerModal.defaultProps = defaultProps;

export default FromToDateRangePickerModal;
