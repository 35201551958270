import React, { useState, useEffect, useRef } from 'react';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../assets/selection.json';
import { DropDownWrapper } from './DropDown.styles';

type DropDownProps<OptionValueType> = {
  options: Array<Common.ITypedOption<OptionValueType>>;
  enabled: boolean;
  selectedIndex: number;
  selectedValue: OptionValueType;
  selectText: string;
  showBlankValue: boolean;
  showNameValueFormat: boolean;
  showValue: boolean;
  showSelectedIcon: boolean;
  showDropDownIcon: boolean;
  handleChange: Function;
  className?: string;
  preselectSingleValue?: boolean;
  checkIsSelectedValue: (
    currentItem: OptionValueType,
    selectedValue: OptionValueType | undefined,
    index: number
  ) => any;
  // If you pass this prop and `showBlankValue` is `true`, make sure to add fallback value in return.
  // Thus `blank value` option will have defined "key" attribute, otherwise you will see an error in console
  getOptionsKey: (option: OptionValueType) => any;
  resetState: boolean;
  afterStateResetCallback: Function;
};

const defaultProps = {
  options: [],
  enabled: true,
  className: '',
  selectText: '',
  showValue: true,
  resetState: false,
  showBlankValue: false,
  showSelectedIcon: true,
  showDropDownIcon: true,
  handleChange: undefined,
  selectedIndex: undefined,
  selectedValue: undefined,
  showNameValueFormat: false,
  preselectSingleValue: false,
  checkIsSelectedValue: undefined,
  afterStateResetCallback: () => true,
  getOptionsKey: (value: any) => value,
};

export const DropDown = <OptionValueType extends Object>(props: DropDownProps<OptionValueType>) => {
  // Refs
  const prevOptionsRef = useRef<Common.ITypedOption<OptionValueType>[]>([]);

  // Initialise options
  let options: Common.ITypedOption<OptionValueType>[] = JSON.parse(JSON.stringify(props.options)); // Deep clone an array
  let initialValue: OptionValueType = props.selectedValue;
  let initialIndex = props.selectedIndex;

  if (props.showBlankValue) {
    const value: unknown = '';

    // Add first element with empty value
    options.unshift({ value: value as OptionValueType, display: props.selectText });
    initialIndex = 0;
    initialValue = options[initialIndex].value;
  }

  // State
  const [active, setActive] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<OptionValueType>(initialValue);
  const [selectedItem, setSelectedItem] = useState<Common.ITypedOption<OptionValueType>>();
  const [, setSelectedIndex] = useState<number>(initialIndex);

  useEffect(() => {
    if (props.selectedIndex !== undefined) {
      updateSelectIndex(props.selectedIndex);
    } else {
      let target = options.find(i => i.value === props.selectedValue);

      if (target) {
        setSelectedValue(props.selectedValue);
        setSelectedItem(target);
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedValue, props.selectedIndex]);

  useEffect(() => {
    if (props.resetState) {
      setActive(false);
      setSelectedValue(props.selectedValue);
      setSelectedItem(undefined);
      setSelectedIndex(props.selectedIndex);
      if (typeof props.afterStateResetCallback === 'function') {
        props.afterStateResetCallback();
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetState]);

  useEffect(() => {
    if (props.preselectSingleValue) {
      // This needed for case where options length don't change but value does
      // We need to campare those values and if they are different, select new value
      if (props.options.length === 1 && prevOptionsRef.current.length === 1) {
        const [currentItem] = props.options;
        const [prevItem] = prevOptionsRef.current;

        if (prevItem.display !== currentItem.display || prevItem.value !== currentItem.value) {
          const newSelectedIndex = 0 + Number(!!props.showBlankValue);

          setSelectedItem(currentItem);
          setSelectedIndex(newSelectedIndex);
          setSelectedValue(currentItem.value);
        }
      }
    }
    prevOptionsRef.current = props.options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  useEffect(() => {
    if (props.options.length === 1 && props.preselectSingleValue) {
      const newSelectedIndex = 0 + Number(!!props.showBlankValue);
      const newSelectedItem = props.options[0];

      setSelectedItem(newSelectedItem);
      setSelectedIndex(newSelectedIndex);
      setSelectedValue(newSelectedItem.value);
    }
    // We don't need `showBlankValue` and `preselectSingleValue` in dependency list as we need only its initial value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options.length]);

  function updateSelectIndex(index: number) {
    // Check the range before updating
    if (index > options.length - 1) return;

    if (index < 0) return;

    setSelectedIndex(index);
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    // TODO: Rework needed
    return;
    // if (e.key === 'Enter') {
    //   setActive(false);

    //   // handleChange
    //   if (selectedIndex > -1) {
    //     let target = options[selectedIndex];
    //     props.handleChange(target);
    //   }

    //   return;
    // }

    // if (e.key === 'Escape') {
    //   setActive(false);
    //   return;
    // }

    // if (e.key === 'ArrowDown') {
    //   if (!active) {
    //     setActive(true);
    //     return;
    //   }
    //   updateSelectIndex(selectedIndex + 1);
    // } else if (e.key === 'ArrowUp') {
    //   if (!active) {
    //     setActive(true);
    //     return;
    //   }
    //   updateSelectIndex(selectedIndex - 1);
    // }
  }

  function selectItem(item: Common.ITypedOption<OptionValueType>, index: number) {
    setSelectedValue(item.value);
    setSelectedItem(item);
    updateSelectIndex(index);
    if (typeof props.handleChange === 'function') {
      props.handleChange(item, index);
    }
  }

  function formatDisplayValue(index: number): JSX.Element | JSX.Element[] {
    if (props.showNameValueFormat) {
      return (
        <span key={`value-${index}`}>
          <>
            {selectedItem?.display} -{selectedValue}
          </>
        </span>
      );
    }

    let result: any = [];
    if (props.showSelectedIcon && selectedItem?.icon) {
      result.push(
        <IcomoonReact
          key={`itemIcon-${index}`}
          className='itemicon'
          iconSet={iconSet}
          size={15}
          icon={selectedItem.icon}
        />
      );
    }

    if (props.showValue) {
      result.push(selectedValue);
    }
    result.push(
      <span className='text-truncate' key={`item-${index}`}>
        {selectedItem?.display}
      </span>
    );

    return result;
  }

  const openDropdown = () => {
    if (active) setActive(false);
    else setActive(true);
  };

  const closeDropdown = () => {
    setActive(false);
  };

  return (
    <DropDownWrapper {...props} onClick={openDropdown} onBlur={closeDropdown}>
      <div
        className={'wrapperdropdown' + (active ? ' active' : '') + (!props.enabled ? ' disabled' : '')}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
      >
        {selectedValue && props.enabled ? (
          <div className='selecteditem'>
            <>
              {formatDisplayValue(0)}
              <IcomoonReact className='itemicon chevron' iconSet={iconSet} size={15} icon='chevrons-expand-vertical' />
            </>
          </div>
        ) : (
          <div>
            {props.selectText}
            <IcomoonReact className='itemicon chevron' iconSet={iconSet} size={15} icon='chevrons-expand-vertical' />
          </div>
        )}

        <ul className='dropdown'>
          {props.enabled &&
            options.map((i, index) =>
              props.checkIsSelectedValue?.(i.value, selectedValue, index) || i.value === selectedValue ? (
                <li className='selected' key={props.getOptionsKey(i.value)} onClick={() => selectItem(i, index)}>
                  <div className='dropdownitem'>
                    <div>
                      <>
                        {props.showDropDownIcon && i.icon && (
                          <IcomoonReact className='itemicon' iconSet={iconSet} size={15} icon={i.icon} />
                        )}
                        {props.showValue && i.value}
                      </>
                    </div>
                    <div>{i.display}</div>
                  </div>
                </li>
              ) : (
                <li key={props.getOptionsKey(i.value)} onClick={() => selectItem(i, index)}>
                  <div className='dropdownitem'>
                    <div>
                      <>
                        {props.showDropDownIcon && i.icon && (
                          <IcomoonReact className='itemicon' iconSet={iconSet} size={15} icon={i.icon} />
                        )}
                        {props.showValue && i.value}
                      </>
                    </div>
                    <div>{i.display}</div>
                  </div>
                </li>
              )
            )}
        </ul>
      </div>
    </DropDownWrapper>
  );
};

DropDown.defaultProps = defaultProps;
