import { createGlobalStyle } from 'styled-components/macro';
import { BRANDS } from '../../../consts';

const StyledDraxFont = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
  }
`;

const StyledOpusFont = createGlobalStyle`
  @font-face {
    font-family: 'avertabold';
    src: url('../../../assets/fonts/averta-bold-webfont.eot');
    src: url('../../../assets/fonts/averta-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/averta-bold-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/averta-bold-webfont.woff') format('woff'),
    url('../../../assets/fonts/averta-bold-webfont.ttf') format('truetype'),
    url('../../../assets/fonts/averta-bold-webfont.svg#avertabold') format('svg');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'avertalight';
    src: url('../../../assets/fonts/averta-light-webfont.eot');
    src: url('../../../assets/fonts/averta-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/averta-light-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/averta-light-webfont.woff') format('woff'),
    url('../../../assets/fonts/averta-light-webfont.ttf') format('truetype'),
    url('../../../assets/fonts/averta-light-webfont.svg#avertalight') format('svg');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'avertaregular';
    src: url('../../../assets/fonts/averta-regular-webfont.eot');
    src: url('../../../assets/fonts/averta-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/averta-regular-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/averta-regular-webfont.woff') format('woff'),
    url('../../../assets/fonts/averta-regular-webfont.ttf') format('truetype'),
    url('../../../assets/fonts/averta-regular-webfont.svg#avertaregular') format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'avertaitalic';
    src: url('../../../assets/fonts/averta-regularitalic-webfont.eot');
    src: url('../../../assets/fonts/averta-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/averta-regularitalic-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/averta-regularitalic-webfont.woff') format('woff'),
    url('../../../assets/fonts/averta-regularitalic-webfont.ttf') format('truetype'),
    url('../../../assets/fonts/averta-regularitalic-webfont.svg#avertaregular') format('svg');
    font-weight: normal;
    font-style: italic;
  }
`;

interface IFonts {
  [key: string]: any;
}

export const Fonts: IFonts = {
  [BRANDS.DRAX]: StyledDraxFont,
  [BRANDS.OPUS]: StyledOpusFont,
};
