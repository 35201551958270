import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import AuthService from '../services/authService';
import { getHeaders } from './getHeaders';
import { TPI_ACCESSID } from '../common/constants';

// TODO - TEMPORARY
export let temporary = {
  billingpartyid: '',
  originalCustomerId: '',
  accessId: '',
};
const [mockedCustomerId, mockedBillingPartyId] = (localStorage.getItem('mock') || '').split(',');

const addHeader = (
  url: string,
  data: AxiosRequestConfig,
  context?: AuthService,
  useMockBillingPartyId: boolean = true
): AxiosRequestConfig => {
  let billingpartyidToUse = (useMockBillingPartyId && mockedBillingPartyId) || temporary.billingpartyid;

  if (!data.headers) data.headers = {};

  data.headers = {
    ...data.headers,
  };
  if (billingpartyidToUse) {
    data.headers['tmp-ers-billing-party-id'] = billingpartyidToUse;
  }

  if (context) {
    const token = context.getToken();
    const tokenHeaders = getHeaders(token);

    data.headers.Authorization = 'Bearer ' + tokenHeaders.token;
  }

  // Don't add access-id when requesting one
  if (!url.endsWith('/api-key/tpi') && !url.endsWith('/tpis/customers?loa-status=Active&online-access=true')) {
    if (temporary.accessId) {
      data.headers['access-id'] = temporary.accessId;
    } else {
      let accessId = localStorage.getItem(TPI_ACCESSID);
      if (accessId) {
        data.headers['access-id'] = accessId;
      }
    }
  }

  return data;
};

export const get = async (url: string, data: any, context: AuthService) => {
  let config = JSON.parse(JSON.stringify(data));
  let requestURL = url;
  let isCustomerIdReplacedSuccessfully = false;

  try {
    // Temporary solution to give possibility for testers and developers to replace customerId and billingpartyid to get different data
    if (temporary.originalCustomerId && mockedCustomerId) {
      // Replace customerId in url
      if (requestURL?.includes(temporary.originalCustomerId)) {
        const replaceRegex = new RegExp(temporary.originalCustomerId, 'gi');

        requestURL = requestURL?.replace(replaceRegex, mockedCustomerId);
        isCustomerIdReplacedSuccessfully = true;
      }
    }
  } catch (error) {
    const message = 'Failed to replace customer id in url';

    console.error(message, error);
    toast(message);
    requestURL = url;
  }
  config = addHeader(requestURL, config, context, isCustomerIdReplacedSuccessfully);

  return await axios
    .get(requestURL, config)
    .then(response => {
      if (response && response.status === 200) {
        return response.data;
      } else {
        console.error(response.data);
        return response.data;
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const getExternal = async (url: string) =>
  await axios
    .get(url)
    .then(response => {
      if (response && response.status === 200) {
        return response.data;
      } else {
        console.error(response.data);
        return response.data;
      }
    })
    .catch(error => {
      console.error(error);
    });

export const getFile = async (url: string, data: any, context: AuthService) => {
  data = addHeader(url, data, context);
  data.responseType = 'blob';
  //TODO: tempFileName is a temporary fix. API doesn't provide filename.
  const tempFileName = `doc-${data.headers.docIds.join('-')}.zip`;

  return await axios
    .get(url, data)
    .then(response => {
      let contentDisposition = response.headers['content-disposition'];
      let fileName = contentDisposition?.split('filename=')[1].split(';')[0];
      // remove outer quotes
      fileName = fileName?.replace(/"/g, '') || tempFileName;

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error(error);
    });
};

export const getSingleFile = async (url: string, data: any, context: AuthService) => {
  data = addHeader(url, data, context);
  data.responseType = 'blob';

  return await axios.get(url, data).then(response => {
    if (response && response.status === 204) {
      throw new Error('No content');
    }

    let contentDisposition = response.headers['content-disposition'];
    let fileName = contentDisposition?.split('filename=')[1].split(';')[0];
    // remove outer quotes
    fileName = fileName?.replace(/"/g, '');

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

export const requestFile = async (url: string, data: AxiosRequestConfig, context: AuthService) => {
  const headers = addHeader(url, {}, context);

  return await axios
    .post(url, data, headers)
    .then(response => {
      let contentDisposition = response.headers['content-disposition'];
      let fileName = contentDisposition.split('filename=')[1].split(';')[0];
      // remove outer quotes
      fileName = fileName.replace(/"/g, '');

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error(error);
    });
};

export const requestFileResponseTypeBlob = async (url: string, data: AxiosRequestConfig, context: AuthService) => {
  const headers = addHeader(url, {}, context);
  headers.responseType = 'blob';

  return await axios
    .post(url, data, headers)
    .then(response => {
      let contentDisposition = response.headers['content-disposition'];
      let fileName = contentDisposition.split('filename=')[1].split(';')[0];
      // remove outer quotes
      fileName = fileName.replace(/"/g, '');

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error(error);
    });
};

export const post = async (url: string, config: AxiosRequestConfig = {}, context?: AuthService) => {
  config = addHeader(url, config, context);
  const { data, ...restConfig } = config;

  return await axios
    .post(url, data, restConfig)
    .then(response => {
      if (response.data.success || response.status === 200) {
        return response.data || response;
      } else {
        return response.data;
      }
    })
    .catch(error => {
      if (error.response) return error.response;
      console.error(error.response);
      return error;
    });
};

export const postReturnResponse = async (url: string, config: AxiosRequestConfig = {}, context?: AuthService) => {
  config = addHeader(url, config, context);
  const { data, ...restConfig } = config;

  return await axios
    .post(url, data, restConfig)
    .then(response => {
      return response;
    })
    .catch(error => {
      if (error.response) return error.response;
      console.error(error.response);
      return error;
    });
};

export const postForm = async (
  url: string,
  config: AxiosRequestConfig = {},
  formData: FormData,
  onUploadProgress: Function,
  context?: AuthService
) => {
  config = addHeader(url, config, context);

  config.onUploadProgress = function (progressEvent: any) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    onUploadProgress(percentCompleted);
  };

  const { data, ...restConfig } = config;

  return await axios
    .post(url, formData, restConfig)
    .then(response => {
      if (response.data.success || response.status === 200) {
        return response;
      } else {
        return response.data;
      }
    })
    .catch(error => {
      if (error.response) return error.response;
      console.error(error.response);
      return error;
    });
};

export const put = async (url: string, data: AxiosRequestConfig, context?: AuthService) => {
  const headers = addHeader(url, {}, context);

  return await axios
    .put(url, data, headers)
    .then(response => {
      if (response.data.success || response.status === 200) {
        return response;
      } else {
        return response.data;
      }
    })
    .catch(error => {
      if (error.response) return error.response;
      console.error(error.response);
      return error;
    });
};

export const patch = async (url: string, data: AxiosRequestConfig, context?: AuthService) => {
  const headers = addHeader(url, {}, context);

  return await axios
    .patch(url, data, headers)
    .then(response => {
      if (response.data.success || response.status === 200) {
        return response;
      } else {
        return response.data;
      }
    })
    .catch(error => {
      if (error.response) return error.response;
      console.error(error.response);
      return error;
    });
};

export const requestZipFile = async (
  url: string,
  data: AxiosRequestConfig,
  context: AuthService,
  fileName: string,
  errorMessage: string
) => {
  let config = addHeader(url, {}, context);
  config.responseType = 'blob';

  return await axios
    .post(url, data, config)
    .then(response => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${fileName}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: false,
      });
      console.error(error);
    });
};
