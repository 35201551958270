import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { DataTypeSelectionWrapper } from './styles/DataTypeSelection.styles';
import PortalFilter from '../../../common/components/PortalFilter/PortalFilter';
import { getHHSagaProcess } from '../../../common/api/dataApi';
import { useAuthState } from '../../../providers/authProvider';
import { HHDataRequestModal } from '../../mymessages/modalTemplates/HHDataRequestModal';

type DataTypeSelectionProps = {
  accountRefSelected: Common.IOption;
  metersArray: Array<any>;
  handleDataTypeSelected: Function;
  handleAccountMpans: Function;
  handleLoadingData: Function;
  resetData: boolean;
};

const defaultProps = {
  accountRefSelected: { value: '', display: '' },
  metersArray: [],
};

export const DataTypeSelection = (props: DataTypeSelectionProps) => {
  const authContext = useAuthState();
  const [dataTypeSelectedIndex, setDataTypeSelectedIndex] = useState<number | undefined>(undefined);
  const [meterTypeOptions, setMeterTypeOptions] = useState<Array<Common.IOption>>([]);
  const [selectedMeterType, setSelectedMeterType] = useState<string>('');
  const [showHHDataRequestModal, setShowHHDataRequestModal] = useState<boolean>(false);

  useEffect(() => {
    setMeterTypeOptions([]);
    setDataTypeSelectedIndex(-1);
    setSelectedMeterType('');
  }, [props.resetData]);

  useEffect(() => {
    let meterTypeData: Array<Common.IOption> = [];
    const amrMeterTypes = ['NCAMR', 'RCAMR', 'RCAMY'];

    if (props.metersArray && props.metersArray.length > 0) {
      for (var item in props.metersArray) {
        if (props.metersArray[item]['hh-or-nhh'] === 'HH') {
          meterTypeData.push({ display: 'HH data', value: 'HalfHourly' });
        } else if (
          props.metersArray[item]['smart-meter'] === true &&
          props.metersArray[item]['meter-type'].toUpperCase().startsWith('S')
        ) {
          meterTypeData.push({ display: 'Smart meter data', value: 'Smart' });
        } else if (
          props.metersArray[item]['smart-meter'] === true &&
          amrMeterTypes.includes(props.metersArray[item]['meter-type'])
        ) {
          meterTypeData.push({ display: 'AMR data', value: 'AutomaticMeterRead' });
        }
      }

      //Remove duplicates
      const uniqueArray = meterTypeData.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          meterTypeData.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });

      setMeterTypeOptions(uniqueArray);
    }
  }, [props.metersArray]);

  useEffect(() => {
    if (meterTypeOptions.length === 1) {
      setDataTypeSelectedIndex(0);
      handleDataTypeSelection(meterTypeOptions[0]);
    }
  }, [meterTypeOptions]);

  const handleDataTypeSelection = (dataType: any) => {
    if (dataType) {
      sagaExistingRequestChecker(dataType);
    } else {
      props.handleDataTypeSelected({ value: '', display: '' });
    }
  };

  const sagaExistingRequestChecker = async (dataType: Common.IOption) => {
    props.handleLoadingData(true);

    const hhSagaChecker: Common.IResult<Array<Api.IHHSagaProcessingData>> = await getHHSagaProcess(
      process.env.REACT_APP_API_URL,
      props.accountRefSelected.value,
      dataType.value,
      authContext
    );

    if (hhSagaChecker && hhSagaChecker.success) {
      if (hhSagaChecker.data[0].processing === false) {
        setSelectedMeterType(dataType.value);
        props.handleDataTypeSelected(dataType);
        createMpanArray(props.metersArray, dataType.value);
      } else {
        setSelectedMeterType(dataType.value);
        setShowHHDataRequestModal(true);
        props.handleDataTypeSelected({ value: '', display: '' });
        setDataTypeSelectedIndex(-1);
      }
    } else {
      const message = 'API error calling: getHHSagaProcess';
      toast.error(message, { position: 'bottom-right' });
    }
    props.handleLoadingData(false);
  };

  const createMpanArray = (meters: any[], selectedMeterType: string) => {
    let selectedAccountMpans: number[] = [];
    let selectedAccountMpansNew: Common.IOption[] = [];

    const selectedAccountMpansByMeterType = meters.filter(item => item.meterTypeValue === selectedMeterType);

    selectedAccountMpansByMeterType.map(meter => selectedAccountMpans.push(meter['mpan-core']));
    const selectedAccountMpansRemoveDup: number[] = removeDuplicates(selectedAccountMpans);

    selectedAccountMpansRemoveDup.map(meter =>
      selectedAccountMpansNew.push({ value: meter.toString(), display: meter.toString() })
    );
    props.handleAccountMpans(selectedAccountMpansNew);
  };

  function removeDuplicates(arr: any) {
    let unique: any[] = [];
    arr.forEach((element: any) => {
      if (!unique.includes(element)) {
        unique.push(element);
      }
    });
    return unique;
  }

  return (
    <>
      <DataTypeSelectionWrapper {...props}>
        <div className='DataTypeSelectionOverview'>
          <div className='container'>
            <div className='left'>Please select the type of HH data you would like to download</div>
            <div className='right text-center'>
              <PortalFilter
                className={'dropdown'}
                selectedIndex={dataTypeSelectedIndex}
                options={meterTypeOptions}
                placeholder={'Select data type'}
                onChange={handleDataTypeSelection}
                isClearable={true}
              />
            </div>
          </div>
        </div>
        <div className='DataTypeSelectionOverviewMobile'>
          <div className='left mb-3'>Please select the type of HH data you would like to download</div>
          <div className='text-center'>
            <PortalFilter
              className={'dropdown'}
              selectedIndex={dataTypeSelectedIndex}
              options={meterTypeOptions}
              placeholder={'Select data type'}
              onChange={handleDataTypeSelection}
              isClearable={true}
            />
          </div>
        </div>
      </DataTypeSelectionWrapper>

      <HHDataRequestModal
        show={showHHDataRequestModal}
        onHide={() => {
          setShowHHDataRequestModal(false);
          setSelectedMeterType('');
        }}
        meterType={selectedMeterType}
      />
    </>
  );
};

DataTypeSelection.defaultProps = defaultProps;
