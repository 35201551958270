import { breakpoints } from '../../../common/styles/variables';

const getMediaSize = () => {
  const windowWidth = document.body.clientWidth;
  let size;
  if (windowWidth >= breakpoints.tablet) {
      size = 'isDesktop';
  } else if (
      windowWidth <= breakpoints.tablet &&
      windowWidth >= breakpoints.mobile
  ) {
      size = 'isTablet';
  } else {
      size = 'isMobile';
  }
  return size;
}

export { getMediaSize };
