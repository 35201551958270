// Modules
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';

// Context
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

// Components
import { User } from '../icons';
import { Icon } from '../../common/components';
import { ExternalLink } from '../../common/components';
import { documentClassName } from '../utils/text/text';
import { ButtonPrimary } from '../../common/components';
import SelectCustomer from './selectcustomer/SelectCustomer';
import { NavigationTemplate } from './templates/NavigationTemplate';
import { OpusProfileContactInfo } from '../header/OpusProfileContactInfo';

// Utils
import logos from '../icons/logos';
import { getMediaSize } from '../utils/media/media';
import { TagManager } from '../utils/analytics/TagManager';
import { isDraxBrand, isOpusBrand } from '../../utils/common';

// Styles
import { SideMenuWrapper, SideMenuText } from './SideMenu.styles';
import * as colors from '../../common/styles/colors';
import { temporary } from '../../helpers/axiosWrapper';

// Consts
import { TPI_ACCESSID, TPI_CUSTOMERS } from '../../common/constants';
import { HelpSupport } from '../header/HelpSupport';
import { Modal } from 'react-bootstrap';
import { MeterReadUploadSuccess } from '../mymessages/modalTemplates/MeterReadUploadSuccess';
import { ModalTemplate } from '../mymessages/modalTemplates/ModalTemplate';

export const SideMenu = () => {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const { userDetail, isSMEBroker, isCorpBroker, setUserDetail, isSMECustomer, isCorpCustomer } =
    useContext<UserDetailContextProps>(UserDetailContext);

  const [showHelpSupportModal, setShowHelpSupportModal] = useState<boolean>(false);

  const { desktopLogo: DesktopLogo, mobileLogo: MobileLogo }: any = process.env.REACT_APP_BRAND
    ? logos[process.env.REACT_APP_BRAND]
    : {};

  const isIndexPage = documentClassName() === 'indexPage';

  const handleViewAsCustomerToggle = () => {
    const viewAsCustomerToggle = !userDetail.viewAsCustomerToggle;
    let essCustomerId = userDetail.essCustomerId;
    let hasRenewable = userDetail.hasRenewable;
    let hasElectricity = userDetail.hasElectricity;
    let hasGas = userDetail.hasGas;

    let viewAsCustomerSelected = userDetail.viewAsCustomerSelected;
    if (!viewAsCustomerToggle) {
      viewAsCustomerSelected = false;
      essCustomerId = userDetail.loggedInDetails.essCustomerId;
      hasRenewable = userDetail.loggedInDetails.hasRenewable;
      hasElectricity = userDetail.loggedInDetails.hasElectricity;
      hasGas = userDetail.loggedInDetails.hasGas;
    }

    // log for 35984
    console.log('HandleViewAsCustomerToggle');

    setUserDetail({
      ...userDetail,
      viewAsCustomerToggle,
      viewAsCustomerSelected,
      essCustomerId,
      hasRenewable,
      hasElectricity,
      hasGas,
    });
  };

  const handleCustomerChange = (item: Common.IExpandedCustomerOption) => {
    // log for 35984
    console.log('HandleCustomerChange');

    setUserDetail({
      ...userDetail,
      essCustomerId: item.value,
      hasRenewable: item.hasRenewable,
      hasElectricity: item.hasElectricity,
      hasGas: item.hasGas,
      viewAsCustomerSelected: true,
    });
  };

  const handleOpenMenu = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      filterActive: false,
      isSideMenuActive: true,
    });
  };

  const handleCloseMenu = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      isSideMenuActive: false,
    });
  };

  const resetFilters = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      filterActive: false,
    });
  };

  const checkMediSize = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      isSideMenuActive: getMediaSize() === 'isDesktop',
    });
  };

  const showBrokerLandingPageRef: any = useRef(globalContext.globalDetail.showBrokerLandingPage);

  const onResizeHandler = () => {
    if (getMediaSize() === 'isDesktop') {
      const showBrokerLandingPage = showBrokerLandingPageRef.current;
      if (!showBrokerLandingPage) {
        globalContext.setGlobalDetail({
          ...globalContext.globalDetail,
          isSideMenuActive: true,
          showBrokerLandingPage,
        });
      }
    }
  };

  const onHowToLinkClicked = () => {
    TagManager.pushData({
      event: 'Launch How To Guide',
    });
  };

  const onChangePasswordClick = () => {
    TagManager.pushData({
      event: 'Launch password reset',
    });
  };

  const handleReturnToCustomerSelection = () => {
    temporary.accessId = '';

    localStorage.removeItem(TPI_CUSTOMERS);
    localStorage.removeItem(TPI_ACCESSID);

    setUserDetail({
      ...userDetail,
      loggedInDetails: {
        essCustomerId: '',
        hasRenewable: false,
        hasElectricity: false,
        hasGas: false,
      },
      customerAccounts: [],
      hasUnreadMessages: false,
      selectedCustomers: [],
    });

    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      showBrokerLandingPage: true,
    });

    TagManager.pushData({
      event: 'Return to customer selection (TPI)',
    });
  };

  useEffect(() => {
    const showBrokerLandingPage = globalContext.globalDetail.showBrokerLandingPage;
    showBrokerLandingPageRef.current = showBrokerLandingPage;
  }, [globalContext.globalDetail.showBrokerLandingPage]);

  useEffect(() => {
    window.addEventListener('resize', onResizeHandler);
    window.addEventListener('orientationchange', checkMediSize);
    window.addEventListener('load', checkMediSize);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
      window.removeEventListener('load', checkMediSize);
      window.removeEventListener('orientationchange', checkMediSize);
    };
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.globalDetail]);

  const isShowingForename = isSMECustomer() || isSMEBroker() || isCorpBroker() || isCorpCustomer();
  const isShowingLastname = false;
  const isShowingId = isOpusBrand && (isSMECustomer() || isCorpCustomer());
  // TODO: Allow internal page only for users with "support" role
  const isInternalPageAllowed = parseInt(userDetail.essCustomerId, 10) === 909354; // isDraxBrand;
  const isCurrentPageInternal = useRouteMatch({ path: '/home/internal', strict: true });
  let logoLink = '/home';

  if (isInternalPageAllowed && !isCurrentPageInternal) {
    logoLink = '/home/internal';
  }

  const fullCustomerDetails = [
    userDetail.userProfile?.customerIds?.map((account: Api.ICustomer, index) => {
        if (!account) {
          return null;
        }

        const essAccountID = account.customerId;
        const accountName = account.description;

        const total = userDetail.userProfile?.customerIds.length;

        const isFinalRow = (index: number): boolean => {

          if (total <= 3)
            return false;

          if (!seeMore) {
            return index === (3-1);
          }

          if (seeMore) {
            return index === total-1;
          }

          return false;
        }

        return (
          <div key={`${accountName}-${index}`} className='row pl-3 customerRow'>

            <p>{essAccountID}</p>
            <span className={essAccountID.length < 7 ? 'mx-2' : 'mx-1'}>-</span>

            {!isFinalRow(index) &&
              <div className='customerRowAccountName'>
                <span title={accountName}>{accountName}</span>
              </div>
            }
            {isFinalRow(index) &&
              <>
                <div className='customerRowAccountNameLast'>
                  <span title={accountName}>{accountName}</span>
                </div>
                <div className='seeMoreBtn' onClick={() => setSeeMore(!seeMore)}>
                  {seeMore ? 'See less ' : 'See more '}
                    <Icon size={15} className='chevron' icon={seeMore ? 'chevron-up' : 'chevron-down'} />
                </div>
              </>
            }
          </div>
        );
      }),
  ];

  const shortCustomerDetails = fullCustomerDetails[0]?.slice(0, 3);

  return (
    <SideMenuWrapper
      className={`side-menu-wrapper ${globalContext.globalDetail.isSideMenuActive ? 'menu-open' : 'menu-closed'}`}
    >
      <div className='mobile-menu-wrapper d-block d-md-none'>
        <button className='mobile-menu-icon' onClick={handleOpenMenu}>
          <Icon color={isIndexPage ? colors.white : colors.black} size={40} icon='menu' />
          {userDetail.hasUnreadMessages && <span className='message-count' />}
        </button>
        <NavLink to={logoLink} className='app-logo logo-collapsed' onClick={resetFilters}>
          <MobileLogo className='index-image logo-mobile' fillColor='#fff' />
          <MobileLogo className='logo-mobile' />
        </NavLink>
      </div>
      <NavLink to={logoLink} className='app-logo d-none d-md-block'>
        <DesktopLogo className='index-image logo' fillColor='#fff' />
        <DesktopLogo className='logo' />
      </NavLink>
      <div
        className={`menu-wrapper mt-1 ${globalContext.globalDetail.isSideMenuActive ? 'slide-in' : 'slide-out hidden'}`}
      >
        <button className='close-menu-icon d-block d-md-none' onClick={handleCloseMenu}>
          <Icon color={colors.trueblack} size={15} icon='cross2' />
        </button>
        <div>
          <ul className='customer-information m-0 mb-4'>
            <ul className='col-md-12 pb-2 d-md-none email'>
              <div>{userDetail.emailAddress}</div>
            </ul>
            <li className='col-md-12'>You have access to the following account(s):</li>
            <li className='col-12 col-md-12 mt-2'>{seeMore ? fullCustomerDetails : shortCustomerDetails}</li>

            {temporary.accessId && temporary.accessId.length > 0 && (
              <li className='col-md-12 pt-2'>
                <Link to='/' className='returnToCustomer' onClick={handleReturnToCustomerSelection}>
                  Return to customer selection
                </Link>
              </li>
            )}
          </ul>
        </div>
        <ul id='mainMenu'>
          {isCorpBroker() && (
            <div className='menu-selectcustomer'>
              <SelectCustomer
                selectedValue={null}
                customers={userDetail.customers}
                handleViewAsCustomerToggle={handleViewAsCustomerToggle}
                viewAsCustomer={userDetail.viewAsCustomerToggle}
                handleChange={handleCustomerChange}
              />
            </div>
          )}
          <NavigationTemplate
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
            hasUnreadMessages={userDetail.hasUnreadMessages}
          />
          <div className='profile'>
            <ExternalLink
              onClick={onChangePasswordClick}
              title='Change password'
              href={
                process.env.REACT_APP_AUTH_URL_BRANDED +
                '/Account/CreateYourNewPassword?id=' +
                userDetail.userId +
                '&ReturnUrl=' +
                process.env.REACT_APP_PUBLIC_URL
              }
              target='_self'
              className='d-block btn btn-terinary btnchangepassword'
            />
            <ButtonPrimary className={'btnsignout mb-3'} title={'Sign out'} route='/signout' />
            {isDraxBrand ? (
              <div className='profileText'>
                <div>
                  Find troubleshooting advice and other info in our{' '}
                  <a
                    onClick={onHowToLinkClicked}
                    href='https://assets.ctfassets.net/jl0e441lxc8n/3MTEqx1Z6Cd9JGHu95GO3n/2623602336d5382cbf6aacc2992155de/My_Drax_Energy_-_Customer_How_to_guide.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    how to guide
                  </a>
                  .{' '}
                  <a onClick={() => setShowHelpSupportModal(true)} className='supportLink'>
                    Contact us
                  </a>
                  {' '}
                  if you need any further help.
                </div>
                <ModalTemplate
                  name={'HelpSupportModal'}
                  show={showHelpSupportModal}
                  onHide={() => { setShowHelpSupportModal(false)}}
                />
              </div>
            ) : (
              <OpusProfileContactInfo hasRenewableAccount={userDetail.hasRenewable} />
            )}
          </div>
        </ul>
      </div>
    </SideMenuWrapper>
  );
};
