const Loa = ({ width, height, className, fillColor }: LoaProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.54873 25.7333V0.666672H24.0154V3.45186'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M11.0344 10.1926H16.6725M11.0344 25.0074H20.9009M19.6578 30.9333H8.2154V4.26666H22.3106L27.9487 10.1926V30.9333H19.6578Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M21.9215 4.15642V10.0781H27.8432M10.0781 13.7795H24.8824M10.0781 17.4804H24.8824M10.0781 21.1814H24.8824'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type LoaProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Loa.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#283244',
};

export default Loa;
