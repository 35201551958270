import styled from 'styled-components';

import * as colors from '../../common/styles/colors';

export const ContactSummaryGridWrapper = styled.div`
  .energyTypeIcon {
    position: relative;
    top: -0.2em;
    left: 0.5em;

    background-color: ${colors.warningLight};
    padding 2px;
    padding-left: 3px;
  }

  .warningIcon {
    padding-top: 15px;
    padding-right: 0;
  }

`;
