import { useContext, useEffect, useState, useReducer } from 'react';
import { toast } from 'react-toastify';

import { getMeters, MetersFilterObject } from '../../common/api/meterReadsApi';
import { SearchDetailContext, SearchDetailContextProps } from '../../context/searchDetail/searchDetailContext';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { useAuthState } from '../../providers/authProvider';
import MpanDetails from './MpanDetails';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';
import { EssAccountsTypes } from '../../types/account';
import { getInitialType } from '../../utils/getInitialTypes';
import { RoutingToTypes } from '../search/SearchActions/SearchActions';
import { isDraxBrand } from '../../utils/common';
import { downloadCSV } from '../../helpers/downloadCSV';
import { downloadXML } from '../../helpers/downloadXML';

import convertArrayToCSV from 'convert-array-to-csv';
import XMLJs from 'xml-js';
import { globalApiParams } from '../../common/api/globals';
import { getSelectedCustomerId } from '../../helpers/customer';
import { getCustomerMpanDetails, getMpanTariffInfo } from '../../common/api/mpanDetailsApi';
import { formatDateReverse } from '../utils/date/date';

const MPANs = () => {
  const authContext = useAuthState();

  const userContext = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const [meterType, setMeterType] = useState<Api.MeterType>(undefined);
  const [isEligibleForSmartMeter, setIsEligibleForSmartMeter] = useState<boolean>(false);

  const [initialType] = useState<string>(
    getInitialType(
      userContext.userDetail.hasElectricity,
      userContext.userDetail.hasGas,
      userContext.userDetail.hasRenewable
    )
  );
  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(
      userContext.userDetail.hasElectricity,
      userContext.userDetail.hasGas,
      userContext.userDetail.hasRenewable
    ),
    data: {},
    initialSortField: 'MpanCore',
    initialSortOrder: 'ASC',
    currentSortField: 'MpanCore',
    currentSortOrder: 'ASC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userContext.userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  // When navigating directly to url
  useEffect(() => {
    if (userContext.userDetail.essCustomerId) {
      initialState.currentType = getInitialType(
        userContext.userDetail.hasElectricity,
        userContext.userDetail.hasGas,
        userContext.userDetail.hasRenewable
      );
      initialState.selectedCustomerId = getSelectedCustomerId(userContext.userDetail);

      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.userDetail.hasElectricity, userContext.userDetail.hasGas, userContext.userDetail.hasRenewable]);

  useEffect(() => {
    // Ignore if we are entering component from Search
    if (
      searchContext.searchDetail != null &&
      searchContext.searchDetail.type &&
      searchContext.searchDetail.type.length > 0
    )
      return;

    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.selectedCustomerId, state.currentFilter, state.page, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentType,
    state.page,
    state.selectedCustomerId,
    state.currentFilter,
    state.currentSortField,
    state.currentSortOrder,
  ]);

  useEffect(() => {
    if (searchContext.searchDetail != null && searchContext.searchDetail.searchText.length > 0) {
      // filter by search result

      if (searchContext.searchDetail.type && searchContext.searchDetail.routingTo === RoutingToTypes.mpanDetails) {
        const type = searchContext.searchDetail.type.toLowerCase();
        if (type === 'mpan') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: searchContext.searchDetail.mpan } });
        } else if (type === 'invoice') {
          switch (searchContext.searchDetail.energyType) {
            case EssAccountsTypes.Electricity: {
              dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
              dispatch({
                type: GRID_ACTIONS.FILTER_BY_INVOICE,
                payload: { invoice: searchContext.searchDetail.invoice },
              });
              break;
            }
          }
        } else if (type === 'account') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({
            type: GRID_ACTIONS.FILTER_BY_CUSTOMER,
            payload: { essCustomerId: searchContext.searchDetail.accountId },
          });
        } else if (type === 'statement') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Renewables });
          dispatch({
            type: GRID_ACTIONS.FILTER_BY_STATEMENT,
            payload: { statement: searchContext.searchDetail.statement },
          });
        } else if (type === 'site') {
          // Note:  only done for electricity
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: searchContext.searchDetail.siteId } });
        }
        // Clear Search Detail
        searchContext.setSearchDetail({ searchText: '' });
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext.searchDetail.type]);

  const getMpanDetails = async (customerId: string, page: number, sort?: Common.ISort) => {

    let promise = null;
    var filters: MetersFilterObject = {};

    if (state.currentFilter && state.currentFilter.type === 'MeterType') {
      if (state.currentFilter.value !== 'all') {
        filters['HhOrNhh'] = state.currentFilter.value;
      }
    }

    const mpanDetails = await getCustomerMpanDetails(
      process.env.REACT_APP_API_URL,
      authContext,
      customerId,
      globalApiParams.maxFilterPageSize,
      state.page,
      sort,
      filters,
    );

      promise = new Promise((resolve, reject) => {
        const gridData: Array<any> = mpanDetails.data.map((item: Api.IMeterReadsMissing) => {
          const mpanDetailsData = {
            'hh-or-nhh': item['hh-or-nhh'],
            'mpan-core': item['mpan-core'],
            'site-reference': item['site-reference'],
            'site-name': item['site-name'],
            'site-address': `${item['site-address']}, ${item['site-postcode']}`,

            'smart-meter': item['smart-meter'],
          };

          // 52222 / 52246 - Temporarily removed due to data issues.
          // if (mpanDetailsData['smart-meter']) {
          //   setIsEligibleForSmartMeter(true);
          // }

          return mpanDetailsData;
        });

        resolve(gridData);
      });

    if (promise instanceof Promise) {
      promise
        .then(result => {
          if (result) {
            dispatch({
              type: GRID_ACTIONS.ADD_DATA,
              payload: { data: result, page: state.page },
            });
          } else {
            console.error(`MPAN details request returned errors.`);
          }
        })
        .finally(() => {
          dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
        });
    }
  };

  const clearData = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
  };

  const loadData = (customerId: string, currentFilter: Common.IFilter | null, page: number, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    getMpanDetails(customerId, page, sort);
  };

  const handleTypeChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: type });
  };

  const handleSortChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleSiteBlur = async (id: number, site: string) => {};

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    const essSiteId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: essSiteId } });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
    setIsEligibleForSmartMeter(false);
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    const essAccountId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId } });
  };

  const handleMpanMprnFilterChange = (option: Common.IOption) => {
    const mprnMpan = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan } });
  };

  const handleDateFilterChange = (formattedMonthYear: string) => {
    const date = formattedMonthYear;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_DATE, payload: { date } });
  };

  const handleClearAllClick = (): void => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handleDownload = async (fileType: 'xml' | 'csv') => {
    const date = new Date();
    const currentDate = formatDateReverse(date);

    const mpanDetails = await getMeters(
      process.env.REACT_APP_API_URL,
      authContext,
      state.selectedCustomerId,
      undefined,
      1,
      undefined,
      undefined,
      'Active',
      0 //pageSize all
    );

    if (mpanDetails == null) return;

    let result = null;
    if (fileType === 'csv') {
      const csv = convertArrayToCSV(mpanDetails);
      result = csv.toString();
    } else if (fileType === 'xml') {
      const mpanDetailsCollection: any = {
        mpans: {
          mpanDetails: mpanDetails,
        },
      };

      result = XMLJs.js2xml(mpanDetailsCollection, { compact: true, spaces: 2 });
    }

    if (result !== null) {
      const fileName = `${state.selectedCustomerId} MPAN-Details ${currentDate}`;

      // Download file
      if (fileType === 'csv') {
        downloadCSV(result, fileName);
      } else if (fileType === 'xml') {
        downloadXML(result, fileName);
      }
    }
  };

  const handleTariffDownload = async (fileType: 'xml' | 'csv') => {
    const url = '/ersgatewayadapter/mpandetails/download-mpan-tariff-info';
    const date = new Date();
    const currentDate = formatDateReverse(date);

    const mpanTariffInfo = await getMpanTariffInfo(
      process.env.REACT_APP_API_URL,
      authContext,
      url,
      state.selectedCustomerId
    );

    if (mpanTariffInfo.success && mpanTariffInfo.data) {
      if (mpanTariffInfo == null) return;
      let result = null;
      if (fileType === 'csv') {
        const csv = convertArrayToCSV(mpanTariffInfo.data);
        result = csv.toString();
      } else if (fileType === 'xml') {
        const mpanTariffInfoCollection: any = {
          tariffs: {
            mpanTariffInfo: mpanTariffInfo,
          },
        };

        result = XMLJs.js2xml(mpanTariffInfoCollection, { compact: true, spaces: 2 });
      }

      if (result !== null) {
        const fileName = `${state.selectedCustomerId} Tariff-information ${currentDate}`;

        // Download file
        if (fileType === 'csv') {
          downloadCSV(result, fileName);
        } else if (fileType === 'xml') {
          downloadXML(result, fileName);
        }
      }
    } else {
      const message = 'Unable to get MPAN tariff info';
      toast.error(message, { position: 'bottom-right' });
      throw new Error(message);
    }
  };

  const handleMeterTypeFilterChange = (type: Api.MeterType) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_METER_TYPE, payload: type });
    return type === 'all' ? setMeterType(undefined) : setMeterType(type);
  };

  return (
    <MpanDetails
      initialType={initialType}
      handleTypeChange={handleTypeChange}
      handleSortChange={handleSortChange}
      handleSiteBlur={handleSiteBlur}
      handlePagingChange={handlePagingChange}
      gridData={state.gridData}
      hasRenewableAccount={userContext.userDetail.hasRenewable}
      hasElectricityAccount={userContext.userDetail.hasElectricity}
      hasGasAccount={userContext.userDetail.hasGas}
      customerData={state.data?.customers}
      accountData={state.data?.accounts}
      siteData={state.data?.sites}
      mpanMprnData={state.data?.mpans}
      handleAccountFilterChange={handleAccountFilterChange}
      handleCustomerFilterChange={handleCustomerFilterChange}
      handleSiteFilterChange={handleSiteFilterChange}
      handleMpanMprnFilterChange={handleMpanMprnFilterChange}
      handleDateFilterChange={handleDateFilterChange}
      handleMeterTypeFilterChange={handleMeterTypeFilterChange}
      handleDownload={handleDownload}
      handleTariffDownload={handleTariffDownload}
      handleClearAllClick={handleClearAllClick}
      isLoading={state.isLoading}
      hasMoreData={state.hasMoreData}
      currentSortField={state.currentSortField}
      currentSortOrder={state.currentSortOrder}
      eligibleForSmartMeter={isEligibleForSmartMeter} 
    />
  );
};
export default MPANs;
