import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import * as variables from '../../styles/variables';

export const StyledLink = styled(Link)`
  ${mixins.transition};
  font-weight: ${props => props.theme.button.fontWeight};
  color: ${props => props.theme.terinary.color};
  border-radius: ${props => props.theme.terinary.btnBorderRadius};
  text-decoration: none;
  padding: 0.5rem 2rem !important;
  white-space: nowrap;
  margin: 30px 0;
  background-color: ${colors.white};
  border: 2px solid ${props => props.theme.terinary.border};
  text-align: center;

  .icon {
    margin-right: ${variables.spacingXSmall};
  }

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.terinary.hovercolor};
    border-color: ${props => props.theme.terinary.hovercolor};
  }

  // anchors dont have disabled so have to use props
  ${props =>
    props.disabled === true && {
      'opacity': 0.4,
      'cursor': 'default',
      'pointer-events': 'none',
    }};
`;
