import styled from 'styled-components';
import * as variables from '../../common/styles/variables';
import * as mixins from '../../common/styles/mixins';
import * as colors from '../../common/styles/colors';
import { respondTo } from '../../common/styles/mixins';

export const AppStyles = styled.div`
  height: auto;

  ${respondTo.tablet`
      height: 100vh;
  `};

  .app {
    font-weight: 400;
    height: 100%;
    display: block;
    position: relative;

    .hidden {
      display: none;
    }

    ${respondTo.tablet`
      display: flex;

      .hidden {
        display: none;
      }
    `};

    .main-wrapper {
      ${mixins.scrollbars};
      flex-grow: 1;
      flex-direction: column;

      ${respondTo.tablet`
        padding: 3em 0 0 33px;
        top: 0;
        width: calc(100% - ${variables.menuWidth});
      `};

      &.mobile-filter-active {
        // We need this to Hide all tables
        // IF mobile filter is visible in one place
        .infinite-scroll-component__outerdiv {
          display: none;
        }
      }

      header {
        margin-bottom: 40px;
        border-bottom: 1px solid ${colors.lightGrey};

        h1 {
          display: flex;
          align-items: center;
          transform: translateX(-20px);
        }
      }

      footer {
        margin-top: auto;
        color: ${colors.midGrey};
        text-align: center;

        a {
          text-decoration: none;
          color: ${colors.midGrey};

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-inner {
        ${mixins.noTouchSelect};
      }
    }

    .main-wrapper-homepage {
      ${mixins.scrollbars};
      flex-grow: 1;
      flex-direction: column;

      ${respondTo.tablet`
        padding: 2em 0 0 33px;
        top: 0;
        width: calc(100% - ${variables.menuWidth});
      `};

      &.mobile-filter-active {
        // We need this to Hide all tables
        // IF mobile filter is visible in one place
        .infinite-scroll-component__outerdiv {
          display: none;
        }
      }

      header {
        margin-bottom: 40px;
        border-bottom: 1px solid ${colors.lightGrey};

        h1 {
          display: flex;
          align-items: center;
          transform: translateX(-20px);
        }
      }

      footer {
        margin-top: auto;
        color: ${colors.midGrey};
        text-align: center;

        a {
          text-decoration: none;
          color: ${colors.midGrey};

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-inner {
        ${mixins.noTouchSelect};
      }
    }
  }

  .mainview {
    // margin-bottom: 5em;
    padding-top: 4em;

    ${respondTo.tablet`
      padding-right: 33px;
      padding-top: 2.7em;
    `};

    h1 {
      font-size: 2rem;
      padding-left: 15px;
      margin: 0 0 ${variables.spacingSmall};
    }

    h2 {
      color: ${colors.darkBlue};
      cursor: pointer;
      font-size: 1.3rem;
      margin: 0;

      ${respondTo.tablet`
        cursor: initial;
        margin: revert;
      `};
    }

    h3 {
      color: ${colors.lightBlue};
    }

    h4 {
      &.primary {
        text-align: center;

        ${respondTo.tablet`
          text-align: left;
      `};
      }
    }

    .meu-dropdown {
      cursor: pointer;
    }
  }

  .mainview-homepage {
    // margin-bottom: 5em;
    padding-top: 4em;

    ${respondTo.tablet`
      padding-right: 33px;
      padding-top: 0.1em;
    `};

    h1 {
      font-size: 2rem;
      padding-left: 15px;
      margin: 0 0 ${variables.spacingSmall};
    }

    h2 {
      color: ${colors.darkBlue};
      cursor: pointer;
      font-size: 1.3rem;
      margin: 0;

      ${respondTo.tablet`
        margin: revert;
      `};
    }

    h3 {
      color: ${colors.lightBlue};
    }

    h4 {
      &.primary {
        text-align: center;

        ${respondTo.tablet`
          text-align: left;
      `};
      }
    }

    .meu-dropdown {
      cursor: pointer;
    }
  }

  .service-message {
    ${respondTo.tablet`
      padding-bottom: ${variables.spacingMedium};
    `};
  }
`;
