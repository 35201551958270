import { ButtonPrimary, Checkbox, Loading, Sort } from '../../common/components';
import { AdminLandingGridWrapper } from './AdminLandingPageStyles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../common/styles/mixins';
import React from 'react';
import { DATE_TIME_FORMAT_SECONDS } from '../../common/constants';
import moment from 'moment';

type ResultsGridProps = {
  gridData: Api.IAdminUserSearchResult;
  isLoading?: boolean;
  handlePasswordReset: Function;
  handleUnlock: Function;
};

const defaultProps = {
  gridData: '',
  isLoading: false,
  handlePasswordReset: undefined,
  handleUnlock: undefined,
};

export const ResultsGrid = (props: ResultsGridProps) => {
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const [unlockBtnEnabled, setUnlockBtnEnabled] = useState<boolean>(true);
  const [generatePasswordResetBtnEnabled, setGeneratePasswordResetBtnEnabled] = useState<boolean>(true);

  const StyledTd = styled.td<any>`
    p,
    span {
      width: 50%;
    }

    p {
      display: flex;
      align-items: end;
      margin-top: auto;
    }

    ${respondTo.tablet`
    width: ${({ colWidth }: any) => colWidth};
    p, span {
      margin-left: -10px;
      width: auto;
    }

    p {
      display: block;
    }
  `};
  `;

  const setButtons = (item: Api.IAdminUserSearchResult) => {
    if (checkedItems.length == 1) {
      if (item.status === 'Enabled' || item.status === 'Invited') {
        setGeneratePasswordResetBtnEnabled(true);
      }
    }

    if (item.status === 'Locked') {
      setUnlockBtnEnabled(true);
    }
  };

  useEffect(() => {
    setGeneratePasswordResetBtnEnabled(false);
    setUnlockBtnEnabled(false);

    if (props.gridData) {
      setButtons(props.gridData);
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gridData, checkedItems.length]);

  const handlePasswordReset = () => {
    props.handlePasswordReset(props.gridData);
  };

  const handleUnlock = () => {
    props.handleUnlock(props.gridData.email, props.gridData.userType, props.gridData.id);
  };

  const handleItemChange = (index: number) => {
    const items = checkedItems.slice(0, checkedItems.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    setCheckedItems(items);
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const getStatusStyle = (status: string) => {
    if (status === 'Locked') {
      return 'lockedStatus';
    } else if (status === 'Disabled') {
      return 'disabledStatus';
    } else {
      return 'goodStatus';
    }
  };

  const gridHeaders = () => {
    return (
      <thead>
        <tr>
          <th style={{ opacity: 0 }} scope='col' className='checkboxHeader'>
            <Checkbox id='selectAllAccounts' onChange={() => handleItemChange(0)} checked={isChecked(0)} />
          </th>
          <th scope='col' className='sort account'>
            <Sort title='Status' dataValue={'status'} />
          </th>
          <th scope='col' className='sort name'>
            <Sort title='Internal reference' dataValue={'reference'} />
          </th>
          <th scope='col' className='sort totalBalance'>
            <Sort title='Email' dataValue={'userEmail'} />
          </th>
          <th scope='col' className='sort ddAccountNo'>
            <Sort title='Lockout end' dataValue={'lockoutEnd'} />
          </th>
        </tr>
      </thead>
    );
  };

  const gridRows = () => {
    return (
      <React.Fragment>
        <td className='checkbox'>
          <span onClick={event => event.stopPropagation()}>
            <Checkbox
              className='checkboxSmall'
              id={`site-0`}
              onChange={() => handleItemChange(0)}
              checked={isChecked(0)}
            />
          </span>
        </td>
        <td>
          <div className={getStatusStyle(props.gridData['status'])}>{props.gridData['status']}</div>
        </td>
        <td>{props.gridData['reference']}</td>
        <td>{props.gridData['email']}</td>
        <td>
          {props.gridData['lockoutEnd'] && props.gridData['status'] === 'Locked'
            ? moment(props.gridData['lockoutEnd']).format(DATE_TIME_FORMAT_SECONDS)
            : 'N/A'}
        </td>
      </React.Fragment>
    );
  };

  return (
    <AdminLandingGridWrapper>
      <div className='justify-content-center align-items-center'>
        <div className='pb-4 d-flex'>
          <div className='ml-auto'>
            <ButtonPrimary
              route='#'
              className=''
              title={'Generate password reset'}
              onClick={() => handlePasswordReset()}
              disabled={!generatePasswordResetBtnEnabled}
            />
          </div>

          <div className='pl-3'>
            <ButtonPrimary
              route='#'
              className=''
              title={'Unlock'}
              onClick={() => handleUnlock()}
              disabled={!unlockBtnEnabled}
            />
          </div>
        </div>
        <table className='list table plain'>
          <>
            {gridHeaders()}
            <tbody>
              <tr>{gridRows()}</tr>
            </tbody>
          </>
        </table>
      </div>
      {props.isLoading && <Loading overlay nowait />}
    </AdminLandingGridWrapper>
  );
};

ResultsGrid.defaultProps = defaultProps;
