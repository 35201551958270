import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import iconSet from '../../../../assets/selection.json';
import IcomoonReact from 'icomoon-react';

import { ButtonPrimary, ButtonTerinary, ModalClose } from '../../../../common/components';

type FailureSubmitBulkMetersModalProps = {
  onClose: () => void;
  failureMessages: Array<string> | undefined;
};

export const ContentWrapper = styled.div`
  .failure-icon {
    display: inline-block;
    padding: 25px;
    width: 80px;
    height: 80px;
    text-align: center;
    background: #ccc;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  li {
    list-style-type: disc;
    margin-top: 20px;
  }
`;

export const FailureSubmitBulkMetersModal = (props: FailureSubmitBulkMetersModalProps) => {
  const { onClose, failureMessages = [''] } = props;

  return (
    <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Submit meter reads</Modal.Title>
        <ModalClose onClick={onClose} />
      </Modal.Header>

      <Modal.Body className='pl-lg-4'>
        <ContentWrapper>
          <div className='d-flex justify-content-center align-items-center'>
            <span className='failure-icon'>
              <IcomoonReact iconSet={iconSet} color={'white'} size={30} icon='cross' />
            </span>
          </div>
          There is a problem with your meter read file as described below. Please review the file data and submit again.
          <ul>
            {failureMessages.map((msg: string, i: number) => (
              <li key={i}>{msg}</li>
            ))}
          </ul>
          <br />
          <br />
        </ContentWrapper>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonTerinary onClick={onClose} className={'mr-2'} title='Cancel' route='#' />
        <ButtonPrimary onClick={onClose} className={'mr-0'} title='Try again' route='#' />
      </Modal.Footer>
    </Modal>
  );
};
