import { NavigationTemplateProps } from './NavigationTemplateProps';
import { BRANDS } from '../../../consts';

export const smeNavigation = ({
  brand,
  isExclusiveOffersDataAvailable,
  showMeterReadButtons,
}: NavigationTemplateProps) => [
  {
    text: 'Messages',
    link: '/messages',
    icon: 'menuMessages',
    classname: '',
    isVisible: true,
  },
  {
    text: 'Account details',
    link: '/',
    icon: 'menuAccount',
    subMenu: [
      {
        text: 'Account & site details',
        link: '/site-details',
        isVisible: true,
      },
      {
        text: 'Contract summary',
        link: '/contract-summary',
        isVisible: true,
      },
      {
        text: 'User details',
        link: '/user-details',
        isVisible: brand === BRANDS.OPUS,
      },
      {
        text: 'Moving premises',
        link: '/moving-premises',
        isVisible: true,
      },
    ],
  },
  {
    text: 'MPANs',
    link: '/',
    icon: 'menuMPAN',
    subMenu: [
      {
        text: 'MPAN details',
        link: '/mpan-details',
        isVisible: true,
      },
      {
        text: 'Meter read history',
        link: '/energy-usage/previous-meter-readings',
        isVisible: showMeterReadButtons,
      },
      {
        text: 'Submit meter read',
        link: '/meter-reads',
        isVisible: showMeterReadButtons,
      },
      {
        text: 'Bulk readings tool',
        link: '/energy-usage/bulk-readings-tool',
        isVisible: brand === BRANDS.OPUS,
      },
    ],
  },
  {
    text: 'My invoices',
    link: '/invoices',
    icon: 'menuInvoicePayment',
    classname: '',
  },
  {
    text: 'Make a payment',
    link: '/make-a-payment',
    icon: 'menuPayment',
    classname: '',
  },
  {
    text: 'Data usage',
    link: '/energy-usage/data',
    icon: 'menuData',
    classname: '',
  },
  {
    text: 'Exclusive offers',
    link: '/exclusive-offers',
    icon: 'menuOffers',
    classname: '',
    isVisible: isExclusiveOffersDataAvailable,
  },
];
