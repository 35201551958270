import styled from 'styled-components';
import * as mixins from '../../../common/styles/mixins';

export const MovePremisesText = {
  header: 'Moving premises?',
  headerBtn: 'Looking to move?',
  movePremisesBtn: 'Move premises',
};

export const MovePremisesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h2.header {
    font-size: 23px;
  }

  .movePremisesOverviewMobile {
    ${mixins.widgetMobile};
  }

  .movePremisesOverview {
    ${mixins.widgetDesktop};

    .premises__text {
      padding-right: 5px;
    }
  }
`;
