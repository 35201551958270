// Modules
import React, { useContext, ReactElement, useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// Components
import {
  ButtonPrimary,
  Checkbox,
  CustomDatePicker,
  FormInput,
  LocationFinder,
  FilterRowWrapper,
  StyledTitle,
} from '../../../common/components';
import { YourInfoBlock } from './YourInfoBlock';
import { NewContactOldAddressDetails } from './NewContactOldAddressDetails';
import { Loading } from '../../../common/components';

// Context
import { MovingPremisesProcessContext } from '../movingPremisesProcess/movingPremisesProcessContext';
import { useAuthState } from '../../../providers/authProvider';

// Api
import { saveSitesMovingPremisesDetails } from '../../../common/api/movingPremisesApi';

// Styles
import { MovingPremisesWrapper, SitesWrapper } from './MovingPremisesNewAddress.styles';

// Utils
import { TagManager } from '../../utils/analytics/TagManager';
import FormElement from '../../../common/components/FormElement';
import { UploadSupportingDocuments } from './UploadSupportingDocuments';
import { REVERSED_DATE_FORMAT_HYPHEN } from '../../../common/constants';
import { UserDetailContextProps, UserDetailContext } from '../../../context/userDetail/userDetailContext';
import { SubmissionFailedModal } from '../../mymessages/modalTemplates/SubmissionFailedModal';
import { getMeteringPointsBySite } from '../../../common/api/meterReadsApi';

type MovingPremisesNewAddressProps = {
  stepsBar: ReactElement;
  pageTitle: ReactElement;
};

type Inputs = {
  sites: {
    siteId: number;
    addressLine1: string;
    town: string;
    postcode: string;
    movingDate: any;
    businessName: string;
    fullName: string;
    phoneNumber: string;
    copyDate: boolean;
    copyAddress: boolean;
    landlordFullName: string;
    landlordPhoneNumber: string;
  }[];
  yourContactName: string;
  yourPhoneNumber: string;
};

const DEFAULT_SITE_DETAIL = {
  movingDate: '',
  addressLine1: '',
  town: '',
  postcode: '',
  businessName: '',
  fullName: '',
  phoneNumber: '',
  copyDate: false,
  copyAddress: false,
  landlordFullName: '',
  landlordPhoneNumber: '',
};

export const MovingPremisesNewAddress = ({ stepsBar, pageTitle }: MovingPremisesNewAddressProps) => {
  const history = useHistory();
  const authContext = useAuthState();
  const { selectedSites, setFilledSitesData } = useContext<any>(MovingPremisesProcessContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPremises, setNewPremises] = useState<string>('');
  const [emailFormCopy, setEmailFormCopy] = useState<string>('');
  const [sendExternalEmail, setSendExternalEmail] = useState<boolean>();
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const [checkedMpans, setCheckedMpans] = useState<Array<number>>([]);
  const [siteMpans, setSiteMpans] = useState<Array<number>>([]);
  const [landlordPreference, setLandlordPreference] = useState<boolean>();
  const [residentialPreference, setResidentialPreference] = useState('');
  const [newOwnerOption, setNewOwnerOption] = useState<boolean>();
  const [files, setFiles] = useState<Array<File>>([]);
  const [comments, setComments] = useState<string>('');
  const [cotDate, setCotDate] = useState<any>('');
  const [showFailureModal, setShowFailureModal] = useState<boolean>(false);

  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  useEffect(() => {
    // Navigate to 1st process step in case page reload
    if (!selectedSites?.length) {
      history.push('/home/moving-premises');
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
    loadMpanData();
  }, [selectedSites]);

  const sitesDefaultFormData = selectedSites.map((site: any) => {
    return {
      ...DEFAULT_SITE_DETAIL,
      siteId: site['site-id'],
    };
  });

  const methods = useForm<Inputs>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      sites: sitesDefaultFormData,
      yourContactName: '',
      yourPhoneNumber: '',
    },
  });

  const { control, watch, setValue, getValues, handleSubmit } = methods;
  const { fields } = useFieldArray({ control, name: 'sites' });

  const contactName = watch(`yourContactName`);
  const contactNumber = watch(`yourPhoneNumber`);

  const submitButtonValid =
    cotDate &&
    newPremises &&
    checkedMpans.length > 0 &&
    getValues(`sites.${0}.addressLine1`) !== '' &&
    getValues(`sites.${0}.town`) !== '' &&
    getValues(`sites.${0}.postcode`) !== '' &&
    landlordPreference != null &&
    residentialPreference &&
    newOwnerOption != null &&
    contactName !== '' &&
    contactNumber !== '' &&
    sendExternalEmail != null;

  const setLocationValues = (index: number, firstLine: string, town: string, postcode: string) => {
    setValue(`sites.${index}.addressLine1` as any, firstLine, { shouldValidate: true });
    setValue(`sites.${index}.town` as any, town, { shouldValidate: true });
    setValue(`sites.${index}.postcode` as any, postcode, { shouldValidate: true });
  };

  const prepareLocationValues = (selectedLocation: any, index: number) => {
    if (!selectedLocation) {
      return;
    }

    const { City, PostalCode, Company, Line1, Line2, Line3 } = selectedLocation;
    const firstLine = `${Company} ${Line1} ${Line2} ${Line3}`.trim();

    setLocationValues(index, firstLine, City, PostalCode);
  };

  const loadMpanData = async () => {
    if (selectedSites.length > 0) {
      const mpanResult: Array<any> = await getMeteringPointsBySite(
        process.env.REACT_APP_API_URL,
        authContext,
        selectedSites[0]['site-id'],
        1
      );

      const mpans: Array<any> = [];
      if (mpanResult) {
        mpanResult.map((item: any) => {
          mpans.push(item['mpan-core']);
        });
      }

      setSiteMpans(mpans);
      handleCheckMpans(mpans);
    }
  };

  const handleCheckMpans = (mpanList: Array<any>) => {
    if (mpanList.length) {
      const items: Array<number> = mpanList.map((item: any, index: number) => index);
      const mpans: Array<number> = mpanList.map((item: any, index: number) => item);
      setCheckedItems(items);
      setCheckedMpans(mpans);
    }
  };

  const onUploadProgress = (percentCompleted: number) => {};

  const saveData = async (data: Inputs) => {
    const preparedData = {
      CotDate: cotDate.format(REVERSED_DATE_FORMAT_HYPHEN),
      HasNewPremises: newPremises === 'Yes' ? true : false,
      SiteAddress: selectedSites[0].siteAddress,
      Mpans: checkedMpans,
      FaStreet: data.sites[0].addressLine1,
      FaTownCity: data.sites[0].town,
      FaPostcode: data.sites[0].postcode,
      HasLandlordContact: landlordPreference,
      LandlordName: data.sites[0].landlordFullName,
      LandlordTelephone: data.sites[0].landlordPhoneNumber,
      PremisePurpose: residentialPreference,
      NewOwnerKnown: newOwnerOption,
      NewOwnerBusinessName: data.sites[0].businessName,
      NewOwnerContactName: data.sites[0].fullName,
      NewOwnerTelephone: data.sites[0].phoneNumber,
      SupportingFiles: files,
      Comments: comments,
      ContactTelephone: data.yourPhoneNumber,
      ExternalEmail: sendExternalEmail,
      CustomerAccountReference: selectedSites[0]['customer-account-reference'],
      RaisedBy: data.yourContactName,
    };

    TagManager.pushData({
      event: 'Submit COT',
      formData: preparedData,
    });

    setIsSubmitting(true);

    const result = await saveSitesMovingPremisesDetails(
      process.env.REACT_APP_API_URL,
      authContext,
      preparedData,
      onUploadProgress
    );

    setIsSubmitting(false);

    if (result?.data.success) {
      history.push('/home/moving-premises/confirmation');
    } else if (result.status == '500') {
      setShowFailureModal(true);
    }
  };

  const submitHandler: SubmitHandler<Inputs> = data => {
    saveData(data);
    setFilledSitesData(data);
  };

  const handleConfirmationStep = (event: any) => {
    event.preventDefault();
    handleSubmit(submitHandler)();
  };

  const handleFilesAndComments = (data: any) => {
    setFiles(data.files);
    setComments(data.comments);
  };

  const handleItemChange = (index: number, mpan: number) => {
    let items = checkedItems.slice(0, checkedItems.length);
    let currentMpans = checkedMpans.slice(0, checkedMpans.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);
    const mpanIndex = currentMpans.indexOf(mpan);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    if (mpanIndex > -1) {
      currentMpans.splice(mpanIndex, 1);
    } else {
      currentMpans.push(mpan);
    }

    setCheckedItems(items);
    setCheckedMpans(currentMpans);
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const handleUpdateLandlordPreference = (preference: boolean) => {
    setLandlordPreference(preference);
  };

  const handleUpdateResidentialPreference = (preference: string) => {
    setResidentialPreference(preference);
  };

  const handleUpdateMovingPreference = (preference: boolean) => {
    setNewOwnerOption(preference);
  };

  const handleSendExternalEmail = (preference: string) => {
    setEmailFormCopy(preference);

    if (preference === 'Yes') {
      setSendExternalEmail(true);
    } else {
      setSendExternalEmail(false);
    }
  };

  const mpanRows = () => {
    const mpanList = siteMpans.map((item: any, index: number) => {
      return (
        <>
          <tbody>
            <tr>
              <td>
                <Checkbox
                  id={`mpan-check-${index}`}
                  onChange={(event: any) => {
                    handleItemChange(index, item);
                  }}
                  checked={isChecked(index)}
                />
              </td>
              <td className=''>{item}</td>
            </tr>
          </tbody>
        </>
      );
    });
    return mpanList;
  };

  return (
    <>
      <MovingPremisesWrapper>
        <FilterRowWrapper className='pb-4'>{pageTitle}</FilterRowWrapper>

        {selectedSites?.length && (
          <div className='selectedAccountDisplay'>{`Acc - ${selectedSites[0]['customer-account-reference']}`}</div>
        )}

        <div className='pt-4 d-inline-flex'>
          <div>{stepsBar}</div>
          <span className='requiredFieldText'>* Required fields</span>
        </div>

        <FormProvider {...methods}>
          <form>
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <SitesWrapper>
                  <div className='formRowWrapper withInfo'>
                    <div className='fieldWrapper'>
                      <StyledTitle className='leftText mb-3' fontSize='20px'>
                        Tell us about the move
                      </StyledTitle>

                      <div className='formRowWrapper dateField'>
                        <span className='pt-3'>Date you left or will leave the premises*</span>

                        <div className='ml-auto'>
                          <Controller
                            name={`sites.${index}.movingDate` as any}
                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => (
                              <CustomDatePicker
                                date={cotDate}
                                onChange={(e: any) => {
                                  setCotDate(e);
                                }}
                                showIcon={true}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className='formRowWrapper dateField'>
                        <span className='pt-3'>Do you have a new premises to move into?*</span>
                        <div className='ml-auto'>
                          <FormElement.Radio
                            id='page-new-prem'
                            className={''}
                            label={''}
                            name='newPrem'
                            elements={['Yes', 'No']}
                            value={newPremises}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setNewPremises(e.target.value);
                            }}
                            required={true}
                          />
                        </div>
                      </div>

                      {newPremises === 'Yes' && <div className='pb-2'>{'Let us know where you are moving to*'}</div>}
                      {newPremises === 'No' && (
                        <div className='pb-2'>
                          {'Where can we send all future correspondence, including the last invoice?*'}
                        </div>
                      )}

                      <div className='formRowWrapper'>
                        <LocationFinder
                          label='New address'
                          placeholder='Type a postcode, street or address...'
                          onLocationSelect={(data: any) => prepareLocationValues(data, index)}
                        />
                      </div>

                      <div className='formRowWrapper'>
                        <FormInput
                          name={`sites.${index}.addressLine1`}
                          label='First line of address *'
                          params={{ required: true }}
                        />
                      </div>

                      <div className='formRowWrapper'>
                        <FormInput name={`sites.${index}.town`} label='Town/City *' params={{ required: true }} />
                      </div>

                      <div className='formRowWrapper'>
                        <FormInput name={`sites.${index}.postcode`} label='Postcode *' params={{ required: true }} />
                      </div>
                    </div>

                    <div className='infoPart'>
                      <span className='text-bold'>Site selected</span>
                      <div className='siteTitle'>Site address</div>
                      <div>{selectedSites[0].siteAddress}</div>
                      <div className='siteTitle' style={{ paddingLeft: '40px' }}>
                        MPAN/s
                      </div>
                      <div className='formRowWrapper withMpan'>
                        <div className='mpanList pt-0'>{mpanRows()}</div>
                        <div className='mpanInfo pt-0 text-left'>
                          Unselect any MPANs not relevant to the change of address.
                        </div>
                      </div>
                    </div>

                    <FormInput name={`sites.${index}.siteId`} params={{ required: false }} hidden={true} />
                  </div>
                </SitesWrapper>

                <SitesWrapper>
                  <NewContactOldAddressDetails
                    siteIndex={index}
                    handleUpdateLandlordPreference={handleUpdateLandlordPreference}
                    handleUpdateResidentialPreference={handleUpdateResidentialPreference}
                    handleUpdateMovingPreference={handleUpdateMovingPreference}
                  />
                </SitesWrapper>

                <SitesWrapper>
                  <UploadSupportingDocuments upload={handleFilesAndComments} />
                </SitesWrapper>
              </React.Fragment>
            ))}

            <SitesWrapper>
              <YourInfoBlock />

              <div className='formRowWrapper withContactDetails'>
                <div className='submitEmail d-flex dateField'>
                  <span className='pt-3'>Would you like a copy of this submission sent to your email address?*</span>
                  <div className='pl-4'>
                    <FormElement.Radio
                      id='page-submit-email'
                      className={''}
                      label={''}
                      name='submitEmail'
                      elements={['Yes', 'No']}
                      value={emailFormCopy}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleSendExternalEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className='text-right pt-4 submitBtn'>
                  <ButtonPrimary
                    title='Submit'
                    className='submit-btn'
                    onClick={(event: any) => handleConfirmationStep(event)}
                    disabled={!submitButtonValid}
                  ></ButtonPrimary>
                </div>
              </div>
            </SitesWrapper>
          </form>
        </FormProvider>
        {isSubmitting && <Loading overlay nowait />}
      </MovingPremisesWrapper>

      <SubmissionFailedModal
        show={showFailureModal}
        onHide={() => {
          setShowFailureModal(false);
        }}
      ></SubmissionFailedModal>
    </>
  );
};
