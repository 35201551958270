const BarChart = ({ width, height, className, fillColor }: BarChartProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.5 3H12.5V29H19.5V3Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M8 21H1V29H8V21Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M31 13H24V29H31V13Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type BarChartProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

BarChart.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2a3745',
};

export default BarChart;
