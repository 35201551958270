// Components
import { useEffect } from 'react';
import { ButtonDownload } from '../../../common/components';

// Styles
import { BrokerTpiWrapper, BrokerTpiText, DownloadButtonsWrapper } from './BrokerTpi.styles';

type BrokerTpiProps = {
  downloadClicked: Function;
};

export const BrokerTpi = (props: BrokerTpiProps) => {
  const downloadClicked = () => {
    props.downloadClicked();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BrokerTpiWrapper>
      <div>
        <div>
          <h4>{BrokerTpiText.header}</h4>
          <p>
            Use the download button below to request a csv file of data relating to your customers where an active
            Letter of Authority is present.
            <div className='mt-4'>
              The report will include the following data items for customers with an active contract:
            </div>
            <div className='mt-4'>
              <ul className='details'>
                <li>MPAN details</li>
                <li>Contract details</li>
                <li>Tariff information</li>
                <li>Contact details</li>
              </ul>
            </div>
          </p>
        </div>

        <div className='d-flex flex-column h-100 align-center-horizontal'>
          <div className='d-flex justify-content-end mt-auto'>
            <DownloadButtonsWrapper>
              <ButtonDownload
                className='pt-1'
                outline={true}
                icon='upload'
                title={BrokerTpiText.BrokerTpiTextBtn}
                onButtonClick={downloadClicked}
              />
            </DownloadButtonsWrapper>
          </div>
        </div>
      </div>
    </BrokerTpiWrapper>
  );
};
