import styled from 'styled-components';
import * as mixins from '../../common/styles/mixins';
import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';
export const ServiceUpdateHealthWrapper = styled.div``;
export const HealthWrapper = styled.div`
  h2.header {
    font-size: 1rem;
    align-items: center;
  }
  .health {
    &.healthy {
      border 4px solid ${colors.healthy};
    }
    &.Medium {
      border 4px solid ${colors.unhealthy};
    }
    &.High {
      border 4px solid ${colors.danger};
    }
  }
  .chevron {
    position: relative;
    top: 0.2em;
    left: 0.1em;
  }
  .healthDesktop {
    ${mixins.boxShadowPrimary};
    background-color: ${({ theme }) => theme.bg.secondary};
    border-radius: ${variables.globalRadius};
    padding: 5px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: pointer;
  }
  .badge-pill {
    padding-right: 0.5em;
    padding-left: 0.5em;
    border-radius: 10rem;
  }
  .badge-warning {
    color: ${colors.white};
    font-size: 1.3rem;
  }
  .badge-danger {
    color: ${colors.white};
    font-size: 1.3rem;
  }
`;
