const Wallet = ({ width, height, className, fillColor }: WalletProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24 10H29V22H17C13.6863 22 11 19.3137 11 16C11 12.6863 13.6863 10 17 10Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M5 24V8H25V10H27V7C27 6.44772 26.5523 6 26 6H4C3.44772 6 3 6.44772 3 7V25C3 25.5523 3.44772 26 4 26H26C26.5523 26 27 25.5523 27 25V22H25V24H5Z'
      fill={fillColor}
      stroke={fillColor}
      strokeWidth='0'
    />
    <rect x='17' y='15' width='4' height='2' fill={fillColor} />
  </svg>
);

export type WalletProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Wallet.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2A3745',
};

export default Wallet;
