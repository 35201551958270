import { useState } from 'react';
import { StyledTitle } from '../../common/components/StyledTitle';
import { BrokerTpi } from '../home/BrokerTpiReport/BrokerTpi';
import { TpiDataRequestFailureModal } from '../mymessages/modalTemplates/TpiBroker/TpiDataRequestFailureModal';
import { TagManager } from '../utils/analytics/TagManager';
import { TpiDataRequestInProgressModal } from '../mymessages/modalTemplates/TpiBroker/TpiDataRequestInProgressModal';
import { TpiDataRequestedModal } from '../mymessages/modalTemplates/TpiBroker/TpiDataRequestedModal';
import { getBrokerTpiReportData } from '../../common/api/dataApi';
import { useAuthState } from '../../providers/authProvider';

const BrokerTPI = () => {
  const authContext = useAuthState();

  const [showTpiDataRequestFailureModal, setShowTpiDataRequestFailureModal] = useState<boolean>(false);
  const [showTpiDataRequestInProgressModal, setShowTpiDataRequestInProgressModal] = useState<boolean>(false);
  const [showTpiDataRequestedModal, setShowTpiDataRequestedModal] = useState<boolean>(false);

  const downloadClicked = async () => {
    TagManager.pushData({
      event: 'Download broker report',
    });

    const result: any = await getBrokerTpiReportData(process.env.REACT_APP_API_URL, authContext);

    if (!result) {
      setShowTpiDataRequestFailureModal(true);
    } else if (result && result.status == '500') {
      setShowTpiDataRequestFailureModal(true);
    } else if (result && result.status == '200') {
      setShowTpiDataRequestedModal(true);
    } else if (result && result.status == '429') {
      setShowTpiDataRequestInProgressModal(true);
    } else {
      setShowTpiDataRequestFailureModal(true);
    }
  };

  return (
    <>
      <StyledTitle>Broker/TPI report</StyledTitle>
      <BrokerTpi downloadClicked={downloadClicked}></BrokerTpi>

      <TpiDataRequestFailureModal
        show={showTpiDataRequestFailureModal}
        onHide={() => {
          setShowTpiDataRequestFailureModal(false);
        }}
      ></TpiDataRequestFailureModal>
      <TpiDataRequestInProgressModal
        show={showTpiDataRequestInProgressModal}
        onHide={() => {
          setShowTpiDataRequestInProgressModal(false);
        }}
      ></TpiDataRequestInProgressModal>
      <TpiDataRequestedModal
        show={showTpiDataRequestedModal}
        onHide={() => {
          setShowTpiDataRequestedModal(false);
        }}
      ></TpiDataRequestedModal>
    </>
  );
};
export default BrokerTPI;
