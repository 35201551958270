// Modules
import React from 'react';

// Styles
import { BrokerLandingSelectedWrapper } from './BrokerLandingSelected.styles';

// Components
import { ButtonPrimary } from '../../../common/components';
import { TagManager } from '../../utils/analytics/TagManager';

type BrokerLandingSelectedProps = {
  customers: Array<Common.IExpandedCustomerOption>;
  selectedCustomers: Array<Common.IExpandedCustomerOption>;
  handleViewCustomers: Function;
  handleTrash: Function;
};

const defaultProps = {
  customers: [],
};

export const BrokerLandingSelected = (props: BrokerLandingSelectedProps) => {
  const handleViewCustomers = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    TagManager.pushData({
      event: 'View Customers (Landing page)',
      count: props.selectedCustomers.length,
    });

    props.handleViewCustomers(props.selectedCustomers);
  };

  const handleTrash = (item: Common.IExpandedCustomerOption) => {
    props.handleTrash(item);
  };

  const isDisabled = props.selectedCustomers.length > 0;

  return (
    <>
      <BrokerLandingSelectedWrapper {...props}>
        <div className='brokerLandingPageSelected'>
          <div className='d-flex align-items-center'>
            <h3 className='primary heading'>Selected customers</h3>
          </div>
          <div className='selected-customers'>
            {props.selectedCustomers.length === 0 && <div className='pb-5'>No customers selected</div>}
            <div className='pill-list mb-small'>
              {props.selectedCustomers.map((customer: Common.IExpandedCustomerOption, index: number) => (
                <React.Fragment key={index}>
                  <button className='pillButton'>
                    <div className='pillDisplay pr-1'>
                      {customer.display} - {customer.value}
                    </div>
                    <div
                      className='pillTrash'
                      onClick={() => {
                        handleTrash(customer);
                      }}
                    >
                      <a className='btn-close-link' aria-label='Close'>
                        &#10006;
                      </a>
                    </div>
                  </button>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className='d-flex justify-content-end mt-auto'>
            <ButtonPrimary
              className='m-0 mt-auto'
              title='View customers'
              onClick={handleViewCustomers}
              route='/'
              disabled={!isDisabled}
            />
          </div>
        </div>

        <div className='brokerLandingPageSelectedMobile'>
          <div className='d-flex align-items-center'>
            <h3 className='primary heading'>Selected customers</h3>
          </div>
          <div className='selected-customers'>
            {props.selectedCustomers.length === 0 && <div className='pt-2'>No customers selected</div>}
            <div className='pill-list mb-small row'>
              {props.selectedCustomers.map((customer: Common.IExpandedCustomerOption, index: number) => (
                <React.Fragment key={index}>
                  <button className='pillButton'>
                    <div className='pillDisplay pr-1'>
                      {customer.display} - {customer.value}
                    </div>
                    <div
                      className='pillTrash'
                      onClick={() => {
                        handleTrash(customer);
                      }}
                    >
                      <a className='btn-close-link' aria-label='Close'>
                        &#10006;
                      </a>
                    </div>
                  </button>
                </React.Fragment>
              ))}
            </div>
            <div className='d-flex justify-content-end mt-auto'>
              <ButtonPrimary
                className='m-0'
                title='View customers'
                onClick={handleViewCustomers}
                route='/'
                disabled={!isDisabled}
              />
            </div>
          </div>
        </div>
      </BrokerLandingSelectedWrapper>
    </>
  );
};

BrokerLandingSelected.defaultProps = defaultProps;
