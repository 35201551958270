const CircleExclamation = ({ width, height, className, fillColor }: CircleExclamationProps) => (
  <svg className={className} width={width} height={height} viewBox='0 1 20 20' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='11' cy='-1041.36' r='8' transform='matrix(1 0 0-1 0-1030.36)' fill={fillColor} />
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='m-26.309 18.07c-1.18 0-2.135.968-2.135 2.129v12.82c0 1.176.948 2.129 2.135 2.129 1.183 0 2.135-.968 2.135-2.129v-12.82c0-1.176-.946-2.129-2.135-2.129zm0 21.348c-1.18 0-2.135.954-2.135 2.135 0 1.18.954 2.135 2.135 2.135 1.181 0 2.135-.954 2.135-2.135 0-1.18-.952-2.135-2.135-2.135z'
      transform='matrix(.30056 0 0 .30056 18.902 1.728)'
      fill='#fff'
      stroke='#fff'
    />
  </svg>
);

export type CircleExclamationProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

CircleExclamation.defaultProps = {
  width: 40,
  height: 40,
  fillColor: '#216EF2',
};

export default CircleExclamation;
