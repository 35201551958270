import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import moment from 'moment';

import { Table, Loading, NoResults, ButtonPrimary, Icon } from '../../common/components';
import { respondTo } from '../../common/styles/mixins';
import { DATE_FORMAT } from '../../common/constants';
import { LoaDataGridWrapper } from './LoaDataGridStyles';
import { iconsToUse } from '../../common/styles/themes';
import * as variables from '../../common/styles/variables';

const StyledTableWrapper = styled.div`
  ${respondTo.tablet`
    padding-right: 33px;
  `};
`;

type LoaDataGridComponentProps = {
  gridData: Api.IContactSummaryPagedData[];
  handleSortChange: Function;
  handlePagingChange: Function;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
};

const defaultProps = {
  isLoading: true,
  gridData: [],
  hasMoreData: true,
  currentSortField: '',
  currentSortOrder: 'ASC',
};

const actionStyles = {
  width: '150px',
};

const loaDataDraxHeaders: Array<Common.HeaderItem> = [
  {
    title: '',
    dataValue: 'warningIcon',
    enableSort: false,
  },
  {
    title: 'Customer ref',
    dataValue: 'customerAccountReference',
    sortField: 'customerAccountReference',
  },
  {
    title: 'Customer name',
    dataValue: 'customerName',
    sortField: 'customerName',
  },
  {
    title: 'Start date',
    dataValue: 'startDate',
    sortField: 'startDate',
  },
  {
    title: 'End date',
    dataValue: 'endDate',
    sortField: 'endDate',
  },
  {
    title: 'Status',
    dataValue: 'status',
    enableSort: false,
  },
];

export const LoaDataGrid = (props: LoaDataGridComponentProps) => {
  const { gridData, isLoading } = props;
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };
  const scrollableTable = useRef<any>();

  const clearSelection = () => {
    setSelectedRow(null);
  };

  const handleRowSelect = (event: any, row: number | null) => {
    if (row !== undefined) {
      setSelectedRow(row);
    }
  };

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>) => {
    clearSelection();
    const typeId = event.currentTarget.dataset.value || '';
    const details = sortDetails;

    if (details.field === typeId) {
      details.order = details.order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }

    props.handleSortChange(details);
    event.preventDefault();
  };

  const handlePagingChange = () => {
    props.handlePagingChange();
  };

  const renderDate = (value: any) => <td>{moment(value).format(DATE_FORMAT)}</td>;

  const renderwarningIcon = (value: any) => {
    if(value === 'Expiring') {
      return(<td className='warningIcon'> <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> </td>);
    } else if(value === 'Expiring but future dated LoA exists') {
      return(<td className='warningIcon'> <Icon icon={iconsToUse.success} size={variables.iconSizeSmall} /> </td>);
    } else {
      return(<td className='warningIcon'></td>);
    }
  }

  const headers = loaDataDraxHeaders;
  const updatedGridData = gridData.map(el => ({
    ...el,
  }));

  return (
    <LoaDataGridWrapper>
      {!isLoading && gridData.length === 0 && <NoResults />}

      {gridData.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={gridData.length}
            next={handlePagingChange}
            hasMore={props.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <StyledTableWrapper
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1'
            >
              <Table
                headers={headers}
                rows={updatedGridData}
                onClickSort={handleSort}
                sortDetails={sortDetails}
                renderendDate={renderDate}
                actionStyles={actionStyles}
                selectedRowIndex={selectedRow}
                handleSelectRow={handleRowSelect}
                className='list table plain text-left'
                renderstartDate={renderDate}
                renderwarningIcon={renderwarningIcon}
              />
            </StyledTableWrapper>
          </InfiniteScroll>
        </>
      )}

      {isLoading && <Loading overlay />}
    </LoaDataGridWrapper>
  );
};

LoaDataGrid.defaultProps = defaultProps;
