import Modal from 'react-bootstrap/Modal';

import { ModalClose, ButtonPrimary } from '../../../../common/components';
import { CheckSubmitDateWrapper } from '../SubmitMeterReadingModals.styles';

type CheckDateModalProps = {
  date: string;
  onClose: () => void;
  onSubmit: () => void;
  onWrongDate: () => void;
};

export const CheckDateModal = (props: CheckDateModalProps) => {
  const { date, onClose, onSubmit, onWrongDate } = props;

  return (
    <>
      <Modal animation={false} className={'mid-size-modal'} show={true} backdrop='static' centered>
        <Modal.Header>
          <Modal.Title>Do you want to submit?</Modal.Title>
          <ModalClose onClick={onClose} />
        </Modal.Header>

        <Modal.Body>
          <CheckSubmitDateWrapper>
            <p>
              Meter read date <span onClick={onWrongDate}>{date}</span>
            </p>

            <div className='actions'>
              <ButtonPrimary
                title='Submit'
                onClick={(e: any) => {
                  e.preventDefault();
                  onSubmit();
                }}
              />
            </div>
          </CheckSubmitDateWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
};
