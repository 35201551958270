// Modules
import React from 'react';

// Components
import { Icon } from '../../common/components';

// Styles
import { ServiceUpdateWrapper } from './ServiceUpdateStyles';
import * as colors from '../../common/styles/colors';
import { themeDrax, themeOpus } from '../../common/styles/themes';
import { isDraxBrand, isOpusBrand } from '../../utils/common';

export type updateType = 'High' | 'Medium' | 'Low' | '';

type ServiceUpdateProps = {
  type: updateType;
  message: string;
};

const defaultProps = {
  type: '',
  message: '',
};

const ServiceUpdate = (props: ServiceUpdateProps) => {
  const message = props.message;
  const type = props.type.toLowerCase();
  let iconColor = colors.white;

  if (type === '') {
    if (isDraxBrand) {
      iconColor = themeDrax.icons.default;
    } else if (isOpusBrand) {
      iconColor = themeOpus.icons.default;
    } else {
      iconColor = colors.razzmatazzPink;
    }
  }
  return (
    <>
      {message && (
        <ServiceUpdateWrapper {...props}>
          <div className={`d-flex justify-content-center service-update ${type}`}>
            <div className='icon'>
              <Icon color={iconColor} size={30} icon='warning' />
            </div>
            <div className='message'>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          </div>
        </ServiceUpdateWrapper>
      )}
    </>
  );
};

ServiceUpdate.defaultProps = defaultProps;

export default ServiceUpdate;
