import styled from 'styled-components';

import * as variables from '../../../common/styles/variables';

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: ${variables.spacingMedium};

  a {
    min-width: 40%;
    margin: 0;
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;

  .rmdp-wrapper {
    box-shadow: none;
  }
`;

export const CheckSubmitDateWrapper = styled.div`
  p {
    margin-bottom: ${variables.spacingMedium};
  }

  span {
    color: ${props => props.theme.text.secondary};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  a {
    margin: 0;
  }
`;
