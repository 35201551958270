import styled from 'styled-components';

export const XModalCloseContainer = styled.button`
  right: 2.5px;
  top: 2.5px;
  padding: 10px;
  border: none;
  background: none;

  & div {
    width: 16px;
    height: 16px;
    background: ${({ theme }) => theme.primary.bg};
    border-radius: 50%;
  }
`;
