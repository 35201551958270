import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';

export const CustomDatePickerWrapper = styled.div`
  .rmdp-container {
    display: block !important;
  }

  .error {
    border: 1px solid ${colors.errorLight};
  }

  .error-message {
    color: ${colors.errorLight};
    font-size: ${variables.fontSizeMedium};
    margin-top: ${variables.spacingXSmall};
  }

  .icon {
    right: 10px !important;
  }
`;
