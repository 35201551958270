const Meter = ({ width, height, className, fillColor }: MeterProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.4375 37.5076H12.1875'
      stroke='#0A1464'
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M30 15.9451V19.6951'
      stroke='#0A1464'
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M45.2467 22.261L42.5955 24.9122'
      stroke='#0A1464'
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M51.5625 37.5076H47.8125'
      stroke='#0A1464'
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M26.685 34.1926L15 22.5076'
      stroke='#0A1464'
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M29.999 42.1951C32.5879 42.1951 34.6865 40.0964 34.6865 37.5076C34.6865 34.9187 32.5879 32.8201 29.999 32.8201C27.4102 32.8201 25.3115 34.9187 25.3115 37.5076C25.3115 40.0964 27.4102 42.1951 29.999 42.1951Z'
      stroke='#0A1464'
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M53.7863 52.5077C56.4706 48.2531 57.9647 43.3564 58.1128 38.328C58.2609 33.2996 57.0576 28.3235 54.6283 23.9183C52.199 19.5131 48.6326 15.8402 44.3009 13.2822C39.9691 10.7243 35.0306 9.375 30 9.375C24.9694 9.375 20.0309 10.7243 15.6991 13.2822C11.3674 15.8402 7.80101 19.5131 5.3717 23.9183C2.9424 28.3235 1.73909 33.2996 1.88719 38.328C2.0353 43.3564 3.52939 48.2531 6.21375 52.5077H53.7863Z'
      stroke='#0A1464'
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export type MeterProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Meter.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#0A1464',
};

export default Meter;
