import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';
import { respondTo } from '../../styles/mixins';

export const FilterDropDownWrapper = styled.div`
  ${mixins.clearfix};
  font-weight: 400;
  border-radius: ${variables.inputRadius};
  width: 100%;
  padding: 10px 15px;
  text-overflow: ellipsis;
  border: 1px solid ${({ theme }) => theme.filterDropdown.border};
  background-color: ${({ theme }) => theme.filterDropdown.bg};
  cursor: pointer;

  .chevron {
    position: absolute;
    right: 0.2em;
    top: 0.2em;
  }

  *,
  *:after,
  *:before {
    ${mixins.borderBox};
    padding: 0;
    margin: 0;
  }

  ::selection {
    background: transparent;
  }

  ::-moz-selection {
    background: transparent;
  }

  .wrapperdropdown {
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    background-position-x: 100%;
  }

  .wrapperdropdown .dropdown {
    ${mixins.scrollbars};
    z-index: ${variables.zIndexHighest * 10};
    position: absolute;
    top: 35px;
    left: -16px;
    right: -16px;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    border-bottom: none;
    list-style: none;
    max-height: 0;
    overflow-y: auto;

    ${respondTo.tablet`
      width: fit-content;
      max-width: 280px;
      left: auto;
    `};
  }

  .wrapperdropdown .dropdown li {
    ${mixins.transition};
    background: ${colors.lightGrey};

    &:hover {
      ${mixins.transition};
      background: ${colors.white};
      color: ${({ theme }) => theme.text.secondary};

      .itemicon {
        ${mixins.transition};
        fill: ${({ theme }) => theme.text.secondary};
      }
    }

    &.selected {
      background-color: ${colors.white};
      color: ${({ theme }) => theme.text.secondary};

      .itemicon {
        fill: ${({ theme }) => theme.text.secondary};
      }
    }

    .dropdownitem {
      padding: 10px;

      .item__value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .wrapperdropdown .dropdown li .dropdownitem {
    display: block;
    text-decoration: none;
    border-bottom: 1px solid ${colors.inputGrey};
    line-height: 1.5;
  }

  .itemicon {
    margin-right: 0.5em;
  }

  .wrapperdropdown .dropdown li:last-of-type .dropdownitem {
    border: none;
  }

  .selecteditem {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      line-height: 1;
      padding-left: 5px;
    }
  }

  .wrapperdropdown.active {
    border-radius: 5px 5px 0 0;
    box-shadow: none;
    border-bottom: none;
  }

  .wrapperdropdown.active:after {
    border-color: #82d1ff transparent;
  }

  .wrapperdropdown.active .dropdown {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    max-height: 400px;
  }

  &.plain {
    ${respondTo.tablet`
        padding: 0;
        border: none;
        color: ${colors.oxfordBlue};
        background-color: transparent;

        .itemicon {
          position: relative;
          left: 0.5em;
        }

        .wrapperdropdown .selectText.active{
          color: ${({ theme }) => theme.text.secondary};
        }

        .wrapperdropdown .selectText {
          &:hover {
            color: ${({ theme }) => theme.text.secondary};
          }
        }

        .wrapperdropdown .dropdown {

          @-moz-document url-prefix() {
            width: 200px;  
          }

          ${mixins.boxShadowPrimary};
          border: 0;
          border-radius: 5px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          text-align: left;
          ${mixins.textTruncate};

          li {
            background-color: ${colors.white};

            &:first-child {
              border-top: 1px solid ${colors.lightGrey};
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            &:hover,
            &.active {
              background-color: ${({ theme }) => theme.primary.bg};
              color: ${colors.white};
            }
          }

          .dropdownitem {
            border: 0;
          }
        }

        .selecteditem span {
          padding-left: 0;
        }
    `}
  }

  &.filter {
    .itemicon {
      display: none !important;
    }
  }

  &.no__border {
    border: none;
  }

  &.portal_filter{
    width: '200px';
    cursor: 'pointer';
    borderRadius: '16px';
    padding: '0 15px';
    border: '1px solid #BBBBBB';
    background: '#F5F5F5';
    minHeight: '30px';
    whiteSpace: 'nowrap';
  }
`;
