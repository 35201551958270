import React, { useEffect, useState } from 'react';
import { RowInputItemWrapper } from './RowInputItem.styles';

import { convertNullValues, trimLeadingAndTrailingSpaces } from '../../../components/utils/data/data';

type RowInputItemProps = {
  style?: any;
  value: string | number;
  readOnly: boolean;
  onHandleBlur: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onInput?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeValidation: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  validateValue?: (value: string | number) => boolean;
  className: string;
  background: boolean;
  maxLength: number;
  min?: number;
  max?: number;
  step?: number | string;
  pattern?: string;
  isSubmitted?: boolean;
  placeholder: string;
  type: string;
  htmlInputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
};

const defaultProps = {
  initialValue: '',
  readOnly: false,
  onHandleBlur: undefined,
  onInput: undefined,
  onFocus: undefined,
  background: false,
  className: '',
  maxLength: 20,
  min: 0,
  placeholder: '',
  type: 'text',
  htmlInputProps: {},
  onChangeValidation: () => true,
  validateValue: () => true,
};

export const RowInputItem = (props: RowInputItemProps) => {
  const [value, setValue] = useState<string | number>(props.value);
  const [additionalClassNames, setAdditionalClassNames] = useState('');
  const [isValidValue, setIsValid] = useState(true);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!value && props.isSubmitted) {
      setAdditionalClassNames('input-error');
    } else {
      setAdditionalClassNames('');
    }

    if (value && !isValidValue) {
      setAdditionalClassNames('input-error');
    } else if (!props.isSubmitted) {
      setAdditionalClassNames('');
    }
  }, [props.isSubmitted, value, isValidValue]);

  const onHandleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (!event.target.value || props.onChangeValidation(event)) {
      // Restrict to the max number
      if (props.max) {
        const value = Number(event.target.value);
        if (value > props.max) {
          return;
        }
      }

      setValue(event.target.value);

      if (typeof props.onChange === 'function') {
        props.onChange(event);
      }

      if (typeof props.validateValue === 'function') {
        const isValid = props.validateValue(event.target.value);

        if (typeof isValid === 'boolean') {
          setIsValid(isValid);
        }
      }
    }
  };

  const onHandleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (typeof props.onHandleBlur === 'function') {
      event.target.value = trimLeadingAndTrailingSpaces(event.target.value);
      props.onHandleBlur(event);
    }
  };

  const preventTextInput = (e: any) => {
    const invalidChars = ['-', '+', 'e', 'E'];
    if (invalidChars.includes(e.key) && props.type === 'number') {
      e.preventDefault();
    }
  };

  return (
    <RowInputItemWrapper>
      <input
        {...props.htmlInputProps}
        min={props.min}
        max={props.max}
        step={props.step}
        type={props.type}
        style={props.style}
        onInput={props.onInput}
        onFocus={props.onFocus}
        pattern={props.pattern}
        readOnly={props.readOnly}
        disabled={props.readOnly}
        maxLength={props.maxLength}
        value={convertNullValues(value)}
        onBlur={event => onHandleBlur(event)}
        onChange={event => onHandleChange(event)}
        placeholder={convertNullValues(value) || (!props.readOnly && props.placeholder)}
        onKeyDown={event => preventTextInput(event)}
        className={`${props.className} ${additionalClassNames} ${props.background ? 'pale-background' : ''}`}
      />
    </RowInputItemWrapper>
  );
};

RowInputItem.defaultProps = defaultProps;
