import React, { ReactElement } from 'react';

import { Icon } from '../../../common/components';
import { EssAccountsTypes } from '../../../types/account';
import { isOpusBrand } from '../../../utils/common';
import SearchActions from '../SearchActions/SearchActions';
import { StyledRow, StyledTitle, SearchItemWrapper } from './SearchItemStyles';

type SearchItemProps = {
  type: Common.searchItemType;
  item: any;
  handleSelected: Function;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultProps = {
  type: '',
  item: {},
  handleSelected: undefined,
};

const SearchItem = (props: SearchItemProps): ReactElement => {
  const energyIcon = (): string => {
    if (
      props.item.productClass === EssAccountsTypes.Electricity ||
      props.item.productClass === 'icon-electricity-portal'
    )
      return 'electricity';
    else if (props.item.productClass === EssAccountsTypes.Gas || props.item.productClass === 'icon-gas-portal')
      return 'gas-02';
    else if (props.item.productClass === EssAccountsTypes.Renewables) return 'renewables';
    else return '';
  };

  return (
    <SearchItemWrapper>
      <div className='searchitem-row'>
        {isOpusBrand && (
          <div className={`icon-circle ${props.type === 'site' ? 'hide' : ''}`}>
            {props.type === 'account' && <Icon className='itemicon' icon={energyIcon()} />}
            {props.type === 'mpan' && <Icon className='itemicon' icon='electricity' />}
            {props.type === 'mprn' && <Icon className='itemicon' icon='gas-02' />}
            {props.type === 'invoice' && <Icon className='itemicon' icon={energyIcon()} />}
            {props.type === 'statement' && <Icon className='itemicon' icon='renewables' />}
          </div>
        )}

        <StyledRow className='col-md-12'>
          {props.type === 'account' && <StyledTitle>{props.item.description}</StyledTitle>}
          {props.type === 'site' && <StyledTitle>{props.item.subjectID}</StyledTitle>}
          {props.type === 'mpan' && <StyledTitle>{props.item.mpan}</StyledTitle>}
          {props.type === 'mprn' && <StyledTitle>{props.item.mprn}</StyledTitle>}
          {props.type === 'invoice' && <StyledTitle>{props.item.invoiceNumber}</StyledTitle>}
          {props.type === 'statement' && <StyledTitle>{props.item.statementNumber}</StyledTitle>}

          <SearchActions
            className='searchAction'
            type={props.type}
            item={props.item}
            handleSelected={props.handleSelected}
          />
        </StyledRow>
      </div>
    </SearchItemWrapper>
  );
};

SearchItem.defaultProps = defaultProps;

export default SearchItem;
