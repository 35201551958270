import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../common/components';

type HHDataRequestNoHHModalProps = {
  onHide: () => void;
  show: boolean;
};

const defaultProps = {
  show: true,
};

export const HHDataRequestNoHHModal = (props: HHDataRequestNoHHModalProps) => {
  const { show } = props;
  const title = 'HH data request';
  const topActiveRequest = "The selected account doesn't support HH data.";

  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
          <p>{topActiveRequest}</p>
        </Modal.Body>
        <Modal.Footer className='p-0'>
          <ButtonPrimary onClick={handleClose} title='OK' className='mt-3 mr-0' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

HHDataRequestNoHHModal.defaultProps = defaultProps;
