import styled from 'styled-components';
import * as mixins from '../../common/styles/mixins';
import * as colors from '../../common/styles/colors';
import { respondTo } from '../../common/styles/mixins';
import * as variables from '../../common/styles/variables';

export const TpiUserDetailsHeaderWrapper = styled.div`
  .typeAheadDropDown {
    ul {
      ${respondTo.tablet`
        background-color: ${colors.white};
      `};
    }
  }

  .btndisabletpi {
  }

  .btnenabletpi {
  }

  .btnDownloadReport {
    margin-left: ${variables.spacingSmall};
  }

  .btnDownloaduserReport {
    margin-top: 25px;
    ${respondTo.desktopXXl`
      margin-top: 8px;
    `};
  }

  .dropdown {
    width: 80%;
  }

  .title {
    h4 {
      color: ${({ theme }) => theme.primary.color};
      font-size: 21px;
      margin: 0;
    }
  }

  .filter {
    padding-left: 90px;
  }
`;

export const TpiUserDetailsHeaderSectionWrapper = styled.div`
  background-color: ${colors.lightGrey};
  border-radius: ${variables.inputRadius};
  padding: ${variables.spacingSmall};
  margin-right: ${variables.spacingSmall};
  margin-bottom: ${variables.spacingSmall};

  .title {
    padding-right: 10px;
    float: left;
  }
`;
