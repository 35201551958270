import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, Icon, ModalClose } from '../../../../common/components';
import FormElement from '../../../../common/components/FormElement';
import * as colors from '../../../../common/styles/colors';
import { iconsToUse } from '../../../../common/styles/themes';
import { respondTo } from '../../../../common/styles/mixins';
import { TagManager } from '../../../utils/analytics/TagManager';

type UploadLoAModalProps = {
  tpiReference: string;
  tpiName: string;
  submittedBy: string;
  show: boolean;
  upload: Function;
  onHide: Function;
};

const defaultProps = {
  tpiName: '',
  submittedBy: '',
  show: true,
};

export const UploadLoAWrapper = styled.div`
  .upload-btn {
    width: 100%;
  }

  .dropzonecontainer {
    border 2px ${colors.hydroBlue} dashed;
    border-radius: 10px;

    div {
      color: ${colors.hydroBlue};
    }

    .loaIcon {
      display: none;
      position: absolute;
      left: 2px;
      padding-top: 10px;

      ${respondTo.tablet`
        display: block;
        left: 2px;
      `}

      ${respondTo.desktop`
        display: block;
        left: 50px;
      `}
    }

    .title {
      font-size: 20px;
    }
  }

  .container {
    border: 1px solid red;
    height: 300px;
    display: flex;
    flex-direction: row;
    
    flex-wrap: wrap;
  }
  
  .item {
    border: 1px dashed blue;
    height: 50px;
  }

  .filecontainer {
    .file {
      border 2px ${colors.midGrey} solid;
      border-radius: 10px;
      color: ${colors.darkGrey};
    }
  
    .filepath {
      white-space: nowrap;
      padding-right: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .filesize {
      line-height: 0.8;
      padding-bottom: 5px;
    }

    .delete {
      cursor: pointer;
    }

    .close {
      margin-top: -7px;
      padding-right: 10px;
    }
  }

  .errors {
    color: ${colors.danger};
    padding-top: 10px;
  }
`;

export const UploadLoAModal = (props: UploadLoAModalProps) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [comments, setComments] = useState<string>('');
  const [showUploadTooBigError, setShowUploadTooBigError] = useState<boolean>(false);
  const [showUploadMustBePdfError, setShowUploadMustBePdfError] = useState<boolean>(false);
  const [showUploadTooManyFilesError, setShowUploadTooManyFilesError] = useState<boolean>(false);

  const totalSizeLimit = 20 * 1024 * 1024; // 20 MB

  const getTotalPreviousUploadedFilesSize: any = () => {
    var totalSize = 0;
    files.forEach((file: File) => {
      totalSize = totalSize + file.size;
    });
    return totalSize;
  };

  const formatSize = (size: number) => {
    const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    var i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    var exactSize = Math.round(size * 100) / 100 + ' ' + fSExt[i];
    return exactSize;
  };

  const findExistingFile: any = (droppedFiles: Array<File>) => {
    var existingFiles: Array<File> = [];

    droppedFiles.forEach((file: File) => {
      const index = files.findIndex(f => f.name === file.name);
      if (index >= 0) {
        existingFiles.push(file);
      }
    });
    return existingFiles;
  };

  const onDrop = (droppedFiles: Array<File>) => {
    setShowUploadTooBigError(false);
    setShowUploadMustBePdfError(false);
    setShowUploadTooManyFilesError(false);

    const existingFilesCount = files.length;
    const droppedFilesCount = droppedFiles.length;
    if (existingFilesCount + droppedFilesCount > 10) {
      setShowUploadTooManyFilesError(true);
      return;
    }

    let alreadyUploadedTotalSize = getTotalPreviousUploadedFilesSize();
    droppedFiles.forEach((file: File) => {
      alreadyUploadedTotalSize += file.size;
    });
    if (alreadyUploadedTotalSize > totalSizeLimit) {
      setShowUploadTooBigError(true);
      return;
    }

    // Check for duplicates
    const existingFiles = findExistingFile(droppedFiles);
    if (existingFiles) {
      deleteFiles(existingFiles);
    }

    setFiles([...files, ...droppedFiles]);
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 10,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  useEffect(() => {
    const rejections: any = fileRejections;
    if (rejections) {
      rejections.map((file: any) => {
        if (file && file.errors.length > 0) {
          file.errors.map((error: any) => {
            if (error.code === 'too-many-files') {
              setShowUploadTooManyFilesError(true);
              return;
            } else if (error.code === 'file-invalid-type') {
              setShowUploadMustBePdfError(true);
              return;
            }
          });
        }
      });
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  const handleUploadClick = (event: any) => {
    event.preventDefault();

    if (window.location.pathname === '/home') {
      TagManager.pushData({
        event: 'Submit LoA (Landing page)',
      });
    }

    if (window.location.pathname === '/home/loa') {
      TagManager.pushData({
        event: 'Submit LoA (LoA data page)',
      });
    }

    const obj: Api.ILoaFileUpload = {
      tpiReference: props.tpiReference,
      tpiName: props.tpiName,
      emailAddress: props.submittedBy,
      comments: comments,
      files: files,
    };
    console.log(obj);

    props.upload(obj);

    props.onHide();
  };

  const deleteFiles = (filesToDelete: Array<File>) => {
    filesToDelete.forEach((file: File) => {
      const tmp = files;
      const index = tmp.findIndex(f => f.name === file.name);
      if (index >= 0) {
        tmp.splice(index, 1);
        setFiles([...tmp]);
      }
    });
  };

  const handleDelete = (event: any, file: File) => {
    event.preventDefault();
    deleteFiles([file]);
  };

  const renderedFiles = files.map((file: any, index: number) => (
    <React.Fragment key={index}>
      <div className='col-12 col-md-6 mt-2'>
        <div className='file'>
          <div className='pl-2'>
            <div className='filepath'>{file.path}</div>
            <button className='close' onClick={event => handleDelete(event, file)}>
              <span aria-hidden='true'>
                <Icon color={colors.darkGrey} size={16} icon='cross' />
              </span>
            </button>
          </div>
          <div className='pl-2'>
            <div className='filesize'>{formatSize(file.size)}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={props.show} size={'lg'} backdrop='static' centered>
        <UploadLoAWrapper>
          <Modal.Header className='pt-0 pb-0 pr-0'>
            <Modal.Title>Upload Letter of Authority</Modal.Title>
            <ModalClose onClick={() => props.onHide()} />
          </Modal.Header>

          <Modal.Body>
            <form>
              <div className='pt-3'>
                <FormElement.Input
                  id='tpiname'
                  className='c-form__input'
                  label={''}
                  name='tpiName'
                  type='text'
                  value={props.tpiName || ''}
                  onChange={() => {}}
                  errorMessage=''
                  disabled={true}
                  locked={true}
                />
              </div>

              <div className=''>
                <p>
                  Submit a PDF copy of each Letter of Authority to our Servicing team. We'll validate and process them
                  when we receive them.
                </p>
              </div>
              <div className='' style={{ fontStyle: 'italic' }}>
                <p>
                  Please include information such as customer name, account reference and MPAN within the file name to
                  help us link it to the right customer.
                </p>
              </div>
              <div className='pt-3'>
                <section className='dropzonecontainer'>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className='loaIcon'>
                      <Icon icon={iconsToUse.loaHealth} size={80} />
                    </div>
                    <div className='pt-4 text-center title'>Click to add or drag .pdf files...</div>
                    <div className='pb-4 text-center' style={{ fontStyle: 'italic' }}>
                      maximum of 10 files - 20mb total
                    </div>
                  </div>
                </section>
              </div>

              <div className='errors'>
                {showUploadTooBigError && <div>You can only upload files to a maximum of 20MB</div>}
                {showUploadMustBePdfError && <div>You can only upload pdf files</div>}
                {showUploadTooManyFilesError && <div>You can only upload a maximum of 10 files</div>}
              </div>

              <div className='filecontainer gx-5'>
                <div className='row'>{renderedFiles}</div>
              </div>

              <div className='pt-3'>
                <FormElement.Textarea
                  id='comments'
                  placeholder={'Any additional comments...'}
                  className='c-form__input no-resize font-italic'
                  label={'Comments'}
                  name='message'
                  value={comments}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setComments(e.target.value);
                  }}
                  rows={5}
                  maxLength={500}
                />
              </div>

              <div className='pt-3'>
                <FormElement.Input
                  id='submittedby'
                  className='c-form__input'
                  label={'Submitted By'}
                  name='submittedBy'
                  type='text'
                  value={props.submittedBy || ''}
                  onChange={() => {}}
                  errorMessage=''
                  disabled={true}
                  locked={true}
                />
              </div>
            </form>
          </Modal.Body>

          <div className='d-flex justify-content-end'>
            <ButtonPrimary
              disabled={files.length < 1}
              className='mt-3 upload-btn'
              title='Submit'
              onClick={handleUploadClick}
            />
          </div>
        </UploadLoAWrapper>
      </Modal>
    </>
  );
};

UploadLoAModal.defaultProps = defaultProps;
