import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, Checkbox, CustomDatePicker, DropDown, Icon, ModalClose } from '../../../common/components';
import FormElement from '../../../common/components/FormElement';
import * as colors from '../../../common/styles/colors';
import * as variables from '../../../common/styles/variables';
import ReactSelect from 'react-select';
import { newMessageStyles, newMessageStylesError } from './styles/NewMessageModalStyles';
import { DATE_FORMAT, REVERSED_DATE_FORMAT_HYPHEN } from '../../../common/constants';
import moment from 'moment';
import { createMessage } from '../../../common/api/adminMessagesApi';
import { useMsal } from '@azure/msal-react';
import { useNewMessageModalValidation } from './useNewMessageModalValidation';
import { onlyLettersNumbersAndCommas } from '../../utils/text/text';

type NewMessageModalProps = {
  show: boolean;
  onHide: Function;
};

const defaultProps = {
  show: true,
};

export const NewMessageWrapper = styled.div`
  .dropzonecontainer {
    border: 2px solid #dde4e9;
    background-color: #f2f2f2;
    border-radius: 8px;
    min-height: 150px;
    overflow-wrap: break-word;

    div {
      color: ${colors.darkMidGrey};
      font-weight: 600;
    }

    .title {
      font-size: 20px;
    }
  }

  .container {
    border: 1px solid red;
    height: 300px;
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
  }

  .item {
    border: 1px dashed blue;
    height: 50px;
  }

  .modal-title {
    text-decoration: underline;
    color: ${colors.hydroBlue};
  }

  .dropdown-third {
    margin-bottom: 20px;
    width: 30%;
    height: 54px;
  }

  .reference-box {
    width: 65%;
  }

  .reference-box-input {
    height: 50px;
    padding-top: 10px;
    font-weight: normal;
  }

  .label {
    display: block;
    margin: 0 0 5px 12px;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-mid-gray);
  }

  .dropdown-full {
    margin-bottom: 20px;
  }

  .upload-btn {
    border-radius: 16px;
  }

  .date-picker {
    width: 30%;
    max-height: 32px;
  }

  .has-error {
    input {
      border: 2px solid #d22f19;
      border-radius: 8px;
    }
  }

  .error-text {
    color: rgb(210, 47, 25);
    font-size: ${variables.fontSizeMedium};
    margin-top: ${variables.spacingXSmall};
    font-weight: bold;
  }

  .smallFont {
    font-size: 14.5px;
  }
`;

export const NewMessageModal = (props: NewMessageModalProps) => {
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [customerRefStr, setCustomerRefStr] = useState<string>('');
  const [customerRefDisabled, setCustomerRefDisabled] = useState<boolean>(true);
  const [customerRefError, setCustomerRefError] = useState<string>('');
  const [customerRefPlaceholder, setCustomerRefPlaceholder] = useState<string>('');
  const [userType, setUserType] = useState<IOption>({ value: 'Select', label: 'Select message type' });
  const [priority, setPriority] = useState<IOption>({ value: '', label: '' });
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [preference, setPreference] = useState('Internal page');
  const [internalLink, setInternalLink] = useState<IOption>({ value: 'Select', label: 'Select a page' });
  const [externalLink, setExternalLink] = useState<string>('');
  const [selectPreview, setSelectPreview] = useState<boolean>(false);
  const [selectSendEmail, setSelectSendEmail] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('Draft');
  const { instance, accounts } = useMsal();

  interface IOption {
    value: string;
    label: string;
  }

  const userTypeOptions: Array<IOption> = [
    { value: 'ALL', label: 'All' },
    { value: 'AllCustomers', label: 'All customers' },
    { value: 'AllTPIUsers', label: 'All TPI users' },
    { value: 'SpecificCustomers', label: 'Specific customer(s)' },
    { value: 'SpecificTPIUsers', label: 'Specific TPI user(s)' },
  ];
  const priorityOptions: Array<IOption> = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
  ];
  const internalPageOptions: Array<IOption> = [
    { value: '/home/messages', label: 'Messages' },
    { value: '/home/site-details', label: 'Site details' },
    { value: '/home/contract-summary', label: 'Contract summary' },
    { value: '/home/moving-premises', label: 'Moving premises' },
    { value: '/home/mpan-details', label: 'MPAN details' },
    { value: '/home/energy-usage/previous-meter-readings', label: 'Previous meter readings' },
    { value: '/home/meter-reads', label: 'Meter reads' },
    { value: '/home/invoices', label: 'Invoices' },
    { value: '/home/make-a-payment', label: 'Make a payment' },
    { value: '/home/energy-usage/data', label: 'Data' },
    { value: '/home/exclusive-offers', label: 'Exclusive offers' },
  ];

  let startDateWindowMin = moment().format(REVERSED_DATE_FORMAT_HYPHEN);
  let customerRefArr: Array<string> = [];

  const {
    subjectError,
    messageError,
    userTypeError,
    priorityError,
    startDateError,
    endDateError,
    internalLinkError,
    externalLinkError,
    validateSubject,
    validateMessage,
    validateUserType,
    validatePriority,
    validateStartDate,
    validateEndDate,
    validateInternalLink,
    validateExternalLink,
    clearAllValidation,
  } = useNewMessageModalValidation();

  const handleUserTypeChange = async (userType: IOption) => {
    setUserType(userType);
    setCustomerRefStr('');
    setSelectSendEmail(false);

    if (userType.value === 'SpecificCustomers') {
      setCustomerRefDisabled(false);
      setCustomerRefPlaceholder('Input Customer account references(s) separated by a comma');
    } else if (userType.value === 'SpecificTPIUsers') {
      setCustomerRefDisabled(false);
      setCustomerRefPlaceholder('Input TPI references(s) separated by a comma');
    } else {
      setCustomerRefError('');
      setCustomerRefDisabled(true);
      setCustomerRefPlaceholder('');
    }
  };

  const handleMessagePriorityChange = async (messagePriority: IOption) => {
    setPriority(messagePriority);
  };

  const handleInternalPageChange = async (internalPage: IOption) => {
    setInternalLink(internalPage);
    setExternalLink('');
  };

  const handleFormClear = () => {
    setSubject('');
    setMessage('');
    setUserType({ value: 'Select', label: 'Select message type' });
    setPriority({ value: '', label: '' });
    setStartDate('');
    setEndDate('');
    setPreference('Internal page');
    setInternalLink({ value: 'Select', label: 'Select a page' });
    setExternalLink('');
    setSelectPreview(false);
    setStatus('Draft');
    setCustomerRefStr('');
    setCustomerRefDisabled(true);
    setCustomerRefError('');
    setCustomerRefPlaceholder('');
    customerRefArr = [];
    setSelectSendEmail(false);

    clearAllValidation();
  };

  const handleSelectPreview = () => {
    setSelectPreview(!selectPreview);
  };

  const handleSelectSendEmail = () => {
    setSelectSendEmail(!selectSendEmail);
  };

  const validateLink = () => {
    if (preference === 'Internal page') {
      if (internalLink.value !== 'Select' && internalLink.value !== '') {
        return true;
      } else {
        return false;
      }
    } else if (preference === 'External page') {
      if (externalLink.length) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const validateReferenceInput = () => {
    setCustomerRefError('');

    if (userType.value === 'SpecificCustomers') {
      return validateSpecificCustomers(customerRefStr);
    } else if (userType.value === 'SpecificTPIUsers') {
      return validateTpiCustomers(customerRefStr);
    } else {
      return true;
    }
  };

  const validateSpecificCustomers = (references: string) => {
    if (!references.length) {
      setCustomerRefError('Input Customer account references(s) separated by a comma');
      return false;
    } else {
      const referenceArray = references.split(/[ ,]+/);
      for (let i = 0; i < referenceArray.length; i++) {
        if (referenceArray[i].toUpperCase().startsWith('TPI')) {
          setCustomerRefError('Input Customer account references(s) separated by a comma');
          return false;
        }
      }

      customerRefArr = referenceArray;
    }
    return true;
  };

  const validateTpiCustomers = (references: string) => {
    if (!references.length) {
      setCustomerRefError('Input TPI references(s) separated by a comma');
      return false;
    } else {
      const referenceArray = references.split(/[ ,]+/);
      for (let i = 0; i < referenceArray.length; i++) {
        if (!referenceArray[i].toUpperCase().startsWith('TPI')) {
          setCustomerRefError('Input TPI references(s) separated by a comma');
          return false;
        }
      }
      customerRefArr = referenceArray;
    }
    return true;
  };

  const handleCustomerRefChange = (event: any) => {
    setCustomerRefError('');
    if (onlyLettersNumbersAndCommas(event.target.value)) {
      setCustomerRefStr(event.target.value);
    }
  };

  const checkMaxStartDate = () => {
    const startDateWindowMax = moment(startDateWindowMin).add(6, 'M').endOf('month');
    if (endDate && endDate <= startDateWindowMax) {
      return endDate.format(REVERSED_DATE_FORMAT_HYPHEN);
    } else {
      return startDateWindowMax.format(REVERSED_DATE_FORMAT_HYPHEN);
    }
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    let json = {
      message: message,
    };

    clearAllValidation();
    validateSubject(subject);
    validateMessage(json.message);
    validateUserType(userType.value);
    validatePriority(priority.value);
    {
      preference === 'Internal page' && validateInternalLink(internalLink.value);
    }
    {
      preference === 'External page' && validateExternalLink(externalLink);
    }
    validateStartDate(startDate);
    validateEndDate(endDate);
    if (subject && !subjectError) {
      if (json.message && !messageError) {
        if (userType.value && !userTypeError) {
          if (priority.value && !priorityError) {
            if (validateLink()) {
              if (!customerRefError && validateReferenceInput()) {
                if (startDate && !startDateError) {
                  if (endDate && !endDateError) {
                    const data: Api.IAdminCreateMessageData = {
                      subject: subject,
                      startDate: startDate ? startDate.format(REVERSED_DATE_FORMAT_HYPHEN) : '',
                      endDate: endDate ? endDate.format(REVERSED_DATE_FORMAT_HYPHEN) : '',
                      priority: priority.value,
                      externalLink: preference === 'External page' ? externalLink : '',
                      internalLink: preference === 'Internal page' ? internalLink.value : '',
                      showInMessages: internalLink.value === '/home/messages' ? true : false,
                      status: status === 'Ready to publish' ? 'Ready' : 'Draft',
                      json: JSON.stringify(json),
                      userType: userType.value,
                      references: customerRefArr,
                      sendEmail: selectSendEmail,
                    };
                    await createMessage(accounts[0], data, instance);

                    handleFormClear();
                    props.onHide();
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const handleCancelClick = () => {
    handleFormClear();
    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={props.show} size={'lg'} backdrop='static' centered>
        <NewMessageWrapper>
          <Modal.Header className='pt-0 pb-0 pr-0'>
            <Modal.Title className='modal-title'>New message</Modal.Title>
            <ModalClose onClick={() => handleCancelClick()} />
          </Modal.Header>

          <Modal.Body>
            <form>
              <div className='pt-2'>
                <div className='label'>Subject *</div>
                <FormElement.Textarea
                  id='subject'
                  placeholder={'Max characters 150'}
                  className='c-form__input no-resize'
                  name='subject'
                  value={subject}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setSubject(e.target.value);
                  }}
                  rows={2}
                  maxLength={150}
                  required={true}
                  errorMessage={subjectError}
                />
              </div>

              <div className='pt-1'>
                <div className='label'>Message *</div>
                <FormElement.Textarea
                  id='message'
                  placeholder={''}
                  className='c-form__input no-resize'
                  name='message'
                  value={message}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setMessage(e.target.value);
                  }}
                  rows={3}
                  required={true}
                  errorMessage={messageError}
                />
              </div>

              <div className='container-fluid'>
                <div className='row justify-content-between'>
                  <div className='label'>
                    Select a message type or input specific customer account reference(s) or TPI reference(s)
                  </div>
                  <div className='pt-1 dropdown-third'>
                    <ReactSelect
                      autoFocus={false}
                      blurInputOnSelect={true}
                      placeholder={'Select message type'}
                      value={userType}
                      styles={userTypeError ? newMessageStylesError : newMessageStyles}
                      options={userTypeOptions}
                      onChange={handleUserTypeChange}
                    />
                    {userTypeError && <div className='error-text'>Required field</div>}
                  </div>

                  <div className='reference-box'>
                    <FormElement.Textarea
                      id='specRef'
                      placeholder={customerRefPlaceholder}
                      className='c-form__input no-resize reference-box-input'
                      label={''}
                      name='specRef'
                      value={customerRefStr}
                      onChange={e => handleCustomerRefChange(e)}
                      rows={1}
                      errorMessage={customerRefError}
                      disabled={customerRefDisabled}
                    />
                  </div>
                </div>
              </div>

              {(userType.value === 'SpecificCustomers' || userType.value === 'SpecificTPIUsers') && (
                <div className='smallFont pb-3'>
                  <Checkbox
                    id={'select-push-email'}
                    label={
                      'Do you want to send a push email out to the users to inform them of an important message on the portal?'
                    }
                    onChange={handleSelectSendEmail}
                    checked={selectSendEmail}
                  ></Checkbox>
                </div>
              )}

              <div className='container-fluid'>
                <div className='row justify-content-between pb-4'>
                  <div className='pt-1 dropdown-third'>
                    <div className='label'>Priority *</div>
                    <ReactSelect
                      autoFocus={false}
                      blurInputOnSelect={true}
                      placeholder={''}
                      value={priority}
                      styles={priorityError ? newMessageStylesError : newMessageStyles}
                      options={priorityOptions}
                      onChange={handleMessagePriorityChange}
                    />
                    {priorityError && <div className='error-text'>Required field</div>}
                  </div>

                  <div className='pt-1 date-picker'>
                    <div className='label'>Start date *</div>
                    <div className={startDateError ? 'has-error' : ''}>
                      <CustomDatePicker
                        date={startDate ? startDate.format(DATE_FORMAT) : ''}
                        showIcon={true}
                        minDate={startDateWindowMin}
                        maxDate={checkMaxStartDate()}
                        onChange={(e: any) => {
                          setStartDate(e);
                        }}
                      />
                    </div>
                    {startDateError && <div className='error-text'>Required field</div>}
                  </div>

                  <div className='pt-1 date-picker'>
                    <div className='label'>End date *</div>
                    <div className={endDateError ? 'has-error' : ''}>
                      <CustomDatePicker
                        className=''
                        date={endDate ? endDate.format(DATE_FORMAT) : ''}
                        showIcon={true}
                        minDate={startDate ? startDate : startDateWindowMin}
                        maxDate={''}
                        onChange={(e: any) => {
                          setEndDate(e);
                        }}
                        disabled={startDate === ''}
                      />
                    </div>
                    {endDateError && <div className='error-text'>Required field</div>}
                  </div>
                </div>
              </div>

              <FormElement.Radio
                id='page-link-preference'
                className={
                  userTypeError || priorityError || startDateError || endDateError
                    ? 'pt-5 mb-0 ml-0 label'
                    : 'pt-3 mb-0 ml-0 label'
                }
                label={'Where would you like the more info links to navigate?'}
                name='linkPref'
                elements={['Internal page', 'External page', 'No link']}
                value={preference}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPreference(e.target.value);
                }}
              />

              {preference === 'Internal page' && (
                <div className='dropdown-full'>
                  <ReactSelect
                    autoFocus={false}
                    blurInputOnSelect={true}
                    placeholder={'Select a page'}
                    value={internalLink}
                    styles={internalLinkError ? newMessageStylesError : newMessageStyles}
                    options={internalPageOptions}
                    onChange={handleInternalPageChange}
                  />
                  {internalLinkError && <div className='error-text'>Select a page</div>}
                </div>
              )}

              {preference === 'External page' && (
                <div className=''>
                  <FormElement.Textarea
                    id='externalPage'
                    placeholder={'Add external page URL'}
                    className='c-form__input no-resize'
                    label={''}
                    name='externalPage'
                    value={externalLink}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setExternalLink(e.target.value);
                      setInternalLink({ value: 'Select', label: 'Select a page' });
                    }}
                    rows={1}
                    maxLength={150}
                    required={false}
                    errorMessage={externalLinkError}
                  />
                </div>
              )}

              <div className='pt-1 mb-2'>
                <Checkbox
                  id={'select-preview'}
                  label={'Select checkbox to view preview of the message in message box.'}
                  onChange={handleSelectPreview}
                  checked={selectPreview}
                ></Checkbox>
              </div>

              <div className='pb-3'>
                <section className='dropzonecontainer'>
                  {!selectPreview && (
                    <div className='p-2' style={{ fontStyle: 'italic' }}>
                      Preview of the message
                    </div>
                  )}
                  {selectPreview && (
                    <div className='p-2'>
                      {message} &nbsp;
                      {(preference === 'Internal page' || preference === 'External page') && (
                        <a href={''} style={{ color: '#216EF2' }}>
                          More info
                        </a>
                      )}
                    </div>
                  )}
                </section>
              </div>

              <FormElement.Radio
                id='page-status'
                className='c-form__input'
                label={'Status'}
                name='page-status'
                elements={['Ready to publish', 'Draft']}
                value={status}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStatus(e.target.value);
                }}
              />
            </form>
          </Modal.Body>

          <div className='float-right'>
            <ButtonPrimary disabled={false} className='mt-3 upload-btn' title='Save' onClick={handleSaveClick} />

            <ButtonPrimary
              disabled={false}
              className='mt-3 ml-3 upload-btn'
              title='Cancel'
              onClick={event => {
                event.preventDefault();
                handleCancelClick();
              }}
            />
          </div>
        </NewMessageWrapper>
      </Modal>
    </>
  );
};

NewMessageModal.defaultProps = defaultProps;
