import styled from 'styled-components';
import IcomoonReact from 'icomoon-react';

import Modal from 'react-bootstrap/Modal';
import iconSet from '../../../assets/selection.json';
import { ButtonPrimary } from '../../../common/components';

type SubmissionFailedModalProps = {
  show: boolean;
  onHide: any;
};

const defaultProps = {
  show: true,
};

export const Wrapper = styled.div`
  .failure-icon {
    display: inline-block;
    padding: 25px;
    width: 80px;
    height: 80px;
    text-align: center;
    background: #ccc;
    border-radius: 50%;
    margin-bottom: 10px;
  }
`;

export const SubmissionFailedModal = (props: SubmissionFailedModalProps) => {

  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={props.show} backdrop='static' centered>
        <Wrapper>
          <Modal.Title><div className='text-center'>Submission failed</div></Modal.Title>

          <Modal.Body>
            <div className='text-center'>
              <div className='failure-icon'>
                <IcomoonReact iconSet={iconSet} color={'white'} size={30} icon='cross' />
              </div>
              <div className='pt-3'>
                <p>Unfortunately we couldn't process this request.</p>
                <p>Please try again later.</p>
              </div>
            </div>
          </Modal.Body>

          <div className='d-flex justify-content-end'>
            <ButtonPrimary
              className='m-0'
              title='OK'
              onClick={handleClose}
            />
          </div>
        </Wrapper>
      </Modal>
    </>
  );
};

SubmissionFailedModal.defaultProps = defaultProps;
