import { useFormContext, UseFormReturn, Controller } from 'react-hook-form';

import { ReactSelectStyled, Label, Error } from './FormSelect.styles';

type FormSelectProps = {
  name: string;
  params: any;
  options: any[];
  isSearchable?: boolean;
  placeholder?: string;
  label?: string;
};

export const ConnectForm = ({ children }: { children: Function }) => {
  const methods = useFormContext();

  return children({ ...methods });
};

export const FormSelect = ({ name, params, options, isSearchable, placeholder, label }: FormSelectProps) => {
  return (
    <ConnectForm>
      {({ control, formState: { errors } }: UseFormReturn<any>) => (
        <>
          <Controller
            name={name}
            control={control}
            rules={{ required: params.required }}
            render={({ field }) => (
              <>
                {label && <Label>{label}</Label>}
                <ReactSelectStyled
                  {...field}
                  options={options}
                  isSearchable={!!isSearchable}
                  hasError={!!errors[`${name}`]}
                  classNamePrefix={'FormSelect'}
                  placeholder={placeholder}
                />
              </>
            )}
          />

          {errors[`${name}`] && <Error>{'Required'}</Error>}
        </>
      )}
    </ConnectForm>
  );
};
