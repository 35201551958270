import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, ModalClose } from '../../../common/components';

type ErrorModalProps = {
  onHide: Function;
  show: boolean;
  title: string;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Error',
};

const ErrorModal = (props: ErrorModalProps) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>Sorry there was an error retrieving the information</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          route='/'
          title='Close'
          onClick={event => {
            event.preventDefault();
            props.onHide();
          }}
          className='btn-primary m-0'
        ></ButtonPrimary>
      </Modal.Footer>
    </>
  );
};

ErrorModal.defaultProps = defaultProps;

export default ErrorModal;
