export const getValueByPath = (obj: any, path: any) => {
  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');

  const a = path.split('.');

  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];

    if (k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }

  return obj;
};
