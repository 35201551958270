import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { StyledLink } from './ButtonTerinary.styles';
import * as variables from '../../../common/styles/variables';
import { iconsToUse } from '../../styles/themes';
import { Icon } from '../Icon/Icon';

type ButtonTerinaryProps = {
  title: string;
  route: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled: boolean;
  className?: string | undefined;
  tabIndex?: number;
  target?: string;
  icon?: string | null;
};

const defaultProps = {
  route: '',
  title: '',
  onClick: undefined,
  disabled: false,
  tabIndex: '',
  icon: null,
};

export const ButtonTerinary = (props: ButtonTerinaryProps) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledLink {...props} to={props.route} onClick={props.onClick} title={''}>
      {props.icon && (
        <Icon
          className={`icon ${props.disabled ? 'icon--disabled' : ''}`}
          size={variables.iconSizeExtraSmall}
          icon={(iconsToUse as any)[`${props.icon}`]}
          color={theme.icons.default}
        />
      )}
      <span>{props.title}</span>
    </StyledLink>
  );
};

ButtonTerinary.defaultProps = defaultProps;
