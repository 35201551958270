import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../common/components';
import { razzmatazzPink, hydroBlue } from '../../styles/colors';

type DownloadRequestedModalProps = {
  isOpusBrand?: boolean;
  show: boolean;
  onClose: () => void;
  title?: string;
  message?: string[];
};

const defaultProps = {
  show: false,
  title: 'File download request received',
  message: ['The file will start downloading shortly', 'You are free to navigate to other pages as required'],
};

export const DownloadRequestedModal = (props: DownloadRequestedModalProps) => {
  const onClose = (e: any) => {
    e.preventDefault();
    props.onClose();
  };

  return (
    <Modal
      animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
      className={'custom-modal'}
      show={props.show}
      centered={true}
    >
      <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
        <h3 style={props.isOpusBrand ? { color: razzmatazzPink } : { color: hydroBlue }}>{props.title}</h3>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.message?.map(message => {
            return <p style={{ textAlign: 'center' }}>{message}</p>;
          })}
        </div>
        <div className='d-flex justify-content-center mt-5'>
          <ButtonPrimary onClick={e => onClose(e)} title='OK' className='my-0' />
        </div>
      </Modal.Body>
    </Modal>
  );
};

DownloadRequestedModal.defaultProps = defaultProps;

export default DownloadRequestedModal;
