const Profile = ({ width, height, className, fillColor, style }: ProfileProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 28 28'
    fill='none'
    style={style}
    xmlns='http://www.w3.org/2000/svg'
  >
    <ellipse cx='16.3025' cy='11.5' rx='3.52713' ry='3.5' fill='{fillColor}' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.3566 22C23.3566 18.4562 20.1983 15.5833 16.3023 15.5833C12.4063 15.5833 9.24805 18.4562 9.24805 22H23.3566Z'
      fill='{fillColor}'
    />
  </svg>
);

export type ProfileProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

Profile.defaultProps = {
  width: 28,
  height: 28,
  fillColor: '#dde4e9',
};

export default Profile;
