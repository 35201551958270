// Modules
import { useState, useEffect, useContext } from 'react';
import { DateObject } from 'react-multi-date-picker';
import styled from 'styled-components';
import { toast } from 'react-toastify';

// API
import {
  accountoverview,
  accountPayments,
  renewableAccountPayments,
  yourgeneration,
  customerstartdate,
  totalbalanceallaccounts,
  electrictotalconsumptionbyesscustomerid,
  gastotalconsumptionbyesscustomerid,
  downloadreport,
  accountbalances,
  getTpiAccessId,
  getMeteringPointTypeForCustomers,
} from '../../common/api/homeApi';
import { fetchEssAccountsByCustomerId } from '../../common/api/accountApi';

// Components
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { InvoiceAndPayment } from './InvoiceAndPayment/InvoiceAndPayment';
import { SubmitMeterReading } from './SubmitMeterReading/SubmitMeterReading';
import { SubmitMeterReadingDrax } from './SubmitMeterReading/SubmitMeterReadingDrax';
import { MovePremises } from './MovePremises/MovePremises';
import { useAuthState } from '../../providers/authProvider';
import { AccountOverview } from './AccountOverview/AccountOverview';
import { DownloadBulkInvoices } from './DownloadBulkInvoices/DownloadBulkInvoices';
import { DashboardModals } from './DashboardModals/DashboardModals';
import { YourGeneration } from './YourGeneration/YourGeneration';
import { Usage } from './Usage/Usage';

// Context
import { HomeProvider } from './context/homeProvider';
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';

// Utils
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { EssAccountsTypes } from '../../types/account';

// Health
import { AccountHealth } from '../health/AccountHealth/AccountHealth';
import { MeterHealth } from '../health/MeterHealth/MeterHealth';
import { LoAHealth } from '../health/LoAHealth/LoAHealth';
import { isTpiUser } from '../../helpers/tpiUser';
import { SubmitMeterReadingTpi } from './SubmitMeterReading/SubmitMeterReadingTpi';
import { BrokerLandingSubmitLoa } from './BrokerLandingSubmitLoa/BrokerLandingSubmitLoa';
import { BrokerLandingSelectCustomers } from './BrokerLandingSelectCustomers/BrokerLandingSelectCustomers';
import { BrokerLandingSelected } from './BrokerLandingSelected/BrokerLandingSelected';
import { sortAccountsByDisplay } from '../../utils/sortFilters';
import { CUSTOMER_TYPES } from '../../consts';
import { temporary } from '../../helpers/axiosWrapper';
import { TPI_ACCESSID, TPI_CUSTOMERS } from '../../common/constants';
import { BulkMessages } from './BulkMessages/BulkMessages';
import { getCurrentBulkMessages } from '../../common/api/myMessagesApi';
import { getCustomerList, getHHandNHHforCustomers, getMeteringPointType, meteringType } from '../../helpers/customer';
import { TagManager } from '../utils/analytics/TagManager';
import { InsightHome } from './MarketInsightsHome/InsightHome/InsightHome';

const StyledHome = styled.div``;

const Home = () => {
  const authContext = useAuthState();

  // general customer data
  const { userDetail, setUserDetail, isSMEBroker, isSMECustomer, isCorpBroker, isCorpCustomer } =
    useContext<UserDetailContextProps>(UserDetailContext);
  const [customerStartDate, setCustomerStartDate] = useState<Date | string | number | DateObject>('');
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  // account overview
  const [accountOverview, setAccountOverview] = useState<Object>({
    id: null,
    customerIds: null,
    description: null,
    numberOfCustomers: null,
  });

  // usage
  const [selectedAccount, setSelectedAccount] = useState<Api.IInvoiceAccount>();
  const [accounts, setAccounts] = useState<Array<Api.IEssAccountData>>([]);
  const [electricity, setElectricity] = useState<string>('');
  const [gas, setGas] = useState<string>('');
  const [powerGenerated, setPowerGenerated] = useState<string>('');
  const [incomeGenerated, setIncomeGenerated] = useState<string>('');
  const [lastQuarter, setLastQuarter] = useState<string>('');
  const [totalBalance, setTotalBalance] = useState<Api.ITotalBalance>();

  // messages
  const [messageData, setMessageData] = useState<Array<Api.IAdminCreateMessageData>>();

  const getAccountOverview = async (essCustomerId: string) => {
    if (userDetail && userDetail.customerAccounts.length > 0) {
      setAccountOverview({
        id: essCustomerId,
        customerIds: userDetail.userProfile.customerIds,
        description: userDetail.userProfile.description,
        numberOfCustomers: userDetail.userProfile.numberOfCustomers,
      });
    }
  };

  const getUsageData = async () => {
    const essCustomerId = userDetail.essCustomerId;

    const electricTotalConsumptionResult: Common.IResult<string> = await electrictotalconsumptionbyesscustomerid(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );
    if (electricTotalConsumptionResult && electricTotalConsumptionResult.success) {
      setElectricity(electricTotalConsumptionResult.data);
    } else {
      const message = 'API error calling: elec totalconsumptionbyesscustomerid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }

    const gasTotalConsumptionResult: Common.IResult<string> = await gastotalconsumptionbyesscustomerid(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );
    if (gasTotalConsumptionResult && gasTotalConsumptionResult.success) {
      setGas(gasTotalConsumptionResult.data);
    } else {
      const message = 'API error calling: gas totalconsumptionbyesscustomerid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getPaymentData = async (essAccountId: string) => {
    const invoiceAccount: Common.IResult<Api.IInvoiceAccount> = await accountPayments(
      process.env.REACT_APP_API_URL,
      authContext,
      essAccountId
    );
    if (invoiceAccount && invoiceAccount.success) {
      if (invoiceAccount.data) {
        let account: Api.IInvoiceAccount = invoiceAccount.data;
        setSelectedAccount(account);
      }
    } else {
      const message = 'API error calling: accountpayments';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getRenewablePaymentData = async (essAccountId: string) => {
    const invoiceAccount: Common.IResult<Api.IInvoiceAccount> = await renewableAccountPayments(
      process.env.REACT_APP_API_URL,
      authContext,
      essAccountId
    );
    if (invoiceAccount && invoiceAccount.success) {
      if (invoiceAccount.data) {
        let account: Api.IInvoiceAccount = invoiceAccount.data;
        setSelectedAccount(account);
      }
    } else {
      const message = 'API error calling: renewablepayments';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getAccountData = async (essCustomerId: string) => {
    let accounts: Array<Api.IEssAccountData> = [];

    if (isDraxBrand) {
      accounts = userDetail.customerAccounts;
    } else {
      const invoiceAccount: Common.IResult<Array<Api.IEssAccountData>> = await fetchEssAccountsByCustomerId(
        process.env.REACT_APP_API_URL,
        authContext,
        essCustomerId
      );
      if (invoiceAccount && invoiceAccount.success) {
        accounts = invoiceAccount.data;
      }
    }

    if (accounts) {
      let invoiceAccounts: Array<Api.IEssAccountData> = [];
      accounts.map((account: any) => invoiceAccounts.push(account));
      setAccounts(invoiceAccounts);
    } else {
      const message = 'API error calling: getessaccountidbyesscustomerid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getTotalBalanceData = async (essCustomerId: string) => {
    const invoiceAccount: Common.IResult<Api.ITotalBalance> = await totalbalanceallaccounts(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );
    if (invoiceAccount && invoiceAccount.success) {
      if (invoiceAccount.data) {
        let totalBalance: Api.ITotalBalance = invoiceAccount.data;
        setTotalBalance(totalBalance);
      }
    } else {
      const message = 'API error calling: totalbalanceallaccounts';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getBalancesData = async (essCustomerId: string) => {
    let invoiceAccounts: Array<Api.IEssAccountData> = [];

    const customer: Api.ICustomer | undefined = userDetail.userProfile.customerIds.find(
      c => c.customerId === essCustomerId
    );
    if (!customer) return;

    const accounts: Array<Api.IAccountBalance> = await accountbalances(
      process.env.REACT_APP_API_URL,
      authContext,
      customer.description
    );
    if (accounts) {
      let total = 0.0;
      accounts.map((account: Api.IAccountBalance) => {
        const balance = account.balance;
        const balanceOverdue = account['balance-overdue'];
        const customerAccountReference = account['customer-account-reference'];
        const customerName = account['customer-name'];

        total += balance;

        let invoiceAccount: Api.IEssAccountData = {
          renewable: false,
          accountName: customerName,
          essAccountID: customerAccountReference,
          product: EssAccountsTypes.Electricity,
        };
        invoiceAccounts.push(invoiceAccount);
      });

      let totalBalance: Api.ITotalBalance = {
        essCustomerID: essCustomerId,
        totalBalance: total.toFixed(2),
      };
      setTotalBalance(totalBalance);
      setAccounts(invoiceAccounts);
    }
  };

  const getYourGenerationData = async () => {
    const essCustomerId = userDetail.essCustomerId;
    const generated: Common.IResult<Api.IYourGeneration> = await yourgeneration(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );
    if (generated && generated.success) {
      let generatedData = generated.data;
      setPowerGenerated(generatedData.powerGenerated);
      setIncomeGenerated(generatedData.incomeGenerated);
      setLastQuarter(generatedData.dateRange);
    } else {
      const message = 'API error calling: generation';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getCustomerDetails = async (essCustomerId: string) => {
    const customer: Common.IResult<Api.ICustomerStartDate> = await customerstartdate(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );
    if (customer?.success) {
      const customerData = customer.value.data;
      setCustomerStartDate(new Date(customerData.minDate));
    } else {
      const message = 'API error calling: customerdetails';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getMessageData = async () => {
    let references: Array<string> = [];

    if (isTpiUser()) {
      const claims = authContext.getClaims();
      references.push(claims.tpiReferenceId);
    } else {
      let accounts = userDetail.customerAccounts.map(x => x.essAccountID);
      references.push(...accounts);
    }

    const tempData: Common.IResult<Array<Api.IAdminCreateMessageData>> = await getCurrentBulkMessages(
      process.env.REACT_APP_API_URL,
      authContext,
      references
    );
    if (tempData.success) {
      const messageData = tempData.data;
      setMessageData(messageData);
    } else {
      const message = 'API error calling: currentbulkmessages';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const getData = async () => {
    const essCustomerId = userDetail.essCustomerId;

    if (isSMECustomer()) {
      getUsageData();
      getAccountData(essCustomerId);
      getTotalBalanceData(essCustomerId);
      if (userDetail.hasRenewable) {
        getYourGenerationData();
      }
    } else if (isCorpBroker()) {
      if (userDetail.viewAsCustomerToggle && userDetail.viewAsCustomerSelected) {
        getAccountOverview(essCustomerId);
      }
      if (isOpusBrand) {
        getCustomerDetails(essCustomerId);
      }
    } else if (isCorpCustomer()) {
      getAccountOverview(essCustomerId);
      if (isOpusBrand) {
        getCustomerDetails(essCustomerId);
      }
      if (isDraxBrand) {
        getBalancesData(essCustomerId);
        getMessageData();
      } else {
        getAccountData(essCustomerId);
        getTotalBalanceData(essCustomerId);
      }
    } else if (isSMEBroker()) {
      //
    }
  };

  const downloadReport = async (data: object) => {
    const downloadReportResult: Common.IResult<any> = await downloadreport(
      process.env.REACT_APP_API_URL,
      authContext,
      data
    );
    if (downloadReportResult && downloadReportResult.success) {
      // TODO
      // update this once we get the correct response object.
      // Useful for error handling, but we don't need to do anything with success
      // as that is handled in the axiosWrapper
    } else {
      const message = 'API error calling: accountbalancereport';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  // TODO - NOT SURE WHY WE NEEDED THIS - LEAVING IT JUST INCASE
  // useDidMountEffect(() => {
  //   getData();
  // }, [userDetail.essCustomerId, userDetail.customerType]);

  useEffect(() => {
    const isTpiCustomer = globalContext.globalDetail.showBrokerLandingPage;
    if (isTpiCustomer) {
      TagManager.pushData({
        event: 'Launch Landing page (Landing page)',
      });
    }
  }, []);

  useEffect(() => {
    // log for 35984
    console.log(
      'Home getData, ' +
        'userDetail.essCustomerId: ' +
        userDetail.essCustomerId +
        'userDetail.customerType: ' +
        userDetail.customerType
    );

    if (userDetail.customerType?.length === 0) return;

    getData();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.essCustomerId, userDetail.customerType, userDetail.selectedCustomers]);

  const handleAccountChange = (option: Common.IExpandedCustomerOption) => {
    if (option.hasRenewable) {
      getRenewablePaymentData(option.value);
    } else if (!option.hasRenewable) {
      getPaymentData(option.value);
    }
  };

  const corporateBrokerAsCustomerDashboard = () => {
    return (
      <>
        <div className='col-md-12 col-xl-6 widget'>
          <AccountOverview accountOverview={accountOverview} />
        </div>
        {isDraxBrand && (
          <div className='col-md-12 col-xl-6 widget'>
            <MovePremises />
          </div>
        )}
        {isOpusBrand && (
          <div className='col-md-12 col-xl-6 widget'>
            <DownloadBulkInvoices minDate={customerStartDate} />
          </div>
        )}
        <div className='col-md-12 col-xl-6 widget'>
          <SubmitMeterReading />
        </div>
        <div className='col-md-12 col-xl-6 widget'>
          <InvoiceAndPayment
            handleAccountChange={handleAccountChange}
            selectedAccount={selectedAccount}
            accounts={accounts}
            hasRenewableAccount={userDetail.hasRenewable}
            downloadReport={downloadReport}
          />
        </div>
        {isOpusBrand && (
          <div className='col-12 widget'>
            <MovePremises />
          </div>
        )}
      </>
    );
  };

  const corporateBrokerDashboard = () => {
    return (
      <>
        <div className='col-12 widget'>
          <InvoiceAndPayment
            handleAccountChange={handleAccountChange}
            selectedAccount={selectedAccount}
            accounts={accounts}
            hasRenewableAccount={userDetail.hasRenewable}
            downloadReport={downloadReport}
          />
        </div>
        <div className={`col-md-12 widget ${globalContext.globalDetail.meterWidgetExpanded ? '' : 'col-xl-6'}`}>
          <SubmitMeterReading />
        </div>
        {isOpusBrand && !globalContext.globalDetail.meterWidgetExpanded && (
          <div className='col-md-12 col-xl-6 widget'>
            <DownloadBulkInvoices minDate={customerStartDate} />
          </div>
        )}
        <div className='col-12 widget'>
          <MovePremises />
        </div>
      </>
    );
  };

  const showMovingPremisesFullWidth =
    globalContext.globalDetail.meterWidgetExpanded || userDetail.customersMeteringPointTypes?.hhMeteringPointTypeOnly;
  const showMovingPremisesHalfWidth =
    !globalContext.globalDetail.meterWidgetExpanded && !userDetail.customersMeteringPointTypes?.hhMeteringPointTypeOnly;

  const corporateDashboard = () => {
    return (
      <>
        {messageData && messageData?.length > 0 && (
          <div className='col-md-12 col-xl-12 widgetNoFlex'>
            <BulkMessages messageData={messageData} TpiUser={false}></BulkMessages>
          </div>
        )}

        <InsightHome customerUser={true} tpiUser={false}></InsightHome>
      </>
    );
  };

  const handleCustomerClick = (option: Common.IExpandedCustomerOption) => {
    const selectedCustomers = globalContext.globalDetail.selectedCustomers;
    selectedCustomers.push(option);

    let availableCustomers = globalContext.globalDetail.availableCustomers;
    const index = availableCustomers.findIndex(c => c.value === option.value);
    if (index >= 0) {
      // Remove from Selected
      availableCustomers.splice(index, 1);
    }

    const selectedCustomersSorted: any = sortAccountsByDisplay(selectedCustomers);
    const availableCustomersSorted: any = sortAccountsByDisplay(availableCustomers);

    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      selectedCustomers: selectedCustomersSorted,
      availableCustomers: availableCustomersSorted,
    });
  };

  const handleTrash = (option: Common.IExpandedCustomerOption) => {
    let selectedCustomers = globalContext.globalDetail.selectedCustomers;
    const index = selectedCustomers.findIndex(c => c.value === option.value);
    if (index >= 0) {
      // Remove from Selected
      selectedCustomers.splice(index, 1);

      // Add back to Available
      let availableCustomers = globalContext.globalDetail.availableCustomers;
      availableCustomers.push(option);

      const selectedCustomersSorted: any = sortAccountsByDisplay(selectedCustomers);
      const availableCustomersSorted: any = sortAccountsByDisplay(availableCustomers);

      globalContext.setGlobalDetail({
        ...globalContext.globalDetail,
        selectedCustomers: selectedCustomersSorted,
        availableCustomers: availableCustomersSorted,
      });
    }
  };

  const handleViewCustomers = async (customers: Array<Common.IExpandedCustomerOption>) => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      showBrokerLandingPage: false,
    });

    const customersList = getCustomerList(customers);

    temporary.accessId = '';
    const tpiAccessId = await getTpiAccessId(process.env.REACT_APP_API_URL, authContext, customersList);

    let tpiAccess: any = {};

    if (tpiAccessId) {
      temporary.accessId = tpiAccessId['access-id'];

      localStorage.setItem(TPI_CUSTOMERS, JSON.stringify(customers));

      localStorage.setItem(TPI_ACCESSID, temporary.accessId);

      tpiAccess = {
        accessId: tpiAccessId['access-id'],
        username: tpiAccessId.username,
        userId: tpiAccessId.userId,
        emailAddress: tpiAccessId.emailAddress,
      };
    } else {
      toast.error('CANNOT GET ACCESS ID', { position: 'bottom-right' });
      return;
    }

    // using first customer selected
    const customer = customers[0];

    let customersMeteringPointTypes: Common.ICustomersMeteringPointTypes | null = null;

    // Get Metering Point Type for customers for TPI user
    const customersWithMeteringPointTypeResult = await getMeteringPointTypeForCustomers(
      process.env.REACT_APP_API_URL,
      authContext,
      customersList
    );

    let customersWithMeteringPointType: Array<Api.ICustomerMeteringPointType> = [];
    if (customersWithMeteringPointTypeResult) {
      customersWithMeteringPointType = customersWithMeteringPointTypeResult.data;
      customersMeteringPointTypes = getHHandNHHforCustomers(customersWithMeteringPointType);
    }

    let customerAccounts: Api.IEssAccountData[] = [];
    customers.map((customer: Common.IExpandedCustomerOption) => {
      let meteringPointType: meteringType;
      if (customersWithMeteringPointType) {
        meteringPointType = getMeteringPointType(customersWithMeteringPointType, customer.value);
      }

      let customerAccount: Api.IEssAccountData = {
        essAccountID: customer.value,
        accountName: customer.display,
        product: 'Electricity',
        renewable: false,
        meteringPointType,
      };
      customerAccounts.push(customerAccount);
    });

    let customerIds: Array<Api.ICustomer> = [];
    customers.map((customer: Common.IExpandedCustomerOption) => {
      const customerId: Api.ICustomer = {
        customerId: customer.value,
        description: customer.display,
      };
      customerIds.push(customerId);
    });

    const userProfile: Api.IUserProfile = {
      id: null,
      accountManager: 'Portal Support',
      email: 'myenergysupply@drax.com',
      phone: '01473 707797',
      description: '',
      numberOfCustomers: customerAccounts.length,
      customerIds,
    };

    setUserDetail({
      ...userDetail,
      customerType: CUSTOMER_TYPES.CORPORATE,
      essCustomerId: customer.value,
      hasRenewable: false,
      hasElectricity: true,
      hasGas: false,
      viewAsCustomerSelected: true,
      viewAsCustomerToggle: true,
      selectedCustomers: customers,
      selectedCustomerId: customer.value,
      loggedInDetails: {
        essCustomerId: customer.value,
        hasRenewable: true,
        hasElectricity: false,
        hasGas: false,
      },
      customerAccounts,
      customersMeteringPointTypes,
      forename: tpiAccess.username,
      lastName: tpiAccess.username,
      userId: tpiAccess.userId,
      emailAddress: tpiAccess.emailAddress,
      // isPrimaryContact
      // isAccountSignatory
      userProfile,
    });
  };

  const tpiCustomerDashboard = () => {
    return (
      <>
        <div className='col-md-12 col-xl-6'>
          <div className='widgetNoFlex'>
            <BrokerLandingSelectCustomers
              customers={globalContext.globalDetail.availableCustomers}
              selectedCustomers={globalContext.globalDetail.selectedCustomers}
              handleCustomerClick={handleCustomerClick}
            />
          </div>

          {/* desktop only */}
          <div className='widgetNoFlex d-none d-xl-block'>
            <BrokerLandingSubmitLoa></BrokerLandingSubmitLoa>
          </div>
        </div>

        <div className='col-md-12 col-xl-6'>
          <div className='widget'>
            <BrokerLandingSelected
              customers={globalContext.globalDetail.availableCustomers}
              selectedCustomers={globalContext.globalDetail.selectedCustomers}
              handleViewCustomers={handleViewCustomers}
              handleTrash={handleTrash}
            />
          </div>

          {/* smaller devices only */}
          <div className='widgetNoFlex d-xl-none'>
            <BrokerLandingSubmitLoa></BrokerLandingSubmitLoa>
          </div>
        </div>
      </>
    );
  };

  const tpiUserDashboard = () => {
    return (
      <>
        {messageData && messageData?.length > 0 && (
          <div className='col-md-12 col-xl-12 widgetNoFlex'>
            <BulkMessages messageData={messageData} TpiUser={true}></BulkMessages>
          </div>
        )}

        <InsightHome customerUser={false} tpiUser={true}></InsightHome>
      </>
    );
  };

  const smeCustomerDashboard = () => {
    return (
      <>
        <div className='col-md-12 col-xl-4 widget'>
          <Usage electricity={electricity} gas={gas} />
        </div>
        <div className='col-md-12 col-xl-8 widget'>
          <InvoiceAndPayment
            handleAccountChange={handleAccountChange}
            totalBalance={totalBalance}
            selectedAccount={selectedAccount}
            accounts={accounts}
            hasRenewableAccount={userDetail.hasRenewable}
            downloadReport={downloadReport}
          />
        </div>
        <div
          className={`widget ${
            !userDetail.hasRenewable || globalContext.globalDetail.meterWidgetExpanded ? 'col-12' : 'col-md-12 col-xl-6'
          }`}
        >
          <SubmitMeterReading />
        </div>
        {userDetail.hasRenewable && !globalContext.globalDetail.meterWidgetExpanded && (
          <div className='col-md-12 col-xl-6 widget'>
            <YourGeneration
              incomeGenerated={incomeGenerated}
              lastQuarter={lastQuarter}
              electricityGenerated={powerGenerated}
            />
          </div>
        )}
        <div className='col-12 widget'>
          <MovePremises />
        </div>
      </>
    );
  };

  const smeBrokerDashboard = () => {
    return (
      <>
        <div className='col-12 col-xl-6 widget'>
          <div>SME Broker is not yet implemented</div>
        </div>
      </>
    );
  };

  const dashboard = () => {
    const isTpiCustomer = globalContext.globalDetail.showBrokerLandingPage;

    if (isTpiCustomer) {
      return tpiCustomerDashboard();
    }

    if (isSMECustomer()) {
      return smeCustomerDashboard();
    } else if (isCorpBroker()) {
      if (userDetail.viewAsCustomerToggle && userDetail.viewAsCustomerSelected) {
        return corporateBrokerAsCustomerDashboard();
      } else {
        return corporateBrokerDashboard();
      }
    } else if (isCorpCustomer()) {
      if (isTpiUser()) return tpiUserDashboard();
      else return corporateDashboard();
    } else if (isSMEBroker()) {
      return smeBrokerDashboard();
    }
  };

  return (
    <StyledHome>
      <h1 className='text-white'>Welcome to My Drax Energy</h1>
      <div className='row m-0'>
        <HomeProvider value={{ selectedCard: '', active: false }}>
          {dashboard()}
          <DashboardModals />
        </HomeProvider>
      </div>
    </StyledHome>
  );
};
export default Home;
