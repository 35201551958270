import styled from 'styled-components';
import * as variables from '../../styles/variables';
import * as colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import { respondTo } from '../../styles/mixins';

export const TypeAheadDropDownWrapper = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  color: ${colors.darkBlue};
  margin-right: 20px;
  position: relative;

  .chevron {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
  }

  *,
  *:after,
  *:before {
    ${mixins.borderBox};
    padding: 0;
    margin: 0;
  }

  ::selection {
    background: transparent;
  }

  ::-moz-selection {
    background: transparent;
  }

  input {
    text-overflow: ellipsis;
    width: 90%;
    border: none;
    color: ${colors.darkBlue};
    padding-left: 15px;

    :focus {
      outline: none;
    }
  }

  .typeAheadDropDown {
    position: relative;
    background-color: ${colors.white};
    padding: 10px 0;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    cursor: pointer;

    ul {
      ${mixins.scrollbars};
      position: absolute;
      background-color: ${colors.white};
      top: 45px;
      max-height: 288px;
      overflow-y: auto;
      z-index: ${variables.zIndexHigh};
      width: 100%;

      ${respondTo.tablet`
          background-color: ${colors.lightGrey};
        `};
    }

    li {
      ${mixins.transition};
      text-decoration: none;
      border-bottom: 1px solid ${colors.inputGrey};
      line-height: 2;
      padding: 4px 15px !important;

      &:hover {
        ${mixins.transition};
        background: ${colors.lightGrey};
        color: ${({ theme }) => theme.text.secondary};

        ${respondTo.tablet`
                background-color: ${colors.white};
              `};
      }
    }

    &.disabled {
      background-color: ${colors.lightGreyAccent};

      ${respondTo.tablet`
            background-color: ${colors.lightGrey};
          `};

      input {
        background-color: ${colors.lightGreyAccent};
        color: transparent;

        ${respondTo.tablet`
              background-color: ${colors.lightGrey};
            `};
      }
    }
  }

  .selecteditem {
    max-width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      line-height: 1;
      padding-left: 5px;
    }
  }
`;
