const Coins = ({ width, height, className, fillColor }: CoinsProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill={'none'}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 4.99988V8.99988C1 10.6569 3.68649 11.9999 6.99998 11.9999C10.3135 11.9999 13 10.6569 13 8.99988V4.99988'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M1 9V13C1 14.657 3.68649 16 6.99998 16C10.3135 16 13 14.657 13 13V9'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M1 13.0001V17.0001C1 18.6571 3.68649 20.0001 6.99998 20.0001'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M1 17V21C1 22.657 3.68649 24 6.99998 24'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M6.99998 8C10.3137 8 13 6.65685 13 5C13 3.34315 10.3137 2 6.99998 2C3.68628 2 1 3.34315 1 5C1 6.65685 3.68628 8 6.99998 8Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M19 9V13C19 14.657 21.6865 16 25 16C28.3135 16 31 14.657 31 13V9'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M25.0002 20.0001C28.3137 20.0001 31.0002 18.6571 31.0002 17.0001V13.0001'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M25.0002 24C28.3137 24 31.0002 22.657 31.0002 21V17'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M25 12.0001C28.3137 12.0001 31 10.657 31 9.00012C31 7.34327 28.3137 6.00012 25 6.00012C21.6863 6.00012 19 7.34327 19 9.00012C19 10.657 21.6863 12.0001 25 12.0001Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M9.99976 18.9999V22.9999C9.99976 24.6569 12.6862 25.9999 15.9997 25.9999C19.3132 25.9999 21.9997 24.6569 21.9997 22.9999V18.9999'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M9.99976 23V27C9.99976 28.657 12.6862 30 15.9997 30C19.3132 30 21.9997 28.657 21.9997 27V23'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M15.9997 22C19.3134 22 21.9997 20.6569 21.9997 19C21.9997 17.3431 19.3134 16 15.9997 16C12.686 16 9.99976 17.3431 9.99976 19C9.99976 20.6569 12.686 22 15.9997 22Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type CoinsProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Coins.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2A3745',
};

export default Coins;
