import React, { useState } from 'react';

import { FilterWrapper } from './Filter.styles';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../assets/selection.json';

type FilterProps = {
  options: Array<Common.IOption>;
  filterTitle?: string;
  alignRight?: boolean;
  handleClick: Function;
  multiSelect?: boolean;
  className?: string;
  selectedItems: Array<any>;
};

const defaultProps = {
  options: [],
  filterTitle: 'Category',
  alignRight: false,
  handleClick: undefined,
  multiSelect: false,
  className: '',
  selectedItems: [],
};

export const Filter = (props: FilterProps) => {
  const [active, setActive] = useState<boolean | null>(null);

  const handleToggle = () => {
    setActive(!active);
  };

  const handleBlur = () => {
    setActive(false);
  };

  const isInArray = (value: string) => {
    const index = props.selectedItems.findIndex(item => {
      return item.value === value;
    });
    return index >= 0;
  };

  const handleSelectItem = (event: any) => {
    const itemInArray = props.selectedItems.findIndex(item => {
      return item.value === event.target.dataset.value;
    });
    if (itemInArray >= 0) {
      // Remove if already selected
      props.selectedItems.splice(itemInArray, 1);
    } else {
      if (!props.multiSelect) {
        // Clear all
        props.selectedItems.splice(0, props.selectedItems.length);
      }
      // Add newly selected
      props.selectedItems.push({ value: event.target.dataset.value, display: event.target.innerText }); // TODO: refactoring is required!
    }

    if (!props.multiSelect || !props.selectedItems.length) {
      setActive(false);
    }

    props.handleClick(props.selectedItems);
  };

  return (
    <FilterWrapper {...props} onBlur={handleBlur} tabIndex={1}>
      <p className={`selector m-0 position-relative ${props.alignRight ? 'right' : ''}`} onClick={handleToggle}>
        <span className={`${props.selectedItems.length > 0 ? 'active' : ''}`}>{props.filterTitle}</span>
      </p>
      <ul className={`filter col ${active ? 'open' : ''} ${props.alignRight ? 'right' : ''}`}>
        <li>
          <ul className='col p-0'>
            {props.options.map((item, index) => (
              <li
                key={index}
                data-value={item.value}
                onClick={handleSelectItem}
                className={`${isInArray(item.value) ? 'selected' : ''}`}
              >
                {item.display}
              </li>
            ))}
          </ul>
        </li>
      </ul>
      <IcomoonReact className='itemicon chevron' iconSet={iconSet} size={15} icon='chevrons-expand-vertical' />
    </FilterWrapper>
  );
};

Filter.defaultProps = defaultProps;
