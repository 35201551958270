import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary } from '../../../../common/components';

type TpiDataRequestInProgressModalProps = {
  show: boolean;
  onHide: Function;
};

const defaultProps = {
  show: false,
};

export const Wrapper = styled.div``;

export const TpiDataRequestInProgressModal = (props: TpiDataRequestInProgressModalProps) => {
  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <Modal animation={false} className={'custom-modal'} show={props.show} backdrop='static' centered>
      <Wrapper>
        <Modal.Title>
          <div className='text-center'>Broker/TPI data request</div>
        </Modal.Title>

        <Modal.Body>
          <div className='text-center'>
            <div className='pt-3'>
              <p>There's an active request associated with this account.</p>
              <p>You'll be able to raise another request when you've received the data you previously requested.</p>
            </div>
          </div>
        </Modal.Body>

        <div className='d-flex justify-content-end'>
          <ButtonPrimary className='m-0' title='OK' onClick={handleClose} />
        </div>
      </Wrapper>
    </Modal>
  );
};

TpiDataRequestInProgressModal.defaultProps = defaultProps;
