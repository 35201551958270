// Modules
import React from 'react';
import moment from 'moment';
import { DateObject, Calendar } from 'react-multi-date-picker';

// Styles
import { DownloadFileFormatContainer } from './DownloadFileModal.styles';

// Components
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';
import { Modal } from 'react-bootstrap';
import { XModalClose } from '../XModalClose';
import { trueblack } from '../../styles/colors';

// Types
export type SelectResult = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  fileType: 'xml' | 'csv';
};
export type OnFileTypeSelectFunction = (result: SelectResult) => void;
type DownloadFileModalProps = {
  onFileTypeSelect: OnFileTypeSelectFunction;
  hideModal: () => void;
  show: boolean;
};

export function DownloadFileModal(props: DownloadFileModalProps) {
  // Props
  const { onFileTypeSelect, show, hideModal } = props;

  // State
  const [isShowingMonthPicker, setIsShowingMonthPicker] = React.useState(true);
  const [monthPickerValue, setMonthPickerValue] = React.useState<DateObject | DateObject[]>();

  const setPickerValues = (visibility: boolean, value: DateObject | DateObject[] | undefined) => {
    setIsShowingMonthPicker(visibility);
    setMonthPickerValue(value);
  };

  // Handlers
  const handleHideModal = (visibility: boolean) => {
    hideModal();
    setTimeout(() => {
      setPickerValues(visibility, undefined);
    }, 300);
  };

  const handleClick = (fileType: 'xml' | 'csv') => {
    const result: SelectResult = {
      startDate: null,
      endDate: null,
      fileType,
    };

    try {
      if (monthPickerValue instanceof DateObject) {
        setPickerValues(false, undefined);
        result.endDate = moment(monthPickerValue.toDate()).startOf('month');
        result.startDate = moment(result.endDate).endOf('month');

        if (typeof onFileTypeSelect === 'function') {
          onFileTypeSelect(result);
        }
      }
    } catch (error) {
      console.error(`Failed to handle date change`, error);
    } finally {
      handleHideModal(true);
    }
  };

  const handleDateChange = (date: DateObject) => {
    setPickerValues(false, date);
  };

  return (
    <Modal
      className='d-flex justify-content-center align-items-center modal-full-width'
      centered
      onHide={() => handleHideModal(true)}
      show={show}
    >
      <Modal.Header>
        <XModalClose close={() => handleHideModal(true)} />
      </Modal.Header>
      <Modal.Body>
        {isShowingMonthPicker ? (
          <Calendar
            minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
            maxDate={new Date()}
            onlyMonthPicker
            value={monthPickerValue}
            onChange={handleDateChange}
          />
        ) : (
          <DownloadFileFormatContainer>
            <p className='select__text'>Select download format</p>
            <div className='d-flex justify-content-around align-items-center'>
              <ButtonPrimary route='#' title='XML' onClick={() => handleClick('xml')} borderRadiusType='squared' />
              <ButtonPrimary route='#' title='CSV' onClick={() => handleClick('csv')} borderRadiusType='squared' />
            </div>
          </DownloadFileFormatContainer>
        )}
      </Modal.Body>
    </Modal>
  );
}
