import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthState } from '../../providers/authProvider';
import TimeOut from '../timeout/modal/timeOut/TimeOut';

export const Expiring = () => {
  const authContext = useAuthState();
  const history = useHistory();
  const [show, setShow] = useState<boolean>(true);

  const message = "Click below to stay signed in. Otherwise we'll end your session to keep your account secure.";
  const countdown = 30;

  const onTimeout = () => {
    // Redirect should be done automatically by oidc-client
  };

  const onKeepAlive = () => {
    if (authContext) {
      authContext.refreshToken();
    }

    if (localStorage.getItem('lastVisitedPath') === '/signout') {
      history.push('/home');
    } else {
      history.push(localStorage.getItem('lastVisitedPath') || '/');
    }

    setShow(false);
  };

  const onHide = () => {
    setShow(false);
  };

  return (
    <div className='inner'>
      <div className='row'>
        <div className='col-12 no_stuff'>
          <TimeOut
            message={message}
            show={show}
            countdown={countdown}
            onTimeout={onTimeout}
            onKeepAlive={onKeepAlive}
            onHide={onHide}
          />
        </div>
      </div>
    </div>
  );
};
