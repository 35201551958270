// Modules
import React from 'react';

// Components
import { RowInputItem } from '../RowInputItem/RowInputItem';

// Utils
import { isValidNumber } from '../../../utils';

// Styles
import { SubmitRegisterItemDraxWrapper } from './SubmitRegisterItemDrax.styles';
import { DateObject } from 'react-multi-date-picker';
import { CustomDatePicker } from '../CustomDatePicker/CustomDatePicker';
import { DATE_FORMAT, REVERSED_DATE_FORMAT_HYPHEN } from '../../constants';

type SubmitRegisterItemDraxProps = {
  index: number;
  value: string;
  selectedDate: string;
  lastRead: number;
  containerClassName?: string;
  description: string;
  lastReadDate: string;
  isDescriptionHidden?: boolean;
  disabled?: boolean;
  isSubmitted?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

SubmitRegisterItemDrax.defaultProps = {
  isDescriptionHidden: false,
  disabled: false,
};

export function SubmitRegisterItemDrax(props: SubmitRegisterItemDraxProps) {
  // Props
  const {
    value,
    lastRead,
    description,
    lastReadDate,
    containerClassName,
    isDescriptionHidden,
    disabled,
    isSubmitted,
    onBlur,
    onFocus,
    onChange,
  } = props;

  // Refs
  const inputWrapperRef = React.useRef<any>(null);

  // State
  const [isValidValue, setIsValidValue] = React.useState(false);

  // Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  const validateValue = (currentValue: string | number) => {
    const isValid = parseFloat(`${currentValue}`) >= lastRead;

    if (isValid !== isValidValue) {
      setIsValidValue(isValid);
    }
    return isValid;
  };

  let wrapperClassName = 'd-flex flex-column';

  if (typeof containerClassName === 'string') wrapperClassName += ` ${containerClassName}`;

  return (
    <SubmitRegisterItemDraxWrapper className={wrapperClassName}>
      <div className={`row ${props.index !== 0 && 'meterReadingRow'}`}>
        <div className='col'>
          <p className='mb-0'>Register ID: {description}</p>
          <p className='mb-0'>
            Last provided read: {(typeof lastRead === 'number' && lastRead) || 'No previous read available'}
          </p>
        </div>
        <div className='col'>
          <div ref={inputWrapperRef} className='px-0 col-12 col-xl-6 meter__reading__input'>
            <RowInputItem
              style={{ height: '54px', width: '90px' }}
              value={value}
              readOnly={disabled}
              isSubmitted={isSubmitted}
              onFocus={handleOnFocus}
              onHandleBlur={handleOnBlur}
              onChange={handleInputChange}
              validateValue={validateValue}
              onChangeValidation={e => isValidNumber(e.target.value)}
              placeholder='0000.00'
              htmlInputProps={{
                inputMode: 'numeric',
                required: true,
              }}
            />
          </div>
        </div>
      </div>
    </SubmitRegisterItemDraxWrapper>
  );
}
