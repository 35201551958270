import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading, NoResults, Table } from '../../common/components';
import { StatusBadge } from '../../common/components/StatusBadge/StatusBadge';
import { breakpoints } from '../../common/styles/variables';
import { formatUtcDate } from '../utils/date/date';
import { TpiUserDetailsGridWrapper } from './TpiUserActionsPanel.styles';

type TpiUserDetailsGridProps = {
  // selectedType: string;
  tpiDetails: Api.ITpiDetail;
  gridData: Array<Api.ITpiUser>;
  checkedItems: Array<number>;
  selectAll: boolean;
  selectedRow: number | null;
  handleSortChange: Function;
  handlePagingChange: Function;
  handleStatusClick: Function;
  handleRowChecked: Function;
  handleRowSelected: Function;
  handleSelectAll: Function;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
};

const defaultProps = {
  tpiDetails: {},
  gridData: [],
  selectedRow: null,
  checkedItems: [],
  selectAll: false,
  hasMoreData: false,
  currentSortField: 'Status',
  currentSortOrder: 'ASC',
};

export const TpiUserDetailsGrid = (props: TpiUserDetailsGridProps) => {
  const [selectedRow, setSelectedRow] = useState<number | null>(props.selectedRow);
  const [checkedItems, setCheckedItems] = useState<Array<number>>(props.checkedItems);

  const scrollableTable = useRef<any>();

  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    setSelectedRow(props.selectedRow);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRow]);

  useEffect(() => {
    setCheckedItems(props.checkedItems);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkedItems]);

  const headers: Array<Common.HeaderItem> = [
    {
      title: 'Status',
      dataValue: 'status',
      enableSort: false,
      minWidth: 50,
      responsive: {
        [breakpoints.mobile]: 140,
      },
    },
    {
      title: 'TPI',
      dataValue: 'description',
      enableSort: false,
      className: 'd-none d-md-table-cell',
      minWidth: 100,
    },
    {
      title: 'Email',
      dataValue: 'email',
      enableSort: false,
      className: 'd-none d-md-table-cell',
      minWidth: 100,
    },
    {
      title: 'First Name',
      dataValue: 'firstName',
      enableSort: false,
      className: 'd-none d-md-table-cell',
      minWidth: 80,
    },
    {
      title: 'Last Name',
      dataValue: 'lastName',
      sortField: 'LastName',
      minWidth: 80,
      width: 80,
    },
    {
      title: 'Date Updated',
      dataValue: 'updatedDate',
      enableSort: false,
      minWidth: 80,
      width: 80,
    },
  ];

  // Handlers
  const handlePagingChange = () => {
    props.handlePagingChange();
  };

  const handleStatusClick = (item: any) => {
    props.handleStatusClick(item);
  };

  const clearSelection = () => {
    setSelectedRow(null);
    setCheckedItems([]);
  };

  const onClickSort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearSelection();
    const typeId = e.currentTarget.dataset.value || '';
    let details = sortDetails;
    if (details.field === typeId) {
      if (details.order === 'ASC') details.order = 'DESC';
      else details.order = 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }
    props.handleSortChange(details);
  };

  const handleSelectAll = () => {
    setSelectedRow(null);

    const select = !props.selectAll;
    if (select) {
      const items: Array<number> = props.gridData.map((item: Api.ITpiUser, index: number) => index);
      setCheckedItems(items);
      props.handleSelectAll(items);
    } else {
      // unselect
      setCheckedItems([]);
      props.handleSelectAll([]);
    }
  };

  const actionStyles = {
    width: '150px',
    opacity: '1',
  };

  const handleCheckRow = (index: number) => {
    setSelectedRow(null);

    const items = checkedItems.slice(0, checkedItems.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    if (items.length === props.gridData.length) {
      handleSelectAll();
    } else if (props.selectAll) {
      props.handleSelectAll([]);
    }

    setCheckedItems(items);
    props.handleRowSelected(null);

    props.handleRowChecked(index, items);
  };

  const handleSelectRow = (event: any, row: number | null) => {
    if (row != null) {
      if (checkedItems.length === 0) {
        setSelectedRow(row);
        props.handleRowSelected(row);
      } else {
        setSelectedRow(null);
      }
    }
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const actions = (row: any, index: number) => <></>;

  const renderStatus = (value: string, row: any) => {
    return (
      <td className='d-md-table-cell pb-0'>
        <StatusBadge
          className={''}
          title={value}
          borderRadiusType={'squared'}
          enabledStatus={value === 'Enabled'}
          disabledStatus={value === 'Disabled'}
          lockedStatus={value === 'Locked'}
          onClick={() => handleStatusClick(row)}
        />
      </td>
    );
  };

  const renderUpdatedDate = (value: any) => <td>{formatUtcDate(value)}</td>;

  return (
    <div>
      {!props.isLoading && props.gridData.length === 0 && <NoResults />}

      <TpiUserDetailsGridWrapper>
        {props.gridData.length > 0 && (
          <>
            <InfiniteScroll
              dataLength={props.gridData.length}
              next={handlePagingChange}
              hasMore={props.hasMoreData}
              loader={''}
              scrollableTarget='scrollableDiv'
              scrollThreshold={0.9}
            >
              <div
                id='scrollableDiv'
                ref={scrollableTable}
                className='table-responsive table-fixed fixed-column columns-1'
              >
                <Table
                  isSelectable={props.tpiDetails.status === 'Enabled'}
                  headers={headers}
                  actions={actions}
                  rows={props.gridData}
                  isChecked={isChecked}
                  sortDetails={sortDetails}
                  onClickSort={onClickSort}
                  actionStyles={actionStyles}
                  isAllSelected={props.selectAll}
                  selectedRowIndex={selectedRow}
                  handleSelectAll={handleSelectAll}
                  handleCheckRow={handleCheckRow}
                  handleSelectRow={handleSelectRow}
                  className='list table plain text-left'
                  renderstatus={renderStatus}
                  renderupdatedDate={renderUpdatedDate}
                />
              </div>
            </InfiniteScroll>
          </>
        )}
      </TpiUserDetailsGridWrapper>

      {props.isLoading && <Loading overlay nowait />}
    </div>
  );
};

TpiUserDetailsGrid.defaultProps = defaultProps;

export default TpiUserDetailsGrid;
