import styled from 'styled-components';

import * as variables from '../../../../common/styles/variables';

export const OurInsightWrapper = styled.div`
  p {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .insight-more {
    display: flex;
    flex-direction: row;
    text-align: center;

    .insight-more-button {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin: ${variables.spacingSmall};
      }
    }
  }

  .more-button {
    width: 200px !important;
  }
`;
