export const groupRegisters = (registers: Array<Api.IMeterRead>) => {
  const groupedRegisters: { [key: string]: Array<Api.IMeterRead> } = {};

  registers?.map(reg => {
    const regId = reg['register-id'];

    if (Object.keys(groupedRegisters).includes(regId)) {
      groupedRegisters[regId].push(reg);
    } else {
      groupedRegisters[regId] = [reg];
    }
    return groupedRegisters;
  });
  return groupedRegisters;
};
