import styled from 'styled-components';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';
import * as colors from '../../../common/styles/colors';

export const AccountOverviewText = {
  header: 'Account overview',
  headerBtn: 'Account overview',
  ViewMySitesBtn: 'View my sites',
};

export const AccountOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .accountOverviewOverviewMobile {
    ${mixins.widgetMobile};
  }

  .accountOverviewOverview {
    ${mixins.widgetDesktop};
  }

  .icon-user {
    ${mixins.flipHorizontal};
    position: relative;
    top: -${variables.spacingSmall};
  }

  h6 {
    line-height: inherit;
  }

  p {
    margin: 0;

    &.primary {
      margin-bottom: 0.5em;
    }
  }

  .accounts__list {
    max-height: calc(25px * 5); /* Maximum 5 visible rows */
    overflow-y: auto;
    overflow-x: hidden;
    line-height: 19.2px;
    color: ${colors.hydroBlue};
    font-family: ${variables.fontRegular};
    ${mixins.scrollbars};

    & > div {
      margin-top: 5px;
    }
  }
`;
