import { useState } from 'react';

export const useSupportFormValidation = () => {
  const [phoneError, setPhoneError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const validatePhoneInput = (phone: string) => {
    if (!phone.length) {
      setPhoneError('Required field');
    } else if (!/^(?:\W*\d){11}\W*$/.test(phone)) {
      setPhoneError('Invalid format');
    } else {
      setPhoneError('');
    }
  };

  const validateMessageInput = (msg: string) => {
    if (!msg.length) {
      setMessageError('Required field');
    } else if (msg.length < 10) {
      setMessageError('Please enter at least 10 characters in the message field');
    } else {
      setMessageError('');
    }
  };

  const clearPhoneValidation = () => {
    setPhoneError('');
  };

  const clearAllValidation = () => {
    setPhoneError('');
    setMessageError('');
  };

  return {
    phoneError,
    messageError,
    validatePhoneInput,
    validateMessageInput,
    clearPhoneValidation,
    clearAllValidation,
  };
};
