// Modules
import React from 'react';

// Styles
import { XModalCloseContainer } from './XModalClose.styles';
import { white } from '../../styles/colors';

// Components
import { Icon } from '../Icon/Icon';

type XModalCloseProps = {
  close: () => void;
};
export function XModalClose(props: XModalCloseProps) {
  return (
    <XModalCloseContainer className='position-absolute ' onClick={props.close}>
      <div className='d-flex justify-content-center align-items-center p-0'>
        <Icon icon='cross' color={white} size={8} />
      </div>
    </XModalCloseContainer>
  );
}
