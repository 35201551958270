import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import * as colors from '../../../../common/styles/colors';
import { ButtonPrimary, Icon } from '../../../../common/components';

type TpiDataFailureModalProps = {
  show: boolean;
  onHide: () => void;
};

const defaultProps = {
  show: true,
};

const TpiDataFailureModalWrapper = styled.div``;

export const TpiDataFailureModal = (props: TpiDataFailureModalProps) => {
  const title = 'Broker/TPI data failure';
  const messageTop = "We weren't able to retrieve the data you requested.";
  const messageBottom = 'Please re-request the data.';

  const handleClose = (e: any) => {
    e.preventDefault();
    props.onHide();
  };

  return (
    <TpiDataFailureModalWrapper>
      <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
        <Modal.Title>
          <span className='pr-4'>
            <Icon color={colors.draxBtn} size={50} icon='download2' />
          </span>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
        <p>{messageTop}</p>
        <p>{messageBottom}</p>
      </Modal.Body>

      <Modal.Footer className='p-0'>
        <div className='d-flex justify-content-end'>
          <ButtonPrimary className='m-0' title='OK' onClick={handleClose} />
        </div>
      </Modal.Footer>
    </TpiDataFailureModalWrapper>
  );
};

TpiDataFailureModal.defaultProps = defaultProps;
