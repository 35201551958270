// Styles
import { ButtonPrimary } from '../../../../common/components';
import { TagManager } from '../../../utils/analytics/TagManager';
import { InsightWrapper } from './Insight.styles';

type InsightProps = {
  title: string;
  description: string;
  imageSrc: string;
  btnText: string;
  btnLink: string;
};

const defaultProps = {};

export const Insight = (props: InsightProps) => {
  const onLinkClicked = (event: any) => {
    const insight = props.btnText;
    TagManager.pushData({
      event: 'View Market Insight',
      insight,
    });
  };

  return (
    <InsightWrapper>
      <div>
        <a className={`insight-link insight-animation`} href={props.btnLink} target='_blank' onClick={onLinkClicked}>
          <div className='row'>
            <div className='col-xs-12'>
              <img alt={props.title} className='pl-4 pr-4' src={props.imageSrc} width='100%' />
            </div>
            <div className='col-xs-12 insight-item'>
              <h4>{props.title}</h4>
              <p className='text-center description'>{props.description}</p>
              <ButtonPrimary href={props.btnLink} target='_blank' className='mt-auto'>
                {props.btnText}
              </ButtonPrimary>
            </div>
          </div>
        </a>
      </div>
    </InsightWrapper>
  );
};

Insight.defaultProps = defaultProps;
