import { useState } from 'react';
import IcomoonReact from 'icomoon-react';
import { components } from 'react-select';

import iconSet from '../../../assets/selection.json';
import { findLocation, getLocationDetails } from '../../api/paymentApi';
import { ReactSelectStyled } from './FormSelect.styles';
import { Label } from './FormSelect.styles';

type FormSelectProps = {
  onLocationSelect: Function;
  placeholder?: string;
  label?: string;
};

export const LocationFinder = ({ placeholder, label, onLocationSelect }: FormSelectProps) => {
  const [options, setOptions] = useState<any>([]);
  const [value, setValue] = useState<any>(null);

  const loadLocationDetails = async (id: string) => {
    const result = await getLocationDetails(id);

    onLocationSelect(result?.Items[0]);

    // Clear input value and options list after address selection
    setValue(null);
    setOptions([]);
  };

  const findLocations = async (text: string, id?: string) => {
    const result = await findLocation(text, id);
    const options = result?.Items?.map((item: any) => {
      return { ...item, value: item.Id, label: item.Text };
    });

    setOptions(options);
  };

  const handleInputChange = (value: string) => {
    if (!!value) {
      findLocations(value);
    }
  };

  const onChange = (selectedOption: any) => {
    loadLocationDetails(selectedOption.Id);
    setValue(selectedOption);
  };

  const onParentOptionClick = (option: any) => findLocations(option.label, option.Id);

  const Option = (props: any) => {
    const {
      innerProps: { onClick, ...restInnerProps },
      ...otherProps
    } = props;
    const { Description, Next, label } = props.data;
    const moreResults = Next === 'Find';

    return (
      // Using components.Option to use all default styles and other behaviour except onClick
      <components.Option {...restInnerProps} {...otherProps}>
        {/* Overwriting onClick step to use custom logic */}
        <div
          className='custom-option-wrapper'
          onClick={() => {
            moreResults ? onParentOptionClick(props.data) : onClick(props.data);
          }}
        >
          <div>
            {label}
            <span>{moreResults && Description}</span>
          </div>

          {moreResults && <IcomoonReact iconSet={iconSet} size={15} icon='chevron-right' />}
        </div>
      </components.Option>
    );
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <ReactSelectStyled
        isSearchable
        value={value}
        options={options}
        classNamePrefix={'FormSelect'}
        placeholder={placeholder}
        onInputChange={handleInputChange}
        components={{ Option }}
        onChange={onChange}
        // Turn off front-end filtering as we're using service search
        filterOption={() => true}
      />
    </>
  );
};
