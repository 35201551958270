const Power = ({ width, height, className, fillColor }: PowerProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16 29.0001C23.1798 29.0001 29.0001 23.1798 29.0001 16C29.0001 8.82031 23.1798 3 16 3C8.82031 3 3 8.82031 3 16C3 23.1798 8.82031 29.0001 16 29.0001Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M4 16C4 9.37475 9.37475 4 16 4C22.6253 4 28 9.37475 28 16C28 22.6253 22.6253 28 16 28C9.37475 28 4 22.6253 4 16ZM4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16ZM15.3747 18.0922V23.0701C15.3747 23.7074 16.2285 23.9118 16.517 23.3347L20.7976 14.7735C20.99 14.3768 20.7014 13.9078 20.2565 13.9078H17.2024V8.86974C17.2024 8.23247 16.3487 8.02806 16.0721 8.59319L11.6473 17.2144C11.4309 17.6112 11.7315 18.0922 12.1764 18.0922H15.3747Z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill={fillColor}
      stroke={fillColor}
    />
  </svg>
);

export type PowerProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Power.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2A3745',
};

export default Power;
