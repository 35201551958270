import { css } from 'styled-components';
import { respondTo } from '../mixins';

// TODO: Refactoring is required
export const filterReset = css`
  .mobile-wrapper {
    display: none !important;

    ${respondTo.tablet`
      display: flex !important;
    `}
  }
`;
