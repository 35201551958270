// Modules
import React from 'react';

// Components
import { OverviewGraph } from '../../ConfigurableChart/OverviewGraph';

// Styles
import { OverviewWrapper } from './Overview.styles';

export function Overview(props: any) {
  return (
    <OverviewWrapper>
      <OverviewGraph askForCustomerAccount={props.askForCustomerAccount}/>
    </OverviewWrapper>
  );
}
