import { css } from 'styled-components';

export const animationStyles = css`
  .slide-in {
    animation: slide-in 200ms forwards;
  }

  .slide-out {
    animation: slide-out 200ms forwards;
  }
`;
