// Modules
import { useState, useContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router';

// Components
import { SiteReference } from './SiteReference';
import { SiteDetailsGrid } from './SiteDetailsGrid';
import { CustomerDetails } from './CustomerDetails';
import { GridFilter } from '../gridfilter/GridFilter';
import { RoutingToTypes } from '../search/SearchActions/SearchActions';
import { ButtonDownload, FilterRowWrapper, StyledContentWrapper, StyledTitle } from '../../common/components';

// Types
import { EssAccountsTypes } from '../../types/account';

// Styles
import { DownloadWrapper } from './SiteDetailsGrid.styles';

// Context
import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { SearchDetailContext, SearchDetailContextProps } from '../../context/searchDetail/searchDetailContext';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';

// Utils
import { getInitialType } from '../../utils/getInitialTypes';
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { TagManager } from '../utils/analytics/TagManager';

// Api
import { getCustomerBalance, getCustomerDetails, getErsSitesData } from '../../common/api/sitesApi';
import { getSelectedCustomerId } from '../../helpers/customer';
import { NotificationBox } from '../health/NotificationBox/NotificationBox';

import { ContactUsModal } from '../mymessages/modalTemplates/ContactUsModal';
import { temporary } from '../../helpers/axiosWrapper';
import { DownloadDirectDebitModal } from '../mymessages/modalTemplates/DownloadDirectDebitModal';
import { getHealthCheck } from '../../common/api/healthCheckApi';
import { getAccountDetailsByCustomer } from '../../common/api/accountApi';
import { getMeteringPoints } from '../../common/api/meterReadsApi';
import { globalApiParams } from '../../common/api/globals';

const downloadOptions = [
  { value: 'csv', display: 'Download CSV' },
  { value: 'xml', display: 'Download XML' },
];

export const SiteDetails = () => {
  const authContext = useAuthState();
  const { userDetail, isCorpBroker, isCorpCustomer } = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const [contractId, setContractId] = useState(null);
  const [customerDetails, setCustomerDetails] = useState<Api.ICustomerDetails | null>(null);
  const [balanceDetails, setCustomerBalances] = useState<Api.ICustomerBalances | null>(null);
  const [hasDirectDebit, setHasDirectDebit] = useState<boolean>(true);

  const [showContactUsModal, setShowContactUsModal] = useState<boolean>(false);
  const [showDirectDebitModal, setShowDirectDebitModal] = useState<boolean>(false);
  const [siteData, setSiteData] = useState<Array<Api.IMeteringPoint>>([]);

  const location = useLocation<any>();
  const resolution = window.innerWidth;
  const isMobile = resolution <= 540;
  const isDesktop = !isMobile;

  useEffect(() => {
    setContractId(location?.state?.contractId || null);
  }, [location]);

  const initialState: IGridReducerState = {
    currentType: '',
    page: 1,
    data: {},
    initialSortField: isDraxBrand ? 'site-reference' : 'essSiteID',
    initialSortOrder: 'DESC',
    currentSortField: isDraxBrand ? 'site-reference' : 'essSiteID',
    currentSortOrder: 'DESC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  // When navigating directly to url
  useEffect(() => {
    if (userDetail.essCustomerId) {
      initialState.currentType = getInitialType(userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable);
      initialState.selectedCustomerId = getSelectedCustomerId(userDetail);

      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable]);

  useEffect(() => {
    if (searchContext.searchDetail != null && searchContext.searchDetail.searchText.length > 0) {
      // filter by search result

      if (searchContext.searchDetail.type && searchContext.searchDetail.routingTo === RoutingToTypes.siteDetails) {
        const type = searchContext.searchDetail.type.toLowerCase();

        if (type === 'mpan') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: searchContext.searchDetail.mpan } });
        } else if (type === 'site') {
          // Note:  only done for electricity
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: searchContext.searchDetail.siteId } });
        } else if (type === 'account') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({
            type: GRID_ACTIONS.FILTER_BY_CUSTOMER,
            payload: { essCustomerId: searchContext.searchDetail.accountId },
          });
        }
        // Clear Search Detail
        searchContext.setSearchDetail({ searchText: '' });
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext.searchDetail.type]);

  useEffect(() => {
    // Ignore if we are entering component from Search
    if (
      searchContext.searchDetail != null &&
      searchContext.searchDetail.type &&
      searchContext.searchDetail.type.length > 0
    )
      return;

    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(
      state.selectedCustomerId,
      state.selectedSiteReference || null,
      state.selectedMpanMprn || null,
      currentSort
    );
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selectedCustomerId,
    state.selectedSiteReference,
    state.page,
    state.currentFilter,
    state.currentSortField,
    state.currentSortOrder,
  ]);

  useEffect(() => {
    if (!state.selectedCustomerId) return;

    async function fetchData() {
      try {
        let siteData = await getMeteringPoints(
          process.env.REACT_APP_API_URL,
          authContext,
          state.selectedCustomerId,
          globalApiParams.maxFilterPageSize,
          state.page,
          'Active',
          undefined
        );
        setSiteData(siteData);
      } catch (err) {
        // handle error
      }
    }
    fetchData();
  }, [state.selectedCustomerId]);

  useEffect(() => {
    if (siteData.length == 0) return;

    let filters: any = {
      sitesFilters: [],
    };

    for (let i = 0; i < siteData.length; i++) {
      filters.sitesFilters.push({
        essSiteID: parseInt(siteData[i]['site-reference']),
        siteName: siteData[i]['site-reference'],
      });
    }

    dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId: state.selectedCustomerId, data: filters } });

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData]);

  const loadCustomerDetails = async (essCustomerId: string) => {
    const result: Common.IResult<Api.ICustomerDetails> = await getCustomerDetails(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );

    if (result?.success && result?.data) {
      return result.data;
    }

    return null;
  };

  const loadCustomerBalance = async (essCustomerId: string) => {
    const result: Api.ICustomerBalances = await getCustomerBalance(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );

    if (result) {
      return result;
    }

    return null;
  };

  const loadDirectDebitAccountHealth = async (essCustomerId: string) => {
    const url = '/ersgatewayadapter/account-health';
    const type = 'DirectDebitSetup';
    const accounts = [essCustomerId];

    const result: Common.IResult<Api.IHealthResponse> = await getHealthCheck(
      process.env.REACT_APP_API_URL,
      authContext,
      url,
      type,
      accounts
    );

    if (result?.success && result?.data) {
      return result.data;
    }

    return null;
  };

  const loadAccountDetails = async (essCustomerId: string) => {
    const result: Api.IAccountDetailsByCustomer = await getAccountDetailsByCustomer(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );

    if (result) {
      return result;
    }

    return null;
  };

  const getSitesRows = async (
    essCustomerId: string,
    siteReference: string | null,
    mpan: string | null,
    sort?: Common.ISort
  ) => {
    const result: Array<Api.IErsSiteData> = await getErsSitesData(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      siteReference,
      state.page,
      sort
    );

    const siteDetails: Array<Api.ISiteData> = result.map((item: Api.IErsSiteData) => ({
      customerSiteReference: item['customer-site-reference'],
      essSiteName: item['site-reference'],
      outOfContract: false,
      siteAddress: `${item['site-address']}, ${item['site-post-code']}`,
      supplyActive: true,
      contractEndDate: item['contract-end-date'],
      supplyEndDate: item['max-site-supply-end-date'],
      accountID: item['customer-account-reference'],
      essSiteID: item['site-id'],
    }));

    return siteDetails;
  };

  const loadData = async (customerId: string, siteId: string | null, mpan: string | null, sort?: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const customerDetails = await loadCustomerDetails(customerId);
    setCustomerDetails(customerDetails);

    const customerBalance = await loadCustomerBalance(customerId);
    setCustomerBalances(customerBalance);

    const directDebitAccountHealth = await loadDirectDebitAccountHealth(customerId);

    if (directDebitAccountHealth?.healthy) {
      setHasDirectDebit(true);
    } else {
      setHasDirectDebit(false);
    }

    const rawData = await getSitesRows(customerId, siteId, mpan, sort);
    dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: rawData, page: state.page } });

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  };

  const handleSortChange = (sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
    TagManager.pushData({
      event: 'Select Customer Account (Sites page)',
      selectedCustomerId: option.value,
    });
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId: option.value } });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    const siteReference = option ? option.value : '';
    dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: siteReference } });
  };

  const handleClearAll = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const downloadRegistrationReport = (option: Common.IOption) => {
    // TODO run file downloading based on option.value
    console.log('Download: ', option.value);
  };

  const downloadScheduleReport = () => {
    // TODO run file downloading
    console.log('Download schedule report');
  };

  const contactUsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (isTPIUser) {
      TagManager.pushData({
        event: 'Notification message (TPI overdue balance)',
      });
    } else {
      TagManager.pushData({
        event: 'Notification message (Customer overdue balance)',
      });
    }

    setShowContactUsModal(true);
  };

  const handleContactUsOnHide = (event: any) => {
    setShowContactUsModal(false);
  };

  const downloadFormClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    TagManager.pushData({
      event: 'Notification message (DDM download)',
    });

    setShowDirectDebitModal(true);
  };

  const handleDownloadDirectDebitOnHide = (event: any) => {
    setShowDirectDebitModal(false);
  };

  const isTPIUser = (temporary.accessId && temporary.accessId.length > 0) || false;

  return (
    <>
      <FilterRowWrapper>
        <StyledTitle>Account details</StyledTitle>
        <>
          {isOpusBrand && (isCorpBroker() || isCorpCustomer()) && (
            <DownloadWrapper>
              <ButtonDownload
                title='Group registration report'
                outline={false}
                options={downloadOptions}
                onClick={downloadRegistrationReport}
                icon='download'
              />

              <button className='download-btn nooutline' onClick={downloadScheduleReport}>
                Site schedule report
              </button>
            </DownloadWrapper>
          )}
        </>
      </FilterRowWrapper>
      {/* The below GridFilter will only return for mobile */}
      {isMobile && (
        <div>
          <GridFilter
            showSites={true}
            customerData={state.data?.customers}
            accountData={state.data?.accounts}
            siteData={state.data?.sites}
            handleCustomerFilterChange={handleCustomerFilterChange}
            handleAccountFilterChange={handleAccountFilterChange}
            handleSiteFilterChange={handleSiteFilterChange}
            handleClearAllClick={handleClearAll}
            showClearAll={true}
            showAccount={isOpusBrand}
          />
        </div>
      )}
      <div className='container-fluid'>
        <div className='row'>
          {/* The below GridFilter will only return for desktop */}
          {isDesktop && (
            <div className='col-md-12 col-xl-3'>
              <GridFilter
                showSites={true}
                customerData={state.data?.customers}
                accountData={state.data?.accounts}
                siteData={state.data?.sites}
                handleCustomerFilterChange={handleCustomerFilterChange}
                handleAccountFilterChange={handleAccountFilterChange}
                handleSiteFilterChange={handleSiteFilterChange}
                handleClearAllClick={handleClearAll}
                showClearAll={true}
                showAccount={isOpusBrand}
              />
            </div>
          )}
          <div className={!hasDirectDebit ? 'col-md-12 col-xl-3 my-3' : 'col-md-12 col-xl-3'}>
            {!hasDirectDebit && (
              <NotificationBox
                showButton={true}
                buttonClassName='button'
                boxText="We don't have a valid DD for this account"
                buttonText='Download'
                onClick={downloadFormClick}
              ></NotificationBox>
            )}
          </div>
          <div className={balanceDetails?.['balance-overdue']! > 0 ? 'col-md-12 col-xl-3 my-3' : 'col-md-12 col-xl-3'}>
            {balanceDetails?.['balance-overdue']! > 0 && (
              <NotificationBox
                showButton={true}
                buttonClassName='button'
                boxText='Your latest payment is overdue'
                buttonText='Contact us'
                onClick={contactUsClick}
              ></NotificationBox>
            )}
          </div>
        </div>
      </div>

      <StyledContentWrapper>
        {isOpusBrand && contractId && <div>Your sites are being filtered by contract ID: {contractId}</div>}
        {isOpusBrand && (isCorpBroker() || isCorpCustomer()) && <SiteReference />}
      </StyledContentWrapper>

      {!isCorpBroker() && (
        <CustomerDetails
          customerDetails={customerDetails}
          balanceDetails={balanceDetails}
          hasDirectDebit={hasDirectDebit}
        />
      )}

      <SiteDetailsGrid
        handleSortChange={handleSortChange}
        handlePagingChange={handlePagingChange}
        hasMoreData={state.hasMoreData}
        gridData={state.gridData}
        isLoading={state.isLoading}
      />

      <ContactUsModal
        show={showContactUsModal}
        contactEmail='majorbusinesscustomerservice@drax.com'
        contactNumber='01473 707748'
        onHide={handleContactUsOnHide}
        isDirectCustomer={!isTPIUser}
        isTpiCustomer={isTPIUser}
      ></ContactUsModal>

      <DownloadDirectDebitModal
        show={showDirectDebitModal}
        onHide={handleDownloadDirectDebitOnHide}
        accountManager={userDetail.userProfile.accountManager}
      ></DownloadDirectDebitModal>
    </>
  );
};
