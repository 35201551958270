import { Icon } from '../../../../common/components';
import { Info } from '../../../icons';

export const SuccessSubmitMessage = (
  <div>
    Thank you for submitting your meter read.
    <br />
    <br />
    This will be validated and processed by our systems and will be reflected on your next bill.
    <br />
    <br />
    If there are any further issues, you will be contacted by a member of our team.
    <br />
    <br />
    <div className='d-flex justify-content-center align-items-center'>
      <Icon icon={Info} size={36} className='mb-1 mr-1' />
      <div className='mr-5'>
        <i>
          "Did you know installing a{' '}
          <a href='https://energy.drax.com/smart-meters/' target='_blank' rel='noreferrer'>
            smart meter
          </a>{' '}
          could make things easier for you?"
        </i>
      </div>
    </div>
  </div>
);
