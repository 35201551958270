import { globalApiParams } from '../../common/api/globals';
import { EssAccountsTypes, IMeterOption } from '../../types/account';
import { isDraxBrand } from '../../utils/common';
import { sortMpans, sortSites } from '../../utils/sortFilters';

export const GRID_ACTIONS = {
  REINIT: 'REINIT',

  // GRID
  CHANGE_TYPE: 'CHANGE_TYPE',
  CHANGE_SORT: 'CHANGE_SORT',
  INCREMENT_PAGE: 'INCREMENT_PAGE',
  ADD_DATA: 'ADD_DATA',
  CLEAR_DATA: 'CLEAR_DATA',

  // FILTERS
  INIT_FILTERS: 'INIT_FILTERS',
  FILTER_BY_CUSTOMER: 'FILTER_BY_CUSTOMER',
  FILTER_BY_ACCOUNT: 'FILTER_BY_ACCOUNT',
  FILTER_BY_SITE: 'FILTER_BY_SITE',
  FILTER_BY_MPANMPRN: 'FILTER_BY_MPANMPRN',
  FILTER_BY_INVOICE: 'FILTER_BY_INVOICE',
  FILTER_BY_STATEMENT: 'FILTER_BY_STATEMENT',
  FILTER_BY_DATE: 'FILTER_BY_DATE',
  FILTER_BY_PERIOD: 'FILTER_BY_PERIOD',
  FILTER_BY_METER_TYPE: 'FILTER_BY_METER_TYPE',
  CLEAR_FILTERS: 'CLEAR_FILTERS',

  // LOADING
  CHANGE_LOADING: 'CHANGE_LOADING',
};

export const filterByCustomer = (essCustomerId: string) => ({
  type: GRID_ACTIONS.FILTER_BY_CUSTOMER,
  payload: { essCustomerId },
});

export interface IGridReducerState {
  currentType: string;
  filters?: Api.IFilters;

  gridData?: Array<any>;

  data: {
    customers?: Common.IOption[];
    accounts?: Common.IOption[];
    sites?: Common.IOption[];
    mpans?: Common.IOption[];
  };

  selectedEssAccountId?: string;
  selectedCustomerId: string;
  selectedSiteReference?: string;
  selectedMpanMprn?: string;
  selectedInvoice?: string;
  selectedStatement?: string;

  currentFilter?: any;
  initialSortField: string;
  initialSortOrder: 'ASC' | 'DESC' | '';
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';

  page: number;
  hasMoreData: boolean;
  isLoading?: boolean;
}

const formatCustomers = (data: Array<Api.ICustomerFilter>) =>
  data &&
  data.map(item => ({
    value: String(item.essCustomerID),
    display: `${item.essCustomerID} - ${item.customerName}`,
  }));

const formatAccountData = (data?: Array<Api.IAccountFilter>) => {
  let accountOptions: Array<Common.IOption> = [];

  if (data) {
    data.forEach(item => {
      const found = accountOptions.some(option => Number(option.value) === item.essAccountID);

      if (!found) {
        accountOptions.push({
          value: String(item.essAccountID),
          display: `${item.essAccountID} - ${item.accountName}`,
        });
      }
    });
  }
  return accountOptions;
};

const formatSiteData = (data?: Array<Api.ISiteFilter>) => {
  let siteOptions: Array<Common.IOption> = [];
  if (data) {
    data.forEach(item => {
      const found = siteOptions.some(option => option.value === String(item.siteName));
      if (!found) {
        siteOptions.push({
          value: `${item.siteName}`,
          display: item.siteName,
        });
      }
    });
  }
  return siteOptions;
};

const formatMpans = (currentType: string, data?: Array<Api.IMetersFilter>) => {
  let mpanMprnOptions: Array<IMeterOption> = [];
  if (data) {
    if (currentType === EssAccountsTypes.Electricity) {
      data.forEach(item => {
        const found = mpanMprnOptions.some(option => Number(option.value) === Number(item.mpan || item.mpanCore));

        if (!found && (item.mpan !== null || item.mpanCore !== 0)) {
          mpanMprnOptions.push({
            value: String(item.mpan || item.mpanCore),
            display: `${item.mpan || item.mpanCore}`,
            siteReference: item.essSiteID,
          });
        }
      });
    } else if (currentType === EssAccountsTypes.Gas) {
      data.forEach(item => {
        const found = mpanMprnOptions.some(option => Number(option.value) === item.mprn);

        if (!found && item.mprn !== null) {
          mpanMprnOptions.push({
            value: String(item.mprn),
            display: `${item.mprn}`,
          });
        }
      });
    }
  }
  return mpanMprnOptions;
};

const filterByCustomerId = (state: IGridReducerState, essCustomerId: string) => {
  if (state.filters?.accountFilters) {
    const accountFilters: Api.IAccountFilter[] = state.filters.accountFilters;
    const filteredAccounts = accountFilters.filter(item => item.essCustomerID === essCustomerId);
    const data = formatAccountData(filteredAccounts);
    state.data.accounts = data;
  }
};

const filterByAccountId = (state: IGridReducerState, essAccountId: number) => {
  if (state.filters?.accountFilters) {
    const sitesFilters: Api.ISiteFilter[] = state.filters.sitesFilters;

    const filteredSites = sitesFilters.filter(item => item.essAccountID === essAccountId);
    const data = formatSiteData(filteredSites);
    state.data.sites = data;
  }
};

const resetSort = (state: IGridReducerState) => {
  state.currentSortField = state.initialSortField;
  state.currentSortOrder = state.initialSortOrder;
};

export const gridReducer = (state: IGridReducerState, action: any) => {
  switch (action.type) {
    case GRID_ACTIONS.REINIT: {
      state.selectedCustomerId = action.payload.selectedCustomerId;
      state.currentType = action.payload.currentType;

      return { ...state };
    }
    case GRID_ACTIONS.CHANGE_TYPE: {
      state.currentType = action.payload;

      state.page = 1;
      state.currentFilter = null;
      state.selectedEssAccountId = undefined;
      state.selectedSiteReference = undefined;
      state.selectedMpanMprn = undefined;

      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.INIT_FILTERS: {
      const essCustomerId = action.payload.essCustomerId;
      const data = action.payload.data;

      // Sort filters
      let siteFiltersSorted = sortSites(data.sitesFilters);
      let mpansFilters: Array<Api.IMetersFilter> = data.mpansFilters || data.metersFilters;
      let mpansFiltersSorted = sortMpans(mpansFilters);

      if (data) {
        const filters: Api.IFilters = {
          customerFilters: data.customerFilters,
          accountFilters: data.accountFilters,
          sitesFilters: siteFiltersSorted,
          mpansFilters: mpansFiltersSorted,
        };
        state.filters = filters;

        // initialise customers
        const formattedCustomers = formatCustomers(state.filters.customerFilters);
        state.data.customers = formattedCustomers;

        // initialise accounts
        filterByCustomerId(state, essCustomerId);

        // initialise sites - DRAX ONLY as there's no Accounts so it's not filtered
        if (isDraxBrand) {
          state.data.sites = formatSiteData(state.filters.sitesFilters);
        }

        // initialise meters = mpan/mprn
        const formattedMeters = formatMpans(state.currentType, state.filters.mpansFilters);

        if (!state.selectedSiteReference) {
          state.data.mpans = formattedMeters;
        } else {
          state.data.mpans = formattedMeters.filter((el: IMeterOption) => {
            if (el.siteReference) return el.siteReference === state.selectedSiteReference;
            return el;
          });
        }
      }

      return { ...state };
    }
    case GRID_ACTIONS.ADD_DATA: {
      const data = action.payload.data;
      const page = action.payload.page;

      if (!data) {
        return { ...state, hasMoreData: false };
      }

      let gridData: any[] = [];
      if (!page || page === 1) {
        gridData = data;
      } else {
        if (state.gridData) {
          gridData = state.gridData.concat(data);
        } else gridData = [];
      }

      let hasMoreData = false;
      const length = data.length;
      if (length < globalApiParams.pageSize) {
        hasMoreData = false;
      } else {
        hasMoreData = true;
      }

      return { ...state, gridData: gridData, hasMoreData: hasMoreData };
    }
    case GRID_ACTIONS.CLEAR_DATA: {
      return { ...state, gridData: [] };
    }
    case GRID_ACTIONS.FILTER_BY_CUSTOMER: {
      state.page = 1;
      const essCustomerId = action.payload.essCustomerId;
      filterByCustomerId(state, essCustomerId);
      state.selectedCustomerId = essCustomerId;
      state.selectedSiteReference = undefined;
      state.selectedMpanMprn = undefined;
      state.currentFilter = { type: 'CustomerId', value: essCustomerId };
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_ACCOUNT: {
      state.page = 1;
      const essAccountId = action.payload.essAccountId;
      filterByAccountId(state, essAccountId);
      state.selectedEssAccountId = essAccountId;
      state.selectedSiteReference = undefined;
      state.currentFilter = { type: 'AccountId', value: essAccountId };
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_SITE: {
      state.page = 1;
      const essSiteId = action.payload.essSiteId;
      state.selectedSiteReference = essSiteId;
      state.selectedMpanMprn = undefined;
      state.currentFilter = { type: 'SiteId', value: essSiteId };

      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_MPANMPRN: {
      state.page = 1;
      const mprnMpan = action.payload.mprnMpan;
      state.selectedMpanMprn = mprnMpan;
      state.selectedSiteReference = undefined;
      state.currentFilter = { type: 'MpanMprn', value: mprnMpan };
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_INVOICE: {
      state.page = 1;
      const invoice = action.payload.invoice;
      state.selectedInvoice = invoice;
      state.currentFilter = { type: 'InvoiceReference', value: invoice };
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_STATEMENT: {
      state.page = 1;
      const statement = action.payload.statement;
      state.selectedStatement = statement;
      state.currentFilter = { type: 'Statement', value: statement };
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_DATE: {
      state.page = 1;
      const date = action.payload.date;
      state.currentFilter = { type: 'Date', value: date };
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_PERIOD: {
      state.page = 1;
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.FILTER_BY_METER_TYPE: {
      state.page = 1;
      const meterTypes = action.payload;
      state.currentFilter = { type: 'MeterType', value: meterTypes };
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.CLEAR_FILTERS: {
      state.page = 1;
      state.currentFilter = null;
      state.selectedEssAccountId = undefined;
      state.selectedSiteReference = undefined;
      state.selectedMpanMprn = undefined;
      resetSort(state);

      return { ...state };
    }
    case GRID_ACTIONS.INCREMENT_PAGE: {
      return { ...state, page: ++state.page };
    }
    case GRID_ACTIONS.CHANGE_SORT: {
      state.page = 1;
      state.currentSortField = action.payload.field;
      state.currentSortOrder = action.payload.order;

      return { ...state };
    }
    case GRID_ACTIONS.CHANGE_LOADING: {
      state.isLoading = action.payload;

      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};
