import IcomoonReact from 'icomoon-react';
import { EssAccountsTypes } from '../types/account';
import iconSet from '../assets/selection.json';
import * as colors from '../common/styles/colors';

export const getFuelIcon = (fuelType: string): any => {
  if (fuelType === EssAccountsTypes.Electricity)
    return <IcomoonReact className='energyTypeIcon' iconSet={iconSet} size={20} icon='electricity' />;
  else if (fuelType === EssAccountsTypes.Gas)
    return <IcomoonReact className='energyTypeIcon' iconSet={iconSet} size={20} icon='gas-02' />;
  else if (fuelType === EssAccountsTypes.Renewables)
    return <IcomoonReact className='energyTypeIcon' iconSet={iconSet} size={20} icon='renewables' />;
  return '';
};
