import Enumerable from 'linq';

export const groupedReadHistory = (registers: Array<Api.IMeterRead>) => {
  if (registers.length === 0 || registers === undefined) {
    return;
  }

  const grouping: Array<Api.IMeterReadHistory> = Enumerable.from(registers)
    .groupBy(
      r => r['meter-serial-number'],
      element => element,
      (meterSerialNumber, reads) => ({
        meterSerialNumber,
        registers: Enumerable.from(reads.toArray())
          .groupBy(
            read => read['register-id'],
            el => el,
            (id, items) => ({
              id,
              reads: items
                .select(r => ({
                  readingSource: r['reading-source'],
                  readingDate: r['reading-date'],
                  registerId: r['register-id'],
                  reading: r.reading,
                }))
                .toArray(),
            })
          )
          .toArray(),
      })
    )
    .toArray();
  return grouping;
};
