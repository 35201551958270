import { css } from 'styled-components';
import * as colors from '../colors';

export const barThickness = 11;
export const tooltipTransitionDurationSec = 0.5;
const tooltipArrowMargin = 7;
export const chartStyles = css`
  .chartjs-tooltip {
    opacity: 1;
    position: absolute;
    -webkit-transition: all ${tooltipTransitionDurationSec}s ease;
    transition: all ${tooltipTransitionDurationSec}s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 5%);

    p {
      margin: 0;
      white-space: nowrap;
    }

    .chartjs-tooltip-wrapper {
      position: relative;
      background: ${colors.white};
      box-shadow: 0 2px 5px rgba(10, 20, 100, 0.2);
      color: ${colors.darkBlue};
      border-radius: 8px;
      padding: 12px;

      .chartjs-tooltip-arrow {
        width: 13.5px;
        height: 13.5px;
        background-color: ${colors.white};
        position: absolute;
        left: -${tooltipArrowMargin}px;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(45deg);
        box-shadow: -5px 5px 10px -2px rgb(10 20 100 / 20%);
      }
      .chartjs-tooltip-arrow-align-left {
        left: unset;
        right: -${tooltipArrowMargin}px;
        box-shadow: 5px -5px 10px -2px rgb(10 20 100 / 20%);
      }
    }
  }

  .chartjs-tooltip-hidden {
    opacity: 0;
  }

  .chartjs-tooltip-display-none {
    display: none;
  }
`;
