import Input from './components/Input';
import Radio from './components/Radio';
import RadioColumn from './components/RadioColumn';
import Textarea from './components/Textarea';
import './styles.scss';

const formExports = {
  Input,
  Textarea,
  Radio,
  RadioColumn,
};

export default formExports;
