import { useState, useContext } from 'react';
import IcomoonReact from 'icomoon-react';
import Modal from 'react-bootstrap/Modal';
import { ThemeContext } from 'styled-components';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';

import { ButtonPrimary, Checkbox, FormInput, FormSelect, ModalClose } from '../../../common/components';
import { ActionRowWrapper, AddUserWrapper } from '../AddUserModal.styles';
import { ContactPreference } from '../../contactpreference/ContactPreference';
import iconSet from '../../../assets/selection.json';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

type ManageUserModalProps = {
  onHide: () => void;
  handleConfirm: Function;
  handleDeleteContact: Function;
  show: boolean;
  title: string;
  userInfo?: any;
};

const defaultProps = {
  onHide: undefined,
  handleConfirm: undefined,
  handleDeleteContact: undefined,
  show: false,
  title: 'Error',
};

type Inputs = {
  title: { label: string; value: string };
  firstname: string;
  surname: string;
  phoneNumber: string;
  mobile: string;
  email: string;
  jobtitle: string;
  associatedcustomer: { label: string; value: string };
  profilingOptIn: boolean;
};

const TITLES = ['Mr', 'Mrs', 'Miss', 'Ms', 'Other'];
const PHONE_PATTERN = /^\d{11}$/;
const EMAIL_PATTERN = /^\S+@\S+\.\S+$/;

const MOCK_CUSTOMERS = ['Mock Customer A', 'Mock Customer B', 'Mock Customer C', 'Mock Customer D', 'Other'];

const phoneNumberError = 'Enter valid phone number';
const mobilePhoneNumberError = 'Enter valid phone number';
const emailError = 'Enter valid email address';

export const ManageUserModal = (props: ManageUserModalProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const theme = useContext(ThemeContext);
  const [primaryContact, setPrimaryContact] = useState<boolean>(false);

  const methods = useForm<Inputs>({
    defaultValues: {
      title: { value: props.userInfo.title, label: props.userInfo.title },
      firstname: props.userInfo.firstname,
      surname: props.userInfo.surname,
      phoneNumber: props.userInfo.phoneNumber,
      mobile: props.userInfo.mobile,
      email: props.userInfo.email,
      jobtitle: props.userInfo.jobtitle,
      associatedcustomer: { value: props.userInfo.associatedcustomer, label: props.userInfo.associatedcustomer },
    },
  });

  const contactPreferences = {
    opusEmail: props.userInfo.opusEmail,
    opusPost: props.userInfo.opusPost,
    opusPhone: props.userInfo.opusPhone,
    opusSMS: props.userInfo.opusSMS,
    thirdPartyEmail: props.userInfo.thirdPartyEmail,
    thirdPartyPost: props.userInfo.thirdPartyPost,
    thirdPartyPhone: props.userInfo.thirdPartyPhone,
    thirdPartySMS: props.userInfo.thirdPartySMS,
    marketResearchEmail: props.userInfo.marketResearchEmail,
    marketResearchPost: props.userInfo.marketResearchPost,
    marketResearchPhone: props.userInfo.marketResearchPhone,
    marketResearchSMS: props.userInfo.marketResearchSMS,
  };

  const emailValue = useWatch({ control: methods.control, name: 'email' });
  const titleOptions = TITLES.map(title => ({ value: title, label: title }));
  const customerOptions = MOCK_CUSTOMERS.map(title => ({ value: title, label: title }));
  const [profilingOptIn, setProfilingOptIn] = useState<boolean>(props.userInfo.profilingOptIn);

  const submitHandler: SubmitHandler<Inputs> = data => {
    data.profilingOptIn = profilingOptIn;
    props.handleConfirm(data);
  };

  const handleDeleteContact = (event: any) => {
    event.preventDefault();
    props.onHide();
    props.handleDeleteContact();
  };

  return (
    <Modal animation={false} className={'custom-modal'} show={true} size={'lg'} onHide={props.onHide}>
      <Modal.Header>
        <p>Complete the details to manage a contact</p>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>

      <Modal.Body>
        <FormProvider {...methods}>
          <form>
            <AddUserWrapper>
              <div className='rowWrapper'>
                <label>Title</label>
                <FormSelect
                  name='title'
                  placeholder='Select a title'
                  options={titleOptions}
                  params={{ required: true }}
                />
              </div>
              <div className='rowWrapper'>
                <label>First Name</label>
                <FormInput name='firstname' placeholder='' params={{ required: true }} />
              </div>
              <div className='rowWrapper'>
                <label>Last Name</label>
                <FormInput name='surname' placeholder='' params={{ required: true }} />
              </div>
              <div className='rowWrapper'>
                <label>Phone number (landline)</label>
                <FormInput
                  name='phoneNumber'
                  placeholder=''
                  params={{
                    required: true,
                    pattern: { value: PHONE_PATTERN, message: phoneNumberError },
                  }}
                />
              </div>
              <div className='rowWrapper'>
                <label>Phone number (mobile)</label>
                <FormInput
                  name='mobile'
                  placeholder=''
                  params={{
                    pattern: { value: PHONE_PATTERN, message: mobilePhoneNumberError },
                  }}
                />
              </div>
              <div className='rowWrapper'>
                <label>Email Address</label>
                <FormInput
                  name='email'
                  placeholder=''
                  params={{
                    required: true,
                    pattern: { value: EMAIL_PATTERN, message: emailError },
                  }}
                />
              </div>
              <div className='rowWrapper'>
                <label>Job Title</label>
                <FormInput name='jobtitle' placeholder='' />
              </div>
              <div className='rowWrapper'>
                <label>Which customer is this account associated with?</label>
                <FormSelect
                  name='associatedcustomer'
                  placeholder='Select an account'
                  options={customerOptions}
                  params={{ required: true }}
                />
              </div>

              {emailValue === userDetail.emailAddress && (
                <>
                  <ContactPreference values={contactPreferences} />
                  <div className='rowWrapper pb-1'>
                    <Checkbox
                      id='profiling'
                      label='Opt in to Profiling'
                      onChange={(event: any) => setProfilingOptIn(event.target.checked)}
                      checked={profilingOptIn}
                    />
                  </div>
                </>
              )}

              {!props.userInfo.primaryContact && (
                <div className='rowWrapper'>
                  <Checkbox
                    id='primaryContact'
                    label='User to be setup as the Primary Contact'
                    onChange={(event: any) => setPrimaryContact(event.target.checked)}
                    checked={primaryContact}
                  />
                </div>
              )}

              <ActionRowWrapper>
                <div>
                  {props.userInfo.primaryContact && (
                    <p className='pr-3'>
                      This user is a primary contact or an account signatory which means they will receive all
                      correspondence and cannot be deleted or revoked unless another is assigned.
                    </p>
                  )}
                </div>

                <div className='buttons-wrapper'>
                  <ButtonPrimary
                    title='Update User'
                    onClick={event => {
                      event.preventDefault();
                      methods.handleSubmit(submitHandler)();
                    }}
                    className='m-0 submit-btn'
                  ></ButtonPrimary>

                  {!props.userInfo.primaryContact && (
                    <button className='icon-btn' onClick={handleDeleteContact}>
                      <span>Delete user</span>
                      <IcomoonReact iconSet={iconSet} color={theme.icons.default} size={15} icon='trash' />
                    </button>
                  )}
                </div>
              </ActionRowWrapper>
            </AddUserWrapper>
          </form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

ManageUserModal.defaultProps = defaultProps;
