import styled from 'styled-components';

import { respondTo } from '../../../common/styles/mixins';
import * as variables from '../../../common/styles/variables';

export const ActionRowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo.mobile`
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${variables.spacingXSmall};
  `};

  .submit-btn {
    min-width: 12.5rem;
    max-width: 100%;
    margin: ${variables.spacingMedium} 0 0;

    ${respondTo.mobile`
      margin: 0;
    `};
  }
`;
