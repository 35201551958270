export function downloadXML(xmlStr: string, name: string) {
  try {
    const hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:text/xml;charset=utf-8,' + encodeURI(xmlStr);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${name}.xml`;
    hiddenElement.click();
  } catch (error) {
    console.error(`Failed to begin XML download`, error);
  }
}
