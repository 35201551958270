import { MpanFilterItem } from '../components/ConfigurableChart/ChartTypes';
import { ICustomersFilterItem } from '../types/account';

export const sortCustomers = (customersFilters: Array<ICustomersFilterItem>): Array<ICustomersFilterItem> => {
  let customersFiltersSorted =
    customersFilters &&
    customersFilters.sort((a: ICustomersFilterItem, b: ICustomersFilterItem) => {
      const customerA = a.customerName.toLowerCase();
      const customerB = b.customerName.toLowerCase();
      if (customerA > customerB) {
        return 1;
      }

      if (customerA < customerB) {
        return -1;
      }

      return 0;
    });
  return customersFiltersSorted;
};

export const sortCustomer = (customers: Array<Api.ICustomer>): Array<Api.ICustomer> => {
  let customersFiltersSorted =
    customers &&
    customers.sort((a: Api.ICustomer, b: Api.ICustomer) => {
      const customerA = a.description.toLowerCase();
      const customerB = b.description.toLowerCase();
      if (customerA > customerB) {
        return 1;
      }

      if (customerA < customerB) {
        return -1;
      }

      return 0;
    });
  return customersFiltersSorted;
};

export const sortAccountsByDisplay = (accountFilters: Array<Common.IOption>): Array<Common.IOption> => {
  let accountFiltersSorted =
    accountFilters &&
    accountFilters.sort((a: Common.IOption, b: Common.IOption) => {
      const accountA = a.display.toLowerCase().trimStart();
      const accountB = b.display.toLowerCase().trimStart();
      if (accountA > accountB) {
        return 1;
      }

      if (accountA < accountB) {
        return -1;
      }

      return 0;
    });
  return accountFiltersSorted;
};

export const sortAccounts = (accountFilters: Array<Api.IEssAccountData>): Array<Api.IEssAccountData> => {
  let accountFiltersSorted =
    accountFilters &&
    accountFilters.sort((a: Api.IEssAccountData, b: Api.IEssAccountData) => {
      const accountA = a.accountName.toLowerCase();
      const accountB = b.accountName.toLowerCase();
      if (accountA > accountB) {
        return 1;
      }

      if (accountA < accountB) {
        return -1;
      }

      return 0;
    });
  return accountFiltersSorted;
};

export const sortSites = (siteFilters: Array<Api.ISitesSiteFilterOption>): Array<Api.ISitesSiteFilterOption> => {
  let siteFiltersSorted =
    siteFilters &&
    siteFilters.sort((a: Api.ISitesSiteFilterOption, b: Api.ISitesSiteFilterOption) => {
      const siteNameA = parseInt(a.siteName, 10);
      const siteNameB = parseInt(b.siteName, 10);
      if (siteNameA > siteNameB) {
        return 1;
      }

      if (siteNameA < siteNameB) {
        return -1;
      }

      return 0;
    });
  return siteFiltersSorted;
};

export const sortMpansFilterItem = (mpansFilters: Array<MpanFilterItem>): Array<MpanFilterItem> => {
  let mpansFiltersSorted =
    mpansFilters &&
    mpansFilters.sort((a: MpanFilterItem, b: MpanFilterItem) => {
      // mpan check
      const mpanA = parseInt(a.mpan, 10);
      const mpanB = parseInt(b.mpan, 10);
      if (mpanA > mpanB) {
        return 1;
      }

      if (mpanA < mpanB) {
        return -1;
      }

      return 0;
    });
  return mpansFiltersSorted;
};

export const sortMpans = (mpansFilters: Array<Api.IMetersFilter>): Array<Api.IMetersFilter> => {
  let mpansFiltersSorted =
    mpansFilters &&
    mpansFilters.sort((a: Api.IMetersFilter, b: Api.IMetersFilter) => {
      // mpanCore check
      if (a.mpanCore && b.mpanCore) {
        if (a.mpanCore > b.mpanCore) {
          return 1;
        }

        if (a.mpanCore < b.mpanCore) {
          return -1;
        }
      }

      // mpan check
      if (a.mpan > b.mpan) {
        return 1;
      }

      if (a.mpan < b.mpan) {
        return -1;
      }

      return 0;
    });
  return mpansFiltersSorted;
};
