import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { get, getFile, requestFile, post, put } from '../../helpers/axiosWrapper';
import { isDraxBrand } from '../../utils/common';

export const details = async (url: string | undefined, context: any): Promise<Common.IResult<Api.IDetails>> => {
  let headers = { user: context.user };
  return await get(url + '/v1/home/details', { headers }, context);
};

export const user = async (url: string | undefined, context: any): Promise<Common.IResult<Api.IUserDetails>> => {
  let headers = {};
  // TODO:  API GATEWAY VERSION (NEEDS TO MATCH):
  // return await get(url + '/users/user', { headers }, context);

  return await get(url + '/user/userBP', { headers }, context);
};

export const getuserbyId = async (
  url: string | undefined,
  context: any
): Promise<Common.IResult<Api.ILoggedInUser>> => {
  let headers = {};
  // return await get(url + '/user/getuserbyId', { headers }, context);

  return await get(url + '/user/getuserbyidbp', { headers }, context);
};

export const getCustomersByTpiReferenceId = async (
  url: string | undefined,
  context: any
): Promise<Array<Api.ITpiCustomer>> => {
  let headers = {};

  const loaStatus = 'Active';
  const onlineAccess = true;

  return await get(url + `/tpis/customers?loa-status=${loaStatus}&online-access=${onlineAccess}`, { headers }, context);
};

export const getCustomersByBrokerId = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Array<Api.ICustomerDetails>>> => {
  let headers = {};
  return await get(url + `/organisation/customersbybrokerid/${essCustomerId}`, { headers }, context);
};

export const getTpiAccessId = async (
  url: string | undefined,
  context: any,
  selectedCustomers: Array<string>
): Promise<Api.ITpiAccess> => {
  const data = selectedCustomers;

  return await post(url + '/ers/v1/tpis/api-key/tpi', { data }, context);
};

export const getMeteringPointTypeForCustomers = async (
  url: string | undefined,
  context: any,
  customerAccountReference: Array<string>
): Promise<Common.IResult<Array<Api.ICustomerMeteringPointType>> | undefined> => {
  if (customerAccountReference.length === 0) return Promise.resolve(undefined);

  let headers = {
    customerAccountReference,
  };
  return await get(url + '/ersgatewayadapter/meter/metering-point-types', { headers }, context);
};

export const search = async (
  url: string | undefined,
  axiosRequestConfig: AxiosRequestConfig,
  context: any
): Promise<any> => {
  const customerType = axiosRequestConfig.data.customerType;

  let searchAction = customerType;

  const searchUrl = url + `/search/${searchAction}?searchterm=${axiosRequestConfig.data.displayMessage}`;
  return await get(searchUrl, {}, context);
};

//Dashboard
export const accountoverview = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.IAccountOverviewData>> => {
  if (isDraxBrand) {
    return await get(url + `/ersgatewayadapter/customer/account-overview`, {}, context);
  } else {
    return await get(url + `/organisation/accountview/${essCustomerId}`, {}, context);
  }
};

// Usage Card
export const electrictotalconsumptionbyesscustomerid = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<string>> => {
  return await get(url + `/elecmonthlyconsumption/totalconsumptionbyesscustomerid/${essCustomerId}`, {}, context);
};

export const gastotalconsumptionbyesscustomerid = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<string>> => {
  return await get(url + `/gasmonthlyconsumption/totalconsumptionbyesscustomerid/${essCustomerId}`, {}, context);
};
// End of Usage Card

// Invoice And Payment Card
export const totalbalanceallaccounts = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.ITotalBalance>> => {
  return await get(url + `/account/totalbalanceallaccounts/${essCustomerId}`, {}, context);
};

// Invoice And Payment Card All accounts
export const accountbalances = async (
  url: string | undefined,
  context: any,
  customerName: string
): Promise<Array<Api.IAccountBalance>> => {
  return await get(url + `/account/accountbalances?ledger-type=Sales&page-size=50`, {}, context);
};

export const accountPayments = async (
  url: string | undefined,
  context: any,
  essAccountId: string
): Promise<Common.IResult<Api.IInvoiceAccount>> => {
  return await get(url + `/account/accountpayments/${essAccountId}`, {}, context);
};

export const renewableAccountPayments = async (
  url: string | undefined,
  context: any,
  essAccountId: string
): Promise<Common.IResult<Api.IInvoiceAccount>> => {
  return await get(url + `/adhocinvoice/renewablepayments/${essAccountId}`, {}, context);
};

export const downloadreport = async (url: string | undefined, context: any, data: {}): Promise<any> => {
  return await requestFile(url + `/streaming/accountbalancereport`, data, context);
};
// End of Invoice And Payment Card

export const getzippeddocuments = async (url: string | undefined, context: any, ids: Array<number>): Promise<any> => {
  const apiUrl = isDraxBrand ? `documents/getdocumentbyid/${ids[0]}` : 'streaming/getzipeddocuments';
  const headers: any = {
    docIds: ids,
  };

  return await getFile(url + `/${apiUrl}`, { headers }, context);
};

export const getinvoicesummaryreport = async (
  url: string | undefined,
  context: any,
  essInvoiceIDs: Array<number>
): Promise<any> => {
  let body: any = {
    responseFormat: 'csv',
    essInvoiceIDs: essInvoiceIDs.join(),
  };
  return await requestFile(url + `/streaming/invoicesummaryreport`, body, context);
};

export const getbackingdatadocuments = async (
  url: string | undefined,
  context: any,
  essAdhocInvoiceIDs: Array<number>
): Promise<any> => {
  // TODO - Awaiting changes on the API
  const essAdhocInvoiceIDList = essAdhocInvoiceIDs.join();
  return await requestFile(url + `/streaming/backingdata/${essAdhocInvoiceIDList}`, {}, context);
};

export const sumbitmeterreading = async (
  url: string | undefined,
  context: any
): Promise<Common.IResult<Api.ISubmitMeterReading>> => {
  return await post(url + '/v1/home/sumbitmeterreading', {}, context);
};

export const yourgeneration = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.IYourGeneration>> => {
  return await get(url + `/adhocinvoice/generation/${essCustomerId}`, {}, context);
};

export const customerUserProfile = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.IUserProfile>> => {
  return await get(url + `/customer/userprofile/${essCustomerId}`, {}, context);
};

export const customerstartdate = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.ICustomerStartDate>> => {
  // return await get(url + `?/customerdetails/${essCustomerId}`, {}, context);

  toast.error('ENDPOINT NOT YET IMPLEMENTED (1)', { position: 'bottom-right' });

  // END POINT NOT IMPLEMENTED
  const tmpDate = Date();

  return Promise.resolve({
    success: true,
    value: {
      data: {
        minDate: tmpDate,
      },
    },
    data: {
      minDate: tmpDate,
    },
    errors: [],
  });
};

export const bulkdownloadinvoice = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  month: number | null,
  year: number | null
): Promise<Common.IResult<number[]>> => {
  const headers: any = { year, month };

  return await get(url + `/invoice/invoicedocidsbymonth/${essCustomerId}`, { headers }, context);
};

// SME E-bill Specific
export const getEBillingStatus = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.IEBillStatus[]>> => {
  return await get(url + `/ebilling/getcustomerregimebyesscustomerid/${essCustomerId}`, {}, context);
};

export const setEBillingAcceptance = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<string>> => {
  return await put(url + `/ebilling/putbillingregimeid/${essCustomerId}`, {}, context);
};
// End of SME E-bill Specific

// Primary Contact
export const getPrimaryContactDetails = async (
  url: string | undefined,
  context: any
): Promise<Common.IResult<Api.IPrimaryContactDetails>> => {
  return await get(`${url}/contact/primarycontact`, {}, context);
};

export const updatePrimaryContactDetails = async (
  url: string | undefined,
  context: any,
  data: any
): Promise<Common.IResult<any>> => {
  return await put(`${url}/contact/primarycontact`, data, context);
};

export const markContactUpdated = async (url: string | undefined, context: any): Promise<Common.IResult<any>> => {
  return await put(`${url}/user/contactdialog`, {}, context);
};
// End of Primary Contact

export const submitSupportRequest = async (url: string | undefined, data: any, context: any): Promise<any> => {
  return await put(`${url}/correspondence/correspondence`, data, context);
};
