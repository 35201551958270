// Modules
import React from 'react';
import { Tooltip, Overlay } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

// Components
import { RowInputItem } from '../RowInputItem/RowInputItem';

// Utils
import { isValidNumber } from '../../../utils';
import { formatDate } from '../../../components/utils/date/date';

// Types
import { EssAccountsTypes } from '../../../types/account';

// Styles
import { SubmitRegisterItemWrapper } from './SubmitMeterReadingItem.styles';
import { error, spearmintGreen, white } from '../../styles/colors';
import { fontSizeMedium } from '../../styles/variables';
import { isDraxBrand, isOpusBrand } from '../../../utils/common';
import { Placement } from 'react-bootstrap/esm/Overlay';

type SubmitRegisterItemProps = {
  value: string;
  lastRead: number;
  containerClassName?: string;
  description: string;
  lastReadDate: string;
  isDescriptionHidden?: boolean;
  tooltipId: any;
  meterType: EssAccountsTypes;
  disabled?: boolean;
  toolTipPosition?: Placement;
  isSubmitted?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

SubmitRegisterItem.defaultProps = {
  isDescriptionHidden: false,
  disabled: false,
  toolTipPosition: 'left-start',
};

export function SubmitRegisterItem(props: SubmitRegisterItemProps) {
  // Props
  const {
    value,
    lastRead,
    meterType,
    tooltipId,
    description,
    lastReadDate,
    containerClassName,
    isDescriptionHidden,
    disabled,
    isSubmitted,
    onBlur,
    onFocus,
    onChange,
  } = props;

  // Refs
  const inputWrapperRef = React.useRef<any>(null);

  // State
  const [isValidValue, setIsValidValue] = React.useState(false);
  const [isShowingTooltip, setIsShowingTooltip] = React.useState(false);

  // Handlers
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      onChange(e);
    }

    // Show tooltip when entered first digit
    if (e.target.value.length > 0 && !isShowingTooltip) {
      setIsShowingTooltip(true);
      // Hide tooltip when cleared input
    } else if (!e.target.value.length && isShowingTooltip) {
      setIsShowingTooltip(false);
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    if (value.length) {
      setIsShowingTooltip(true);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
    if (parseFloat(value) >= lastRead) {
      setIsShowingTooltip(false);
    }
  };

  const validateValue = (currentValue: string | number) => {
    const isValid = parseFloat(`${currentValue}`) >= lastRead;

    if (isValid !== isValidValue) {
      setIsValidValue(isValid);
    }
    return isValid;
  };

  const isRenderingDescription =
    ((isOpusBrand && meterType === EssAccountsTypes.Electricity) || isDraxBrand) && description && !isDescriptionHidden;
  let wrapperClassName = 'd-flex flex-column';

  if (typeof containerClassName === 'string') wrapperClassName += ` ${containerClassName}`;

  return (
    <SubmitRegisterItemWrapper className={wrapperClassName}>
      {isRenderingDescription && <p className='mb-0 meter__reading__title'>{description}</p>}
      <div className='px-0 col-12 d-flex flex-column flex-xl-row'>
        <div className='px-0 col-md-6 col-xl-6 d-flex-column'>
          <p className='mb-1'>
            {(typeof lastRead === 'number' && lastRead.toFixed(2)) || 'No previous read available'}
          </p>
          <p className='mb-0'>{lastReadDate ? formatDate(lastReadDate) : 'No Date available'}</p>
        </div>
        <div ref={inputWrapperRef} className='px-0 col-12 col-xl-6 meter__reading__input'>
          <RowInputItem
            value={value}
            readOnly={disabled}
            isSubmitted={isSubmitted}
            onFocus={handleOnFocus}
            onHandleBlur={handleOnBlur}
            onChange={handleInputChange}
            validateValue={validateValue}
            onChangeValidation={e => isValidNumber(e.target.value)}
            placeholder='0000.00'
            htmlInputProps={{
              inputMode: 'numeric',
              required: true,
            }}
          />
        </div>
      </div>
    </SubmitRegisterItemWrapper>
  );
}
