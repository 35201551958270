import Contacts from '../contacts/Contacts';
import React, { useContext } from 'react';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

export const OpusProfileContactInfo = ({ hasRenewableAccount }: any) => {
  const { isCorpBroker, isCorpCustomer, isSMEBroker, isSMECustomer } =
    useContext<UserDetailContextProps>(UserDetailContext);

  return (
    <>
      <div className='profileText'>
        <span>Find troubleshooting advice and more info in our </span>

        {(isSMECustomer() || isCorpCustomer() || isCorpBroker()) && (
          <a href='http://www.opusenergy.com/MOE-User-Guide' target='_blank' rel='noopener noreferrer'>
            how to guide.
          </a>
        )}

        {isSMEBroker() && (
          <a href='http://www.opusenergy.com/MOE-NonTendered' target='_blank' rel='noopener noreferrer'>
            how to guide.
          </a>
        )}

        <span> If you require anymore information or general queries then </span>
        <a href='https://www.opusenergy.com/contact-us/' target='_blank' rel='noopener noreferrer'>
          contact us.
        </a>
      </div>
      <br />

      <div className='contactText'>
        <Contacts hasRenewableAccount={hasRenewableAccount} />
      </div>
    </>
  );
};
