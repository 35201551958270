const Invoices = ({ width, height, className, fillColor }: InvoicesProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 11V6H25V22H20V24H26C26.5523 24 27 23.5523 27 23V5C27 4.44772 26.5523 4 26 4H11C10.4477 4 10 4.44772 10 5V11H12Z'
      fill={fillColor}
      stroke={fillColor}
      strokeWidth='0'
    />
    <path
      d='M5 10C5 9.44772 5.44772 9 6 9H22V28C22 28.5523 21.5523 29 21 29H6C5.44772 29 5 28.5523 5 28V10Z'
      stroke={fillColor}
      strokeWidth='2'
      style={{ fill: '#fff' }}
    />

    <rect x='9' y='14' width='9' height='2' fill={fillColor} stroke={fillColor} strokeWidth='0' />
    <rect x='9' y='18' width='9' height='2' fill={fillColor} stroke={fillColor} strokeWidth='0' />
    <rect x='9' y='22' width='9' height='2' fill={fillColor} stroke={fillColor} strokeWidth='0' />
  </svg>
);

export type InvoicesProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Invoices.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2A3745',
};

export default Invoices;
