import IcomoonReact from 'icomoon-react';

import iconSet from '../../../assets/selection.json';

type IconProps = {
  icon: string | React.ElementType;
  size?: any;
  color?: any;
  className?: any;
};

const defaultProps = {
  size: 15,
  className: '',
};

const Component = ({ icon: Icon, size, color, className }: IconProps) => {
  if (typeof Icon === 'string') {
    return <IcomoonReact className={className} iconSet={iconSet} size={size} fill={color} icon={Icon} />;
  }
  return <Icon className={className} fillColor={color} width={size} height={size} />;
};

Component.defaultProps = defaultProps;

export { Component as Icon };
