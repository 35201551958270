import React from 'react';
import styled from 'styled-components';

import { darkBlue } from '../../styles/colors';

interface SortingArrowsProps {
  order: '' | 'ASC' | 'DESC';
  onClick?: any;
}

const Wrapper = styled.div`
  margin-left: 10px;
  margin-bottom: 5px;
`;

const StyledArrow = styled.div<{ theme: any; active: boolean }>`
  width: 7px;
  height: 7px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: ${({ theme, active }) => (active ? theme.primary.border : darkBlue)};
`;

export const StyledArrowUp = styled(StyledArrow)`
  transform: rotate(-135deg);
`;

export const StyledArrowDown = styled(StyledArrow)`
  transform: rotate(45deg);
`;

const SortingArrows = (props: SortingArrowsProps) => {
  const { order } = props;
  return (
    <Wrapper>
      <StyledArrowUp active={order === 'ASC'} />
      <StyledArrowDown active={order === 'DESC'} />
    </Wrapper>
  );
};

SortingArrows.defaultProps = {
  order: '',
};

export default SortingArrows;
