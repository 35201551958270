import styled from 'styled-components';

import * as colors from '../../../common/styles/colors';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';
import { respondTo } from '../../../common/styles/mixins';

export const PaymentHistoryWrapper = styled.div`
  padding-left: ${variables.defaultSpacer};
  padding-right: ${variables.defaultSpacer};

  ${respondTo.tablet`
    padding-left: 0px;
    padding-right: 0px;
  `};

  .primary {
    text-align: center;

    ${respondTo.tablet`
      text-align: left;
    `};
  }

  .expand {
    border-top: 1px solid ${colors.darkGrey};

    th {
      color: ${({ theme }) => theme.text.secondary};
      background-color: ${colors.lightGrey} !important;
    }
  }

  .table-fixed {
    ${respondTo.tablet`
      padding-right: 33px;
    `};
    height: calc(100vh - 280px);
  }

  .list {
    ${mixins.textWrap};

    ${respondTo.mobile`
      overflow-wrap: initial;
    `};

    .heading {
      color: ${({ theme }) => theme.text.secondary};
    }

    thead {
      display: table-header-group;
    }

    .statement {
      white-space: nowrap;
      display: table-cell;
    }

    thead,
    tbody {
      .icon {
        padding-left: 0px;
      }
    }

    tr {
      position: relative;
    }

    tbody {
      border-top: none;

      .date {
        padding-left: 0.75rem;
      }

      .actions-wrapper {
        white-space: nowrap;
        opacity: 0;
        vertical-align: middle;

        a {
          pointer-events: none;
          padding-right: 0.7em;

          &:last-child {
            padding-right: 0;
          }
        }
      }

      &.selected {
        tr {
          background-color: ${({ theme }) => theme.bg.secondary};
        }

        .actions-wrapper {
          display: inherit;
          opacity: 1;
          pointer-events: all;

          a {
            pointer-events: all;
          }
        }
      }

      tr {
        ${mixins.transitionFast};
        cursor: pointer;

        &.selected {
          td {
            background-color: ${({ theme }) => theme.bg.secondary};

            input {
              background-color: ${colors.white};
            }
          }

          .actions-wrapper {
            opacity: 1;
            pointer-events: all;

            a {
              pointer-events: all;
            }
          }
        }

        .item {
          cursor: pointer;
        }
      }
    }

    .checkbox {
      label {
        display: inline;
      }

      span {
        bottom: 0;
      }
    }

    .icon-chevron {
      position: relative;
      top: -0.1em;
      left: 0.1em;
    }

    .expandable-content {
      display: flex;
      flex-direction: column;

      td {
        display: flex;

        &:first-child {
          position: inherit;
          z-index: 0 !important;
        }
      }

      span,
      p {
        text-align: left;
        white-space: break-spaces;
        margin-bottom: 0;
      }

      ${respondTo.tablet`
          display: table-row;

          td {
            display: table-cell;
          }
      `};

      & > td {
        &:first-child {
          position: relative !important;
        }
      }
    }

    .amount-cell {
      ${respondTo.tablet`
        width: auto;
      `};
      text-align: end;
    }

    .serial-number-list {
      td {
        padding: 4px 0;
      }
    }

    .dotted-underline {
      margin: 0;
      border-bottom: 1px dashed;
    }
  }

  .download-btn {
    a {
      padding: 0.2rem 2rem !important;
      background-color: ${colors.hydroBlue} !important;
      color: ${colors.white} !important;

      &:hover {
        background-color: ${colors.draxDarkBlue} !important;
      }

      & > svg {
        fill: ${colors.white} !important;
        transform: rotate(180deg);
      }

      ${respondTo.desktop`
        padding: 0.5rem 2rem !important;
      `};
    }
  }

  .dropdown__list {
    a {
      background-color: ${colors.white} !important;
      color: ${colors.oxfordBlue} !important;

      &:hover {
        background-color: ${({ theme }) => theme.primary.bg} !important;
        color: ${colors.white} !important;
      }
    }
  }

  .page-text {
    text-align: center;

    ${respondTo.tablet`
      text-align: left;
    `};
  }
`;

export const PaymentHistoryGridWrapper = styled.div`
  position: relative;
  min-height: 200px;

  tbody:after {
    background: none !important;
  }

  td.collection-ref-col {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .expand-scroll {
    ${mixins.scrollbars};
    padding: '0 0';
    border-top: 1px solid black;
    max-height: 250px;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
  }

  .expand-scroll-snap {
    scroll-snap-align: start;
  }
`;

export const DownloadButtonsWrapper = styled.div`
  a {
    margin: 20px 0;
    font-weight: 600;
    text-decoration: none;
    color: ${colors.white};
    background: ${colors.hydroBlue};
    padding: 30px;

    ${respondTo.tablet`
      margin: 0;
    `}

    &:hover {
      text-decoration: none;
      color: white;
      background: ${colors.draxDarkBlue};
    }

    & > svg {
      margin-right: 10px !important;
      fill: ${colors.white};
      transform: rotate(180deg);
    }
  }
`;
