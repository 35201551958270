import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import { Table, Loading, NoResults, ButtonPrimary, Icon } from '../../common/components';
import { respondTo } from '../../common/styles/mixins';
import { DATE_FORMAT } from '../../common/constants';
import { getFuelIcon } from '../../utils/getFuelIcon';
import { isDraxBrand } from '../../utils/common';
import { TagManager } from '../../components/utils/analytics/TagManager';
import { ContactSummaryGridWrapper } from './ContactSummaryGridStyles';
import { iconsToUse } from '../../common/styles/themes';
import * as variables from '../../common/styles/variables';
import { globalApiParams } from '../../common/api/globals';

const StyledTableWrapper = styled.div`
  ${respondTo.tablet`
    padding-right: 33px;
  `};
`;

type ContactSummaryGridComponentProps = {
  selectedType: string;
  gridData: Api.IContactSummaryPagedData[];
  handleSortChange: Function;
  handlePagingChange: Function;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
};

const defaultProps = {
  isLoading: true,
  gridData: [],
  selectedType: 'Electricity',
  hasMoreData: true,
  currentSortField: '',
  currentSortOrder: 'ASC',
};

const actionStyles = {
  width: '150px',
};

const contractSummaryDraxHeaders: Array<Common.HeaderItem> = [
  {
    title: '',
    dataValue: 'warningIcon',
    enableSort: false,
  },
  {
    title: 'Contract ID',
    dataValue: 'contract-id',
    sortField: 'contract-id',
  },
  {
    title: 'Contract status',
    dataValue: 'contract-status',
    enableSort: false,
  },
  {
    title: 'Contract type',
    dataValue: 'contractAccountTypeExt', // contract-account-type" and "contract-account-sub-type"
    sortField: 'contract-account-type',
  },
  {
    title: 'Start date',
    dataValue: 'contract-start-date',
    sortField: 'contract-start-date',
  },
  {
    title: 'End date',
    dataValue: 'contract-end-date',
    sortField: 'contract-end-date',
  },
  {
    title: 'Billing cycle',
    dataValue: 'billing-frequency',
    sortField: 'billing-frequency',
  },
  {
    title: 'Sites supplied',
    dataValue: 'count-sites',
    sortField: 'count-sites',
    width: 110,
  },
];

const contractSummaryOpusHeaders: Array<Common.HeaderItem> = [
  {
    title: '',
    dataValue: 'icon',
  },
  {
    title: 'Contract ID',
    dataValue: 'essContractID',
    sortField: 'ContractId',
  },
  {
    title: 'Start date',
    sortField: 'StartDate',
    dataValue: 'startDate',
  },
  {
    title: 'End date',
    dataValue: 'endDate',
    sortField: 'EndDate',
  },
  {
    title: 'Sites supplied',
    dataValue: 'sitesSupplied',
  },
];

export const ContactSummaryGrid = (props: ContactSummaryGridComponentProps) => {
  const { gridData, selectedType, isLoading } = props;
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };
  const scrollableTable = useRef<any>();
  const history = useHistory();

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }
  }, [props.gridData]);

  useEffect(() => {
    clearSelection();
  }, [selectedType]);

  const clearSelection = () => {
    setSelectedRow(null);
  };

  const handleRowSelect = (event: any, row: number | null) => {
    if (row !== undefined) {
      setSelectedRow(row);
    }
  };

  const handleRedirectToSite = (contractId: number) => {
    history.push({
      pathname: 'site-details',
      state: { contractId },
    });
  };

  const handleSiteDetailsClick = (event: any, row: any) => {
    event.preventDefault();

    TagManager.pushData({
      event: 'View Contract Site Details',
      selectedRow: row,
    });
    handleRedirectToSite(row.essContractID);
  };

  const getActions = (row: any) => (
    <ButtonPrimary title='Site details' onClick={e => handleSiteDetailsClick(e, row)} className='col m-0' />
  );

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>) => {
    clearSelection();
    const typeId = event.currentTarget.dataset.value || '';
    const details = sortDetails;

    if (details.field === typeId) {
      details.order = details.order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }

    props.handleSortChange(details);
    event.preventDefault();
  };

  const handlePagingChange = () => {
    props.handlePagingChange();
  };

  const getBillingCycleText = (str: string) => {
    if (str.includes('Monthly')) return 'Monthly';
    else if (str.includes('Quarterly')) return 'Quarterly';
    else return 'Custom';
  };

  const renderIcon = () => <td className='icon'>{getFuelIcon(selectedType)}</td>;
  const renderDate = (value: any) => <td>{moment(value).format(DATE_FORMAT)}</td>;
  const renderWarningIcon = (value: any) => (
    <td className='warningIcon'>{value ? <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> : ''}</td>
  );

  const headers = isDraxBrand ? contractSummaryDraxHeaders : contractSummaryOpusHeaders;
  const updatedGridData = gridData.map(el => ({
    ...el,
    billingFrequency: getBillingCycleText(el['billing-frequency']),
  }));

  return (
    <ContactSummaryGridWrapper>
      {!isLoading && gridData.length === 0 && <NoResults />}

      {gridData.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={gridData.length}
            next={handlePagingChange}
            hasMore={props.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <StyledTableWrapper
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1'
            >
              <Table
                headers={headers}
                actions={getActions}
                rows={updatedGridData}
                onClickSort={handleSort}
                sortDetails={sortDetails}
                renderendDate={renderDate}
                actionStyles={actionStyles}
                selectedRowIndex={selectedRow}
                handleSelectRow={handleRowSelect}
                className='list table plain text-left'
                rendericon={renderIcon}
                renderstartDate={renderDate}
                renderwarningIcon={renderWarningIcon}
              />
            </StyledTableWrapper>
          </InfiniteScroll>
        </>
      )}

      {isLoading && <Loading overlay />}
    </ContactSummaryGridWrapper>
  );
};

ContactSummaryGrid.defaultProps = defaultProps;
