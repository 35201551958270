import styled from 'styled-components';

import { nepalBlue } from '../../styles/colors';
import { globalRadius } from '../../styles/variables';

export const SwitchViewBtnContainer = styled.button`
  bottom: 22px;
  left: 12px;
  min-width: 62px;
  max-width: 62px;
  min-height: 39px;
  max-height: 39px;
  background-color: ${nepalBlue};
  border-radius: ${globalRadius};
  border: none;
`;
