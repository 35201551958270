const Download = ({ width, height, className, fillColor, style }: DownloadProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 13 15'
    style={style}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8.00001 12C7.7348 12 7.48044 11.8946 7.29291 11.7071L3.29291 7.70711C2.90238 7.31658 2.90238 6.68342 3.29291 6.29289C3.68343 5.90237 4.31659 5.90237 4.70712 6.29289L7.00001 8.58579L7.00001 0.999999C7.00001 0.447715 7.44773 -4.85395e-07 8.00001 -4.37113e-07C8.5523 -3.88831e-07 9.00001 0.447714 9.00001 1L9.00001 8.58578L11.2929 6.29289C11.6834 5.90237 12.3166 5.90237 12.7071 6.29289C13.0976 6.68342 13.0976 7.31658 12.7071 7.70711L8.70712 11.7071C8.51958 11.8946 8.26523 12 8.00001 12Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0 15C0 14.4477 0.447715 14 1 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H1C0.447715 16 0 15.5523 0 15Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M8.00001 12C7.7348 12 7.48044 11.8946 7.29291 11.7071L3.29291 7.70711C2.90238 7.31658 2.90238 6.68342 3.29291 6.29289C3.68343 5.90237 4.31659 5.90237 4.70712 6.29289L7.00001 8.58579L7.00001 0.999999C7.00001 0.447715 7.44773 -4.85395e-07 8.00001 -4.37113e-07C8.5523 -3.88831e-07 9.00001 0.447714 9.00001 1L9.00001 8.58578L11.2929 6.29289C11.6834 5.90237 12.3166 5.90237 12.7071 6.29289C13.0976 6.68342 13.0976 7.31658 12.7071 7.70711L8.70712 11.7071C8.51958 11.8946 8.26523 12 8.00001 12Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0 15C0 14.4477 0.447715 14 1 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H1C0.447715 16 0 15.5523 0 15Z'
      fill='white'
    />
  </svg>
);

export type DownloadProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

Download.defaultProps = {
  width: 28,
  height: 28,
};

export default Download;
