import IcomoonReact from 'icomoon-react';

import * as colours from '../../../common/styles/colors';
import iconSet from '../../../assets/selection.json';

type ModalCloseProps = {
  onClick: () => void;
};

export const ModalClose = ({ onClick }: ModalCloseProps) => {
  return (
    <>
      <button className='close close-mobile d-block d-md-none' onClick={onClick}>
        <div className='d-flex' aria-hidden='true'>
          <IcomoonReact iconSet={iconSet} color={colours.trueblack} size={20} icon='chevron-left' />
        </div>
      </button>
      <button className='close close-desktop d-none d-md-block' onClick={onClick}>
        <div className='d-flex' aria-hidden='true'>
          <IcomoonReact iconSet={iconSet} color={colours.trueblack} size={20} icon='cross' />
        </div>
      </button>
    </>
  );
};
