import styled from 'styled-components';
import * as mixins from '../../../common/styles/mixins';

export const BulkMessagesText = {
  header: 'Moving premises?',
  headerBtn: 'Looking to move?',
  movePremisesBtn: 'Move premises',
};

export const BulkMessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h2.header {
    font-size: 23px;
  }

  .bulkMessagesOverviewMobile {
    ${mixins.widgetMobile};
  }

  .bulkMessagesOverview {
    ${mixins.widgetDesktop};
  }

  .customerRow {
    .customerRowAccountName {
      white-space: normal;
    }

    .customerRowAccountNameLast {
      white-space: normal;
    }
  }

  .seeMoreBtn {
    margin-left: auto;
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
    padding-right: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    text-align: right;
    cursor: pointer;
  }

  .linkBtn {
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
  }
`;
