import { css } from 'styled-components';
import * as variables from '../variables';

export const sortStyles = css`
  .sort {
    a {
      color: ${({ theme }) => theme.text.secondary};
      text-decoration: none;

      svg {
        margin-left: ${variables.spacingSmall};
      }
    }
  }
`;
