import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary, ModalClose } from '../../../common/components';
import { useContext } from 'react';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

type MeterReadUploadSuccessProps = {
  onHide: () => void;
  messagevalues: Messages.IMessagesValues;
  handleMarkAsRead: Function | undefined;
};

export const MeterReadUploadSuccess = (props: MeterReadUploadSuccessProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const { onHide, handleMarkAsRead = () => {} } = props;

  const handleCloseModal = () => {
    if (props.messagevalues && props.messagevalues?.ids) {
      const data = {
        emailAddress: userDetail.emailAddress,
        alertIds: [props.messagevalues.ids[0]],
      };
      handleMarkAsRead(data);
      onHide();
    }
  };

  return (
    <>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Meter read upload successful</Modal.Title>
        <ModalClose onClick={handleCloseModal} />
      </Modal.Header>

      <Modal.Body className='pl-lg-4'>
        <div>
          Thank you for submitting meter reads.
          <br />
          <br />
          These will be validated and processed by our systems and will be reflected on your next bill.
          <br />
          <br />
          If there are any further issues, you will be contacted by a member of our team.
        </div>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={handleCloseModal} className={'mr-0'} title='Okay' route='#' />
      </Modal.Footer>
    </>
  );
};
