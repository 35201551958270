// Modules
import React, { useContext, useState } from 'react';
import IcomoonReact from 'icomoon-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// Images
import iconSet from '../../../assets/selection.json';

// Components
import { DropDown } from '../../../common/components/DropDown/DropDown';
import { MeterReadingDataBlock } from './MeterReadingData/MeterReadingDataBlock';

// Styles
import { SubmitMeterReadingWrapper, SubmitMeterReadingText } from './SubmitMeterReading.styles';
import { razzmatazzPink } from '../../../common/styles/colors';

// Context
import { HomeContext, HomeContextProps } from '../context/homeContext';
import { useAuthState } from '../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

// Api
import { fetchEssAccountsByCustomerId } from '../../../common/api/accountApi';
import {
  loadElecMeterReads,
  loadGenMeterReads,
  loadGasMeterReads,
  loadElecMetersBySiteId,
  loadGasMetersBySiteId,
  loadSitesByAccountId,
} from '../../../common/api/dataApi';

// Types
import {
  EssAccountsTypes,
  IElecRegisterData,
  IGasRegisterData,
  IMeterItemData,
  ISiteData,
} from '../../../types/account';
import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';
import { isDraxBrand } from '../../../utils/common';

type SubmitMeterReadingProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const defaultProps = {
  onClick: undefined,
};

const WIDGET_KEY = 'SubmitMeterReading';

export const SubmitMeterReading = (props: SubmitMeterReadingProps) => {
  // Context
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);

  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const { userDetail, isSMECustomer, isCorpCustomer } = useContext<UserDetailContextProps>(UserDetailContext);
  const authContext = useAuthState();

  // State
  const [selectedAccountId, setSelectedAccount] = useState<string | null>(null);
  const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
  const [selectedMeterSerialNumber, setSelectedMeterSerialNumber] = useState<string | null>(null);

  const [essAccounts, setEssAccounts] = useState<Api.IEssAccountData[]>([]);
  const [sites, setSites] = useState<ISiteData[]>([]);
  const [resetSitesDropdownState, setResetSitesDropdownState] = useState(false);
  const [meters, setMeters] = useState<IMeterItemData[]>([]);
  const [meterRegisters, setMeterRegisters] = useState<Array<IGasRegisterData | IElecRegisterData>>([]);
  const [resetAccountsDropdownState, setResetAccountsDropdownState] = useState(false);
  const [resetMetersDropdownState, setResetMetersDropdownState] = useState(false);
  const [isLoadingMeterData, setIsLoadingMeterData] = React.useState(false);

  const [isSingleAccount, setIsSingleAccount] = useState<boolean>(false);
  const [isSingleSite, setIsSingleSite] = useState<boolean>(false);
  const [isSingleMeter, setIsSingleMeter] = useState<boolean>(false);

  // Handlers
  const resetAccountsDropdown = () => {
    setResetAccountsDropdownState(true);
    setSelectedAccount(null);
  };
  const resetMetersDropdown = () => {
    setResetMetersDropdownState(true);
    setSelectedMeterSerialNumber(null);
    setMeters([]);
    setMeterRegisters([]);
  };
  const resetSitesDropdown = () => {
    setResetSitesDropdownState(true);
    setSelectedSiteId(null);
    setSites([]);
  };
  const handleAccountChange = (e: Common.ITypedOption<string>) => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      meterWidgetExpanded: !!e.value,
    });

    setSelectedAccount(e.value);
    resetSitesDropdown();
    resetMetersDropdown();

    loadSitesByAccountId(process.env.REACT_APP_API_URL, authContext, e.value).then(result => {
      if (result) {
        setSites(result || []);
      } else {
        const message = 'API error calling: sitesbyaccountid';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      }
    });

    // TODO - API needs to return IResult - move to this when it does
    // loadSitesByAccountId(process.env.REACT_APP_API_URL, authContext, e.value).then(result => {
    //   if (result?.success) {
    //     setSites(result.data || []);
    //   } else {
    //     const message = 'API error calling: sitesbyaccountid';
    //     console.error(message);
    //     toast.error(message, { position: 'bottom-right' });
    //   }
    // });
  };
  const handleSiteChange = (e: Common.ITypedOption<ISiteData>) => {
    setSelectedSiteId(e.value.essSiteID);
    resetMetersDropdown();
    let promise = null;

    if (e.value.product === EssAccountsTypes.Gas) {
      promise = loadGasMetersBySiteId(process.env.REACT_APP_API_URL, authContext, e.value.essSiteID);
    } else if (isDraxBrand || e.value.product === EssAccountsTypes.Electricity) {
      promise = loadElecMetersBySiteId(process.env.REACT_APP_API_URL, authContext, e.value.essSiteID);
    }

    if (promise instanceof Promise) {
      promise
        .then((result: any) => {
          // TODO - API needs to return IResult - move to this when it does
          if (result) {
            setMeters(result?.data || result || []);
          } else {
            const message = 'API error calling: BySiteId';
            console.error(message);
            toast.error(message, { position: 'bottom-right' });
          }
        })
        .catch(error => {
          console.error(`Failed to load meters list for site: "${e.value.essSiteID}"`, error);
        });
    }
  };
  const handleMeterChange = (e: Common.ITypedOption<IMeterItemData>) => {
    const meter = e.value;
    const meterSerialNumber = String(
      meter?.meterSerialNumber || meter?.serialNumber || meter?.essElecMeteringSystemID
    )!;

    setSelectedMeterSerialNumber(meterSerialNumber || null);

    if (!meter) return;
    let promise = null;
    const selectedSite = sites.find(site => site.essSiteID === selectedSiteId);

    if (selectedSite?.product === EssAccountsTypes.Gas) {
      promise = loadGasMeterReads(process.env.REACT_APP_API_URL, authContext, meter.essSiteID, meterSerialNumber);
    } else if (selectedSite?.product === EssAccountsTypes.Electricity || isDraxBrand) {
      if (meter.genmeter) {
        promise = loadGenMeterReads(process.env.REACT_APP_API_URL, authContext, meterSerialNumber);
      } else {
        promise = loadElecMeterReads(
          process.env.REACT_APP_API_URL,
          authContext,
          (meter.essSiteID || meter?.siteId)!,
          meterSerialNumber
        );
      }
    }

    if (promise instanceof Promise) {
      setIsLoadingMeterData(true);
      promise
        .then((result: Common.IResult<IGasRegisterData> | Common.IResult<IElecRegisterData>) => {
          if (result.success) {
            let registers;

            if (result.data) {
              if (Array.isArray(result.data)) {
                registers = [...result.data];
              } else {
                registers = [result.data];
              }
              setMeterRegisters(registers);
            }
          } else {
            const message = 'API error calling: MeterReads';
            console.error(message);
            toast.error(message, { position: 'bottom-right' });
          }
        })
        .catch(error => {
          console.error(`Failed to load meters list for site: "${e.value.essSiteID}"`, error);
        })
        .finally(() => {
          setIsLoadingMeterData(false);
        });
    }
  };
  function toggleActive() {
    setHomeState({
      selectedCard: WIDGET_KEY,
      active: homeState.selectedCard !== WIDGET_KEY || !homeState.active,
    });
  }
  const checkIsSiteSelected = (currentItem: ISiteData, selectedItem: ISiteData | undefined) =>
    currentItem.essSiteID === selectedItem?.essSiteID;
  const checkIsMeterSelected = (currentItem: IMeterItemData, selectedItem: IMeterItemData | undefined) =>
    (currentItem?.meterID || currentItem?.essElecMeterID) === (selectedItem?.meterID || selectedItem?.essElecMeterID);

  React.useEffect(() => {
    const customerId = userDetail.essCustomerId;

    // Load required data
    fetchEssAccountsByCustomerId(process.env.REACT_APP_API_URL, authContext, customerId).then(async result => {
      try {
        if (result?.success) {
          const loadedEssAccounts = result.data;

          setEssAccounts(loadedEssAccounts);
        } else {
          const message = 'API error calling: getessaccountidbyesscustomerid';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
        }
      } catch (error) {
        console.error(`Failed to load data required for meter readings widget`, error);
      }
    });
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobileDropdownOpened = homeState.active && homeState.selectedCard === WIDGET_KEY;
  const showMeterReadData = isSMECustomer() || isCorpCustomer();
  const isSMECustomerWithNoRenewables = isSMECustomer() && !userDetail.hasRenewable;
  const accountOptions: Common.ITypedOption<string>[] = essAccounts.map(account => {
    let icon = undefined;

    if (account.product === EssAccountsTypes.Gas) {
      icon = 'gas-02';
    } else if (account.product === EssAccountsTypes.Electricity) {
      icon = 'electricity';
      if (account.renewable) {
        icon = 'renewables';
      }
    }
    return {
      display: account.accountName,
      value: account.essAccountID,
      icon,
    };
  });
  const siteOptions: Common.ITypedOption<ISiteData>[] = sites.map(site => ({
    display: `${site.essSiteID} ${site.siteName}`,
    value: site,
  }));
  const meterOptions: Common.ITypedOption<IMeterItemData>[] = meters.map(meter => ({
    display: String(meter?.meterSerialNumber || meter?.serialNumber || meter?.essElecMeteringSystemID),
    value: meter,
  }));

  React.useEffect(() => {
    if (isSMECustomer() && !userDetail.hasRenewable && accountOptions.length === 1) {
      setIsSingleAccount(true);
      handleAccountChange(accountOptions[0]);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [essAccounts]);

  React.useEffect(() => {
    if (isSingleAccount && isSMECustomer() && !userDetail.hasRenewable && siteOptions.length === 1) {
      setIsSingleSite(true);
      handleSiteChange(siteOptions[0]);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites]);

  React.useEffect(() => {
    if (isSingleSite && isSMECustomer() && !userDetail.hasRenewable && meterOptions.length === 1) {
      setIsSingleMeter(true);
      handleMeterChange(meterOptions[0]);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meters]);

  React.useEffect(() => {
    if (!globalContext.globalDetail.meterWidgetExpanded) {
      resetAccountsDropdown();
      resetMetersDropdown();
      resetSitesDropdown();
    }
  }, [globalContext.globalDetail.meterWidgetExpanded]);

  const shouldPreselectOption = () => {
    return isSingleAccount && isSingleSite && isSingleMeter && meterRegisters.length <= 2;
  };

  return (
    <SubmitMeterReadingWrapper {...props}>
      <div className='submitMeterReading'>
        <div onClick={toggleActive} className='toggleSubmitMeterReadingMobile position-absolute w-100 h-100 d-md-none'>
          <div className='menu-dropdown'>
            <IcomoonReact
              className='itemicon chevron'
              iconSet={iconSet}
              size={15}
              icon={isMobileDropdownOpened ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        <div className={`d-flex flex-lg-row flex-wrap flex-lg-nowrap`}>
          <div
            className={`meterReadFields p-0 px-md-3 ${
              showMeterReadData && (isSMECustomerWithNoRenewables || globalContext.globalDetail.meterWidgetExpanded)
                ? `col-12 col-lg-6`
                : `col-12`
            }`}
          >
            <h2 className={`submitReadTitle m-auto`}>{SubmitMeterReadingText.header}</h2>
            <div className={`d-md-flex flex-column mt-lg-3 ${!isMobileDropdownOpened ? 'd-none mt-0' : 'mt-3'}`}>
              <div className='dropdown'>
                <DropDown
                  options={accountOptions}
                  showBlankValue
                  selectText={SubmitMeterReadingText.selectAccount}
                  selectedIndex={shouldPreselectOption() ? 1 : undefined}
                  handleChange={handleAccountChange}
                  resetState={resetAccountsDropdownState}
                  afterStateResetCallback={() => setResetAccountsDropdownState(false)}
                />
              </div>
              <div className='dropdown'>
                <DropDown
                  options={siteOptions}
                  showBlankValue
                  selectText={SubmitMeterReadingText.selectSite}
                  selectedIndex={shouldPreselectOption() ? 1 : undefined}
                  showValue={false}
                  handleChange={handleSiteChange}
                  enabled={!!siteOptions.length}
                  checkIsSelectedValue={checkIsSiteSelected}
                  getOptionsKey={site => site.essSiteID || site}
                  resetState={resetSitesDropdownState}
                  afterStateResetCallback={() => setResetSitesDropdownState(false)}
                />
              </div>
              <div className='dropdown'>
                <DropDown
                  options={meterOptions}
                  showBlankValue
                  selectText={SubmitMeterReadingText.selectMeter}
                  selectedIndex={shouldPreselectOption() ? 1 : undefined}
                  showValue={false}
                  handleChange={handleMeterChange}
                  enabled={!!meterOptions.length && meterOptions.length < 3}
                  checkIsSelectedValue={checkIsMeterSelected}
                  getOptionsKey={meter => meter?.meterID || meter?.essElecMeterID || meter}
                  resetState={resetMetersDropdownState}
                  afterStateResetCallback={() => setResetMetersDropdownState(false)}
                />
              </div>
              {meterRegisters.length > 2 && (
                <div className='d-flex justify-content-end'>
                  <Link
                    className='bulk__upload__link d-flex align-items-center'
                    to='/home/energy-usage/bulk-readings-tool'
                  >
                    <span>Bulk upload</span>
                    <IcomoonReact
                      className='itemicon chevron'
                      iconSet={iconSet}
                      color={razzmatazzPink}
                      size={20}
                      icon='launch'
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
          {showMeterReadData && (isSMECustomerWithNoRenewables || globalContext.globalDetail.meterWidgetExpanded) && (
            <MeterReadingDataBlock
              essAccountId={selectedAccountId}
              essAccounts={essAccounts}
              siteId={selectedSiteId}
              sites={sites}
              meterSerialNumber={selectedMeterSerialNumber}
              meters={meters}
              meterRegisters={meterRegisters}
              isLoading={isLoadingMeterData}
            />
          )}
        </div>
      </div>
    </SubmitMeterReadingWrapper>
  );
};

SubmitMeterReading.defaultProps = defaultProps;
