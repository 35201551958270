import { getMeters, getMetersDetailsV2, MeterReadingRegisterBody } from '../../common/api/meterReadsApi';

export const formatSites = (data: Array<Api.IMeteringPoint>) =>
  data.map((el: Api.IMeteringPoint) => ({
    essAccountID: el['customer-account-reference'],
    essSiteID: el['site-id'],
    siteName: el['site-reference'],
  }));

export const formatMpans = (data: Array<Api.IMeteringPoint>) =>
  data.map((el: Api.IMeteringPoint) => ({
    essCustomerID: el['customer-account-reference'],
    essSiteID: el['site-reference'],
    mpanCore: el['mpan-core'],
  }));

export const getRegisterReadings = (data: any, meterSerialNumber: string | null): Array<MeterReadingRegisterBody> => {
  if (!meterSerialNumber) return [];

  return Object.values(data).map((el: any) => ({
    'register-id': el.registerId,
    'meter-serial-number': meterSerialNumber,
    'reading': parseFloat(el.newRead),
  }));
};

export const getActiveMeterRegisters = async (
  authContext: any,
  customerId: string,
  mpan: string,
  siteReference: string,
  showEstimatedReadings: boolean,
) => {
  const isActiveMeter = (activeMeters: Array<Api.IMeter>, meterSerialNumber: string) => {
    const index = activeMeters.findIndex(m => m['meter-serial-number'] === meterSerialNumber);
    return index >= 0;
  };

  const metersRegisters = await getMetersDetailsV2(process.env.REACT_APP_API_URL, authContext, Number(mpan), showEstimatedReadings);

  const filters = {
    'mpan-core': mpan,
    'site-reference': siteReference,
  };

  const activeMeters: Array<Api.IMeter> = await getMeters(
    process.env.REACT_APP_API_URL,
    authContext,
    customerId,
    filters,
    undefined,
    undefined,
    undefined,
    'Active'
  );

  if (activeMeters) {
    // Filter to get the active meters from meterRegisters
    const activeMeterRegisters = metersRegisters.filter(m => {
      return isActiveMeter(activeMeters, m['meter-serial-number']);
    });

    return activeMeterRegisters;
  } else {
    return [];
  }
};
