const Info = ({ width, height, className, fillColor, style }: InfoProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 28 28'
    fill='none'
    style={style}
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='16' cy='16' r='10' fill='#216EF2' />
    <path
      d='M16.4219 17.8281H15.5703L15.2109 9.57812H16.7812L16.4219 17.8281ZM15.0547 20.1875C15.0547 19.8229 15.1432 19.5651 15.3203 19.4141C15.4974 19.2578 15.7188 19.1797 15.9844 19.1797C16.2448 19.1797 16.4635 19.2578 16.6406 19.4141C16.8229 19.5651 16.9141 19.8229 16.9141 20.1875C16.9141 20.5469 16.8229 20.8099 16.6406 20.9766C16.4635 21.138 16.2448 21.2188 15.9844 21.2188C15.7188 21.2188 15.4974 21.138 15.3203 20.9766C15.1432 20.8099 15.0547 20.5469 15.0547 20.1875Z'
      fill='white'
    />
  </svg>
);

export type InfoProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

Info.defaultProps = {
  width: 28,
  height: 28,
};

export default Info;
