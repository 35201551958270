const HelpQuestion = ({ width, height, className, fillColor }: HelpQuestionProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 1024 1024'
    xmlns='http://www.w3.org/2000/svg'
    fill={fillColor}
  >
    <path d='M486.4 768c-14.138 0-25.6-11.461-25.6-25.6v-102.4c0-14.139 11.462-25.6 25.6-25.6 98.811 0 179.2-80.389 179.2-179.2s-80.389-179.2-179.2-179.2-179.2 80.389-179.2 179.2c0 14.138-11.462 25.6-25.6 25.6s-25.6-11.462-25.6-25.6c0-127.043 103.357-230.4 230.4-230.4 127.042 0 230.4 103.357 230.4 230.4 0 118.389-89.763 216.211-204.8 228.987v78.213c0 14.139-11.462 25.6-25.6 25.6z' />
    <path d='M486.4 921.6c-0.002 0 0 0 0 0-14.139 0-25.6-11.462-25.6-25.6v-51.2c0-14.138 11.462-25.6 25.6-25.6 0 0 0 0 0 0 14.139 0 25.6 11.462 25.6 25.6v51.2c0 14.138-11.464 25.6-25.6 25.6z' />
  </svg>
);

export type HelpQuestionProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

HelpQuestion.defaultProps = {
  width: 40,
  height: 40,
  fillColor: '#000000',
};

export default HelpQuestion;
