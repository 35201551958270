import React, { useState, useContext, useEffect, useReducer } from 'react';

import { GridFilter } from '../gridfilter/GridFilter';
import { useAuthState } from '../../providers/authProvider';
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { Loading, StyledTitle } from '../../common/components';
import { FilterRowWrapper } from '../../common/components/Filter/Filter.styles';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { gridReducer, GRID_ACTIONS, filterByCustomer, IGridReducerState } from '../../reducer/gridReducer/gridReducer';

import { getSelectedCustomerId } from '../../helpers/customer';
import { CustomerDetails } from '../sitedetails/CustomerDetails';
import { getHealthCheck } from '../../common/api/healthCheckApi';
import { getCustomerBalance, getCustomerDetails } from '../../common/api/sitesApi';
import MakeCardPaymentFormExternal from './MakeCardPaymentFormExternal';
import DebitCreditCardExternal from './DebitCreditCardExternal';
import { isTpiUser } from '../../helpers/tpiUser';

const DEFAULT_SORT = { field: 'none', order: 'ASC' } as Common.ISort;

const MakeAPayment = () => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [customerDetails, setCustomerDetails] = useState<Api.ICustomerDetails | null>(null);
  const [balanceDetails, setCustomerBalances] = useState<Api.ICustomerBalances | null>(null);
  const [hasDirectDebit, setHasDirectDebit] = useState<boolean>(true);

  const initialState: IGridReducerState = {
    page: 1,
    currentType: '',
    data: {},
    initialSortField: 'none',
    initialSortOrder: 'DESC',
    currentSortField: 'none',
    currentSortOrder: 'ASC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  useEffect(() => {
    const selectedCustomerId = getSelectedCustomerId(userDetail);
    if (selectedCustomerId) {
      dispatch(filterByCustomer(selectedCustomerId));
      loadData(selectedCustomerId, undefined, DEFAULT_SORT);
    }
  }, [state.data?.customers, userDetail.essCustomerId]);
  
  const loadCustomerDetails = async (essCustomerId: string) => {
    const result: Common.IResult<Api.ICustomerDetails> = await getCustomerDetails(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );

    if (result?.success && result?.data) {
      return result.data;
    }

    return null;
  };

  const loadCustomerBalance = async (essCustomerId: string) => {
    const result: Api.ICustomerBalances = await getCustomerBalance(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );

    if (result) {
      return result;
    }

    return null;
  };

  const loadDirectDebitAccountHealth = async (essCustomerId: string) => {

    const url = '/ersgatewayadapter/account-health';
    const type = 'DirectDebitSetup'
    const accounts = [essCustomerId];

    const result: Common.IResult<Api.IHealthResponse> = await getHealthCheck(
      process.env.REACT_APP_API_URL,
      authContext,
      url,
      type,
      accounts
    );

    if (result?.success && result?.data) {
      return result.data;
    }

    return null;
  };

  const loadData = async (customerId: string, accountId?: string, sort?: Common.ISort) => {
   
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const customerDetails = await loadCustomerDetails(customerId);
    setCustomerDetails(customerDetails);

    const customerBalance = await loadCustomerBalance(customerId);
    setCustomerBalances(customerBalance);

    const directDebitAccountHealth = await loadDirectDebitAccountHealth(customerId);
    
    if (directDebitAccountHealth?.healthy) {
      setHasDirectDebit(true);
    } else {
      setHasDirectDebit(false);
    }

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch(filterByCustomer(option.value));
    loadData(option.value, undefined, DEFAULT_SORT);
  };

  const title = isDraxBrand ? 'Make a payment' : 'Make Card Payment';

  return (
    <>
      <FilterRowWrapper>
        <StyledTitle>{title}</StyledTitle>
      </FilterRowWrapper>
      <GridFilter
        customerData={state.data?.customers}
        selectedType={state.currentType}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleAccountFilterChange={() => {}}
        handleSiteFilterChange={() => {}}
        handleClearAllClick={() => {}}
        showSites={false}
        showClearAll={false}
        showAccount={isOpusBrand}
      />

      <CustomerDetails customerDetails={customerDetails} balanceDetails={balanceDetails} hasDirectDebit={hasDirectDebit} />

      {!isTpiUser() && 
        <DebitCreditCardExternal></DebitCreditCardExternal>
      }
      
      <MakeCardPaymentFormExternal></MakeCardPaymentFormExternal>

      {state.isLoading && <Loading overlay />}
    </>
  );
};

export default MakeAPayment;
