// Modules
import { useContext, useEffect, useRef } from 'react';

// Context
import { MakeCardPaymentContext } from './context/MakeCardPaymentContext';

// Utils
import { BRANDS } from '../../consts';
import { TagManager } from '../utils/analytics/TagManager';
import { useHistory } from 'react-router-dom';

type MakeCardPaymentConfirmationProps = {
  brand: BRANDS;
};

const defaultProps = {
  brand: BRANDS.DRAX,
};

const MakeCardPaymentConfirmation = (props: MakeCardPaymentConfirmationProps) => {
  // Refs
  const isEventSentRef = useRef(false);

  // Context
  const [accounts, , hppResponse] = useContext<any>(MakeCardPaymentContext);
  const history = useHistory();

  useEffect(() => {
    // Navigate to 1st process step in case page reload
    if (!accounts?.length) {
      history.push('/home/make-a-payment');
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  useEffect(() => {
    if (!isEventSentRef.current) {
      isEventSentRef.current = true;
      if (hppResponse?.RESULT > '00' || hppResponse?.error) {
        // Payment failed',
      }
    }
  }, [hppResponse]);

  const TEXTS: { [key: string]: any } = {
    [BRANDS.OPUS]: [
      'If you have any queries regarding this payment or anything else, please contact the ' +
        "<span class='text-bold'>Customer Service</span> team on <span class='text-bold'>0843 227 2377</span>",
      '',
      "A receipt of this transaction has been emailed to: <span class='primary'>" +
        hppResponse?.HPP_CUSTOMER_EMAIL +
        '</span>',
      "Please note that it can take up to <span class='text-bold'>3 working days</span> for your payment to be processed and applied to the outstanding balance on your account.",
    ],
    [BRANDS.DRAX]: [
      "Thanks for submitting your payment. We've emailed a receipt to <span class='primary'>" +
        hppResponse?.HPP_CUSTOMER_EMAIL +
        '</span>',
      'Please note that it can take up to 3 working days to process your payment and apply it to the balance(s) on your account.',
      'Please contact your account manager if you have any queries.',
    ],
  };

  const TEXTS_FAILED: { [key: string]: any } = {
    [BRANDS.OPUS]: [
      'Please check the card details entered were correct and try again, or contact your payment provider for more information.',
    ],
    [BRANDS.DRAX]: [
      'Please check the card details entered were correct and try again, or contact your payment provider for more information.',
    ],
  };

  return (
    <>
      {/* 00 - transaction completed successfully and finalised. */}
      {hppResponse?.RESULT === '00' && (
        <div className='panel d-flex flex-column'>
          <h4>Payment summary</h4>
          <p className='pt-4 mb-0'>
            Payment amount: <span className='primary'>£{(Number(hppResponse.AMOUNT) / 100).toFixed(2)}</span>
          </p>
          <p className='mb-0'>
            {props.brand === BRANDS.OPUS && <span>Payment Reference: </span>}
            {props.brand === BRANDS.DRAX && <span>Reference: </span>}
            <span className='primary'>{hppResponse?.PASREF}</span>
          </p>
          <p className='mb-5'>
            <span dangerouslySetInnerHTML={{ __html: TEXTS[props.brand][0] }} />
          </p>
          <h5 className='my-4'>What happens next?</h5>
          <p>
            <span dangerouslySetInnerHTML={{ __html: TEXTS[props.brand][1] }} />
          </p>
          <p>
            <span dangerouslySetInnerHTML={{ __html: TEXTS[props.brand][2] }} />
          </p>
          <p>
            <span dangerouslySetInnerHTML={{ __html: TEXTS[props.brand][3] }} />
          </p>
        </div>
      )}

      {/* 101 - the transaction did not complete successfully. No further status will be communicated. */}
      {(hppResponse?.RESULT > '00' || hppResponse?.error) && (
        <div className='panel d-flex flex-column'>
          <h4>Your transaction has not been successful</h4>
          <h5 className='my-4'>What happens next?</h5>
          <p>
            <span dangerouslySetInnerHTML={{ __html: TEXTS_FAILED[props.brand][0] }} />
          </p>
        </div>
      )}
    </>
  );
};

MakeCardPaymentConfirmation.defaultProps = defaultProps;

export default MakeCardPaymentConfirmation;
