import styled from 'styled-components';
import * as mixins from '../../../common/styles/mixins';
import * as colors from '../../../common/styles/colors';

export const HealthWrapper = styled.div`
  h2.header {
    font-size: 23px;
  }

  .spinner {
    height: 100%;
    position: relative;
  }
`;
