import styled from 'styled-components';

import * as colors from '../../styles/colors';

const CheckboxWrapper = styled.div`
  label {
    position: relative;
    display: inline;
    margin: 0 0 4px 36px;
    cursor: pointer;
  }

  input {
    opacity: 0;
    position: absolute;
    left: -20px;

    &:checked + span {
      background-color: ${({ theme }) => theme.primary.bg};

      &:after {
        border: solid ${colors.white};
        border-width: 0 2px 2px 0;
        height: 59%;
        width: 23%;
        left: 40%;
        top: 11%;
        transform: rotate(43deg);
      }
    }
  }

  span {
    margin-left: -28px;
    background-color: ${colors.midGrey};
    border-radius: 2px;
    height: 20px;
    width: 20px;
    left: 0;
    position: absolute;
    top: 2px;

    &:after {
      position: absolute;
      content: '';
    }
  }

  &.checkbox-small {
    span {
      height: 18px;
      width: 18px;
      top: -8px;
    }
  }
`;

export default CheckboxWrapper;
