import styled from 'styled-components';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';

export const BrokerLandingSubmitLoaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .brokerLandingPageSubmitLoaMobile {
    ${mixins.widgetMobile};
  }

  .brokerLandingPageSubmitLoa {
    ${mixins.widgetDesktop};
  }

  .icon-user {
    ${mixins.flipHorizontal};
    position: relative;
    top: -${variables.spacingSmall};
  }

  .access-message {
    font-weight: 500;
  }
`;
