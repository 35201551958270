import * as React from 'react';
import { ReactElement } from 'react';
import { AuthConsumer } from '../../providers/authProvider';

export const Logout: React.FC = (): ReactElement => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return <div className='p-4'>Logging Out...</div>;
    }}
  </AuthConsumer>
);
