import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import { respondTo } from '../../../common/styles/mixins';

export const SelectCustomerWrapper = styled.div`
  font-weight: 400;
  background-color: ${colors.lightGrey};

  margin-bottom: 1em;

  & > a {
    color: ${props => props.theme.primary.color};
  }

  & > a:hover {
    color: ${props => props.theme.primary.color};
  }

  ${respondTo.tablet`
      background-color: ${colors.white};
    `};

  .selectCustomerDropDown {
    padding-top: 15px;
  }

  // toggle
  .switch {
    padding-left: 2px;
    width: 200px;
    height: 20px;
    position: relative;
    display: block;
    margin: 0;
    text-align: left;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    position: absolute; //required error tooltip position
    right: 17px;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    border: none;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-sizing: content-box;
    width: 32px;
    height: 20px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: ${colors.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.primary.bg};

    &:before {
      background-color: ${colors.white};
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);

    &:before {
    }
  }

  input:disabled + .slider {
    opacity: var(--disabled-opacity);

    &:hover {
      cursor: auto;
    }
  }

  input:checked + .slider:before {
    transform: translateX(12px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  // end of toggle
`;
