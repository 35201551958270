// Modules
import { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Components
import Home from '../components/home';
import Error from '../components/error/Error';
import { Expiring } from '../components/expiring/Expiring';
import { MyMessages } from '../components/mymessages';
import Invoices from '../components/invoices';
import InvoicesAndPayments from '../components/invoicesandpayments';
import SubmitMeterReadings from '../components/meterreads/SubmitMeterReadings';
import BulkReadingsTool from '../components/bulkreadingstool';
import EnergyUsage from '../components/energyusage';
import DownloadHHData from '../components/energyusage/DownloadHHData';
import MakeAPayment from '../components/invoicesandpayments/';
import PaymentHistory from '../components/invoicesandpayments/PaymentHistory/';
import MPANs from '../components/mpans';
import DirectDebit from '../components/directdebit';
import { MeterReadsHistory } from '../components/meterreads/MeterReadsHistory';
import { ExclusiveOffers } from '../components/exclusiveoffers';
import { ContactSummary } from '../components/contactsummary';
import { SiteDetails } from '../components/sitedetails';
import UserDetails from '../components/userdetails';
import MovingPremises from '../components/movingpremises';
import NoMatch from '../components/nomatch';
import { InternalPage } from '../components/InternalPage';
import { LoaData } from '../components/loadata';
import BrokerTPI from '../components/brokertpi';

// Utils
import { documentTitle } from '../components/utils/text/text';
import { isOpusBrand } from '../utils/common';

const homePageRoutes = [
  { path: '/error', component: Error, isExact: true, isAvailable: true },
  { path: '/expiring', component: Expiring, isExact: true, isAvailable: true },
  { path: '/internal', component: InternalPage, isExact: true, isAvailable: true },
  { path: '/messages', component: MyMessages, isExact: true, isAvailable: true },
  { path: '/invoices', component: Invoices, isExact: true, isAvailable: true },
  { path: '/make-a-payment', component: MakeAPayment, isExact: false, isAvailable: true },
  { path: '/make-card-payment', component: MakeAPayment, isExact: true, isAvailable: true },
  { path: '/payment-history', component: PaymentHistory, isExact: true, isAvailable: true },
  { path: '/direct-debit', component: DirectDebit, isExact: false, isAvailable: isOpusBrand },
  { path: '/invoices-and-payments', component: InvoicesAndPayments, isExact: true, isAvailable: true },
  { path: '/energy-usage', component: EnergyUsage, isExact: true, isAvailable: true },
  { path: '/energy-usage/data', component: EnergyUsage, isExact: true, isAvailable: true },
  { path: '/energy-usage/downloadhhdata', component: DownloadHHData, isExact: true, isAvailable: true },
  // 65980 - Consent Functionality de-scoped.  Comment out temporarily.
  // { path: '/energy-usage/smartmeterconsent', component: SmartMeterConsent, isExact: true, isAvailable: true },
  { path: '/mpan-details', component: MPANs, isExact: true, isAvailable: true },
  { path: '/meter-reads', component: SubmitMeterReadings, isExact: true, isAvailable: true },
  { path: '/energy-usage/meter-reads', component: SubmitMeterReadings, isExact: true, isAvailable: true },
  { path: '/energy-usage/previous-meter-readings', component: MeterReadsHistory, isExact: true, isAvailable: true },
  { path: '/energy-usage/bulk-readings-tool', component: BulkReadingsTool, isExact: true, isAvailable: isOpusBrand },
  { path: '/contract-summary', component: ContactSummary, isExact: true, isAvailable: true },
  { path: '/site-details', component: SiteDetails, isExact: true, isAvailable: true },
  { path: '/user-details', component: UserDetails, isExact: true, isAvailable: isOpusBrand },
  { path: '/moving-premises', component: MovingPremises, isExact: false, isAvailable: true },
  { path: '/exclusive-offers', component: ExclusiveOffers, isExact: true, isAvailable: true },
  { path: '/loa', component: LoaData, isExact: true, isAvailable: true },
  { path: '/broker-tpi', component: BrokerTPI, isExact: true, isAvailable: true },
].filter(({ isAvailable }) => isAvailable);

export const Routes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    history.listen((location: any) => {
      localStorage.setItem('lastVisitedPath', location.pathname);
    });

    document.title = documentTitle();
  });

  return (
    <Switch>
      <Route exact path={path} component={Home} />

      {homePageRoutes.map(({ path, isExact, component: Component }) => (
        <Route key={path} path={`/home${path}`} exact={isExact} component={Component} />
      ))}

      <Redirect from='/' exact to='/home' />
      <Route path='*' component={NoMatch} />
    </Switch>
  );
};
