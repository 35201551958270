import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../common/styles/mixins';

const SORT_CODE = '20-00-00';
const DRAX_ACCOUNT_NUMBER = 63521575;
const DRAX_ACCOUNT_NAME = 'Drax Energy Solutions Ltd';

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  margin-bottom: 45px;
  flex-direction: column;

  .column {
    ${respondTo.desktopXXl`
      margin-left: auto;
      margin-right: auto;
    `};
  }

  ${respondTo.tablet`
     flex-direction: row;
  `};

  & > div {
    margin-right: 45px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 5px;

  ${respondTo.tablet`
      margin-bottom: 20px;
  `};
`;

const BankTransferDraxWidget = () => (
  <div className='panel d-flex flex-column'>
    <h4>Bank Transfer</h4>
    <StyledWrapper>
      <div>
        <StyledTitle>Account name</StyledTitle>
        <div className='primary'>{DRAX_ACCOUNT_NAME}</div>
      </div>
      <div className='column'>
        <StyledTitle>Account number</StyledTitle>
        <div className='primary'>{DRAX_ACCOUNT_NUMBER}</div>
      </div>
      <div className='column'>
        <StyledTitle>Sort code</StyledTitle>
        <div className='primary'>{SORT_CODE}</div>
      </div>
    </StyledWrapper>
    <p className='font-small ml-auto mr-auto'>Include reference numbers on your transfers to help us with tracking payments.</p>
  </div>
);

export default BankTransferDraxWidget;
