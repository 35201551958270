import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import * as variables from '../../../common/styles/variables';

export const NotificationBoxWrapper = styled.div`
  border 5px solid ${colors.unhealthy};
  border-radius: 25px;
  height: 100%;
  font-weight: 550;
  color: rgb(42, 55, 69);

  .notification-box {
    padding: 8px 8px 0px 16px;
  }

  .button {
    font-size: ${variables.fontSizeBase};
    float: right;
    margin: 0px 8px 8px 18px;
  }
`;
