// Modules
import React, { useState, useEffect, useContext } from 'react';

// Components
import { ButtonPrimary } from '../../../common/components';

// Utils
import { TagManager } from '../../../components/utils/analytics/TagManager';

// Api
import {
  MeterReadingBody,
  MeterReadingRegisterBody,
  deleteMeterReadsGridCache,
  submitMultiMeterReads,
} from '../../../common/api/meterReadsApi';

// Context
import { useAuthState } from '../../../providers/authProvider';
import { getSubmitMeterError } from '../../../helpers/getSubmitMetersErrors';
import { groupedReadHistory } from '../../../utils/groupReads';
import { MeterReading, RegisterRead, SubmitReadRow } from './SubmitReadRow';
import { SuccessSubmitModal } from '../../home/SubmitMeterReading/SuccessSubmitModal/SuccessSubmitModal';
import { FailureSubmitModal } from '../../home/SubmitMeterReading/FailureSubmitModal/FailureSubmitModal';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { temporary } from '../../../helpers/axiosWrapper';
import { isTpiUser } from '../../../helpers/tpiUser';

type SubmitReadAccordionContentProps = {
  productType: string;
  registers?: Array<Api.IMeterRead>;
  mpan: number;
  onSuccessModalAppear?: () => void;
  setIsLoading: (isLoading: boolean) => void;
};

const defaultProps = {
  registers: [],
};

export const SubmitReadAccordionContent = (props: SubmitReadAccordionContentProps) => {
  const { registers, mpan, onSuccessModalAppear = () => {}, setIsLoading } = props;
  const authContext = useAuthState();

  // State
  const [readingsValues, setReadingsValues] = useState<Array<MeterReading>>([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [groupedHistoryReadings, setGroupedHistoryReadings] =
    useState<Array<Api.IMeterReadHistory> | undefined>(undefined);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const readingSources: { [key: string]: string } = {
    'Derived': 'Estimate',
    'User Input': 'Customer',
    'Data Flow': 'Actual',
  };

  const clearReadings = () => {
    if (registers && registers?.length > 0) {
      const groupedReadings = groupedReadHistory(registers || []);
      setGroupedHistoryReadings(groupedReadings);

      if (groupedReadings && groupedReadings?.length > 0) {
        const meterReadings: Array<MeterReading> = [];

        groupedReadings.map((reading: Api.IMeterReadHistory) => {
          const meterReading: MeterReading = {
            meterSerialNumber: reading.meterSerialNumber,
            readings: [],
          };

          reading.registers.forEach((register: Api.IRegisters, cnt: number) => {
            const registerRead: RegisterRead = {
              register: `read${cnt + 1}`,
              reading: {
                newRead: '',
                isValid: false,
                registerId: ``,
                date: '',
              },
            };
            meterReading.readings.push(registerRead);
          });
          meterReadings.push(meterReading);
        });

        setReadingsValues(meterReadings);
      }
    }
  };

  const rowIsExpanded = readingsValues && readingsValues.length > 0;

  useEffect(() => {
    clearReadings();
  }, [registers]);

  useEffect(() => {
    if (Object.keys(readingsValues).length > 0) {
      setIsSubmitDisabled(false);

      readingsValues.map((reading: MeterReading) => {
        reading.readings.map((read: RegisterRead) => {
          if (!read.reading.isValid) {
            setIsSubmitDisabled(true);
            return;
          }

          if (read.reading.date === '') {
            setIsSubmitDisabled(true);
            return;
          }
        });
      });
    } else {
      setIsSubmitDisabled(true);
    }
  }, [readingsValues]);

  // Handlers

  const handleOnChange = (meterSerialNumber: string, registerRead: RegisterRead) => {
    const item = readingsValues.find(c => c.meterSerialNumber === meterSerialNumber);
    if (item) {
      const reading = item.readings.find(r => r.register === registerRead.register);
      if (reading) {
        reading.reading = {
          registerId: registerRead.reading.registerId,
          newRead: registerRead.reading.newRead,
          isValid: registerRead.reading.isValid,
          date: reading.reading.date,
        };
      }
    }

    setReadingsValues([...readingsValues]);
  };

  const handleDateChange = (meterSerialNumber: string, selectedDate: string) => {
    readingsValues.map((meterReading: MeterReading) => {
      meterReading.readings.map((reading: RegisterRead) => {
        reading.reading.date = selectedDate;
      });
    });

    setReadingsValues([...readingsValues]);
  };

  const handleSubmitClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    setIsLoading(true);

    var readings: Array<MeterReadingRegisterBody> = [];

    readingsValues.map((readingValue: MeterReading) => {
      const meterSerialNumber = readingValue.meterSerialNumber;

      readingValue.readings.map((value: RegisterRead) => {
        var reading: MeterReadingRegisterBody = {
          'meter-serial-number': meterSerialNumber,
          'register-id': value.reading.registerId, // TODO CHECK R1
          'reading': Number(value.reading.newRead),
        };

        readings.push(reading);
      });
    });
    const date = readingsValues[0].readings[0].reading.date;
    const claims = authContext.getClaims();
    const tpiUser = isTpiUser();

    var body: MeterReadingBody = {
      'mpan-core': mpan,
      'reading-date': date,
      'cot-reads': false,
      'created-by': tpiUser ? `CPS${claims.tpiReferenceId}` : `CPS${temporary.billingpartyid}`,
      'register-readings': readings,
    };

    let selectedCustomerAccount = userDetail.essCustomerId;
    const response = await submitMultiMeterReads(process.env.REACT_APP_API_URL, authContext, body);

    if (response && !response.data) {
      await deleteMeterReadsGridCache(selectedCustomerAccount, authContext);
      setShowSuccessModal(true);
      onSuccessModalAppear();
      clearReadings();
    } else {
      const error: string = response.data['error-details'].join('\n');
      const errorMsg = getSubmitMeterError(error);
      setErrorMessage(errorMsg);
      setShowFailureModal(true);
    }

    setIsLoading(false);

    TagManager.pushData({
      event: 'Submit meter read (MR page)',
      selectedDate: date,
    });
  };

  const onSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  return (
    <div>
      {rowIsExpanded && <div className='readingMessage'>*Read value must be equal or greater than previous read</div>}
      {groupedHistoryReadings?.map((register: Api.IMeterReadHistory, index: number) => (
        <SubmitReadRow
          key={index}
          meterSerialNumber={register.meterSerialNumber}
          index={index}
          registers={register.registers}
          readingsValues={readingsValues}
          handleOnChange={handleOnChange}
          handleDateChange={handleDateChange}
        ></SubmitReadRow>
      ))}
      {!groupedHistoryReadings && <p className='pt-4 pl-2'>No data available</p>}
      <div className='d-flex flex-column flex-row flex-wrap flex-nowrap'>
        <div>
          <div className='action-row'>
            {rowIsExpanded && (
              <ButtonPrimary
                onClick={handleSubmitClick}
                disabled={isSubmitDisabled}
                className=''
                title='Submit meter read'
              />
            )}
          </div>
        </div>
      </div>
      {showSuccessModal && <SuccessSubmitModal onClose={onSuccessModalClose} />}
      {showFailureModal && (
        <FailureSubmitModal
          errorMessage={errorMessage}
          onClose={() => {
            setShowFailureModal(false);
            clearReadings();
          }}
        />
      )}
    </div>
  );
};

SubmitReadAccordionContent.defaultProps = defaultProps;
