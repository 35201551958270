import * as colors from '../colors';
import * as variables from '../variables';

// ********** REMINDER **********
// Don't forget to update other theme file(s) if you're making any changes
export const themeOpus = {
  fontFamily: `'avertaregular', helvetica, sans-serif`,
  bg: {
    homeHeader: colors.darkBlue,
    secondary: colors.lightGrey,
  },
  text: {
    secondary: colors.razzmatazzPink,
  },
  title: {
    fontSize: variables.fontSizeLargeX,
    fontWeight: variables.fontWeightBold,
  },
  primary: {
    bg: colors.razzmatazzPink,
    border: colors.razzmatazzPink,
    color: colors.razzmatazzPink,
    hovercolor: colors.darkPink,
    btnBorderRadius: variables.btnBorderRadiusSquare,
  },
  secondary: {
    bg: colors.spearmintGreen,
    color: colors.spearmintGreen,
    border: colors.spearmintGreen,
    hovercolor: colors.spearmintGreen,
    btnBorderRadius: variables.btnBorderRadiusSquare,
  },
  terinary: {
    color: colors.darkGrey,
    border: colors.darkGrey,
    hovercolor: colors.darkGrey,
    btnBorderRadius: variables.btnBorderRadiusSquare,
  },
  externalLink: {
    color: colors.darkGrey,
    border: colors.darkGrey,
    hovercolor: colors.darkGrey,
    btnBorderRadius: variables.btnBorderRadiusSquare,
  },
  button: {
    bg: colors.razzmatazzPink,
    border: colors.razzmatazzPink,
    hoverBg: colors.pink,
    hoverBorder: colors.pink,
    textOnlyHoverColor: colors.razzmatazzPink,
    btnBorderRadius: variables.btnBorderRadiusSquare,
    fontWeight: 'bold',
  },
  formSelect: {
    selected: colors.razzmatazzPink,
  },
  dropdown: {
    bg: colors.white,
    border: colors.white,
    color: colors.darkGrey,
  },
  filterDropdown: {
    bg: colors.white,
    border: colors.darkGrey,
    color: colors.darkGrey,
    borderRadius: variables.btnBorderRadiusSquare,
  },
  loader: {
    borderBg: colors.pinkFaded,
    borderActive: colors.pink,
  },
  amount: {
    color: colors.darkBlue,
  },
  input: {
    bg: colors.inputBg,
    color: colors.manateeGray,
    border: colors.tiaraGray,
  },
  rowinput: {
    border: colors.pinkFaded,
  },
  icons: {
    default: colors.razzmatazzPink,
  },
  datepicker: {
    bg: colors.razzmatazzPink,
    text: colors.razzmatazzPink,
    arrow: colors.razzmatazzPink,
    range: colors.razzmatazzPink,
  },
  serviceMessages: {
    high: colors.error,
    medium: colors.warning,
    low: colors.success,
  },
};

// Check reminder on top of the file
