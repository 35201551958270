// Modules
import { useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import convertArrayToCSV from 'convert-array-to-csv';
import XMLJs from 'xml-js';

// Components
import { ConfigurableChart } from '.';

// Types
import { EssAccountsTypes } from '../../types/account';
import {
  LoadDataFiltersArg,
  LoadDRAXDataFunction,
  LoadOPUSDataFunction,
  LoadOPUSDataFunctionOptions,
  LoadDRAXDataFunctionOptions,
  LoadDataFunctionResult,
  DownloadEntitiesIds,
} from './ChartTypes';
import { Ranges } from '../../common/components/DateFilterModal';

// Api
import {
  loadConsumptionDrax,
  getElectMonthlyConsumptionByDateRangeOrLast12Months,
  getGasMonthlyConsumptionByDateRangeOrLast12Months,
  streamElectMonthlyConsumptionByDateRangeOrLast12Months,
  streamGasMonthlyConsumptionByDateRangeOrLast12Months,
} from '../../common/api/dataApi';

// Context
import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

// Utils
import { fillEmptyMonths } from './ChartUtils';
import { downloadCSV } from '../../helpers/downloadCSV';
import { downloadXML } from '../../helpers/downloadXML';
import { getDateFromRange } from '../../helpers/dateHelper';
import { isDraxBrand, isOpusBrand } from '../../utils/common';

export function OverviewGraph(props: any) {
  // Ref
  const draxConsumptionResponseRef = useRef<Api.IDraxConsumptionResponse | Api.IDraxHHConsumptionResponse | null>(null);

  // Context
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  // Handlers
  const loadElecDataSSP = (customerId: string, filters: LoadDataFiltersArg, startDate?: string, endDate?: string) =>
    getElectMonthlyConsumptionByDateRangeOrLast12Months(
      process.env.REACT_APP_API_URL,
      authContext,
      customerId,
      filters,
      startDate,
      endDate
    ).then(response => {
      let result: LoadDataFunctionResult = {
        accountsFilters: [],
        customerFilters: [],
        sitesFilters: [],
        data: [],
      };

      if (response?.success) {
        if (response.data?.accountsFilters) {
          result.accountsFilters = response.data.accountsFilters;
        }
        if (response.data?.customerFilters) {
          result.customerFilters = response.data.customerFilters;
        }
        if (response.data?.elecMonthlyConsumptions) {
          result.data = response.data?.elecMonthlyConsumptions;
        }
      } else {
        const message = 'API error calling: elecmonthlyconsumptionbydaterangeorlast12months';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      }

      return result;
    });
  const loadGasDataSSP = (customerId: string, filters: LoadDataFiltersArg, startDate?: string, endDate?: string) =>
    getGasMonthlyConsumptionByDateRangeOrLast12Months(
      process.env.REACT_APP_API_URL,
      authContext,
      customerId,
      filters,
      startDate,
      endDate
    ).then(response => {
      let result: LoadDataFunctionResult = {
        accountsFilters: [],
        customerFilters: [],
        sitesFilters: [],
        data: [],
      };

      if (response?.success) {
        if (response.data?.accountsFilters) {
          result.accountsFilters = response.data.accountsFilters;
        }
        if (response.data?.customerFilters) {
          result.customerFilters = response.data.customerFilters;
        }
        if (response.data?.gasMonthlyConsumption) {
          result.data = response.data?.gasMonthlyConsumption;
        }
      } else {
        const message = 'API error calling: gasmonthlyconsumptionbydaterangeorlast12months';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      }

      return result;
    });
  const loadOPUSData: LoadOPUSDataFunction = async (options: LoadOPUSDataFunctionOptions) => {
    let result: LoadDataFunctionResult = {
      accountsFilters: [],
      customerFilters: [],
      sitesFilters: [],
      data: [],
    };
    const { type, customerId, filters = {}, startDate, endDate } = options;

    if (type === EssAccountsTypes.Electricity) {
      const elecResult = await loadElecDataSSP(customerId, filters, startDate, endDate);

      result = { ...result, ...elecResult };
    } else if (type === EssAccountsTypes.Gas) {
      const gasResult = await loadGasDataSSP(customerId, filters, startDate, endDate);

      result = { ...result, ...gasResult };
    } else {
      console.error(`Received unknown product type: "${type}"`);
    }
    return result;
  };

  const loadDRAXData: LoadDRAXDataFunction = async (options: LoadDRAXDataFunctionOptions) => {
    let result: LoadDataFunctionResult = {
      accountsFilters: [],
      customerFilters: userDetail.customerAccounts.map(customer => ({
        essCustomerID: customer.essAccountID,
        customerName: customer.accountName,
      })),
      sitesFilters: [],
      data: [],
    };
    const { entities, startDate, endDate, groupingInterval, isInitialLoad } = options || {};

    try {
      if (!isInitialLoad || (isInitialLoad && result.customerFilters.length === 1)) {
        // Get consumption data
        const consumptionResponse = await loadConsumptionDrax(
          process.env.REACT_APP_API_URL,
          authContext,
          entities.customerId,
          endDate,
          startDate,
          groupingInterval,
          undefined,
          entities.customerId
        );

        // Consumption response
        if (consumptionResponse?.success) {
          draxConsumptionResponseRef.current = consumptionResponse.data;
          // Save consumption data to result object
          result.data = consumptionResponse.data?.consumptions || [];

          if (groupingInterval === 'Month') {
            result.data = fillEmptyMonths(startDate, endDate, result.data);
          }
        } else {
          const message = 'API error calling: elecmonthlyconsumptionbydaterangeorlast12months';

          console.error(message);
          toast.error(message, { position: 'bottom-right' });
        }
      }
    } catch (error) {
      console.error('Tried to fetch DRAX consumption data but caught error.', error);
      toast.error('DRAX consumption unexpected error occurred', { position: 'bottom-right' });
    } finally {
      return result;
    }
  };

  const downloadFile = async (
    fileType: 'csv' | 'xml',
    dataType: EssAccountsTypes,
    date: Ranges,
    entitiesIds: DownloadEntitiesIds
  ) => {
    try {
      const [startDate, endDate] = getDateFromRange(date);
      const { customerId, essAccountId } = entitiesIds;
      let result = null;

      if (isOpusBrand) {
        // Load graph data in selected format with current filters applied
        if (dataType === EssAccountsTypes.Gas) {
          result = await streamGasMonthlyConsumptionByDateRangeOrLast12Months(
            process.env.REACT_APP_API_URL,
            authContext,
            customerId,
            essAccountId!,
            startDate,
            endDate,
            fileType
          );
        } else if (dataType === EssAccountsTypes.Electricity) {
          result = await streamElectMonthlyConsumptionByDateRangeOrLast12Months(
            process.env.REACT_APP_API_URL,
            authContext,
            customerId,
            essAccountId!,
            startDate,
            endDate,
            fileType
          );
        }
      } else if (isDraxBrand && !!draxConsumptionResponseRef.current) {
        if ('groupingInterval' in draxConsumptionResponseRef.current) {
          const { endDate, entityId, startDate, entityType, consumptions, groupingInterval } =
            draxConsumptionResponseRef.current;
          let consumptionData: any[] = consumptions || [];

          if (groupingInterval === 'Month') {
            consumptionData = fillEmptyMonths(endDate, startDate, consumptionData);
          }

          // Fill out all items with additional data
          consumptionData = consumptionData.map(consumption => ({
            customerId,
            actualConsumption: consumption.actualConsumption,
            estimatedConsumption: consumption.estimatedConsumption,
            totalConsumption: consumption.totalConsumption,
            intervalEnd: consumption.intervalEnd,
            intervalStart: consumption.intervalStart,
            consumptionSource: consumption.сonsumptionSource,
            entityId: entityId,
            entityType: entityType,
            groupingInterval: groupingInterval,
            endDate: endDate,
            startDate: startDate,
          }));

          if (fileType === 'csv') {
            const csv = convertArrayToCSV(consumptionData);
            result = csv.toString();
          } else if (fileType === 'xml') {
            const consumptionCollection: Consumption.IConsumptionCollection = {
              consumption: {
                elecMonthlyConsumption: consumptionData,
              },
            };

            result = XMLJs.js2xml(consumptionCollection, { compact: true, spaces: 2 });
          }
        }

        if (result !== null) {
          const fileName = `${customerId}-Overview-Elec-Data-${endDate}-${startDate}`;

          // Download file
          if (fileType === 'csv') {
            downloadCSV(result, fileName);
          } else if (fileType === 'xml') {
            downloadXML(result, fileName);
          }
        }
      }
    } catch (error) {
      const message = `Failed to download overview graph consumption data as "${fileType}" file`;

      console.error(message, error);
      toast.error(message);
    }
  };

  return <ConfigurableChart loadOPUSData={loadOPUSData} loadDRAXData={loadDRAXData} downloadFile={downloadFile} askForCustomerAccount={props.askForCustomerAccount} />;
}
