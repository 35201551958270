import { css } from 'styled-components';

export const buttonStyles = css`
  .btn-primary {
    background-color: ${({ theme }) => theme.button.bg} !important;
    border: 2px solid ${({ theme }) => theme.button.border} !important;
    border-radius: ${({ theme }) => theme.button.btnBorderRadius} !important;
    font-weight: bold;

    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.button.hoverBg} !important;
      border: 2px solid ${({ theme }) => theme.button.hoverBorder} !important;
    }
  }
`;
