// Modules
import styled from 'styled-components';

// Styles
import { fontSizeMedium, fontSizeSmall } from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const ChartWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;
  ${mixins.scrollbars}

  .canvasWrapper {
    position: relative;

    .units {
      height: 100%;
      position: absolute;
      left: 0;
      font-size: ${fontSizeSmall};
      ${mixins.respondTo.mobile`
        font-size: ${fontSizeMedium};
      `}

      span {
        transform: rotate(-90deg);
      }
    }
  }
`;
