// Modules
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { useAuthState } from '../../../providers/authProvider';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../../reducer/gridReducer/gridReducer';

// Types
import { REVERSED_DATE_FORMAT_HYPHEN } from '../../../common/constants';
import { EssAccountsTypes } from '../../../types/account';

// Api
import {
  getMeterReadsReport,
  MetersFilterObject,
  getMeteringPoints,
  getCustomerMeterReads,
} from '../../../common/api/meterReadsApi';

// Components
import { MeterReadingsRow } from '../MeterReadingsRow';
import { GridFilter } from '../../gridfilter/GridFilter';
import { Loading, NoResults, TableHeader, FilterRowWrapper, GridTypeSelector, Icon } from '../../../common/components';
import { StyledTitle } from '../../../common/components';

// Styles
import { MeterReadsGridWrapper } from '../MeterReadsGrid.styles';

// Utils
import { TagManager } from '../../utils/analytics/TagManager';
import { getInitialType } from '../../../utils/getInitialTypes';
import { isDraxBrand, isOpusBrand } from '../../../utils/common';
import { MeterReadsFilterAdditionalItems } from './MeterReadsFilterAdditionalItems';
import MeterReadsHistoryPeriodModal from './MeterReadsHistoryPeriodModal';
import { formatSites, formatMpans, getActiveMeterRegisters } from '../helper';
import { globalApiParams } from '../../../common/api/globals';
import { getSelectedCustomerId } from '../../../helpers/customer';
import { iconsToUse } from '../../../common/styles/themes';
import * as variables from '../../../common/styles/variables';

const draxHeaders = [
  {
    title: '',
    dataValue: 'warningIcon',
    enableSort: false,
  },
  {
    title: 'MPAN',
    dataValue: 'MpanCore',
    enableSort: true,
  },
  {
    title: 'Site reference',
    dataValue: 'SiteReference',
    enableSort: true,
  },
  {
    title: 'Site name',
    dataValue: 'site-name',
    enableSort: false,
  },
  {
    title: 'Site address',
    dataValue: 'site-address',
    enableSort: false,
  },
  {
    title: '',
  },
  {
    title: '',
  },
];

const opusHeaders = [
  {
    title: 'Site',
    dataValue: 'CustomerSiteReference',
  },
  {
    title: 'Address',
    dataValue: 'siteAddress',
  },
  {
    title: 'MPAN/MPRN',
    dataValue: 'mpan',
  },
  {
    title: '',
  },
];

export const MeterReadsHistory = () => {
  // Context
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  // State
  const [sortDetails] = useState<Common.ISort>({ field: 'MpanCore', order: 'ASC' });
  const [gridTypes, setGridTypes] = useState<EssAccountsTypes[]>();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [isDownloadModalOpened, setIsDownloadModalOpened] = useState(false);
  const [selectedFuelIndex, setSelectedFuelIndex] = useState<number>(0);
  const [resetFuelDropdownState, setResetFuelDropdownState] = useState(false);
  const [selectedSiteIndex, setSelectedSiteIndex] = useState<number>(-1);
  const [resetSiteDropdownState, setResetSiteDropdownState] = useState(false);
  const [meterRegisters, setMeterRegisters] = useState<Array<Api.IMeterRead>>([]);
  const [startDateValue, setStartDateValue] = useState<DateObject>();
  const [endDate, setEndDateValue] = useState<DateObject>();
  const [isWarningIcon, setIsWarningIcon] = useState<boolean>(false);

  const scrollableTable = useRef<any>();

  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(userDetail.hasElectricity, userDetail.hasGas),
    data: {},
    initialSortField: '',
    initialSortOrder: 'DESC',
    currentSortField: '',
    currentSortOrder: 'DESC',
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userDetail, true),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  // When navigating directly to url
  useEffect(() => {
    if (userDetail.essCustomerId) {
      initialState.currentType = getInitialType(userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable);
      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable]);

  useEffect(() => {
    if (!state.selectedCustomerId) return;
    loadSitesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedCustomerId]);

  useEffect(() => {
    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.currentType, state.selectedCustomerId, state.currentFilter, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentType,
    state.page,
    state.currentFilter,
    state.currentSortField,
    state.currentSortOrder,
    state.selectedCustomerId,
  ]);

  const loadSitesData = async () => {
    const filters: MetersFilterObject = {
      'hh-or-nhh': 'NHH',
    };

    const sites = await getMeteringPoints(
      process.env.REACT_APP_API_URL,
      authContext,
      state.selectedCustomerId,
      globalApiParams.maxFilterPageSize,
      state.page,
      'Active',
      undefined,
      filters
    );

    const payload = {
      essCustomerId: state.selectedCustomerId,
      data: {
        customerFilters: [],
        accountFilters: [],
        sitesFilters: formatSites(sites),
        mpansFilters: formatMpans(sites),
      },
    };

    dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload });
  };

  const loadData = async (
    gridType: string,
    customerId: string,
    currentFilter?: Common.IFilter | null,
    sort?: Common.ISort
  ) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    let promise = null;
    const filters: MetersFilterObject = {};

    if (state.selectedSiteReference) {
      filters.SiteReference = state.selectedSiteReference;
    }
    if (state.selectedMpanMprn) {
      filters.MpanCore = state.selectedMpanMprn;
    }

    filters.HhOrNhh = 'NHH';

    if (gridType === EssAccountsTypes.Electricity) {
      const meteringPoints = await getCustomerMeterReads(
        process.env.REACT_APP_API_URL,
        authContext,
        customerId,
        globalApiParams.maxFilterPageSize,
        state.page,
        sort,
        filters
      );

      promise = new Promise((resolve, reject) => {
        const gridData: Array<any> = meteringPoints.data.map((item: Api.IMeterReadsMissing) => {
          const meteringPointsData = {
            'mpan-core': item['mpan-core'],
            'site-reference': item['site-reference'],
            'site-name': item['site-name'],
            'site-address': `${item['site-address']}, ${item['site-postcode']}`,

            'warningIcon': item['missing-read'] || item['failure-to-obtain-read'],
          };

          if (meteringPointsData.warningIcon) {
            setIsWarningIcon(true);
          }

          return meteringPointsData;
        });

        resolve(gridData);
      });
    }

    if (promise instanceof Promise) {
      promise
        .then(result => {
          if (result) {
            dispatch({
              type: GRID_ACTIONS.ADD_DATA,
              payload: { data: result, page: state.page },
            });
          } else {
            console.error(`Meter reads request returned errors.`);
          }
        })
        .catch(error => {
          console.error(`Failed to get reads for ${gridType} product type.`, error);
        })
        .finally(() => {
          dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
        });
    }
  };

  useEffect(() => {
    let types: EssAccountsTypes[] = [];
    if (userDetail) {
      if (userDetail.hasElectricity) types.push(EssAccountsTypes.Electricity);
      if (userDetail.hasGas) types.push(EssAccountsTypes.Gas);
      if (userDetail.hasRenewable) types.push(EssAccountsTypes.Renewables);
    }
    setGridTypes(types);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.hasRenewable, userDetail.hasElectricity, userDetail.hasGas]);

  const clearSelection = () => {
    setSelectedRow(null);
  };

  // Handlers
  const handleTypeChange = (product: EssAccountsTypes) => {
    clearSelection();
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: product });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    clearSelection();
    setIsWarningIcon(false);
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    clearSelection();
    const essAccountId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId: essAccountId } });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    clearSelection();
    const siteReference = option ? option.value : '';
    dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: siteReference } });
  };

  const handleMpanMprnFilterChange = (option: Common.IOption) => {
    clearSelection();
    const mprnMpan = option ? option.value : '';
    dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: mprnMpan } });
  };

  const handleClearAllClick = () => {
    clearSelection();
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handleRowSelect = (index: number) => {
    if (index !== selectedRow) {
      setSelectedRow(index);
    } else {
      setSelectedRow(null);
    }
  };

  const onClickSort = (e: any) => {
    e.preventDefault();
    clearSelection();
    const typeId = e.currentTarget.dataset.value || '';
    let details = sortDetails;
    if (details.field === typeId) {
      if (details.order === 'ASC') details.order = 'DESC';
      else details.order = 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: details });
  };

  const onFuelTypeChange = (_: any, index: number) => {
    setSelectedFuelIndex(index);
  };
  const onSiteChange = (_: any, index: number) => {
    setSelectedSiteIndex(index);
  };
  const onClose = () => setIsDownloadModalOpened(false);

  const onDownloadReport = async (e: any, fileType: string) => {
    e.preventDefault();

    if (!startDateValue || !endDate) {
      toast.error('Invalid date range', { position: 'bottom-right' });
      return;
    }

    console.log('Start Date: ' + startDateValue.format(REVERSED_DATE_FORMAT_HYPHEN));
    console.log('End Date: ' + endDate.format(REVERSED_DATE_FORMAT_HYPHEN));

    if (fileType === 'XML') {
      TagManager.pushData({
        event: 'Download meter history xml',
      });
    }

    if (fileType === 'CSV') {
      TagManager.pushData({
        event: 'Download meter history csv',
      });
    }

    await getMeterReadsReport(
      process.env.REACT_APP_API_URL,
      authContext,
      state.selectedCustomerId,
      fileType,
      startDateValue,
      endDate
    );

    setIsDownloadModalOpened(false);
  };

  const handleSiteRefBlur = (inputValue: string) => {};

  const handleExpandRow = async (mpan: string, siteReference: string) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    TagManager.pushData({
      event: 'View Previous Meter Reads',
    });

    setMeterRegisters([]);

    const activeMeterRegisters = await getActiveMeterRegisters(
      authContext,
      state.selectedCustomerId,
      mpan,
      siteReference,
      true
    );
    setMeterRegisters(activeMeterRegisters);

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  };

  const fuelOptions = Object.keys(EssAccountsTypes).map(item => ({
    display: item,
    value: item,
  }));
  const sitesOptions: any[] = []; // TODO: Load options from API

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const headers = isDraxBrand ? draxHeaders : opusHeaders;

  return (
    <MeterReadsGridWrapper>
      <FilterRowWrapper>
        <StyledTitle>Meter read history</StyledTitle>
      </FilterRowWrapper>
      <GridFilter
        showSites={true}
        showDate={false}
        showMpanMprn={true}
        showAccount={isOpusBrand}
        showClearAll={true}
        showNhhAndMixed={true}
        showMeterWarning={isWarningIcon}
        customerData={state.data?.customers}
        accountData={state.data?.accounts}
        siteData={state.data?.sites}
        selectedType={state.currentType}
        mpanMprnData={state.data?.mpans}
        handleClearAllClick={handleClearAllClick}
        handleSiteFilterChange={handleSiteFilterChange}
        handleAccountFilterChange={handleAccountFilterChange}
        handleMpanMprnFilterChange={handleMpanMprnFilterChange}
        handleCustomerFilterChange={handleCustomerFilterChange}
        // Descoped from release
        renderAdditionalListItems={() =>
          MeterReadsFilterAdditionalItems(() => {
            setIsDownloadModalOpened(true);
            setStartDateValue(undefined);
          })
        }
      />

      {isWarningIcon && (
        <div className='containerText d-inline-block d-md-none'>
          <div className='rightText'>
            <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> Indicates that we haven't received a read
            for this meter recently
          </div>
        </div>
      )}

      <GridTypeSelector initialType={state.currentType} types={gridTypes} handleTypeChange={handleTypeChange} />

      {!state.isLoading && state.gridData && state.gridData.length === 0 && (
        <NoResults
          className='pt-3'
          title=''
          description='We do not show historic readings for the meter you have linked to the account you chose<p style="padding-top: 20px"><a class="usageLink" href="/home/energy-usage/data">Click here to view your usage </a></p>'
        />
      )}

      {state.gridData && state.gridData.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={state.gridData.length}
            next={handlePagingChange}
            hasMore={state.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <div
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1'
            >
              <table className='list table plain text-left'>
                <TableHeader
                  headers={headers}
                  isSelectable={false}
                  isAllSelected={false}
                  sortDetails={sortDetails}
                  onClickSort={onClickSort}
                  handleSelectAll={() => {}}
                />
                <tbody>
                  {state.gridData.map((item, index) => (
                    <MeterReadingsRow
                      key={index}
                      warningIcon={item.warningIcon}
                      mpan={item['mpan-core']}
                      siteId={item?.essSiteID}
                      registers={meterRegisters}
                      onSiteRefBlur={handleSiteRefBlur}
                      siteReference={item['site-reference']}
                      siteName={item['site-name']}
                      siteAddress={item['site-address']}
                      expandRow={() => handleExpandRow(item['mpan-core'], item['site-reference'])}
                      isSelected={index === selectedRow}
                      productType={state.currentType}
                      selectRow={() => {
                        if (selectedRow !== index) {
                          handleRowSelect(index);
                        }
                      }}
                      columnsNumber={headers.length}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </>
      )}

      {state.isLoading && <Loading overlay nowait />}

      <MeterReadsHistoryPeriodModal
        isOpusBrand={isOpusBrand}
        show={isDownloadModalOpened}
        onClick={(e, fileType) => onDownloadReport(e, fileType)}
        onClose={onClose}
        startDate={startDateValue}
        handleStartDateChange={e => setStartDateValue(e as DateObject)}
        handleEndDateChange={e => setEndDateValue(e as DateObject)}
        fuelOptions={fuelOptions}
        selectedFuelIndex={selectedFuelIndex}
        handleFuelTypeChange={onFuelTypeChange}
        resetFuelDropdownState={resetFuelDropdownState}
        afterResetFuelDropdownStateCallback={() => setResetFuelDropdownState(false)}
        sitesOptionsEnabled={!!sitesOptions.length}
        sitesOptions={sitesOptions}
        selectedSiteIndex={selectedSiteIndex}
        handleSiteChange={onSiteChange}
        resetSiteDropdownState={resetSiteDropdownState}
        afterResetSiteDropdownStateCallback={() => setResetSiteDropdownState(false)}
      />
    </MeterReadsGridWrapper>
  );
};
