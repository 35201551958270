import { css } from 'styled-components';
import * as variables from '../variables';
import { respondTo } from '../mixins';

export const widgetStyles = css`
  .widget {
    background-clip: content-box;
    display: flex;

    padding-bottom: ${variables.spacingSmall};

    ${respondTo.tablet`
      padding-bottom: ${variables.spacingLarge};
    `};

    .innerWrapper {
      border-radius: ${variables.globalRadius};
      margin: ${variables.spacingSmall} 0;
      padding: ${variables.spacingSmall} 0;
    }
  }

  .widgetNoFlex {
    background-clip: content-box;

    padding-bottom: ${variables.spacingSmall};

    ${respondTo.tablet`
      padding-bottom: ${variables.spacingLarge};
    `};

    .innerWrapper {
      border-radius: ${variables.globalRadius};
      margin: ${variables.spacingSmall} 0;
      padding: ${variables.spacingSmall} 0;
    }
  }
`;
