import styled from 'styled-components';

import * as colors from '../../../../common/styles/colors';
import * as variables from '../../../../common/styles/variables';
import * as mixins from '../../../../common/styles/mixins';
import { respondTo } from '../../../../common/styles/mixins';

export const BlockWrapper = styled.div`
  ${mixins.boxShadowPrimary};
  background-color: ${colors.lightGrey};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingSmall};
  margin-bottom: ${variables.spacingMedium};

  ${respondTo.desktop`
    padding: ${variables.spacingMedium};
  `};

  h4 {
    font-size: ${variables.fontSizeLarge};
    margin: 0 0 ${variables.spacingSmall};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary.bg};
  }

  .formRowWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: ${variables.spacingMedium};

    ${respondTo.desktop`
      width: 46%;
    `};
  }
`;

export const AccountsWrapper = styled(BlockWrapper)`
  .account-summary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: ${variables.spacingMedium};

    ${respondTo.tablet`
      flex-direction: row;
      align-items: center;
    `};

    & > div {
      padding-right: ${variables.spacingMedium};
    }
  }

  .single-acc-details {
    display: flex;
    flex-direction: column;

    ${respondTo.tablet`
      flex-direction: row;
    `};

    & > div {
      width: 100%;
      margin-bottom: ${variables.spacingSmall};

      ${respondTo.tablet`
        width: 50%;
        margin: 0;
      `};

      ${respondTo.desktop`
        width: 30%;
      `};
    }
  }

  .single-acc-payment-info {
    background-color: ${colors.white};
    border-radius: ${variables.globalRadius};
    padding: ${variables.spacingSmall} ${variables.spacingMedium};

    h4 {
      margin: 0;
    }

    .balance-number {
      font-weight: bold;
      font-size: ${variables.fontSizeLargeXXL};
    }

    .balance-info {
      font-size: ${variables.fontSizeMedium};
    }
  }

  .column-label {
    color: ${({ theme }) => theme.text.secondary};
  }

  .table-header {
    display: flex;
    margin-bottom: ${variables.spacingSmall};
  }

  .account-table {
    margin: ${variables.spacingMedium} 0;

    .column-value {
      padding-right: ${variables.spacingMedium};

      &.col-balance {
        padding: ${variables.spacingXSmall} 0;
      }
    }

    ${respondTo.desktop`
      .col-balance.column-value {
        padding-right: ${variables.spacingMedium};
      }
    `};
  }

  .multi-acc-details {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: ${variables.spacingSmall};
    padding-bottom: ${variables.spacingXSmall};
    border-bottom: 1px solid #ddd;

    ${respondTo.desktop`
      align-items: center;
      border-bottom: none;
      padding: 0;
    `};
  }

  .col-account {
    width: 50%;

    ${respondTo.desktop`
      width: 25%;
    `};

    ${respondTo.desktopXl`
      width: 15%;
    `};
  }

  .col-account-name {
    width: 50%;

    ${respondTo.desktop`
      width: 25%;
    `};

    ${respondTo.desktopXl`
      width: 18%;
    `};
  }

  .col-balance {
    width: 100%;

    ${respondTo.desktop`
      width: 50%;
    `};

    ${respondTo.desktopXl`
      width: 35%;
    `};
  }

  .col-balance.column-label {
    display: none;

    ${respondTo.desktop`
      display: block;
    `};
  }

  .label-mobile.column-label {
    display: block;
    margin: ${variables.spacingXSmall} 0;

    ${respondTo.desktop`
      display: none;
    `};
  }

  .balance-wrapper {
    background-color: ${colors.white};
    border-radius: ${variables.inputRadius};
    padding: ${variables.spacingXSmall} ${variables.spacingSmall};
    display: flex;
    justify-content: space-between;
  }
`;

export const BillingDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo.desktop`
    flex-direction: row;
    justify-content: space-between;

    .contactInfo {
      padding-left: ${variables.spacingMedium};
    }
  `};

  & > div {
    ${respondTo.desktop`
      width: 46%;
    `};

    .formRowWrapper {
      width: 100%;
    }
  }

  .contactInfo {
    margin-bottom: ${variables.spacingMedium};
  }
`;

export const GuaranteeAdditional = styled.p`
  font-size: ${variables.fontSizeMedium};
  margin-bottom: ${variables.spacingSmall};
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: ${variables.spacingMedium};
`;

export const ActionRowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .submit-btn {
    min-width: 12.5rem;
    max-width: 100%;
  }
`;
