import { Icon } from '../../common/components';
import { iconsToUse } from '../../common/styles/themes';
import { formatDate } from '../utils/date/date';
import { ContentSectionWrapper } from './SiteDetailsGrid.styles';

type CustomerDetailsProps = {
  customerDetails: Api.ICustomerDetails | null;
  balanceDetails: Api.ICustomerBalances | null;
  hasDirectDebit: boolean;
};

const defaultProps = {
  customerDetails: null,
};

export const CustomerDetails = ({ customerDetails, balanceDetails, hasDirectDebit }: CustomerDetailsProps) => (
  <ContentSectionWrapper className='mt-4'>
    <div className='customer-details-block'>
      <div className='customer-detail-row'>
        <span>Customer name: </span>
        <div>{customerDetails?.customerName}</div>
      </div>

      <div className='customer-detail-row'>
        <span>Business address: </span>
        <div>{customerDetails?.businessAddress}</div>
      </div>

      <div className='customer-detail-row'>
        <span>Registered company number: </span>
        <div>{customerDetails?.registeredCompanyNumber}</div>
      </div>

      <div className='customer-detail-row'>
        <span>Customer account number: </span>
        <div>{customerDetails?.customerAccountNumber}</div>
      </div>

      {!hasDirectDebit && (
        <div className='customer-detail-row'>
          <span>Active Direct Debit set up: </span>
          <div>
            <Icon className={'pr-1'} icon={iconsToUse.warning} size={'27px'} />
            Missing
          </div>
        </div>
      )}
    </div>
    <div className='customer-details-block'>
      <div className='customer-detail-row'>
        <span>Account balance: </span>
        {customerDetails?.customerAccountNumber && (
          <div>
            £
            {balanceDetails?.balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            - As of {formatDate(new Date())}
          </div>
        )}
      </div>

      <div className='customer-detail-row'>
        <div>{balanceDetails?.['balance-overdue'] != 0 ? 'Overdue: ' : ''}</div>
        {customerDetails?.customerAccountNumber && (
          <div>
            {balanceDetails?.['balance-overdue'] != 0 ? (
              <Icon className={'pr-1'} icon={iconsToUse.warning} size={'27px'} />
            ) : (
              ''
            )}
            {balanceDetails?.['balance-overdue'] != 0
              ? '£' +
                balanceDetails?.['balance-overdue'].toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ''}
          </div>
        )}
      </div>

      {/* Hidden for:  44983 */}
      {/* <div className='customer-detail-row'>
        <div>{balanceDetails?.['undisputed-balance-overdue'] != 0 ? 'Disputed: ' : ''}</div>
        <div>{balanceDetails?.['undisputed-balance-overdue'] != 0 ? '£' + balanceDetails?.['undisputed-balance-overdue'] : ''}</div>
      </div> */}
    </div>
  </ContentSectionWrapper>
);

CustomerDetails.defaultProps = defaultProps;
