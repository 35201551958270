import { useContext, useEffect, useState, ReactElement, useReducer } from 'react';

import { useAuthState } from '../../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../../context/userDetail/userDetailContext';
import { getAccountForDDSetup } from '../../../../common/api/accountApi';
import { ChooseAccount } from './ChooseAccount';
import { GridFilter } from '../../../gridfilter/GridFilter';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../../../reducer/gridReducer/gridReducer';
import { EssAccountsTypes } from '../../../../types/account';
import { getInitialType } from '../../../../utils/getInitialTypes';
import { GridTypeSelector } from '../../../../common/components';

type DDChooseAccountProps = {
  stepsBar: ReactElement;
};

export const DDChooseAccount = ({ stepsBar }: DDChooseAccountProps) => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [gridTypes, setGridTypes] = useState<string[]>();

  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(userDetail.hasElectricity, userDetail.hasGas),
    data: {},
    initialSortField: 'EssAccountID',
    initialSortOrder: 'DESC',
    currentSortField: 'EssAccountID',
    currentSortOrder: 'DESC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: userDetail.essCustomerId,
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  useEffect(() => {
    let types = [];
    if (userDetail.hasElectricity) types.push(EssAccountsTypes.Electricity);
    if (userDetail.hasGas) types.push(EssAccountsTypes.Gas);
    setGridTypes(types);
  }, [userDetail.hasElectricity, userDetail.hasGas]);

  const filterRowsByProductType = (data: Api.IAccountsForDDSetupData[], product: string) => {
    return data?.length ? data.filter(item => item.product === product) : [];
  };

  const getAccountsData = async (essCustomerId: string, essAccountId: string | null, sort?: Common.ISort) => {
    const { success, data }: Common.IResult<Api.IAccountsForDDSetupPagedData> = await getAccountForDDSetup(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      essAccountId,
      state.page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (success && data) {
      // TODO:  TEMP fix due to pluralisation differences in the API
      const tmp = {
        customerFilters: data.customerFilters,
        accountFilters: data.accountsFilters.filter(
          (option: Api.IDDAccountFilterOption) => option.product === state.currentType
        ),
        sitesFilters: data.sitesFilters,
      };
      dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data: tmp } });
      //
      // TODO - AFTER API FIX REPLACE THE ABOVE WITH
      //dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data } });

      return data.records;
    }

    return [];
  };

  const clearData = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
  };

  const loadData = async (gridType: string, customerId: string, accountId: string | null, sort?: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const rawData = await getAccountsData(customerId, accountId, sort);

    if (rawData) {
      const filteredData = filterRowsByProductType(rawData, gridType);
      dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: filteredData, page: state.page } });
    } else {
      clearData();
    }
  };

  useEffect(() => {
    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.currentType, userDetail.essCustomerId, state.selectedEssAccountId || null, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentType, state.page, state.selectedEssAccountId, state.currentSortField, state.currentSortOrder]);

  const handleTypeChange = (type: string) => {
    // clearData();
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: type });
  };

  const handleSortChange = (sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    const essAccountId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId: essAccountId } });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    // NOT IMPLEMENTED
  };

  const handleClearAll = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  return (
    <>
      <GridFilter
        customerData={state.data?.customers}
        accountData={state.data?.accounts}
        siteData={state.data?.sites}
        selectedType={state.currentType}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleAccountFilterChange={handleAccountFilterChange}
        handleSiteFilterChange={handleSiteFilterChange}
        handleClearAllClick={handleClearAll}
        showSites={false}
        showClearAll={false}
      ></GridFilter>

      <GridTypeSelector
        initialType={state.currentType}
        types={gridTypes}
        handleTypeChange={handleTypeChange}
      ></GridTypeSelector>

      {stepsBar}

      <ChooseAccount
        selectedType={state.currentType}
        handleSortChange={handleSortChange}
        handlePagingChange={handlePagingChange}
        gridData={state.gridData}
        isLoading={state.isLoading}
        currentSortField={state.currentSortField}
        currentSortOrder={state.currentSortOrder}
        hasMoreData={state.hasMoreData}
      />
    </>
  );
};
