const Tick = ({ width, height, className, fillColor, style }: TickProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx="16" cy="16" r="10" fill="#1C8913"/>
    <path d="M13.9497 18.7264L11.6133 16.3865C11.2416 16.0143 10.6506 16.0143 10.2788 16.3865C9.90706 16.7586 9.90706 17.3503 10.2788 17.7225L13.2729 20.7209C13.6446 21.093 14.2452 21.093 14.6169 20.7209L21.7212 13.6151C22.0929 13.2429 22.0929 12.6513 21.7212 12.2791C21.3494 11.907 20.7584 11.907 20.3867 12.2791L13.9497 18.7264Z" fill="white"/>
    </svg>

);

export type TickProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

Tick.defaultProps = {
  width: 32,
  height: 32,
};

export default Tick;
