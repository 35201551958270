import React, { ReactNode, useState } from 'react';
import { UserDetailContext } from './userDetailContext';

import { IUserDetail } from './userDetail';
import { CUSTOMER_TYPES } from '../../consts';

export interface IUserDetailProviderProps {
  value: IUserDetail;
  children?: ReactNode;
}

export const UserDetailProvider = (props: IUserDetailProviderProps) => {
  const [userDetail, setUserDetail] = useState<IUserDetail>(props.value);

  const isSMECustomer = () => userDetail.customerType === CUSTOMER_TYPES.SME;
  const isSMEBroker = () => userDetail.customerType === CUSTOMER_TYPES.SME_BROKER;
  const isCorpCustomer = () => userDetail.customerType === CUSTOMER_TYPES.CORPORATE;
  const isCorpBroker = () => userDetail.customerType === CUSTOMER_TYPES.CORPORATE_BROKER;

  return (
    <UserDetailContext.Provider
      value={{
        userDetail,
        setUserDetail,
        isSMECustomer,
        isSMEBroker,
        isCorpCustomer,
        isCorpBroker,
      }}
    >
      {props.children}
    </UserDetailContext.Provider>
  );
};
