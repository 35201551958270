import styled from 'styled-components';
import { respondTo } from '../../common/styles/mixins';

export const LayoutStyles = styled.div`
  .indexHeader,
  .index-image {
    display: none;
  }

  &.indexPage,
  &.internal {
    .indexHeader {
      display: block;
      background-color: ${({ theme }) => theme.bg.homeHeader};
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      ${respondTo.tablet`
        position: absolute;
        bottom: initial;
        height: 400px;
      `};
    }

    .logo,
    .logo-mobile {
      display: none;
    }

    .index-image {
      display: inline-block;
    }
  }
`;
