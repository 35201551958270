import { ButtonPrimary } from '../../common/components';
import { ContentSectionWrapper } from './SiteDetailsGrid.styles';

export const SiteReference = () => {
  const handleFormDownload = (event: any) => {
    // TODO
    event.preventDefault();
    console.log('handle downloading form');
  };

  return (
    <ContentSectionWrapper>
      <h2>Want to organize your sites?</h2>

      <p>
        You can give each of your sites a unique reference, making them easier to find and manage. If you have a large
        number of sites, you can download a full list in .csv format. Once you’ve updated these site names, you can send
        them back to your Account Manager.
      </p>

      <div className='action-wrapper'>
        <ButtonPrimary className='m-0' title='Reference form' onClick={handleFormDownload} />
      </div>
    </ContentSectionWrapper>
  );
};
