import { get } from '../../helpers/axiosWrapper';
import { globalApiParams } from './globals';

export const getLoaData = async (
  url: string | undefined,
  context: any,
  apiUrl: string,
  page: number,
  sort?: Common.ISort
): Promise<Array<Api.ILoaData>> => {
  if (!url) throw new Error('url not defined');

  const headers = {
    page,
    pageSize: globalApiParams.pageSize,
    sortField: sort?.field,
    sortDir: sort?.order,
  };

  return await get(url + apiUrl, { headers }, context);
};
