const LongArrow = ({ width, height, className, fillColor }: LongArrowProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 61 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M60.5303 6.53034C60.8232 6.23744 60.8232 5.76257 60.5303 5.46968L55.7574 0.696704C55.4645 0.403811 54.9896 0.403811 54.6967 0.696704C54.4038 0.989597 54.4038 1.46447 54.6967 1.75736L58.9393 6.00001L54.6967 10.2426C54.4038 10.5355 54.4038 11.0104 54.6967 11.3033C54.9896 11.5962 55.4645 11.5962 55.7574 11.3033L60.5303 6.53034ZM-6.55671e-08 6.75L60 6.75001L60 5.25001L6.55671e-08 5.25L-6.55671e-08 6.75Z'
      fill={fillColor}
    />
  </svg>
);

export type LongArrowProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

LongArrow.defaultProps = {
  width: 61,
  height: 12,
  fillColor: '#2a3745',
};

export default LongArrow;
