import styled from 'styled-components';

export const ActionRowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    margin: 0;
  }
`;
