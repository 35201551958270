// Components
import { IndividualSitesGraph } from '../../ConfigurableChart/IndividualSitesGraph';

// Styles
import { IndividualSitesWrapper } from './IndividualSites.styles';

type IndividualSitesProps = {
  handleSelectedMpanChanged?: Function;
}

const defaultProps = {
}

export function IndividualSites(props: IndividualSitesProps) {
  return (
    <IndividualSitesWrapper>
      <IndividualSitesGraph handleSelectedMpanChanged={props.handleSelectedMpanChanged} />
    </IndividualSitesWrapper>
  );
}

IndividualSites.defaultProps = defaultProps;