import styled from 'styled-components';
import * as variables from '../../../../common/styles/variables';
import * as mixins from '../../../../common/styles/mixins';
import * as colors from '../../../../common/styles/colors';

export const DateSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .modal-title {
    color: ${colors.hydroBlue};
    margin-bottom: 24px;
    font-size: 1.5rem;
  }

  .DateSelectionOverviewMobile {
    ${mixins.widgetMobile};
  }

  .DateSelectionOverview {
    ${mixins.widgetDesktop};

    p {
      margin-bottom: 22px;
    }
  }

  .container {
    width: 100%;
    margin: 0px;
    padding-left: 0px;
  }
  
  .left {
    float: left;
    width: 40%;
    padding-right: 20px;
  }
  
  .right {
    float: right;
    width: 60%;
  }

  .confirmButton {
    float: right;
    padding-top: 0px;
    justify-content: end;
    display: flex;
  }
`;
