// Modules
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';

// Components
import { DropDown, ButtonPrimary, ButtonDownload, Icon } from '../../../common/components';

// Styles
import { InvoiceAndPaymentWrapper, InvoiceAndPaymentText } from './InvoiceAndPayment.styles';

// Context
import { HomeContext, HomeContextProps } from '../context/homeContext';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

// Types
import { EssAccountsTypes } from '../../../types/account';

// Utils
import { TagManager } from '../../utils/analytics/TagManager';
import { isDraxBrand, isOpusBrand } from '../../../utils/common';

const dateUtils = require('../../../components/utils/date/date');

type InvoiceAndPaymentProps = {
  hasRenewableAccount: boolean;
  accounts: Array<Api.IEssAccountData>;
  selectedAccount: Api.IInvoiceAccount | undefined;
  downloadReport: Function;
  totalBalance: Api.ITotalBalance;
  handleAccountChange: Function;
};

const defaultProps = {
  hasRenewableAccount: true,
  accounts: {},
  totalBalance: 0,
};

const currentDate: string = dateUtils.formatDate(new Date());

const WIDGET_KEY = 'InvoiceAndPayment';

export const InvoiceAndPayment = (props: InvoiceAndPaymentProps) => {
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);
  const [accounts, setAccounts] = useState<Array<Common.IOption>>([]);
  const [selectedAccountPaymentInfo, setSelectedAccountPaymentInfo] = useState<Api.IInvoiceAccount>();
  const [selectedAccount, setSelectedAccount] = useState<Common.IExpandedCustomerOption>();
  const [accountChanged, setAccountChanged] = useState<boolean>(false);
  const [isTotalBalanceAccountSelected, setIsTotalBalanceAccountSelected] = useState<boolean>(true);
  const [isGasElecAccountSelected, setIsGasElecAccountSelected] = useState<boolean>(false);
  const { userDetail, isSMEBroker, isSMECustomer, isCorpBroker, isCorpCustomer } =
    useContext<UserDetailContextProps>(UserDetailContext);
  const [isCorporateBroker, setIsCorporateBroker] = useState<boolean>(false);
  const [isSmeCustomer, setIsSmeCustomer] = useState<boolean>(false);
  const theme = useContext(ThemeContext);

  function toggleActive() {
    let active = true;
    if (homeState.selectedCard === WIDGET_KEY) active = !homeState.active;

    setHomeState({ selectedCard: WIDGET_KEY, active: active });
  }
  const onPaymentLinkClick = () => {
  };

  useEffect(() => {
    let accountOptions: Array<Common.IExpandedCustomerOption> = [];
    accountOptions.push({
      value: 'allaccounts',
      display: 'All accounts',
      hasElectricity: false,
      hasGas: false,
      hasRenewable: false,
    });

    props.accounts.forEach(item => {
      if (isDraxBrand) {
        item.product = 'Electricity';
      }

      const found = accountOptions.some(option => option.value === item.essAccountID);

      if (item.renewable && !found) {
        accountOptions.push({
          hasGas: false,
          hasElectricity: false,
          hasRenewable: true,
          value: String(item.essAccountID),
          display: `${item.essAccountID} - ${item.accountName}`,
        });
      } else if (!item.renewable && item.product === EssAccountsTypes.Electricity && !found) {
        accountOptions.push({
          hasGas: false,
          hasElectricity: true,
          hasRenewable: false,
          value: String(item.essAccountID),
          display: `${item.essAccountID} - ${item.accountName}`,
        });
      } else if (!item.renewable && item.product === EssAccountsTypes.Gas && !found) {
        accountOptions.push({
          hasGas: true,
          hasElectricity: false,
          hasRenewable: false,
          value: String(item.essAccountID),
          display: `${item.essAccountID} - ${item.accountName}`,
        });
      }
    });

    setAccounts(accountOptions);
    if (props.accounts.length === 1) setSelectedAccount(accountOptions[1]);
  }, [props.accounts]);

  useEffect(() => {
    if (isCorpBroker() && !userDetail.viewAsCustomerSelected) {
      setIsCorporateBroker(true);
    } else if (userDetail.customerType) {
      setIsCorporateBroker(false);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  useEffect(() => {
    if (isSMECustomer() || isSMEBroker()) {
      setIsSmeCustomer(true);
    } else if (userDetail.customerType) {
      setIsSmeCustomer(false);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  const handleAccountChange = (option: Common.IExpandedCustomerOption) => {
    if (option.value === 'allaccounts') {
      const emptySelectedAccountPaymentInfo: any = {
        amount: '',
        balanceDate: '',
        paymentDate: '',
        paymentMethod: '',
        totalBalance: '',
      };

      setSelectedAccountPaymentInfo(emptySelectedAccountPaymentInfo);
      setIsTotalBalanceAccountSelected(true);
      setAccountChanged(false);
      setIsGasElecAccountSelected(false);
      setSelectedAccount(option);
    } else if (option.value !== 'allaccounts' && option.hasRenewable) {
      setSelectedAccount(option);
      if (option.value) {
        props.handleAccountChange(option);
      }
      setAccountChanged(true);
      setIsTotalBalanceAccountSelected(false);
    } else if (option.value !== 'allaccounts' && !option.hasRenewable) {
      setSelectedAccount(option);
      if (option.value) {
        props.handleAccountChange(option);
      }
      setIsTotalBalanceAccountSelected(true);
      setIsGasElecAccountSelected(true);
    }
  };

  useEffect(() => {
    if (props.selectedAccount) {
      let balanceDate = dateUtils.formatDate(props.selectedAccount?.balanceDate);
      if (balanceDate === 'Invalid date') {
        balanceDate = '';
      }
      props.selectedAccount.balanceDate = balanceDate;
      let paymentDate = dateUtils.formatDate(props.selectedAccount?.paymentDate);
      if (paymentDate === 'Invalid date') {
        paymentDate = '';
      }
      props.selectedAccount.paymentDate = paymentDate;
      setSelectedAccountPaymentInfo(props.selectedAccount);
    }
  }, [props.selectedAccount]);

  const getEnergyIcon = () => {
    if (selectedAccount?.hasElectricity) {
      return <Icon color={theme.icons.default} size={50} icon='electricity' />;
    } else if (selectedAccount?.hasGas) {
      return <Icon color={theme.icons.default} size={50} icon='gas-02' />;
    } else if (selectedAccount?.hasRenewable) {
      return <Icon color={theme.icons.default} size={50} icon='renewables' />;
    }
  };

  const filterMessageTitle: Array<Messages.IFilterMessageType> = [
    { value: 'csv', display: 'Download CSV' },
    { value: 'xml', display: 'Download XML' },
  ];

  const downloadReport = (option: Common.IOption) => {
    const data = {
      responseFormat: option.value,
      essCustomerId: userDetail.essCustomerId,
    };
    props.downloadReport(data);
  };

  const isDropdownActive = homeState.active && homeState.selectedCard === WIDGET_KEY;
  const dropdownSelectText = isDraxBrand ? InvoiceAndPaymentText.selectTextINC : InvoiceAndPaymentText.selectText;
  const isDownloadTempDisabled = isDraxBrand;
  let totalBalance = 0;

  if (isTotalBalanceAccountSelected && !isGasElecAccountSelected) {
    totalBalance = props.totalBalance?.totalBalance;
  } else if (selectedAccount?.hasRenewable && selectedAccountPaymentInfo?.renewablesPaymentsForCurrentYear) {
    totalBalance = selectedAccountPaymentInfo.renewablesPaymentsForCurrentYear;
  } else if (!selectedAccount?.hasRenewable && selectedAccountPaymentInfo?.totalBalance) {
    totalBalance = selectedAccountPaymentInfo.totalBalance;
  }
  totalBalance = totalBalance ?? 0;

  let balanceDateText = '';
  let isDateTextBold = false;

  if (selectedAccount?.hasRenewable && accountChanged && selectedAccountPaymentInfo?.balanceDate) {
    isDateTextBold = true;
    balanceDateText = `For ${selectedAccountPaymentInfo.balanceDate}`;
  } else if (isTotalBalanceAccountSelected && !isGasElecAccountSelected && currentDate) {
    balanceDateText = `As of ${currentDate}`;
  } else if (selectedAccountPaymentInfo?.balanceDate) {
    balanceDateText = `As of ${selectedAccountPaymentInfo?.balanceDate}`;
  }
  return (
    <InvoiceAndPaymentWrapper {...props}>
      <div className='balanceOverview'>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-6'>
                <h2 className='mt-0 mb-4'>{InvoiceAndPaymentText.header}</h2>
                <div className='dropdown'>
                  <DropDown
                    options={accounts}
                    showBlankValue={false}
                    selectText={dropdownSelectText}
                    showValue={false}
                    selectedValue={selectedAccount?.value}
                    handleChange={handleAccountChange}
                  />
                </div>

                {isOpusBrand && (
                  <>
                    <div className='pt-3'>
                      <span className='text-bold'>Last payment - </span>
                      <span className='value'>{selectedAccountPaymentInfo?.paymentDate}</span>
                    </div>

                    <div className='pt-3'>
                      <span className='text-bold'>Amount - </span>
                      <span className='value'>{selectedAccountPaymentInfo?.amount}</span>
                    </div>

                    {!selectedAccount?.hasRenewable && (
                      <div className='pt-3'>
                        <span className='text-bold'>Paid by - </span>
                        <span className='value'>{selectedAccountPaymentInfo?.paymentMethod}</span>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className='col-6 overview'>
                <div className='row col-12 pr-0 mr-0'>
                  <div className='col-md-6 p-0'>
                    {isTotalBalanceAccountSelected && !selectedAccount?.hasRenewable ? (
                      <span className='col p-0 text-bold text-nowrap'>Total balance</span>
                    ) : (
                      <span className='col p-0 text-bold text-nowrap'>Payments made</span>
                    )}
                    <div className='paymentsMade'>£ {totalBalance}</div>
                  </div>
                  {isOpusBrand && <div className='energy-icon mt-1 pt-4'>{getEnergyIcon()}</div>}
                </div>
                {/* SSP TODO: Double check this on SSP */}
                {balanceDateText && (
                  <span className={`col p-0 paymentYear ${isDateTextBold ? 'text-bold' : ''}`}>{balanceDateText}</span>
                )}
                {isOpusBrand && (
                  <div className='d-flex justify-content-end mt-auto'>
                    {selectedAccount?.hasRenewable ? (
                      isSMECustomer() && userDetail.hasRenewable ? (
                        <div>&nbsp;</div>
                      ) : (
                        <ButtonPrimary
                          title={InvoiceAndPaymentText.statementsBtn}
                          route='/home/invoices'
                          className='m-0 mt-2'
                        />
                      )
                    ) : (
                      <ButtonPrimary
                        onClick={onPaymentLinkClick}
                        title={InvoiceAndPaymentText.makeaCardPaymentBtn}
                        route='/home/make-a-payment'
                        className='m-0 mt-2'
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            {!isCorporateBroker && !isSmeCustomer && (
              <div className='d-flex justify-content-end w-100'>
                {isOpusBrand && (
                  <div className='col-xl-6 pt-4'>
                    You can download a report containing all your account balances here
                  </div>
                )}

                <div className='col-xl-6 px-0 pt-3 pb-0'>
                  {isDraxBrand && isCorpCustomer() && (
                    <div className='row justify-content-end'>
                      <div className='d-flex justify-content-end mt-auto text-center'>
                        <ButtonPrimary
                          onClick={onPaymentLinkClick}
                          title={InvoiceAndPaymentText.makeaCardPaymentBtn}
                          route='/home/make-a-payment'
                          className='m-0 w-100 mr-3'
                          disabled={true}
                        />
                      </div>
                    </div>
                  )}
                  {!isDownloadTempDisabled && (
                    <div className='d-flex justify-content-end mt-auto text-center'>
                      <ButtonDownload
                        className='w-100'
                        title='Download report'
                        options={filterMessageTitle}
                        onClick={downloadReport}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {isCorporateBroker && !isSmeCustomer && !isDownloadTempDisabled && (
              <>
                <div className='col-lg-6' />
                <div className='col-lg-6 pr-4 p-0 pt-4'>
                  <div className='row'>
                    {isOpusBrand && (
                      <div className='col-xl-5'>
                        You can download a report containing all your account balances here
                      </div>
                    )}
                    <div className='col-xl-7 d-flex justify-content-end mt-auto'>
                      <ButtonDownload
                        className='w-100'
                        title='Download report'
                        options={filterMessageTitle}
                        onClick={downloadReport}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='balanceOverviewMobile'>
        <div onClick={toggleActive}>
          <h2>{InvoiceAndPaymentText.headerBtn}</h2>
          <div className='menu-dropdown'>
            <Icon className='itemicon chevron' size={15} icon={isDropdownActive ? 'chevron-up' : 'chevron-down'} />
          </div>
        </div>
        <div className={isDropdownActive ? 'active-dropdown' : 'hidden-dropdown'}>
          <div className='row mt-3'>
            <div className='col-12 '>
              <div className='overview p-4 d-flex w-100  justify-content-center flex-wrap'>
                <div className='pt-0'>
                  {isTotalBalanceAccountSelected && !selectedAccount?.hasRenewable ? (
                    <div className='text-bold'>Total balance </div>
                  ) : (
                    <div className='text-bold'>Payments made </div>
                  )}

                  <span className='paymentsMade'>£ {totalBalance}</span>
                  {/* SSP TODO: Double check this on SSP */}
                  {isOpusBrand && !(isTotalBalanceAccountSelected && !isGasElecAccountSelected) && (
                    <span className=''>{getEnergyIcon()}</span>
                  )}
                  <div className='text-bold paymentYear pt-2'>{balanceDateText}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-12'>
              <div className='dropdown'>
                <DropDown
                  options={accounts}
                  showBlankValue={false}
                  selectText={dropdownSelectText}
                  showValue={false}
                  handleChange={handleAccountChange}
                />
              </div>

              {isOpusBrand && (
                <>
                  <div className='pt-3'>
                    <span className='text-bold'>Last payment - </span>
                    <span className='value'>{selectedAccountPaymentInfo?.paymentDate}</span>
                  </div>
                  <div className='pt-3'>
                    <span className='text-bold'>Amount - </span>
                    <span className='value'>{selectedAccountPaymentInfo?.amount}</span>
                  </div>

                  {!selectedAccount?.hasRenewable && (
                    <>
                      <div className='pt-3'>
                        <span className='text-bold'>Paid by - </span>
                        <span className='value'>{selectedAccountPaymentInfo?.paymentMethod}</span>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className='menu-list row'>
            <div className='col-12 item'>
              <div className='d-flex mt-auto'>
                {props.hasRenewableAccount ? (
                  <ButtonPrimary
                    title={InvoiceAndPaymentText.statementsBtn}
                    route='/home/invoices'
                    className='col m-0 mt-2'
                  />
                ) : (
                  <ButtonPrimary
                    onClick={onPaymentLinkClick}
                    title={InvoiceAndPaymentText.makeaCardPaymentBtn}
                    route='/home/make-a-payment'
                    className='col m-0 mt-2'
                    disabled={true}
                  />
                )}
              </div>
              {!isSmeCustomer && !isDownloadTempDisabled && (
                <>
                  <div className='mt-2'>You can download a report containing all your account balances here</div>
                  <div className='d-flex mt-auto text-center'>
                    <ButtonDownload
                      className='w-100'
                      title='Download report'
                      options={filterMessageTitle}
                      onClick={downloadReport}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </InvoiceAndPaymentWrapper>
  );
};

InvoiceAndPayment.defaultProps = defaultProps;
