import Modal from 'react-bootstrap/Modal';

import { ExternalLink, ModalClose } from '../../../common/components';

type BulkAcceptanceReadyProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Acceptance forms ready',
  messagevalues: {},
};

export const BulkAcceptanceReady = (props: BulkAcceptanceReadyProps) => {
  return (
    <>
      <span className='font-medium'>
        {props.messagevalues.date}
        <span className='ml-2'>{props.messagevalues.time}</span>
      </span>
      <Modal.Header>
        <Modal.Title>{props.messagevalues.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>Your file is ready to download</p>
        <p>{props.messagevalues.subject}</p>
      </Modal.Body>
      <Modal.Footer>
        <ExternalLink
          onClick={() => {
            props.onHide();
          }}
          className='btn-primary'
          title='Download your file'
          target='_blank'
          href={`${props.messagevalues.callToAction}`}
        />
      </Modal.Footer>
    </>
  );
};

BulkAcceptanceReady.defaultProps = defaultProps;
