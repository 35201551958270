// Modules
import { useContext, useEffect, useState } from 'react';

// Components
import { Icon, Loading, ModalClose } from '../../../common/components';
import { iconsToUse } from '../../../common/styles/themes';

// Styles
import { HealthWidgetWrapper } from './HealthWidget.styles';

// Context
import { RoutingToTypes } from '../../search/SearchActions/SearchActions';
import { Modal } from 'react-bootstrap';
import { Health } from '../Health/Health';
import { SubmitMeterReadingDrax } from '../../home/SubmitMeterReading/SubmitMeterReadingDrax';

export type IHealthCheck = {
  type: string;
  display: string;
  route: string;
  redirect: RoutingToTypes;
  status: 'loading' | 'healthy' | 'notHealthy';
  url: string;
  response?: Array<any>;
};

type HealthWidgetProps = {
  widgetKey: 'Health' | 'Meter' | 'LoA' | 'SubmitMeter';
  title: string;
  type: string;
  healthChecks: Array<IHealthCheck>;
  checkingText: string;
  healthyText: string;
  onMoreInfoClick: Function;
  customers: Array<any>;
};

const defaultProps = {
  widgetKey: 'Health',
  title: 'Account health',
  type: 'AccountHealth',
  healthChecks: [],
  checkingText: 'Checking',
  healthyText: 'Account health check complete',
  customers: [],
};

export const HealthWidget = (props: HealthWidgetProps) => {
  const [status, setStatus] = useState<'loading' | 'healthy' | 'notHealthy'>('loading');
  const [showModal, setShowModal] = useState<boolean>(false);

  const notHealthyItems: Array<IHealthCheck> = props.healthChecks.filter(
    (healthCheck: IHealthCheck) => healthCheck.status === 'notHealthy'
  );

  useEffect(() => {
    const indexLoading = props.healthChecks.findIndex((healthCheck: IHealthCheck) => healthCheck.status === 'loading');
    if (indexLoading >= 0) {
      setStatus('loading');
      return;
    }

    const indexNotHealthy = props.healthChecks.findIndex(
      (healthCheck: IHealthCheck) => healthCheck.status === 'notHealthy'
    );
    if (indexNotHealthy >= 0) {
      setStatus('notHealthy');
      return;
    }

    setStatus('healthy');
  }, [props.healthChecks]);

  return (
    <HealthWidgetWrapper>
      <div
        className={`health ${status}`}
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        <span className='badge badge-pill badge-warning'>
          {notHealthyItems && notHealthyItems.length > 0 && notHealthyItems.length}
        </span>

        <div className='d-flex my-auto'>
          <div className='my-auto'>
            {props.type === 'AccountHealth' && <Icon icon={iconsToUse.accountHealth} size={50} />}
            {props.type === 'MeterHealth' && <Icon icon={iconsToUse.meterHealth} size={50} />}
            {props.type === 'LoAHealth' && <Icon icon={iconsToUse.loaHealth} size={50} />}
            {props.type === 'SubmitMeterReading' && <Icon icon={iconsToUse.meter} size={50} />}
          </div>
          {status === 'loading' && (
            <div className='spinner'>
              {<Loading nowait message={props.checkingText} containerClassName='widget-loader' />}
            </div>
          )}

          {status !== 'loading' && (
            <>
              <h2 className='header ml-2 my-auto'>{props.title}</h2>

              <div className='menu-dropdown d-md-none'>
                <Icon size={15} icon={'chevron-down'} />
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        className={`d-flex justify-content-center align-items-center modal-full-width health-modal ${status}`}
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header className='pl-lg-4 pt-0'>
          <Modal.Title className='modal-title-center'>{props.title}</Modal.Title>
          <ModalClose
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className='d-flex flex-column flex-md-row justify-content-around align-items-center'>
          {props.type === 'SubmitMeterReading' ? (
            <SubmitMeterReadingDrax />
          ) : (
            <Health
              type={props.type}
              healthChecks={props.healthChecks}
              checkingText={props.checkingText}
              healthyText={props.healthyText}
              customers={props.customers}
              onMoreInfoClick={props.onMoreInfoClick}
            ></Health>
          )}
        </Modal.Body>
      </Modal>
    </HealthWidgetWrapper>
  );
};

HealthWidget.defaultProps = defaultProps;
