import styled from 'styled-components';
import * as colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
    ${mixins.transition};
    font-weight: ${props => props.theme.button.fontWeight};
    color: ${colors.trueblack};
    border-radius: ${props => props.theme.secondary.btnBorderRadius};
    text-decoration: none;
    padding: 0.5rem 2rem;
    white-space: nowrap;
    margin: 30px 0;
    text-align: center;
    font-size: 16px;
    '--hovercolor': ${props => props.theme.secondary.hover};
    border: 2px solid ${props => props.theme.secondary.border};
    background-color: ${props => props.theme.secondary.bg};

    &:hover {
        border: 2px solid ${colors.spearmintGreen};
        color: ${colors.trueblack};
        text-decoration: none;
    }

    // anchors dont have disabled so have to use props
    ${props =>
      props.disabled === true && {
        'opacity': 0.4,
        'cursor': 'default',
        'pointer-events': 'none',
      }};
`;
