import styled from 'styled-components';

import * as colors from '../../../common/styles/colors';
import * as mixins from '../../../common/styles/mixins';

export const SearchItemCollectionWrapper = styled.div`
  & > h5 {
    padding-top: 40px;
    padding-left: 40px;
    margin-bottom: 15px;
  }

  .selectedItem {
    ${mixins.boxShadowLight};
    background: ${colors.inputGrey};
    color: ${({ theme }) => theme.text.secondary};
    border-radius: 15px;

    .searchAction {
      display: block;
    }
  }

  .search-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
