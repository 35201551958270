// Modules
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';

// Components
import { StyledTitle } from '../../common/components';
import { MovingPremisesChooseSite } from './sites-step';
import { MovingPremisesNewAddress } from './sites-step/MovingPremisesNewAddress';
import { MovingPremisesConfirmation } from './sites-step/MovingPremisesConfirmation';
import { MovingPremisesProcessProvider } from './movingPremisesProcess/movingPremisesProcessProvider';

// Styles
import { ProcessStepsWrapper } from './MovingPremises.styles';
import { ProcessStepIndicatorMovingPremises } from '../../common/components/ProcessStepIndicator/ProcessStepIndicatorMovingPremises';

const MovingPremises = () => {
  const { url, path } = useRouteMatch();
  const brand = process.env.REACT_APP_BRAND as 'drax' | 'opus';
  const steps = {
    drax: [
      { label: 'Site selection', routePath: '', component: MovingPremisesChooseSite },
      { label: 'Address update', routePath: '/newaddress', component: MovingPremisesNewAddress },
      { label: 'Confirmation', routePath: '/confirmation', component: MovingPremisesConfirmation },
    ],
    opus: [
      { label: 'Select site', routePath: '', component: MovingPremisesChooseSite },
      { label: 'New address', routePath: '/newaddress', component: MovingPremisesNewAddress },
      { label: 'Confirmation', routePath: '/confirmation', component: MovingPremisesConfirmation },
    ],
  };
  const selectedSteps = steps[brand];

  const location = useLocation();

  const currentTabIndex = () => {
    const pathname = location.pathname;

    if (pathname.toUpperCase().endsWith('/MOVING-PREMISES')) return 0;

    var ind: number = 0;
    for (const item of selectedSteps) {
      if (item.routePath.length === 0) {
        ind++;
      } else if (pathname.toUpperCase().endsWith(item.routePath.toUpperCase())) {
        break;
      } else {
        ind++;
      }
    }

    return ind;
  };

  const ProcessIndicator = (
    <ProcessStepsWrapper>
      {selectedSteps.map(({ label, routePath }, index) => {
        var visited = false;

        if (index <= currentTabIndex()) {
          visited = true;
        }

        return (
          <ProcessStepIndicatorMovingPremises
            key={`${url}${routePath}`}
            path={`${url}${routePath}`}
            step={index + 1}
            label={label}
            visited={visited}
          />
        );
      })}
    </ProcessStepsWrapper>
  );

  return (
    <div>
      <MovingPremisesProcessProvider>
        <Switch>
          {selectedSteps.map(({ routePath, component: Component }) => (
            <Route exact key={`${path}${routePath}`} path={`${path}${routePath}`}>
              <Component stepsBar={ProcessIndicator} pageTitle={<StyledTitle>Moving premises</StyledTitle>} />
            </Route>
          ))}
        </Switch>
      </MovingPremisesProcessProvider>
    </div>
  );
};
export default MovingPremises;
