import { isMobileOnly } from 'react-device-detect';

import { IUserDetail } from './context/userDetail/userDetail';
import { UserDetailProvider } from './context/userDetail/userDetailProvider';
import { IGlobalDetail } from './context/globalContext/globalDetail';
import { GlobalDetailProvider } from './context/globalContext/globalProvider';
import { Layout } from './Layout';

export const Home = () => {
  const initialUserDetail: IUserDetail = {
    loggedInDetails: {
      essCustomerId: '',
      hasRenewable: false,
      hasElectricity: false,
      hasGas: false,
    },
    customerAccounts: [],
    customersMeteringPointTypes: undefined,
    hasUnreadMessages: false,
    essCustomerId: '',
    customerType: '',
    forename: '',
    lastName: '',
    viewAsCustomerToggle: false,
    viewAsCustomerSelected: false,
    hasRenewable: false,
    hasElectricity: false,
    hasGas: false,
    customers: [],
    selectedCustomers: [],
    emailAddress: '',
    isPrimaryContact: false,
    isAccountSignatory: false,
    userProfile: {
      accountManager: '',
      email: '',
      phone: '',
      description: '',
      id: '',
      numberOfCustomers: 0,
      customerIds: [],
    },
    selectedCustomerAccount: '',
  };

  const initialGlobalDetail: IGlobalDetail = {
    isSideMenuActive: !isMobileOnly,
    filterActive: false,
    mobileFilterActive: false,
    meterWidgetExpanded: false,
    filteredItems: [],
    customerData: [],
    accountData: [],
    siteData: [],
    mpanMprnData: [],
    selectedCustomer: { display: '', value: '' },
    selectedAccount: { display: '', value: '' },
    selectedSite: { display: '', value: '' },
    selectedMpanMprn: { display: '', value: '' },
    selectedDate: '',
    clearAllTrigger: false,
    exclusiveOffersOptions: [],
    showBrokerLandingPage: false,
    userProfile: {},
    availableCustomers: [],
    selectedCustomers: [],
  };

  return (
    <UserDetailProvider value={initialUserDetail}>
      <GlobalDetailProvider value={initialGlobalDetail}>
        <Layout />
      </GlobalDetailProvider>
    </UserDetailProvider>
  );
};
