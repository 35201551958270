const Insights = ({ width, height, className, fillColor }: InsightsProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.375 29.0625H9.375V44.0625H24.375V29.0625Z'
      stroke={fillColor}
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='square'
      style={{ fill: 'none' }}
    />
    <path
      d='M30.9375 29.0625H50.625'
      stroke={fillColor}
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='square'
    />
    <path
      d='M30.9375 36.5625H50.625'
      stroke={fillColor}
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='square'
    />
    <path
      d='M30.9375 44.0625H42.1875'
      stroke={fillColor}
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='square'
    />
    <path
      d='M9.375 12.1875H13.125'
      stroke={fillColor}
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='square'
    />
    <path d='M18.75 12.1875H22.5' stroke={fillColor} stroke-width='3' stroke-miterlimit='10' stroke-linecap='square' />
    <path
      d='M40.3125 12.1875H50.625'
      stroke={fillColor}
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='square'
    />
    <path d='M1.875 18.75H58.125' stroke={fillColor} stroke-width='3' stroke-miterlimit='10' stroke-linecap='square' />
    <path
      d='M58.125 5.625H1.875V54.375H58.125V5.625Z'
      stroke={fillColor}
      stroke-width='3'
      stroke-miterlimit='10'
      stroke-linecap='square'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type InsightsProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Insights.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2A3745',
};

export default Insights;
