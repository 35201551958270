// Modules
import React, { useContext, useEffect, useState, useReducer } from 'react';
import { toast } from 'react-toastify';

// Context
import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

// Api
import { getLoaData } from '../../common/api/letterOfAuthorityApi';

// Components
import { StyledTitle, FilterRowWrapper, ButtonPrimary } from '../../common/components';
import { LoaDataGrid } from './LoaDataGrid';
import { NotificationBox } from '../health/NotificationBox/NotificationBox';
import { SubmissionFailedModal } from '../mymessages/modalTemplates/SubmissionFailedModal';

// Utils
import { getInitialType } from '../../utils/getInitialTypes';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';
import { getSelectedCustomerId } from '../../helpers/customer';
import { UploadLoAModal } from '../mymessages/modalTemplates/LoA/UploadLoAModal';
import { LoAUploadCompleteModal } from '../mymessages/modalTemplates/LoA/LoAUploadCompleteModal';
import { getTpiByTpiReference, uploadLoaFiles } from '../../common/api/tpiApi';
import ProgressBarModal from '../../common/components/ProgressBarModal/ProgressBarModal';
import { TagManager } from '../utils/analytics/TagManager';

export const LoaData = () => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [isLoaExpiring, setIsLoaExpiring] = useState<boolean>(false);
  const [showUploadLoAModal, setShowUploadLoAModal] = useState<boolean>(false);
  const [loAUploadCompleteSuccess, setLoAUploadCompleteSuccess] = useState<boolean>(false);
  const [showLoAUploadCompleteModal, setShowLoAUploadCompleteModal] = useState<boolean>(false);
  const [showFailureModal, setShowFailureModal] = useState<boolean>(false);

  const [tpiReference, setTpiReference] = useState<string>('');
  const [tpiName, setTpiName] = useState<string>('');

  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressBarComplete, setProgressBarComplete] = useState<number>(0);

  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(userDetail.hasElectricity, userDetail.hasGas),
    data: {},
    gridData: [],
    initialSortField: 'endDate',
    initialSortOrder: 'ASC',
    currentSortField: 'endDate',
    currentSortOrder: 'ASC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  const getLoaDataRows = async (sort?: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const url = '/ersgatewayadapter/lettersofauthority/getgriddata';

    const result: Array<Api.ILoaData> = await getLoaData(
      process.env.REACT_APP_API_URL,
      authContext,
      url,
      state.page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    return result;
  };

  const loadData = async (sort?: Common.ISort) => {
    //const rawData = await getContactSummaryRows(customerId, sort);
    const rawData = await getLoaDataRows(sort);

    rawData.map((data: Api.ILoaData) => {
      data.status = data.status.replace('FutureDated', 'Future dated');
      data.status = data.status.replace('ExpiringFutureExists', 'Expiring but future dated LoA exists');

      data.warningIcon = data.status;

      if (data.status === 'Expiring') {
        setIsLoaExpiring(true);
      }
    });
    dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: rawData, page: state.page } });
  };

  useEffect(() => {
    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentSortField, state.currentSortOrder, state.page]);

  const handleSortChange = (sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const handleShowModalSubmitLoAClick = async (event: any) => {
    event.preventDefault();

    TagManager.pushData({
      event: 'Launch submit LoA (LoA data page)',
    });

    const result = await getTpiByTpiReference(process.env.REACT_APP_API_URL, authContext);

    if (result && result.success) {
      const data = result.data;

      setTpiReference(data.tpiReference);
      setTpiName(data.description);
    } else {
      const message = 'Unable to get TPI details';
      toast.error(message, { position: 'bottom-right' });
      throw new Error(message);
    }

    setShowUploadLoAModal(true);
  };

  const onUploadProgress = (percentCompleted: number) => {
    setShowProgressBar(true);
    setProgressBarComplete(percentCompleted);
  };

  const handleUpload = async (params: Api.ILoaFileUpload) => {
    const result: any = await uploadLoaFiles(process.env.REACT_APP_API_URL, authContext, params, onUploadProgress);

    setShowProgressBar(false);

    let success = false;

    if (result && result.status == '200') {
      success = true;
    }
    
    if (success) {
      TagManager.pushData({
        event: 'Success LoA (LoA data page)',
      });
      setLoAUploadCompleteSuccess(true);
      setShowLoAUploadCompleteModal(true);
    } else {
      TagManager.pushData({
        event: 'Failure LoA (LoA data page)',
      });
      setLoAUploadCompleteSuccess(false);
      setShowFailureModal(true);
    }
  };

  return (
    <>
      <FilterRowWrapper>
        <StyledTitle>Letter of Authority data</StyledTitle>
      </FilterRowWrapper>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 col-xl-2 py-1 d-flex justify-content-center'>
            <ButtonPrimary title='Submit LoA' onClick={handleShowModalSubmitLoAClick} />
          </div>
          <div className={isLoaExpiring ? 'col-md-12 col-xl-8 my-auto' : 'col-md-12 col-xl-5'}>
            {isLoaExpiring && (
              <NotificationBox
                showButton={false}
                buttonClassName='button'
                boxText="You have Letters of Authority that are due to expire within 28 days. Use the 'Submit LoA' button to send a new Letter of Authority for validation so you can continue to access the relevant customer's data."
                buttonText=''
              ></NotificationBox>
            )}
          </div>
        </div>
      </div>

      <LoaDataGrid
        gridData={state.gridData}
        isLoading={state.isLoading}
        hasMoreData={state.hasMoreData}
        handleSortChange={handleSortChange}
        handlePagingChange={handlePagingChange}
        currentSortField={state.currentSortField}
        currentSortOrder={state.currentSortOrder}
      />

      {showUploadLoAModal && (
        <UploadLoAModal
          show={true}
          tpiName={tpiName}
          tpiReference={tpiReference}
          submittedBy={userDetail.emailAddress}
          upload={handleUpload}
          onHide={() => {
            setShowUploadLoAModal(false);
          }}
        />
      )}

      {showLoAUploadCompleteModal && (
        <LoAUploadCompleteModal
          success={loAUploadCompleteSuccess}
          show={true}
          onHide={() => {
            setShowLoAUploadCompleteModal(false);
          }}
        />
      )}

      {showProgressBar && (
        <ProgressBarModal
          show={true}
          title={'Letter of Authority files'}
          message={['Uploading...']}
          onClose={() => {}}
          percentageComplete={progressBarComplete}
        />
      )}

      <SubmissionFailedModal
        show={showFailureModal}
        onHide={() => {
          setShowFailureModal(false);
        }}
      ></SubmissionFailedModal>
    </>
  );
};
