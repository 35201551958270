import styled from 'styled-components';
import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';

export const StyledCardContainer = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

export const StyledCard = styled.div<{ isExpanded: boolean }>`
  height: ${({ isExpanded }) => (isExpanded ? '95%' : '27vh')};

  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 5px 25px;
  cursor: pointer;
  padding: 20px;
  border: none;
  background-color: ${colors.lightGrey};
  border-radius: ${variables.globalRadius};
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);

  & > h5 {
    font-size: 23px;
    font-weight: 400;
    text-align: center;
  }

  & > h6 {
    font-size: 19px;
    font-weight: 400;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ isExpanded }) => (isExpanded ? 0 : 2)};
    line-height: 1.3;
  }
`;

export const StyledLogoWrapper = styled.div`
  width: 207px;
  height: 100px;
  padding: 20px;
  margin: 14px auto;
  background-color: #fff;
  border-radius ${variables.globalRadius};

  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledExpandableContent = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-wrap: wrap;

  & > p {
    text-align: left;
    font-size: 14px;
    margin: 10px 0;
  }

  & > a {
    display: block;
    align-self: end;
    margin: 0 0 0 auto;
  }
`;
