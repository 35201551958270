import styled from 'styled-components';

import * as variables from '../../../../../common/styles/variables';
import * as mixins from '../../../../../common/styles/mixins';

export const Setup = styled.a`
  ${mixins.transition};
  z-index: ${variables.zIndexHighest};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: fixed;
  bottom: 5px;
  right: 0;
  padding: 0.5em 1.5em;
  font-size: ${variables.fontSizeSmall};
  background-color: ${variables.transparentWhite};
  color: ${({ theme }) => theme.text.secondary};
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    color: ${({ theme }) => theme.text.secondary};
    opacity: 1;
  }
`;
