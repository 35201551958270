const Success = ({ width, height, className, fillColor, style }: SuccessProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='32' height='32' fill={fillColor} />
    <path
      d='M16.1879 24.8122C21.0547 24.7084 24.9159 20.679 24.8121 15.8122C24.7083 10.9454 20.6788 7.08426 15.8121 7.18805C10.9453 7.29184 7.08414 11.3213 7.18793 16.188C7.29172 21.0548 11.3212 24.916 16.1879 24.8122Z'
      stroke='white'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.4622 17.7317L12.71 15.9117C12.4312 15.6222 11.9879 15.6222 11.7091 15.9117C11.4303 16.2012 11.4303 16.6613 11.7091 16.9508L13.9546 19.2829C14.2335 19.5724 14.6839 19.5724 14.9627 19.2829L20.2909 13.7562C20.5697 13.4667 20.5697 13.0066 20.2909 12.7171C20.0121 12.4276 19.5688 12.4276 19.29 12.7171L14.4622 17.7317Z'
      fill='white'
    />
  </svg>
);

export type SuccessProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

Success.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#1C8913',
};

export default Success;
