import Modal from 'react-bootstrap/Modal';
import { FailureSubmitMessage } from './FailureSubmitMessage';

import { ButtonPrimary, ModalClose } from '../../../../common/components';

type FailureSubmitModalProps = {
  errorMessage: string;
  onClose: () => void;
};

export const FailureSubmitModal = (props: FailureSubmitModalProps) => {
  const { errorMessage, onClose } = props;

  return (
    <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Meter read validation failure</Modal.Title>
        <ModalClose onClick={onClose} />
      </Modal.Header>

      <Modal.Body className='pl-lg-4'>
        <div>{FailureSubmitMessage(errorMessage)}</div>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={onClose} className={'mr-0'} title='Okay' route='#' />
      </Modal.Footer>
    </Modal>
  );
};
