import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import { respondTo } from '../../common/styles/mixins';
import * as variables from '../../common/styles/variables';

export const SiteDetailsWrapper = styled.div`
  .icon-status {
    fill: ${props => props.theme.text.secondary};
    margin-right: ${variables.spacingSmall};
  }

  td.site-address-col {
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: ${variables.zIndexHigh};
  }
`;

export const ContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${variables.spacingMedium};

  ${respondTo.desktop`
    flex-direction: row;
  `};

  .customer-details-block {
    background-color: ${colors.lightGrey};
    border-radius: ${variables.inputRadius};
    padding: ${variables.spacingSmall};
    flex: 1 100%;

    &:first-child {
      margin: 15px;
    }

    &:last-child {
      margin: 15px;
    }

    ${respondTo.desktop`
      &:first-child {
        margin: 0 15px 0 0;
      }
    
      &:last-child {
        margin: 0 0 0 15px;
      }
    `};
  }

  .action-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .info-row {
    ${respondTo.desktop`
      display: flex;
      justify-content: flex-start;
    `};
  }

  .customer-detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${variables.spacingXSmall};
  }
`;

export const DownloadWrapper = styled.div`
  .download-btn {
    border: none;
    background: none;
    color: ${({ theme }) => theme.text.secondary};
    padding-left: calc(20px + 0.2em);
  }
`;

export const SubContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.midGrey};
`;

export const NestedRowAccountDetailsWrapper = styled.div`
  width: 65%;

  .disabled {
    opacity: 0.5;
  }

  .header {
    display: flex;
    margin-bottom: ${variables.spacingSmall};

    span {
      color: ${({ theme }) => theme.text.secondary};
      width: 30%;
    }
  }

  .info-row {
    display: flex;
    margin-bottom: ${variables.spacingMedium};

    span {
      width: 30%;
    }
  }

  .info-row-column {
    margin-bottom: ${variables.spacingMedium};
  }

  .info-row-title {
    color: ${({ theme }) => theme.text.secondary};
    margin-bottom: ${variables.spacingXSmall};
  }

  .generation-meter-message {
    span {
      color: ${({ theme }) => theme.text.secondary};
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const NestedRowActionsWrapper = styled.div`
  .actions-column {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-left: ${variables.spacingLarge};

    ul {
      margin-bottom: ${variables.spacingMedium};
    }

    a {
      margin: 0;
      width: 100%;
      max-width: 320px;

      &:first-child {
        margin-bottom: ${variables.spacingMedium};
      }
    }
  }

  .non-active-message {
    margin-bottom: ${variables.spacingMedium};

    span {
      color: ${({ theme }) => theme.text.secondary};
    }
  }
`;
