import React, { useContext, useEffect, useRef, useState } from 'react';
import IcomoonReact from 'icomoon-react';

import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

import MyMessagesActionsPanel from './MyMessagesActionsPanel';
import MyMessagesFilters from './MyMessagesFilters';
import { MyMessagesWrapper } from './MyMessages.styles';

import { ModalTemplate } from './modalTemplates/ModalTemplate';
import { RowSelector, Checkbox, Loading, NoResults, StyledTitle, Icon } from '../../common/components';
import { formatDate, formatTime, formatUtcDateTime } from '../utils/date/date';

import iconSet from '../../assets/selection.json';
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { globalApiParams } from '../../common/api/globals';
import TableFilter, { TableFilterOption } from '../../common/components/TableFilter/TableFilter';
import { iconsToUse } from '../../common/styles/themes';
import * as variables from '../../common/styles/variables';
import { TagManager } from '../utils/analytics/TagManager';

type MyMessagesComponentProps = {
  initialType: string;
  gridData: Array<Messages.IAlertData>;
  handleTypeChange: Function;
  handlePagingChange: Function;
  handleSortChange: Function;
  handleFilterChange: Function;
  handleMarkAsRead: Function;
  handleMarkAsDeleted: Function;
  checkedItems: Array<string>;
  multiple?: boolean;
  options: Array<any>;
  isLoading?: boolean;
  hasMoreData: boolean;
};

const defaultProps = {
  initialType: 'All',
  gridData: [],
  handleFilterChange: undefined,
  handleSortChange: undefined,
  handleMarkAsRead: undefined,
  handleMarkAsDeleted: undefined,
  checkedItems: [],
  multiple: false,
  options: [],
  isLoading: true,
  hasMoreData: true,
};

export const MyMessagesComponent = (props: MyMessagesComponentProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [show, setShow] = useState<boolean>(false);
  const handleShow = () => setShow(true);
  const [modalName, setModalName] = useState<string>('');
  const [messagevalues, setMessageValues] = useState<Messages.IMessagesValues>();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const [checkedAlertIds, setCheckedAlertIds] = useState<Array<number>>([]);
  const { globalDetail } = useContext<GlobalDetailContextProps>(GlobalContext);
  const [selectedType, setSelectedType] = useState<string | null | number>(props.initialType);
  const scrollableTable = useRef<any>();

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }
  }, [props.gridData]);

  useEffect(() => {
    if (globalDetail.filteredItems) {
      props.handleFilterChange(globalDetail.filteredItems);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDetail.filteredItems]);

  const handleTypeChange = (option: TableFilterOption) => {
    setSelectedType(option.value);
    props.handleTypeChange(option.value, { field: 'Month', order: 'DESC' });
    setSelectAll(false);
    setCheckedItems([]);
  };

  const handleSort = (details: Common.ISort) => {
    props.handleSortChange(selectedType, details);
  };

  const handleMessageSelect = (event: any, selectedRow: number | null) => {
    const wrapper = event.target.closest('td').classList?.contains?.('select-item');
    if (wrapper) {
      return;
    }
    if (selectedRow !== undefined) {
      setSelectedRow(selectedRow);
    }
  };

  const handleShowModal = (event: any, name: string, messageType: string, index: number) => {
    if (!props.gridData[index].read) {
      props.gridData[index].read = true;
      handleMarkAsRead(event);
    }

    if (messageType === 'Alert') {
      if (name === 'HalfHourlyDataSuccess' || name === 'HalfHourlyDataFailure') {
        TagManager.pushData({
          event: 'Read HH request message',
        });
      } else if (name === 'MeterReadSuccess' || name === 'MeterReadFailure') {
        TagManager.pushData({
          event: 'Read Meter Read message',
        });
      } else {
        TagManager.pushData({
          event: 'Read alert message',
        });
      }
    }

    if (messageType === 'BulkMessage') {
      TagManager.pushData({
        event: 'Read bulk message',
      });
    }

    setModalName(name);
    setMessageValues({
      ids: [],
      date: '',
      time: '',
      title: '',
      callToAction: '',
      subject: '',
      accountReference: '',
      json: null,
      messageType: '',
    });
  };

  const handleFilterChange = (filteredItems: Array<Common.IOption>) => {
    props.handleFilterChange(filteredItems);
  };

  const handleMarkAsRead = (event: any) => {
    const messages: Array<any> = [];

    checkedItems.map(index => {
      props.gridData[index].read = true;

      const data = {
        id: props.gridData[index].id,
        messageType: props.gridData[index].messageType,
      };
      messages.push(data);
    });

    resetCheckedItems();

    if (checkedAlertIds.length <= 0) {
      return;
    }

    const payload = {
      messages: messages,
    };

    props.handleMarkAsRead(payload);
  };

  const handleClose = () => {
    if (messagevalues && messagevalues.ids) {
      const id = messagevalues.ids[0];
      const data = {
        id,
        messageType: messagevalues.messageType,
      };
      const payload = {
        messages: [data],
      };
      props.handleMarkAsRead(payload);
    }

    setShow(false);
  };

  const handleError = (type?: any) => {
    if (type === 'brokerTpiReport') {
      setModalName('TpiDataFailure');
    } else setModalName('HalfHourlyDownloadError');
  };

  const handleMarkAsDeleted = (event: any) => {
    const messages: Array<any> = [];
    checkedItems.map(index => {
      const data = {
        id: props.gridData[index].id,
        messageType: props.gridData[index].messageType,
      };
      messages.push(data);
    });

    // Find and delete from gridData
    let itemsToDelete: Array<Messages.IAlertData> = [];
    checkedItems.map(index => {
      itemsToDelete.push(props.gridData[index]);
    });

    if (itemsToDelete.length > 0) {
      itemsToDelete.map((item: Messages.IAlertData) => {
        const index = props.gridData.findIndex(c => c.id === item.id);
        props.gridData.splice(index, 1);
      });
    }

    resetCheckedItems();

    if (checkedAlertIds.length <= 0) {
      return;
    }

    const payload = {
      messages: messages,
    };

    props.handleMarkAsDeleted(payload);
  };

  const handleItemChange = (index: number) => {
    setSelectedRow(null);

    let items = checkedItems.slice(0, checkedItems.length);
    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);
    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }
    const newItems = items.map((item: any) => props.gridData[item].id);

    if (items.length === props.gridData.length) {
      handleSelectAll();
    } else if (selectAll) {
      setSelectAll(false);
    }

    setCheckedAlertIds(newItems);
    setCheckedItems(items);
  };

  const handleSelectAll = () => {
    setSelectedRow(null);

    const select = !selectAll;
    setSelectAll(select);
    if (select) {
      let items: Array<number> = [];
      props.gridData.map((item, index) => items.push(index));
      setCheckedItems(items);

      const newItems = items.map((item: any) => props.gridData[item].id);
      setCheckedAlertIds(newItems);
    } else {
      // unselect
      setCheckedItems([]);
    }
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const resetCheckedItems = () => {
    setCheckedItems([]);
    setSelectAll(false);
  };

  const messageRows = () => {
    const items = props.gridData.map((item: any, index: number) => {
      return (
        <RowSelector
          key={index}
          className={`item ${selectedRow === index ? 'selected' : ''} ${item.read ? '' : 'text-bold'}`}
          onClick={event => {
            if (selectedRow !== index) {
              handleMessageSelect(event, index);
            } else {
              handleShow();
              handleShowModal(event, item.name, item.messageType, index);
              setMessageValues({
                ids: [item.id],
                date: formatDate(item.date),
                time: formatTime(item.date),
                title: item.title,
                callToAction: item.callToAction,
                subject: item.subject,
                accountReference: item.accountReference,
                json: JSON.parse(item.json),
                messageType: item.messageType,
              });
              handleMessageSelect(event, null);
            }
          }}
        >
          <td className='warning-icon'>
            {item.priority === 'High' ? <Icon icon={iconsToUse.warningInverse} size={variables.iconSizeSmall} /> : ''}
          </td>
          <td className='select-item pl-0'>
            <Checkbox
              id={item.id}
              onChange={(event: any) => {
                handleItemChange(index);
              }}
              checked={isChecked(index)}
            />
          </td>
          <td className='date'>{formatUtcDateTime(item.date)}</td>
          <td className='accountreference'>
            <div>{item.accountReference}</div>
          </td>
          <td className='title'>
            <div>{item.title}</div>
          </td>
          <td className='subject'>
            <div>{item.subject}</div>
          </td>
          <td className='actions-wrapper text-right'>
            <IcomoonReact className='icon-chevron' iconSet={iconSet} size={20} icon='chevron-right' />
          </td>
        </RowSelector>
      );
    });
    return items;
  };

  const handlePagingChange = () => {
    if (selectAll) {
      handleSelectAll();
    }
    props.handlePagingChange();
  };

  const messagesTypeFilterOptions = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Read',
      value: 'read',
    },
    {
      label: 'Unread',
      value: 'unread',
    },
  ];

  return (
    <MyMessagesWrapper>
      <StyledTitle>Message centre</StyledTitle>

      <div className='row'>
        <div className='col-md-6 mb-4 mt-4 d-flex'>
          <TableFilter
            options={messagesTypeFilterOptions}
            isClearable={false}
            isSearchable={false}
            defaultValue={messagesTypeFilterOptions[0]}
            onChange={handleTypeChange}
          />
        </div>
        <div className='col-lg-6'>
          <MyMessagesActionsPanel
            checkedItems={checkedItems}
            checkedAlertIds={checkedAlertIds}
            handleMarkAsRead={handleMarkAsRead}
            handleMarkAsDeleted={handleMarkAsDeleted}
            gridData={props.gridData}
          />
        </div>
      </div>

      {!props.isLoading && props.gridData.length === 0 && (
        <NoResults title={'No new messages'} isDescriptionEnabled={false} />
      )}

      {props.gridData.length > 0 && (
        <div className='position-relative'>
          <InfiniteScroll
            dataLength={props.gridData.length}
            next={handlePagingChange}
            hasMore={props.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <div
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1 fixed-end-column'
            >
              <table className='messages-list table plain'>
                <thead>
                  <MyMessagesFilters
                    selectAll={selectAll}
                    handleFilterChange={handleFilterChange}
                    handleSort={handleSort}
                    handleSelectAll={handleSelectAll}
                    options={props.options}
                  />
                </thead>
                <tbody>{messageRows()}</tbody>
              </table>
            </div>
          </InfiniteScroll>

          {props.isLoading && <Loading overlay />}
        </div>
      )}

      <ModalTemplate
        name={modalName}
        show={show}
        onHide={handleClose}
        messagevalues={messagevalues}
        handleConfirm={handleMarkAsDeleted}
        onError={handleError}
      />
    </MyMessagesWrapper>
  );
};

MyMessagesComponent.defaultProps = defaultProps;

export default MyMessagesComponent;
