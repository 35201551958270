import React, { useContext } from 'react';

import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

type ContactsProps = {
  hasRenewableAccount: boolean;
};

const defaultProps = {
  hasRenewableAccount: true,
};

const Contacts = (props: ContactsProps) => {
  const { isCorpBroker, isCorpCustomer, isSMEBroker, isSMECustomer } =
    useContext<UserDetailContextProps>(UserDetailContext);

  const contactDetails = () => {
    if (isSMECustomer()) {
      if (props.hasRenewableAccount) {
        return (
          <div className='row'>
            <div className='col-6 contact-number'>0843 227 0979</div>
            <div className='col-6 pl-0 contact-type'>Customer service</div>
            <div className='col-6 contact-number'>0845 040 5795</div>
            <div className='col-6 pl-0 contact-type'>Renewables</div>
          </div>
        );
      } else {
        return (
          <div className='row'>
            <div className='col-6 contact-number'>0843 227 0979</div>
            <div className='col-6 pl-0 contact-type'>Customer service</div>
          </div>
        );
      }
    }

    // corporate
    if (isCorpCustomer()) {
      if (props.hasRenewableAccount) {
        return (
          <div className='row'>
            <div className='col-6 contact-number'>0843 227 2359</div>
            <div className='col-6 pl-0 contact-type'>Customer service</div>
            <div className='col-6 contact-number'>0845 040 5795</div>
            <div className='col-6 pl-0 contact-type'>Renewables</div>
          </div>
        );
      } else {
        return (
          <div className='row'>
            <div className='col-6 contact-number'>0843 227 2359</div>
            <div className='col-6 pl-0 contact-type'>Customer service</div>
          </div>
        );
      }
    }

    // corporateBroker
    if (isCorpBroker()) {
      if (props.hasRenewableAccount) {
        return (
          <div className='row'>
            <div className='col-6 contact-number'>0843 227 2359</div>
            <div className='col-6 pl-0 contact-type'>Customer service</div>
            <div className='col-6 contact-number'>0845 040 5795</div>
            <div className='col-6 pl-0 contact-type'>Renewables</div>
          </div>
        );
      } else {
        return (
          <div className='row'>
            <div className='col-6 contact-number'>0843 227 2359</div>
            <div className='col-6 pl-0 contact-type'>Customer service</div>
          </div>
        );
      }
    }

    // sme broker
    if (isSMEBroker()) {
      return (
        <div className='row'>
          <div className='col-6 contact-number'>0845 543 5778</div>
          <div className='col-6 pl-0 contact-type'>Portal Help</div>
        </div>
      );
    }
  };

  return <>{contactDetails()}</>;
};

Contacts.defaultProps = defaultProps;

export default Contacts;
