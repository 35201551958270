const OpusMobileLogo = ({ width, height, fillColor, className }: OpusMobileLogoProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 291.9 298.6' className={className} width={width} height={height}>
    <g>
      <path
        fill={fillColor || '#dc004d'}
        d='M46.7,178.6c-17.7,9.7-33.8,7-41.3,0.6C18,207.4,59,234.7,87.6,234.7c30.8,0,52.7-12.4,71.3-26.2
		c-12.6-0.5-46.4-16.9-81.7-57C65.6,165.9,59.7,171.4,46.7,178.6z'
      />
      <path
        fill={fillColor || '#283244'}
        d='M213.2,162.8c26.4,29.3,48.6,50.5,73.4,23.4c-8.3,47.2-66.2,112.5-138.1,112.5c-72,0-120-51.4-137.7-95.9
		c17.8,21.8,47.4,40.1,80,40.1C128.7,242.8,165.5,224.5,213.2,162.8'
      />
      <path
        fill={fillColor || '#dc004d'}
        d='M199.6,63.2c-30.4,0-56.6,20.3-66.7,26.8c31.2,7.5,45.6,23.3,71.3,50.7c25.7,27.4,42.4,48.9,57.9,48.9
		c14.8,0,29.8-12.4,29.8-42.7C291.9,89.8,229.7,63.2,199.6,63.2'
      />
      <path
        fill={fillColor || '#283244'}
        d='M143,0C84.6,0,41,45.8,29.8,60.1C18.6,74.4,0,108.2,0,143.2c0,35,20.7,33.1,24.1,33.1
		c4.4,0,24.5-1.7,45.7-28.9c20-25.7,64.9-91.6,131.3-91.6c42.8,0,74.8,33.4,80,40.3C261.7,48.9,213.8,0,143,0'
      />
    </g>
  </svg>
);

type OpusMobileLogoProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

OpusMobileLogo.defaultProps = {
  width: 45,
  height: 45,
  fillColor: '',
};

export default OpusMobileLogo;
