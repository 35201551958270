export enum EssAccountsTypes {
  'Gas' = 'Gas',
  'Electricity' = 'Electricity',
  'Renewables' = 'Renewables',
}

export interface ISiteData {
  canBeManuallyRead: boolean;
  essSiteID: string;
  endDate: string | null;
  startDate: string | null;
  essAccountID: number;
  essCustomerID: string;
  product: EssAccountsTypes;
  siteName: string;
}

export interface IMeterSerial {
  mpan: string;
  registers: Array<Api.IMeterReadings>;
}

export interface ISiteMeterDetail {
  essSiteID: string; // TODO
  essCustomerID: string;
  product: EssAccountsTypes;
  siteName: string;
  meters: Array<IMeterSerial>;
  // canBeManuallyRead: boolean;
  // endDate: string | null;
  // startDate: string | null;
  // essAccountID: number;
}

export interface IMeterItemData {
  essCustomerID: string;
  essSiteID: number;
  gasMeterID?: number;
  meterID?: number;
  genmeter?: boolean;
  renewable?: boolean;
  serialNumber?: string;
  essElecMeterID?: number;
  meterSerialNumber?: string;
  essElecMeteringSystemID?: number;
  id?: number;
  mpanCore?: number;
  siteId?: number;
}

export interface IGasRegisterData {
  description: string;
  essCustomerID: string;
  essSiteID: number;
  meterSerialNumber: string;
  essGasMeterID: number;
  lastRead: number;
  lastReadDate: string; // "2020-01-11T00:00:00"
  mprn: string;
  read: number | null;
  readDate: string | null;
  elecMeterRegisterID?: string;
}
export interface IElecRegister {
  description: string;
  elecMeterID: string;
  elecMeterRegisterID: string;
  lastReadDate: string;
  lastRead: number;
  meterSerialNumber: string;
  mpanCore: number;
}

export interface IElecRegisterData {
  description: string;
  elecMeterID: string;
  elecMeterRegisterID: string;
  essCustomerID: string;
  essSiteID: number;
  isHalfHourly: boolean;
  lastRead: number;
  lastReadDate: string; // "2021-05-21T00:00:00"
  llf: string;
  meterSerialNumber: string;
  mpanCore: number;
  mtsCode: number;
  profileClass: number;
  read: number | null;
  readDate: string | null;
}

export interface ICustomersFilterItem {
  customerName: string;
  essCustomerID: string;
}

export interface IAccountsFilterItem {
  accountName: string;
  essCustomerID: string;
  essAccountID: number;
}

export interface IMeterOption extends Common.IOption {
  siteReference?: string;
}
