// Modules
import { useContext } from 'react';
import styled from 'styled-components';

// Components
import { Icon } from '../../common/components';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

// Utils
import { TagManager } from '../utils/analytics/TagManager';

// Styles
const Wrapper = styled.div`
  display: flex;
  align-items: inherit;

  svg {
    margin-right: 7px;
  }

  svg path {
    fill: ${({ theme }) => theme.icons.default};
  }

  a {
    text-decoration: none;
    max-width: 205px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

//TODO: change link to "how to guide"
export const DraxProfileContactInfo = () => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const { accountManager, email, phone } = userDetail.userProfile;

  const onHowToLinkClicked = () => {
    TagManager.pushData({
      event: 'Launch How To Guide',
    });
  };

  return (
    <>
      <div className='profileText'>
        <div>
          Find troubleshooting advice and other info in our{' '}
          <a
            onClick={onHowToLinkClicked}
            href='https://assets.ctfassets.net/jl0e441lxc8n/3MTEqx1Z6Cd9JGHu95GO3n/2623602336d5382cbf6aacc2992155de/My_Drax_Energy_-_Customer_How_to_guide.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            how to guide
          </a>
          .
          <br />
          <a href='https://energy.drax.com/contact-us/' target='_blank' rel='noopener noreferrer'>
            Contact us
          </a>{' '}
          if you need any further help.
        </div>
      </div>
      <div style={{ marginTop: '15px' }}>Account Manager: {accountManager}</div>
      <Wrapper>
        <Icon size={17} icon={'envelope'} />
        <a href={`mailto:${email}`}>{email}</a>
      </Wrapper>
      <Wrapper>
        <Icon size={17} icon={'headset'} />
        <a href={`tel:${phone}`}>{phone}</a>
      </Wrapper>
      <div style={{ marginTop: '15px' }}>Opening hours:</div>
      <div>8:30am to 5:00pm</div>
    </>
  );
};
