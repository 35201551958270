import styled from 'styled-components';
import { white } from '../../../../common/styles/colors';
import { respondTo } from '../../../../common/styles/mixins';
import { fontSizeMedium } from '../../../../common/styles/variables';

export const MeterReadingDataWrapper = styled.div`
  .widget__message {
    background-color: rgba(40, 50, 68, 0.8);
    border-radius: 30px;
    padding: 40px 40px 60px;
  }

  .reading__fields {
    border-radius: 30px;
    background-color: rgba(40, 50, 68, 0.8);
    padding: 15px 15px;
    p {
      color: ${white};
    }
    ${respondTo.mobile`
      padding: 25px 35px 20px;
    `}

    .submit__meter__btn {
      ${respondTo.desktop`
        justify-content: flex-end;
      `}

      & > div {
        width: 100%;
        ${respondTo.desktopXl`
          width: 50%;
        `}

        a {
          ${respondTo.desktopXl`
            max-width: 220px;
            `}
          display: flex;
          justify-content: center;
        }
      }
    }

    & > p {
      color: ${white};
      font-size: ${fontSizeMedium};
    }

    .meterReadingRow {
      border-top: 1px solid white;
      padding-top: 4px;
    }

    .readingMessage {
      padding-top: 10px;
      margin-left: -5px;
    }
  }

  .meter__link {
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    max-width: 90%;

    position: absolute;
    bottom: 20px;
    right: 15px;

    margin: auto;
    ${respondTo.desktop`
      right: 35px;
    `}

    ${respondTo.desktop`
      max-width: 70%;
    `}
  }
`;
