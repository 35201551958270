// Modules
import React from 'react';

// Styles
import { InternalPageContainer } from './InternalPage.styles';

// Components
import { ViewAsCustomerWidget } from './ViewAsCustomerWidget';

export function InternalPage() {
  return (
    <InternalPageContainer>
      <div className='welcome'>
        <p>Welcome</p>
      </div>
      <ViewAsCustomerWidget />
    </InternalPageContainer>
  );
}
