const DraxLogo = ({ width, height, className, fillColor }: DraxLogoProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 283.5 119.5' className={className} width={width} height={height}>
    <path
      fill={fillColor}
      d='M66.1 0c-4.8 0-8.7 4-8.7 8.9v24.8c-5.4-2.5-13.7-4.8-21.6-4.8C14.5 28.9 2.3 41.6.5 62.1 0 68 0 79.8.5 85.5c1.8 20.3 15.4 34 37.6 34 16 0 29.4-7.1 33.7-10.2 2.6-1.8 3.1-3.6 3.1-6.6V8.9C74.8 4 71 0 66.1 0zm-8.8 96.7c-3.3 2.5-11.9 5.9-19 5.9-12.7 0-19.5-6.6-20.5-19.3-.5-5.1-.3-14.9 0-19.3.7-12 6.7-18.3 19.2-18.3 8.6 0 16.8 3 20.3 5.1v45.9zm69.4-49.5c-3 0-6.9-1.6-12.4-1.5-7.8.2-11.4 4.1-11.4 13.2v50.8c0 5-4 8.7-8.9 8.7-4.6 0-8.6-3.8-8.6-8.7V56.1c0-16.7 9.7-27.2 27.7-27.2 5.6 0 11.6.8 15 1.8 5 1.5 6.8 4.3 6.8 8.3.1 3.6-2.4 8.2-8.2 8.2zM169 28.9c-10.1 0-20.5 2.6-25.3 5-4.5 2-5.8 5.3-5.8 8.1 0 5.1 4.8 8.9 12.1 6.6 4.6-1.7 11.2-3.6 18-3.6 12.9 0 18.3 4.8 18.3 16v5.4c-7.6-1.8-13.2-2.8-21-2.8-23.1 0-33.5 13-33.5 27.4 0 16.2 11.2 28.6 33.8 28.6 17.2 0 29.7-6.1 34.3-9.2 2.5-1.7 3.1-3.5 3.1-6.4V60.9c.2-22.3-12.9-32-34-32zm17.3 70.2c-1.6 1.3-7.8 5.1-19.2 5.1-11.7 0-18.2-5-18.2-13.9 0-7.9 7.3-12.4 17.3-12.4 7.4 0 12.7 1 20 2.8v18.4zm97.2 11.3c0 4.1-4 8.3-8.6 8.3-2.6 0-5.3-1.2-6.9-3.3l-22.6-28.2-22.6 28.4c-1.7 2-4.3 3.1-6.9 3.1-5 0-8.6-4.1-8.6-8.6 0-2 .7-4.1 2.1-5.8l24.8-30.5-23.4-29.2c-1.5-1.8-2.3-3.8-2.3-5.9 0-4.5 4.1-8.4 8.9-8.4 2.5 0 5 1 6.6 3.1l21.3 27.1 21.5-26.9c1.8-2.3 4.5-3.3 7.1-3.3 4.8 0 8.6 4 8.6 8.4 0 2.1-.8 4.3-2.3 6.1l-23.3 29.1 24.4 30.5c1.3 1.7 2.2 3.9 2.2 6z'
    />
  </svg>
);

export type DraxLogoProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

DraxLogo.defaultProps = {
  width: 180,
  height: 68,
  fillColor: '#0A1464',
};

export default DraxLogo;
