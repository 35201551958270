import styled from 'styled-components';

import * as variables from '../../common/styles/variables';
import { respondTo } from '../../common/styles/mixins';
import * as colors from '../../common/styles/colors';
import { isDraxBrand } from '../../utils/common';

export const GridFilterText = {
  customerSelectText: isDraxBrand ? 'Customer account' : 'Customer',
  accountSelectText: 'Account',
  sitesSelectText: 'Site Ref - All',
  mpanMprnSelectText: isDraxBrand ? 'MPAN - All' : 'MPAN/MPRN - All',
  dateSelectorSelectText: 'Period',
  dateFilterSelectText: 'Date',
  clearAllSelectText: 'Clear All',
};

export const GridFilterWrapper = styled.div`
  .grid-filter {
    display: none;

    ${respondTo.tablet`
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    `};

    & > li {
      font-size: ${variables.fontSizeMedium};
      margin-right: ${variables.spacingMedium};
      width: fit-content;
      margin: 16px 16px 0px 0px;
      display: inline-block;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }

    .grid-date-filter-input-class {
      ${respondTo.tablet`
        padding: 0;
        border: none;
        color: ${colors.oxfordBlue};
        background-color: transparent;

        &:hover {
          color:${({ theme }) => theme.text.secondary};
        }

        .active {
          color:${({ theme }) => theme.text.secondary};
        }
      `};
    }

    .grid-date-filter-input-class.active {
      ${respondTo.tablet`
        color:${({ theme }) => theme.text.secondary};
      `};
    }

    .grid-date-filter {
      ${respondTo.tablet`
        .rmdp-action-buttons {
          display: none;
        }

        .only.rmdp-month-picker {
          width: 672px;
        }

        border-radius: ${variables.spacingSmall}
      `};
    }

    .icon-btn {
      white-space: nowrap;
      min-width: 100px;
      margin-left: 10px;
    }

    .icon-btn-download {
      white-space: nowrap;
      min-width: 100px;
      margin-left: 10px;
    }

    .grid-clear-all-button {
      white-space: nowrap;
      position: relative;

      ${respondTo.tablet`
        padding: 0;
        border: none;
        background-color: transparent;

        &:not(:disabled) {
          color: ${colors.oxfordBlue};

          &:hover {
            color: ${({ theme }) => theme.text.secondary};
          }
          .active {
            color: ${({ theme }) => theme.text.secondary};
          }
        }

      `};
    }

    .nooutline {
      border: none;
      display: inline-block;
      margin: 0;
      padding: 0 0.2em !important;
      font-weight: 400;
      color: ${({ theme }) => theme.text.secondary} !important;
    }
  }

  .mobile-grid-filter > li {
    padding: ${variables.spacingSmall} 0 ${variables.spacingSmall} 0;
  }

  .mobile-grid-filter {
    //hate to use important but there is an in-line style for this class in the date picker component that I had to override
    .rmdp-container {
      display: block !important;
    }
    .mobile-grid-date-filter {
      .rmdp-action-buttons {
        display: none;
      }
    }
    .grid-date-filter-input-class {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 5px;
      cursor: pointer;
      outline: none;
      -webkit-appearance: none;
      background-position-x: 100%;
      padding: 10px 15px;
      border: none;
    }
  }

  .account-grid-filter {
    padding: 4px 40px !important;
    border: 1px solid #bbbbbb;
    border-radius: 16px;
    min-height: 32px !important;
    color: ${colors.oxfordBlue};
    white-space: nowrap;
    width: 200px;
    background-color: ${colors.lightGrey};
    font-size: 16px;
    margin: 2px;

    .active {
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  .period-grid-filter {
    padding: 4px 30px !important;
    border: 1px solid #bbbbbb;
    border-radius: 16px;
    min-height: 30px;
    color: ${colors.oxfordBlue};
    white-space: nowrap;
    width: 200px;
    background-color: ${colors.lightGrey};
    font-size: 16px;
    margin: 2px;

    .active {
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  .disabled {
    opacity: 0.4;
    cursor: 'default';
    pointer-events: 'none';
  }

  .warning-text {
    font-size: 1rem;
  }

  .period-dropdown {
    margin-top: 20px !important;
  }
`;
