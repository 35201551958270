import { useState } from 'react';

import { Checkbox } from '../../common/components';
import { ContactPreferenceWrapper } from './ContactPreference.styles';

type ContactPreferenceProps = {
  values: {
    opusEmail: boolean;
    opusPost: boolean;
    opusPhone: boolean;
    opusSMS: boolean;
    thirdPartyEmail: boolean;
    thirdPartyPost: boolean;
    thirdPartyPhone: boolean;
    thirdPartySMS: boolean;
    marketResearchEmail: boolean;
    marketResearchPost: boolean;
    marketResearchPhone: boolean;
    marketResearchSMS: boolean;
  };
};

const defaultProps = {
  opusEmail: false,
  opusPost: false,
  opusPhone: false,
  opusSMS: false,
  thirdPartyEmail: false,
  thirdPartyPost: false,
  thirdPartyPhone: false,
  thirdPartySMS: false,
  marketResearchEmail: false,
  marketResearchPost: false,
  marketResearchPhone: false,
  marketResearchSMS: false,
};

export const ContactPreference = ({ values }: ContactPreferenceProps) => {
  const [opusEmail, setOpusEmail] = useState<boolean>(values.opusEmail);
  const [opusPost, setOpusPost] = useState<boolean>(values.opusPost);
  const [opusPhone, setOpusPhone] = useState<boolean>(values.opusPhone);
  const [opusSMS, setOpusSMS] = useState<boolean>(values.opusSMS);

  const [thirdPartyEmail, setThirdPartyEmail] = useState<boolean>(values.thirdPartyEmail);
  const [thirdPartyPost, setThirdPartyPost] = useState<boolean>(values.thirdPartyPost);
  const [thirdPartyPhone, setThirdPartyPhone] = useState<boolean>(values.thirdPartyPhone);
  const [thirdPartySMS, setThirdPartySMS] = useState<boolean>(values.thirdPartySMS);

  const [marketResearchEmail, setMarketResearchEmail] = useState<boolean>(values.marketResearchEmail);
  const [marketResearchPost, setMarketResearchPost] = useState<boolean>(values.marketResearchPost);
  const [marketResearchPhone, setMarketResearchPhone] = useState<boolean>(values.marketResearchPhone);
  const [marketResearchSMS, setMarketResearchSMS] = useState<boolean>(values.marketResearchSMS);

  return (
    <ContactPreferenceWrapper>
      <p>How would you like us to contact you?</p>

      <div className='table-wrapper'>
        <table>
          <tbody>
            <tr>
              <td></td>
              <td>Email</td>
              <td>Post</td>
              <td>Phone</td>
              <td>SMS</td>
            </tr>
            <tr>
              <td>
                <span>Opus Energy Products</span>
              </td>
              <td>
                <Checkbox
                  id='OpusEmail'
                  onChange={(event: any) => setOpusEmail(event.target.checked)}
                  checked={opusEmail}
                />
              </td>
              <td>
                <Checkbox
                  id='OpusPost'
                  onChange={(event: any) => setOpusPost(event.target.checked)}
                  checked={opusPost}
                />
              </td>
              <td>
                <Checkbox
                  id='OpusPhone'
                  onChange={(event: any) => setOpusPhone(event.target.checked)}
                  checked={opusPhone}
                />
              </td>
              <td>
                <Checkbox id='OpusSMS' onChange={(event: any) => setOpusSMS(event.target.checked)} checked={opusSMS} />
              </td>
            </tr>
            <tr>
              <td>
                <span>Third Party Partner Products</span>
              </td>
              <td>
                <Checkbox
                  id='ThirdPartyEmail'
                  onChange={(event: any) => setThirdPartyEmail(event.target.checked)}
                  checked={thirdPartyEmail}
                />
              </td>
              <td>
                <Checkbox
                  id='ThirdPartyPost'
                  onChange={(event: any) => setThirdPartyPost(event.target.checked)}
                  checked={thirdPartyPost}
                />
              </td>
              <td>
                <Checkbox
                  id='ThirdPartyPhone'
                  onChange={(event: any) => setThirdPartyPhone(event.target.checked)}
                  checked={thirdPartyPhone}
                />
              </td>
              <td>
                <Checkbox
                  id='ThirdPartySMS'
                  onChange={(event: any) => setThirdPartySMS(event.target.checked)}
                  checked={thirdPartySMS}
                />
              </td>
            </tr>
            <tr>
              <td>
                <span>Market Research</span>
              </td>
              <td>
                <Checkbox
                  id='MarketResearchEmail'
                  onChange={(event: any) => setMarketResearchEmail(event.target.checked)}
                  checked={marketResearchEmail}
                />
              </td>
              <td>
                <Checkbox
                  id='MarketResearchPost'
                  onChange={(event: any) => setMarketResearchPost(event.target.checked)}
                  checked={marketResearchPost}
                />
              </td>
              <td>
                <Checkbox
                  id='MarketResearchPhone'
                  onChange={(event: any) => setMarketResearchPhone(event.target.checked)}
                  checked={marketResearchPhone}
                />
              </td>
              <td>
                <Checkbox
                  id='MarketResearchSMS'
                  onChange={(event: any) => setMarketResearchSMS(event.target.checked)}
                  checked={marketResearchSMS}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ContactPreferenceWrapper>
  );
};

ContactPreference.defaultProps = defaultProps;
