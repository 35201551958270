import { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MobileView } from 'react-device-detect';

import logos from '../icons/logos';

import { BrokerLandingPageWrapper } from './BrokerLandingPageStyles';
import { sortAccountsByDisplay } from '../../utils/sortFilters';
import { Main } from '../../Main';
import { LayoutStyles } from '../layout/Layout.styles';
import { SwitchViewBtn } from '../../common/components/SwitchViewBtn';
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { ButtonPrimary } from '../../common/components';

type BrokerLandingPageProps = {
  selectedCustomerId: string;
  customers: Array<Common.IExpandedCustomerOption>;
  selectedCustomers: Array<Common.IExpandedCustomerOption>;
  contactPhone: string;
  contactEmail: string;
  handleViewDetails: Function;
  handleViewCustomers: Function;
};

const defaultProps = {
  customers: [],
  contactPhone: '',
  contactEmail: '',
};

const BrokerLandingPage = (props: BrokerLandingPageProps) => {
  const theme = useContext(ThemeContext);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  
  // Deep copy of props.customers
  const [availableCustomers, setAvailableCustomers] = useState<Array<Common.IExpandedCustomerOption>>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Array<Common.IExpandedCustomerOption>>([]);
  const [customer, setCustomer] = useState<Common.IExpandedCustomerOption | null>(null);
  const [dropdownDisabled, setDropdownDisabled] = useState<boolean>(false);

  const { desktopLogo: DesktopLogo, mobileLogo: MobileLogo }: any = process.env.REACT_APP_BRAND
    ? logos[process.env.REACT_APP_BRAND]
    : {};

  useEffect(() => {
    // Clear selectedCustomers
    globalContext.globalDetail.selectedCustomers.splice(0, globalContext.globalDetail.selectedCustomers.length);
  }, []);

  useEffect(() => {
    selectedCustomers.map((item: Common.IExpandedCustomerOption) => {
      removeFromAvailableCustomers(item.value);
      return item;
    });
  }, [selectedCustomers]);

  useEffect(() => {
    // deep copy Customer to keep dropdown up-to-date
    const customerCopy = JSON.parse(JSON.stringify(props.customers));

    const customersSorted: any = sortAccountsByDisplay(customerCopy);

    setAvailableCustomers(customersSorted);

    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      availableCustomers: customersSorted,
    });

  }, [props.customers]);

  const removeFromAvailableCustomers = (value: string) => {
    const index = availableCustomers.findIndex(c => c.value === value);
    if (index >= 0) {
      availableCustomers.splice(index, 1);

      globalContext.setGlobalDetail({
        ...globalContext.globalDetail,
        availableCustomers: availableCustomers,
      });
    }
  };

  const removeFromSelectedCustomers = (value: string) => {
    const index = selectedCustomers.findIndex(c => c.value === value);
    if (index >= 0) {
      selectedCustomers.splice(index, 1);
      
      globalContext.setGlobalDetail({
        ...globalContext.globalDetail,
        selectedCustomers: selectedCustomers,
      });

      if (selectedCustomers.length < 10) {
        setDropdownDisabled(false);
      }
    }
  };

  let logoLink = '/home';

  return (
    <BrokerLandingPageWrapper>
      <LayoutStyles className='indexPage'>
        <div className='container-fluid p-0 h-100vh'>
          <div>
            <div className='indexHeader' />
            <div className={`app row m-0`}>
              <NavLink to={logoLink} className='app-logo-tpi'>
                <DesktopLogo className='index-image logo' fillColor='#fff' />
                <DesktopLogo className='logo' />
              </NavLink>
              <ButtonPrimary className={'btnsignoutMobile'} title={'Sign out'} route='/signout' />
              <Main collapsed={true} />
              <MobileView>
                <SwitchViewBtn />
              </MobileView>
            </div>
          </div>
        </div>
      </LayoutStyles>
    </BrokerLandingPageWrapper>
  )
};

BrokerLandingPage.defaultProps = defaultProps;

export default BrokerLandingPage;
