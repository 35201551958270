import styled from 'styled-components';

import { white } from '../../common/styles/colors';

export const InternalPageContainer = styled.div`
  padding: 0 53px 0 13px;

  .welcome {
    margin-bottom: 115px;
    font-size: 40px;
    color: ${white};
  }
`;
