// Modules
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { GetQuoteModal } from './GetQuoteModal';
import { ButtonPrimary, ButtonTerinary } from '../../common/components';

// Utils
import { isOpusBrand } from '../../utils/common';
import { DATE_FORMAT } from '../../common/constants';
import { TagManager } from '../utils/analytics/TagManager';

// Styles
import { NestedRowAccountDetailsWrapper, NestedRowActionsWrapper, SubContentWrapper } from './SiteDetailsGrid.styles';

type ElecAndGasDetailsProps = {
  item: Api.ISiteData;
  disabled: boolean;
};

export const ElecAndGasDetails = ({ item, disabled }: ElecAndGasDetailsProps) => {
  const history = useHistory();
  const [showGetQuote, setShowGetQuote] = useState(false);

  const handleViewUsage = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    history.push('/home/energy-usage/data');
    TagManager.pushData({
      event: 'Launch Usage Data page (Site Details page)',
    });
  };

  const handleContactSummary = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    history.push('/home/contract-summary');
    TagManager.pushData({
      event: 'Launch Contract Summary page (Site Details page)',
    });
  };

  const handleGetQuote = (event: any) => {
    event.preventDefault();
    setShowGetQuote(true);
  };

  return (
    <SubContentWrapper className='pt-3'>
      <NestedRowAccountDetailsWrapper>
        <div className={`${disabled ? 'disabled' : ''}`}>
          <div className='header'>
            <span>Account ID</span>
            <span>Contract expires</span>
          </div>

          {item.accountID && (
            <div className='info-row'>
              <span>{item.accountID}</span>
              <span>{moment(item.contractEndDate).format(DATE_FORMAT)}</span>
            </div>
          )}

          {item.gasAccountID && (
            <div className='info-row'>
              <span>{item.gasAccountID}</span>
              <span>{moment(item.gasContractEndDate).format(DATE_FORMAT)}</span>
            </div>
          )}
        </div>
      </NestedRowAccountDetailsWrapper>

      <NestedRowActionsWrapper>
        {disabled ? (
          <div className='actions-column'>
            {!item.supplyActive && (
              <div className='non-active-message'>
                We stopped supplying this site on
                <span> {moment(item.supplyEndDate).format(DATE_FORMAT)}</span>
              </div>
            )}

            {item.outOfContract && (
              <div className='non-active-message'>
                {item.contractEndDate && item.gasContractEndDate ? (
                  'Your contract(s) have expired.'
                ) : (
                  <div>
                    Your contract expired on
                    <span>
                      {moment(item.contractEndDate).format(DATE_FORMAT) ||
                        moment(item.gasContractEndDate).format(DATE_FORMAT)}
                    </span>
                  </div>
                )}
              </div>
            )}

            {isOpusBrand && <ButtonPrimary title='Get a quote' onClick={handleGetQuote}></ButtonPrimary>}
          </div>
        ) : (
          <div className='actions-column'>
            <ButtonTerinary title='View your usage' onClick={handleViewUsage}></ButtonTerinary>

            <ButtonPrimary title='View contract summary' onClick={handleContactSummary}></ButtonPrimary>
          </div>
        )}
      </NestedRowActionsWrapper>

      {showGetQuote && <GetQuoteModal onClose={() => setShowGetQuote(false)} />}
    </SubContentWrapper>
  );
};
