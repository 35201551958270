import styled from 'styled-components';

import * as variables from '../../common/styles/variables';
import { respondTo } from '../../common/styles/mixins';


export const BrokerLandingPageWrapper = styled.div`
  .app-logo-tpi {
    padding: ${variables.spacingMedium} 0 ${variables.spacingMedium} ${variables.spacingMedium};
    position: absolute;
    top: -15px;
    left: -15px;

    ${respondTo.tablet`
      top: 0;
      left: 0;
      width: 100%;
    `}
  }

  .btnsignoutMobile {

    display: block;
    top: 100px;
    position: relative;
    margin-left: 15px;
    margin-right: 15px;

    ${respondTo.mobileXSm`
      display: flex;
      top: 0;
      position: absolute;
      right: 15px;
      margin-left: 0;
      margin-right: 0;
    `}

    ${respondTo.tablet`
      display: none;
    `}
  }
`;
