import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';

import { ExternalLink, ModalClose } from '../../../common/components';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

type StatementReadyProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Statement ready',
  messagevalues: {},
};

export const StatementReady = (props: StatementReadyProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  return (
    <>
      <span className='font-medium'>
        {props.messagevalues.date}
        <span className='ml-2'>{props.messagevalues.time}</span>
      </span>
      <Modal.Header>
        <Modal.Title>{props.messagevalues.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>Your latest statemment is here</p>
        <p>
          You can now see your latest statement for <span className='primary'>{userDetail.lastName}</span> in your
          online account.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ExternalLink
          onClick={() => {
            props.onHide();
          }}
          className='btn-primary'
          title='View my statement'
          target='_blank'
          href={`account-summary/statements?id=${props.messagevalues.callToAction}`}
        />
      </Modal.Footer>
    </>
  );
};

StatementReady.defaultProps = defaultProps;
