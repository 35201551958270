import styled from 'styled-components';

import * as variables from '../../../../common/styles/variables';
import * as colors from '../../../../common/styles/colors';
import { respondTo } from '../../../../common/styles/mixins';

export const SubmitMeterDownloadFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${variables.spacingMedium};
  background: ${colors.white};
  align-items: stretch;

  .baseline {
    align-items: baseline;
  }

  ${respondTo.desktop`
    flex-direction: row;
  `};

  .info-block {
    padding: ${variables.spacingSmall};
    background-color: ${colors.lightGrey};
    border-radius: ${variables.globalRadius};
    margin-bottom: ${variables.spacingMedium};
    width: 100%;

    ${respondTo.desktop`
      width: 48%;
      margin-bottom: 0;
    `};

    h4 {
      font-size: ${variables.fontSizeBase};
      font-family: ${variables.fontBold};
      font-weight: ${variables.fontWeightBold};
      margin: 0;
    }

    a {
      color: ${({ theme }) => theme.primary.color};
      text-decoration: none;
    }

    ul {
      margin-top: 5px;
      margin-bottom: 0;
      margin-left: 50px;
    }

    p {
      margin: 0px;
    }
  }

  .action-row {
    display: flex;
    justify-content: flex-end;

    a {
      margin: 0;
    }
  }

  .help-link {
    a {
      color: ${colors.hydroBlue} !important;
      text-decoration: underline !important;

      &:hover {
        text-decoration: underline !important;
        color: ${colors.draxDarkBlue} !important;
      }
    }
  }

  .upload-meter-column {
    margin-top: auto;
  }

  .moreInformationBtn {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
    padding-right: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    text-align: right;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
  }
`;
