import React, { ReactElement } from 'react';

import { Icon, StyledTitle } from '../../../common/components';
import * as colors from '../../../common/styles/colors';

type SearchNoResultsProps = {
  hidden: boolean;
  handleHide: Function;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultProps = {
  hidden: true,
};

export const SearchNoResults = (props: SearchNoResultsProps): ReactElement => (
  <>
    <StyledTitle>We couldn’t find any matching results</StyledTitle>

    <button className='close' onClick={() => props.handleHide()}>
      <span aria-hidden='true'>
        <Icon color={colors.white} size={25} icon='cross-circle' />
      </span>
    </button>

    <h3>Search tips:</h3>
    <ul>
      <li>Enter a specific term (like an invoice number)</li>
      <li>Broaden your search by using more general words</li>
      <li>Check the accuracy of your search term</li>
      <li>Use the navigation links to find what you're looking for</li>
    </ul>
  </>
);

SearchNoResults.defaultProps = defaultProps;
