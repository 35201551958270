// ********** REMINDER **********
// When you are adding icon here, don't forget to add same icon
// to another theme file

import {
  Mail,
  Offer,
  Account,
  BarChart,
  ControlPanel,
  Invoices,
  Wallet,
  Power,
  Upload,
  Warning,
  Success,
  AccountHealth,
  MeterHealth,
  LOAHealth,
  Tick,
  Loa,
  WarningInverse,
  Insights,
  CircleExclamation,
  Meter,
} from '../../../components/icons';
export const iconsDrax = {
  help: 'question-circle',
  profile: 'user',
  menuMessages: Mail,
  menuInvoicePayment: Invoices,
  menuData: BarChart,
  menuMeter: ControlPanel,
  menuAccount: Account,
  menuOffers: Offer,
  menuOurInsights: Insights,
  menuMPAN: Power,
  menuPayment: Wallet,
  menuReports: 'clipboard-check',
  menuContract: 'clipboard-pencil',
  menuInProgress: 'checkmark-circle',
  menuLoa: Loa,
  filezip: 'file-zip',
  userplus: 'user-plus',
  download: 'download2',
  upload: Upload,
  warning: Warning,
  success: Success,
  accountHealth: AccountHealth,
  meterHealth: MeterHealth,
  loaHealth: LOAHealth,
  tick: Tick,
  warningInverse: WarningInverse,
  insights: Insights,
  circleExclamation: CircleExclamation,
  meter: Meter,
} as any;
