import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Icon, StyledTitle } from '../../../common/components';
import FormElement from '../../../common/components/FormElement';
import * as colors from '../../../common/styles/colors';
import { iconsToUse } from '../../../common/styles/themes';
import * as variables from '../../../common/styles/variables';
import { respondTo } from '../../../common/styles/mixins';
import { FileTypesPermittedModal } from '../../mymessages/modalTemplates/FileTypesPermittedModal';

type UploadSupportingDocumentsProps = {
  upload: Function;
};

export const UploadSupportingDocumentsWrapper = styled.div`

  .dropzonecontainer {
    border 2px ${colors.hydroBlue} dashed;
    border-radius: 10px;
    position: relative;
    height: 180px;

    div {
      color: ${colors.hydroBlue};
    }

    .loaIcon {
      display: none;
      left: 2px;

      ${respondTo.tablet`
        display: block;
        left: 2px;
      `}

      ${respondTo.desktop`
        display: block;
        left: 50px;
        padding-left: 30px;
      `}
    }

    .title {
      font-size: 20px;
    }
  }

  .container {
    border: 1px solid red;
    height: 300px;
    display: flex;
    flex-direction: row;
    
    flex-wrap: wrap;
  }
  
  .item {
    border: 1px dashed blue;
    height: 50px;
  }

  .filecontainer {
    .file {
      border 2px ${colors.midGrey} solid;
      border-radius: 10px;
      color: ${colors.darkGrey};
    }
  
    .filepath {
      white-space: nowrap;
      padding-right: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .filesize {
      line-height: 0.8;
      padding-bottom: 5px;
    }

    .delete {
      cursor: pointer;
    }

    .close {
      margin-top: -16px;
      padding-right: 10px;
    }
  }

  .errors {
    color: ${colors.danger};
    padding-top: 10px;
  }

  .supportLink {
    cursor: pointer;
    padding-left: 10px;
    text-decoration: underline;
  }

  .commentBox {
    background-color: white;
    resize: none;
    font-style: italic;
  }
`;

export const UploadSupportingDocuments = (props: UploadSupportingDocumentsProps) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [comments, setComments] = useState<string>('');
  const [showUploadTooBigError, setShowUploadTooBigError] = useState<boolean>(false);
  const [showUploadMustBePdfError, setShowUploadMustBePdfError] = useState<boolean>(false);
  const [showUploadTooManyFilesError, setShowUploadTooManyFilesError] = useState<boolean>(false);
  const [showFileTypesPermittedModal, setShowFileTypesPermittedModal] = useState<boolean>(false);

  const totalSizeLimit = 20 * 1024 * 1024; // 20 MB

  const getTotalPreviousUploadedFilesSize: any = () => {
    var totalSize = 0;
    files.forEach((file: File) => {
      totalSize = totalSize + file.size;
    });
    return totalSize;
  };

  const formatSize = (size: number) => {
    const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    var i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    var exactSize = Math.round(size * 100) / 100 + ' ' + fSExt[i];
    return exactSize;
  };

  const findExistingFile: any = (droppedFiles: Array<File>) => {
    var existingFiles: Array<File> = [];

    droppedFiles.forEach((file: File) => {
      const index = files.findIndex(f => f.name === file.name);
      if (index >= 0) {
        existingFiles.push(file);
      }
    });
    return existingFiles;
  };

  const onDrop = (droppedFiles: Array<File>) => {
    setShowUploadTooBigError(false);
    setShowUploadMustBePdfError(false);
    setShowUploadTooManyFilesError(false);

    const existingFilesCount = files.length;
    const droppedFilesCount = droppedFiles.length;
    if (existingFilesCount + droppedFilesCount > 10) {
      setShowUploadTooManyFilesError(true);
      return;
    }

    let alreadyUploadedTotalSize = getTotalPreviousUploadedFilesSize();
    droppedFiles.forEach((file: File) => {
      alreadyUploadedTotalSize += file.size;
    });
    if (alreadyUploadedTotalSize > totalSizeLimit) {
      setShowUploadTooBigError(true);
      return;
    }

    // Check for duplicates
    const existingFiles = findExistingFile(droppedFiles);
    if (existingFiles) {
      deleteFiles(existingFiles);
    }

    setFiles([...files, ...droppedFiles]);
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 10,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/gif': ['.gif'],
      'image/png': ['.png'],
      'image/bmp': ['.bmp'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
      'application/xml': ['.xml'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/rtf': ['.rtf'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/pdf': ['.pdf'],
    },
  });

  useEffect(() => {
    const rejections: any = fileRejections;
    if (rejections) {
      rejections.map((file: any) => {
        if (file && file.errors.length > 0) {
          file.errors.map((error: any) => {
            if (error.code === 'too-many-files') {
              setShowUploadTooManyFilesError(true);
              return;
            } else if (error.code === 'file-invalid-type') {
              setShowUploadMustBePdfError(true);
              return;
            }
          });
        }
      });
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  useEffect(() => {
    const obj: any = {
      comments: comments,
      files: files,
    };

    props.upload(obj);
  }, [files, comments]);

  const deleteFiles = (filesToDelete: Array<File>) => {
    filesToDelete.forEach((file: File) => {
      const tmp = files;
      const index = tmp.findIndex(f => f.name === file.name);
      if (index >= 0) {
        tmp.splice(index, 1);
        setFiles([...tmp]);
      }
    });
  };

  const handleDelete = (event: any, file: File) => {
    event.preventDefault();
    deleteFiles([file]);
  };

  const renderedFiles = files.map((file: any, index: number) => (
    <React.Fragment key={index}>
      <div className='col-12 col-md-6 mt-2'>
        <div className='file'>
          <div className='pl-2'>
            <div className='filepath'>{`${file.path} - ${formatSize(file.size)}`}</div>
            <button className='close' onClick={event => handleDelete(event, file)}>
              <span aria-hidden='true'>
                <Icon color={colors.darkGrey} size={16} icon='cross' />
              </span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));

  return (
    <>
      <UploadSupportingDocumentsWrapper>
        <div className='formRowWrapper withInfo'>
          <div className='fieldWrapper'>
            <StyledTitle className='leftText mb-3' fontSize='20px'>
              Upload any supporting documents.
            </StyledTitle>

            <div className='pt-3'>
              <section className='dropzonecontainer d-flex'>
                <div {...getRootProps({ className: 'dropzone d-flex' })}>
                  <input {...getInputProps()} />
                  <div className='loaIcon mr-5 my-auto'>
                    <Icon icon={iconsToUse.loaHealth} size={80} />
                  </div>
                  <div className='d-flex flex-column w-100 my-auto'>
                    <div className='pt-4 title'>Click to add or drag files...</div>
                    <div className='pb-4' style={{ fontStyle: 'italic' }}>
                      maximum of 10 files - 20mb total
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className='errors'>
              {showUploadTooBigError && <div>You can only upload files to a maximum of 20MB</div>}
              {showUploadMustBePdfError && <div>See file types permitted for upload</div>}
              {showUploadTooManyFilesError && <div>You can only upload a maximum of 10 files</div>}
            </div>

            <div className='filecontainer gx-5'>
              {renderedFiles.length > 0 && 'Files added:-'}
              <div className='row'>{renderedFiles}</div>
            </div>
          </div>

          <div className='infoPart pt-0'>
            <p>
              Submit copies of any supporting documents to our Service team to help speed up the change of address
              process.<br></br>These could include tenancy termination notices or lease agreements to show lease expiry,
              for example.
            </p>

            <Icon icon={iconsToUse.circleExclamation} size={variables.iconSizeBase} />
            <a onClick={() => setShowFileTypesPermittedModal(true)} className='supportLink'>
              File types permitted for upload
            </a>

            <div className='pt-3'>
              Comments
              <FormElement.Textarea
                id='comments'
                placeholder={'Any additional comments...'}
                className='c-form__input commentBox'
                name='message'
                value={comments}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setComments(e.target.value);
                }}
                rows={8}
              />
            </div>
          </div>
        </div>
      </UploadSupportingDocumentsWrapper>

      <FileTypesPermittedModal
        show={showFileTypesPermittedModal}
        onHide={() => {
          setShowFileTypesPermittedModal(false);
        }}
      />
    </>
  );
};
