import styled from 'styled-components';
import * as mixins from '../../../common/styles/mixins';
import * as colors from '../../../common/styles/colors';
import { respondTo } from '../../../common/styles/mixins';

export const HealthWidgetWrapper = styled.div`

  cursor: pointer;

  position: relative;
  top: 0;
  transition: top ease 0.5s;

  ${respondTo.tablet`
    &:hover {
      top: -5px;
    }
  `};

  .health {
    &.healthy {
      border 4px solid ${colors.healthy};
    }

    &.notHealthy {
      border 4px solid ${colors.unhealthy};
    }

    &.loading {
      border 4px solid ${colors.trueblack};
    }

    ${mixins.widget};

    padding-top: 0;
    padding-bottom: 0;

    min-height: 80px;
    min-width: 190px;

    ${respondTo.desktop`
      min-height: 100px;
    `};
  }

  .badge {
    position: absolute;
    top: 15px;

    ${respondTo.desktop`
      top: 20px;
    `};
  }

  .spinner {
    // height: 10%;
    // position: relative;
  }

  .widget-loader {
    
    font-size: 12px;

    transform: none;
    width: 50%;
    top: 20px;

    position: absolute;

    .loader {
      width: 30px;
      height: 30px;
    }
  }

  .menu-dropdown {
    position: absolute;
    top: 25px;
    right: 15px;
  }
`;
