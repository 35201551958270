import { useState, useEffect } from 'react';

import { MyMessagesActionsPanelWrapper } from './MyMessagesActionsPanel.styles';
import { ModalTemplate } from './modalTemplates/ModalTemplate';
import { ButtonPrimary } from '../../common/components/ButtonPrimary/ButtonPrimary';

type MyMessagesActionsPanelProps = {
  show: boolean;
  messagevalues: Messages.IMessagesValues;
  checkedItems: Array<number>;
  checkedAlertIds: Array<number>;
  multiple?: boolean;
  handleToggle: Function;
  handleMarkAsRead: Function;
  handleMarkAsDeleted: Function;
  handleSettings: Function;
  gridData: Array<Messages.IAlertData>;
};

const defaultProps = {
  show: false,
  messagevalues: {},
  checkedItems: [],
  checkedAlertIds: [],
  multiple: false,
  handleToggle: undefined,
  handleMarkAsRead: undefined,
  handleMarkAsDeleted: undefined,
  handleSettings: undefined,
  gridData: {},
};

const MyMessagesActionsPanel = (props: MyMessagesActionsPanelProps) => {
  const [messagevalues, setMessageValues] = useState<Messages.IMessagesValues>();
  const [modalName, setModalName] = useState<string>('');
  const [multiple, setMultiple] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [numberOfSelectedUnReadMessages, setNumberOfSelectedUnReadMessages] = useState<number>(0);
  const handleClose = () => setShow(false);

  const handleShowModal = (event: any, name: string) => {
    const deleteAction =
      event.target.classList.contains('.delete-messages') || event.target.closest('.delete-messages');
    if (deleteAction) {
      setMessageValues({
        ids: [],
        date: '',
        time: '',
        title: '',
        callToAction: '',
        subject: '',
        accountReference: '',
        json: null,
        messageType: '',
      });
      setMultiple(true);
      setModalName('DeleteModal');
    } else {
      setModalName('MyMessagesSettingsModal');
    }
    setShow(true);
  };

  const handleMarkAsRead = (event: any) => {
    props.handleMarkAsRead(event);
  };

  const handleMarkAsDeleted = (event: any) => {
    props.handleMarkAsDeleted(event);
  };

  useEffect(() => {
    let count = 0;
    props.checkedItems.map(itemIndex => {
      var item = props.gridData[itemIndex];
      if (item && item.read === false) {
        count++;
        return;
      }
    });
    setNumberOfSelectedUnReadMessages(count);

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gridData, props.checkedItems]);

  return (
    <>
      <MyMessagesActionsPanelWrapper className='d-flex justify-content-end grid-settings'>
        <div className='mb-4 mt-0 position-relative'>
          <ButtonPrimary
            title='Delete messages'
            disabled={props.checkedItems.length <= 0}
            onClick={event => {
              handleMarkAsDeleted(event);
              event.preventDefault();
            }}
          />
          <div className='badge badge-pill'>{props.checkedItems.length > 1 && props.checkedItems.length}</div>
        </div>
        <div className='mb-4 mt-0 position-relative'>
          <ButtonPrimary
            title='Mark as read'
            disabled={numberOfSelectedUnReadMessages <= 0}
            onClick={event => {
              handleMarkAsRead(event);
              event.preventDefault();
            }}
          />
          <div className='badge badge-pill'>{numberOfSelectedUnReadMessages > 1 && numberOfSelectedUnReadMessages}</div>
        </div>

        {/* <li
          className='messages-settings'
          onClick={event => {
            handleShowModal(event, 'MyMessagesSettingsModal');
          }}
        >
          <IcomoonReact className='icon-trash primary' iconSet={iconSet} size={20} icon='cog' />
          <span className='d-none d-md-inline'>Message settings</span>
        </li> */}
      </MyMessagesActionsPanelWrapper>
      <ModalTemplate
        name={modalName}
        show={show}
        onHide={handleClose}
        messagevalues={messagevalues}
        multiple={multiple}
        handleConfirm={props.handleMarkAsDeleted}
      />
    </>
  );
};

MyMessagesActionsPanel.defaultProps = defaultProps;

export default MyMessagesActionsPanel;
