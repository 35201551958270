import { get } from '../../helpers/axiosWrapper';

interface IHealthRequest {
  entityType: string;
  accountReferences: Array<string>;
}

export const getHealthCheck = async (
  url: string | undefined,
  context: any,
  apiUrl: string,
  entityType: string,
  customerAccounts: Array<string>
): Promise<Common.IResult<Api.IHealthResponse>> => {
  if (!url) throw new Error('url not defined');

  let headers: IHealthRequest = {
    entityType,
    accountReferences: customerAccounts,
  };
  return await get(url + apiUrl, { headers }, context);
};
