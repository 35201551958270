import { useState } from 'react';

import { ButtonTerinary } from '../ButtonTerinary/ButtonTerinary';
import { ButtonDownloadWrapper } from './ButtonDownloadStyles';

type ButtonDownloadProps = {
  title?: string;
  className?: string;
  onClick?: (event: Common.IOption) => void;
  onButtonClick?: (isDropdownOpened: boolean) => void;
  outline: boolean;
  disabled: boolean;
  dontSaveSelectedIndex: boolean;
  icon?: string;
  options: Array<Common.IOption>;
};

const defaultProps = {
  title: 'Download',
  className: '',
  outline: true,
  disabled: false,
  onClick: undefined,
  options: [],
  dontSaveSelectedIndex: false,
};

export const ButtonDownload = (props: ButtonDownloadProps) => {
  const [active, setActive] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleClick = () => {
    setActive(!active);
    if (typeof props.onButtonClick === 'function') {
      props.onButtonClick(!active);
    }
  };

  const handleSelected = (index: number) => {
    if (!props.dontSaveSelectedIndex) {
      setSelectedIndex(index);
    }
    setActive(false);
  };

  let options = Array.from(props.options);

  const handleBlur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setActive(false);
    }
  };

  return (
    <ButtonDownloadWrapper className={props.className}>
      <li onBlur={(e: any) => handleBlur(e)}>
        <ButtonTerinary
          className={`mb-0 ${active ? 'open' : ''} ${!props.outline ? 'nooutline' : ''}`}
          route='/'
          title={props.title}
          disabled={props.disabled}
          icon={props.icon}
          onClick={(event: any) => {
            event.preventDefault();
            handleClick();
          }}
        />
        {!!options.length && (
          <ul className='dropdown__list'>
            {options.map((item, index) => (
              <li key={index}>
                <a
                  href={item.value}
                  className={`${selectedIndex === index ? 'selected' : ''}`}
                  onClick={(event: any) => {
                    event.preventDefault();
                    handleSelected(index);
                    if (props.onClick) {
                      props.onClick(item);
                    }
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {item.display}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    </ButtonDownloadWrapper>
  );
};

ButtonDownload.defaultProps = defaultProps;
