// Modules
import { useState } from 'react';

// Components
import { ExternalLink, Icon } from '../../../common/components';

// Styles
import { BulkMessagesWrapper } from './BulkMessages.styles';

// Context
import { Link } from 'react-router-dom';

const WIDGET_KEY = 'BulkMessages';

type BulkMessageProps = {
  messageData?: Array<Api.IAdminCreateMessageData>;
  TpiUser: boolean;
};

const defaultProps = {
  messageData: [],
  TpiUser: false,
};

export const BulkMessages = (props: BulkMessageProps) => {
  const [seeMore, setSeeMore] = useState<boolean>(false);

  function validateUrl(string: any) {
    if(/(http(s?)):\/\//i.test(string)) {
      return true;
    } else {
      return false;
    }
  }

  
  const fullCustomerDetails = [
    props.messageData?.map((messages: Api.IAdminCreateMessageData, index) => {
      if (!messages) {
        return null;
      }
      var tempMessage = JSON.parse(messages.json)

        const messageBody = tempMessage.message;
        const externalLink = messages.externalLink ? (validateUrl(messages.externalLink) ? messages.externalLink : `https://${messages.externalLink}`) : '';
        const internalLink = messages.internalLink;
        const total = props.messageData ? props.messageData.length : 0;

        const isFinalRow = (index: number): boolean => {

          if (total <= 3)
            return false;
          if (!seeMore) {
            return index === (3-1);
          }
          if (seeMore) {
            return index === total-1;
          }
          return false;
        }

        return (
          <div key={`${messageBody}-${index}`} className='row pl-3 customerRow'>

            {!isFinalRow(index) &&
              <span className='customerRowAccountName'>
                {internalLink && <li>{messageBody} ...{<Link className='linkBtn' to={internalLink}>More info</Link>}</li>}
                {externalLink && <li>{messageBody} ...{<ExternalLink title={'More info'} href={externalLink} target='_blank' className='linkBtn'/>}</li>}
                {(!internalLink && !externalLink) && <li>{messageBody}</li>}
              </span>
            }
            {isFinalRow(index) &&
              <>
                <div className='customerRowAccountNameLast'>
                {internalLink && <li>{messageBody} ...{<Link className='linkBtn' to={internalLink}>More info</Link>}</li>}
                {externalLink && <li>{messageBody} ...{<ExternalLink title={'More info'} href={externalLink} target='_blank' className='linkBtn'/>}</li>}
                {(!internalLink && !externalLink) && <li>{messageBody}</li>}
                </div>
                <div className='seeMoreBtn' onClick={() => setSeeMore(!seeMore)}>
                  {seeMore ? 'See less ' : 'See more '}
                    <Icon size={15} className='chevron' icon={seeMore ? 'chevron-up' : 'chevron-down'} />
                </div>
              </>
            }
          </div>
        );
      }),
  ];

  const shortCustomerDetails = fullCustomerDetails[0]?.slice(0, 3);

  return (
    <BulkMessagesWrapper>
      <div className='bulkMessagesOverview'>
        <h4 style={{ textDecoration: 'underline' }}>Important messages</h4>
        <div className='d-flex flex-column h-100 align-center-horizontal'>
          <div className='premises__text'>
            <div className='col-12 col-md-12 mt-2'>{seeMore ? fullCustomerDetails : shortCustomerDetails}</div>
          </div>
        </div>
      </div>
      <div className='bulkMessagesOverviewMobile'>
        <h4 style={{ textDecoration: 'underline' }}>Important messages</h4>
        <div className='d-flex flex-column h-100 align-center-horizontal'>
          <div className='premises__text'>
            <div className='col-12 col-md-12 mt-2'>{seeMore ? fullCustomerDetails : shortCustomerDetails}</div>
          </div>
        </div>
      </div>
    </BulkMessagesWrapper>
  );
};

BulkMessages.defaultProps = defaultProps;
