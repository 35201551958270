import styled from 'styled-components';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';

export const BrokerLandingSelectCustomersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .brokerLandingPageSelectCustomersMobile {
    ${mixins.widgetMobile};
  }

  .brokerLandingPageSelectCustomers {
    ${mixins.widgetDesktop};
  }

  .icon-user {
    ${mixins.flipHorizontal};
    position: relative;
    top: -${variables.spacingSmall};
  }
`;
