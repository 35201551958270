import { useContext } from 'react';

import { DirectDebitProcessContext } from '../directDebitProcess/directDebitProcessContext';
import { ReviewBlockWrapper, AccountsReviewGridWrapper } from './DDReview.styles';
import { EssAccountsTypes } from '../../../../types/account';
import { getFuelIcon } from '../../../../utils/getFuelIcon';

const AccountsReviewGrid = () => {
  const { selectedAccounts: accounts, accountNumber, sortCode } = useContext<any>(DirectDebitProcessContext);

  const rows = accounts.map((account: any, index: number) => {
    return (
      <div className='account-row' key={index}>
        <span className='account-col'>
          {getFuelIcon(account.product)}
          {account.essAccountID}
        </span>
        <span className='account-number-col'>{index === 0 && accountNumber}</span>
        <span className='sort-code-col'>{index === 0 && sortCode}</span>
      </div>
    );
  });

  return (
    <AccountsReviewGridWrapper>
      <div className='headers'>
        <span className='account-col'>Account</span>
        <span className='account-number-col'>Account number</span>
        <span className='sort-code-col'>Sort code</span>
      </div>

      <div className='rows'>{rows}</div>
    </AccountsReviewGridWrapper>
  );
};

export const AccountsReview = () => {
  const { selectedAccounts } = useContext<any>(DirectDebitProcessContext);
  const companyName = `Opus ${
    selectedAccounts[0].product === EssAccountsTypes.Electricity ? 'Energy' : 'Gas Supply'
  } Ltd`;

  return (
    <ReviewBlockWrapper>
      <h4>Acounts on Direct Debit</h4>
      <p>Any credit applied to your account will be applied before any payment is taken.</p>

      <AccountsReviewGrid />

      <p>The company name which will appear on your bank statement against the Direct Debit will be {companyName}.</p>

      <p>
        Your Direct Debit instruction will be confirmed to you via email within 3 working days or not later than 10
        working days. Any changed to frequency or amount of your collections will be advised to you 5 days in advance.
      </p>

      <p>
        Please note that Direct Debits take 10 days to set up or amend. We will automatically add any outstanding
        balances to your next automated payment.
      </p>

      <h5>Contact details</h5>
      <p>
        Opus Energy Ltd <br />
        Opus Energy House <br />
        8-10 The Lakes <br />
        Northampton NN4 7YD
      </p>
    </ReviewBlockWrapper>
  );
};
