import { css } from 'styled-components';

import * as variables from '../variables';
import * as mixins from '../mixins';
import * as colors from '../colors';
import { respondTo } from '../mixins';
import { isOpusBrand } from '../../../utils/common';

export const datePickerStyles = css`
  .no-border {
    .rmdp-input {
      ${mixins.borderNone};
    }
  }

  .full-width {
    .rmdp-container {
      display: block;
      width: 100%;
    }
  }

  .closeDatePicker {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 13px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.primary.bg};

    &:before,
    &:after {
      position: absolute;
      left: 8px;
      content: ' ';
      height: 9px;
      width: 1px;
      background-color: #fff;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  .quarterlyBtnSpan {
    width: 300px;
    left: 50%;
    margin-left: -150px;
    position: absolute;
    top: 20px;
  }

  .quarterlyBtn {
    cursor: pointer;
  }

  .only.rmdp-month-picker,
  .only.rmdp-year-picker {
    width: 260px !important;
    height: 270px !important;

    ${respondTo.tablet`
      width: ${variables.menuWidth} !important;
    `};
  }

  .rmdp-wrapper {
    max-height: 100vh;
    overflow-y: auto;
    ${mixins.scrollbars};
  }

  .rmdp-input {
    height: auto;
    display: block;
    width: 100%;
    height: 54px;
    background-clip: padding-box;
    padding: 0.85em ${variables.spacingSmall};
    border-radius: ${variables.inputRadius};
    color: ${({ theme }) => theme.input.color};
    margin: 0;
  }

  .rmdp-header {
    margin: ${isOpusBrand ? '20px 0 10px !important;' : '0 0 10px !important;'};
    padding: 0 !important;
  }

  .rmdp-header-values,
  .rmdp-week-day {
    color: ${({ theme }) => theme.datepicker.text};
    font-weight: bold;
  }

  .rmdp-day {
    color: ${colors.oxfordBlue};
  }

  .rmdp-arrow {
    border-color: ${({ theme }) => theme.datepicker.arrow};
    padding: 4px;
    margin-top: 3px;
  }

  .rmdp-arrow-container {
    margin: 0;
    width: auto;

    &.disabled {
      .rmdp-arrow {
        pointer-events: none;
        border-color: ${colors.midGrey};
      }
    }

    &:hover {
      background-color: inherit;
      box-shadow: none;
      color: ${({ theme }) => theme.datepicker.text};

      .rmdp-arrow {
        border-color: ${({ theme }) => theme.datepicker.arrow};
      }
    }
  }

  .rmdp-day-picker,
  .rmdp-month-picker,
  .rmdp-year-picker {
    .rmdp-day {
      &.rmdp-selected {
        span {
          background-color: ${({ theme }) => theme.datepicker.bg};
        }
      }

      &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
        ${respondTo.tablet`
          background-color: ${({ theme }) => theme.datepicker.bg};
          color: ${colors.white};
        `};
      }

      &.rmdp-selected span:not(.highlight) {
        ${mixins.transitionFast};
        background-color: ${({ theme }) => theme.datepicker.bg};
        box-shadow: none;
        color: ${colors.white};

        &:hover {
          ${mixins.transitionFast};
          color: ${colors.white};
        }
      }

      &.rmdp-today span {
        background-color: inherit;
        color: inherit;
      }

      &.rmdp-disabled,
      &.rmdp-deactive {
        color: ${colors.midGrey};
        pointer-events: none;

        span {
          color: ${colors.midGrey} !important;
        }
      }

      &.rmdp-range {
        ${mixins.transitionFast};
        background-color: ${({ theme }) => theme.datepicker.range};
        box-shadow: none;
        color: ${colors.white};

        span {
          background-color: ${({ theme }) => theme.datepicker.bg};

          &:hover {
            ${mixins.transitionFast};
            color: ${colors.white};
          }
        }
      }
    }
  }

  .rmdp-month-picker,
  .rmdp-year-picker {
    .rmdp-day {
      padding: 25px 0;
      margin: 0 4px;

      ${respondTo.tablet`
        margin: 0 10px;
      `};

      span {
        ${mixins.transitionFast};
        background-color: ${({ theme }) => theme.bg.secondary};

        &:hover {
          background-color: ${({ theme }) => theme.datepicker.bg};
          color: ${colors.white};
        }
      }

      &:not(.rmdp-selected) span:hover {
        background-color: ${({ theme }) => theme.datepicker.bg};
        color: ${colors.white};
      }

      .rmdp-selected span:hover {
        ${mixins.transitionFast};
        background-color: ${({ theme }) => theme.datepicker.bg};
        color: ${colors.white};
      }

      &.rmdp-disabled,
      &.rmdp-deactive {
        color: ${colors.midGrey};

        span,
        span:hover {
          color: ${colors.midGrey};
        }
      }

      &.rmdp-range {
        background-color: ${({ theme }) => theme.datepicker.bg};
        color: ${colors.white};

        span {
          ${mixins.transitionFast};
          background-color: ${({ theme }) => theme.datepicker.bg} !important;
          color: ${colors.white} !important;

          &:hover {
            ${mixins.transitionFast};
            background-color: ${({ theme }) => theme.datepicker.bg};
            color: ${colors.white};
          }
        }
      }
    }
  }

  .rmdp-ym {
    margin-bottom: 2px;

    .rmdp-day:not(.rmdp-selected) span {
      ${mixins.transitionFast};
      background-color: ${({ theme }) => theme.bg.secondary};
      color: ${({ theme }) => theme.datepicker.text};

      &:hover {
        ${mixins.transitionFast};
        background-color: ${({ theme }) => theme.bg.secondary};
        color: ${({ theme }) => theme.datepicker.text};
      }
    }
  }

  .rmdp-action-button {
    color: ${({ theme }) => theme.datepicker.text};
  }

  .rmdp-calendar {
    padding: ${variables.spacingLarge} ${variables.spacingSmall} ${variables.spacingSmall};
  }

  .rmdp-calendar-container-mobile {
    background-color: rgba(40, 50, 68, 0.6); /* oxfordBlue as RGBA*/
  }
`;
