import { useEffect, useState, useContext } from 'react';

import { ReactSelectStyled } from '../../../common/components/FormSelect/FormSelect.styles';
import { SelectIndicator } from '../../../common/components/FormSelect/SelectIndicator';
import { SelectCustomerWrapper } from './SelectCustomer.styles';
import { BRANDS } from '../../../consts';
import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';

type SelectCustomerProps = {
  viewAsCustomer: boolean;
  selectedValue: { value: string } | null;
  customers: Array<Common.IOption>;
  handleChange: Function;
  handleViewAsCustomerToggle: Function;
};

const defaultProps = {
  viewAsCustomer: false,
  selectedValue: undefined,
  customers: [],
};

export const SelectCustomer = (props: SelectCustomerProps) => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const [customer, setCustomer] = useState<{ value: string } | null>(props.selectedValue);

  useEffect(() => {
    if (!props.viewAsCustomer) setCustomer(null);
  }, [props.viewAsCustomer]);

  const handleChange = (event: any) => {
    setCustomer(event);
    props.handleChange(event);
  };

  const handleViewAsCustomerToggle = (event: any) => {
    props.handleViewAsCustomerToggle();
  };

  const handleChangeCustomer = (event: any) => {
    event.preventDefault();
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      showBrokerLandingPage: true,
    });
  };

  const brand = process.env.REACT_APP_BRAND;

  return brand === BRANDS.DRAX ? (
    <SelectCustomerWrapper {...props}>
      {/* TODO:  Might want to remove this and revisit this problem later */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href='#' onClick={handleChangeCustomer}>
        Change customer
      </a>
    </SelectCustomerWrapper>
  ) : (
    <SelectCustomerWrapper {...props}>
      {/* TODO use toggle from Drax Design System when available */}
      <label className='switch'>
        View as customer
        <input type='checkbox' checked={props.viewAsCustomer} onChange={handleViewAsCustomerToggle} />
        <span className='slider round' />
      </label>

      <div className='selectCustomerDropDown'>
        <>
          <ReactSelectStyled
            options={props.customers}
            classNamePrefix={'DashboardSelect'}
            value={props.customers.filter(option => option.value === customer?.value)}
            getOptionLabel={({ value, display }: any) => `${value} - ${display}`}
            onChange={handleChange}
            isDisabled={!props.viewAsCustomer}
            placeholder='Select customer'
          />
        </>
      </div>
    </SelectCustomerWrapper>
  );
};

SelectCustomer.defaultProps = defaultProps;

export default SelectCustomer;
