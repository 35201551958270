import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import * as mixins from '../../common/styles/mixins';
import { respondTo } from '../../common/styles/mixins';

export const MyMessagesSettingsModalWrapper = styled.div`
  tr {
    ${mixins.transitionFast};
    cursor: pointer;

    ${respondTo.tablet`
      cursor: default;
    `};

    .actions-wrapper {
      opacity: 0;

      a {
        pointer-events: none;
      }
    }

    &.selected {
      td {
        background-color: ${colors.lightGrey};

        ${respondTo.tablet`
          background-color: inherit;
        `};
      }

      .actions-wrapper {
        opacity: 1;
        pointer-events: all;

        a {
          pointer-events: all;
        }
      }

      .site-name input {
        background-color: ${colors.white};

        ${respondTo.tablet`
          background-color: ${colors.lightGrey};;
        `};
      }
    }

    &.open {
      + .hidden {
        display: table-row !important;
      }
    }

    .bordered {
      border-top: 1px solid ${colors.darkBlue};
    }
  }
`;
