import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import { ButtonPrimary } from '../../../common/components';

type RequestReceivedModalProps = {
  show: boolean;
  onYes: Function;
  onNo: Function;
  bodyTopText?: string;
  bodybottomText?: string;
};

const defaultProps = {
  show: true,
};

const RequestReceivedModalWrapper = styled.div`
  .modal-title {
    color: ${colors.hydroBlue};
  }
`;

export const RequestReceivedModal = (props: RequestReceivedModalProps) => {
  const titleText = "We've received your request";

  return (
    <Modal animation={false} className={'custom-modal'} show={props.show} backdrop='static' centered>
      <RequestReceivedModalWrapper>
        <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: 'center', textAlign: 'start' }}>
          <p>{props.bodyTopText}</p>
          <p>{props.bodybottomText}</p>
        </Modal.Body>
        <Modal.Footer className='p-0'>
          <div className='d-flex justify-content-end'>
            <ButtonPrimary onClick={() => props.onYes()} className={'m-0'} title={'Yes'} route='#' />
            <ButtonPrimary onClick={() => props.onNo()} className={'m-0 ml-2'} title={'No'} route='#' />
          </div>
        </Modal.Footer>
      </RequestReceivedModalWrapper>
    </Modal>
  );
};

RequestReceivedModal.defaultProps = defaultProps;
