import styled from 'styled-components';

import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';

export const ProcessStepIndicatorWrapper = styled.div`
  padding: ${variables.spacingSmall} 5px;
  text-align: center;

  h4 {
    font-size: ${variables.fontSizeLargeX};
    color: ${colors.darkBlue};
    transition: font-size 0.3s;
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: ${variables.fontSizeSmall};
    color: ${colors.darkBlue};
    margin: 0;
  }

  &.active {
    h4 {
      color: ${({ theme }) => theme.text.secondary};
    }

    p {
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  ${mixins.respondTo.tablet`
    h4 {
      font-size: ${variables.fontSizeLargeXL};
    }
    p {
      font-size: ${variables.fontSizeMedium};
    }
  `};

  ${mixins.respondTo.desktop`
    padding: ${variables.spacingSmall};
    h4 {
      font-size: ${variables.fontSizeLargeX};
    }
    p {
      font-size: ${variables.fontSizeBase};
    }
  `};
`;

export const ProcessStepIndicatorMovingPremisesWrapper = styled.div`
  font-family: 'Segoe UI';
  color: ${colors.midGrey};

  ul.arrow-steps li.active {
    background-color: #f0f5ff;
    color: ${({ theme }) => theme.text.secondary};

    .numberCircle {
      background: ${({ theme }) => theme.text.secondary};
    }
  }

  ul.arrow-steps li.visited {
    background-color: #f0f5ff;
    color: ${({ theme }) => theme.text.secondary};

    .numberCircle {
      background: ${({ theme }) => theme.text.secondary};
    }
  }

  ul.arrow-steps li {
    background-color: transparent;
    position: relative;
    text-indent: 0.5em;
  }

  ul.arrow-steps li:after {
    content: '';
    position: absolute;
    right: 14px;
    margin-right: 0.4em;
    width: 2.3em;
    padding-top: 4em;
    z-index: 1;
    transform: rotate(45deg);
    border-bottom: 0;
    border-left: 0;
  }

  ul.arrow-steps li.active:after {
    box-shadow: 50px -6px 0 45px #fff;
  }

  ul.arrow-steps {
    height: 45px;
    list-style: none;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    width: 220px;
  }

  ul.arrow-steps li {
    display: inline-block;
    line-height: 45px;
    height: 50px;
    min-width: 130px;
    position: relative;
    transition: min-width 0.5s;
    width: 100%;
  }

  .numberCircle {
    line-height: 30px;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    background: ${colors.darkMidGrey};
    display: inline-block;
  }

  .numberCircle p {
    padding: 0 0 0 0.1em;
    font-size: ${variables.fontSizeLarge};
    color: #fff;
  }
`;
