import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { SearchItemCollectionWrapper } from './SearchItemCollectionStyles';
import SearchItem from '../SearchItem/SearchItem';
import { SearchResultsContext, SearchResultsContextProps } from '../context/searchResultsContext';

type SearchItemCollectionProps = {
  type: Common.searchItemType;
  name: string;
  items: Array<Api.ISearchItem>;
  handleSelected: Function;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultProps = {
  type: '',
  name: '',
  items: [],
};

export const SearchItemCollection = (props: SearchItemCollectionProps): ReactElement => {
  const { searchResult, setSearchResult } = useContext<SearchResultsContextProps>(SearchResultsContext);

  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  useEffect(() => {
    if (selectedRow !== -1) {
      setSelectedRow(-1);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  const handleRowSelect = (event: any, selectedIndex: number | null) => {
    if (selectedIndex !== undefined) {
      setSearchResult({ selectedCollection: props.name });
      setSelectedRow(selectedIndex);
    }
  };

  return (
    <SearchItemCollectionWrapper>
      {props.items && props.items.length > 0 && <h5 className='primary'>{props.name}</h5>}

      <div>
        {props.items &&
          props.items.map((el, i) => (
            <div
              key={i}
              onClick={event => {
                handleRowSelect(event, i);
              }}
              className={`${
                searchResult.selectedCollection === props.name && selectedRow === i ? 'selectedItem' : ''
              } search-item`}
            >
              <SearchItem type={props.type} item={el} handleSelected={props.handleSelected} />
            </div>
          ))}
      </div>
    </SearchItemCollectionWrapper>
  );
};

SearchItemCollection.defaultProps = defaultProps;
