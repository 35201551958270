import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';
import { respondTo } from '../../../common/styles/mixins';
import { isDraxBrand } from '../../../utils/common';

export const InvoiceAndPaymentText = {
  header: isDraxBrand ? 'Account balance' : 'Invoice & Payments',
  headerBtn: isDraxBrand ? 'Account balance' : 'Invoice & Payments',
  makeaCardPaymentBtn: 'Make a payment (coming soon)',
  statementsBtn: 'Statements',
  selectText: 'All accounts',
  selectTextINC: 'Select account',
  downloadReport: 'Download Report',
};

export const InvoiceAndPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .balanceOverviewMobile {
    ${mixins.widgetMobile};
    .overview {
      border-radius: 20px;
      background-color: ${colors.white};
    }
    .paymentsMade {
      font-size: ${variables.fontSizeLargeX};
      white-space: nowrap;
    }
    .selecteditem {
      max-width: 216px;
    }
    .value {
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  .balanceOverview {
    ${mixins.widgetDesktop};

    ${respondTo.tablet`
      .paymentsMade {
        font-size: ${variables.fontSizeLargeX};
        white-space: nowrap;
      }
      .energy-icon {
        padding-left: 4.1em;
      }
      .selecteditem {
        max-width: 222px;
      }
      .overview {
        min-height: 120px;
      }
    `};

    ${respondTo.desktopXl`
      .paymentsMade {
        font-size: ${variables.fontSizeLargeX};
      }
      .energy-icon {
        padding-left: 2.8em;
      }
    `};

    ${respondTo.desktopXXl`
      .paymentsMade {
        font-size: ${variables.fontSizeLargeXL};
      }
      .dropdown {
        max-width: 250px;
      }

      .overview {
        min-height: 153px;
      }
    `};

    .value {
      color: ${({ theme }) => theme.text.secondary};
    }

    .overview {
      border-radius: 20px;
      background-color: ${colors.white};
    }
  }

  --totalincredit: ${({ theme }) => theme.primary.bg};
  --totalpaymanually: ${({ theme }) => theme.primary.bg};
`;
