import React from 'react';

const Error = () => {
  return (
    <div className='inner'>
      <div className='row'>
        <div className='col-12 no_stuff'>
          <h1 className='largeIcon'>
            <span className='icon icon-warning' />
            <br />
          </h1>
          <h1>Error</h1>
          <p>Unfortuantely, there has been an error. Please contact support at Opus Energy.</p>
        </div>
      </div>
    </div>
  );
};
export default Error;
