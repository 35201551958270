// Modules
import { useState, useEffect, useContext } from 'react';

// Components
import { HeaderSearch } from './HeaderSearch';
import { ButtonPrimary, Loading } from '../../common/components';
import { HeaderHelpSearch } from './HeaderHelpSearch';
import { HeaderHelpProfile } from './HeaderHelpProfile';
import { SearchInput } from '../search/SearchInput/SearchInput';
import { SearchResults } from '../search/SearchResults/SearchResults';
import { RoutingToTypes } from '../search/SearchActions/SearchActions';
import { SearchNoResults } from '../search/SearchResults/SearchNoResults';

// Types
import { EssAccountsTypes } from '../../types/account';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { SearchDetailContext, SearchDetailContextProps } from '../../context/searchDetail/searchDetailContext';

// Utils
import { documentClassName } from '../utils/text/text';
import { TagManager } from '../utils/analytics/TagManager';

// Styles
import { HeaderWrapper } from './Header.styles';
import { ResultsWrapper } from '../search/SearchResults/SearchResults.styles';
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';
import { HeaderHelpSupport } from './HeaderHelpSupport';
import ServiceUpdate, { updateType } from '../serviceupdate/ServiceUpdate';

type HeaderProps = {
  collapsed: boolean;
  handleToggleSidebar: Function;
  handleCollapsedChange: Function;
  passwordExpire: boolean;
  passwordExpiretime: string;
  hasRenewableAccount: boolean;
  serviceMessageType: updateType;
  serviceMessageText: string;
};

const defaultProps = {
  passwordExpire: false,
  passwordExpiretime: '5 days',
  hasRenewableAccount: true,
  serviceMessageType: '',
  serviceMessageText: '',
};

export const Header = (props: HeaderProps) => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const { userDetail, setUserDetail, isSMEBroker } = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [showNoResults, setShowNoResults] = useState<boolean>(false);
  const [toggleSearch, setToggleSearch] = useState<boolean>(false);
  const [showSearchPanel, setShowSearchPanel] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [items, setItems] = useState<any>(null);

  const handleSearchTermChange = (query: string) => {
    setSearchTerm(query);
  };

  const handleSearchStatusChange = (isSearching: boolean) => {
    setShowSearchPanel(isSearching);
    setShowLoader(isSearching);
  };

  useEffect(() => {
    // Check for Search Results Data
    if (items) {
      setShowLoader(false);
      let showResult = false;

      if (items?.accountItems?.length > 0) {
        showResult = true;
      } else if (items?.invoiceItems?.length > 0) {
        showResult = true;
      } else if (items?.mpanItems?.length > 0) {
        showResult = true;
      } else if (items?.mprnItems?.length > 0) {
        showResult = true;
      } else if (items?.siteItems?.length > 0) {
        showResult = true;
      } else if (items?.statementItems?.length > 0) {
        showResult = true;
      }

      setShowSearchResults(showResult);
      setShowNoResults(!showResult);
      if (!showResult) {
        // Search failed
      }
    }
  }, [items]);

  useEffect(() => {
    if (isSMEBroker()) {
      setShowSearch(false);
    } else if (userDetail.customerType) {
      setShowSearch(true);
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail]);

  const handleHide = () => {
    // Hide Results in Timer due to conflict with onMouseClick
    setTimeout(function () {
      setShowSearchResults(false);
      setShowNoResults(false);
      setShowSearchPanel(false);
      setSearchTerm('');
      setItems(null);
    }, 300);
  };

  const handleShow = (show: boolean) => {
    setToggleSearch(show);
    setShowSearchResults(false);
  };

  const getEnergyType = (type: string) => {
    switch (type) {
      case 'icon-electricity-portal':
        return EssAccountsTypes.Electricity;
      case 'icon-gas-portal':
        return EssAccountsTypes.Gas;
      case 'icon-renewables-portal':
        return EssAccountsTypes.Renewables;
    }
    return '';
  };

  const handleSelected = (item: any, routingTo: RoutingToTypes) => {
    if (item) {
      const address = item.address;
      const energyType = getEnergyType(item.productClass);

      let invoiceId,
        accountId,
        siteId = '';
      if (item.resultType === 'Invoice') {
        invoiceId = item.subjectID;
      } else if (item.resultType === 'Account') {
        accountId = item.subjectID;
      } else if (item.resultType === 'Site') {
        siteId = item.subjectID;
      }

      const mpan = item.mpan;
      const mprn = item.mprn;
      const invoice = item.invoiceNumber;
      const name = item.name;
      const number = item.number;
      const statement = item.statementNumber;

      const type = item.resultType;

      searchContext.setSearchDetail({
        ...searchContext.searchDetail,
        address,
        energyType,
        invoiceId,
        accountId,
        siteId,
        mpan,
        mprn,
        invoice,
        name,
        number,
        statement,
        type,
        routingTo,
      });
    }

    handleHide();
  };

  const handleBlur = (event: any) => {
    if (
      event?.relatedTarget?.className?.indexOf('searchresult') >= 0 ||
      event?.relatedTarget?.className?.indexOf('search-input') >= 0 ||
      event?.relatedTarget?.className?.indexOf('scrolltop') >= 0
    ) {
      // dont close the searchresult window

      if (event?.relatedTarget?.className?.indexOf('scrolltop') >= 0) {
        let searchResult: any = document.getElementsByClassName('searchresult')[0];
        searchResult.focus();
      }
    } else {
      handleHide();
    }
  };

  const isIndexPage = documentClassName() === 'indexPage';

  return (
    <div className='main-wrapper-inner' tabIndex={-1} onBlur={handleBlur}>
      <HeaderWrapper>
        <div className='header-wrapper justify-content-end'>
          {showSearch && (
            <div className='col d-none d-md-inline'>
              <div className='service-message'>
                {props.serviceMessageText && (
                  <ServiceUpdate type={props.serviceMessageType} message={props.serviceMessageText} />
                )}
              </div>
              {/* Removed for Release 1a */}
              {/* <SearchInput
                items={items}
                setItems={setItems}
                searchTerm={searchTerm}
                className='d-flex justify-content-end'
                handleSearchTermChange={handleSearchTermChange}
                handleSearchStatusChange={handleSearchStatusChange}
              /> */}
            </div>
          )}
          <div className='mobile-wrapper d-flex'>
            {showSearch && (
              <>
                <div className='iconButton d-md-none'>
                  {/* Removed for Release 1a */}
                  {/* <HeaderSearch handleShow={handleShow} show={toggleSearch} /> */}
                </div>
                <div className='iconButton'>
                  {/* Removed for Release 1a */}
                  {/* <HeaderHelpSearch /> */}
                </div>
              </>
            )}
            {!showSearch && (
              <>
                <div className='mobile-hidden' />
              </>
            )}
            <div className={`pl-4 pt-2 ${isIndexPage && 'home-email-address'}`}>{userDetail.emailAddress}</div>
            <div className='iconButton d-none d-md-inline'>
              <HeaderHelpProfile hasRenewableAccount={props.hasRenewableAccount} />
            </div>
            <div className='iconButton d-none d-md-inline'>
              <HeaderHelpSupport />
            </div>
          </div>
          <div className='d-md-none'>
            {/* Removed for Release 1a */}
            {/* {toggleSearch && showSearch && isIndexPage && (
              <SearchInput
                items={items}
                setItems={setItems}
                showPlaceHolder={false}
                searchTerm={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                handleSearchStatusChange={handleSearchStatusChange}
              />
            )} */}
          </div>
        </div>
      </HeaderWrapper>

      <ResultsWrapper tabIndex={-1} hidden={!showSearchPanel} className='searchresult' isIndexPage={isIndexPage}>
        {showLoader && <Loading containerClassName='search-loader' />}

        {showSearchResults && !showLoader && (
          <SearchResults items={items} handleHide={handleHide} handleSelected={handleSelected} />
        )}

        {showNoResults && !showLoader && <SearchNoResults handleHide={handleHide} />}
      </ResultsWrapper>
    </div>
  );
};

Header.defaultProps = defaultProps;
