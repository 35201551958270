import { createContext } from 'react';

import { IUserDetail } from './userDetail';

export type UserDetailContextProps = {
  userDetail: IUserDetail;
  setUserDetail: Function;
  isSMECustomer: () => boolean;
  isSMEBroker: () => boolean;
  isCorpCustomer: () => boolean;
  isCorpBroker: () => boolean;
};

export const UserDetailContext = createContext({} as UserDetailContextProps);
