import React from 'react';

import useVisible from '../../hooks/useVisibile';
import { ButtonPrimary } from '../../common/components';
import { StyledCard, StyledCardContainer, StyledExpandableContent, StyledLogoWrapper } from './StyledCard';
import { TagManager } from '../utils/analytics/TagManager';

type ExclusiveOffersCardProps = {
  options: Array<Api.IExclusiveOffersData>;
  title?: string;
};

const defaultProps = {
  options: [],
  title: '',
};

interface CardItemProps {
  header: string;
  imageUrl: string;
  imagePath: string;
  className?: string;
  description: string;
  partnerName: string;
  handleNavigation: (url: string) => void;
}

const ExclusiveOffersCardItem = (props: CardItemProps) => {
  const { header, imagePath, className, imageUrl, description, partnerName, handleNavigation } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useVisible(false);

  const onClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const navigationHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    TagManager.pushData({
      event: 'View Exclusive Offer',
      partnerName,
      header,
    });

    handleNavigation(imageUrl);
  };

  return (
    <StyledCard isExpanded={isComponentVisible} className={className} ref={ref} onClick={onClick}>
      <h5>{partnerName}</h5>
      <StyledLogoWrapper>
        <img src={imagePath} alt={header} />
      </StyledLogoWrapper>
      <h6>{header}</h6>
      <StyledExpandableContent isVisible={isComponentVisible}>
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <ButtonPrimary title='View' onClick={navigationHandler} />
      </StyledExpandableContent>
    </StyledCard>
  );
};

export const ExclusiveOffersCard = (props: ExclusiveOffersCardProps) => {
  const handleNavigation = (url?: string) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <StyledCardContainer className='row'>
      {props.options
        .filter(item => item.enabled && !item.deleted)
        .map((item, index) => (
          <div key={index} className='col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12'>
            <ExclusiveOffersCardItem
              {...item}
              className=''
              key={item.partnerName + index}
              handleNavigation={handleNavigation}
            />
          </div>
        ))}
    </StyledCardContainer>
  );
};

ExclusiveOffersCard.defaultProps = defaultProps;
