// Modules
import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { DateObject, Calendar } from 'react-multi-date-picker';

// Components
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';
import { XModalClose } from '../XModalClose';

// Styles
import { DateFilterModalBody } from './DateFilterModal.styles';
import { isMobileOnly } from 'react-device-detect';

type DateFilterProps = {
  show: boolean;
  onHide: () => void;
  enableDaily?: boolean;
  enableMonthly?: boolean;
  selectedRange?: Ranges;
  onDateSelect: Function;
  previouslySelectedDate?: { begin: string | null; end: string | null };
};
DateFilterModal.defaultProps = {
  enableDaily: false,
  enableMonthly: false,
  selectedRange: undefined,
};
export enum Ranges {
  '2years' = '2 Years',
  '1year' = '1 Year',
  '6months' = '6 Months',
  'monthly' = 'Monthly',
  'daily' = 'Daily',
}
export type SelectDateResult = {
  type: 'range' | 'error';
  end?: moment.Moment;
  begin?: moment.Moment;
};

export function DateFilterModal(props: DateFilterProps) {
  // Refs
  const lastSelectedDateObjectRef = React.useRef<DateObject | null>(null);

  // Props
  const { show, onHide, enableDaily, onDateSelect, selectedRange, enableMonthly, previouslySelectedDate } = props;

  // State
  const [isShowingDayPicker, setIsShowingDayPicker] = React.useState(false);
  const [isShowingMonthPicker, setIsShowingMonthPicker] = React.useState(false);
  const [monthPickerValue, setMonthPickerValue] =
    React.useState<Date | DateObject | DateObject[] | string | undefined>('');
  const [dayPickerValue, setDayPickerValue] = React.useState<Date | DateObject | DateObject[] | string | undefined>('');
  const isShowingCalendar = (props.enableDaily && isShowingDayPicker) || (props.enableMonthly && isShowingMonthPicker);

  // Handlers
  const handleClick = (option: Ranges) => {
    const result: SelectDateResult = { type: 'range' };

    result.begin = moment();
    // We subtract one less months
    // Because we are including current month
    // So we won't have 7 months in range for "6 months" period
    switch (option) {
      case Ranges['6months']:
        result.end = moment().subtract(5, 'months').startOf('month');
        break;
      case Ranges['1year']:
        result.end = moment().subtract(11, 'months').startOf('month');
        break;
      case Ranges['2years']:
        result.end = moment().subtract(23, 'months').startOf('month');
        break;
      case Ranges['daily']:
        setDayPickerValue(lastSelectedDateObjectRef.current || new Date());
        setIsShowingDayPicker(true);
        break;
      case Ranges['monthly']:
        setMonthPickerValue(lastSelectedDateObjectRef.current || new Date());
        setIsShowingMonthPicker(true);
        break;

      default:
        result.type = 'error';
        console.error(`DateFilterModal: Unhandled date range: "${option}"`);
        break;
    }

    if (typeof onDateSelect === 'function' && option !== Ranges['monthly'] && option !== Ranges['daily']) {
      onDateSelect(result, option);
    }
  };

  const handleHideClick = () => {
    if (isShowingMonthPicker) {
      setIsShowingMonthPicker(false);
    } else if (isShowingDayPicker) {
      setIsShowingDayPicker(false);
    } else if (typeof handleHideClick === 'function') {
      onHide();
    }
  };

  const handleDateChange = (dateObject: DateObject) => {
    try {
      const result: SelectDateResult = {
        type: 'range',
      };

      if (dateObject) {
        let option = null;
        let endDate = moment(dateObject.toDate()).startOf('day');
        let startDate = moment(endDate);

        lastSelectedDateObjectRef.current = null;
        if (isShowingMonthPicker) {
          option = Ranges.monthly;
          result.begin = startDate.endOf('month');
          result.end = endDate;
          lastSelectedDateObjectRef.current = dateObject;
        } else if (isShowingDayPicker) {
          option = Ranges.daily;
          result.begin = startDate.endOf('day');
          result.end = endDate;
          lastSelectedDateObjectRef.current = dateObject;
        }

        if (typeof onDateSelect === 'function') {
          onDateSelect(result, option);
        }
      }
    } catch (error) {
      console.error(`Failed to handle date change`, error);
    } finally {
      setTimeout(() => {
        if (isShowingMonthPicker) {
          setIsShowingMonthPicker(false);
        } else if (isShowingDayPicker) {
          setIsShowingDayPicker(false);
        }
      }, 300);
    }
  };

  React.useEffect(() => {
    if (previouslySelectedDate?.end) {
      if (selectedRange === Ranges['daily']) {
        setDayPickerValue(new Date(previouslySelectedDate.end));
      } else if (selectedRange === Ranges['monthly']) {
        setMonthPickerValue(new Date(previouslySelectedDate.end));
      }
    }
  }, [previouslySelectedDate, selectedRange]);

  const getDatePickerOptions = () => {
    let options: Ranges[] = [Ranges['2years'], Ranges['1year'], Ranges['6months']];

    if (enableMonthly) {
      options.push(Ranges['monthly']);
    }
    if (enableDaily) {
      options.push(Ranges['daily']);
    }
    return options;
  };

  return (
    <Modal
      className='d-flex justify-content-center align-items-center modal-full-width'
      centered
      onHide={handleHideClick}
      show={show}
    >
      <Modal.Header>
        <XModalClose close={handleHideClick} />
      </Modal.Header>
      <Modal.Body className='d-flex flex-column flex-md-row justify-content-around align-items-center'>
        {isShowingCalendar ? (
          <>
            {props.enableDaily && isShowingDayPicker && (
              <Calendar
                numberOfMonths={isMobileOnly ? 1 : 2}
                minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
                maxDate={new Date()}
                value={dayPickerValue}
                onChange={handleDateChange}
              />
            )}
            {props.enableMonthly && isShowingMonthPicker && (
              <Calendar
                minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
                maxDate={new Date()}
                onlyMonthPicker
                value={monthPickerValue}
                onChange={handleDateChange}
              />
            )}
          </>
        ) : (
          <DateFilterModalBody
            className={`d-flex flex-column justify-content-center align-items-center flex-md-row justify-content-sm-around`}
          >
            {getDatePickerOptions().map(option => (
              <ButtonPrimary
                key={option}
                className='date__btn'
                onClick={() => handleClick(option)}
                title={option}
                route='#'
                outlined={option !== selectedRange}
              />
            ))}
          </DateFilterModalBody>
        )}
      </Modal.Body>
    </Modal>
  );
}
