import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import IcomoonReact from 'icomoon-react';

/* context */
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';

/* components */
import {
  FilterDropDown,
  ButtonDownload,
  SideMenuFilter,
  MobileActionsPanel,
  CustomDatePicker,
  Icon,
} from '../../common/components';
import { SelectAccountModal } from './SelectAccountModal';
import { isDraxBrand, isOpusBrand } from '../../utils/common';

/* styles */
import { GridFilterWrapper, GridFilterText } from './GridFilter.styles';
import * as colors from '../../common/styles/colors';
import iconSet from '../../assets/selection.json';
import FromToDateRangePickerModal from '../../common/components/FromToDateRangePickerModal/FromToDateRangePickerModal';
import { sortAccounts } from '../../utils/sortFilters';
import PortalFilter from '../../common/components/PortalFilter/PortalFilter';
import { DATE_FORMAT_SHORT, REVERSED_DATE_FORMAT_HYPHEN } from '../../common/constants';
import { FilterClearButton } from '../../common/components/FilterClearButton/FilterClearButton';
import { DateObject } from 'react-multi-date-picker';
import { TableFilterOption } from '../../common/components/TableFilter/TableFilter';
import { DateMonthDayFilterModal, Ranges, SelectDateResult } from '../../common/components/DateMonthDayFilterModal';
import { iconsToUse } from '../../common/styles/themes';
import * as variables from '../../common/styles/variables';
import { WarningInverse } from '../icons';

type GridFilterProps = {
  customerData: Array<Common.IOption>;
  accountData: Array<Common.IOption>;
  siteData: Array<Common.IOption>;
  mpanMprnData: Array<Common.IOption>;
  meterTypeFilterOptions: Array<Common.IOption>;
  renderAdditionalListItems: () => Array<React.ReactNode> | null;
  selectedType: string;
  handleCustomerFilterChange: Function;
  handleAccountFilterChange: Function;
  handleSiteFilterChange?: Function;
  handleMpanMprnFilterChange?: Function;
  handleDownload?: Function;
  handleExport?: Function;
  handleDateFilterChange?: Function;
  handlePeriodFilterChange?: Function;
  handleDateSelectorFilterChange?: Function;

  handleDateSelectorDayMonthFilterChange?: Function;

  handleMeterTypeFilterChange?: Function;
  handleClearAllClick: Function;

  enableDownloadBtn?: boolean;
  downloadOptions?: Array<Common.IOption>;

  enableExportBtn?: boolean;
  exportOptions?: Array<Common.IOption>;

  enableAddUserBtn?: boolean;

  showCustomer: boolean;
  showAccount: boolean;
  showSites: boolean;
  showMpanMprn: boolean;
  showDateSelector: boolean;
  showDate: boolean;
  showPeriod: boolean;
  showClearAll: boolean;
  showMeterType: boolean;
  showDownload: boolean;
  showAddUser: boolean;
  showExport: boolean;
  addUserEnabled: boolean;
  showIcon?: boolean;
  showNhhAndMixed?: boolean;
  showMeterWarning?: boolean;
  showConsentReminder?: boolean;
  showOutstandingPaymentWarning: boolean;

  dateSelectorData?: Array<Common.IOption>;
  disableDateSelector?: boolean;

  askForCustomerAccount?: boolean;

  handleAddUser?: Function;

  previouslySelectedDate?: { begin: string | null; end: string | null };
};

const defaultProps = {
  customerData: [],
  accountData: [],
  siteData: [],
  mpanMprnData: [],
  meterTypeFilterOptions: [],
  renderAdditionalListItems: () => null,
  selectedType: '',

  enableDownloadBtn: false,
  downloadOptions: [],

  enableExportBtn: false,
  exportOptions: [],

  enableAddUserBtn: false,

  showCustomer: true,
  showAccount: true,
  showSites: true,
  showMpanMprn: false,
  showDateSelector: false,
  showDate: false,
  showPeriod: false,
  showClearAll: true,
  showMeterType: false,
  showDownload: false,
  showAddUser: false,
  showExport: false,
  addUserEnabled: false,
  showIcon: true,
  disableDateSelector: false,
  askForCustomerAccount: true,
  showNhhAndMixed: false,
  showMeterWarning: false,
  showOutstandingPaymentWarning: false,
  showConsentReminder: false,
};

export const GridFilter = (props: GridFilterProps) => {
  const theme = useContext(ThemeContext);
  const { userDetail, setUserDetail, isSMEBroker, isSMECustomer, isCorpBroker, isCorpCustomer } =
    useContext<UserDetailContextProps>(UserDetailContext);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const [isSiteFilterDropdownEnabled, setIsSiteFilterDropdownEnabled] = useState<boolean>(false);
  const [isAccountFilterDropdownEnabled, setIsAccountFilterDropdownEnabled] = useState<boolean>(false);
  const [isCustomerFilterDropdownEnabled, setIsCustomerFilterDropdownEnabled] = useState<boolean>(false);
  const [isMpanMprnFilterDropdownEnabled, setIsMpanMprnFilterDropdownEnabled] = useState<boolean>(false);
  const [isDateSelectorDropdownEnabled, setIsDateSelectorDropdownEnabled] = useState<boolean>(false);
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');
  const [placeHolder, setPlaceHolder] = useState<string>(GridFilterText.dateFilterSelectText);

  const [showSelectAccountModal, setShowSelectAccountModal] = useState<boolean>(false);
  const [customerAccountsOptions, setCustomerAccountsOptions] = useState<any>([]);

  const [accountSelectedIndex, setAccountSelectedIndex] = useState<number | undefined>(undefined);
  const [siteSelectedIndex, setSiteSelectedIndex] = useState<number | undefined>(undefined);
  const [mpanMprnSelectedIndex, setMpanMprnSelectedIndex] = useState<number | undefined>(-1);
  const [dateSelectorSelectedIndex, setDateSelectorSelectedIndex] = useState<number | undefined>(1); // 1 Year

  const [customerSelectedIndex, setCustomerSelectedIndex] = useState<number | undefined>(undefined);
  const [meterTypeSelectedIndex, setMeterTypeSelectedIndex] = useState<number | undefined>(undefined);
  const [showPeriodModal, setShowPeriodModal] = useState<boolean>(false);
  const [showMonthlyModal, setShowMonthlyModal] = useState<boolean>(false);
  const [showDailyModal, setShowDailyModal] = useState<boolean>(false);

  const [dateSelectorList, setDateSelectorList] = useState<Array<Common.IOption> | undefined>(props.dateSelectorData);
  const [dateSelectorPlaceHolder, setDateSelectorPlaceHolder] = useState<string>(GridFilterText.dateSelectorSelectText);

  const [dateTitle, setDateTitle] = useState<string>('Date period - All');
  const [accountRef, setAccountRef] = useState<any>('Account Ref');
  const [startDateMobile, setStartDateMobile] = useState<any>();
  const [endDateMobile, setEndDateMobile] = useState<any>();

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const currentDate = new Date();
  const startDateWindow = new DateObject({
    year: currentDate.getUTCFullYear() - 2,
    month: currentDate.getUTCMonth() + 1,
    day: 1,
  });
  const endDateWindow = new DateObject({
    year: currentDate.getUTCFullYear(),
    month: currentDate.getUTCMonth() + 1,
    day: currentDate.getUTCDate(),
  });

  const [startDateMobileMaxDate, setStartDateMobileMaxDate] = useState<any>(
    endDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)
  );
  const [endDateMobileMinDate, setEndDateMobileMinDate] = useState<any>(
    startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)
  );

  const resetSitesFilter = () => {
    setIsSiteFilterDropdownEnabled(isDraxBrand);
  };

  const onCustomerSelect = (account: Common.IOption) => {
    handleCustomerFilterChange(account);
    setShowSelectAccountModal(false);
  };

  const autoSelectAccount = () => {
    const customerId = userDetail?.essCustomerId;
    setShowSelectAccountModal(false);
    setAccountRef(`Acc - ${customerId}`);

    var selectedOption = {
      display: customerId,
      value: customerId,
    };

    props.handleCustomerFilterChange(selectedOption);
  };

  const getCustomerAccountsOptions = async () => {
    let sortedAccounts: Api.IEssAccountData[];
    if (props.showNhhAndMixed) {
      sortedAccounts = sortAccounts(userDetail?.customerAccounts).filter(
        user => user.meteringPointType === 'Mixed' || user.meteringPointType === 'AllNHH'
      );
    } else {
      sortedAccounts = sortAccounts(userDetail?.customerAccounts);
    }

    const accounts = sortedAccounts?.map((item: any) => {
      return {
        display: `${item?.essAccountID} - ${item?.accountName}`,
        value: item?.essAccountID,
        meteringPointType: item?.meteringPointType,
      };
    });

    setCustomerAccountsOptions(accounts ? accounts : []);

    const selectedCustomerAccount = userDetail?.selectedCustomerAccount;

    if (sortedAccounts) {
      if (sortedAccounts.length > 1) {
        if (selectedCustomerAccount.length > 0) {
          setShowSelectAccountModal(false);
          setAccountRef(`Acc - ${selectedCustomerAccount}`);
        } else {
          if (!props.askForCustomerAccount) {
            autoSelectAccount();
          } else {
            // Auto show SelectAccount modal if more that one account
            setShowSelectAccountModal(true);
          }
        }
      } else if (sortedAccounts.length === 1) {
        setAccountRef(`Acc - ${accounts[0].value}`);

        if (!props.askForCustomerAccount && props.showMpanMprn) {
          autoSelectAccount();
        }
      }
    }

    // Clear selectedCustomerAccount
    setUserDetail({
      ...userDetail,
      selectedCustomerAccount: '',
    });
  };

  useEffect(() => {
    getCustomerAccountsOptions();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.customerAccounts]);

  useEffect(() => {
    if (
      (isSMECustomer() || isCorpCustomer() || isCorpBroker()) &&
      userDetail.customers.length > 0 &&
      !userDetail.viewAsCustomerToggle
    ) {
      setIsCustomerFilterDropdownEnabled(true);
    } else if (isSMEBroker()) {
      setIsCustomerFilterDropdownEnabled(false);
      resetSitesFilter();
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDraxBrand && customerAccountsOptions.length === 1) {
      setCustomerSelectedIndex(0);
      setIsCustomerFilterDropdownEnabled(false);
    }
  }, [customerAccountsOptions]);

  useEffect(() => {
    if (isDraxBrand) {
      setIsCustomerFilterDropdownEnabled(true);
      resetSitesFilter();
    } else if (userDetail.viewAsCustomerToggle) {
      setIsCustomerFilterDropdownEnabled(false);
      resetSitesFilter();
    } else if (!userDetail.viewAsCustomerToggle && userDetail.customers.length > 0) {
      setIsCustomerFilterDropdownEnabled(true);
      resetSitesFilter();
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.essCustomerId]);

  useEffect(() => {
    if (!isCustomerFilterDropdownEnabled) {
      setIsAccountFilterDropdownEnabled(true);
    } else {
      setIsAccountFilterDropdownEnabled(false);
    }
  }, [isCustomerFilterDropdownEnabled]);

  useEffect(() => {
    resetSitesFilter();
  }, [selectedCustomer, props.selectedType]);

  useEffect(() => {
    // Clear Selected
    setAccountSelectedIndex(undefined);
    resetSitesFilter();
    setPlaceHolder(GridFilterText.dateFilterSelectText);

    if (props.selectedType === 'Renewables') {
      setIsMpanMprnFilterDropdownEnabled(false);
      setIsDatePickerDisabled(true);
    } else {
      setIsDatePickerDisabled(false);
      setIsMpanMprnFilterDropdownEnabled(true);
    }
  }, [props.selectedType]);

  useEffect(() => {
    if (props.siteData.length === 1) {
      setIsSiteFilterDropdownEnabled(false);
      setSiteSelectedIndex(0);
    }
  }, [props.siteData]);

  useEffect(() => {
    if (props.mpanMprnData.length === 1) {
      setIsMpanMprnFilterDropdownEnabled(false);
      setMpanMprnSelectedIndex(0);
    } else {
      setIsMpanMprnFilterDropdownEnabled(true);
      setMpanMprnSelectedIndex(undefined);
    }
  }, [props.mpanMprnData]);

  useEffect(() => {
    if (startDateMobile || endDateMobile) {
      let tempDates: DateObject[] = [startDateMobile, endDateMobile];
      if (!startDateMobile) {
        tempDates[0] = startDateWindow;
      }
      if (!endDateMobile) {
        tempDates[1] = endDateWindow;
      }
      handlePeriodFilterChange(tempDates);
    } else {
      setDateTitle('Date period - All');
    }
  }, [startDateMobile, endDateMobile]);

  const handleCustomerFilterChange = (option: Common.IOption) => {
    const index = (customerAccountsOptions || props.customerData).findIndex(
      (i: Common.IOption) => i.value === option.value
    );

    setAccountSelectedIndex(undefined);
    setSiteSelectedIndex(undefined);
    setMpanMprnSelectedIndex(undefined);
    setIsMpanMprnFilterDropdownEnabled(true);
    setMeterTypeSelectedIndex(undefined);

    setPlaceHolder(GridFilterText.dateFilterSelectText);
    setSelectedCustomer(option.value);
    setCustomerSelectedIndex(index);
    setIsAccountFilterDropdownEnabled(true);

    props.handleCustomerFilterChange(option);
    setAccountRef(`Acc - ${option.value}`);

    setUserDetail({
      ...userDetail,
      essCustomerId: option.value,
    });
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    const index = props.accountData.findIndex(i => i.value === option.value);
    setAccountSelectedIndex(index);

    setSiteSelectedIndex(undefined);
    setMpanMprnSelectedIndex(undefined);

    setPlaceHolder(GridFilterText.dateFilterSelectText);
    resetSitesFilter();
    props.handleAccountFilterChange(option);
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    if (option) {
      const index = props.siteData.findIndex(i => i.value === option.value);
      setSiteSelectedIndex(index);
    } else {
      setSiteSelectedIndex(-1);
    }
    setAccountSelectedIndex(undefined);
    setMpanMprnSelectedIndex(undefined);

    setPlaceHolder(GridFilterText.dateFilterSelectText);
    if (props.handleSiteFilterChange) {
      props.handleSiteFilterChange(option);
    }
  };

  const handleMpanMprnFilterChange = (option: Common.IOption) => {
    let index = -1;
    if (option) {
      index = props.mpanMprnData.findIndex(i => i.value === option.value);
      setMpanMprnSelectedIndex(index);
    } else {
      setMpanMprnSelectedIndex(-1);
    }
    setAccountSelectedIndex(undefined);
    setSiteSelectedIndex(undefined);
    resetSitesFilter();

    setPlaceHolder(GridFilterText.dateFilterSelectText);
    if (props.handleMpanMprnFilterChange) {
      props.handleMpanMprnFilterChange(props.mpanMprnData[index], index);
    }
  };

  useEffect(() => {
    if (props.dateSelectorData && dateSelectorSelectedIndex) {
      const cnt = props.dateSelectorData.length;
      if (dateSelectorSelectedIndex >= cnt) {
        // Default back to 12 Months, this stops Month/Day being incorrectly selected
        setDateSelectorSelectedIndex(1);

        const option: any = {
          label: 'Last 12 months',
          value: '12Months',
        };

        setDateSelectorPlaceHolder(GridFilterText.dateSelectorSelectText);

        if (props.handleDateSelectorFilterChange) {
          props.handleDateSelectorFilterChange(option);
        }
      }
    }
  }, [props.dateSelectorData]);

  const handleMenuOpen = () => {
    setDateSelectorList(props.dateSelectorData);
  };

  const handleDateSelectorFilterChange = (option: Common.IOption) => {
    let index = -1;
    if (option) {
      if (dateSelectorList) {
        index = dateSelectorList.findIndex(i => i.value === option.value);
      }
      setDateSelectorSelectedIndex(index);
    } else {
      setDateSelectorSelectedIndex(-1);
    }

    if (option.value === 'Monthly') {
      setShowMonthlyModal(true);
    } else if (option.value === 'Daily') {
      setShowDailyModal(true);
    } else {
      setDateSelectorPlaceHolder(GridFilterText.dateSelectorSelectText);

      if (props.handleDateSelectorFilterChange) {
        props.handleDateSelectorFilterChange(option);
      }
    }
  };

  const updateDateSelectorListWithSelectedValue = (date: SelectDateResult, type: 'Monthly' | 'Daily') => {
    if (dateSelectorList) {
      var dateSelectorListCopy = JSON.parse(JSON.stringify(dateSelectorList));
      var item = dateSelectorListCopy.find((c: any) => c.display == type);

      var formattedDateDisplay = date.begin!.format('MMMM YY'); // Monthly
      if (type === 'Daily') {
        formattedDateDisplay = date.begin!.format('D MMMM YY'); // Daily
      }

      item.display = formattedDateDisplay;
      item.value = formattedDateDisplay;

      setDateSelectorList(dateSelectorListCopy);
    }
  };

  const handleDateSelectorDayMonthFilterChange = (
    date: SelectDateResult,
    option: any,
    isDaily: boolean,
    isMonthly: boolean
  ) => {
    if (isMonthly) {
      updateDateSelectorListWithSelectedValue(date, 'Monthly');
      setShowMonthlyModal(false);
    } else if (isDaily) {
      updateDateSelectorListWithSelectedValue(date, 'Daily');
      setShowDailyModal(false);
    }

    if (props.handleDateSelectorDayMonthFilterChange) {
      props.handleDateSelectorDayMonthFilterChange(date, option, isDaily, isMonthly);
    }
  };

  const handleClearAllClick = (): void => {
    setAccountSelectedIndex(undefined);
    setSiteSelectedIndex(undefined);
    setMpanMprnSelectedIndex(undefined);
    setIsMpanMprnFilterDropdownEnabled(true);
    setStartDateMobile(undefined);
    setEndDateMobile(undefined);
    resetSitesFilter();

    props.handleClearAllClick();
    setPlaceHolder(GridFilterText.dateFilterSelectText);
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      mobileFilterActive: false,
    });
  };

  const handleCloseMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      mobileFilterActive: false,
    });
  };

  const download = (option: Common.IOption) => {
    if (props.handleDownload) {
      props.handleDownload(option.value);
    }
  };

  const handleExport = (option: Common.IOption) => {
    if (props.handleExport) {
      props.handleExport(option.value);
    }
  };

  const handleAddUser = () => {
    if (props.handleAddUser) {
      props.handleAddUser();
    }
  };

  const handleDateFilterChange = (value: any) => {
    setAccountSelectedIndex(undefined);
    setSiteSelectedIndex(undefined);
    setMpanMprnSelectedIndex(undefined);
    resetSitesFilter();

    if (props.handleDateFilterChange) {
      if (typeof value === 'object') {
        let monthValue: string = JSON.stringify(value.month.name);
        let formattedYear: string = JSON.stringify(value.year);
        let formattedMonth: string = monthValue.replaceAll('"', '');
        let formattedMonthYear: string = `${formattedMonth} ${formattedYear}`;

        const shortMonthYear = `${value.month.number}/${formattedYear}`;
        setPlaceHolder(shortMonthYear);
        props.handleDateFilterChange(formattedMonthYear);
      } else {
        setPlaceHolder(value);
        props.handleDateFilterChange(value);
      }
    }
  };

  const handlePeriodFilterChange = (value: any) => {
    setAccountSelectedIndex(undefined);
    resetSitesFilter();

    if (props.handlePeriodFilterChange) {
      props.handlePeriodFilterChange(value);
    }

    const startDate = value[0];
    const endDate = value[1];

    if (startDate && endDate) {
      const dateFrom = startDate?.format(DATE_FORMAT_SHORT);
      const dateTo = endDate?.format(DATE_FORMAT_SHORT);
      setDateTitle(`${dateFrom} - ${dateTo}`);
    }
    setShowPeriodModal(false);
  };

  const handlePeriodFilterClose = () => {
    setShowPeriodModal(false);
    setDateTitle('Date period - All');

    if (props.handlePeriodFilterChange) {
      props.handlePeriodFilterChange([]);
    }
  };

  const handleStartDateMobileChange = (e: any) => {
    setStartDateMobile(e);
    setEndDateMobileMinDate(e);
  };

  const handleEndDateMobileChange = (e: any) => {
    setEndDateMobile(e);
    setStartDateMobileMaxDate(e);
  };

  const handleMeterTypeFilterChange = (meterType: string) => {
    const index = props.meterTypeFilterOptions.findIndex(i => i.value === meterType);
    setMeterTypeSelectedIndex(index);

    if (props.handleMeterTypeFilterChange) {
      props.handleMeterTypeFilterChange(meterType);
    }
  };

  const isCustomerFilterDisabled =
    (isDraxBrand && customerAccountsOptions.length < 2) || (isOpusBrand && !isCustomerFilterDropdownEnabled);

  let isClearAllDisabled =
    // Customer account hidden or disabled
    (!props.showCustomer || (props.showCustomer && isCustomerFilterDisabled)) &&
    (!props.showAccount || (props.showAccount && !isAccountFilterDropdownEnabled)) &&
    (!props.showSites || (props.showSites && !isSiteFilterDropdownEnabled)) &&
    (!props.showMpanMprn || (props.showMpanMprn && !isMpanMprnFilterDropdownEnabled)) &&
    (!props.showDateSelector || (props.showDateSelector && !isDateSelectorDropdownEnabled)) &&
    (!props.showDate || (props.showDate && isDatePickerDisabled)) &&
    (!props.showPeriod || (props.showPeriod && !showPeriodModal));

  if (props.siteData.length === 1 && !props.showPeriod) {
    isClearAllDisabled = true;
  }

  return (
    <GridFilterWrapper {...props}>
      <ul className='grid-filter mt-4'>
        {props.showCustomer && (
          <li>
            {isDraxBrand ? (
              <>
                <FilterClearButton
                  className={'account-grid-filter'}
                  title={accountRef}
                  disabled={isCustomerFilterDisabled}
                  onClick={(event: any) => {
                    event.preventDefault();
                    setShowSelectAccountModal(true);
                  }}
                  showClear={true}
                  showChevron={false}
                />

                {showSelectAccountModal && (
                  <SelectAccountModal
                    onCustomerSelect={onCustomerSelect}
                    accounts={customerAccountsOptions}
                    customerSelectedIndex={customerSelectedIndex}
                  />
                )}
              </>
            ) : (
              <FilterDropDown
                className='plain filter w-200'
                options={props.customerData}
                showBlankValue={false}
                showValue={false}
                selectText={GridFilterText.customerSelectText}
                handleChange={handleCustomerFilterChange}
                enabled={isCustomerFilterDropdownEnabled}
              />
            )}
          </li>
        )}

        {props.showOutstandingPaymentWarning && (
          <li className='ml-auto mt-n1'>
            <div className='outstanding-payment-text'>
              <Icon icon={WarningInverse} size={variables.iconSizeSmall} />
              Indicates that you have an outstanding payment to make.{' '}
            </div>
          </li>
        )}

        {props.showMeterWarning && (
          <li className='ml-auto mt-0'>
            <div className='warning-text'>
              <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> Indicates that we haven't received a
              read for this meter recently
            </div>
          </li>
        )}

        {props.showConsentReminder && (
          <li className='ml-auto mt-0'>
            <div className='warning-text'>
              <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> Please update consent
            </div>
          </li>
        )}
      </ul>

      <ul className='grid-filter mb-2'>
        {props.showAccount && (
          <li>
            <FilterDropDown
              className='plain filter w-200'
              options={props.accountData}
              showBlankValue={false}
              showValue={false}
              selectText={GridFilterText.accountSelectText}
              handleChange={handleAccountFilterChange}
              enabled={isAccountFilterDropdownEnabled}
              selectedIndex={accountSelectedIndex}
            />
          </li>
        )}
        {props.showSites && (
          <li>
            <PortalFilter
              className={` ${props.siteData.length > 1 ? '' : 'disabled'}`}
              selectedIndex={siteSelectedIndex}
              options={props.siteData}
              placeholder={GridFilterText.sitesSelectText}
              onChange={handleSiteFilterChange}
              isClearable={true}
            />
          </li>
        )}
        {props.showMpanMprn && (
          <li>
            <PortalFilter
              className={` ${props.mpanMprnData.length > 1 ? '' : 'disabled'}`}
              selectedIndex={mpanMprnSelectedIndex}
              options={props.mpanMprnData}
              placeholder={GridFilterText.mpanMprnSelectText}
              onChange={handleMpanMprnFilterChange}
              isClearable={true}
            />
          </li>
        )}
        {props.showDateSelector && (
          <li>
            <PortalFilter
              className={`${
                props.disableDateSelector && mpanMprnSelectedIndex && mpanMprnSelectedIndex < 0 ? ' disabled' : ''
              }`}
              selectedIndex={dateSelectorSelectedIndex}
              options={dateSelectorList ? dateSelectorList : []}
              placeholder={dateSelectorPlaceHolder}
              onChange={handleDateSelectorFilterChange}
              handleMenuOpen={handleMenuOpen}
              isClearable={false}
              isDisabled={
                props.disableDateSelector && (mpanMprnSelectedIndex && mpanMprnSelectedIndex < 0 ? true : false)
              }
            />
            {showMonthlyModal && (
              <DateMonthDayFilterModal
                enableDaily={false}
                onHide={() => {
                  setShowMonthlyModal(false);
                }}
                enableMonthly={true}
                selectedRange={Ranges['monthly']}
                show={showMonthlyModal}
                onDateSelect={handleDateSelectorDayMonthFilterChange}
                previouslySelectedDate={props.previouslySelectedDate}
              />
            )}
            {showDailyModal && (
              <DateMonthDayFilterModal
                enableDaily={true}
                onHide={() => {
                  setShowDailyModal(false);
                }}
                enableMonthly={false}
                selectedRange={Ranges['daily']}
                show={showDailyModal}
                onDateSelect={handleDateSelectorDayMonthFilterChange}
                previouslySelectedDate={props.previouslySelectedDate}
              />
            )}
          </li>
        )}

        {props.showPeriod && (
          <li className='period-dropdown'>
            <>
              <FilterClearButton
                className={'period-grid-filter'}
                title={dateTitle}
                disabled={false}
                onClick={(event: any) => {
                  event.preventDefault();
                  setShowPeriodModal(true);
                }}
                showClear={dateTitle != 'Date period - All'}
                showChevron={dateTitle === 'Date period - All'}
              />
              {showPeriodModal && (
                <FromToDateRangePickerModal
                  startDate={startDate}
                  endDate={endDate}
                  show={showPeriodModal}
                  onClose={handlePeriodFilterClose}
                  handlePeriodFilterChange={handlePeriodFilterChange}
                />
              )}
            </>
          </li>
        )}
        {props.showMeterType && (
          <li className='d-flex justify-content-between align-items-center mb-4'>
            <PortalFilter
              className={'meter-btn'}
              selectedIndex={meterTypeSelectedIndex}
              options={props.meterTypeFilterOptions}
              placeholder={'Meter Type - All'}
              onChange={(option: TableFilterOption) => handleMeterTypeFilterChange(option?.value as string)}
              isClearable={true}
            />
          </li>
        )}
        {props.showDownload && (
          <li>
            <span className='row icon-btn-download'>
              <ButtonDownload
                title='Download'
                icon='filezip'
                outline={false}
                disabled={!props.enableDownloadBtn}
                options={props.downloadOptions}
                onClick={download}
              />
            </span>
          </li>
        )}

        {props.showAddUser && (
          <li>
            <span className={`row icon-btn ${props.addUserEnabled ? '' : 'disabled'}`}>
              <IcomoonReact iconSet={iconSet} color={theme.icons.default} size={15} icon='user-plus' />
              <button className='grid-clear-all-button nooutline' onClick={handleAddUser}>
                Add user
              </button>
            </span>
          </li>
        )}

        {props.showExport && (
          <li>
            <span className='row icon-btn'>
              <ButtonDownload
                title='Export table'
                icon='filezip'
                outline={false}
                disabled={!props.enableExportBtn}
                options={props.exportOptions}
                onClick={handleExport}
              />
            </span>
          </li>
        )}
        {props.renderAdditionalListItems()}
      </ul>
      {(globalContext.globalDetail.mobileFilterActive || globalContext.globalDetail.filterActive) && (
        <SideMenuFilter>
          <ul className='mobile-grid-filter'>
            <li>
              <button className='close-menu-icon' onClick={handleCloseMenuClick}>
                <IcomoonReact iconSet={iconSet} color={colors.trueblack} size={15} icon='cross2' />
              </button>
            </li>
            {props.showCustomer && (
              <li>
                <PortalFilter
                  className={` ${customerAccountsOptions.length > 1 ? '' : 'disabled'}`}
                  selectedIndex={customerSelectedIndex}
                  options={isDraxBrand ? customerAccountsOptions : props.customerData}
                  onChange={handleCustomerFilterChange}
                  isClearable={false}
                />
              </li>
            )}
            {props.showAccount && (
              <li>
                <FilterDropDown
                  className=''
                  options={props.accountData}
                  showBlankValue={false}
                  showValue={false}
                  selectText={GridFilterText.accountSelectText}
                  handleChange={handleAccountFilterChange}
                  enabled={isAccountFilterDropdownEnabled}
                  selectedIndex={accountSelectedIndex}
                  showSelectedValue={true}
                />
              </li>
            )}
            {props.showSites && (
              <li>
                <PortalFilter
                  className={` ${props.siteData.length > 1 ? '' : 'disabled'}`}
                  selectedIndex={siteSelectedIndex}
                  options={props.siteData}
                  placeholder={GridFilterText.sitesSelectText}
                  onChange={handleSiteFilterChange}
                  isClearable={true}
                />
              </li>
            )}
            {props.showMpanMprn && (
              <li>
                <PortalFilter
                  className={` ${props.mpanMprnData.length > 1 ? '' : 'disabled'}`}
                  selectedIndex={mpanMprnSelectedIndex}
                  options={props.mpanMprnData}
                  placeholder={GridFilterText.mpanMprnSelectText}
                  onChange={handleMpanMprnFilterChange}
                  isClearable={true}
                />
              </li>
            )}
            {props.showDateSelector && (
              <li>
                <PortalFilter
                  className={`${
                    props.disableDateSelector && mpanMprnSelectedIndex && mpanMprnSelectedIndex < 0 ? ' disabled' : ''
                  }`}
                  selectedIndex={dateSelectorSelectedIndex}
                  options={dateSelectorList ? dateSelectorList : []}
                  placeholder={dateSelectorPlaceHolder}
                  onChange={handleDateSelectorFilterChange}
                  handleMenuOpen={handleMenuOpen}
                  isClearable={false}
                  isDisabled={
                    props.disableDateSelector && (mpanMprnSelectedIndex && mpanMprnSelectedIndex < 0 ? true : false)
                  }
                />
                {showMonthlyModal && (
                  <DateMonthDayFilterModal
                    enableDaily={false}
                    onHide={() => {
                      setShowMonthlyModal(false);
                    }}
                    enableMonthly={true}
                    selectedRange={Ranges['monthly']}
                    show={showMonthlyModal}
                    onDateSelect={handleDateSelectorDayMonthFilterChange}
                    previouslySelectedDate={props.previouslySelectedDate}
                  />
                )}
                {showDailyModal && (
                  <DateMonthDayFilterModal
                    enableDaily={true}
                    onHide={() => {
                      setShowDailyModal(false);
                    }}
                    enableMonthly={false}
                    selectedRange={Ranges['daily']}
                    show={showDailyModal}
                    onDateSelect={handleDateSelectorDayMonthFilterChange}
                    previouslySelectedDate={props.previouslySelectedDate}
                  />
                )}
              </li>
            )}
            {props.showDate && (
              <div className='d-flex justify-content-between'>
                <div className='mr-1'>
                  <span>
                    Period <br /> From
                  </span>
                  <CustomDatePicker
                    date={startDateMobile}
                    minDate={startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
                    maxDate={startDateMobileMaxDate}
                    showIcon={props.showIcon}
                    fixMainPosition={true}
                    onChange={e => handleStartDateMobileChange(e)}
                  />
                </div>
                <div className='ml-1'>
                  <span>
                    <br />
                    To
                  </span>
                  <CustomDatePicker
                    disabled={startDateMobile == null}
                    date={endDateMobile}
                    minDate={endDateMobileMinDate}
                    maxDate={endDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
                    showIcon={props.showIcon}
                    fixMainPosition={true}
                    onChange={e => handleEndDateMobileChange(e)}
                  />
                </div>
              </div>
            )}
            {props.showMeterType && (
              <PortalFilter
                className={'meter-btn'}
                selectedIndex={meterTypeSelectedIndex}
                options={props.meterTypeFilterOptions}
                placeholder={'Meter Type - All'}
                onChange={(option: TableFilterOption) => handleMeterTypeFilterChange(option?.value as string)}
                isClearable={true}
              />
            )}
            {props.showClearAll && (
              <li className='d-flex justify-content-center'>
                <button
                  disabled={isClearAllDisabled}
                  className={`border-none d-block mb-4 mt-4 plain primary ${(isClearAllDisabled && 'disabled') || ''}`}
                  onClick={handleClearAllClick}
                >
                  {GridFilterText.clearAllSelectText}
                </button>
              </li>
            )}
            <div className='text-center'>{props.renderAdditionalListItems()}</div>
          </ul>
        </SideMenuFilter>
      )}
      <MobileActionsPanel
        showDownload={props.showDownload}
        downloadOptions={props.downloadOptions}
        enableDownloadBtn={props.enableDownloadBtn}
        handleDownload={props.handleDownload}
        showExport={props.showExport}
        exportOptions={props.exportOptions}
        enableExportBtn={props.enableExportBtn}
        handleExport={handleExport}
        showAddUser={props.showAddUser}
        handleAddUser={handleAddUser}
      />
    </GridFilterWrapper>
  );
};

GridFilter.defaultProps = defaultProps;
