import React, { useState, useEffect, useRef, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import IcomoonReact from 'icomoon-react';
import styled from 'styled-components';

import iconSet from '../../../assets/selection.json';

import {
  ExpandableRow,
  ButtonPrimary,
  NoResults,
  Loading,
  Table,
  GridTypeSelector,
  Icon,
} from '../../../common/components';

import { StyledTitle } from '../../../common/components';
import { GridFilter } from '../../gridfilter/GridFilter';
import { EssAccountsTypes } from '../../../types/account';
import { respondTo } from '../../../common/styles/mixins';
import { globalApiParams } from '../../../common/api/globals';
import { isOpusBrand } from '../../../utils/common';
import { TagManager } from '../../utils/analytics/TagManager';
import { Upload } from '../../icons';
import { PaymentHistoryWrapper, PaymentHistoryGridWrapper, DownloadButtonsWrapper } from './PaymentHistory.styles';
import { useAuthState } from '../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { IPaymentHistoryExpanded } from '../../../types/payment';
import { getPaymentHistoryExpanded } from '../../../common/api/paymentApi';
import { InvoiceDownloadModal } from '../../mymessages/modalTemplates/InvoiceDownloadModal';

type PaymentHistoryComponentProps = {
  hasElectricityAccount: boolean;
  hasGasAccount: boolean;
  hasRenewableAccount: boolean;
  initialType: string;
  gridData: Array<any>;
  handleTypeChange: Function;
  handleSortChange: Function;
  handleSiteBlur: Function;
  handlePagingChange: any;
  handleCustomerFilterChange: Function;
  handleClearAllClick: Function;
  handleAccountFilterChange: Function;
  handleSiteFilterChange: Function;
  handleMpanMprnFilterChange: Function;
  handleMeterTypeFilterChange: (el: Api.MeterType) => void;
  handleDateFilterChange: Function;
  handleDownload: Function;
  customerData: Array<Common.IOption>;
  accountData: Array<Common.IOption>;
  siteData: Array<Common.IOption>;
  mpanMprnData: Array<Common.IOption>;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
  eligibleForSmartMeter: boolean;
};

const defaultProps = {
  gridData: [],
  customerData: [],
  accountData: [],
  siteData: [],
  mpanMprnData: [],
  hasElectricityAccount: false,
  hasGasAccount: false,
  hasRenewableAccount: false,
  initialType: EssAccountsTypes.Electricity,
  isLoading: true,
  hasMoreData: true,
  currentSortField: '',
  currentSortOrder: 'ASC',
  eligibleForSmartMeter: false,
};

const paymentHistoryHeaders: Array<Common.HeaderItem> = [
  {
    title: 'Transaction date',
    dataValue: 'transaction-date',
    enableSort: false,
    minWidth: 210,
  },
  {
    title: 'Transaction type',
    dataValue: 'transaction-payment-method',
    enableSort: false,
    minWidth: 210,
  },
  {
    title: 'Collection reference',
    dataValue: 'user-reference',
    enableSort: false,
    className: 'collection-ref-col',
    minWidth: 350,
  },
  {
    title: 'Transaction reference',
    dataValue: 'transaction-reference',
    enableSort: false,
    minWidth: 210,
  },
  {
    title: 'Payment total (£)',
    dataValue: 'total',
    enableSort: false,
    minWidth: 140,
  },
];

const StyledTd = styled.td<any>`
  p,
  span {
    width: 50%;
  }

  p {
    display: flex;
    align-items: end;
    margin-top: auto;
  }

  ${respondTo.tablet`
    width: ${({ colWidth }: any) => colWidth};
    p, span {
      margin-left: -10px;
      width: auto;
    }

    p {
      display: block;
    }
  `};
`;

const PaymentHistoryComponent = (props: PaymentHistoryComponentProps) => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedType, setSelectedType] = useState<string>(props.initialType);
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const [gridTypes, setGridTypes] = useState<Array<string>>();
  const [isAllSelected, setSelectAll] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const scrollableTable = useRef<any>();
  const [paymentInfo, setPaymentInfo] = useState<Array<IPaymentHistoryExpanded>>([]);
  const [isDownloadRequestedModalOpened, setIsDownloadRequestedModalOpened] = useState(false);

  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }
  }, [props.gridData]);

  useEffect(() => {
    setSelectedType(props.initialType);
  }, [props.initialType]);

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
    setSelectAll(false);
    setCheckedItems([]);
  };

  const handleCustomerFilterChange = (account: Common.IOption): void => {
    clearSelection();
    props.handleCustomerFilterChange(account);
  };

  const handleClearAllClick = (): void => {
    clearSelection();
    props.handleClearAllClick();
  };

  const handleMeterTypeFilterChange = (el: Api.MeterType) => {
    clearSelection();
    props.handleMeterTypeFilterChange(el);
  };

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
    clearSelection();
    props.handleTypeChange(type, { field: 'Month', order: 'DESC' });
  };

  const handleSelectRow = (event: any, row: number | null) => {
    const wrapper = event.target.closest('td')?.classList?.contains?.('select-item'); //!!
    if (wrapper) {
      return;
    }
    if (row !== undefined) {
      TagManager.pushData({
        event: 'Select payment',
        selectedRow: row || undefined,
      });
      setSelectedRow(row);
      setExpandRow(false);
      getPaymentData(row);
    }
  };

  const toggleRow = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (!expandRow) {
      TagManager.pushData({
        event: 'View payment info',
      });
    }
    setExpandRow(!expandRow);
  };

  const handleItemChange = (index: number) => {
    setSelectedRow(null);

    const items = checkedItems.slice(0, checkedItems.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    if (items.length === props.gridData.length) {
      handleSelectAll();
    } else if (isAllSelected) {
      setSelectAll(false);
    }

    setCheckedItems(items);
  };

  const handleSelectAll = () => {
    setSelectedRow(null);

    const select = !isAllSelected;
    setSelectAll(select);
    if (select) {
      const items: Array<number> = props.gridData.map((item, index) => index);
      setCheckedItems(items);
    } else {
      // unselect
      setCheckedItems([]);
    }
  };

  const handlePagingChange = () => {};

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const onClickSort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearSelection();
    const typeId = e.currentTarget.dataset.value || '';
    let details = sortDetails;
    if (details.field === typeId) {
      if (details.order === 'ASC') details.order = 'DESC';
      else details.order = 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }
    props.handleSortChange(selectedType, details);
  };

  const handleDownload = () => {
    setIsDownloadRequestedModalOpened(true);
    props.handleDownload(userDetail.essCustomerId);
  };

  const getPaymentActions = (row: any, index: number) => (
    <div>
      <a href='/' onClick={toggleRow} className='d-md-inline' style={{ marginLeft: '10px' }}>
        <IcomoonReact
          size={20}
          iconSet={iconSet}
          className='icon-chevron'
          icon={expandRow && selectedRow === index ? 'chevron-up' : 'chevron-down'}
        />
      </a>
    </div>
  );

  const getPaymentData = async (row: number | null) => {
    const expandedRow: IPaymentHistoryExpanded | undefined = props.gridData.find((_, index) => index === row);

    if (expandedRow) {
      const customerId = userDetail.essCustomerId;
      const transactionId = expandedRow['transaction-reference'];

      let paymentDataOriginal: Common.IResult<Array<IPaymentHistoryExpanded>> = await getPaymentHistoryExpanded(
        process.env.REACT_APP_API_URL,
        authContext,
        customerId,
        transactionId
      );

      setPaymentInfo(paymentDataOriginal.data);
    }
  };

  const getExpandableRow = (row: any, rowIndex: number) => (
    <ExpandableRow className={`sub-row ${selectedRow === rowIndex ? 'selected' : ''} ${expandRow ? 'open' : ''}`}>
      <div className='expand-scroll'>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr className='tarrif-info expand-scroll-snap'>
              <StyledTd colWidth={'15%'}>MPAN</StyledTd>
              <StyledTd colWidth={'15%'}>Site ref</StyledTd>
              <StyledTd colWidth={'25%'}>Site name</StyledTd>
              <StyledTd colWidth={'15%'}>Invoice reference</StyledTd>
              <StyledTd colWidth={'20%'}>Individual total (£)</StyledTd>
            </tr>
            <>
              {paymentInfo.map((el, index) => (
                <tr className='tarrif-info expand-scroll-snap' key={index}>
                  <StyledTd colWidth={'15%'}>{el['mpan-core'] || 'N/A'}</StyledTd>
                  <StyledTd colWidth={'15%'}>{el['site-reference'] || 'N/A'}</StyledTd>
                  <StyledTd colWidth={'25%'}>{el['site-name'] || 'N/A'}</StyledTd>
                  <StyledTd colWidth={'15%'}>{el['transaction-reference'] || 'N/A'}</StyledTd>
                  <StyledTd colWidth={'15%'}>
                    {el['total'].toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) || 'N/A'}
                  </StyledTd>
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </ExpandableRow>
  );

  const title = 'Payment history';
  const headers = paymentHistoryHeaders;
  const actions = getPaymentActions;

  const actionStyles = {
    width: '150px',
    opacity: '1',
  };

  const meterTypeFilterOptions = [
    {
      display: 'NHH',
      value: 'NHH',
    },
    {
      display: 'HH',
      value: 'HH',
    },
  ];

  const PaymentHistoryFilterAdditionalItems = () => {
    const additionalItems = [
      <DownloadButtonsWrapper key='paymenthistoryAdditionalItem1' className='additional-buttons'>
        <ButtonPrimary onClick={handleDownload} route='#' disabled={props.gridData.length === 0}>
          <Icon icon={Upload} size={18} className='mr-2' />
          Download payment history
        </ButtonPrimary>
      </DownloadButtonsWrapper>,
    ];

    return additionalItems;
  };

  return (
    <PaymentHistoryWrapper>
      <StyledTitle>{title}</StyledTitle>
      <div className='mt-3 mb-4 page-text'>
        <p>Use the chevron to expand the row for more detailed information.</p>
        <p className=''>Non-Direct Debit will include BACS, card payments and other cash payments.</p>
      </div>

      <GridFilter
        showSites={false}
        showDate={false}
        showMpanMprn={false}
        showClearAll={false}
        siteData={props.siteData}
        showAccount={isOpusBrand}
        showMeterType={false}
        showDownload={isOpusBrand}
        selectedType={selectedType}
        accountData={props.accountData}
        handleDownload={handleDownload}
        customerData={props.customerData}
        mpanMprnData={props.mpanMprnData}
        meterTypeFilterOptions={meterTypeFilterOptions}
        handleClearAllClick={handleClearAllClick}
        enableDownloadBtn={checkedItems.length > 0}
        handleDateFilterChange={props.handleDateFilterChange}
        handleSiteFilterChange={props.handleSiteFilterChange}
        handleAccountFilterChange={props.handleAccountFilterChange}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleMpanMprnFilterChange={props.handleMpanMprnFilterChange}
        handleMeterTypeFilterChange={handleMeterTypeFilterChange}
        renderAdditionalListItems={PaymentHistoryFilterAdditionalItems}
      />

      <GridTypeSelector initialType={selectedType} types={gridTypes} handleTypeChange={handleTypeChange} />

      {!props.isLoading && props.gridData.length === 0 && <NoResults />}

      <PaymentHistoryGridWrapper>
        {props.gridData.length > 0 && (
          <>
            <InfiniteScroll
              dataLength={props.gridData.length}
              next={handlePagingChange}
              hasMore={props.hasMoreData}
              loader={''}
              scrollableTarget='scrollableDiv'
              scrollThreshold={0.9}
            >
              <div
                id='scrollableDiv'
                ref={scrollableTable}
                className='table-responsive table-fixed fixed-column columns-1'
              >
                <Table
                  isSelectable={false}
                  headers={headers}
                  actions={actions}
                  rows={props.gridData}
                  isChecked={isChecked}
                  sortDetails={sortDetails}
                  onClickSort={onClickSort}
                  isRowExpanded={expandRow}
                  actionStyles={actionStyles}
                  isAllSelected={isAllSelected}
                  selectedRowIndex={selectedRow}
                  expandableRow={getExpandableRow}
                  handleSelectAll={handleSelectAll}
                  handleCheckRow={handleItemChange}
                  handleSelectRow={handleSelectRow}
                  className='list table plain text-left'
                />
              </div>
            </InfiniteScroll>
          </>
        )}

        {props.isLoading && <Loading overlay nowait />}
      </PaymentHistoryGridWrapper>

      {isDownloadRequestedModalOpened && (
        <InvoiceDownloadModal
          show={isDownloadRequestedModalOpened}
          onClose={() => {
            setIsDownloadRequestedModalOpened(false);
          }}
        />
      )}
    </PaymentHistoryWrapper>
  );
};

PaymentHistoryComponent.defaultProps = defaultProps;

export default PaymentHistoryComponent;
