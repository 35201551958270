import styled from 'styled-components';

import * as variables from '../../styles/variables';
import * as colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import { respondTo } from '../../styles/mixins';
import { isDraxBrand } from '../../../utils/common';

export const FilterRowWrapper = styled.div`
  ${respondTo.tablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
  margin-bottom: ${variables.spacingSmall};
`;

export const FilterWrapper = styled.div`
  position: relative;

  svg {
    display: none !important;
  }
  @media (max-width: 768px) {
    svg {
      display: inline-block !important;
      position: absolute;
      right: 24px;
      top: 16px;
    }
  }

  width: 100%;

  &.w-200 {
    .filter {
      width: 200%;
    }
  }

  ${respondTo.tablet`
    width: initial;
  `};

  .selector {
    background-color: ${colors.white};
    cursor: pointer;
    max-width: initial;
    padding: 0;

    @media (max-width: 768px) {
      width: 100%;
      background-color: #fff;
      border: 1px solid #5f646d;
      border-radius: 8px;
      height: 46px;
      display: flex;
      padding: 16px;
      align-items: center;

      & > span {
        border-bottom: none !important;
      }
    }

    &.right {
      text-align: left;

      ${respondTo.tablet`
        text-align: right;
      `};
    }

    span {
      border-bottom: ${isDraxBrand ? 'none' : `1px solid ${colors.darkBlue}`};
    }

    .active {
      color: ${({ theme }) => theme.text.secondary};

      ${respondTo.tablet`
        ${mixins.borderNone};
        border-bottom: ${isDraxBrand ? 'none' : `1px solid ${({ theme }) => theme.text.secondary}`};
      `};
    }

    .chevron {
      position: absolute !important;
      left: initial !important;
      right: 1.5em !important;
      top: 1.3em !important;
    }
  }

  .filter {
    display: flex;
    height: 0;
    max-width: initial;
    left: -30px;
    z-index: ${variables.zIndexHighest * 10};

    ${respondTo.tablet`
      padding: 0;
      position: absolute;
      margin-left: initial;
    `};

    &.right {
      ${respondTo.tablet`
        left: initial;
        right: 0;
        max-width: ${variables.menuWidth};
      `};
    }

    > li {
      padding-top: 5px !important;
    }

    li {
      ${mixins.transitionFast};
      cursor: pointer;
      padding: 10px 15px;
      width: 100%;
      font-size: 14px;

      ${respondTo.tablet`
        width: initial;
      `};

      &:hover {
        ${mixins.transitionFast};
      }
    }

    ul {
      ${mixins.boxShadowPrimary};

      border: 0;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
      top: 12px;

      ${respondTo.tablet`
         width: 170px;
         right: 0;
      `}

      li {
        background-color: ${colors.white};
        font-weight: 600;
        color: ${({ theme }) => theme.primary.color};

        &:hover,
        &.selected {
          background-color: ${({ theme }) => theme.primary.bg};
          color: ${colors.white};
        }

        &:first-child {
          border-top: 1px solid ${colors.lightGrey};
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }

    &.open {
      ul {
        ${mixins.scrollbars}
        height: auto;
        max-height: 221px;
        overflow: auto;
        overflow-x: hidden;
      }
    }
  }
`;
