import { useContext, useState } from 'react';

// Styles
import { LoAHealthWrapper } from './LoAHealth.styles';

import { Health, IHealthCheck } from '../Health/Health';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { useFetchHealthChecks } from '../hooks/useFetchHealthChecks';
import { useHistory } from 'react-router-dom';
import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { RoutingToTypes } from '../../search/SearchActions/SearchActions';
import { ModalTemplate } from '../../mymessages/modalTemplates/ModalTemplate';
import { HealthWidget } from '../HealthWidget/HealthWidget';

type LoAHealthProps = {
  status: 'loading' | 'healthy' | 'notHealthy';
};

const defaultProps = {
  status: 'loading',
};

export const LoAHealth = (props: LoAHealthProps) => {
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const history = useHistory();

  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [messagevalues, setMessageValues] = useState<Messages.IMessagesValues>();

  const hc: Array<IHealthCheck> = [
    {
      type: 'LetterOfAuthorityExpiry',
      display: 'LoA’s are due to expire within 28 days',
      route: '/home/loa',
      redirect: RoutingToTypes.lettersOfAuthority,
      status: 'loading',
      url: '/ersgatewayadapter/letters-of-authority-health',
    },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onMoreInfoClick = (healthCheck: IHealthCheck) => {
    if (!healthCheck.response) return;

    history.push(healthCheck.route);
  };

  const { healthChecks } = useFetchHealthChecks(hc, userDetail.customerAccounts, props.status);

  return (
    <LoAHealthWrapper>
      <HealthWidget
        widgetKey='LoA'
        title='Portfolio LoA health'
        type='LoAHealth'
        healthChecks={healthChecks}
        checkingText='LoA Health'
        healthyText='LoA health check complete'
        customers={userDetail.customerAccounts} // TODO WILL NEED TPIREFERENCE ?
        onMoreInfoClick={onMoreInfoClick}
      ></HealthWidget>

      <ModalTemplate
        name={'HealthCheckModal'}
        messagevalues={messagevalues}
        show={showModal}
        onHide={handleCloseModal}
      />
    </LoAHealthWrapper>
  );
};

LoAHealth.defaultProps = defaultProps;
