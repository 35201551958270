import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';
import { respondTo } from '../../styles/mixins';

export const ActionsListWrapper = styled.ul`
  position: absolute;
  padding: 10px 0;
  top: 14px;
  right: 85px;
  max-width: 200px;
  z-index: ${variables.zIndexHigh};

  ${respondTo.mobileSm`
    max-width: 300px;
  `};
`;

export const ActionsBtnWrapper = styled.li`
  font-size: ${variables.fontSizeSmall};
  float: left;
  margin-left: ${variables.spacingSmall};
  padding-bottom: ${variables.spacingXSmall};

  a .button-label {
    display: none;

    ${respondTo.mobile`
      display: inline;
    `};
  }
`;

export const ActionsPanelWrapper = styled.ul`
  background-color: ${colors.lightGrey};
  border-radius: ${variables.globalRadius};
  font-size: ${variables.fontSizeSmall};
  justify-content: left;
  margin: 0;
  position: absolute;
  padding: 10px 20px;
  right: 10px;
  top: 10px;

  ${respondTo.tablet`
    background-color: inherit;
    justify-content: flex-end;
    margin: 1.5rem 0;
    padding: 0;
    position: static;
  `};

  li {
    ${mixins.transition};
    cursor: pointer;
    font-size: ${variables.fontSizeMedium};
    margin-right: ${variables.spacingSmall};

    &:hover {
      ${mixins.transition};
      color: ${({ theme }) => theme.text.secondary};

      [class^='icon'] path {
        fill: ${({ theme }) => theme.icons.default} !important;
      }
    }

    ${respondTo.tablet`
      margin-right: ${variables.spacingMedium};
    `};

    &:last-child {
      margin-right: 0;

      [class^='icon'] {
        margin-right: 0;

        ${respondTo.tablet`
          margin-right: ${variables.spacingSmall};
        `};
      }
    }
  }

  [class^='icon'] {
    margin-right: 10px;

    path {
      ${mixins.transition};
    }
  }
`;
