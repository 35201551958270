import React, { useEffect, useState } from 'react';

import { LoadingOverlay, LoadingWrapper, LoadingSpan } from './Loading.styles';

type LoadingProps = {
  message: string;
  containerStyles: React.CSSProperties;
  containerClassName: string;
  overlay?: boolean;
  backdrop?: boolean;
  nowait?: boolean;
};

const defaultProps = {
  message: '',
  containerStyles: {},
  containerClassName: '',
  overlay: false,
  backdrop: false,
  nowait: false,
};

export const Loading = (props: LoadingProps) => {
  const { containerClassName, containerStyles, message, backdrop, overlay } = props;
  const [isVisible, setIsVisible] = useState(!overlay);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsVisible(true);
      },
      props.nowait ? 0 : 1000
    );

    return () => clearTimeout(timer);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overlayStyles: React.CSSProperties = {};

  if (backdrop) {
    overlayStyles.background = 'rgba(0, 0, 0, .35)';
  }

  const loader = (
    <LoadingWrapper className={containerClassName} style={containerStyles}>
      <div>
        <LoadingSpan {...props} className='loader' />
        <br />
        <span>{message}</span>
      </div>
    </LoadingWrapper>
  );

  return overlay ? (
    <LoadingOverlay style={overlayStyles} isVisible={isVisible}>
      {loader}
    </LoadingOverlay>
  ) : (
    loader
  );
};

Loading.defaultProps = defaultProps;
