import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import iconSet from '../../../assets/selection.json';
import IcomoonReact from 'icomoon-react';
import { TypeAheadDropDownWrapper } from './TypeAheadDropDown.styles';

type TypeAheadDropDownProps = {
  options: Array<Common.IOption>;
  enabled: boolean;
  selectedValue: string;
  selectText: string;
  className?: string;
  showNameValueFormat?: boolean;
  showSelectedIcon?: boolean;
  showDropDownIcon?: boolean;
  handleChange: Function;
};

const defaultProps = {
  enabled: true,
  className: '',
  selectedValue: '',
  selectedOption: '',
  selectText: '',
  showBlankValue: false,
  showNameValueFormat: false,
  showValue: true,
  showSelectedIcon: true,
  showDropDownIcon: true,
  handleChange: undefined,
};

export const TypeAheadDropDown = forwardRef((props: TypeAheadDropDownProps, ref: any) => {
  useImperativeHandle(ref, () => ({
    collapse() {
      collapseDropDown();
    },
  }));

  const [selectedValue, setSelectedValue] = useState<string>(props.selectedValue);
  const [selectedItem, setSelectedItem] = useState<Common.IExpandedOption>();
  const [suggestions, setSuggestions] = useState<Common.IExpandedOption[]>([]);
  const [formattedDisplay, setFormattedDisplay] = useState<string>('');

  const [expandedOptions, setExpandedOptions] = useState<Common.IExpandedOption[]>([]);

  useEffect(() => {
    const expanded = props.options.map(option => {
      const formattedDisplay = option.value + ' - ' + option.display;
      let expandedOption: Common.IExpandedOption = {
        ...option,
        formattedDisplay: formattedDisplay,
      };

      return expandedOption;
    });
    setExpandedOptions(expanded);

    if (props.selectedValue !== undefined) {
      let target = expanded.find(i => i.value === props.selectedValue);
      if (target) {
        setSelectedItem(target);
      }
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  useEffect(() => {
    setSuggestions([]);

    if (!props.enabled) {
      setSelectedValue('');
      setSelectedItem(undefined);
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.enabled]);

  useEffect(() => {
    // TODO
    if (!selectedItem) {
      setFormattedDisplay(selectedValue);
      return;
    }

    if (!selectedValue) {
      setFormattedDisplay('');
      return;
    }

    const display = selectedItem?.formattedDisplay;
    setFormattedDisplay(display);

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  function loseFocus() {
    setSelectedValue('');
  }

  function onClickChevron() {
    if (!props.enabled) return;

    if (suggestions.length > 0) {
      setSuggestions([]);
    } else {
      setSuggestions(expandedOptions);
    }
  }

  function onTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    if (!props.enabled) return;

    setSelectedValue(value);
    setSelectedItem(undefined);

    const filteredExpanded = expandedOptions.filter(option => {
      if (option.value.toString().startsWith(value) || option.display.toLowerCase().match(value.toLowerCase())) {
        return option;
      } else {
        return null;
      }
    });
    setSuggestions(filteredExpanded);
  }

  const handleKeyDown = () => {
    if (!props.enabled) {
      return null;
    }
  };

  function suggestionSelected(expandedOption: Common.IExpandedOption) {
    setSelectedValue(expandedOption.value);
    setSelectedItem(expandedOption);
    setSuggestions([]);

    props.handleChange(expandedOption);
  }

  function collapseDropDown() {
    setSuggestions([]);
  }

  function renderSuggestions() {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        {suggestions.map(option => (
          <li className='text-truncate' key={option.value} onClick={() => suggestionSelected(option)}>
            {option.formattedDisplay}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <TypeAheadDropDownWrapper {...props} onBlur={loseFocus}>
      <div className={'typeAheadDropDown' + (!props.enabled ? ' disabled' : '')} onClick={onClickChevron}>
        <input
          onChange={onTextChange}
          onKeyDown={handleKeyDown}
          placeholder={props.selectText}
          value={!props.enabled ? '' : selectedItem ? formattedDisplay : selectedValue}
          type='text'
        />

        <span>
          <IcomoonReact className='itemicon chevron' iconSet={iconSet} size={15} icon={'chevrons-expand-vertical'} />
        </span>
        {renderSuggestions()}
      </div>
    </TypeAheadDropDownWrapper>
  );
});

TypeAheadDropDown.defaultProps = defaultProps;
