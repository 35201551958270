import styled from 'styled-components';

import * as colors from '../../../common/styles/colors';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';
import { respondTo } from '../../../common/styles/mixins';
import arrowup from '../../../assets/images/arrow-up.png';

export const ProfileLogoutWrapper = styled.div`
  display: initial;
  padding-right: 20px;
  display: flex;

  justify-content: flex-end;

  .mobile-hidden {
    padding-bottom: 40px;
  }

  .icon-circle {
    width: 40px;
    height: 40px;
    background-color: ${colors.inputGrey};
    border-radius: 50%;
    left: 100px;
  }

  .icon-circle-help {
    width: 40px;
    height: 40px;
    background-color: ${props => props.theme.primary.bg};
    border-radius: 50%;
    left: 100px;
  }

  .icon-help {
    left: -5.5px;
    top: 5.5px;
  }

  svg path {
    fill: ${colors.darkGrey};
  }

  svg.active {
    fill: #fff;
  }

  svg.active path {
    fill: #fff;
  }

  .profile-popup {
    ${mixins.boxShadowPrimary};
    font-size: 14px;
    background-color: ${colors.white};
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${colors.midGrey};
    position: relative;
    left: -180px;
    width: 230px;
    top: 26px;
    z-index: ${variables.zIndexHighest + 2};

    ${respondTo.mobile`
      left: -215px;
      width: 270px;
    `};

    &:before {
      content: '';
      display: block;
      background-image: url(${arrowup});
      background-repeat: no-repeat;
      background-size: 36px;
      position: absolute;
      width: 36px;
      height: 17px;
      top: -17px;
      left: 182px;

      ${respondTo.mobile`
        left: 217px;
      `};
    }

    .btnsignout {
      display: block;
      margin-top: 0;
      font-size: 16px;
      margin-bottom: 0;
    }

    .btnreturntopageselection {
      display: block;
      margin-top: 0;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .btn-toggle {
    cursor: pointer;
    color: ${colors.midGrey};
    text-align: center;
    border-radius: 50%;
    font-size: ${variables.fontSizeBase};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
