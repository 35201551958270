import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ExternalLink, ModalClose } from '../../../common/components';

type BulkInvoicesReadyProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Bulk Invoice download',
  messagevalues: {},
};

export const BulkInvoicesReady = (props: BulkInvoicesReadyProps) => {
  return (
    <>
      <span className='font-medium'>
        {props.messagevalues.date}
        <span className='ml-2'>{props.messagevalues.time}</span>
      </span>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>Your file is ready to download</p>
        <p>
          Your file <span className='file-name primary'>test.zip</span> is now available in your online account
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ExternalLink
          onClick={() => {
            props.onHide();
          }}
          className='btn-primary'
          title={'Download'}
          target='_blank'
          href={props.messagevalues.callToAction}
        />
      </Modal.Footer>
    </>
  );
};

BulkInvoicesReady.defaultProps = defaultProps;
