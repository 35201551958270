// Modules
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Icon } from '../../../../common/components/Icon/Icon';

// Styles
import { BulkUploadLinkWrapper } from './FilterAdditionalItems.styles';

export function FilterAdditionalItems() {
  const additionalItems = [
    <li key='bulk-upload-link' className='bulk__upload'>
      <BulkUploadLinkWrapper className='d-flex justify-content-center justify-content-md-end'>
        <Link className='bulk__upload__link d-flex align-items-center' to='/home/energy-usage/bulk-readings-tool'>
          <Icon icon='launch' />
          <span>Bulk upload</span>
        </Link>
      </BulkUploadLinkWrapper>
    </li>,
  ];

  return additionalItems;
}
