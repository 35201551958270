import React, { useState, useEffect, useContext, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { ButtonPrimary, Checkbox, Loading, Sort, NoResults } from '../../../../common/components';
import { DirectDebitProcessContext } from '../directDebitProcess/directDebitProcessContext';
import { hideCharacters } from '../../../../utils/hideCharacters';
import { DATE_FORMAT } from '../../../../common/constants';
import { getFuelIcon } from '../../../../utils/getFuelIcon';
import { SetupButton } from './SetupButton/SetupButton';

import { ChooseAccountWrapper } from './ChooseAccount.styles';

type ChooseAccountComponentProps = {
  selectedType: string;
  gridData: Api.IAccountsForDDSetupData[];
  handleSortChange: Function;
  handlePagingChange: any;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
};

const defaultProps = {
  isLoading: true,
  gridData: [],
  selectedType: 'Electricity',
  hasMoreData: true,
  currentSortField: 'EssAccountID',
  currentSortOrder: 'DESC',
};

export const ChooseAccount = (props: ChooseAccountComponentProps) => {
  const history = useHistory();
  const { gridData, selectedType, isLoading } = props;
  const { setSelectedAccounts } = useContext<any>(DirectDebitProcessContext);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const scrollableTable = useRef<any>();

  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };

  useEffect(() => {
    clearSelection();
  }, [selectedType]);

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
    setCheckedItems([]);
    setSelectAll(false);
  };

  const handleRowSelect = (row: number | null) => {
    if (row !== undefined) {
      setSelectedRow(row);
      setExpandRow(false);
    }
  };

  const handleItemChange = (index: number) => {
    setSelectedRow(null);

    let items = checkedItems.slice(0, checkedItems.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    setCheckedItems(items);
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const handleSelectAll = (): void => {
    setSelectedRow(null);
    setSelectAll(!selectAll);

    if (!selectAll) {
      const items = gridData.map((_item, index) => index);
      setCheckedItems(items);
    } else {
      setCheckedItems([]);
    }
  };

  const formatTotalBalance = (balance: number | null) =>
    balance === null ? '0' : balance < 0 ? `${Math.abs(balance)}CR` : balance;

  const accountsHeaders = () => {
    return (
      <thead>
        <tr>
          <th scope='col' className='checkboxHeader'>
            <Checkbox id='selectAllAccounts' onChange={handleSelectAll} checked={selectAll} />
          </th>
          <th scope='col' className='iconHeader' />
          <th scope='col' className='sort account'>
            <Sort
              title='Account'
              dataValue={'EssAccountID'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'EssAccountID')}
            />
          </th>
          <th scope='col' className='sort name'>
            <Sort
              title='Name'
              dataValue={'AccountName'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'AccountName')}
            />
          </th>
          <th scope='col' className='sort totalBalance'>
            <Sort
              title='Total balance'
              dataValue={'TotalBalance'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'TotalBalance')}
            />
          </th>
          <th scope='col' className='sort ddAccountNo'>
            <Sort
              title='DD account no.'
              dataValue={'DDAccountNo'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'DDAccountNo')}
            />
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const accountsRows = () => {
    const invoiceData: Api.IAccountsForDDSetupData[] = gridData as Api.IAccountsForDDSetupData[];
    const items = invoiceData.map((item: Api.IAccountsForDDSetupData, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            className={`item ${selectedRow === index ? 'selected' : ''} ${expandRow ? 'open' : ''}`}
            onClick={() => {
              if (selectedRow !== index) {
                handleRowSelect(index);
              } else {
                handleDDSetupStep(index);
                handleRowSelect(null);
              }
            }}
          >
            <td className='checkbox-cell'>
              <span onClick={event => event.stopPropagation()}>
                <Checkbox
                  id={`invoice-${item.essAccountID}-${index}`}
                  onChange={() => handleItemChange(index)}
                  checked={isChecked(index)}
                />
              </span>
            </td>
            <td className='icon'>{getFuelIcon(selectedType)}</td>
            <td className='account'>
              <div>{item.essAccountID}</div>
            </td>
            <td className='name'>
              <div>{item.accountName}</div>
            </td>
            <td className='totalBalance'>
              <div>
                £{formatTotalBalance(item.totalBalance)} - As of{' '}
                {item.balanceDate && moment(item.balanceDate).format(DATE_FORMAT)}
              </div>
            </td>
            <td className='ddAccountNo'>{item.existingDDStatus === 'Active' && hideCharacters(item.ddAccountNo, 4)}</td>
            <td className='actions-wrapper'>
              <ButtonPrimary
                title={`${item.existingDDStatus === 'Active' ? 'Amend' : 'Set up'} Direct Debit`}
                onClick={(event: any) => event.preventDefault()}
                className='col m-0'
              />
            </td>
          </tr>
        </React.Fragment>
      );
    });
    return items;
  };

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>, typeId: string) => {
    clearSelection();
    let details = sortDetails;
    if (details.field === typeId) {
      details.order = details.order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }

    props.handleSortChange(details);
    event.preventDefault();
  };

  const getSelectedAccounts = (indexes: number[]) => {
    return gridData.filter((_item: any, index: number) => indexes.includes(index));
  };

  const handleDDSetupStep = (singleAccountIndex?: number) => {
    const selectedItems = singleAccountIndex !== undefined ? [singleAccountIndex] : checkedItems;
    const accountsToProceed = getSelectedAccounts(selectedItems);

    setSelectedAccounts(accountsToProceed);
    history.push('direct-debit/form');
  };

  const handlePagingChange = () => {
    if (selectAll) {
      handleSelectAll();
    }
    props.handlePagingChange();
  };

  return (
    <ChooseAccountWrapper>
      {!isLoading && gridData.length === 0 && <NoResults />}

      {gridData.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={props.gridData.length}
            next={handlePagingChange}
            hasMore={props.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <div
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1'
            >
              <div className='table-responsive table-fixed fixed-column columns-1'>
                <table className='list table plain'>
                  <>
                    {accountsHeaders()}
                    <tbody>{accountsRows()}</tbody>
                  </>
                </table>
              </div>
            </div>
          </InfiniteScroll>
        </>
      )}

      {checkedItems.length > 0 && <SetupButton onClick={handleDDSetupStep} />}

      {isLoading && <Loading overlay />}
    </ChooseAccountWrapper>
  );
};

ChooseAccount.defaultProps = defaultProps;
