// Modules
import { useContext } from 'react';

// Components
import { OpusProfileContactInfo } from './OpusProfileContactInfo';
import { DraxProfileContactInfo } from './DraxProfileContactInfo';
import { ButtonPrimary, ExternalLink, Icon } from '../../common/components';

// Utils
import { isDraxBrand } from '../../utils/common';
import useVisible from '../../hooks/useVisibile';
import { TagManager } from '../utils/analytics/TagManager';
import { iconsToUse } from '../../common/styles/themes';
import * as variables from '../../common/styles/variables';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

type HeaderHelpProfileProps = {
  hasRenewableAccount: boolean;
};

export const HeaderHelpProfile = (props: HeaderHelpProfileProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const { ref, isComponentVisible, setIsComponentVisible } = useVisible(false);

  const handleShow = (event: any) => {
    event.preventDefault();
    setIsComponentVisible(!isComponentVisible);
    if (!isComponentVisible) {
      TagManager.pushData({
        event: 'Open User Profile',
      });
    }
  };
  const onChangePasswordClick = () => {
    TagManager.pushData({
      event: 'Launch password reset',
    });
  };

  return (
    <div ref={ref} className={isComponentVisible ? 'icon-circle-help' : 'icon-circle'}>
      <a href='/' onClick={handleShow} className='col-auto p-3 icon-help'>
        <Icon
          className={isComponentVisible ? 'active' : ''}
          size={variables.iconSizeExtraSmall}
          icon={iconsToUse.profile}
        />
      </a>

      <div className={`profile-popup ${isComponentVisible ? '' : 'hidden'}`}>
        <ExternalLink
          onClick={onChangePasswordClick}
          title='Change password'
          href={
            process.env.REACT_APP_AUTH_URL_BRANDED +
            '/Account/CreateYourNewPassword?id=' +
            userDetail.userId +
            '&ReturnUrl=' +
            process.env.REACT_APP_PUBLIC_URL
          }
          target='_self'
          className='d-block btn btn-terinary btnchangepassword'
        />
        <ButtonPrimary className={'btnsignout'} title={'Sign out'} route='/signout' />
        <br />

        {isDraxBrand ? (
          <DraxProfileContactInfo />
        ) : (
          <OpusProfileContactInfo hasRenewableAccount={props.hasRenewableAccount} />
        )}
      </div>
    </div>
  );
};
