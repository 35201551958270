import styled from 'styled-components';

import { respondTo } from '../../styles/mixins';

type Props = {
  fontSize: string;
};

export const StyledTitle = styled.h4<Partial<Props>>`
  text-align: center;
  color: ${({ theme }) => theme.primary.color};
  font-size: ${(props: any) => props.fontSize || props.theme.title.fontSize};
  margin: 0;

  ${respondTo.tablet`
    text-align: left;
  `}
`;
