// Modules
import React, { useContext } from 'react';

import { ButtonDownload } from '../../../../common/components';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../../../context/userDetail/userDetailContext';

// Styles
import { DownloadButtonsWrapper } from './MeterReadsFilterAdditionalItems.styles';

export const MeterReadsFilterAdditionalItems = (openModal: Function) => {
  const { isSMECustomer, isCorpCustomer, isCorpBroker } = useContext<UserDetailContextProps>(UserDetailContext);
  const additionalItems = [];

  const downloadOptions = [
    {
      display: 'CSV',
      value: 'csv',
    },
    {
      display: 'XML',
      value: 'xml',
    },
  ];

  const getDownloadText = () => {
    if (isSMECustomer()) {
      return 'Meter read history';
    } else if (isCorpBroker() || isCorpCustomer()) {
      return 'Download meter read history';
    }
  };

  const downloadReadReport = (option: Common.IOption) => {
    // console.log(`Download: "${option.value}"`);
    // TODO: Download read report
  };

  if (isSMECustomer() || isCorpCustomer() || isCorpBroker()) {
    additionalItems.push(
      <DownloadButtonsWrapper key='meterReadsAdditionalItem1' className='additional-buttons additional-buttons-spacing'>
        <ButtonDownload
          className='pt-1'
          outline={true}
          icon='upload'
          title={getDownloadText()}
          onButtonClick={() => openModal(true)}
        />

        {/* <ButtonDownload
          className='download__btn read_report__btn mb-4 mt-2 my-md-0'
          options={downloadOptions}
          outline={false}
          title='Opening read report'
          onClick={downloadReadReport}
        /> */}
      </DownloadButtonsWrapper>
    );
  }

  return additionalItems;
};
