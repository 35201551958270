import React, { useState, useEffect, useContext, useRef } from 'react';
import IcomoonReact from 'icomoon-react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { ModalTemplate } from '../mymessages/modalTemplates/ModalTemplate';
import { UsersWrapper } from './UsersGrid.styles';
import { ButtonPrimary, Loading, RowSelector, Sort, NoResults } from '../../common/components';
import iconSet from '../../assets/selection.json';
import { ManageUserModal } from '../mymessages/modalTemplates/ManageUserModal';
import { ContactDeleteConfirmationModal } from './ContactDeleteConfirmationModal';

export const GRID_TYPES = {
  ACCOUNT_USERS: 'Account users',
  LIMITED_ACCESS_USERS: 'Limited access users',
};

type UsersGridComponentProps = {
  selectedType: string;
  gridData: Api.IAccountUserData[] | Api.ILimitedAccessUserData[];
  handleSortChange: Function;
  handlePagingChange: Function;
  handleRevokeUser: Function;
  handleUpdateUser: Function;
  isLoading?: boolean;
  hasMoreData: boolean;
};

const defaultProps = {
  isLoading: true,
  gridData: [],
  selectedType: 'Electricity',
  hasMoreData: true,
};

export const UsersGrid = (props: UsersGridComponentProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const { gridData, selectedType, isLoading } = props;
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [sortDetails, setSortDetails] = useState<Common.ISort>({ field: 'name', order: 'ASC' });
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const scrollableTable = useRef<any>();
  const [showRevokeUserModal, setShowRevokeUserModal] = useState<boolean>(false);
  const [showManageUserModal, setShowManageUserModal] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>({});

  useEffect(() => {
    clearSelection();
    setSortDetails({ field: 'name', order: 'ASC' });
  }, [selectedType]);

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
    setUserInfo(null);
  };

  const handleSelectItem = (selectedRow: number | null) => {
    if (selectedRow !== undefined) {
      setSelectedRow(selectedRow);
      setExpandRow(false);
    }
  };

  const toggleRow = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    setExpandRow(!expandRow);
  };

  const handleShowRevokeUserModal = () => {
    setShowRevokeUserModal(!showRevokeUserModal);
  };

  const handleCloseRevokeUserModal = () => {
    setShowRevokeUserModal(false);
  };

  const handleRevokeUser = () => {
    if (selectedRow !== null) {
      const userId = gridData[selectedRow].userId;
      const customerId = gridData[selectedRow].essCustomerID;
      props.handleRevokeUser(userId, customerId);
    }
    setShowRevokeUserModal(false);
    clearSelection();
  };

  const handleManage = (event: any) => {
    event.preventDefault();

    if (!userDetail.isPrimaryContact && !userDetail.isAccountSignatory) {
      return;
    }

    if (selectedRow !== null) {
      const selectedItem = props.gridData[selectedRow];
      setUserInfo(selectedItem);
    }

    setShowManageUserModal(true);
  };

  const handleCloseManageUserModal = () => {
    setUserInfo(null);
    setShowManageUserModal(false);
  };

  const handleManageUser = (data: any) => {
    var user = {
      associatedcustomer: data.associatedcustomer.value,
      email: data.email,
      firstname: data.firstname,
      jobtitle: data.jobtitle,
      mobile: data.mobile,
      phoneNumber: data.phoneNumber,
      surname: data.surname,
      title: data.title.value,
      profilingOptIn: data.profilingOptIn,
    };

    props.handleUpdateUser(user);

    setShowManageUserModal(false);
    clearSelection();
  };

  const handleDeleteContact = () => {
    setShowDeleteConfirmationModal(true);
  };

  const onDeleteConfirmationSubmit = (confirmed: boolean) => {
    if (confirmed) {
      // TODO: perform request to delete contact
      console.log('TODO: Delete contact');
    }

    setShowDeleteConfirmationModal(false);
  };

  const gridHeaders = () => {
    if (selectedType === GRID_TYPES.ACCOUNT_USERS) return gridHeadersAccountUsers();
    else if (selectedType === GRID_TYPES.LIMITED_ACCESS_USERS) return gridHeadersLimitedAccountUsers();
  };

  const gridRows = () => {
    if (selectedType === GRID_TYPES.ACCOUNT_USERS) return gridRowsAccountUsers();
    else if (selectedType === GRID_TYPES.LIMITED_ACCESS_USERS) return gridRowsLimitedAccountUsers();
  };

  const gridHeadersAccountUsers = () => {
    return (
      <thead>
        <tr>
          <th scope='col' className='sort'>
            <Sort
              title='Name'
              dataValue={'name'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'name')}
            />
          </th>
          <th scope='col' className='sort'>
            <Sort
              title='Position'
              dataValue={'position'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'position')}
            />
          </th>
          <th scope='col' className='sort'>
            <Sort
              title='Contact information'
              dataValue={'contactInfo'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'contactInfo')}
            />
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const gridHeadersLimitedAccountUsers = () => {
    return (
      <thead>
        <tr>
          <th scope='col' className='sort'>
            <Sort
              title='Name'
              dataValue={'name'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'name')}
            />
          </th>
          <th scope='col' className='sort'>
            <Sort
              title='Username'
              dataValue={'username'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'username')}
            />
          </th>
          <th scope='col' className='sort'>
            <Sort
              title='Linked to'
              dataValue={'linkedTo'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'linkedTo')}
            />
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const gridRowsAccountUsers = () => {
    const rows: Api.IAccountUserData[] = gridData as Api.IAccountUserData[];
    const items = rows.map((item: Api.IAccountUserData, index: number) => {
      return (
        <React.Fragment key={index}>
          <RowSelector
            className={`item ${selectedRow === index ? 'selected' : ''} ${expandRow ? 'open' : ''}`}
            onClick={event => {
              if (selectedRow !== index) {
                handleSelectItem(selectedRow !== index ? index : null);
              }
            }}
          >
            <td>
              {item.firstname} {item.surname}
            </td>
            <td>{item.jobtitle}</td>
            <td>
              {item.mobile ? item.mobile : item.phoneNumber}
              {expandRow && selectedRow === index && <div>{item.email}</div>}
            </td>
            <td className='actions-wrapper text-right pl-0'>
              {item.primaryContact && (
                <IcomoonReact className='icon mr-4' iconSet={iconSet} size={20} icon='user-lock' />
              )}
              <ButtonPrimary
                className='m-0'
                title='Manage'
                onClick={handleManage}
                disabled={!userDetail.isPrimaryContact && !userDetail.isAccountSignatory}
              />
              <a href='/' className='trigger d-flex' onClick={(event: any) => toggleRow(event)}>
                {expandRow && selectedRow === index ? (
                  <IcomoonReact className='icon' iconSet={iconSet} size={20} icon='chevron-up' />
                ) : (
                  <IcomoonReact className='icon' iconSet={iconSet} size={20} icon='chevron-down' />
                )}
              </a>
            </td>
          </RowSelector>

          {expandRow && selectedRow === index && (
            <tr className={`sub-row ${selectedRow === index ? 'selected' : ''} ${expandRow ? 'open' : ''}`}>
              <td>
                <div>Customer ID</div>
                <div>{item.essCustomerID}</div>
              </td>
              <td>
                <div>Mobile</div>
                <div>{item.mobile}</div>
              </td>
              <td colSpan={2}>
                <div>
                  {item.primaryContact ? (
                    <>
                      <div>
                        Primary access
                        <IcomoonReact
                          className='icon pl-2 primary-access-icon'
                          iconSet={iconSet}
                          size={20}
                          icon='user-lock'
                        />
                      </div>
                      <div>This is the main user for this account and will receive all correspondence</div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
    return items;
  };

  const gridRowsLimitedAccountUsers = () => {
    const rows: Api.ILimitedAccessUserData[] = gridData as Api.ILimitedAccessUserData[];
    const items = rows.map((item: Api.ILimitedAccessUserData, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            className={`item ${selectedRow === index ? 'selected' : ''} ${expandRow ? 'open' : ''}`}
            onClick={() => {
              if (selectedRow !== index) {
                handleSelectItem(selectedRow !== index ? index : null);
              }
            }}
          >
            <td>
              <div>{item.greeting}</div>
            </td>
            <td>
              <div>{item.userName}</div>
            </td>
            <td>
              <div>
                {item.essCustomerID} - {item.organisation}
              </div>
            </td>
            <td className='actions-wrapper'>
              <a
                href='/'
                className='delete-item'
                onClick={event => {
                  event.preventDefault();
                  handleShowRevokeUserModal();
                }}
              >
                <IcomoonReact className='icon-trash' iconSet={iconSet} size={20} icon='trash2' />
              </a>
            </td>
          </tr>
        </React.Fragment>
      );
    });

    return items;
  };

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>, typeId: string) => {
    clearSelection();
    let details = sortDetails;
    if (details.field === typeId) {
      details.order = details.order === 'ASC' ? 'DESC' : 'ASC';
      setSortDetails(details);
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }

    props.handleSortChange(details);
    event.preventDefault();
  };

  const handlePagingChange = () => {
    props.handlePagingChange();
  };

  return (
    <>
      <UsersWrapper>
        {!isLoading && gridData && gridData.length === 0 && <NoResults />}

        {gridData.length > 0 && (
          <>
            <InfiniteScroll
              dataLength={gridData.length}
              next={handlePagingChange}
              hasMore={props.hasMoreData}
              loader={''}
              scrollableTarget='scrollableDiv'
              scrollThreshold={0.9}
            >
              <div
                id='scrollableDiv'
                ref={scrollableTable}
                className='table-responsive table-fixed fixed-column columns-1'
              >
                <table className='users-list table plain'>
                  <>
                    {gridHeaders()}
                    <tbody>{gridRows()}</tbody>
                  </>
                </table>
              </div>
            </InfiniteScroll>
          </>
        )}

        {isLoading && <Loading overlay />}
      </UsersWrapper>
      <ModalTemplate
        name={'RevokeUserModal'}
        show={showRevokeUserModal}
        onHide={handleCloseRevokeUserModal}
        handleConfirm={handleRevokeUser}
      />

      {showManageUserModal && (
        <ManageUserModal
          show={true}
          onHide={handleCloseManageUserModal}
          handleConfirm={handleManageUser}
          handleDeleteContact={handleDeleteContact}
          userInfo={userInfo}
        />
      )}

      {showDeleteConfirmationModal && <ContactDeleteConfirmationModal onSubmit={onDeleteConfirmationSubmit} />}
    </>
  );
};

UsersGrid.defaultProps = defaultProps;
