// Modules
import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { DateObject, Calendar } from 'react-multi-date-picker';

// Components
import { XModalClose } from '../XModalClose';

// Styles
import { isMobileOnly } from 'react-device-detect';

type DateMonthDayFilterModalProps = {
  show: boolean;
  onHide: () => void;
  enableDaily?: boolean;
  enableMonthly?: boolean;
  selectedRange?: Ranges;
  onDateSelect: Function;
  previouslySelectedDate?: { begin: string | null; end: string | null };
};

DateMonthDayFilterModal.defaultProps = {
  enableDaily: false,
  enableMonthly: false,
  selectedRange: undefined,
};

export enum Ranges {
  '2years' = '2 Years',
  '1year' = '1 Year',
  '6months' = '6 Months',
  'monthly' = 'Monthly',
  'daily' = 'Daily',
}
export type SelectDateResult = {
  type: 'range' | 'error';
  end?: moment.Moment;
  begin?: moment.Moment;
};

export function DateMonthDayFilterModal(props: DateMonthDayFilterModalProps) {
  // Refs
  const lastSelectedDateObjectRef = React.useRef<DateObject | null>(null);

  // Props
  const { show, onHide, enableDaily, onDateSelect, selectedRange, enableMonthly, previouslySelectedDate } = props;

  // State
  const [monthPickerValue, setMonthPickerValue] =
    React.useState<Date | DateObject | DateObject[] | string | undefined>('');
  const [dayPickerValue, setDayPickerValue] = React.useState<Date | DateObject | DateObject[] | string | undefined>('');

  const handleDateChange = (dateObject: DateObject) => {
    try {
      const result: SelectDateResult = {
        type: 'range',
      };

      if (dateObject) {
        let option = null;
        let endDate = moment(dateObject.toDate()).startOf('day');
        let startDate = moment(endDate);

        lastSelectedDateObjectRef.current = null;
        if (props.enableMonthly) {
          option = Ranges.monthly;
          result.begin = startDate.endOf('month');
          result.end = endDate;
          lastSelectedDateObjectRef.current = dateObject;
        } else if (props.enableDaily) {
          option = Ranges.daily;
          result.begin = startDate.endOf('day');
          result.end = endDate;
          lastSelectedDateObjectRef.current = dateObject;
        }

        if (typeof onDateSelect === 'function') {
          onDateSelect(result, option, props.enableDaily, props.enableMonthly);
        }
      }
    } catch (error) {
      console.error(`Failed to handle date change`, error);
    }
  };

  React.useEffect(() => {
    if (previouslySelectedDate?.end) {
      if (selectedRange === Ranges['daily']) {
        setDayPickerValue(new Date(previouslySelectedDate.end));
      } else if (selectedRange === Ranges['monthly']) {
        setMonthPickerValue(new Date(previouslySelectedDate.end));
      }
    }
  }, [previouslySelectedDate, selectedRange]);

  const getDatePickerOptions = () => {
    let options: Ranges[] = [Ranges['2years'], Ranges['1year'], Ranges['6months']];

    if (enableMonthly) {
      options.push(Ranges['monthly']);
    }
    if (enableDaily) {
      options.push(Ranges['daily']);
    }
    return options;
  };

  return (
    <Modal
      className='d-flex justify-content-center align-items-center modal-full-width'
      centered
      onHide={onHide}
      show={show}
    >
      <Modal.Header>
        <XModalClose close={onHide} />
      </Modal.Header>
      <Modal.Body className='d-flex flex-column flex-md-row justify-content-around align-items-center'>
        <>
          {props.enableDaily && (
            <Calendar
              numberOfMonths={isMobileOnly ? 1 : 2}
              minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
              maxDate={new Date()}
              value={dayPickerValue}
              onChange={handleDateChange}
            />
          )}
          {props.enableMonthly && (
            <Calendar
              minDate={new Date().setFullYear(new Date().getFullYear() - 2)}
              maxDate={new Date()}
              onlyMonthPicker
              value={monthPickerValue}
              onChange={handleDateChange}
            />
          )}
        </>
      </Modal.Body>
    </Modal>
  );
}
