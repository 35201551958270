import styled from 'styled-components';

import * as variables from '../../common/styles/variables';

export const ContactInfoWrapper = styled.div`
  .info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${variables.spacingXSmall};

    svg {
      margin-right: ${variables.spacingSmall};
      fill: ${({ theme }) => theme.text.secondary};
    }
  }
`;
