import styled from 'styled-components';

import { respondTo } from '../../common/styles/mixins';
import * as colors from '../../common/styles/colors';

export const ServiceUpdateWrapper = styled.div`
  width: 100%;
  display: inline-block;
  font-weight: 400;
  color: ${colors.black};

  > div {
    background-color: ${colors.lightGrey};
    border-radius: 10px;

    .icon {
      padding: 0;

      ${respondTo.mobileSm`
        padding: 10px 15px 15px 15px;
      `}
    }
  }

  .low {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.serviceMessages.low};
    color: ${colors.white};

    a {
      color: ${colors.white};
    }
  }

  .medium {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.serviceMessages.medium};
    color: ${colors.white};

    a {
      color: ${colors.white};
    }
  }

  .high {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.serviceMessages.high};
    color: ${colors.white};

    a {
      color: ${colors.white};
    }
  }

  .service-update {
    padding: 15px;
    display: flex;
    align-items: center;

    ${respondTo.tablet`
      padding: 5px 0px;
      padding-right: 30px;
    `};
  }

  .icon {
    padding: 10px 15px 15px 15px;
  }

  .message {
    padding-left: 5px;

    ${respondTo.tablet`
      padding-left: 5px;
      width: 100%!important;
    `};
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }
`;
