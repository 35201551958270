import styled from 'styled-components';
import * as mixins from '../../../../common/styles/mixins';
import * as colors from '../../../../common/styles/colors';

export const AccountSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 25px;

  .modal-title {
    color: ${colors.hydroBlue};
    margin-bottom: 24px;
    font-size: 1.5rem;
  }

  .dropdown {
    width: auto;
  }

  .AccountSelectionOverviewMobile {
    ${mixins.widgetMobile};
  }

  .AccountSelectionOverview {
    ${mixins.widgetDesktop};

    p {
      margin-bottom: 22px;
    }
  }

  .container {
    width: 100%;
    margin: 0px;
    padding-left: 0px;
  }
  
  .left {
    float: left;
    padding-right: 10px;
  }
  
  .right {
    text-align: center!important;
  }
`;
