import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary, Icon, ModalClose } from '../../../common/components';
import * as colors from '../../../common/styles/colors';

type BlankModalProps = {
  show: boolean;
  onHide: any;
  titleText?: string;
  bodyText?: string;
  buttonText?: string;
  contactEmail?: string;
  contactNumber?: string;
};

const defaultProps = {
  show: true,
};

export const BlankModal = (props: BlankModalProps) => {
  const { show } = props;

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header className='pt-0 pb-0 pr-0'>
          <Modal.Title>{props.titleText}</Modal.Title>
          <ModalClose
            onClick={() => {
              props.onHide();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          {props.bodyText && <p>{props.bodyText}</p>}

          {props.contactEmail && (
            <div className='pl-1'>
              <Icon size={17} icon={'envelope'} />
              <a
                className='pl-2'
                href={`mailto:${props.contactEmail}`}
                style={{ color: colors.hyperlinkBlue, textDecoration: 0 }}
              >
                {props.contactEmail}
              </a>
            </div>
          )}

          {props.contactNumber && (
            <div className='pl-1'>
              <Icon size={17} icon={'headset'} />
              <a className='pl-2' href={`tel:${props.contactNumber}`} style={{ textDecoration: 0 }}>
                {props.contactNumber}
              </a>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className='p-0'>
          <ButtonPrimary onClick={() => props.onHide()} className={'mr-0'} title={props.buttonText} route='#' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

BlankModal.defaultProps = defaultProps;
