import { useEffect, useState } from 'react';

import { GridTypeSelectorWrapper } from './GridTypeSelector.styles';
import { isDraxBrand } from '../../../utils/common';

type GridTypeSelectorProps = {
  initialType: string;
  types: Array<string>;
  handleTypeChange: Function;
};

const defaultProps = {
  initialType: '',
  types: [],
};

export const GridTypeSelector = (props: GridTypeSelectorProps) => {
  const [selectedType, setSelectedType] = useState<string>(props.initialType);

  useEffect(() => {
    setSelectedType(props.initialType);
  }, [props.initialType]);

  const handleTypeChange = (event: any) => {
    if (selectedType !== event.target.innerText) {
      setSelectedType(event.target.innerText);
      event.preventDefault();

      props.handleTypeChange(event.target.innerText);
    }
  };

  const isFilterHidden = isDraxBrand && props.types.length < 2;

  return (
    <GridTypeSelectorWrapper>
      {!isFilterHidden && (
        <ul className='types d-flex my-4'>
          {props.types.map((type: string, index: number) => (
            <li key={index}>
              <a
                href={`#${type}`}
                className={`${selectedType === type ? 'active' : ''}`}
                onClick={event => handleTypeChange(event)}
              >
                {type}
              </a>
            </li>
          ))}
        </ul>
      )}
    </GridTypeSelectorWrapper>
  );
};

GridTypeSelector.defaultProps = defaultProps;
