import styled from 'styled-components';

import * as variables from '../../styles/variables';
import { respondTo } from '../../styles/mixins';

export const GridTypeSelectorWrapper = styled.div`
  .types {
    justify-content: center;

    ${respondTo.tablet`
      justify-content: left;
    `};

    li {
      padding: 0 ${variables.spacingSmall};
      font-size: ${variables.fontSizeMedium};

      a {
        text-decoration: none;

        &:hover,
        &.active {
          color: ${({ theme }) => theme.text.secondary};
          text-decoration: underline;
        }
      }
    }
  }
`;
