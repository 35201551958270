// Components
import { Icon } from '../../common/components';

// Utils
import useVisible from '../../hooks/useVisibile';
import * as variables from '../../common/styles/variables';

// Context
import { HelpQuestion } from '../icons';
import { HelpSupport } from './HelpSupport';

type HeaderHelpSupportProps = {};

export const HeaderHelpSupport = (props: HeaderHelpSupportProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useVisible(false);
  
  const handleShow = (event: any) => {
    event.preventDefault();
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div ref={ref} className={isComponentVisible ? 'icon-circle-help' : 'icon-circle'}>
      <a href='/' onClick={handleShow} className='col-auto p-3' style={{ left: '-8.5px', top: '5.5px' }}>
        <Icon className={isComponentVisible ? 'active' : ''} size={variables.iconSizeSmall} icon={HelpQuestion} />
      </a>

      <HelpSupport isComponentVisible={isComponentVisible} handleClose={() => setIsComponentVisible(false)}></HelpSupport>
    </div>
  );
};
