import styled from 'styled-components';

import * as colors from '../../../common/styles/colors';
import * as variables from '../../../common/styles/variables';
import { respondTo } from '../../../common/styles/mixins';
import * as mixins from '../../../common/styles/mixins';

export const SearchActionsWrapper = styled.div`
  ${respondTo.tablet`
        .menu-dropdown {
            display: none;
        }
        .menu-items {
            display: block !important;

            & > div {
              white-space: nowrap;
            }
        }
    `};

  .menu-items {
    display: none;
  }

  a {
    color: ${colors.black};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.text.secondary};
      text-decoration: none;
    }
  }

  .menu-dropdown {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .active-dropdown {
      top: 55px;
      width: 100%;
      z-index: ${variables.zIndexHighest};
      border-top: 1px ${colors.darkGrey} solid;
      padding-top: 15px;
      margin-top: 10px;
    }

    .menu-list {
      height: 100%;
      background-color: ${colors.inputGrey};
      padding: 0 40px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      ${respondTo.tablet`
          padding: 0 ${variables.spacingSmall};
      `}

      .item {
        padding: 8px 15px;
      }
    }

    .hidden-dropdown {
      display: none;
    }

    svg {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    svg path {
      ${mixins.transition};
    }
`;
