import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import { respondTo } from '../../../common/styles/mixins';

import Modal from 'react-bootstrap/Modal';
import { ButtonDownload, ModalClose } from '../../../common/components';
import { TagManager } from '../../utils/analytics/TagManager';

type DownloadDirectDebitModalProps = {
  accountManager: string;
  show: boolean;
  onHide: any;
};

const defaultProps = {
  accountManager: 'xxx',
  show: true,
};

export const Wrapper = styled.div`
  .download-btn {
    a {
      padding: 0.2rem 2rvem !important;
      background-color: ${colors.hydroBlue} !important;
      color: ${colors.white} !important;

      &:hover {
        background-color: ${colors.draxDarkBlue} !important;
      }

      & > svg {
        fill: ${colors.white} !important;
        transform: rotate(180deg);
      }

      ${respondTo.desktop`
        padding: 0.5rem 2rem !important;
      `};
    }
  }
`;

export const DownloadDirectDebitModal = (props: DownloadDirectDebitModalProps) => {
  const handleDownloadClick = (event: any) => {
    TagManager.pushData({
      event: 'Download DD form (Account & Sites)',
    });

    const route = '/files/dd-mandate-form.pdf';
    window?.open(route, '_blank')?.focus();

    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={props.show} backdrop='static' centered>
        <Wrapper>
          <Modal.Header className='pt-0 pb-0 pr-0'>
            <Modal.Title>Download the Direct Debit form</Modal.Title>
            <ModalClose onClick={() => props.onHide()} />
          </Modal.Header>

          <Modal.Body>
            <p>Fill in the relevant details and send the completed form to majorbusinessenquiries@drax.com</p>
          </Modal.Body>

          <div className='d-flex justify-content-end'>
            <ButtonDownload
              className='mt-3 download-btn'
              outline={true}
              icon='upload'
              title='Download Direct Debit Mandate'
              onButtonClick={handleDownloadClick}
            />
          </div>
        </Wrapper>
      </Modal>
    </>
  );
};

DownloadDirectDebitModal.defaultProps = defaultProps;
