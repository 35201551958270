import { useContext } from 'react';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../assets/selection.json';
import { GenerationOverviewWrapper, YourGenerationText } from './YourGeneration.styles';
import { HomeContext, HomeContextProps } from '../context/homeContext';

type YourGenerationProps = {
  electricityGenerated: string;
  incomeGenerated: string;
  lastQuarter: string;
};

const defaultProps = {};

const WIDGET_KEY = 'YourGeneration';

export const YourGeneration = (props: YourGenerationProps) => {
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);

  function toggleActive() {
    let active = true;
    if (homeState.selectedCard === WIDGET_KEY) active = !homeState.active;

    setHomeState({ selectedCard: WIDGET_KEY, active: active });
  }

  const isDropdownOpened = homeState.active && homeState.selectedCard === WIDGET_KEY;

  return (
    <GenerationOverviewWrapper {...props}>
      <div className='generationOverview'>
        <h2>{YourGenerationText.header}</h2>
        <p>{YourGenerationText.para}</p>
        <div className='elecGeneration clearfix'>
          <div className='category'>
            <i className='iconRenewablesPortal'>
              <IcomoonReact iconSet={iconSet} size={50} icon='renewables' />
            </i>
          </div>
          <div className='elecTotal'>
            <span className='amount'>{props.electricityGenerated}</span>
            <span className='amountType'>{YourGenerationText.kWh}</span>
            <br />
            <span className='period'>{props.lastQuarter}</span>
          </div>
        </div>
        <div className='elecGeneration clearfix'>
          <div className='category'>
            <div>
              <i className='iconGasPortal'>
                <IcomoonReact iconSet={iconSet} size={50} icon='cash-pound' />
              </i>
            </div>
          </div>
          <div className='elecTotal'>
            <span className='amount'>£{props.incomeGenerated}</span>
            <br />
            <span className='period'>{props.lastQuarter}</span>
          </div>
        </div>
      </div>
      <div className='generationOverviewMobile'>
        <div onClick={toggleActive}>
          <h2>{YourGenerationText.headerBtn}</h2>
          <div className='menu-dropdown'>
            <IcomoonReact
              className='itemicon chevron'
              iconSet={iconSet}
              size={15}
              icon={isDropdownOpened ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        <div className={isDropdownOpened ? 'active-dropdown' : 'hidden-dropdown'}>
          <p>{YourGenerationText.para}</p>
          <div className='elecGeneration clearfix'>
            <div className='category'>
              <i className='iconRenewablesPortal'>
                <IcomoonReact iconSet={iconSet} size={50} icon='renewables' />
              </i>
            </div>
            <div className='elecTotal'>
              <span className='amount'>{props.electricityGenerated}</span>
              <span className='amountType'>{YourGenerationText.kWh}</span>
              <br />
              <span className='period'>{props.lastQuarter}</span>
            </div>
          </div>
          <div className='elecGeneration clearfix'>
            <div className='category'>
              <div>
                <i className='iconGasPortal'>
                  <IcomoonReact iconSet={iconSet} size={50} icon='cash-pound' />
                </i>
              </div>
            </div>
            <div className='elecTotal'>
              <span className='amount'>£{props.incomeGenerated}</span>
              <br />
              <span className='period'>{props.lastQuarter}</span>
            </div>
          </div>
        </div>
      </div>
    </GenerationOverviewWrapper>
  );
};

YourGeneration.defaultProps = defaultProps;
