import React, { ReactElement, useContext } from 'react';
import AuthService from '../services/authService';

const AuthContext = React.createContext<any>(undefined);

export const AuthConsumer = AuthContext.Consumer;

export const useAuthState = (): AuthService => {
  const context = useContext<AuthService>(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
};

type AuthProviderProps = {
  children: ReactElement;
};

export const AuthProvider = (props: AuthProviderProps): ReactElement => {
  const authService = new AuthService();

  return <AuthContext.Provider value={authService}>{props.children}</AuthContext.Provider>;
};
