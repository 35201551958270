import { useState, useEffect } from 'react';
import { IHealthCheck } from '../Health/Health';

import { getHealthCheck } from '../../../common/api/healthCheckApi';
import { useAuthState } from '../../../providers/authProvider';

export const useFetchHealthChecks = (
  hc: Array<IHealthCheck>,
  customerAccounts: Array<Api.IEssAccountData>,
  status: 'loading' | 'healthy' | 'notHealthy'
) => {
  const authContext = useAuthState();
  const [healthChecks, setHealthChecks] = useState<Array<IHealthCheck>>(hc);

  const updateLoadingHealthChecks = (status: 'healthy' | 'notHealthy') => {
    const updatedHealthChecks = healthChecks.map((healthCheck: IHealthCheck) => {
      if (healthCheck.status === 'loading') healthCheck.status = status;
      return healthCheck;
    });
    setHealthChecks([...updatedHealthChecks]);
  };

  const updateHealthCheck = (
    entityType: string,
    status: 'loading' | 'healthy' | 'notHealthy',
    response?: Array<any>
  ) => {
    const hc = healthChecks.find((healthCheck: IHealthCheck) => healthCheck.type === entityType);
    if (hc) {
      hc.status = status;
      hc.response = response;
      setHealthChecks([...healthChecks]);
    } else {
      throw Error(`entityType: ${entityType} not found`);
    }
  };

  const callHealthChecks = (): Array<Promise<Common.IResult<Api.IHealthResponse>>> => {
    let apis: Array<Promise<Common.IResult<Api.IHealthResponse>>> = [];
    healthChecks.map((healthCheck: IHealthCheck) => {
      const accounts = customerAccounts.map((account: Api.IEssAccountData) => {
        return account.essAccountID;
      });

      const api = getHealthCheck(
        process.env.REACT_APP_API_URL,
        authContext,
        healthCheck.url,
        healthCheck.type,
        accounts
      );

      apis.push(api);
    });
    return apis;
  };

  useEffect(() => {
    if (customerAccounts.length === 0) return;

    const apis: Array<Promise<Common.IResult<Api.IHealthResponse>>> = callHealthChecks();

    Promise.allSettled(apis)
      .then((results: Array<PromiseSettledResult<Common.IResult<Api.IHealthResponse>>>) => {
        results.forEach((result: PromiseSettledResult<Common.IResult<Api.IHealthResponse>>) => {
          if (result.status === 'fulfilled') {
            if (result.value != null) {
              const res: Common.IResult<Api.IHealthResponse> = result.value;

              if (res.success) {
                if (res.data.healthy === true) {
                  // Healthy
                  updateHealthCheck(res.data.entityType, 'healthy');
                } else {
                  // NotHealthy
                  updateHealthCheck(res.data.entityType, 'notHealthy', res.data.response);
                }
              }
            }
          }
        });
      })
      .finally(() => {
        // Set all 'loading' to 'notHealthy' as they've failed if they've not completed by this point
        updateLoadingHealthChecks('notHealthy');
      });
  }, [status, customerAccounts]);

  return { healthChecks };
};
