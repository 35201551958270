export const FailureSubmitMessage = (errorText: string) => (
  <div>
    Unfortunately this reading failed our validation process due to the following reason:
    <br />
    <br />
    {errorText}
    <br />
    <br />
    Please amend the data and resubmit.
  </div>
);
