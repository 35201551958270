import { ReactNode, useState } from 'react';
import { ISearchResult } from './searchResult';
import { SearchResultsContext } from './searchResultsContext';

export interface ISearchResultsProviderProps {
  value: ISearchResult;
  children?: ReactNode;
}

export const SearchResultsProvider = (props: ISearchResultsProviderProps) => {
  const [searchResult, setSearchResult] = useState<ISearchResult>(props.value);

  return (
    <SearchResultsContext.Provider value={{ searchResult, setSearchResult }}>
      {props.children}
    </SearchResultsContext.Provider>
  );
};
