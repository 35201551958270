import { useContext, useEffect, ReactElement, useReducer } from 'react';

import { useAuthState } from '../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { ChooseSite } from './ChooseSite';
import { GridFilter } from '../../gridfilter/GridFilter';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../../reducer/gridReducer/gridReducer';
import { isDraxBrand, isOpusBrand } from '../../../utils/common';
import { RoutingToTypes } from '../../search/SearchActions/SearchActions';
import { EssAccountsTypes } from '../../../types/account';
import { FilterRowWrapper } from '../../../common/components';
import { getSelectedCustomerId } from '../../../helpers/customer';
import { getInitialType } from '../../../utils/getInitialTypes';
import { getAllSitesMovingPremises } from '../../../common/api/sitesApi';
import { getMeteringPoints } from '../../../common/api/meterReadsApi';
import { globalApiParams } from '../../../common/api/globals';
import { formatSites } from '../../meterreads/helper';

type MovingPremisesChooseSiteProps = {
  stepsBar: ReactElement;
  pageTitle: ReactElement;
};

export const MovingPremisesChooseSite = ({ stepsBar, pageTitle }: MovingPremisesChooseSiteProps) => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);

  const initialState: IGridReducerState = {
    page: 1,
    currentType: '',
    data: {},
    initialSortField: 'site-reference',
    initialSortOrder: 'DESC',
    currentSortField: 'site-reference',
    currentSortOrder: 'DESC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  // When navigating directly to url
  useEffect(() => {
    if (userDetail.essCustomerId) {
      initialState.currentType = getInitialType(userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable);
      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable]);

  useEffect(() => {
    if (!state.selectedCustomerId) return;
    loadSitesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedCustomerId]);

  const getSitesData = async (essCustomerId: string, siteReference: string | null, sort?: Common.ISort) => {
    const result: Array<Api.ISites> = await getAllSitesMovingPremises(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      siteReference,
      state.page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (result) {
      result.map((item: any) => {
        item.siteReference = `${item['site-reference']}`;
        item.siteAddress = `${item['site-address']}, ${item['site-post-code']}`;
      });

      return result || [];
    }
  };

  const loadSitesData = async () => {
    const sites = await getMeteringPoints(
      process.env.REACT_APP_API_URL,
      authContext,
      state.selectedCustomerId,
      globalApiParams.maxFilterPageSize,
      state.page,
      'Active',
      undefined
    );

    const payload = {
      essCustomerId: state.selectedCustomerId,
      data: {
        customerFilters: [],
        accountFilters: [],
        sitesFilters: formatSites(sites),
        mpansFilters: [],
      },
    };

    dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload });
  };

  const clearData = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
  };

  const loadData = async (customerId: string, siteId: string | null, sort?: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const rawData = await getSitesData(customerId, siteId, sort);

    if (rawData) {
      dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: rawData, page: state.page } });
    } else {
      clearData();
    }
  };

  useEffect(() => {
    if (searchContext.searchDetail != null && searchContext.searchDetail.searchText.length > 0) {
      // filter by search result

      if (searchContext.searchDetail.type && searchContext.searchDetail.routingTo === RoutingToTypes.movingPremises) {
        const type = searchContext.searchDetail.type.toLowerCase();
        if (type === 'mpan') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: searchContext.searchDetail.mpan } });
        } else if (type === 'site') {
          // Note:  only done for electricity
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: searchContext.searchDetail.siteId } });
        }
        // Clear Search Detail
        searchContext.setSearchDetail({ searchText: '' });
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext.searchDetail.type]);

  useEffect(() => {
    // Ignore if we are entering component from Search
    if (
      searchContext.searchDetail != null &&
      searchContext.searchDetail.type &&
      searchContext.searchDetail.type.length > 0
    )
      return;

    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };

    loadData(state.selectedCustomerId, state.selectedSiteReference || null, currentSort);
    // TODO: Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selectedCustomerId,
    state.currentType,
    state.page,
    state.selectedEssAccountId,
    state.currentFilter,
    state.currentSortField,
    state.currentSortOrder,
  ]);

  const handleSortChange = (sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    const essAccountId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId: essAccountId } });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    const essSiteId = option ? option.value : '';
    dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: essSiteId } });
  };

  const handleClearAll = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  return (
    <>
      <FilterRowWrapper>{pageTitle}</FilterRowWrapper>

      <GridFilter
        showSites={true}
        customerData={state.data?.customers}
        accountData={state.data?.accounts}
        siteData={state.data?.sites}
        selectedType={state.currentType}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleAccountFilterChange={handleAccountFilterChange}
        handleSiteFilterChange={handleSiteFilterChange}
        handleClearAllClick={handleClearAll}
        showClearAll={true}
        showAccount={isOpusBrand}
      />

      <div className='pt-3'>{stepsBar}</div>

      <ChooseSite
        selectedType={state.currentType}
        handleSortChange={handleSortChange}
        handlePagingChange={handlePagingChange}
        gridData={state.gridData}
        isLoading={state.isLoading}
        currentSortField={state.currentSortField}
        currentSortOrder={state.currentSortOrder}
        hasMoreData={state.hasMoreData}
      />
    </>
  );
};
