import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from '../../authConfig';
import { toast } from 'react-toastify';

export const getAdminMessages = (account: AccountInfo, instance: IPublicClientApplication, params: Api.IAdminMessagesGridParams, success: Function, error: Function) => {
  const request = {
    ...loginRequest,
    account: account,
  };

  instance
    .acquireTokenSilent(request)
    .then(async (response: any) => {
      var headers = new Headers();
      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'GET',
        headers: headers,
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      headers.append('sortField', params.sortField);
      headers.append('sortDir', params.sortDir);
      headers.append('page', String(params.page));
      headers.append('pageSize', String(params.pageSize));
      
      const currentBulkMessagesEndpoint = `${url}/alert/AllCurrentBulkMessages`;

      fetch(currentBulkMessagesEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((adminMessagesResult: Common.IResult<Array<Api.IAdminMessagesPagedData>>) => {

          if (adminMessagesResult && adminMessagesResult.success) {

            adminMessagesResult.data.map((row: Api.IAdminMessagesPagedData) => {
              row.idExt = `MES-${row.id}`;
              row.statusExt = row.status;
              if (row.status === 'Ready')
                row.statusExt = 'Ready to publish';
              if (row.updatedDate === null && row.updatedBy === null) {
                row.updatedBy = row.createdBy;
              }
            });
          }
          success(adminMessagesResult);
        });
    })
    .catch((e: any) => {
      error('getAdminMessages');
    });
}

export const deleteMessage = (account: AccountInfo, instance: IPublicClientApplication, id: number, success: Function, error: Function) => {
  const request = {
    ...loginRequest,
    account: account,
  };

  instance
    .acquireTokenSilent(request)
    .then(async (response: any) => {
      var headers = new Headers();
      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'PUT',
        headers: headers,
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      const deleteBulkMessagesEndpoint = `${url}/alert/deletebulkmessage/${id}`;

      fetch(deleteBulkMessagesEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((deleteMessagesResult: Common.IResult<Array<boolean>>) => {
          success(deleteMessagesResult);
        });
    })
    .catch((e: any) => {
      error('deleteMessage');
    });
}

export const publishMessage = (account: AccountInfo, instance: IPublicClientApplication, id: number, success: Function, error: Function) => {
  const request = {
    ...loginRequest,
    account: account,
  };

  instance
    .acquireTokenSilent(request)
    .then(async (response: any) => {
      var headers = new Headers();
      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'PUT',
        headers: headers,
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      const publishBulkMessagesEndpoint = `${url}/alert/publishbulkmessage/${id}`;

      fetch(publishBulkMessagesEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((publishMessagesResult: Common.IResult<Array<boolean>>) => {
          success(publishMessagesResult);
        });
    })
    .catch((e: any) => {
      error('publishmessage');
    });
}

export const createMessage = async (account: AccountInfo, message: any, instance: IPublicClientApplication) => {
  const data: Api.IAdminCreateMessageData = {
    subject: message.subject,
    startDate: message.startDate,
    endDate: message.endDate,
    priority: message.priority,
    updatedDate: message.updatedDate,
    updatedBy: message.updatedBy,
    externalLink: message.externalLink,
    internalLink: message.internalLink,
    showInMessages: message.showInMessages,
    status: message.status,
    createdDate: message.createdDate,
    createdBy: message.createdBy,
    json: message.json,
    userType: message.userType,
    references: message.references,
    sendEmail:message.sendEmail,
  };

  const request = {
    ...loginRequest,
    account: account,
  };
  instance.acquireTokenSilent(request).then(async (response: any) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    var bearer = 'Bearer ' + response.idToken;
    headers.append('Authorization', bearer);
    var options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    };

    const url = process.env.REACT_APP_API_SERVICES_URL;

    var createBulkMessagesEndpoint = `${url}/alert/createmessage`;

    fetch(createBulkMessagesEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
    })
    .catch((e: any) => {
      const message = 'API error calling: createmessage';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    });
};

export const updateMessage = async (account: AccountInfo, message: any, instance: IPublicClientApplication, success: Function, error: Function) => {
  const data: Api.IAdminCreateMessageData = {
    id: message.id,
    subject: message.subject,
    startDate: message.startDate,
    endDate: message.endDate,
    priority: message.priority,
    updatedDate: message.updatedDate,
    updatedBy: message.updatedBy,
    externalLink: message.externalLink,
    internalLink: message.internalLink,
    showInMessages: message.showInMessages,
    status: message.status,
    createdDate: message.createdDate,
    createdBy: message.createdBy,
    json: message.json,
    userType: message.userType,
    references: message.references,
    sendEmail:message.sendEmail,
  };

  const request = {
    ...loginRequest,
    account: account,
  };
  instance.acquireTokenSilent(request).then(async (response: any) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');

    var bearer = 'Bearer ' + response.idToken;
    headers.append('Authorization', bearer);
    var options = {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data),
    };

    const url = process.env.REACT_APP_API_SERVICES_URL;

    var createBulkMessagesEndpoint = `${url}/alert/updatebulkmessage`;

    fetch(createBulkMessagesEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((adminMessageUpdateResult: Common.IResult<any>) => {
          success(adminMessageUpdateResult);
        });
    })
    .catch((e: any) => {
      const message = 'API error calling: updatebulkmessage';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    });
};