import Modal from 'react-bootstrap/Modal';

import { ModalClose, ButtonPrimary } from '../../common/components';
import { ActionRowWrapper } from './ContactDeleteConfirmationModal.styles';

type ContactDeleteConfirmationModalProps = {
  show: boolean;
  onSubmit: (confirmed: boolean) => void;
};

const defaultProps = {
  show: true,
  onSubmit: (confirmed: boolean) => console.log(confirmed),
};

export const ContactDeleteConfirmationModal = (props: ContactDeleteConfirmationModalProps) => {
  const { show, onSubmit } = props;
  const onClose = () => onSubmit(false);

  return (
    <>
      <Modal animation={false} className={'mid-size-modal'} show={show} size={'sm'} backdrop='static' centered>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete this contact?</Modal.Title>
          <ModalClose onClick={onClose} />
        </Modal.Header>

        <ActionRowWrapper>
          <ButtonPrimary
            onClick={event => {
              event.preventDefault();
              onSubmit(true);
            }}
            className='submit-btn'
            title={'Yes'}
          ></ButtonPrimary>
        </ActionRowWrapper>
      </Modal>
    </>
  );
};

ContactDeleteConfirmationModal.defaultProps = defaultProps;
