// Modules
import { ReactElement, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// Components
import { Header } from './components/header/Header';
import ServiceUpdate from './components/serviceupdate/ServiceUpdate';
import { ConcurrentLoginMonitoring } from './components/ConcurrentLoginMonitoring';
import { RefreshToken } from './RefreshToken';

// Context
import { GlobalContext, GlobalDetailContextProps } from './context/globalContext/globalContext';
import { SearchDetailProvider } from './context/searchDetail/searchDetailProvider';
import { UserDetailContext, UserDetailContextProps } from './context/userDetail/userDetailContext';
import { useAuthState } from './providers/authProvider';
import { CUSTOMER_TYPES } from './consts';

// Routes
import { Routes } from './routes/routes';

// Utils
import { temporary } from './helpers/axiosWrapper';
import { isDraxBrand } from './utils/common';

// API
import {
  user,
  getuserbyId,
  getCustomersByBrokerId,
  customerUserProfile,
  accountoverview,
  getCustomersByTpiReferenceId,
  getTpiAccessId,
  getMeteringPointTypeForCustomers,
} from './common/api/homeApi';
import { getServiceMessage } from './common/api/serviceMessageApi';
import { pageServiceName } from './components/utils/text/text';
import { EssAccountsTypes } from './types/account';
import { ISearchDetail } from './context/searchDetail/searchDetail';
import { TPI_CUSTOMERS } from './common/constants';
import { sortAccounts, sortCustomer } from './utils/sortFilters';
import {
  getCustomerList,
  getCustomerListFromAccounts,
  getHHandNHHforCustomers,
  getMeteringPointType,
  meteringType,
} from './helpers/customer';
import { dataLayerPageEvents } from './components/utils/analytics/analytics';
import { TagManager } from './components/utils/analytics/TagManager';

const API = process.env.REACT_APP_API_URL;
const ENV = process.env.REACT_APP_ENV_NAME;
// const CONCURRENT_LOGIN_MONITOR_ENABLED = ENV !== 'dev' && ENV !== 'test';
const CONCURRENT_LOGIN_MONITOR_ENABLED = true;

type MainProps = {
  collapsed: boolean;
  handleToggleSidebar: Function;
  handleCollapsedChange: Function;
};

const defaultProps = {
  collapsed: false,
  handleToggleSidebar: undefined,
  handleCollapsedChange: undefined,
};

export const Main = (props: MainProps): ReactElement => {
  // Context
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const authContext = useAuthState();

  // State
  const [serviceMessage, setServiceMessage] = useState<any>({ type: '', message: '' });
  const [pageName, setPageName] = useState<string>('');
  const [cachedServiceMessage] = useState<Array<Api.IServiceMessageData>>([]);

  const resolution = window.innerWidth;
  const isMobile = resolution <= 1000;

  enum UserTypeEnum {
    notUsed = 1,
    superUser = 2,
    sme = 3, // smeCustomer
    smeBroker = 4,
    corporateBroker = 5,
    corporate = 6, // corporateCustomer
    evoUser = 8,
    evoProductManager = 9,
  }

  const getCorporateBrokerCustomers = async (essCustomerId: string) => {
    const customersByBroker = await getCustomersByBrokerId(API, authContext, essCustomerId);

    if (customersByBroker && customersByBroker.success) {
      const data: Array<Api.ICustomerDetails> = customersByBroker.data;

      // ignore Brokers
      const customersNotBroker = data.filter((item: Api.ICustomerDetails) => {
        return item.parentOrganisationId !== 0;
      });

      const customers = customersNotBroker.map((item: Api.ICustomerDetails) => {
        const customer: Common.IExpandedCustomerOption = {
          display: item.description,
          value: item.accessId,
          hasRenewable: item.renewable,
          hasElectricity: item.hasElectricity,
          hasGas: item.hasGas,
        };
        return customer;
      });
      return customers;
    } else {
      const message = 'API error calling: customersbybrokerid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }

    return [];
  };

  const getCustomerAccountsEDW = async () => {
    const result: Common.IResult<Api.IAccountOverviewData> = await accountoverview(
      process.env.REACT_APP_API_URL,
      authContext,
      ''
    );

    const customers: Array<Api.IEssAccountData> = [];

    if (result && result.success) {
      result.data?.customerIds?.forEach((item: Api.ICustomer) => {
        const cust: Api.IEssAccountData = {
          accountName: item.description,
          essAccountID: item.customerId,
          product: EssAccountsTypes.Electricity,
          renewable: false,
        };
        customers.push(cust);
      });
    }

    const customersOrdered = sortAccounts(customers);

    return customersOrdered;
  };

  const getUserProfile = async (essCustomerId: string) => {
    const userProfile: Common.IResult<Api.IUserProfile> = await customerUserProfile(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId
    );

    if (userProfile?.success) {
      userProfile.data.customerIds = sortCustomer(userProfile.data.customerIds);
      return userProfile.data;
    } else {
      const message = 'API error calling: customer/userprofile';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });

      return {
        accountManager: '',
        email: '',
        phone: '',
      };
    }
  };

  const getTpiCustomersData = async () => {
    const tpiCustomers = await getCustomersByTpiReferenceId(API, authContext);
    if (!tpiCustomers) {
      authContext.logout();
      return;
    } else {
      const _customers: Array<Common.IOption> = [];
      tpiCustomers.map((item: Api.ITpiCustomer) => {
        let customer: Common.IOption = {
          value: item[`customer-account-reference`],
          display: `${item['customer-name']}`,
        };
        _customers.push(customer);
      });

      const userProfile: Api.IUserProfile = {
        id: null,
        accountManager: 'Portal Support',
        email: 'myenergysupply@drax.com',
        phone: '01473 707797',
        description: '',
        numberOfCustomers: 0,
        customerIds: [],
      };

      const claims = authContext.getClaims();

      setUserDetail({
        ...userDetail,
        customers: _customers,
        userProfile,
        emailAddress: claims.email,
        userId: claims.userId,
      });

      return _customers;
    }
  };

  const pageRefreshTpiUser = async (tpiCustomers: Array<Common.IOption> | undefined) => {
    // For page refresh get the selected TPI Users out of localStorage
    const customersListString = localStorage.getItem(TPI_CUSTOMERS);

    if (customersListString) {
      let customers: Array<Common.IOption> = [];
      if (customersListString) customers = JSON.parse(customersListString);

      const selectedCustomerList: Array<string> = customers.map(customer => {
        return customer.value;
      });

      temporary.accessId = '';
      const tpiAccessId = await getTpiAccessId(process.env.REACT_APP_API_URL, authContext, selectedCustomerList);

      if (tpiAccessId) {
        temporary.accessId = tpiAccessId['access-id'];

        let tpiAccess: any = {
          accessId: tpiAccessId['access-id'],
          username: tpiAccessId.username,
          userId: tpiAccessId.userId,
          emailAddress: tpiAccessId.emailAddress,
        };

        // using first customer selected
        const customer = customers[0];

        let customersMeteringPointTypes: Common.ICustomersMeteringPointTypes | null = null;

        // Get Metering Point Type for customers
        const customersList = getCustomerList(customers);
        const customersWithMeteringPointTypeResult = await getMeteringPointTypeForCustomers(
          process.env.REACT_APP_API_URL,
          authContext,
          customersList
        );

        let customersWithMeteringPointType: Array<Api.ICustomerMeteringPointType> = [];
        if (customersWithMeteringPointTypeResult) {
          customersWithMeteringPointType = customersWithMeteringPointTypeResult.data;
          customersMeteringPointTypes = getHHandNHHforCustomers(customersWithMeteringPointType);
        }

        let customerAccounts: Api.IEssAccountData[] = [];
        customers.map((customer: Common.IOption) => {
          let meteringPointType: meteringType;
          if (customersWithMeteringPointType) {
            meteringPointType = getMeteringPointType(customersWithMeteringPointType, customer.value);
          }

          let customerAccount: Api.IEssAccountData = {
            essAccountID: customer.value,
            accountName: customer.display,
            product: 'Electricity',
            renewable: false,
            meteringPointType,
          };

          customerAccounts.push(customerAccount);
        });

        let customerIds: Array<Api.ICustomer> = [];
        customers.map((customer: Common.IOption) => {
          const customerId: Api.ICustomer = {
            customerId: customer.value,
            description: customer.display,
          };
          customerIds.push(customerId);
        });

        const userProfile: Api.IUserProfile = {
          id: null,
          accountManager: 'Portal Support',
          email: 'myenergysupply@drax.com',
          phone: '01473 707797',
          description: '',
          numberOfCustomers: customerAccounts.length,
          customerIds,
        };

        setUserDetail({
          ...userDetail,
          customers: tpiCustomers,
          customerType: CUSTOMER_TYPES.CORPORATE,
          essCustomerId: customer.value,
          hasRenewable: false,
          hasElectricity: true,
          hasGas: false,
          viewAsCustomerSelected: true,
          viewAsCustomerToggle: true,
          selectedCustomers: customers,
          selectedCustomerId: customer.value,
          loggedInDetails: {
            essCustomerId: customer.value,
            hasRenewable: true,
            hasElectricity: false,
            hasGas: false,
          },
          customerAccounts,
          customersMeteringPointTypes,
          forename: tpiAccess.username,
          lastName: tpiAccess.username,
          userId: tpiAccess.userId,
          emailAddress: tpiAccess.emailAddress,
          // isPrimaryContact
          // isAccountSignatory
          userProfile,
        });

        globalContext.setGlobalDetail({
          ...globalContext.globalDetail,
          showBrokerLandingPage: false,
        });

        const claims = authContext.getClaims();
        const userId = claims.tpiReferenceId;
        dataLayerPageEvents(userId, 'tpiUser');
        TagManager.pushData({ event: 'Loggedin' });
      }
    } else {
      globalContext.setGlobalDetail({
        ...globalContext.globalDetail,
        showBrokerLandingPage: true,
      });

      const claims = authContext.getClaims();
      const userId = claims.tpiReferenceId;
      dataLayerPageEvents(userId, 'tpiUser');
      TagManager.pushData({ event: 'Loggedin' });
    }
  };

  const getdata = async () => {
    const users = await user(API, authContext);
    if (!users || !users?.success) {
      let tpiCustomers = await getTpiCustomersData();

      pageRefreshTpiUser(tpiCustomers);

      return;
    }

    const loggedInUser = await getuserbyId(API, authContext);

    if (loggedInUser?.success) {
      const data = users.data;

      const user = data.user;
      const userAccesses = data.userAccesses;

      let customerType = '';
      if (userAccesses) {
        const userTypeId = userAccesses[0].userTypeId;

        const userTypeName: string = UserTypeEnum[userTypeId];
        customerType = userTypeName as Common.customerType;
      } else {
        // TODO - maybe throw Error?
        // Not sure if this is just a data issue with current Corp Broker login
      }

      temporary.billingpartyid = user.billingPartyId; // TODO - TEMPORARY

      let customers: Array<Common.IExpandedCustomerOption> = [];
      let loggedInEssCustomerId: string = '';
      let loggedInRenewable, loggedInHasElectricity, loggedInHasGas;
      let customerAccounts: Api.IEssAccountData[] = [];
      let customersMeteringPointTypes: Common.ICustomersMeteringPointTypes | null = null;

      if (loggedInUser && loggedInUser.success) {
        if (isDraxBrand) {
          customerAccounts = await getCustomerAccountsEDW();
        }

        // Get Metering Point Type for customers
        const customersList = getCustomerListFromAccounts(customerAccounts);
        const customersWithMeteringPointTypeResult = await getMeteringPointTypeForCustomers(
          process.env.REACT_APP_API_URL,
          authContext,
          customersList
        );

        let customersWithMeteringPointType: Array<Api.ICustomerMeteringPointType> = [];
        if (customersWithMeteringPointTypeResult) {
          customersWithMeteringPointType = customersWithMeteringPointTypeResult.data;

          customersMeteringPointTypes = getHHandNHHforCustomers(customersWithMeteringPointType);

          // Get Metering Point Type for customers
          customerAccounts.map((customerAccount: Api.IEssAccountData) => {
            let meteringPointType: meteringType;
            if (customersWithMeteringPointType) {
              meteringPointType = getMeteringPointType(customersWithMeteringPointType, customerAccount.essAccountID);
            }

            if (meteringPointType) {
              customerAccount.meteringPointType = meteringPointType;
            } else {
              customerAccount.meteringPointType = undefined;
            }
          });
        }

        if (customerAccounts.length > 0) {
          loggedInEssCustomerId = customerAccounts[0].essAccountID;
        } else {
          loggedInEssCustomerId = loggedInUser.data.essCustomerID;
        }

        temporary.originalCustomerId = loggedInEssCustomerId;
        loggedInRenewable = loggedInUser.data.renewable;
        loggedInHasElectricity = loggedInUser.data.hasElectricity;
        loggedInHasGas = loggedInUser.data.hasGas;

        if (customerType === CUSTOMER_TYPES.CORPORATE_BROKER) {
          customers = await getCorporateBrokerCustomers(loggedInEssCustomerId);
        }
      } else {
        const message = 'API error calling: getuserbyId';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      }

      let userProfileData = { accountManager: '', email: '', phone: '' };
      if (isDraxBrand) {
        userProfileData = await getUserProfile(loggedInEssCustomerId);
      }

      setUserDetail({
        ...userDetail,
        customerType,
        customers,
        customerAccounts,
        customersMeteringPointTypes,
        loggedInDetails: {
          essCustomerId: loggedInEssCustomerId,
          hasRenewable: loggedInRenewable,
          hasElectricity: loggedInHasElectricity,
          hasGas: loggedInHasGas,
        },
        essCustomerId: loggedInEssCustomerId,
        hasRenewable: loggedInRenewable,
        hasElectricity: loggedInHasElectricity,
        hasGas: loggedInHasGas,
        forename: user.forename,
        lastName: user.surname,
        userId: user.userId,
        emailAddress: user.emailAddress,
        isAccountSignatory: user.accountSignatoryStatus === 2,
        userProfile: userProfileData,
      });

      dataLayerPageEvents(user.billingPartyId, customerType);
      TagManager.pushData({ event: 'Loggedin' });
    } else {
      const message = 'API error calling: getuserbyId';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });

      // force logout - redirects to login page rather than showing a blank page.
      authContext.logout();
    }
  };

  useEffect(() => {
    // log for 35984
    console.log('authContext');

    if (authContext.isAuthenticated()) {
      getdata();
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext]);

  useEffect(() => {
    if (isDraxBrand) {
      if (userDetail && userDetail.customerType && userDetail.customerType.length > 0) {
        let showBrokerLandingPage = false;
        if (userDetail.customerType === CUSTOMER_TYPES.CORPORATE_BROKER) {
          showBrokerLandingPage = true;
        }
        globalContext.setGlobalDetail({
          ...globalContext.globalDetail,
          showBrokerLandingPage,
        });
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.customerType]);

  useEffect(() => {
    const index = cachedServiceMessage.findIndex(s => s.serviceMessageName === pageName);
    if (index >= 0) {
      const serviceMessage = cachedServiceMessage[index];
      setServiceMessage({ type: serviceMessage.serviceMessageSeverity, message: serviceMessage.serviceMessageText });
      return;
    }

    if (!pageName) {
      return;
    }

    getServiceMessage(API, authContext, pageName)
      .then((response: Common.IResult<Api.IServiceMessageData>) => {
        if (response && response.success) {
          let serviceMessage: Api.IServiceMessageData = {
            serviceMessageId: -1,
            serviceMessageText: '',
            serviceMessageName: pageName,
            serviceMessageSeverity: '',
          };

          if (response.data) {
            serviceMessage.serviceMessageId = response.data.serviceMessageId;
            serviceMessage.serviceMessageText = response.data.serviceMessageText;
            serviceMessage.serviceMessageSeverity = response.data.serviceMessageSeverity;
          }
          cachedServiceMessage.push(serviceMessage);
          setServiceMessage({
            type: serviceMessage.serviceMessageSeverity,
            message: serviceMessage.serviceMessageText,
          });
        } else {
          const message = `API error calling: servicemessages/${pageName}`;

          console.error(message);
          toast.error(message, { position: 'bottom-right' });
        }
      })
      .catch(error => {
        const message = `Failed to get service message`;

        console.error(message, error);
        toast.error(`${message}. ${error?.message || ''}`);
      });
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);

  // TODO:  Might want to remove this and revisit this problem later
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Check for tpi landing page
    const isTpiCustomer = globalContext.globalDetail.showBrokerLandingPage;
    if (isTpiCustomer) {
      const serviceName = 'tpilandingpage';
      setPageName(serviceName);
      return;
    }

    const serviceName = pageServiceName();
    setPageName(serviceName);
  });

  const initialSearchDetail: ISearchDetail = {
    searchText: '',
    type: '',
    routingTo: '',
    invoice: '',
    mprn: '',
    mpan: '',
    accountId: '',
    statement: '',
    siteId: '',
    energyType: '',
  };

  return (
    <>
      <RefreshToken></RefreshToken>
      <main
        className={`main-wrapper${pageName === 'HomePage' && !isMobile ? '-homepage' : ''} ${
          globalContext.globalDetail.mobileFilterActive ? 'mobile-filter-active' : ''
        }`}
      >
        <SearchDetailProvider value={initialSearchDetail}>
          {pageName === 'HomePage' && !isMobile && (
            <Header
              collapsed={props.collapsed}
              handleToggleSidebar={props.handleToggleSidebar}
              handleCollapsedChange={props.handleCollapsedChange}
              hasRenewableAccount={userDetail.hasRenewable}
              serviceMessageText={serviceMessage.message}
              serviceMessageType={serviceMessage.type}
            />
          )}
          {(pageName !== 'HomePage' || isMobile) && (
            <Header
              collapsed={props.collapsed}
              handleToggleSidebar={props.handleToggleSidebar}
              handleCollapsedChange={props.handleCollapsedChange}
              hasRenewableAccount={userDetail.hasRenewable}
            />
          )}
          <div className={`mainview ${pageName === 'HomePage' && !isMobile && 'mainview-homepage'}`}>
            {(pageName !== 'HomePage' || isMobile) && (
              <ServiceUpdate type={serviceMessage.type} message={serviceMessage.message} />
            )}
            {Routes()}
            {CONCURRENT_LOGIN_MONITOR_ENABLED && <ConcurrentLoginMonitoring />}
          </div>
        </SearchDetailProvider>
      </main>
    </>
  );
};

Main.defaultProps = defaultProps;
