import React, { ReactNode, useState } from 'react';
import { ISearchDetail } from './searchDetail';
import { SearchDetailContext } from './searchDetailContext';

export interface ISearchDetailProviderProps {
  value: ISearchDetail;
  children?: ReactNode;
}

export const SearchDetailProvider = (props: ISearchDetailProviderProps) => {
  const [searchDetail, setSearchDetail] = useState<ISearchDetail>(props.value);

  return (
    <SearchDetailContext.Provider value={{ searchDetail, setSearchDetail }}>
      {props.children}
    </SearchDetailContext.Provider>
  );
};
