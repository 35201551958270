// Styles
import { BrokerLandingSelectCustomersWrapper } from './BrokerLandingSelectCustomers.styles';

// Utils
import { ReactSelectStyled } from '../../../common/components/FormSelect/FormSelect.styles';

type BrokerLandingSelectCustomersProps = {
  customers: Array<Common.IExpandedCustomerOption>;
  selectedCustomers: Array<Common.IExpandedCustomerOption>;
  handleCustomerClick: Function;
};

const defaultProps = {
  customers: [],
  selectedCustomers: [],
  handleCustomerClick: undefined,
};

export const BrokerLandingSelectCustomers = (props: BrokerLandingSelectCustomersProps) => {

  const handleCustomerClick = (event: any) => {
     props.handleCustomerClick(event);
  };
  
  return (
    <>
    <BrokerLandingSelectCustomersWrapper {...props}>
      <div className='brokerLandingPageSelectCustomers'>
        <div className='d-flex align-items-center'>
        <h3 className='primary heading'>Select customers</h3>
        </div>
        <div>
          <p className='mb-'>Select up to 10 customers in a session to access site details, invoice and usage data.</p>
          <p className='mb-'>You can return to this selection screen to choose other customers when required.</p>
        </div>
        <div className='selectCustomerDropDown mb-2'>
          <ReactSelectStyled
            closeMenuOnSelect={props.customers.length <= 1}
            options={props.customers}
            classNamePrefix={'DashboardSelect'}
            value={null}
            getOptionLabel={({ display, value }: any) => `${display} - ${value}`}
            onChange={handleCustomerClick}
            placeholder='Enter customer name'
            isDisabled={props.selectedCustomers.length >= 10}
          />
        </div>
      </div>

      <div className='brokerLandingPageSelectCustomersMobile'>
        <div className='d-flex align-items-center'>
        <h3 className='primary heading'>Select customers</h3>
        </div>
        <div>
          <p className='mb-'>Select up to 10 customers in a session to access site details, invoice and usage data.</p>
          <p className='mb-'>You can return to this selection screen to choose other customers when required.</p>
        </div>
        <div className='selectCustomerDropDown mb-2'>
          <ReactSelectStyled
            closeMenuOnSelect={props.customers.length <= 1}
            options={props.customers}
            classNamePrefix={'DashboardSelect'}
            value={null}
            getOptionLabel={({ display, value }: any) => `${display} - ${value}`}
            onChange={handleCustomerClick}
            placeholder='Enter customer name'
            isDisabled={props.selectedCustomers.length >= 10}
          />
        </div>
      </div>
      
    </BrokerLandingSelectCustomersWrapper>
  </>
  );
};

BrokerLandingSelectCustomers.defaultProps = defaultProps;
