import Modal from 'react-bootstrap/Modal';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ButtonPrimary, FormInput, ModalClose } from '../../../common/components';
import { ActionRowWrapper, AddUserWrapper } from '../AddUserModal.styles';
import { EMAIL_PATTERN } from '../../../utils/common';

type AddTpiUserModalProps = {
  onHide: Function;
  handleConfirm: Function;
  show: boolean;
  title: string;
  error: string;
};

const defaultProps = {
  onHide: undefined,
  handleConfirm: undefined,
  show: false,
  title: 'Error',
  error: '',
};

type Inputs = {
  firstname: string;
  surname: string;
  email: string;
};

const emailError = 'Enter valid email address';

const AddTpiUserModal = (props: AddTpiUserModalProps) => {
  const methods = useForm<Inputs>({});

  const submitHandler: SubmitHandler<Inputs> = data => {
    props.handleConfirm(data);
  };

  return (
    <>
      <Modal.Header>
        <h4>Add User</h4>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>

      <Modal.Body>
        <FormProvider {...methods}>
          <form>
            <AddUserWrapper>
              <div className='rowWrapper'>
                <div className='row'>
                  <div className='col-md-6'>
                    <label>First name*</label>
                    <FormInput name='firstname' placeholder='' params={{ required: true }} />
                  </div>

                  <div className='col-md-6'>
                    <label>Last name*</label>
                    <FormInput name='surname' placeholder='' params={{ required: true }} />
                  </div>
                </div>
              </div>
              <div className='rowWrapper'>
                <div className='row'>
                  <div className='col-md-12'>
                    <label>Email*</label>
                    <FormInput
                      name='email'
                      placeholder=''
                      error={props.error}
                      params={{
                        required: true,
                        pattern: { value: EMAIL_PATTERN, message: emailError },
                      }}
                    />
                    <div className='addUserError'>
                      {props.error && props.error === 'DuplicateUserName' && 'Email address is already registered'}
                      {props.error && props.error === 'DuplicateEmail' && 'Email address is already registered'}
                    </div>
                  </div>
                </div>
              </div>
            </AddUserWrapper>

            <ActionRowWrapper>
              <ButtonPrimary
                title='Add User'
                onClick={event => {
                  event.preventDefault();
                  methods.handleSubmit(submitHandler)();
                }}
                className='m-0 submit-btn'
              ></ButtonPrimary>
            </ActionRowWrapper>
          </form>
        </FormProvider>
      </Modal.Body>
    </>
  );
};

AddTpiUserModal.defaultProps = defaultProps;

export default AddTpiUserModal;
