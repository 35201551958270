import { StylesConfig } from 'react-select';

const fontStyles = {
  color: '#283244',
  fontSize: '16px',
};

export const tableFilterStyles: StylesConfig<any, false> = {
  control: (base: any, { isFocused }) => {
    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        borderColor: '216EF2',
        boxShadow: 'none',
      };
    }

    return {
      ...base,
      cursor: 'pointer',
      borderRadius: '16px',
      padding: '0 15px',
      border: '1px solid #BBBBBB',
      background: '#F5F5F5',
      minHeight: '30px',
      ...additionalStyles,
    };
  },
  valueContainer: (base: any) => ({ ...base, padding: '0' }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#283244',
    padding: 0,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: '#283244',
    padding: 0,
  }),
  singleValue: (base: any) => ({
    ...base,
    ...fontStyles,
    paddingRight: '10px',
  }),
  input: (base: any) => ({
    ...base,
    padding: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    paddingRight: '10px',
    ...fontStyles,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: '8px',
    zIndex: 2,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    borderRadius: '8px',
  }),
  option(base: any, props) {
    const { isFocused } = props;
    let additionalStyles = {};

    if (isFocused) {
      additionalStyles = {
        backgroundColor: '#216EF2',
        color: '#fff',
      };
    }

    return {
      ...base,
      ...fontStyles,
      'cursor': 'pointer',
      'backgroundColor': '#ffffff',

      '&:hover': {
        backgroundColor: '#216EF2',
      },
      ...additionalStyles,
    };
  },
};
