import { ReactNode, useState } from 'react';

import { DirectDebitProcessContext } from './directDebitProcessContext';

export interface IDirectDebitProcessContext {
  selectedAccounts: any[];
  setSelectedAccounts: Function;
  accountNumber: string | null;
  setAccountNumber: Function;
  sortCode: string | null;
  setSortCode: Function;
}

export interface IDirectDebitProcessProviderProps {
  children?: ReactNode;
}

export const DirectDebitProcessProvider = (props: IDirectDebitProcessProviderProps) => {
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);
  const [accountNumber, setAccountNumber] = useState<string | null>(null);
  const [sortCode, setSortCode] = useState<string | null>(null);

  return (
    <DirectDebitProcessContext.Provider
      value={{
        selectedAccounts,
        setSelectedAccounts,
        accountNumber,
        setAccountNumber,
        sortCode,
        setSortCode,
      }}
    >
      {props.children}
    </DirectDebitProcessContext.Provider>
  );
};
