import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, ButtonTerinary, ModalClose } from '../../../common/components';

type DeleteModalProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
  multiple: boolean;
  handleConfirm: Function;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Delete message',
  messagevalues: {},
  multiple: false,
  handleConfirm: undefined,
};

const DeleteModal = (props: DeleteModalProps) => {
  const ids = props.messagevalues.ids;
  const multiple = props.multiple;

  const handleConfirm = (event: any) => {
    props.handleConfirm(event);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        {(() => {
          if (ids) {
            if (ids.length === 0) {
              return <p>Please select a message to delete</p>;
            } else if (multiple && ids.length > 1) {
              return <p>Are you sure you want to delete the {ids.length} selected messages?</p>;
            } else {
              return <p>Are you sure you want to delete the selected message?</p>;
            }
          }
        })()}
        <input type='hidden' className='message-ids' value={ids?.toString()} />
      </Modal.Body>
      <Modal.Footer>
        {ids && ids.length === 0 ? (
          <ButtonTerinary
            route='/'
            title='Close'
            onClick={event => {
              event.preventDefault();
              props.onHide();
            }}
            className='m-0'
          ></ButtonTerinary>
        ) : (
          <>
            <ButtonTerinary
              route='/'
              title='Cancel'
              onClick={event => {
                event.preventDefault();
                props.onHide();
              }}
            ></ButtonTerinary>
            <ButtonPrimary
              route='/'
              title='Confirm'
              onClick={event => {
                event.preventDefault();
                handleConfirm(event);
                props.onHide();
              }}
            ></ButtonPrimary>
          </>
        )}
      </Modal.Footer>
    </>
  );
};

DeleteModal.defaultProps = defaultProps;

export default DeleteModal;
