import { PHONE_PATTERN } from '../../../utils/common';
import { FormInput, StyledTitle } from '../../../common/components';
import FormElement from '../../../common/components/FormElement';
import { useState } from 'react';

type NewContactOldAddressDetailsProps = {
  siteIndex: number;
  handleUpdateLandlordPreference: Function;
  handleUpdateResidentialPreference: Function;
  handleUpdateMovingPreference: Function;
};

export const NewContactOldAddressDetails = (props: NewContactOldAddressDetailsProps) => {
  const [landlordPreference, setLandlordPreference] = useState('');
  const [residentialPreference, setResidentialPreference] = useState('');
  const [movingPreference, setMovingPreference] = useState('');

  const handleLandlordPreference = (preference: string) => {
    setLandlordPreference(preference);
    if (preference === 'Yes') {
      props.handleUpdateLandlordPreference(true);
    } else {
      props.handleUpdateLandlordPreference(false);
    }
  };

  const handleResidentialPreference = (preference: string) => {
    let tempPreference = '';
    setResidentialPreference(preference);

    if (preference === 'Yes') {
      tempPreference = 'Residential';
    } else if (preference === 'No') {
      tempPreference = 'NonResidential';
    } else {
      tempPreference = 'Unknown';
    }

    props.handleUpdateResidentialPreference(tempPreference);
  };

  const handleMovingPreference = (preference: string) => {
    setMovingPreference(preference);
    if (preference === 'Yes') {
      props.handleUpdateMovingPreference(true);
    } else {
      props.handleUpdateMovingPreference(false);
    }
  };

  return (
    <>
      <div className='formAddress'>
        <StyledTitle className='leftText mb-3' fontSize='20px'>
          Who can we contact regarding the old premises?
        </StyledTitle>

        <div className='d-flex text-center pt-4'>
          <span className='text-center pt-3'>Is there a landlord of the premises?*</span>
          <div className='ml-auto'>
            <FormElement.Radio
              id='page-new-landlord'
              className={''}
              label={''}
              name='newlandlord'
              elements={['Yes', 'No']}
              value={landlordPreference}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleLandlordPreference(e.target.value);
              }}
              required={true}
            />
          </div>
        </div>

        {landlordPreference === 'Yes' && (
          <div>
            <div className='formRowWrapper'>
              <FormInput
                name={`sites.${props.siteIndex}.landlordFullName`}
                label='Landlord name'
                params={{ required: landlordPreference === 'Yes' }}
              />
            </div>

            <div className='formRowWrapper'>
              <FormInput
                name={`sites.${props.siteIndex}.landlordPhoneNumber`}
                label='Contact number'
                params={{
                  required: landlordPreference === 'Yes',
                  pattern: {
                    value: PHONE_PATTERN,
                    message: 'Please provide a valid phone number without spaces',
                  },
                }}
              />
            </div>
          </div>
        )}

        <div className={`d-flex ${landlordPreference === 'Yes' ? 'pt-4' : ''}`}>
          <span className='text-center pt-3'>Will the premises be residential?*</span>
          <div className='ml-auto'>
            <FormElement.Radio
              id='page-residential'
              className={''}
              label={''}
              name='residential'
              elements={['Yes', 'No', "Don't know"]}
              value={residentialPreference}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleResidentialPreference(e.target.value);
              }}
              required={true}
            />
          </div>
        </div>

        <div className='d-flex'>
          <span className='text-center pt-3'>Do you know who's moving into the premises?*</span>
          <div className='ml-auto'>
            <FormElement.Radio
              id='page-who-landlord'
              className={''}
              label={''}
              name='whoLandlord'
              elements={['Yes', 'No']}
              value={movingPreference}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleMovingPreference(e.target.value);
              }}
              required={true}
            />
          </div>
        </div>

        {movingPreference === 'Yes' && (
          <div>
            <div className='formRowWrapper'>
              <FormInput
                name={`sites.${props.siteIndex}.businessName`}
                label='Full business name'
                params={{ required: movingPreference === 'Yes' }}
              />
            </div>

            <div className='formRowWrapper'>
              <FormInput
                name={`sites.${props.siteIndex}.fullName`}
                label='Contact name'
                params={{ required: movingPreference === 'Yes' }}
              />
            </div>

            <div className='formRowWrapper'>
              <FormInput
                name={`sites.${props.siteIndex}.phoneNumber`}
                label='Contact number'
                params={{
                  required: movingPreference === 'Yes',
                  pattern: {
                    value: PHONE_PATTERN,
                    message: 'Please provide a valid phone number without spaces',
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
