import Modal from 'react-bootstrap/Modal';
import { ProgressBarModalContainer } from './ProgressBarModal.styles';
import { useEffect } from 'react';

type ProgressBarModalProps = {
  show: boolean;
  onClose: () => void;
  title?: string;
  message?: string[];
  percentageComplete: number;
};

const defaultProps = {
  show: false,
  title: 'Files',
  message: ['Uploading...'],
  percentageComplete: 0,
};

export const ProgressBarModal = (props: ProgressBarModalProps) => {
  
  useEffect(() => {
  
    if (props.percentageComplete === 100) {
      props.onClose();
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.percentageComplete]);

  return (
    <Modal
      animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
      className={'custom-modal'}
      show={props.show}
      centered={true}
    >
      <div>
        <h5>{props.title}</h5>
      </div>
      <Modal.Body>
        <ProgressBarModalContainer>
          {props.message?.map((message: string, index: number) => {
            return <p key={index}>{message}</p>;
          })}
          <progress className="progressBar" value={props.percentageComplete} max="100"></progress>
        </ProgressBarModalContainer>
      </Modal.Body>
    </Modal>
  );
};

ProgressBarModal.defaultProps = defaultProps;

export default ProgressBarModal;
