import { HubConnection, HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';
import { useEffect, useState, useContext } from 'react';

import { useAuthState } from '../../providers/authProvider';
import { SignedOut } from '../timeout/modal/signedOut/SignedOut';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext'; //'../../../../context/userDetail/userDetailContext';

export const ConcurrentLoginMonitoring = () => {
  const authContext = useAuthState();
  const [modalVisible, setModalVisible] = useState(false);
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [message, setMessage] = useState<undefined | string>(undefined);
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasSubscribedToMessageEvents, setHasSubscribedToMessageEvents] = useState(false);
  const forceLogout = () => authContext.logout();

  const automaticLogout = () => {
    setTimeout(function () {
      forceLogout();
    }, 1000 * 60); // Automatic logout after 1 min
  };

  useEffect(() => {
    console.log('ConcurrentLoginMonitoring');

    const options = {
      logger: LogLevel.Trace,
      logMessageContent: true,
      // TODO: remove logs after testing
      transport: HttpTransportType.LongPolling,
      accessTokenFactory: () => authContext.getToken(),
    };

    const connect = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/signalr/notifications`, options)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('ForceSignout', () => {
            setModalVisible(true);
            automaticLogout();
          });
          connection.on('ForceSignoutLockedUser', () => {
            setMessage('Due to security reasons, your account has been deactivated.');
            setModalVisible(true);
            automaticLogout();
          });
          connection.on('UnreadMessagesNotification', () => {
            console.log('UnreadMessagesNotification Received');
            setHasUnreadMessages(true);
          });
          connection.on('NewBulkMessageNotification', () => {
            console.log('NewBulkMessageNotification Received');
            setHasUnreadMessages(true);
          });
          connection.on('GroupBulkMessageNotification', () => {
            console.log('GroupBulkMessageNotification Received');
            setHasUnreadMessages(true);
          });
          connection.on('SpecificBulkMessageNotification', () => {
            console.log('SpecificBulkMessageNotification Received');
            setHasUnreadMessages(true);
          });
          connection.on('ForceLogoutAllUsers', () => {
            console.log('ForceLogoutAllUsers Received');
            setMessage(`We're currently doing some maintenance work on the portal. Sorry for any inconvenience.`);
            setModalVisible(true);
            automaticLogout();
          });
        })
        .catch(error => console.log(error));
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    if (hasUnreadMessages) {
      setUserDetail({
        ...userDetail,
        hasUnreadMessages: true,
      });
    }
    setHasUnreadMessages(false);
  }, [hasUnreadMessages]);

  if (!hasSubscribedToMessageEvents && connection && connection.connectionId) {
    const claims = authContext.getClaims();

    if (claims.tpiReferenceId === undefined) {
      // Customer user
      if (userDetail.customerAccounts.length > 0) {
        connection.invoke('GroupBulkMessagesSubscription', 'Customers');

        let accounts = userDetail.customerAccounts.map(x => x.essAccountID);
        accounts.forEach(account => {
          connection.invoke('SpecificBulkMessagesSubscription', account);
        });
        setHasSubscribedToMessageEvents(true);
      }
    } else {
      // TPI user
      connection.invoke('GroupBulkMessagesSubscription', 'TPIUsers');

      let tpiReference = claims.tpiReferenceId;
      connection.invoke('SpecificBulkMessagesSubscription', tpiReference);
      setHasSubscribedToMessageEvents(true);
    }
  }

  return <SignedOut onHide={forceLogout} show={modalVisible} message={message} />;
};
