import styled from 'styled-components';

import * as mixins from '../../../../common/styles/mixins';

export const InsightHomeWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: row;
  text-align: center;

  ${mixins.widget}
`;
