import styled from 'styled-components';
import * as colors from '../../common/styles/colors';

export const AdminWrapper = styled.div`
  background-color: ${colors.white};
  height: 90vh;

  img {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
  }
`;
