// Modules
import React, { useContext, useEffect, useState } from 'react';
import IcomoonReact from 'icomoon-react';
import { toast } from 'react-toastify';
import { default as useStateRef } from 'react-usestateref';

// Images
import iconSet from '../../../assets/selection.json';

// Components
import { MeterReadingDataBlockDrax } from './MeterReadingData/MeterReadingDataBlockDrax';
import ReactSelect from 'react-select';

// Styles
import { SubmitMeterReadingWrapper, SubmitMeterReadingText } from './SubmitMeterReading.styles';
import { selectStyles } from './SelectStyles';

// Context
import { HomeContext, HomeContextProps } from '../context/homeContext';
import { useAuthState } from '../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

// Api
import { fetchEssAccountsByCustomerId } from '../../../common/api/accountApi';
import { getMeters, getMetersDetails, MetersFilterObject } from '../../../common/api/meterReadsApi';

// Types
import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';
import { Loading } from '../../../common/components';
import { Link } from 'react-router-dom';
import { hydroBlue } from '../../../common/styles/colors';
import { isDraxBrand } from '../../../utils/common';
import { groupRegisters } from '../../../utils/groupRegisters';
import { SuccessSubmitModal } from './SuccessSubmitModal/SuccessSubmitModal';
import { FailureSubmitModal } from './FailureSubmitModal/FailureSubmitModal';

type SubmitMeterReadingDraxProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const defaultProps = {
  onClick: undefined,
};

const WIDGET_KEY = 'SubmitMeterReading';

interface ISelectOption {
  value: string;
  label: string;
}

interface IMeter extends Api.IMeter, ISelectOption {}

export const SubmitMeterReadingDrax = (props: SubmitMeterReadingDraxProps) => {
  // Context
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const { userDetail, isSMECustomer, isCorpCustomer } = useContext<UserDetailContextProps>(UserDetailContext);
  const authContext = useAuthState();

  // State
  const [selectedAccount, setSelectedAccount, accRef] = useStateRef<ISelectOption | null>(null);
  const [essAccounts, setEssAccounts] = useState<ISelectOption[]>([]);

  const [selectedSite, setSelectedSite] = useState<IMeter | null>(null);
  const [sites, setSites] = useState<IMeter[]>([]);

  const [selectedMpan, setSelectedMpan] = useState<Api.IMeter | null>(null);
  const [mpans, setMpans] = useState<Array<Api.IMeter>>([]);

  const [selectedMeter, setSelectedMeter] = useState<Api.IMeter | null>(null);
  const [meters, setMeters] = useState<Array<Api.IMeter>>([]);
  const [meterRegisters, setMeterRegisters] = useState<Array<Api.IMeterRead>>([]);

  const [isSpinnerVisible, setSpinnerVisibility] = useState<boolean>(false);
  const isMobileDropdownOpened = homeState.active && homeState.selectedCard === WIDGET_KEY;
  const showMeterReadData = isSMECustomer() || isCorpCustomer();
  const isSMECustomerWithNoRenewables = isSMECustomer() && !userDetail.hasRenewable;

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showFailureModal, setShowFailureModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const formatAccounts = (accounts: Array<Api.IEssAccountData>) => {
    const filteredAccounts = accounts.filter((el: Api.IEssAccountData) => {
      if (el.meteringPointType === 'AllNHH' || el.meteringPointType === 'Mixed') return el;
    });

    const accountsOptions = filteredAccounts.map((el: Api.IEssAccountData) => {
      const data = {
        label: `${el.essAccountID} - ${el.accountName}`,
        value: el.essAccountID,
      };
      return data;
    });

    return accountsOptions;
  };

  const formatSites = (sites: Array<Api.IMeter>) => {
    const filteredData: Array<IMeter> = [];

    sites.map((item: Api.IMeter) => {
      const findEl = filteredData.find(f => f['site-reference'] === item['site-reference']);

      if (!findEl) {
        filteredData.push({
          ...item,
          label: `${item['site-reference']} ${item['site-address']}`,
          value: item['site-reference'],
        });
      }
      return filteredData;
    });

    return filteredData;
  };

  const formatMpans = (mpans: Array<Api.IMeter>): Array<IMeter> => {
    const filteredData: Array<IMeter> = [];

    mpans.map((item: Api.IMeter) => {
      const findEl = filteredData.find(f => f['mpan-core'] === item['mpan-core']);

      if (!findEl) {
        filteredData.push({
          ...item,
          label: `${item['mpan-core']}`,
          value: `${item['mpan-core']}`,
        });
      }
      return filteredData;
    });
    return filteredData;
  };

  const formatMeters = (meters: Array<Api.IMeter>): Array<IMeter> =>
    meters.map((item: Api.IMeter) => ({
      ...item,
      label: item['meter-serial-number'],
      value: item['meter-serial-number'],
    })) || [];

  const formatRegisters = (metersRegisters: Array<Api.IMeterRead>) => {
    const groupedRegisters = groupRegisters(metersRegisters);
    const registers = Object.keys(groupedRegisters).map(el => groupedRegisters[el][0]);
    return registers;
  };

  const updateAccounts = () => {
    let accounts: Array<Api.IEssAccountData> = [];

    if (isDraxBrand) {
      accounts = userDetail.customerAccounts;
    } else {
      fetchEssAccountsByCustomerId(process.env.REACT_APP_API_URL, authContext, userDetail.essCustomerId).then(
        (invoiceAccount: Common.IResult<Array<Api.IEssAccountData>>) => {
          if (invoiceAccount && invoiceAccount.success) {
            accounts = invoiceAccount.data;
          }
        },
        error => {
          const message = 'API error calling: getessaccountidbyesscustomerid';
          toast.error(message, { position: 'bottom-right' });
        }
      );
    }
    const formattedAccounts = formatAccounts(accounts);
    setEssAccounts(formattedAccounts);
    if (formattedAccounts.length === 1) handleAccountChange(formattedAccounts[0]);
  };

  useEffect(() => {
    setSelectedAccount(null);
    setSelectedSite(null);
    setSelectedMpan(null);
    setSelectedMeter(null);

    if (userDetail.customerAccounts && userDetail.customerAccounts.length > 0) {
      updateAccounts();
    }
  }, [userDetail.customerAccounts]);

  useEffect(() => {
    updateAccounts();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMeterWidgetExpandedValue = (value: boolean) => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      meterWidgetExpanded: value,
    });
  };

  const loadMetersData = async (essAccountId?: string, siteReference?: string, mpanCore?: string) => {
    try {
      if (!essAccountId) return;
      const filters: MetersFilterObject = {};

      setSpinnerVisibility(true);

      if (siteReference) {
        filters['site-reference'] = siteReference;
      }

      if (mpanCore) {
        filters['mpan-core'] = mpanCore;
      }

      const meters = await getMeters(
        process.env.REACT_APP_API_URL,
        authContext,
        essAccountId,
        filters,
        undefined,
        undefined,
        'NHH',
        'Active'
      );

      return meters;
    } catch (error) {
      const message = 'Unexpected error occurred during meters data loading';
      toast.error(message);
    } finally {
      setSpinnerVisibility(false);
    }
  };

  const loadRegistersData = async (meter: IMeter) => {
    try {
      setSpinnerVisibility(true);

      const metersRegisters = await getMetersDetails(
        process.env.REACT_APP_API_URL,
        authContext,
        meter['mpan-core'],
        meter['meter-serial-number'],
        false,
        {},
        1
      );
      const registers = formatRegisters(metersRegisters);
      setMeterRegisters(registers);
    } catch (error) {
      const message = 'Unexpected error occurred during meters data loading';
      toast.error(message);
    } finally {
      setSpinnerVisibility(false);
    }
  };

  const handleAccountChange = async (account: ISelectOption | null) => {
    setSelectedAccount(account);
    setSites([]);
    handleSiteChange(null);

    if (account) {
      const data = await loadMetersData(account.value);
      if (data) setSitesData(data);
    }
  };

  const handleSiteChange = async (site: IMeter | null) => {
    setSelectedSite(site);

    if (!site) {
      setMpans([]);
      setMeters([]);
      setMeterRegisters([]);
      handleMpanChange(null);
      handleMeterChange(null);
      return;
    }

    const data = await loadMetersData(accRef.current?.value, site['site-reference']);
    if (data) setMpansData(data);
  };

  const handleMpanChange = async (mpan: IMeter | null) => {
    setSelectedMpan(mpan);

    if (!mpan) {
      setMeters([]);
      handleMeterChange(null);
      return;
    }

    const data = await loadMetersData(accRef.current?.value, mpan['site-reference'], mpan.value);
    if (data) {
      setMetersData(data);
      setMeterWidgetExpandedValue(true);
    }
  };

  const handleMeterChange = (meter: IMeter | null) => {
    setSelectedMeter(meter);

    if (meter) {
      loadRegistersData(meter);
    }
    setMeterWidgetExpandedValue(true);
  };

  const toggleActive = () => {
    setHomeState({
      selectedCard: WIDGET_KEY,
      active: homeState.selectedCard !== WIDGET_KEY || !homeState.active,
    });
  };

  const setSitesData = (meters: Array<Api.IMeter>) => {
    const siteCollection = formatSites(meters);
    setSites(siteCollection);

    if (siteCollection.length === 1) handleSiteChange(siteCollection[0]);
  };

  const setMetersData = (meters: Array<Api.IMeter>) => {
    const metersCollection = formatMeters(meters);
    setMeters(metersCollection);

    if (metersCollection.length === 1) handleMeterChange(metersCollection[0]);
  };

  const setMpansData = (mpans: Array<Api.IMeter>) => {
    const mpansCollection = formatMpans(mpans);
    setMpans(mpansCollection);

    if (mpansCollection.length === 1) handleMpanChange(mpansCollection[0]);
  };

  const checkIsClerable = (length: number) => (length > 1 ? true : false);

  return (
    <SubmitMeterReadingWrapper {...props}>
      <div className='submitMeterReading'>
        <div className={`d-flex flex-lg-row flex-wrap flex-lg-nowrap`}>
          <div
            className={`meterReadFields p-0 px-md-3 ${
              showMeterReadData && (isSMECustomerWithNoRenewables || globalContext.globalDetail.meterWidgetExpanded)
                ? `col-12 col-lg-6`
                : `col-12`
            }`}
          >
            <div className={`d-flex flex-column mt-3 mt-3'}`}>
              <div className='dropdown'>
                <ReactSelect
                  autoFocus={false}
                  isClearable={checkIsClerable(essAccounts.length)}
                  blurInputOnSelect={true}
                  placeholder={SubmitMeterReadingText.selectAccount}
                  value={selectedAccount}
                  styles={selectStyles}
                  options={essAccounts}
                  onChange={handleAccountChange as any}
                />
              </div>
              <div className='dropdown'>
                <ReactSelect
                  autoFocus={false}
                  isClearable={checkIsClerable(sites.length)}
                  blurInputOnSelect={true}
                  placeholder={SubmitMeterReadingText.selectSite}
                  isDisabled={!sites.length}
                  value={selectedSite}
                  styles={selectStyles}
                  options={sites}
                  onChange={handleSiteChange as any}
                />
              </div>
              <div className='dropdown'>
                <ReactSelect
                  autoFocus={false}
                  isClearable={checkIsClerable(mpans.length)}
                  blurInputOnSelect={true}
                  placeholder={SubmitMeterReadingText.selectMpan}
                  isDisabled={!selectedSite && !mpans.length}
                  value={selectedMpan}
                  styles={selectStyles}
                  options={mpans}
                  onChange={handleMpanChange as any}
                />
              </div>
              <div className='d-flex justify-content-end'>
                <Link className='bulk__upload__link d-flex align-items-center' to='/home/meter-reads'>
                  <span>Bulk upload</span>
                  <IcomoonReact
                    className='itemicon chevron'
                    iconSet={iconSet}
                    color={hydroBlue}
                    size={20}
                    icon='launch'
                  />
                </Link>
              </div>
              {/* Removed from Release 1a */}
              {/* {meterRegisters.length > 2 && (
                <div className='d-flex justify-content-end'>
                  <Link
                    className='bulk__upload__link d-flex align-items-center'
                    to='/home/energy-usage/bulk-readings-tool'
                  >
                    <span>Bulk upload</span>
                    <IcomoonReact
                      className='itemicon chevron'
                      iconSet={iconSet}
                      color={razzmatazzPink}
                      size={20}
                      icon='launch'
                    />
                  </Link>
                </div>
              )} */}
            </div>
          </div>
          {showMeterReadData && (isSMECustomerWithNoRenewables || globalContext.globalDetail.meterWidgetExpanded) && (
            <MeterReadingDataBlockDrax
              meterSerialNumber={selectedMeter && selectedMeter['meter-serial-number']}
              meters={meters}
              meterRegisters={meterRegisters}
              onSuccessSubmit={() => {
                handleAccountChange(null);
                setShowSuccessModal(true);
                //setMeterWidgetExpandedValue(false);
              }}
              onErrorSubmit={(errorMessage: string) => {
                handleAccountChange(null);
                setErrorMessage(errorMessage);
                setShowFailureModal(true);
              }}
              onClearValues={() => {
                handleAccountChange(null);
              }}
            />
          )}
          {showSuccessModal && (
            <SuccessSubmitModal
              onClose={() => {
                setShowSuccessModal(false);
              }}
            />
          )}
          {showFailureModal && (
            <FailureSubmitModal
              errorMessage={errorMessage}
              onClose={() => {
                setShowFailureModal(false);
                // onClearValues();
              }}
            />
          )}
        </div>
      </div>
      {isSpinnerVisible && <Loading nowait />}
    </SubmitMeterReadingWrapper>
  );
};

SubmitMeterReadingDrax.defaultProps = defaultProps;
