import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const RowInputItemWrapper = styled.span`
  display: inherit;

  input {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px ${variables.spacingXSmall};
    color: ${colors.darkGrey};
    border-radius: 5px;

    :read-write {
      ${mixins.inputBorderFocus}
      background: ${colors.lightGrey};

      &.input-error {
        border-color: ${colors.error};
      }
    }

    :read-only {
      border: 1px solid transparent;
      background-color: ${colors.lightGrey};
      cursor: pointer;

      &:focus {
        border: 1px solid transparent;
        outline: none;
      }
    }

    &.pale-background {
      background-color: ${colors.lightGrey};
    }

    &:focus {
      ::-webkit-input-placeholder {
        visibility: hidden;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
