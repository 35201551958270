import styled from 'styled-components';

import * as variables from '../../../../common/styles/variables';
import { respondTo } from '../../../../common/styles/mixins';

export const InsightWrapper = styled.div`
  height: 100%;

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

  ${respondTo.mobile`
    max-width: 300px;
  `}

  .img {
    padding: 0;
  }

  .insight-link {
    text-decoration: none;

    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }

  .insight-animation {
    ${respondTo.tablet`
      &:hover {
        top: -10px;
      }
    `};
  }

  p {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .insight-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;

    padding-left: ${variables.spacingMedium};
    padding-right: ${variables.spacingMedium};

    .description {
      min-height: 115px;
    }
  }
`;
