import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import * as mixins from '../../common/styles/mixins';
import * as variables from '../../common/styles/variables';

export const DirectDebitWrapper = styled.div`
  padding-left: ${variables.defaultSpacer};
  padding-right: ${variables.defaultSpacer};

  .primary {
    text-align: center;
    margin-bottom: ${variables.spacingSmall};

    ${mixins.respondTo.tablet`
      text-align: left;
    `};
  }

  ${mixins.respondTo.tablet`
    padding-left: 0px;
    padding-right: 0px;
  `};
`;

export const DirectDebitHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${variables.spacingMedium};

  h4 {
    margin: 0 ${variables.spacingSmall} 0 0;
  }
`;

export const DirectDebitWidget = styled.div`
  ${mixins.boxShadowPrimary};
  background-color: ${colors.lightGrey};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingMedium};
`;

export const DownloadActionsRow = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin: ${variables.spacingSmall} 0;
  }

  ${mixins.respondTo.desktop`
    flex-direction: row;

    a:first-child {
      margin-right: ${variables.spacingMedium};
    }
  `};
`;

export const ProcessStepsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: ${variables.spacingLarge};

  ${mixins.respondTo.tablet`
    padding: 0 5%;
  `};

  ${mixins.respondTo.desktop`
    padding: 0 20%;
  `};
`;
