import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';

import { ExternalLink, ModalClose } from '../../../common/components';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

type EbillProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Your latest statement is now ready to view',
  messagevalues: {},
};

export const Ebill = (props: EbillProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  return (
    <>
      <span className='font-medium'>
        {props.messagevalues.date}
        <span className='ml-2'>{props.messagevalues.time}</span>
      </span>
      <Modal.Header>
        <Modal.Title>{props.messagevalues.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>Your latest invoice is here</p>
        <p>
          You can see your latest invoice for account <span className='primary'>{userDetail.essCustomerId}</span> in
          your online account.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ExternalLink
          onClick={() => {
            props.onHide();
          }}
          className='btn-primary'
          title='View my invoice'
          target='_blank'
          href={`account-summary/invoices?id=${props.messagevalues.callToAction}`}
        />
      </Modal.Footer>
    </>
  );
};

Ebill.defaultProps = defaultProps;
