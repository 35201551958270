import styled from 'styled-components';
import * as mixins from '../../../common/styles/mixins';
import * as variables from '../../../common/styles/variables';
import * as colors from '../../../common/styles/colors';
import { respondTo } from '../../../common/styles/mixins';

export const HealthItemWrapper = styled.div`
  display: flex;

  ${mixins.boxShadowPrimary};
  border-top-right-radius: ${variables.globalRadius};
  border-bottom-right-radius: ${variables.globalRadius};

  &.notHealthy {
    background-color: ${colors.unhealthyLight};
  }

  &.healthy {
    background-color: ${colors.healthyLight};
  }

  .descCol {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;

    ${respondTo.mobile`
      max-width: 450px;
    `};
  }

  .moreInfoCol {
    white-space: nowrap;
    margin-top: 2px;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  .moreInfo {
    color: ${colors.hydroBlue};
  }
`;
