import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, ModalClose } from '../../../common/components';

type RevokeUserModalProps = {
  onHide: Function;
  handleConfirm: Function;
  show: boolean;
  title: string;
};

const defaultProps = {
  onHide: undefined,
  handleConfirm: undefined,
  show: false,
  title: 'Error',
};

const RevokeUserModal = (props: RevokeUserModalProps) => {
  return (
    <>
      <Modal.Header>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to revoke this users access?</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          route='/'
          title='Yes'
          onClick={event => {
            event.preventDefault();
            props.handleConfirm();
          }}
          className='btn-primary m-0'
        ></ButtonPrimary>
      </Modal.Footer>
    </>
  );
};

RevokeUserModal.defaultProps = defaultProps;

export default RevokeUserModal;
