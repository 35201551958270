import { useContext, useEffect, useState, useReducer } from 'react';

import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { GRID_TYPES, UsersGrid } from './UsersGrid';
import { GridFilter } from '../gridfilter/GridFilter';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';
import {
  createUser,
  getAccountUsers,
  getAccountUsersDownload,
  getLimitedAccessUsers,
  getLimitedAccessUsersDownload,
  revokeUser,
  updateUser,
} from '../../common/api/userDetailsApi';
import { GridTypeSelector, StyledTitle } from '../../common/components';
import { UserDetailsAbout } from './UserDetailsAbout';
import { ModalTemplate } from '../mymessages/modalTemplates/ModalTemplate';

const UserDetails = () => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [gridTypes] = useState<string[]>([GRID_TYPES.ACCOUNT_USERS, GRID_TYPES.LIMITED_ACCESS_USERS]);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);

  const initialState: IGridReducerState = {
    page: 1,
    currentType: GRID_TYPES.ACCOUNT_USERS,
    data: {},
    initialSortField: 'name',
    initialSortOrder: 'DESC',
    currentSortField: 'name',
    currentSortOrder: 'DESC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: userDetail.essCustomerId,
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  const getAccountUsersData = async (essCustomerId: string, sort?: Common.ISort) => {
    const { success, data }: Common.IResult<Api.IAccountUser> = await getAccountUsers(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      state.page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (success && data) {
      // dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data } });

      return data.records;
    }

    return [];
  };

  const getLimitedAccessUsersData = async (essCustomerId: string, sort?: Common.ISort) => {
    const { success, data }: Common.IResult<Api.ILimitedAccessUser> = await getLimitedAccessUsers(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      state.page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (success && data) {
      // dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data } });

      return data.records;
    }

    return [];
  };

  const clearData = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
  };

  const loadData = async (gridType: string, customerId: string, sort?: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    if (gridType === GRID_TYPES.ACCOUNT_USERS) {
      const rawData = await getAccountUsersData(customerId, sort);
      dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: rawData, page: state.page } });
    } else if (gridType === GRID_TYPES.LIMITED_ACCESS_USERS) {
      const rawData = await getLimitedAccessUsersData(customerId, sort);
      dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: rawData, page: state.page } });
    } else {
      clearData();
    }
  };

  useEffect(() => {
    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.currentType, state.selectedCustomerId, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentType, state.selectedCustomerId, state.currentSortOrder]);

  const handleTypeChange = (type: string) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: type });
  };

  const handleSortChange = (sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
  };

  const handleClearAll = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const exportOptions = [
    { value: 'csv', display: 'CSV' },
    { value: 'xml', display: 'XML' },
  ];

  const handleExport = async (selectedExportType: 'csv' | 'xml') => {
    if (state.currentType === GRID_TYPES.ACCOUNT_USERS) {
      await getAccountUsersDownload(
        process.env.REACT_APP_API_URL,
        authContext,
        state.selectedCustomerId,
        selectedExportType
      );
    } else if (state.currentType === GRID_TYPES.LIMITED_ACCESS_USERS) {
      await getLimitedAccessUsersDownload(
        process.env.REACT_APP_API_URL,
        authContext,
        state.selectedCustomerId,
        selectedExportType
      );
    }
  };

  const handleShowAddUserModal = async () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = async () => {
    setShowAddUserModal(false);
  };

  const handleAddUser = async (data: any) => {
    const user = {
      associatedcustomer: data.associatedcustomer.value,
      email: data.email,
      firstname: data.firstname,
      jobtitle: data.jobtitle,
      mobile: data.mobile,
      phoneNumber: data.phoneNumber,
      surname: data.surname,
      title: data.title.value,
      primarycontact: data.primarycontact,
    };

    await createUser(process.env.REACT_APP_API_URL, authContext, userDetail.essCustomerId, user);

    setShowAddUserModal(false);
  };

  const handleRevokeUser = async (userId: number, customerId: string) => {
    await revokeUser(process.env.REACT_APP_API_URL, authContext, userId, customerId);
  };

  const handleUpdateUser = async (user: any, customerId: number) => {
    await updateUser(process.env.REACT_APP_API_URL, authContext, user, customerId);
  };

  return (
    <>
      <StyledTitle>User details</StyledTitle>
      <GridFilter
        customerData={state.data?.customers}
        accountData={state.data?.accounts}
        siteData={state.data?.sites}
        selectedType={state.currentType}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleAccountFilterChange={() => {}}
        handleSiteFilterChange={() => {}}
        handleClearAllClick={handleClearAll}
        showSites={false}
        showAccount={false}
        showClearAll={false}
        enableExportBtn={true}
        exportOptions={exportOptions}
        showExport={true}
        handleExport={handleExport}
        showAddUser={true}
        addUserEnabled={userDetail.isPrimaryContact || userDetail.isAccountSignatory}
        handleAddUser={handleShowAddUserModal}
      />

      <UserDetailsAbout />

      <GridTypeSelector initialType={state.currentType} types={gridTypes} handleTypeChange={handleTypeChange} />

      <UsersGrid
        selectedType={state.currentType}
        handleSortChange={handleSortChange}
        handlePagingChange={handlePagingChange}
        hasMoreData={state.hasMoreData}
        gridData={state.gridData}
        isLoading={state.isLoading}
        handleRevokeUser={handleRevokeUser}
        handleUpdateUser={handleUpdateUser}
      />
      <ModalTemplate
        name={'AddUserModal'}
        show={showAddUserModal}
        onHide={handleCloseAddUserModal}
        handleConfirm={handleAddUser}
      />
    </>
  );
};

export default UserDetails;
