import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';
import * as mixins from '../../common/styles/mixins';
import { respondTo } from '../../common/styles/mixins';

export const SideMenuText = {
  welcome: 'Welcome',
  customerId: 'ID',
  inprogress: 'In Progress',
  signout: 'Sign Out',
  seeallmymessages: 'See all my messages',
};

export const SideMenuWrapper = styled.div`
  flex: 0 0 100% !important;
  display: flex;
  flex-direction: column;
  padding-right: 1em;
  height: calc(100vh - 2.5em);

  ${respondTo.tablet`
    flex: 0 0 ${variables.menuWidth} !important;
    padding-right: 0;
    height: auto;
    min-height: 100vh;
  `};

  ~ .main-wrapper {
    display: none;

    ${respondTo.tablet`
      display: flex;
    `};
  }

  ~ .filter-menu-wrapper {
    display: none;
  }

  &.menu-closed {
    height: 0;

    ${respondTo.tablet`
      display: flex !important;
      height: auto;
    `};

    .mobile-menu-wrapper {
      display: flex !important;
    }

    .menu-wrapper {
      display: none;

      ${respondTo.tablet`
        display: flex !important;
      `};
    }
  }

  &.menu-closed ~ .main-wrapper {
    display: flex;
  }

  .mobile-menu-wrapper {
    display: none !important;
    width: 175px;
    z-index: ${variables.zIndexHigh};
    padding: 10px 0;
    margin-left: 15px;
  }

  .mobile-menu-icon {
    border: none;
    background-color: transparent;
    width: 50px;
    height: 42px;

    svg {
      width: 100%;
    }

    &:focus {
      outline: 0;
    }

    ${respondTo.tablet`
      display: none !important;
    `};

    .message-count {
      position: relative;
      display: inline-block;
      height: 15px;
      width: 15px;
      right: 12px;
      left: auto;
      top: -40px;
    }
  }

  .customer-information {
    background-color: ${colors.white};
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    padding: 1.5em 0;

    .seeMoreBtn {
      margin-left: auto;
      font-weight: 400;
      color: #007bff;
      background-color: transparent;
      padding-right: 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      text-align: right;
      cursor: pointer;
    }

    .email {
      font-weight: 500;
    }

    .returnToCustomer {
      color: #007bff;
      text-decoration: none;
    }
  }

  .icon-user-info {
    margin-left: 1em;
  }

  .icon-user svg {
    background-color: ${colors.midGrey};
    border: 2px solid ${colors.midGrey};
    border-radius: 100%;
  }

  .menu-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.bg.secondary};
    border-top-right-radius: 1em;
    margin: 0;
    top: 2.5em;
    padding: 2.5em 2.5em 0 0;

    ${respondTo.tablet`
      top: 0;
    `};

    .close-menu-icon {
      ${mixins.borderNone};
      background-color: transparent;
      position: absolute;
      top: 0.5em;
      right: 0.5em;

      &:focus {
        outline: 0;
      }
    }

    p {
      margin: 0;
    }

    .customer-name {
      max-width: 230px;
    }

    .menu.messages {
      border-bottom: 2px solid ${colors.draxLightBg};
      margin-left: 15px;
      margin-right: 20px;
      a {
        margin-left: -15px;
      }
    }
  }

  #mainMenu {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top-right-radius: 1em;
    padding-top: 0.5em;
    margin: 0;
    list-style: none;

    ${respondTo.tablet`
      background-color: ${colors.white};
    `};

    & > li {
      padding: 0 0 0 1em;

      &.messages .menu-item {
        position: relative;
        top: 3px;
      }

      a {
        color: ${colors.oxfordBlue};
        display: flex;
        align-items: center;
        padding: 1em 0;
        position: relative;
        text-decoration: none;

        ${respondTo.tablet`
          padding: 1em 0;
        `};

        &:hover {
          color: ${({ theme }) => theme.text.secondary};

          svg path {
            ${mixins.transition};
            fill: ${({ theme }) => theme.text.secondary};
            stroke: ${({ theme }) => theme.text.secondary};
          }

          svg rect {
            fill: ${({ theme }) => theme.text.secondary};
          }
        }

        &.active {
          color: ${({ theme }) => theme.text.secondary};

          svg path {
            ${mixins.transition};
            fill: ${({ theme }) => theme.text.secondary};
            stroke: ${({ theme }) => theme.text.secondary};
          }
        }

        &.open {
          color: ${({ theme }) => theme.text.secondary};

          svg path {
            ${mixins.transition};
            fill: ${({ theme }) => theme.text.secondary};
            stroke: ${({ theme }) => theme.text.secondary};
          }

          ~ ul {
            height: auto;
          }

          .chevron-up {
            display: inline-block !important;
          }
        }

        .chevron-up {
          display: none !important;
        }
      }

      ul {
        padding: 0;
        list-style: none;
        height: 0;
        overflow: hidden;
      }

      .menu-icon {
        margin-right: 1em;
      }

      .sub-menu-icon {
        position: absolute;
        right: 0;

        ${respondTo.tablet`
          right: ${variables.spacingSmall};
        `}
      }

      .icon-messages .menu-icon {
        position: relative;
        top: -3px;
      }
    }

    .white-bg {
      background-color: ${colors.white};
    }
  }

  .app-logo {
    padding: ${variables.spacingMedium} 0 ${variables.spacingMedium} ${variables.spacingMedium};
    position: relative;
    top: -2px;
    width: 80px;

    ${respondTo.tablet`
      top: 0;
      width: 100%;
    `}

    svg {
      width: 100%;

      ${respondTo.tablet`
        width: auto;
      `}
    }
  }

  .logo-collapsed {
    padding: 0;
    margin-left: 5px;

    ${respondTo.tablet`
      display: none;
    `}

    img {
      padding: 5px 0;
      height: 55px;
    }
  }

  .menu-selectcustomer {
    padding: 0 0 0 15px;

    ${respondTo.tablet`
      padding: 10px 15px 0 15px;
    `}
  }

  .menu-footer {
    align-items: flex-end !important;
    padding: 10px 0px !important;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .message-count {
    position: relative;
    top: -7px;
    left: -97px;
    border-radius: 100%;
    border: 1px solid #ffffff;
    background-color: #cc0541;
    width: 11px;
    height: 11px;
  }

  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .profile {
    font-size: 14px;
    padding: ${variables.spacingSmall};
    padding-right: 0;

    max-width: 400px;

    z-index: ${variables.zIndexHigh};

    ${respondTo.tablet`
      display: none;
    `};

    .btnchangepassword {
      display: block;
      margin-top: 0px;
      margin-bottom: 10px;
    }

    .btnsignout {
      display: block;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .profileText {
      a {
        color: ${({ theme }) => theme.text.secondary};
        text-decoration: none;
      }
    }

    .supportLink {
      cursor: pointer;
    }

    .contactText {
      .contact-type {
        color: ${({ theme }) => theme.text.secondary};
      }
      .contact-number {
        font-size: ${variables.fontSizeMedium};
        font-weight: bold;
      }
    }
  }

  .customerRow {
    .customerRowAccountName {
      white-space: nowrap;
      max-width: 205px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .customerRowAccountNameLast {
      white-space: nowrap;
      max-width: 115px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
