import styled from 'styled-components';

import { inputBg } from '../../styles/colors';

export const SmartMeterBannerContainer = styled.div`
  padding: 25px 21px 30px 43px;
  background: ${inputBg};
  border-radius: 30px;

  h4 {
    font-size: 23px;
    font-weight: 400;
  }
`;
