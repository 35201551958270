import React, { useContext, useEffect, useState, useReducer } from 'react';
import { toast } from 'react-toastify';

import {
  invoicesElectricity,
  invoicesGas,
  renewableStatements,
  putSiteReference,
  invoicesElectricityEDW,
} from '../../common/api/invoicesApi';
import { SearchDetailContext, SearchDetailContextProps } from '../../context/searchDetail/searchDetailContext';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { getMonthString } from '../../helpers/dateHelper';
import { useAuthState } from '../../providers/authProvider';
import InvoicesComponent from './Invoices';
import { getbackingdatadocuments, getinvoicesummaryreport, getzippeddocuments } from '../../common/api/homeApi';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';
import { EssAccountsTypes } from '../../types/account';
import { getInitialType } from '../../utils/getInitialTypes';
import { RoutingToTypes } from '../search/SearchActions/SearchActions';
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { DateObject } from 'react-multi-date-picker';
import { DATE_FORMAT, DATE_FORMAT_MONTH_YEAR, REVERSED_DATE_FORMAT_HYPHEN } from '../../common/constants';
import { getMetersTwoYearsOld } from '../../common/api/meterReadsApi';
import { getSelectedCustomerId } from '../../helpers/customer';
import moment from 'moment';

const Invoices = () => {
  const authContext = useAuthState();

  const userContext = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);

  const [meterData, setMeterData] = useState<Array<Api.IMeter>>([]);

  const [initialType] = useState<string>(
    getInitialType(
      userContext.userDetail.hasElectricity,
      userContext.userDetail.hasGas,
      userContext.userDetail.hasRenewable
    )
  );
  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(
      userContext.userDetail.hasElectricity,
      userContext.userDetail.hasGas,
      userContext.userDetail.hasRenewable
    ),
    data: {},
    initialSortField: 'billing-period-end-date',
    initialSortOrder: 'DESC',
    currentSortField: 'billing-period-end-date',
    currentSortOrder: 'DESC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userContext.userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);
  const [dates, setDates] = useState<DateObject[]>([]);
  const [isLoadingMeter, setIsLoadingMeter] = useState<boolean>(false);

  // When navigating directly to url
  useEffect(() => {
    if (userContext.userDetail.essCustomerId) {
      initialState.currentType = getInitialType(
        userContext.userDetail.hasElectricity,
        userContext.userDetail.hasGas,
        userContext.userDetail.hasRenewable
      );
      initialState.selectedCustomerId = getSelectedCustomerId(userContext.userDetail);

      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.userDetail.hasElectricity, userContext.userDetail.hasGas, userContext.userDetail.hasRenewable]);

  useEffect(() => {
    // Ignore if we are entering component from Search
    if (
      searchContext.searchDetail != null &&
      searchContext.searchDetail.type &&
      searchContext.searchDetail.type.length > 0
    )
      return;

    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.currentType, state.selectedCustomerId, state.currentFilter, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentType,
    state.page,
    state.currentFilter,
    state.currentSortField,
    state.currentSortOrder,
    state.selectedCustomerId,
    dates,
  ]);

  useEffect(() => {
    if (searchContext.searchDetail != null && searchContext.searchDetail.searchText.length > 0) {
      // filter by search result

      if (searchContext.searchDetail.type && searchContext.searchDetail.routingTo === RoutingToTypes.invoices) {
        const type = searchContext.searchDetail.type.toLowerCase();
        if (type === 'mpan') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: searchContext.searchDetail.mpan } });
        } else if (type === 'mprn') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Gas });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan: searchContext.searchDetail.mprn } });
        } else if (type === 'invoice') {
          switch (searchContext.searchDetail.energyType) {
            case EssAccountsTypes.Electricity: {
              dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
              dispatch({
                type: GRID_ACTIONS.FILTER_BY_INVOICE,
                payload: { invoice: searchContext.searchDetail.invoice },
              });
              break;
            }
            case EssAccountsTypes.Gas: {
              dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Gas });
              dispatch({
                type: GRID_ACTIONS.FILTER_BY_INVOICE,
                payload: { invoice: searchContext.searchDetail.invoice },
              });
              break;
            }
            case EssAccountsTypes.Renewables: {
              dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Renewables });
              dispatch({
                type: GRID_ACTIONS.FILTER_BY_INVOICE,
                payload: { invoice: searchContext.searchDetail.invoice },
              });
              break;
            }
          }
        } else if (type === 'account') {
          switch (searchContext.searchDetail.energyType) {
            case EssAccountsTypes.Electricity: {
              dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
              dispatch({
                type: GRID_ACTIONS.FILTER_BY_ACCOUNT,
                payload: { essAccountId: searchContext.searchDetail.accountId },
              });
              break;
            }
            case EssAccountsTypes.Gas: {
              dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Gas });
              dispatch({
                type: GRID_ACTIONS.FILTER_BY_ACCOUNT,
                payload: { essAccountId: searchContext.searchDetail.accountId },
              });
              break;
            }
            case EssAccountsTypes.Renewables: {
              dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Renewables });
              dispatch({
                type: GRID_ACTIONS.FILTER_BY_ACCOUNT,
                payload: { essAccountId: searchContext.searchDetail.accountId },
              });
              break;
            }
          }
        } else if (type === 'statement') {
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Renewables });
          dispatch({
            type: GRID_ACTIONS.FILTER_BY_STATEMENT,
            payload: { statement: searchContext.searchDetail.statement },
          });
        } else if (type === 'site') {
          // Note:  only done for electricity
          dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: EssAccountsTypes.Electricity });
          dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: searchContext.searchDetail.siteId } });
        }
        // Clear Search Detail
        searchContext.setSearchDetail({ searchText: '' });
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext.searchDetail.type]);

  const filterData = (data: Array<any>, energyType: string) => {
    let filteredData = [];
    if (data && data.length > 0) {
      filteredData = data.filter((item: any) => item.energyIcon === energyType);
    }
    return filteredData;
  };

  const getInvoicesElectricity = async (
    essCustomerId: string,
    searchTerm: string,
    fuelType: string,
    page: number,
    sort?: Common.ISort
  ) => {
    const invoiceData: Common.IResult<Api.IInvoiceElecGasData> = await invoicesElectricity(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      searchTerm,
      fuelType,
      page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (invoiceData?.success) {
      dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data: invoiceData.data } });

      if (invoiceData.data) {
        if (isDraxBrand) {
          // TODO: temporary fix due to API issue
          // @ts-ignore: Types error
          dispatch({
            type: GRID_ACTIONS.ADD_DATA,
            payload: { data: invoiceData.data.invoiceDetails, page },
          });
        }

        if (isOpusBrand) {
          const data = filterData(invoiceData.data.invoiceDetails, EssAccountsTypes.Electricity);
          dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data, page } });
        }
      } else {
        clearData();
      }
    } else {
      const message = 'API error calling: gaselecinvoicedetailsbyesscustomerid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });

      clearData();
    }
  };

  useEffect(() => {
    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    async function fetchData() {
      setIsLoadingMeter(true);
      try {
        var newMeterData = await getMetersTwoYearsOld(
          process.env.REACT_APP_API_URL,
          authContext,
          state.selectedCustomerId
        );
        setMeterData(newMeterData);

        setIsLoadingMeter(false);
      } catch (err) {
        // handle error
        setIsLoadingMeter(false);
      }
    }
    fetchData();

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedCustomerId]);

  useEffect(() => {
    if (meterData.length == 0) return;

    let filters: any = {
      mpansFilters: [],
      sitesFilters: [],
    };

    for (let i = 0; i < meterData.length; i++) {
      filters.mpansFilters.push({
        essCustomerID: state.selectedCustomerId,
        essSiteID: meterData[i]['site-reference'],
        mpan: meterData[i]['mpan-core'],
        mprn: 0,
      });

      filters.sitesFilters.push({
        essSiteID: parseInt(meterData[i]['site-reference']),
        // essAccountID: parseInt(accountId), // Not used
        siteName: meterData[i]['site-reference'],
      });
    }

    dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId: state.selectedCustomerId, data: filters } });

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meterData]);

  const formatInvoiceData = (data: Array<Api.IInvoiceResponse>): Array<Api.IInvoiceDetail> => {
    var result: Array<Api.IInvoiceDetail> = [];

    data.forEach((responseData: Api.IInvoiceResponse) => {
      const billingPeriodEndDate = moment(responseData['billing-period-end-date']);
      const billingPeriodEndMonthYear = billingPeriodEndDate.format(DATE_FORMAT_MONTH_YEAR);

      const paymentDueFormatted =
        responseData['transaction-due-date'] !== null || undefined
          ? moment(responseData['transaction-due-date']).format(DATE_FORMAT)
          : 'N/A';

      const transactionPaymentStatus = responseData['transaction-payment-status'];
      const transactionDueDate = moment(responseData['transaction-due-date']);
      const today = moment();

      let outstandingIcon = false;
      if (transactionPaymentStatus === 'Outstanding' && transactionDueDate.isBefore(today)) {
        outstandingIcon = true;
      }

      const invoiceDetail: Api.IInvoiceDetail = {
        accountName: '',
        energyIcon: 'Electricity',
        siteReference: responseData['site-reference'],
        customerSiteReference: responseData['customer-site-reference'],
        siteAddress: responseData['site-address'],
        month: billingPeriodEndMonthYear,
        amount: responseData.total,
        paymentDue: responseData['transaction-due-date'],
        paymentDueFormatted,
        siteEndDate: '',
        mpanElec: responseData['mpan-core'],
        mprnGas: 0,
        invoiceNumber: responseData['transaction-reference'],
        customerID: responseData['customer-account-reference'],
        accountID: 0,
        siteID: responseData['site-id'],
        essInvoiceID: responseData['transaction-id'],
        essDocumentID: responseData['transaction-id'],
        outstandingIcon,
      };
      result.push(invoiceDetail);
    });

    return result;
  };

  const getInvoicesElectricityEDW = async (
    essCustomerId: string,
    invoiceNumber: string,
    accountId: string,
    siteReference: string,
    mpan: string,
    date: string,
    dateFrom: string,
    dateTo: string,
    fuelType: string,
    page: number,
    sort?: Common.ISort
  ) => {
    const invoiceData: Array<Api.IInvoiceResponse> = await invoicesElectricityEDW(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      invoiceNumber,
      accountId,
      siteReference,
      mpan,
      date,
      dateFrom,
      dateTo,
      fuelType,
      page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (invoiceData) {
      const data = formatInvoiceData(invoiceData);

      // @ts-ignore: Types error
      dispatch({
        type: GRID_ACTIONS.ADD_DATA,
        payload: { data: data, page },
      });
    } else {
      const message = 'API error calling: revenue-transactions';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });

      clearData();
      dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
    }
  };

  const getInvoicesGas = async (
    essCustomerId: string,
    searchTerm: string,
    fuelType: string,
    page: number,
    sort?: Common.ISort
  ) => {
    const invoiceData: Common.IResult<Api.IInvoiceElecGasData> = await invoicesGas(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      searchTerm,
      fuelType,
      page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (invoiceData && invoiceData.success) {
      dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data: invoiceData.data } });

      if (invoiceData.data) {
        const data = filterData(invoiceData.data.invoiceDetails, EssAccountsTypes.Gas);
        dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data, page } });
      } else {
        clearData();
      }
    } else {
      const message = 'API error calling: gaselecinvoicedetailsbyesscustomerid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });

      clearData();
    }
  };

  const getRenewableStatements = async (
    essCustomerId: string,
    page: number,
    currentFilter?: Common.IFilter | null,
    sort?: Common.ISort
  ) => {
    const invoiceReference = currentFilter && currentFilter.type === 'InvoiceReference' ? currentFilter.value : '';
    const essAccountId = currentFilter && currentFilter.type === 'AccountId' ? currentFilter.value : '';
    const essSiteId = currentFilter && currentFilter.type === 'SiteId' ? currentFilter.value : '';

    const renewableStatementData: Common.IResult<Api.IRenewableData> = await renewableStatements(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      essAccountId,
      essSiteId,
      invoiceReference,
      page,
      sort
    );

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (renewableStatementData && renewableStatementData.success) {
      dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data: renewableStatementData.data } });

      let data = renewableStatementData.data;
      if (data) {
        data.renewablesStatement.statements.map(item => {
          const monthString = getMonthString(item.invoiceMonth);
          const month = `${monthString} ${item.invoiceYear}`;
          item.month = month;
          return item;
        });
      }
      if (data && data.renewablesStatement) {
        dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: data.renewablesStatement.statements, page } });
      } else {
        clearData();
      }
    } else {
      const message = 'API error calling: renewablestatements';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });

      clearData();
    }
  };

  const saveSiteReference = async (siteId: number, customerSiteReference: string) => {
    // EDW endpoint not returning IResult structure
    if (isDraxBrand) {
      const result: string = await putSiteReference(
        process.env.REACT_APP_API_URL,
        authContext,
        siteId,
        customerSiteReference
      );
      if (result === '') {
        return true;
      } else {
        const message = 'API error calling: putSiteReference';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });

        return false;
      }
    } else {
      const result: Common.IResult<string> = await putSiteReference(
        process.env.REACT_APP_API_URL,
        authContext,
        siteId,
        customerSiteReference
      );
      if (result && result.success) {
        return true;
      } else {
        const message = 'API error calling: putSiteReference';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });

        return false;
      }
    }
  };

  const clearData = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
  };

  const loadData = (
    gridType: string,
    customerId: string,
    currentFilter?: Common.IFilter | null,
    sort?: Common.ISort
  ) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    if (gridType === EssAccountsTypes.Electricity) {
      if (isDraxBrand) {
        var filters = {
          invoiceNumber: '',
          accountId: '',
          siteName: '',
          mpan: '',
          date: '',
          dateFrom: '',
          dateTo: '',
        };

        if (currentFilter?.type === 'InvoiceReference') {
          filters.invoiceNumber = currentFilter?.value;
          toast.error('InvoiceReference FILTER NOT IMPLEMENTED IN EDW ENDPOINT', { position: 'bottom-right' });
        } else if (currentFilter?.type === 'AccountId') {
          filters.accountId = currentFilter?.value;
          toast.error('AccountId FILTER NOT IMPLEMENTED IN EDW ENDPOINT', { position: 'bottom-right' });
        } else if (currentFilter?.type === 'SiteId') {
          filters.siteName = currentFilter?.value;
        } else if (currentFilter?.type === 'MpanMprn') {
          filters.mpan = currentFilter?.value;
        } else if (currentFilter?.type === 'Date') {
          filters.date = currentFilter?.value; // "February 2021"
          toast.error('Date FILTER NOT IMPLEMENTED IN EDW ENDPOINT', { position: 'bottom-right' });
        }

        if (dates[0] && dates[1]) {
          filters.dateFrom = dates[0].format(REVERSED_DATE_FORMAT_HYPHEN);
          filters.dateTo = dates[1].format(REVERSED_DATE_FORMAT_HYPHEN);
        } else {
          filters.dateFrom = '';
          filters.dateTo = '';
        }

        getInvoicesElectricityEDW(
          customerId,
          filters.invoiceNumber,
          filters.accountId,
          filters.siteName,
          filters.mpan,
          filters.date,
          filters.dateFrom,
          filters.dateTo,
          EssAccountsTypes.Electricity,
          state.page,
          sort
        );
      } else {
        const searchTerm = currentFilter ? currentFilter.value : '';
        getInvoicesElectricity(customerId, searchTerm, EssAccountsTypes.Electricity, state.page, sort);
      }
    } else if (gridType === EssAccountsTypes.Gas) {
      const searchTerm = currentFilter ? currentFilter.value : '';
      getInvoicesGas(customerId, searchTerm, EssAccountsTypes.Gas, state.page, sort);
    } else if (gridType === EssAccountsTypes.Renewables) {
      getRenewableStatements(customerId, state.page, currentFilter, sort);
    }
  };

  const handleTypeChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: type });
  };

  const handleSortChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleRenewablesRowBlur = async (data: Array<Api.IRenewableStatement>, id: number, siteReference: string) => {
    let index: any = data.findIndex(element => element.portalSiteId === id);

    if (data[index].customerSiteReference !== siteReference) {
      data[index].customerSiteReference = siteReference;
      const siteId = data[index].essSiteId;
      await saveSiteReference(siteId, siteReference);
    }
  };

  const handleInvoiceRowBlur = async (data: Array<Api.IInvoiceDetail>, id: number, siteReference: string) => {
    let index: any = data.findIndex(element => element.siteID === id);

    // TODO - REMOVED TEMP FIX - May need back if Bug 26913 isn't fixed
    // Cannot Save blank value
    // if (siteReference.length === 0) {
    //   return;
    // }

    if (isDraxBrand) {
      if (data[index].customerSiteReference !== siteReference) {
        data[index].customerSiteReference = siteReference;
        const siteId = data[index].siteID;
        await saveSiteReference(siteId, siteReference);

        // Update all customerSiteReferences in UI for same siteId
        data.map(item => {
          if (item.siteID === siteId) {
            item.customerSiteReference = siteReference;
          }
          return item;
        });
        // Cause page to refresh
        dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
      }
    } else {
      if (data[index].siteReference !== siteReference) {
        data[index].siteReference = siteReference;
        const siteId = data[index].siteID;
        await saveSiteReference(siteId, siteReference);
      }
    }
  };

  const handleSiteBlur = async (id: number, site: string) => {
    if (state.currentType === EssAccountsTypes.Renewables) {
      handleRenewablesRowBlur(state.gridData as Array<Api.IRenewableStatement>, id, site);
    } else {
      handleInvoiceRowBlur(state.gridData as Array<Api.IInvoiceDetail>, id, site);
    }
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    const essSiteId = option ? option.value : '';
    dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: essSiteId } });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    if (state.currentType === EssAccountsTypes.Renewables) {
      // TODO - No CustomerId in renewables
    } else {
      dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
    }
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    const essAccountId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId } });
  };

  const handleMpanMprnFilterChange = (option: Common.IOption) => {
    const mprnMpan = option ? option.value : '';
    dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan } });
  };

  const handleDateFilterChange = (formattedMonthYear: string) => {
    const date = formattedMonthYear;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_DATE, payload: { date } });
  };

  const handlePeriodFilterChange = (dates: any) => {
    const payload = {
      dateFrom: dates[0],
      dateTo: dates[1],
    };

    dispatch({ type: GRID_ACTIONS.FILTER_BY_PERIOD, payload: payload });
    setDates(dates);
  };

  const handleClearAllClick = (): void => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
    setDates([]);
  };

  const handleDownloadReport = async (invoiceIds: Array<number>) => {
    if (invoiceIds.length === 0) return;

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });
    await getzippeddocuments(process.env.REACT_APP_API_URL, authContext, invoiceIds);
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  };

  const handleDownloadBackingData = async (invoiceIds: Array<number>) => {
    if (invoiceIds.length === 0) return;

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });
    await getbackingdatadocuments(process.env.REACT_APP_API_URL, authContext, invoiceIds);
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  };

  const handleDownloadSummary = async (essInvoiceIds: Array<number>) => {
    if (essInvoiceIds.length === 0) return;

    await getinvoicesummaryreport(process.env.REACT_APP_API_URL, authContext, essInvoiceIds);
  };

  return (
    <InvoicesComponent
      initialType={initialType}
      handleTypeChange={handleTypeChange}
      handleSortChange={handleSortChange}
      handleSiteBlur={handleSiteBlur}
      handlePagingChange={handlePagingChange}
      gridData={state.gridData}
      hasRenewableAccount={userContext.userDetail.hasRenewable}
      hasElectricityAccount={userContext.userDetail.hasElectricity}
      hasGasAccount={userContext.userDetail.hasGas}
      customerData={state.data?.customers}
      accountData={state.data?.accounts}
      siteData={state.data?.sites}
      mpanMprnData={state.data?.mpans}
      handleAccountFilterChange={handleAccountFilterChange}
      handleCustomerFilterChange={handleCustomerFilterChange}
      handleSiteFilterChange={handleSiteFilterChange}
      handleMpanMprnFilterChange={handleMpanMprnFilterChange}
      handleDateFilterChange={handleDateFilterChange}
      handlePeriodFilterChange={handlePeriodFilterChange}
      handleDownloadReport={handleDownloadReport}
      handleDownloadBackingData={handleDownloadBackingData}
      handleDownloadSummary={handleDownloadSummary}
      handleClearAllClick={handleClearAllClick}
      isLoading={state.isLoading || isLoadingMeter}
      hasMoreData={state.hasMoreData}
      currentSortField={state.currentSortField}
      currentSortOrder={state.currentSortOrder}
    />
  );
};
export default Invoices;
