import Modal from 'react-bootstrap/Modal';

import PrimaryContactForm from './PrimaryContactForm';
import { ContactDetails } from './ContactDetails';
import { UpdatePrimaryContact, SetPrimaryContact } from './PrimaryContactModal.messages';

type PrimaryContactModalProps = {
  title?: string;
  fixed?: boolean;
  show: boolean;
  updateMode: boolean;
  contactDetails?: ContactDetails;
  onSubmit: (data: ContactDetails) => void;
};

const defaultProps = {
  title: 'Primary contact',
  show: true,
  updateMode: false,
  contactDetails: null,
  onSubmit: (data: ContactDetails) => console.log(data),
};

export const PrimaryContactModal = (props: PrimaryContactModalProps) => {
  const { show, title, updateMode, contactDetails, onSubmit } = props;

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} size={'lg'} backdrop='static' centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{updateMode ? UpdatePrimaryContact : SetPrimaryContact}</p>
        </Modal.Body>

        <PrimaryContactForm onSubmit={onSubmit} initialData={contactDetails} />
      </Modal>
    </>
  );
};

PrimaryContactModal.defaultProps = defaultProps;
