import classnames from 'classnames';
import * as colors from '../../../styles/colors';
import { useRef, useState } from 'react';
import { IInputInterface } from '../types';
import { Icon } from '../../Icon/Icon';
import { PadlockClosed } from '../../../../components/icons';

const Input = ({ errorMessage, locked, className, ...rest }: IInputInterface) => {
  const { label, type } = rest;
  const ref = useRef<HTMLInputElement>(null);
  const [inputType] = useState<string>(type);
  const cxInput = classnames(`c-form__input ${className}`, {
    'c-form__input--locked': locked,
    'c-form__input--error': errorMessage,
  });
  const cxWrapper = classnames(`c-form__input-wrapper`, {
    'c-form__input-wrapper--error': errorMessage,
  });

  const updatedProps = { ...rest, className: cxInput, type: inputType };

  return (
    <div className='c-form__element'>
      <label className='c-form__label' htmlFor={rest.id}>
        {label}
      </label>
      <div className={cxWrapper}>
        <input ref={ref} {...updatedProps} />
        {locked && <Icon icon={PadlockClosed} size={20} />}
      </div>
      {errorMessage && (
        <p className='c-form__error-text' style={{ color: colors.errorLight }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Input;
