import { toast } from 'react-toastify';
import { get } from '../../helpers/axiosWrapper';
import { globalApiParams } from './globals';

export const getCustomerDetails = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Common.IResult<Api.ICustomerDetails>> => {
  return await get(`${url}/customer/customerdetails/${essCustomerId}`, {}, context);
};

export const getCustomerBalance = async (
  url: string | undefined,
  context: any,
  essCustomerId: string
): Promise<Api.ICustomerBalances> => {
  return await get(`${url}/account/account-balances/${essCustomerId}`, {}, context);
};

export const getSites = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  siteReference: string | null,
  mpan: string | null,
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.ISitesPagedData>> => {
  // TODO: TEMP EDW message check
  if (mpan) {
    toast.error('mpan FILTER NOT IMPLEMENTED IN EDW ENDPOINT', { position: 'bottom-right' });
  }

  const headers: Api.ISitesGridParams = {
    sortField: sort?.field,
    sortDir: sort?.order,
    siteReference: siteReference || '',
    mpan: mpan || '',
    page,
    pageSize: globalApiParams.pageSize,
  };

  if (page === 1) {
    return await get(`${url}/site/supplydetails/${essCustomerId}?getFilters=true`, { headers }, context);
  } else {
    return await get(`${url}/site/supplydetails/${essCustomerId}?getFilters=false`, { headers }, context);
  }
};

export const getAllSites = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  siteStatus: string
): Promise<Array<Api.IErsSiteData>> => {
  const headers: any = {
    'page-size': 0, // all
  };
  return await get(
    `${url}/ers/v1/sites?customer-account-reference=${essCustomerId}&site-status=${siteStatus}`,
    { headers },
    context
  );
};

export const getAllSitesMovingPremises = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  siteReferece: string | null,
  page: number,
  sort?: Common.ISort
): Promise<Array<Api.ISites>> => {
  const siteRefQuery = siteReferece ? `&site-reference=${siteReferece}` : '';

  return await get(
    `${url}/ers/v1/sites?customer-account-reference=${essCustomerId}&site-status=Active${siteRefQuery}&sort=${sort?.field}=${sort?.order}&page=${page}&page-size=${globalApiParams.pageSize}`,
    {},
    context
  );
};

export const getErsSitesData = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  siteReference: string | null,
  page: number,
  sort?: Common.ISort
): Promise<Array<Api.IErsSiteData>> => {
  const siteRefQuery = siteReference ? `&site-reference=${siteReference}` : '';

  return await get(
    `${url}/ers/v1/sites?customer-account-reference=${essCustomerId}&site-status=Active${siteRefQuery}&sort=${sort?.field}=${sort?.order}&page=${page}&page-size=${globalApiParams.pageSize}`,
    {},
    context
  );
};
