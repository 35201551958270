import { get, put } from '../../helpers/axiosWrapper';
import { MetersFilterObject } from './meterReadsApi';

export const putSiteReference = async (
  url: string | undefined,
  context: any,
  siteId: number,
  customerSiteReference: string
): Promise<any> => {
  let data: any = { essSiteID: siteId, customerSiteReference };
  if (customerSiteReference.length === 0) {
    data = { essSiteID: siteId };
  }
  return await put(url + '/site/sitereference', data, context);
};

export const statements = async (url: string | undefined, context: any): Promise<any> => {
  let headers = {};
  return await get(url + '/v1/home/statements', { headers }, context);
};

export const getMpanTariffInfo = async (
  url: string | undefined,
  context: any,
  apiUrl: string,
  customerAccountReference: string,
): Promise<Common.IResult<Array<Api.IMpanTariffInfo>>> => {
  if (!url) throw new Error('url not defined');

  const headers = {
    customerAccountReference,
  };

  return await get(url + apiUrl, { headers }, context);
};

export const getCustomerMpanDetails = (
  url: string | undefined,
  context: any,
  customerAccountReference: string,
  pageSize: number,
  page?: number,
  sort?: Common.ISort,
  filters?: MetersFilterObject
): Promise<Common.IResult<Array<Api.IMeterReadsMissing>>> => {
  const headers = {
    page: page || 1,
    pageSize,
    sortField: sort?.field,
    sortDir: sort?.order,
    ...filters,
  };

  return get(`${url}/ersgatewayadapter/mpandetails/grid-data/${customerAccountReference}`, { headers }, context);
};
