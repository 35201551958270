import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import iconSet from '../../../../assets/selection.json';
import IcomoonReact from 'icomoon-react';
import { ButtonPrimary, ModalClose } from '../../../../common/components';

type SuccessSubmitBulkMetersModalProps = {
  onClose: () => void;
};

export const ContentWrapper = styled.div`
  text-align: center;

  .success-icon {
    display: inline-block;
    padding: 25px;
    width: 80px;
    height: 80px;
    text-align: center;
    background: ${({ theme }) => theme.primary.color};
    border-radius: 50%;
    margin-bottom: 10px;
  }
`;

export const SuccessSubmitBulkMetersModal = (props: SuccessSubmitBulkMetersModalProps) => {
  const { onClose } = props;

  return (
    <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Submit meter reads</Modal.Title>
        <ModalClose onClick={onClose} />
      </Modal.Header>

      <Modal.Body className='pl-lg-4'>
        <ContentWrapper>
          <div className='d-flex justify-content-center align-items-center'>
            <span className='success-icon'>
              <IcomoonReact iconSet={iconSet} color={'white'} size={30} icon='check' />
            </span>
          </div>
          Your File has been successfully submitted.
          <br />
          <br />
          We will process your file and notify you via "Messages" when complete or if we have any problems.
          <br />
          <br />
        </ContentWrapper>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={onClose} className={'mr-0'} title='Okay' route='#' />
      </Modal.Footer>
    </Modal>
  );
};
