import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { UploadingIssuesMessage } from './UploadingIssuesMessage';

import { ButtonPrimary, ModalClose } from '../../../../common/components';

type UploadingIssuesModalProps = {
  onClose: () => void;
};

export const ContentWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.text.secondary};
    text-decoration: none;
  }
  li {
    list-style: disc;
  }
`;

export const UploadingIssuesModal = (props: UploadingIssuesModalProps) => {
  const { onClose } = props;

  return (
    <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Having issues uploading a file?</Modal.Title>
        <ModalClose onClick={onClose} />
      </Modal.Header>

      <Modal.Body className='pl-lg-4'>
        <ContentWrapper>{UploadingIssuesMessage}</ContentWrapper>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={onClose} className={'mr-0'} title='Ok' route='#' />
      </Modal.Footer>
    </Modal>
  );
};
