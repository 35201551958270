// Modules
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Types
import { IGasRegisterData } from '../../../../types/account';
import { DATE_FORMAT, REVERSED_DATE_FORMAT_HYPHEN } from '../../../../common/constants';

// Utils
import { TagManager } from '../../../utils/analytics/TagManager';
import { getRegisterReadings } from '../../../meterreads/helper';

// Components
import { ButtonTerinary, CustomDatePicker, Icon, Loading } from '../../../../common/components';

// Context
import { HomeContext, HomeContextProps } from '../../context/homeContext';
import { GlobalContext, GlobalDetailContextProps } from '../../../../context/globalContext/globalContext';
import { useAuthState } from '../../../../providers/authProvider';

// Styles
import { MeterReadingDataWrapper } from './MeterReadingData.styles';
import { getSubmitMeterError } from '../../../../helpers/getSubmitMetersErrors';
import {
  MeterReadingBody,
  deleteMeterReadsGridCache,
  submitMultiMeterReads,
} from '../../../../common/api/meterReadsApi';
import moment from 'moment';
import { SubmitRegisterItemDrax } from '../../../../common/components/SubmitRegisterItemDrax';
import { DateObject } from 'react-multi-date-picker';
import { UserDetailContext, UserDetailContextProps } from '../../../../context/userDetail/userDetailContext';
import { temporary } from '../../../../helpers/axiosWrapper';
import { isTpiUser } from '../../../../helpers/tpiUser';

type MeterReadingData = {
  meterSerialNumber: string | null;
  meters: Array<Api.IMeter>;
  meterRegisters: Array<Api.IMeterRead>;
  onClearValues: Function;
  onSuccessSubmit?: Function;
  onErrorSubmit?: Function;
};

export type NewRead = {
  newRead: string;
  isValid: boolean;
  registerId: string;
  date: string;
};

MeterReadingDataBlockDrax.defaultProps = {
  sites: [],
  meters: [],
  essAccounts: [],
  meterRegisters: [],
};

export function MeterReadingDataBlockDrax(props: MeterReadingData) {
  const history = useHistory();
  const authContext = useAuthState();

  // Context
  const { homeState } = useContext<HomeContextProps>(HomeContext);
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [readingsValues, setReadingsValues] = useState<{ [key: string]: NewRead }>({});
  const [readDate, setReadDate] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedFormatedDate, setSelectedFormatedDate] = useState<string>('');
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  // Props
  const { meterSerialNumber, meters, onClearValues = () => {} } = props;

  // Handlers
  const resetWidgetExpandedState = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      meterWidgetExpanded: false,
    });
  };

  useEffect(() => {
    setIsSubmitted(false);
    setSelectedDate('');
    setSelectedFormatedDate('');

    if (props.meterRegisters && props.meterRegisters?.length > 0) {
      const newReadingsValues: { [key: string]: NewRead } = {};
      props.meterRegisters.map(
        (el, index) =>
          (newReadingsValues[`read${index + 1}`] = {
            registerId: el['register-id'],
            newRead: '',
            isValid: false,
            date: '',
          })
      );
      setReadingsValues(newReadingsValues);
    }
  }, [props.meterRegisters]);

  const submitReads = async () => {
    setIsLoading(true);

    const mpan = props.meterRegisters[0]['mpan-core'];
    const readings = getRegisterReadings(readingsValues, meterSerialNumber);

    const readingDate = selectedFormatedDate;
    const claims = authContext.getClaims();
    const tpiUser = isTpiUser();

    var body: MeterReadingBody = {
      'mpan-core': mpan,
      'reading-date': readingDate,
      'cot-reads': false,
      'created-by': tpiUser ? `CPH${claims.tpiReferenceId}` : `CPH${temporary.billingpartyid}`,
      'register-readings': readings,
    };

    let selectedCustomerAccount = userDetail.essCustomerId;
    const response: Common.IResult<any> = await submitMultiMeterReads(process.env.REACT_APP_API_URL, authContext, body);

    if (response && !response.data) {
      await deleteMeterReadsGridCache(selectedCustomerAccount, authContext);
      onSuccessModalAppeared();
      setIsSubmitted(false);
    } else {
      const error: string = response.data['error-details'].join('\n');
      const errorMsg = getSubmitMeterError(error);
      onErrorModalAppeared(errorMsg);
    }

    setIsLoading(false);
  };

  const isSubmitDisabled = () => {
    const values = Object.values(readingsValues);
    const hasInvalidItem = values.findIndex((item: NewRead) => item.isValid === false) >= 0;
    return hasInvalidItem;
  };

  const handleSubmitClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (isSubmitDisabled()) {
      return;
    } else {
      submitReads();
    }

    TagManager.pushData({
      event: 'Submit meter read (MR page)',
      readDate,
    });
  };

  const onSuccessModalAppeared = () => {
    TagManager.pushData({
      event: 'Meter Read Successful (Home page)',
    });

    if (typeof props.onSuccessSubmit === 'function') {
      props.onSuccessSubmit();
    }
  };

  const onErrorModalAppeared = (errorMessage: string) => {
    if (typeof props.onErrorSubmit === 'function') {
      props.onErrorSubmit(errorMessage);
    }
  };

  const handleMeterReadsNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    resetWidgetExpandedState();
    history.push('/home/meter-reads');
  };

  const getKeyForRegister = (register: IGasRegisterData | Api.IMeterRead) => {
    const gasRegister = register as IGasRegisterData;

    if (gasRegister.essGasMeterID) {
      return gasRegister.essGasMeterID;
    } else {
      const elecRegister = register as Api.IMeterRead;

      return elecRegister['register-id'];
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, register: Api.IMeterRead) => {
    setReadingsValues({
      ...readingsValues,
      [`read${index + 1}`]: {
        registerId: register['register-id'],
        newRead: e.target.value,
        isValid: parseFloat(e.target.value) >= register['reading'] && selectedFormatedDate.length > 0,
        date: selectedFormatedDate,
      },
    });
  };

  const mpan = props.meterRegisters && props.meterRegisters[0] && props.meterRegisters[0]['mpan-core'];

  const previousReadDate =
    props.meterRegisters && props.meterRegisters[0]
      ? moment(props.meterRegisters[0]['reading-date']).format(DATE_FORMAT)
      : 'No Date available';

  const isMobileDropdownOpened = homeState.active && homeState.selectedCard === 'SubmitMeterReading';
  const isShowingReadingFields = meterSerialNumber && mpan && meters.length <= 2 && props.meterRegisters.length <= 2;
  let message: string = '';

  if (meters.length > 2) {
    message =
      `MPAN: ${props.meters[0]['mpan-core']}</br></br>` +
      'This MPAN has more than one meter associated with it.</br></br>' +
      'Please provide the meter readings via our ‘Submit meter read’ page using the button below.';
  } else if (props.meterRegisters.length > 2) {
    message =
      `MPAN: ${mpan}</br></br>` +
      `MSN: ${meterSerialNumber}</br></br>` +
      'This meter has more than two registers.</br></br>' +
      'Please provide the meter reading via our ‘Submit meter read’ page using the button below.';
  }

  let meterProductIcon = '';

  const currentDate = new Date();
  const startDateWindow = new DateObject({
    year: currentDate.getUTCFullYear() - 2,
    month: currentDate.getUTCMonth() + 1,
    day: currentDate.getUTCDate(),
  });

  const handleDateChange = (e: any) => {
    let selectedDate: string = '';
    if (e) {
      selectedDate = e.format(DATE_FORMAT);
      setSelectedDate(selectedDate);

      const readingDate = e.format(REVERSED_DATE_FORMAT_HYPHEN);
      setSelectedFormatedDate(readingDate);

      Object.keys(readingsValues).map((key: string, index: number) => {
        const reading = props.meterRegisters[index].reading;
        readingsValues[key].isValid = parseFloat(readingsValues[key].newRead) >= reading && selectedDate.length > 0;
        readingsValues[key].date = readingDate;
      });
    }
  };

  return (
    <MeterReadingDataWrapper
      className={`col-12 col-lg-6 px-0 px-lg-3 mt-4 d-md-flex justify-content-center position-relative`}
    >
      {isLoading ? (
        <Loading
          containerClassName='d-flex justify-content-center align-items-center'
          containerStyles={{ inset: 0, transform: 'none' }}
        />
      ) : (
        <>
          {!message && !meterSerialNumber && (
            <div className='d-flex justify-content-center align-items-center'>
              <h4>No meter selected</h4>
            </div>
          )}
          {message && (
            <div className='w-100 h-100 widget__message'>
              <p className='text-white' dangerouslySetInnerHTML={{ __html: message }}></p>
              <ButtonTerinary
                onClick={handleMeterReadsNavigation}
                className='meter__link'
                title='Go to ‘Submit meter read’ page'
                route='/'
              />
            </div>
          )}
          {isShowingReadingFields && (
            <form className='reading__fields'>
              <div className='row'>
                <div className='col'>
                  <p className='mb-0'>MPAN: {mpan}</p>
                  <p className='mb-0'>MSN: {meterSerialNumber}</p>
                </div>
                <div className='col'>
                  <p>Last provided read date: {previousReadDate}</p>
                </div>
              </div>
              <div className='row'>
                <div className='col pt-3'>
                  <p className=''>New read date:</p>
                </div>
                <div className='col'>
                  <CustomDatePicker
                    date={selectedDate}
                    showIcon={true}
                    minDate={startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
                    maxDate={new Date()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e)}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'></div>
                <div className='col pt-2'>
                  <p className='mb-0 pb-0'>Read value*</p>
                </div>
              </div>
              <div>
                {props.meterRegisters.map((register, index) => (
                  <SubmitRegisterItemDrax
                    key={getKeyForRegister(register)}
                    index={index}
                    description={register['register-id']}
                    lastRead={register['reading']}
                    lastReadDate={register['reading-date']}
                    containerClassName='px-0 col-12 mt-1'
                    isDescriptionHidden={props.meterRegisters.length === 1}
                    value={readingsValues[`read${index + 1}`]?.['newRead']}
                    selectedDate={selectedDate}
                    onChange={e => handleInputChange(e, index, register)}
                    isSubmitted={isSubmitted}
                  />
                ))}
              </div>
              <div>
                <p className='readingMessage font-small font-italic text-center'>
                  *Read value must be equal or greater than previous read
                </p>
              </div>
              <div className='px-0 pl-0 pt-4 col-12 d-flex justify-content-end'>
                <div>
                  <ButtonTerinary
                    onClick={handleSubmitClick}
                    className='mb-0 col-xl-6 col-12'
                    disabled={isSubmitDisabled()}
                    title='Submit read'
                  />
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </MeterReadingDataWrapper>
  );
}
