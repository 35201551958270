import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary, ModalClose } from '../../../common/components';
import { Icon } from '../../../common/components';
import { Upload } from '../../icons';

import { getCSVFileLink } from '../../../common/api/myMessagesApi';
import { downloadCSV } from '../../../helpers/downloadCSV';

import { useAuthState } from '../../../providers/authProvider';
import { useContext } from 'react';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { TagManager } from '../../utils/analytics/TagManager';

export const CustomMeterReadUploadFailureWrapper = styled.div`
  .download-icon {
    transform: rotate(180deg);
  }
`;

type MeterReadUploadFailureProps = {
  onHide: () => void;
  messagevalues: Messages.IMessagesValues;
  handleMarkAsRead: Function | undefined;
};

export const MeterReadUploadFailure = (props: MeterReadUploadFailureProps) => {
  const { onHide, handleMarkAsRead = () => {} } = props;
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const handleDownloadCSV = async () => {
    if (props.messagevalues && props.messagevalues?.ids) {
      TagManager.pushData({
        event: 'Download bulk failure csv',
      });

      const { date, accountReference } = props.messagevalues;
      const result = await getCSVFileLink(process.env.REACT_APP_API_URL, authContext, props.messagevalues.ids[0]);
      const csvStr = JSON.parse(result.data[0].json).CsvPayload;
      downloadCSV(csvStr, `Meter_read_submission_failure-${date}-${accountReference}`);
    }
  };

  const handleCloseModal = () => {
    if (props.messagevalues && props.messagevalues?.ids) {
      const data = {
        emailAddress: userDetail.emailAddress,
        alertIds: [props.messagevalues.ids[0]],
      };
      handleMarkAsRead(data);
      onHide();
    }
  };

  return (
    <CustomMeterReadUploadFailureWrapper>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Meter read upload failure</Modal.Title>
        <ModalClose onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body className='pl-lg-4'>
        <div>
          Thank you for submitting your meter reads.
          <br />
          <br />
          Unfortunately {props.messagevalues.json.ErrorCount} of these failed our validation process.
          <br />
          <br />
          We have provided a file listing the failure reasons, please download and amend the data before resubmitting
          via the submit meter read page.
          <br />
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={handleDownloadCSV} className={'mr-0'} route='#'>
          <Icon icon={Upload} size={18} className='mr-2 download-icon' />
          Download CSV
        </ButtonPrimary>
      </Modal.Footer>
    </CustomMeterReadUploadFailureWrapper>
  );
};
