import { PHONE_PATTERN } from '../../../utils/common';
import { FormInput } from '../../../common/components';
import { useContext } from 'react';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

type YourInfoBlockProps = {};

export const YourInfoBlock = (props: YourInfoBlockProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  return (
    <>
      <div className='formRowWrapper withContactDetails'>
        <div className='contactInfo'>{`Moving premises notification raised by - ${userDetail.emailAddress}`}</div>

        <div className='row mx-auto mx-sm-4'>
          <div className='pr-2 pb-1'>Contact name*</div>
          <div className=''>
            <FormInput
              name='yourContactName'
              disabled={false}
              params={{
                required: true,
              }}
            />
          </div>
        </div>

        <div className='row mx-auto mx-sm-4'>
          <div className='pr-2 pb-1'>Contact number*</div>
          <div className=''>
            <FormInput
              name='yourPhoneNumber'
              disabled={false}
              params={{
                required: true,
                pattern: {
                  value: PHONE_PATTERN,
                  message: 'Please provide a valid phone number without spaces',
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
