import { ReactNode, useState } from 'react';

import { GlobalContext } from './globalContext';
import { IGlobalDetail } from './globalDetail';

export interface IGlobalDetailProps {
  value: IGlobalDetail;
  children?: ReactNode;
}

export const GlobalDetailProvider = (props: IGlobalDetailProps) => {
  const [globalDetail, setGlobalDetail] = useState<IGlobalDetail>(props.value);

  return <GlobalContext.Provider value={{ globalDetail, setGlobalDetail }}>{props.children}</GlobalContext.Provider>;
};
