import { useAuthState } from '../../../providers/authProvider';
import { patchSiteReference } from '../../api/invoicesApi';
import { RowInputItem } from '../RowInputItem/RowInputItem';

type SiteRefFieldProps = {
  siteId?: number;
  onBlur?: (value: string) => void;
  readOnly?: boolean;
  siteReference: string;
};

const defaultProps = {
  siteId: null,
  readOnly: false,
};

export const SiteRefField = (props: SiteRefFieldProps) => {
  const authContext = useAuthState();

  const handleCustomerSiteRefChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const siteRef = e?.target?.value;

    if (typeof props.onBlur === 'function') {
      props.onBlur(siteRef);
    }

    if (props.siteId) {
      const result: Common.IResult<string> = await patchSiteReference(
        process.env.REACT_APP_API_URL,
        authContext,
        props.siteId,
        siteRef
      );
      // TODO - Currently incorrectly not returning IResult
      // if (result && result.success) {
      //   return true;
      // }
      return true;
    }
  };

  return (
    <RowInputItem
      background
      readOnly={props.readOnly}
      value={props.siteReference}
      placeholder={props.siteReference || 'Edit'}
      onHandleBlur={handleCustomerSiteRefChange}
    />
  );
};

SiteRefField.defaultProps = defaultProps;
