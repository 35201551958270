import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';

export const LoadingOverlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${variables.zIndexSpinner};
  background-color: ${({ isVisible }) => (isVisible ? 'rgba(255, 255, 255, 0.5)' : 'transparent')};

  & > div {
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  }
`;

export const LoadingWrapper = styled.div`
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${variables.zIndexHighest};

  .redBG {
    background: red;
    color: ${colors.white};
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingSpan = styled.span`
  display: inline-block;
  border: 4px solid ${({ theme }) => theme.loader.borderBg};
  border-top: 4px solid ${({ theme }) => theme.loader.borderActive};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
`;
