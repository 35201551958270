import React, { useState, useEffect, useRef, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import IcomoonReact from 'icomoon-react';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import iconSet from '../../assets/selection.json';

import {
  ExpandableRow,
  ButtonPrimary,
  NoResults,
  Loading,
  Table,
  GridTypeSelector,
  ButtonDownload,
} from '../../common/components';

import { StyledTitle, FilterRowWrapper } from '../../common/components';
import { GridFilter } from '../gridfilter/GridFilter';
import { EssAccountsTypes } from '../../types/account';
import { respondTo } from '../../common/styles/mixins';
import { globalApiParams } from '../../common/api/globals';
import { breakpoints } from '../../common/styles/variables';
import { isOpusBrand } from '../../utils/common';
import { TagManager } from '../utils/analytics/TagManager';

import { MpanDetailsWrapper, MpanDetailsGridWrapper, DownloadButtonsWrapper } from './MpanDetails.styles';
import { DATE_FORMAT } from '../../common/constants';

import { getMeteringPointTarrifData, getMeters, MetersFilterObject } from '../../common/api/meterReadsApi';
import { useAuthState } from '../../providers/authProvider';

import { ToolTip } from '../../common/components/ToolTip/ToolTip';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import DownloadRequestedModal from '../../common/components/DownloadFileModal/DownloadRequestedModal';
import { NotificationBox } from '../health/NotificationBox/NotificationBox';
import { BlankModal } from '../mymessages/modalTemplates/BlankModal';

type MpanDetailsProps = {
  hasElectricityAccount: boolean;
  hasGasAccount: boolean;
  hasRenewableAccount: boolean;
  initialType: string;
  gridData: Array<Api.IMeter>;
  handleTypeChange: Function;
  handleSortChange: Function;
  handleSiteBlur: Function;
  handlePagingChange: any;
  handleCustomerFilterChange: Function;
  handleClearAllClick: Function;
  handleAccountFilterChange: Function;
  handleSiteFilterChange: Function;
  handleMpanMprnFilterChange: Function;
  handleMeterTypeFilterChange: (el: Api.MeterType) => void;
  handleDateFilterChange: Function;
  handleDownload: Function;
  handleTariffDownload: Function;
  customerData: Array<Common.IOption>;
  accountData: Array<Common.IOption>;
  siteData: Array<Common.IOption>;
  mpanMprnData: Array<Common.IOption>;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
  eligibleForSmartMeter: boolean;
};

const defaultProps = {
  gridData: [],
  customerData: [],
  accountData: [],
  siteData: [],
  mpanMprnData: [],
  hasElectricityAccount: false,
  hasGasAccount: false,
  hasRenewableAccount: false,
  initialType: EssAccountsTypes.Electricity,
  isLoading: true,
  hasMoreData: true,
  currentSortField: '',
  currentSortOrder: 'ASC',
  eligibleForSmartMeter: false,
};

const mpanHeaders: Array<Common.HeaderItem> = [
  {
    title: 'Meter type',
    dataValue: 'hh-or-nhh',
    sortField: 'HhOrNhh',
    minWidth: 110,
    responsive: {
      [breakpoints.mobile]: 140,
    },
  },
  {
    title: 'MPAN',
    dataValue: 'mpan-core',
    sortField: 'MpanCore',
    minWidth: 140,
  },
  {
    title: 'Site reference',
    dataValue: 'site-reference',
    sortField: 'SiteReference',
    minWidth: 140,
  },
  {
    title: 'Site Name',
    dataValue: 'site-name',
    enableSort: false,
    className: 'site-name-col',
    minWidth: 100,
    width: 200,
  },
  {
    title: 'Site Address',
    dataValue: 'site-address',
    enableSort: false,
    className: 'site-name-col',
    minWidth: 300,
    width: 400,
  },
];

const StyledTd = styled.td<any>`
  p,
  span {
    width: 50%;
  }

  p {
    display: flex;
    align-items: end;
    margin-top: auto;
  }

  ${respondTo.tablet`
    width: ${({ colWidth }: any) => colWidth};
    p, span {
      margin-left: -10px;
      width: auto;
    }

    p {
      display: block;
    }
  `};
`;

const MpanDetails = (props: MpanDetailsProps) => {
  const authContext = useAuthState();
  const history = useHistory();

  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedType, setSelectedType] = useState<string>(props.initialType);
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const [gridTypes, setGridTypes] = useState<Array<string>>();
  const [isAllSelected, setSelectAll] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const scrollableTable = useRef<any>();
  const [showTarrifInfo, setShowTarrifInfo] = useState<boolean>(false);
  const [tarrifInfo, setTarrifInfo] = useState<Array<Api.IContractTariff>>([]);
  const [noMpanData, setNoMpanData] = useState<boolean>(false);

  const [meterSerialNumbers, setMeterSerialNumbers] = useState<Array<string>>([]);
  const [isDownloadRequestedModalOpened, setIsDownloadRequestedModalOpened] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState<boolean>(false);

  const [isLoadingMPANDetails, setIsLoadingMPANDetails] = useState(false);

  const resolution = window.innerWidth;
  const isMobile = resolution <= 540;
  const isDesktop = !isMobile;

  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };

  const modalTitleText = 'Please contact our smart team for more information.';
  const modalButtonText = 'Ok';
  const contactEmail = 'smart@drax.com';
  const contactNumber = '01473 372430';

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }
  }, [props.gridData]);

  useEffect(() => {
    const types = [];
    if (props.hasElectricityAccount) types.push(EssAccountsTypes.Electricity);
    if (props.hasGasAccount) types.push(EssAccountsTypes.Gas);
    if (props.hasRenewableAccount) types.push(EssAccountsTypes.Renewables);
    setGridTypes(types);
  }, [props.hasRenewableAccount, props.hasElectricityAccount, props.hasGasAccount]);

  useEffect(() => {
    setSelectedType(props.initialType);
  }, [props.initialType]);

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
    setSelectAll(false);
    setCheckedItems([]);
    setNoMpanData(false);
  };

  const handleCustomerFilterChange = (account: Common.IOption): void => {
    clearSelection();
    props.handleCustomerFilterChange(account);
  };

  const handleClearAllClick = (): void => {
    clearSelection();
    props.handleClearAllClick();
  };

  const handleMeterTypeFilterChange = (el: Api.MeterType) => {
    clearSelection();
    props.handleMeterTypeFilterChange(el);
  };

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
    clearSelection();
    props.handleTypeChange(type, { field: 'Month', order: 'DESC' });
  };

  const handleSelectRow = (event: any, row: number | null) => {
    const wrapper = event.target.closest('td')?.classList?.contains?.('select-item'); //!!
    if (wrapper) {
      return;
    }
    if (row !== undefined) {
      TagManager.pushData({
        event: 'Select MPAN',
        selectedRow: row || undefined,
      });
      setSelectedRow(row);
      setExpandRow(false);
      setShowTarrifInfo(false);
    }
  };

  const toggleRow = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, row: number | null) => {
    event.preventDefault();
    if (!expandRow) {
      TagManager.pushData({
        event: 'View MPAN Details',
      });
      getTarrifData(row);
    }
    setExpandRow(!expandRow);
  };

  const handleItemChange = (index: number) => {
    setSelectedRow(null);

    const items = checkedItems.slice(0, checkedItems.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    if (items.length === props.gridData.length) {
      handleSelectAll();
    } else if (isAllSelected) {
      setSelectAll(false);
    }

    setCheckedItems(items);
  };

  const handleSelectAll = () => {
    setSelectedRow(null);

    const select = !isAllSelected;
    setSelectAll(select);
    if (select) {
      const items: Array<number> = props.gridData.map((item, index) => index);
      setCheckedItems(items);
    } else {
      // unselect
      setCheckedItems([]);
    }
  };

  const handlePagingChange = () => {
    if (isAllSelected) {
      setSelectAll(!isAllSelected);
    }
    props.handlePagingChange();
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const onClickSort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearSelection();
    const typeId = e.currentTarget.dataset.value || '';
    let details = sortDetails;
    if (details.field === typeId) {
      if (details.order === 'ASC') details.order = 'DESC';
      else details.order = 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }
    props.handleSortChange(selectedType, details);
  };

  const handleDownload = (option: Common.IOption) => {
    if (option.value === 'csv') {
      TagManager.pushData({
        event: 'Download MPAN details csv',
      });
    } else if (option.value === 'xml') {
      TagManager.pushData({
        event: 'Download MPAN details xml',
      });
    }

    setIsDownloadRequestedModalOpened(true);
    const downloadType = option.value;
    props.handleDownload(downloadType);
  };

  const handleTariffDownload = (option: Common.IOption) => {
    if (option.value === 'csv') {
      TagManager.pushData({
        event: 'Download tariff info csv',
      });
    } else if (option.value === 'xml') {
      TagManager.pushData({
        event: 'Download tariff info xml',
      });
    }

    setIsDownloadRequestedModalOpened(true);
    const downloadType = option.value;
    props.handleTariffDownload(downloadType);
  };

  const getMpanActions = (row: any, index: number) => (
    <div>
      <a href='/' onClick={e => toggleRow(e, index)} className='d-md-inline' style={{ marginLeft: '10px' }}>
        <IcomoonReact
          size={20}
          iconSet={iconSet}
          className='icon-chevron'
          icon={expandRow && selectedRow === index ? 'chevron-up' : 'chevron-down'}
        />
      </a>
    </div>
  );

  const navigateToSubmitMeter = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    history.push('/home/meter-reads');
  };

  const navigateToViewMeterReadHistory = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    history.push('/home/energy-usage/previous-meter-readings');
  };

  const navigateToViewConsumption = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    TagManager.pushData({
      event: 'Launch Usage Data page (MPAN Details)',
    });

    history.push('/home/energy-usage/data');
  };

  const isNHH = (meterTypeCode: string) => {
    return meterTypeCode === 'NHH';
  };

  const isHH_or_SmartMeterType = (meterTypeCode: string) => {
    return meterTypeCode === 'HH' || meterTypeCode === 'SMART';
  };

  const removeDuplicatedTariff = (data: Array<Api.IContractTariff>) =>
    data.filter((curr, i, list) => {
      return (
        i ===
        list.findIndex(el => el['tariff-line-description'] === curr['tariff-line-description'] && el.rate === curr.rate)
      );
    });

  const getTarrifData = async (row: number | null) => {
    setIsLoadingMPANDetails(true);

    const expandedRow: Api.IMeter | undefined = props.gridData.find((_, index) => index === row);

    if (expandedRow) {
      const customerId = userDetail.essCustomerId;

      // get row data
      const mpanCore: unknown = expandedRow['mpan-core'] as unknown;

      const filters: MetersFilterObject = {
        'site-reference': undefined,
        'mpan-core': mpanCore as string,
        'hh-or-nhh': undefined,
      };

      let mpanDetailsDataOriginal: Array<Api.IMeter> = await getMeters(
        process.env.REACT_APP_API_URL,
        authContext,
        customerId,
        filters,
        undefined,
        undefined,
        undefined,
        'Active'
      );

      if (mpanDetailsDataOriginal && mpanDetailsDataOriginal.length > 0) {
        // supply details
        expandedRow['profile-class'] = mpanDetailsDataOriginal[0]['profile-class'];
        expandedRow['meter-timeswitch-code'] = mpanDetailsDataOriginal[0]['meter-timeswitch-code'];
        expandedRow['line-loss-factor-class'] = mpanDetailsDataOriginal[0]['line-loss-factor-class'];
        //
        expandedRow['energisation-status'] = mpanDetailsDataOriginal[0]['energisation-status'];
        expandedRow['supply-date-added'] = mpanDetailsDataOriginal[0]['supply-date-added'];
        expandedRow['supply-date-removed'] = mpanDetailsDataOriginal[0]['supply-date-removed'];
        expandedRow['payment-method'] = mpanDetailsDataOriginal[0]['payment-method'];
        expandedRow['eac'] = mpanDetailsDataOriginal[0]['eac'];
        setNoMpanData(false);
      } else {
        setNoMpanData(true);
      }

      // Create list of serial numbers
      const serialNumbers: Array<string> = [];
      mpanDetailsDataOriginal.map((item: Api.IMeter) => {
        serialNumbers.push(item['meter-serial-number']);
      });
      setMeterSerialNumbers(serialNumbers);

      const tarrifData = await getMeteringPointTarrifData(
        process.env.REACT_APP_API_URL,
        authContext,
        expandedRow['mpan-core']
      );
      const filtered = removeDuplicatedTariff(tarrifData);
      setTarrifInfo(filtered);
    }

    setIsLoadingMPANDetails(false);
  };

  const getRateValue = (tariff: Api.IContractTariff) => {
    return tariff.rate === null && tariff['tariff-purpose'] !== 'Offered' ? 'Variable' : tariff.rate;
  };

  const handleSeeMoreLessTariffInformation = (show: boolean) => {
    TagManager.pushData({
      event: 'View Tariff Info',
    });
    setShowTarrifInfo(show);
  };

  const getExpandableRow = (row: any, rowIndex: number) => (
    <ExpandableRow className={`sub-row ${selectedRow === rowIndex ? 'selected' : ''} ${expandRow ? 'open' : ''}`}>
      <div style={{ padding: '0 0', borderTop: '1px solid black' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            {!noMpanData && (
              <tr className='expandable-content'>
                <StyledTd colWidth={'15%'}>
                  <span>Supply details</span>
                  <ToolTip
                    component={
                      <span className='primary dotted-underline'>{`${row['profile-class']} ${row['meter-timeswitch-code']} ${row['line-loss-factor-class']}`}</span>
                    }
                    text='Profile class, MTC, LLF'
                  />
                </StyledTd>
                <StyledTd colWidth={'15%'}>
                  <span>Energisation status</span>
                  <p className='primary'>{row['energisation-status']}</p>
                </StyledTd>
                <StyledTd colWidth={'10%'}>
                  <ToolTip component={<span className='dotted-underline'>SSD</span>} text='Supply start date' />
                  <p className='primary'>
                    {row['supply-date-added'] && moment(row['supply-date-added']).format(DATE_FORMAT)}
                  </p>
                </StyledTd>
                <StyledTd colWidth={'20%'}>
                  <span>Loss date</span>
                  <p className='primary'>
                    {row['supply-date-removed'] && moment(row['supply-date-removed']).format(DATE_FORMAT)}
                  </p>
                </StyledTd>
                <StyledTd colWidth={'20%'}>
                  <span>Payment method</span>
                  <p className='primary'>{row['payment-method']}</p>
                </StyledTd>
                <StyledTd colWidth={'20%'}>
                  <ToolTip
                    component={<span className='dotted-underline'>EAC (kWh)</span>}
                    text='Estimated annual consumption'
                  />
                  <p className='primary'>{row['eac']}</p>
                </StyledTd>
                <StyledTd colWidth={'20%'}>
                  <ButtonPrimary
                    className={`mt-1 mb-0 d-block ${isNHH(row['hh-or-nhh']) ? '' : 'hidden'}`}
                    title='Submit a meter read'
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => navigateToSubmitMeter(event)}
                  />
                  <ButtonPrimary
                    className={`mt-1 mb-0 d-block ${isNHH(row['hh-or-nhh']) ? '' : 'hidden'}`}
                    title='View meter read history'
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => navigateToViewMeterReadHistory(event)}
                  />
                  <ButtonPrimary
                    className={`mt-1 mb-0 d-block ${isHH_or_SmartMeterType(row['hh-or-nhh']) ? '' : 'hidden'}`}
                    title='View consumption'
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => navigateToViewConsumption(event)}
                  />
                </StyledTd>
              </tr>
            )}
            {noMpanData && <p className='pt-4 pl-2'>No data available</p>}
          </tbody>
        </table>
        <table style={{ width: '100%' }}>
          <tbody>
            {meterSerialNumbers && meterSerialNumbers.length > 0 && (
              <tr className='serial-number-list'>
                <StyledTd>
                  <div className='pb-2'>
                    Meter Serial Numbers
                    {meterSerialNumbers.map((serialNumber, index) =>
                      index === 0 ? (
                        <React.Fragment key={`${index}`}> {serialNumber}</React.Fragment>
                      ) : (
                        <React.Fragment key={`${index}`}>, {serialNumber}</React.Fragment>
                      )
                    )}
                  </div>
                </StyledTd>
              </tr>
            )}
            <tr className='tarrif-info'>
              <StyledTd colWidth={'80%'}>
                Tariff information
                {!showTarrifInfo && (
                  <span className='primary ml-2' onClick={() => handleSeeMoreLessTariffInformation(true)}>
                    {' '}
                    See more
                  </span>
                )}
                {showTarrifInfo && (
                  <span className='primary ml-2' onClick={() => handleSeeMoreLessTariffInformation(false)}>
                    {' '}
                    See less
                  </span>
                )}
              </StyledTd>
              {showTarrifInfo && !!tarrifInfo.length && (
                <>
                  <StyledTd colWidth={'10%'}>Rate(p)</StyledTd>
                  <StyledTd colWidth={'10%'}>Unit</StyledTd>
                </>
              )}
            </tr>
            <>
              {showTarrifInfo &&
                !!tarrifInfo.length &&
                tarrifInfo.map((el, index) => (
                  <tr className='tarrif-info' key={index}>
                    <StyledTd colWidth={'80%'}>{el['tariff-line-description']}</StyledTd>
                    <StyledTd colWidth={'10%'}>{getRateValue(el)}</StyledTd>
                    <StyledTd colWidth={'10%'}>{el['tariff-unit'] || '-'}</StyledTd>
                  </tr>
                ))}
            </>
          </tbody>
        </table>
      </div>
    </ExpandableRow>
  );

  const contactUsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowContactUsModal(true);
  };

  const handleContactUsOnHide = (event: any) => {
    setShowContactUsModal(false);
  };

  const title = 'MPAN details';
  const headers = mpanHeaders;
  const actions = getMpanActions;

  const actionStyles = {
    width: '150px',
    opacity: '1',
  };

  const downloadOptions = [
    { value: 'csv', display: 'CSV' },
    { value: 'xml', display: 'XML' },
  ];

  const meterTypeFilterOptions = [
    {
      display: 'NHH',
      value: 'NHH',
    },
    {
      display: 'HH',
      value: 'HH',
    },
  ];

  const MpanDetailsFilterAdditionalItems = () => {
    const additionalItems = [
      <DownloadButtonsWrapper key='mapdetailsAdditionalItem1' className='additional-buttons'>
        <ButtonDownload
          disabled={props.gridData.length === 0}
          className='additional-buttons-spacing pr-4 download-btn'
          title='Download Tariff info'
          options={downloadOptions}
          onClick={handleTariffDownload}
          outline={true}
          icon='upload'
        />
      </DownloadButtonsWrapper>,
      <DownloadButtonsWrapper key='mapdetailsAdditionalItem2'>
        <ButtonDownload
          disabled={props.gridData.length === 0}
          className='additional-buttons-spacing download-btn'
          title='Download MPAN details'
          options={downloadOptions}
          onClick={handleDownload}
          outline={true}
          icon='upload'
        />
      </DownloadButtonsWrapper>,
    ];

    return additionalItems;
  };

  return (
    <MpanDetailsWrapper>
      <FilterRowWrapper>
        <StyledTitle>{title}</StyledTitle>
      </FilterRowWrapper>
      {isMobile && (
        <div>
          <GridFilter
            showSites={false}
            showDate={false}
            showMpanMprn={false}
            showClearAll={false}
            siteData={props.siteData}
            showAccount={isOpusBrand}
            showMeterType={true}
            showDownload={isOpusBrand}
            selectedType={selectedType}
            accountData={props.accountData}
            handleDownload={handleDownload}
            customerData={props.customerData}
            mpanMprnData={props.mpanMprnData}
            meterTypeFilterOptions={meterTypeFilterOptions}
            downloadOptions={downloadOptions}
            handleClearAllClick={handleClearAllClick}
            enableDownloadBtn={checkedItems.length > 0}
            handleDateFilterChange={props.handleDateFilterChange}
            handleSiteFilterChange={props.handleSiteFilterChange}
            handleAccountFilterChange={props.handleAccountFilterChange}
            handleCustomerFilterChange={handleCustomerFilterChange}
            handleMpanMprnFilterChange={props.handleMpanMprnFilterChange}
            handleMeterTypeFilterChange={handleMeterTypeFilterChange}
            renderAdditionalListItems={MpanDetailsFilterAdditionalItems}
          />
        </div>
      )}

      <GridFilter
        showSites={false}
        showDate={false}
        showMpanMprn={false}
        showClearAll={false}
        siteData={props.siteData}
        showAccount={isOpusBrand}
        showMeterType={true}
        showDownload={isOpusBrand}
        selectedType={selectedType}
        accountData={props.accountData}
        handleDownload={handleDownload}
        customerData={props.customerData}
        mpanMprnData={props.mpanMprnData}
        meterTypeFilterOptions={meterTypeFilterOptions}
        downloadOptions={downloadOptions}
        handleClearAllClick={handleClearAllClick}
        enableDownloadBtn={checkedItems.length > 0}
        handleDateFilterChange={props.handleDateFilterChange}
        handleSiteFilterChange={props.handleSiteFilterChange}
        handleAccountFilterChange={props.handleAccountFilterChange}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleMpanMprnFilterChange={props.handleMpanMprnFilterChange}
        handleMeterTypeFilterChange={handleMeterTypeFilterChange}
        renderAdditionalListItems={MpanDetailsFilterAdditionalItems}
      />

      <div className={props.eligibleForSmartMeter ? 'col-md-12 pl-0 pr-0' : 'col-md-12 col-xl-3'}>
        {props.eligibleForSmartMeter && (
          <NotificationBox
            showButton={true}
            buttonClassName='button'
            center={true}
            boxText='You have meters that are eligible for a smart meter upgrade'
            buttonText='Contact us'
            onClick={contactUsClick}
          ></NotificationBox>
        )}
      </div>

      <GridTypeSelector initialType={selectedType} types={gridTypes} handleTypeChange={handleTypeChange} />

      {!props.isLoading && props.gridData.length === 0 && <NoResults />}

      <MpanDetailsGridWrapper>
        {props.gridData.length > 0 && (
          <>
            <InfiniteScroll
              dataLength={props.gridData.length}
              next={handlePagingChange}
              hasMore={props.hasMoreData}
              loader={''}
              scrollableTarget='scrollableDiv'
              scrollThreshold={0.9}
            >
              <div
                id='scrollableDiv'
                ref={scrollableTable}
                className='table-responsive table-fixed fixed-column columns-1'
              >
                <Table
                  isSelectable={false}
                  headers={headers}
                  actions={actions}
                  rows={props.gridData}
                  isChecked={isChecked}
                  sortDetails={sortDetails}
                  onClickSort={onClickSort}
                  isRowExpanded={expandRow}
                  actionStyles={actionStyles}
                  isAllSelected={isAllSelected}
                  selectedRowIndex={selectedRow}
                  expandableRow={getExpandableRow}
                  handleSelectAll={handleSelectAll}
                  handleCheckRow={handleItemChange}
                  handleSelectRow={handleSelectRow}
                  className='list table plain text-left'
                />
              </div>
            </InfiniteScroll>
          </>
        )}

        {props.isLoading || (isLoadingMPANDetails && <Loading overlay nowait />)}
      </MpanDetailsGridWrapper>

      {isDownloadRequestedModalOpened && (
        <DownloadRequestedModal
          show={isDownloadRequestedModalOpened}
          onClose={() => {
            setIsDownloadRequestedModalOpened(false);
          }}
        />
      )}

      <BlankModal
        show={showContactUsModal}
        onHide={handleContactUsOnHide}
        titleText={modalTitleText}
        buttonText={modalButtonText}
        contactEmail={contactEmail}
        contactNumber={contactNumber}
      ></BlankModal>
    </MpanDetailsWrapper>
  );
};

MpanDetails.defaultProps = defaultProps;

export default MpanDetails;
