// Modules
import React from 'react';
// Styles
import { HealthWrapper, ServiceUpdateHealthWrapper } from './ServiceUpdateHealthStyles';
import ServiceUpdate from '../serviceupdate/ServiceUpdate';
import { Icon } from '../../common/components/Icon/Icon';
import { CircleCheck } from '../icons';
export type UpdateType = 'High' | 'Medium' | 'Low' | '';
export type UpdateTypeHealth = 'High' | 'Medium' | 'NoIssues' | undefined;
export type ExpandedDetails = {
  type: UpdateTypeHealth;
  expanded: boolean;
};
type ServiceUpdateHealthProps = {
  messages: Array<ServiceUpdateType>;
  type: UpdateTypeHealth;
  className?: string;
  handleHealthClick?: (type: ExpandedDetails) => void;
  expandedType: ExpandedDetails;
};
type ServiceUpdateType = {
  page: string;
  type: UpdateType;
  message: string;
};
const defaultProps = {
  messages: [],
  expandedType: null,
};
const ServiceUpdateHealth = (props: ServiceUpdateHealthProps) => {
  const messages = props.messages;
  const handleHealthClick = () => {
    if (!props.expandedType) return;
    let newExpandedState = !props.expandedType.expanded;
    if (props.expandedType.type != props.type) {
      newExpandedState = true;
    }
    const type: ExpandedDetails = {
      type: props.type,
      expanded: newExpandedState,
    };
    if (props.handleHealthClick) props.handleHealthClick(type);
  };
  const convertRouteToPageName = (page: string) => {
    switch (page) {
      case 'HomePage':
        return 'Home Page';
      case 'invoices':
        return 'Invoices';
      case 'makeapayment':
        return 'Make A Payment';
      case 'energyusagedata':
        return 'Energy Usage Data';
      case 'messages':
        return 'Messages';
      case 'paymenthistory':
        return 'Payment History';
      case 'downloadhhdata':
        return 'Download HH Data';
      case 'smartmeterconsent':
        return 'Smart Meter Consent';
      case 'mpandetails':
        return 'Mpan Details';
      case 'submitmeterreads':
        return 'Submit Meter Reads';
      case 'meterreadhistory':
        return 'Meter Read History';
      case 'contractsummary':
        return 'Contract Summary';
      case 'sitedetails':
        return 'Site Details';
      case 'movingpremises':
        return 'Moving Premises';
      case 'exclusiveoffers':
        return 'Exclusive Offers';
      case 'tpiadmin':
        return 'TPI Admin';
      case 'tpilandingpage':
        return 'TPI Landing Page';
      case 'loa':
        return 'Loa';
      default:
        return page;
    }
  };
  return (
    <ServiceUpdateHealthWrapper {...props} onClick={handleHealthClick}>
      {messages && (
        <>
          {props.type === 'NoIssues' && props.messages.length === 0 && (
            <HealthWrapper>
              <div className={`health healthDesktop healthy`}>
                <div className={`d-flex`}>
                  <div className='mr-4 px-2 py-1'>
                    <h2 className='header pt-1 mt-0 mb-0 text-center'>
                      <Icon icon={CircleCheck} size={35} />
                      <span className='pl-2'>No issues or information notices</span>
                    </h2>
                  </div>
                </div>
              </div>
            </HealthWrapper>
          )}
          {props.type !== 'NoIssues' && props.messages.length > 0 && (
            <>
              {props.messages.length > 0 && (
                <>
                  <HealthWrapper>
                    <div className={`health healthDesktop ${props.type}`}>
                      <div className={`d-flex`}>
                        <div className='mr-4 px-2 py-1 w-100'>
                          <h2 className='d-flex header pt-1 mt-0 mb-0'>
                            {props.type === 'High' && (
                              <span className='badge badge-pill badge-danger'>{props.messages.length}</span>
                            )}
                            {props.type === 'Medium' && (
                              <span className='badge badge-pill badge-warning'>{props.messages.length}</span>
                            )}
                            <span className='pl-2'>{props.type === 'Medium' ? 'Information notices' : 'Issues'}</span>
                            <Icon
                              size={15}
                              className='ml-auto chevron'
                              icon={
                                props.expandedType?.expanded && props.type === props.expandedType?.type
                                  ? 'chevron-up'
                                  : 'chevron-down'
                              }
                            />
                          </h2>
                        </div>
                      </div>
                      {props.type === props.expandedType.type && props.expandedType.expanded && (
                        <table className='mt-2'>
                          <thead>
                            <th>Page</th>
                            <th>Message</th>
                          </thead>
                          <tbody>
                            {props.messages.map((message, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <tr className='update'>
                                    <td className='pr-4 text-nowrap'>{convertRouteToPageName(message.page)}</td>
                                    <td className='w-100 py-1'>
                                      <div className='d-flex test align-self-center'>
                                        <ServiceUpdate type={message.type} message={message.message} />
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </HealthWrapper>
                </>
              )}
            </>
          )}
        </>
      )}
    </ServiceUpdateHealthWrapper>
  );
};
ServiceUpdateHealth.defaultProps = defaultProps;
export default ServiceUpdateHealth;
