import React, { useEffect } from 'react';
import { useState } from 'react';

import FormElement from '../../../common/components/FormElement';
import { DateSelectionWrapper } from './styles/DateSelection.styles';
import { ButtonPrimary, CustomDatePicker, Icon } from '../../../common/components';
import moment from 'moment';
import { REVERSED_DATE_FORMAT_HYPHEN } from '../../../common/constants';

type DateSelectionProps = {
  handleConfirmClick: Function;
  mpansAvailable: boolean;
  resetData: boolean;
};

const defaultProps = {
  mpansAvailable: false,
};

export const DateSelection = (props: DateSelectionProps) => {
  const [datePreference, setDatePreference] = useState('');
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const startDateWindowMin = moment().subtract(2, 'years').startOf('month').format(REVERSED_DATE_FORMAT_HYPHEN);
  const todaysDate = moment().format(REVERSED_DATE_FORMAT_HYPHEN);
  const isDisabled = props.mpansAvailable && startDate !== '' && endDate !== '';

  useEffect(() => {
    setDatePreference('');
    setStartDate('');
    setEndDate('');
  }, [props.resetData]);

  const handleSetDateRange = async (preference: string) => {
    if (preference === 'Last 12 months') {
      setDatePreference('Last 12 months');
      setStartDate(moment().subtract(12, 'months').startOf('month'));
      setEndDate(moment());
    } else if (preference === 'Last 6 months') {
      setDatePreference('Last 6 months');
      setStartDate(moment().subtract(6, 'months').startOf('month'));
      setEndDate(moment());
    } else {
      setDatePreference('Custom date range');
      setStartDate('');
      setEndDate('');
    }
  };

  const checkMaxEndDate = () => {
    let startDateTemp = startDate.format(REVERSED_DATE_FORMAT_HYPHEN);

    const endDateWindowMax = moment(startDateTemp).add(11, 'M').endOf('month');
    if (endDateWindowMax && endDateWindowMax <= moment(todaysDate)) {
      return endDateWindowMax.format(REVERSED_DATE_FORMAT_HYPHEN);
    } else {
      return moment(todaysDate).format(REVERSED_DATE_FORMAT_HYPHEN);
    }
  };

  const handleConfirmClick = (event: any) => {
    event.preventDefault();
    props.handleConfirmClick(startDate, endDate);
  };

  return (
    <>
      <DateSelectionWrapper {...props}>
        <div className='DateSelectionOverview'>
          <div className='mt-0 modal-title'>Select date range</div>
          <div className='container'>
            <div className='left'>
              <FormElement.RadioColumn
                id=''
                className={''}
                label={''}
                name={''}
                elements={['Last 12 months', 'Last 6 months', 'Custom date range']}
                value={datePreference}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSetDateRange(e.target.value);
                }}
              />
            </div>
            {datePreference === 'Custom date range' && (
              <div className='right'>
                <p className='mb-3'>
                  <i>Note that you can only request data for a maximum of 12 consecutive months.</i>
                </p>
                <div className='row justify-content-between'>
                  <div className='pt-1 date-picker'>
                    <div>From</div>
                    <CustomDatePicker
                      date={startDate}
                      format={'MMM-YYYY'}
                      showIcon={true}
                      minDate={startDateWindowMin}
                      maxDate={todaysDate}
                      onChange={(e: any) => {
                        setStartDate(e);
                        setEndDate('');
                      }}
                      onlyMonthPicker={true}
                    />
                  </div>
                  <div className='pt-1 date-picker'>
                    <div>To</div>
                    <CustomDatePicker
                      date={endDate ? endDate : ''}
                      format={'MMM-YYYY'}
                      showIcon={true}
                      minDate={startDate}
                      maxDate={startDate ? checkMaxEndDate() : ''}
                      onChange={(e: any) => {
                        setEndDate(e.toLastOfMonth());
                      }}
                      onlyMonthPicker={true}
                      disabled={!startDate}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={`${datePreference === 'Custom date range' ? 'confirmButton pt-4' : 'confirmButton'}`}>
            <ButtonPrimary className='m-0' title='Confirm' onClick={handleConfirmClick} disabled={!isDisabled} />
          </div>
        </div>
        <div className='DateSelectionOverviewMobile'>
          <div className='mt-0 modal-title'>Select date range</div>
          <div className=''>
            <div className=''>
              <FormElement.RadioColumn
                id=''
                className={''}
                label={''}
                name={''}
                elements={['Last 12 months', 'Last 6 months', 'Custom date range']}
                value={datePreference}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSetDateRange(e.target.value);
                }}
              />
            </div>
            {datePreference === 'Custom date range' && (
              <div className=''>
                <p className='mb-3'>
                  <i>Note that you can only request data for a maximum of 12 consecutive months.</i>
                </p>
                <div className='row justify-content-between'>
                  <div className='pt-1 date-picker'>
                    <div>From</div>
                    <CustomDatePicker
                      date={startDate}
                      format={'MMM-YYYY'}
                      showIcon={true}
                      minDate={startDateWindowMin}
                      maxDate={todaysDate}
                      onChange={(e: any) => {
                        setStartDate(e);
                        setEndDate('');
                      }}
                      onlyMonthPicker={true}
                    />
                  </div>
                  <div className='pt-1 date-picker'>
                    <div>To</div>
                    <CustomDatePicker
                      date={endDate ? endDate : ''}
                      format={'MMM-YYYY'}
                      showIcon={true}
                      minDate={startDate}
                      maxDate={startDate ? checkMaxEndDate() : ''}
                      onChange={(e: any) => {
                        setEndDate(e.toLastOfMonth());
                      }}
                      onlyMonthPicker={true}
                      disabled={!startDate}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={`${datePreference === 'Custom date range' ? 'confirmButton pt-4' : 'confirmButton'}`}>
            <ButtonPrimary className='m-0' title='Confirm' onClick={handleConfirmClick} disabled={!isDisabled} />
          </div>
        </div>
      </DateSelectionWrapper>
    </>
  );
};

DateSelection.defaultProps = defaultProps;
