// ********** REMINDER **********
// When you are adding icon here, don't forget to add same icon
// to another theme file
import { Power } from '../../../components/icons';
export const iconsOpus = {
  help: 'question-circle',
  profile: 'user',

  menuMessages: 'envelope',
  menuInvoicePayment: 'cash-pound',
  menuData: 'signal',
  menuMeter: 'menu3',
  menuAccount: 'cog',
  menuOffers: 'trophy',
  menuMPAN: Power,
  menuPayment: 'credit-card',
  menuReports: 'clipboard-check',
  menuContract: 'clipboard-pencil',
  menuInProgress: 'checkmark-circle',
  filezip: 'file-zip',
  userplus: 'user-plus',
  download: 'download2',
};
