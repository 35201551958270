export const msalConfig = {
  auth: {
    // DRAX AZURE ACTIVE DIRECTORY
    clientId: '1e0a6bda-91be-466b-91f2-614a12b79667',
    authority: process.env.REACT_APP_AD_AUTHORITY,
    redirectUri: process.env.REACT_APP_AD_REDIRECTURI,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://inc-ad-api/tasks.read'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
};
