import { ReactNode, useState } from 'react';
import { HomeContext } from './homeContext';
import { IHomeContext } from './homeDetail';

export interface IHomeProviderProps {
  value: IHomeContext;
  children?: ReactNode;
}

export const HomeProvider = (props: IHomeProviderProps) => {
  const [homeState, setHomeState] = useState<IHomeContext>(props.value);

  return <HomeContext.Provider value={{ homeState, setHomeState }}>{props.children}</HomeContext.Provider>;
};
