import Modal from 'react-bootstrap/Modal';

import * as colours from '../../../../common/styles/colors';
import { ButtonPrimary } from '../../../../common/components';
import { Icon } from '../../../../common/components';

type SignedOutModalProps = {
  title?: string;
  message?: string;
  modalSize: 'sm' | 'lg' | 'xl' | undefined;
  onHide: () => void;
  show: boolean;
};

const defaultProps = {
  title: 'We’ve had to sign you out',
  message: `Another user has signed into the portal so we've had to end your session.`,
  onHide: undefined,
  show: false,
  modalSize: 'lg',
};

export const SignedOut = (props: SignedOutModalProps) => {
  return (
    <>
      <Modal
        animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
        className={'custom-modal mid-size-modal'}
        show={props.show}
        onHide={props.onHide}
        size={props.modalSize}
        centered
      >
        <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
          <button className='close' onClick={() => props.onHide()}>
            <span aria-hidden='true'>
              <Icon color={colours.trueblack} size={20} icon='cross2' />
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>{props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPrimary route='/signout' title='Return to login' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

SignedOut.defaultProps = defaultProps;
