import React, { useState, useRef, useEffect } from 'react';
// Using the react-modern-datepicker from:
// https://github.com/shahabyazdi/react-multi-date-picker
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import { DATE_FORMAT } from '../../constants';

import { CustomDatePickerWrapper } from './CustomDatePicker.styles';

const dateUtils = require('../../../components/utils/date/date');

type CustomDatePickerProps = {
  date?: Date | DateObject | DateObject[] | string | undefined;
  className?: string;
  format?: string;
  months?: Array<Common.IFormatMonths>;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  minDate?: string;
  maxDate?: Date | string;
  showOtherDays?: boolean;
  multiple?: boolean;
  onlyMonthPicker?: boolean;
  onlyYearPicker?: boolean;
  range?: boolean;
  placeHolder?: string;
  type?: string;
  hasError?: boolean;
  showIcon?: boolean;
  fixMainPosition?: boolean;
  disabled: boolean;
};

const defaultProps = {
  date: '',
  className: 'datepicker',
  format: DATE_FORMAT,
  months: [],
  onChange: undefined,
  minDate: '',
  maxDate: '',
  showOtherDays: true,
  multiple: false,
  onlyMonthPicker: false,
  onlyYearPicker: false,
  range: false,
  placeHolder: '',
  type: 'input',
  showIcon: false,
  fixMainPosition: false,
  disabled: false,
};

export const CustomDatePicker = (props: CustomDatePickerProps) => {
  const [value, setValue] = useState<Date | DateObject | DateObject[] | string | undefined>();
  const datePickerRef = useRef<any>();

  useEffect(() => {
    setValue(props.date);
  }, [props.date]);

  const closeCalendar = (item: any) => {
    if (datePickerRef.current != null) {
      datePickerRef.current.closeCalendar();
    }
  };

  const handleOnChange = (item: any) => {
    setValue(item);
    if (props.onChange) {
      props.onChange(item);
    }
  };

  return (
    <CustomDatePickerWrapper>
      <DatePicker
        className={props.className}
        inputClass={`rmdp-input ${props.hasError ? 'error' : ''}`}
        value={value}
        format={props.format}
        months={dateUtils.formatMonths}
        onChange={handleOnChange}
        minDate={props.minDate}
        maxDate={props.maxDate}
        placeholder={props.placeHolder}
        showOtherDays={props.showOtherDays}
        multiple={props.multiple}
        onlyMonthPicker={props.onlyMonthPicker}
        onlyYearPicker={props.onlyYearPicker}
        range={props.range}
        ref={datePickerRef}
        render={props.showIcon ? <InputIcon /> : this}
        fixMainPosition={props.fixMainPosition}
        editable={false}
        disabled={props.disabled}
      >
        <span className='closeDatePicker' onClick={closeCalendar} />
      </DatePicker>

      {props.hasError && <div className='error-message'>Required</div>}
    </CustomDatePickerWrapper>
  );
};

CustomDatePicker.defaultProps = defaultProps;
