import { css } from 'styled-components';

import * as variables from '../variables';
import * as colors from '../colors';
import { scrollbars, hideScrollbars, gradientToBottom } from '../mixins';

export const tableStyles = css`
  .table {
    color: ${colors.oxfordBlue};

    &.plain {
      td,
      th {
        border: none;
      }

      th {
        font-weight: 400;
      }

      tbody {
        /* Temporary removed */
        /* ${gradientToBottom}; */
      }
    }
  }

  .table-responsive {
    ${scrollbars};
    min-height: ${variables.menuWidth};

    th,
    td {
      line-height: initial;
      white-space: nowrap;
    }

    th {
      background-color: ${colors.white};
    }

    td {
      padding: ${variables.spacingSmall} 0.75rem;
    }

    &.table-fixed {
      display: inline-block;
      height: 70vh;
      overflow: auto;

      table th {
        background-color: ${colors.white};
        // position: sticky;
        top: 0;
        // z-index: ${variables.zIndexHighest}; // TODO: need testing
        z-index: 1;
        vertical-align: bottom;
      }
    }

    &.fixed-column {
      // th:first-child {
      //   z-index: ${variables.zIndexHighest + 1} !important; // TODO: need testing
      // }

      &.columns {
        &-1 {
          th,
          td {
            &:first-child {
              background-color: ${colors.white};
              left: 0;
              // position: sticky;
              z-index: ${variables.zIndexHigh};
            }
          }
        }

        /*
        More than one fixed column will not work without setting hardcoded widths.
        And/or use of JS
        */
        &-2 {
          th,
          td {
            &:nth-child(1) {
              background-color: ${colors.white};
              left: 0;
              position: sticky;
              width: 3.75em;
              z-index: ${variables.zIndexHigh};
            }

            &:nth-child(2) {
              background-color: ${colors.white};
              left: 3.75em;
              position: sticky;
              z-index: ${variables.zIndexHigh};
            }
          }

          th:nth-child(-n + 2) {
            z-index: ${variables.zIndexHigh + 1} !important;
          }
        }

        &-3 {
          th,
          td {
            &:nth-child(-n + 3) {
              background-color: ${colors.white};
              left: 0;
              position: sticky;
              z-index: ${variables.zIndexHigh};
            }

            &:nth-child(1) {
              left: 0;
              width: 3.75em;
            }

            &:nth-child(2) {
              left: 3.75em;
              width: 3.75em;
            }

            &:nth-child(3) {
              left: 6.2em;
            }
          }

          th:nth-child(-n + 3) {
            z-index: ${variables.zIndexHigh + 1} !important;
          }
        }
      }
    }

    &.fixed-end-column {
      td {
        &:last-child {
          right: 0;
          position: sticky !important;
          z-index: ${variables.zIndexNormal};
        }
      }
    }

    &.scroll-bars-at-top {
      transform: rotateX(180deg);
      overflow-x: auto;

      table {
        transform: rotateX(180deg);
      }
    }

    &.wrap {
      tbody td {
        white-space: initial;
      }
    }
  }

  .selected {
    td {
      background-color: ${({ theme }) => theme.bg.secondary} !important;
    }
  }

  .sub-row {
    &.open {
      td {
        background-color: ${({ theme }) => theme.bg.secondary} !important;

        &:first-child {
          border-bottom-left-radius: ${variables.smallRadius};
        }

        &:last-child {
          border-bottom-right-radius: ${variables.smallRadius};
        }
      }
    }
  }

  .top-scroll {
    ${scrollbars};
    overflow: auto;
    overflow-y: hidden;

    div {
      padding-top: 1px;
    }

    + .table-responsive {
      ${hideScrollbars};

      &.no-scroll {
        overflow-x: hidden !important;
      }
    }
  }
`;
