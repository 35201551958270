import { get } from '../../helpers/axiosWrapper';
import { isDraxBrand } from '../../utils/common';

export const getServiceMessage = async (
  url: string | undefined,
  context: any,
  pageName: string
): Promise<Common.IResult<Api.IServiceMessageData>> => {
  let headers = {};
  if (isDraxBrand) {
    return await get(url + `/content/servicemessages/${pageName}`, { headers }, context);
  } else {
    return await get(url + `/content/api/servicemessages/${pageName}`, { headers }, context);
  }
};
