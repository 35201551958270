// Modules
import moment from 'moment';

// Types
import { Ranges } from '../common/components/DateFilterModal';
import { REVERSED_DATE_FORMAT_HYPHEN } from '../common/constants';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getMonthString = (month: number, short: boolean = false): string => {
  const selectedMonth = months[--month];

  if (!short) {
    return selectedMonth;
  }
  if (selectedMonth === 'September') {
    return selectedMonth.substr(0, 4);
  }
  return selectedMonth.substr(0, 3);
};

export const getDateFromRange = (range: Ranges): string[] => {
  let startDate = moment().format(REVERSED_DATE_FORMAT_HYPHEN);
  let endDate = '';

  // We subtract one less months
  // Because we are including current month
  // So we won't have 7 months in range for "6 months" period
  switch (range) {
    case Ranges['2years']:
      endDate = moment().subtract(23, 'months').startOf('month').format(REVERSED_DATE_FORMAT_HYPHEN);
      break;
    case Ranges['1year']:
      endDate = moment().subtract(11, 'months').startOf('month').format(REVERSED_DATE_FORMAT_HYPHEN);
      break;
    case Ranges['6months']:
      endDate = moment().subtract(5, 'months').startOf('month').format(REVERSED_DATE_FORMAT_HYPHEN);
      break;
    case Ranges['daily']:
    case Ranges['monthly']:
      break;
    default:
      console.error(`Received unhandled range value: "${range}"`);
  }
  return [startDate, endDate];
};
