const Offer = ({ width, height, className, fillColor }: OfferProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 31 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16 31H1L4 19V9H24V19'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M9 14V6C9 3.2385 11.2385 1 14 1C16.7615 1 19 3.2385 19 6V14'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M24 31C27.3137 31 30 28.3137 30 25C30 21.6863 27.3137 19 24 19C20.6863 19 18 21.6863 18 25C18 28.3137 20.6863 31 24 31Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M24 22.5V25H26.5'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type OfferProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Offer.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2a3745',
};

export default Offer;
