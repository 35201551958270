import styled from 'styled-components';

import { fontSizeSmall, fontSizeMedium } from '../../common/styles/variables';
import { respondTo } from '../../common/styles/mixins';
import { manateeGray, hydroBlue, draxDarkBlue, successLight } from '../../common/styles/colors';
import * as colors from '../../common/styles/colors';

export const ConfigurableChartWrapper = styled.div`
  .select__date {
    & > a {
      font-weight: 400;
      padding: 0 !important;
      margin: 0;

      & > span {
        color: ${({ theme }) => theme.text.secondary};
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }

    .half-hourly__btn {
      margin-left: 61px;
    }
    .download__btn {
      ${respondTo.mobile`
        margin-right: 1rem;
      `}
    }
  }

  .select__customer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
      font-size: ${fontSizeSmall};
      ${respondTo.mobile`
        font-size: ${fontSizeMedium};
      `}
    }
  }

  .chart__wrapper {
    .bottom-date {
      left: 0;
      right: 0;
      margin: auto;
      color: ${manateeGray};
      font-size: ${fontSizeMedium};
    }

    .points {
      padding-left: 30px;

      & > div {
        align-items: center;
      }

      .actual .marker {
        background-color: ${hydroBlue};
      }
      .estimated .marker {
        background-color: ${draxDarkBlue};
      }
      .month-to-date .marker {
        background-color: ${successLight};
      }
      .marker {
        width: 18px;
        height: 18px;
        border-radius: 6px;
        font-size: ${fontSizeMedium};
      }
    }
  }

  .access-link {
    color: ${({ theme }) => theme.text.secondary};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .dropdown__list {
    a {
      background-color: ${colors.white} !important;
      color: ${colors.oxfordBlue} !important;

      &:hover {
        background-color: ${({ theme }) => theme.primary.bg} !important;
        color: ${colors.white} !important;
      }
    }
  }
`;
