import styled from 'styled-components';

import * as variables from '../../../common/styles/variables';
import * as colors from '../../../common/styles/colors';
import * as mixins from '../../../common/styles/mixins';
import { respondTo } from '../../../common/styles/mixins';

export const ResultsWrapper = styled.div<any>`
  position: absolute;
  background: ${colors.white};
  width: 100%;
  z-index: ${variables.zIndexHighest + 2};
  // min-height: calc(100vh - 132px);
  min-height: ${() => `${document.getElementById('root')?.scrollHeight}px`}; //temporary fix. has to be tested
  border-radius: ${variables.globalRadius};
  top: 107px;
  padding: ${({ isIndexPage }) => (isIndexPage ? '35px' : '0 35px 0 0')};
  margin-top: ${({ isIndexPage }) => (isIndexPage ? '25px' : '0')};

  .search-loader {
    top: calc(20vh + 107px);
  }

  ${respondTo.tablet`
    top: 132px;
    right: 4px;
    margin-top: 0;
    width: calc(100% - ${variables.menuWidth} - ${variables.spacingMedium});
  `}

  .close {
    outline: none;
    margin-top: 10px;
    margin-right: 10px;
  }

  .primary {
    text-align: center;

    ${respondTo.tablet`
      text-align: left;
    `};
  }

  h3 {
    font-size: ${variables.fontSizeBase};
    color: ${({ theme }) => theme.text.secondary};
    margin: ${variables.spacingMedium} 0 ${variables.spacingMedium};
  }

  ul {
    list-style: disc;
    list-style-position: inside;
    font-size: ${variables.fontSizeMedium};

    li {
      margin-bottom: ${variables.spacingXSmall};
    }
  }
`;

export const SearchResultsWrapper = styled.div`
  ${mixins.gradientToBottom};
  color: ${colors.black};
  padding-bottom: 50px;
`;
