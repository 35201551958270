import { get } from '../../helpers/axiosWrapper';
import { isDraxBrand } from '../../utils/common';

const apiUrl = isDraxBrand ? '/content/exclusiveoffers' : '/content/api/exclusiveoffers';

export const exclusiveoffers = async (
  url: string | undefined,
  context: any,
  userType: string
): Promise<Common.IResult<Array<Api.IExclusiveOffersData>>> => {
  let headers = {
    userType: userType,
  };
  return await get(url + apiUrl, { headers }, context);
};
