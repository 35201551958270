const PadlockClosed = ({ width, height, className, fillColor }: PadlockClosedProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 16 24'
    xmlns='http://www.w3.org/2000/svg'
    fill={fillColor}
  >
    <path
      d='M8.00006 13.44C7.81182 13.44 7.52947 13.44 7.34124 13.536C6.5883 13.728 5.92947 14.4 5.74124 15.168C5.45888 16.224 5.83535 17.088 6.49418 17.664L5.74124 19.584C5.553 19.872 5.83535 20.16 6.11771 20.16H9.88241C10.1648 20.16 10.4471 19.872 10.353 19.488L9.60006 17.568C10.0706 17.088 10.4471 16.512 10.4471 15.744C10.353 14.496 9.31771 13.44 8.00006 13.44ZM9.22359 19.2H6.77653L7.52947 17.376L7.05888 16.992C6.58829 16.608 6.49418 16.032 6.58829 15.456C6.68241 14.976 7.05888 14.688 7.52947 14.496C7.71771 14.4 7.90594 14.4 8.00006 14.4C8.753 14.4 9.41182 15.072 9.41182 15.84C9.41182 16.224 9.22359 16.608 8.94124 16.896L8.47065 17.376L9.22359 19.2ZM14.5883 9.6H13.1765V5.28C13.1765 2.4 10.8236 0 8.00006 0C5.17653 0 2.82359 2.4 2.82359 5.28V9.6H1.41183C0.658884 9.6 6.10352e-05 10.272 6.10352e-05 11.04V22.56C6.10352e-05 23.328 0.658884 24 1.41183 24H14.5883C15.3412 24 16.0001 23.328 16.0001 22.56V11.04C16.0001 10.272 15.3412 9.6 14.5883 9.6ZM3.76477 5.28C3.76477 2.88 5.64712 0.96 8.00006 0.96C10.353 0.96 12.2354 2.88 12.2354 5.28V9.6H3.76477V5.28ZM15.0589 22.56C15.0589 22.848 14.8706 23.04 14.5883 23.04H1.41183C1.12947 23.04 0.941237 22.848 0.941237 22.56V11.04C0.941237 10.752 1.12947 10.56 1.41183 10.56H14.5883C14.8706 10.56 15.0589 10.752 15.0589 11.04V22.56Z'
      fill={fillColor}
    />
  </svg>
);

export type PadlockClosedProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

PadlockClosed.defaultProps = {
  width: 40,
  height: 40,
  fillColor: '#216EF2',
};

export default PadlockClosed;
