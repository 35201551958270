import styled from 'styled-components';

import * as variables from '../../../common/styles/variables';
import * as colors from '../../../common/styles/colors';
import * as mixins from '../../../common/styles/mixins';
import { respondTo } from '../../../common/styles/mixins';

export const BlockWrapper = styled.div`
  ${mixins.boxShadowPrimary};
  background-color: ${({ theme }) => theme.bg.secondary};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingSmall};
  margin-bottom: ${variables.spacingMedium};

  ${respondTo.desktop`
    padding: ${variables.spacingMedium};
  `};

  h4 {
    font-size: ${variables.fontSizeLargeX};
    margin: 0 0 ${variables.spacingSmall};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary.bg};
  }

  .highlight {
    color: ${({ theme }) => theme.primary.bg};
  }

  .opposite-align {
    ${respondTo.desktop`
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `};
  }

  .info-text {
    margin: 0 0 ${variables.spacingSmall};

    ${respondTo.desktop`
      width: 45%;
      margin: 0;
    `};
  }

  .submit-btn {
    display: inline-block;
    margin: 0;

    ${respondTo.desktop`
      min-width: 250px;
    `};
  }

  .multisiteModeLayout {
    padding-top: ${variables.spacingMedium};

    .newContactDetailsWrapper {
      padding-top: ${variables.spacingMedium};

      ${respondTo.desktop`
        padding: 0;
      `};
    }

    ${respondTo.desktop`
      display: flex;
      flex-direction: row;

      p,
      .formRowWrapper {
        width: 85%;
      }
    `};
  }

  .dateField {
    flex-direction: row !important;
  }

  .formAddress {
    width: 100%;
      padding-top ${variables.spacingSmall};

      ${respondTo.desktop`
        width: 50%;
      `};
    }
  }

  .formRowWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: ${variables.spacingSmall};

    &.withCheckbox {
      margin-top: -${variables.spacingSmall}
    }

    &.withInfo {
      width: 100%;
      justify-content: space-between;
      flex-direction: column;

      ${respondTo.desktop`
        flex-direction: row;
      `};

      .infoPart {
        width: 100%;
        padding-top ${variables.spacingSmall};

        ${respondTo.desktop`
          width: 48%;
        `};
      }

      .fieldWrapper {
        ${respondTo.desktop`
          width: 46%;
        `};
      }
    }

    &.withMpan {
      width: 100%;
      flex-direction: column;

      ${respondTo.desktop`
        flex-direction: row;
      `};

      .mpanList {
        overflow-y: auto;
        width: 100%;
        padding-top ${variables.spacingSmall};

        ${respondTo.desktop`
          width: 35%;
        `};
      }

      .mpanInfo {
        width: 100%;
        padding-top ${variables.spacingSmall};

        ${respondTo.desktop`
          width: 48%;
        `};
      }
    }

    &.withContactDetails {
      width: 100%;
      flex-direction: column;

      ${respondTo.desktop`
        flex-direction: row;
      `};

      .contactInfo {
        width: 100%;
        text-align: center;
        padding-bottom: ${variables.spacingSmall};;

        ${respondTo.desktop`
          width: 35%;
          text-align: left;
          padding-bottom: 0;
        `};
      }

      .contactInput {
        width: 100%;
        padding-top ${variables.spacingSmall};

        ${respondTo.desktop`
          width: 20%;
        `};
      }

      .submitEmail {
        width: 100%;
        padding-top ${variables.spacingSmall};

        ${respondTo.desktop`
          width: 70%;
        `};
      }

      .submitBtn {
        width: 100%;
        padding-top ${variables.spacingSmall};

        ${respondTo.desktopXl`
          width: 30%;
        `};
      }
    }

    &:last-child {
      margin: 0;
    }

    // ${respondTo.desktop`
    //   width: 46%;
    // `};
  }

  .siteTitle {
    font-size: ${variables.fontSizeBase};
    color: ${({ theme }) => theme.text.secondary};
    min-height: 3em;
    display: flex;
    align-items: flex-end;
    margin-bottom: ${variables.spacingSmall};
  }
`;

export const SitesWrapper = styled(BlockWrapper)`
  th {
    color: ${({ theme }) => theme.primary.bg};
  }
`;

export const FinalReadWrapper = styled(BlockWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReviewInfoRow = styled.div`
  border-bottom: 1px solid ${colors.darkGrey};
  margin-bottom: ${variables.spacingMedium};

  &:last-child {
    border: none;
    margin: 0;
  }
`;

export const MovingPremisesWrapper = styled.div`
  .selectedAccountDisplay {
    padding: 4px 40px !important;
    border: 1px solid #bbbbbb;
    border-radius: 16px;
    min-height: 32px !important;
    color: ${colors.oxfordBlue};
    white-space: nowrap;
    width: 200px;
    background-color: ${colors.lightGrey};
    font-size: 16px;
    margin: 2px;
    text-align: center;
    opacity: 0.5;
  }

  .requiredFieldText {
    padding-left: 1rem !important;
    padding-top: 0.75rem !important;
  }
`;
