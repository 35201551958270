import styled from 'styled-components';

import { ButtonPrimary } from '../../common/components';

import { respondTo } from '../../common/styles/mixins';
import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';
import { TagManager } from '../utils/analytics/TagManager';

const StyledWrapper = styled.div`
  display: none;
  background-color: ${colors.lightGrey};
  border-radius: ${variables.inputRadius};
  padding: ${variables.spacingSmall};
  flex: 1 100%;

  align-items: center;
  margin-bottom: 25px;

  & > * {
    margin-right: auto;
  }

  ${respondTo.desktop`
    display: block;
  `};
`;

const StyledMobileWrapper = styled.div`
  display: flex;
  background-color: ${colors.lightGrey};
  border-radius: ${variables.inputRadius};
  padding: ${variables.spacingSmall};
  margin: 15px;

  ${respondTo.desktop`
    display: none;
  `}
`;

const StyledButton = styled(ButtonPrimary)`
  width: 320px;
  display: block;
  margin: 0 0 0 auto;
`;

const DebitCreditCardExternal = () => {
  const title = 'Pay via debit/credit card';
  const buttonText = 'Make a payment';
  const makeAPaymentLink = 'https://drax.mysecurepay.co.uk';

  const handleMakeAPayment = () => {
    TagManager.pushData({
      event: 'External pay (make a payment)',
    });
  };

  return (
    <>
      <StyledWrapper>
        <div className='d-flex'>
          <h4>{title}</h4>
          <p className='ml-auto font-italic pt-1'>
            If you wish to make a payment over £10,000, please use the Bank Transfer details below
          </p>
        </div>
        <div className='pt-4 d-flex'>
          <p>This will open an external web page to allow you to make a secure payment</p>
          <div className='ml-auto'>
            <StyledButton title={buttonText} href={makeAPaymentLink} onClick={handleMakeAPayment} target='_blank' />
          </div>
        </div>
      </StyledWrapper>

      <StyledMobileWrapper>
        <div>
          <h4 className='pb-4'>{title}</h4>
          <p className='font-italic'>
            If you wish to make a payment over £10,000, please use the Bank Transfer details below
          </p>
          <p className='pb-2'>This will open an external web page to allow you to make a secure payment</p>
          <StyledButton title={buttonText} href={makeAPaymentLink} onClick={handleMakeAPayment} target='_blank' />
        </div>
      </StyledMobileWrapper>
    </>
  );
};

export default DebitCreditCardExternal;
