import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import iconSet from '../../../../assets/selection.json';
import IcomoonReact from 'icomoon-react';

import { ButtonPrimary, ModalClose } from '../../../../common/components';

type RestrictSubmitBulkMetersModalProps = {
  onClose: () => void;
  failureMessages: Array<string> | undefined;
};

export const ContentWrapper = styled.div`
  .failure-icon {
    display: inline-block;
    padding: 25px;
    width: 80px;
    height: 80px;
    text-align: center;
    background: #ccc;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  li {
    list-style-type: disc;
    margin-top: 20px;
  }
`;

export const RestrictSubmitBulkMetersModal = (props: RestrictSubmitBulkMetersModalProps) => {
  const { onClose, failureMessages = [''] } = props;

  return (
    <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
      <Modal.Header className='pl-lg-4 pt-0'>
        <Modal.Title>Submit meter reads</Modal.Title>
        <ModalClose onClick={onClose} />
      </Modal.Header>

      <Modal.Body className='pl-lg-4'>
        <ContentWrapper>
          <div className='d-flex justify-content-center align-items-center'>
            <span className='failure-icon'>
              <IcomoonReact iconSet={iconSet} color={'black'} size={30} icon='exclamation-thick' />
            </span>
          </div>
          {failureMessages.map((msg: string, i: number) => (
            <p key={i}>{msg}</p>
          ))}
        </ContentWrapper>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <ButtonPrimary onClick={onClose} className={'mr-0'} title='Okay' route='#' />
      </Modal.Footer>
    </Modal>
  );
};
