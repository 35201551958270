// Modules
import React, { useState, useEffect } from 'react';

// Components
import { Icon, CustomDatePicker, RowInputItem } from '../../../common/components';
import { CircleCheck } from '../../icons';

// Utils
import { TagManager } from '../../../components/utils/analytics/TagManager';
import { formatDate } from '../../../components/utils/date/date';

// Context
import { REVERSED_DATE_FORMAT_HYPHEN } from '../../../common/constants';
import { Tooltip, Overlay } from 'react-bootstrap';
import { error } from '../../../common/styles/colors';
import { NewRead } from '../../home/SubmitMeterReading/MeterReadingData/MeterReadingDataBlockDrax';
import { DateObject } from 'react-multi-date-picker';

type SubmitReadRowProps = {
  index: number;
  registers: Array<Api.IRegisters>;
  readingsValues: MeterReading[];
  meterSerialNumber: string;
  handleOnChange: Function;
  handleDateChange: Function;
};

const defaultProps = {};

export type RegisterRead = {
  register: string;
  reading: NewRead;
};

export type MeterReading = {
  meterSerialNumber: string;
  readings: Array<RegisterRead>;
};

export const SubmitReadRow = (props: SubmitReadRowProps) => {
  const { index, registers, meterSerialNumber, readingsValues } = props;

  // State
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const readingSources: { [key: string]: string } = {
    'Derived': 'Estimate',
    'User Input': 'Customer',
    'Data Flow': 'Actual',
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    registerIndex: number,
    register: Api.IMeterReads
  ) => {
    const registerRead: RegisterRead = {
      register: `read${registerIndex + 1}`,
      reading: {
        registerId: register.registerId,
        newRead: e.target.value,
        isValid: parseFloat(e.target.value) >= register['reading'],
        date: selectedDate,
      },
    };

    props.handleOnChange(meterSerialNumber, registerRead);
  };

  const handleDateChange = (e: any) => {
    let selectedDate: string = '';
    if (e) {
      selectedDate = e.format(REVERSED_DATE_FORMAT_HYPHEN);
    }

    setSelectedDate(selectedDate);

    TagManager.pushData({
      event: 'Choose meter read date',
      selectedDate: selectedDate,
    });

    props.handleDateChange(meterSerialNumber, selectedDate);
  };

  const inputWrapperRef = React.useRef(new Array());

  useEffect(() => {
    inputWrapperRef.current.splice(0, inputWrapperRef.current.length - 1);

    registers.map((r, ind) => {
      let myRef = React.createRef();
      inputWrapperRef.current.push(myRef);
    });
  }, []);

  const isValidReading = (ind: number): boolean => {
    const values = readingsValues.find(r => r.meterSerialNumber === meterSerialNumber);
    const readings = values?.readings.find(read => read.register === `read${ind + 1}`);

    if (readings && readings.reading && readings.reading.newRead && readings.reading.newRead.length > 0) {
      return readings.reading.isValid;
    } else {
      return true;
    }
  };

  const currentDate = new Date();
  const startDateWindow = new DateObject({
    year: currentDate.getUTCFullYear() - 2,
    month: currentDate.getUTCMonth() + 1,
    day: currentDate.getUTCDate(),
  });

  return (
    <div className={`${index !== 0 ? 'top-divider-border' : ''}`} key={index}>
      <table key={index}>
        <tbody>
          <tr>
            <td colSpan={2}>
              Meter serial no. <span className='blue-text'>{meterSerialNumber}</span>
            </td>
            <td></td>
            <td></td>
            <td>Register ID</td>
          </tr>
          <tr className='bottom-divider-border'>
            <td></td>
            <td className='text-center'>Status</td>
            <td style={{ width: '100px' }}>Source</td>
            <td>Read date</td>
            {registers.map((r, i) => (
              <td key={i}>{r.id}</td>
            ))}
          </tr>
          <tr className='readings-submitrow'>
            <td>Last provided read</td>
            <td className='text-center'>
              <Icon icon={CircleCheck} size={20} />
            </td>
            <td>{readingSources[registers[0].reads[0].readingSource]}</td>
            <td>{formatDate(registers[0].reads[0].readingDate)}</td>
            {registers.map((r, i) => (
              <td key={i}>{r.reads[0].reading}</td>
            ))}
          </tr>
          <tr>
            <td className='readings-newread'>New read*</td>
            <td></td>
            <td></td>
            <td style={{ minWidth: '170px', width: '170px' }}>
              <CustomDatePicker
                date={formatDate(readingsValues[0].readings[0].reading.date)}
                showIcon={true}
                minDate={startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
                maxDate={new Date()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e)}
              />
            </td>
            {registers.map((r, registerIndex) => (
              <td key={registerIndex}>
                <div ref={inputWrapperRef.current[registerIndex]}>
                  <RowInputItem
                    style={{ height: '54px', width: '90px' }}
                    value={readingsValues[index].readings[registerIndex].reading.newRead}
                    readOnly={false}
                    isSubmitted={isSubmitted}
                    onFocus={() => {}}
                    onHandleBlur={() => {}}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, registerIndex, r.reads[0])
                    }
                    validateValue={(currentValue: string | number) => {
                      return parseFloat(`${currentValue}`) >= r.reads[0].reading;
                    }}
                    placeholder='0000.00'
                    htmlInputProps={{
                      inputMode: 'numeric',
                      required: true,
                    }}
                  />
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

SubmitReadRow.defaultProps = defaultProps;
