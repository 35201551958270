// Modules
import React from 'react';

// Styles
import { ConfigurableChartWrapper } from './ConfigurableChartView.styles';
import { DownloadButtonsWrapper } from '../mpans/MpanDetails.styles';

// Components
import { Filters } from './Filters';
import { Chart } from '../../common/components/Chart';
import { ButtonPrimary, Loading, ButtonDownload } from '../../common/components';
import { DateFilterModal, Ranges } from '../../common/components/DateFilterModal';
import { DownloadFileModal } from '../../common/components/DownloadFileModal';
import { isDraxBrand, isOpusBrand } from '../../utils/common';

// Types
import { ConfigurableChartViewProps } from './ChartTypes';

// Utils
import { TagManager } from '../utils/analytics/TagManager';
import { GridFilter } from '../gridfilter/GridFilter';

export function ConfigurableChartView(props: ConfigurableChartViewProps) {
  // State
  const [isShowingDownloadModal, setIsShowingDownloadModal] = React.useState(false);

  const handleDownloadButtonClick = (isDropdownOpened: boolean) => {
    if (isDropdownOpened) {
      let event = '';

      if (props.type === 'overview') {
        event = 'Launch Account Graph data Download';
      } else if (props.type === 'sites') {
        event = 'Launch MPAN Graph data Download';
      }

      if (event) {
        TagManager.pushData({ event });
      }
    }
  };

  const isDatasetsHaveData = (() => {
    if (!props.datasets.length) return false;
    let datasetsHaveData = false;

    for (let d = 0; d < props.datasets.length; d += 1) {
      const { data = [] } = props.datasets[d] || {};

      for (let i = 0; i < data.length; i += 1) {
        const item = data[i];

        if (typeof item === 'number' && item > 0) {
          datasetsHaveData = true;
          break;
        }
      }

      if (datasetsHaveData) break;
    }

    return datasetsHaveData;
  })();

  const filterMessageTitle: Array<Messages.IFilterMessageType> = [
    { value: 'csv', display: 'Download CSV' },
    { value: 'xml', display: 'Download XML' },
  ];
  let isTitleBold = true;
  let title = '';
  let message = '';

  if (!props.isLoadingData) {
    if (((isOpusBrand && props.isCorpBroker()) || isDraxBrand) && props.selectedCustomerIndex < 0) {
      title = 'Select customer account';
      message = 'Use filters above to select customer';
    } else if (props.sitesFilterEnabled && props.selectedMpanIndex < 0) {
      title = 'Please select an MPAN';
      message = 'Use filters above to select an MPAN';
    } else if (!isDatasetsHaveData) {
      isTitleBold = false;
      title = 'No data available for this period.';
      message = 'Please select different dates to display graph.';
    }
  }
  const isDisplayingMessage = title || message;
  let chartDatasets = isDisplayingMessage ? [] : props.datasets;
  let chartLabels = isDisplayingMessage ? [] : props.labels;
  let downloadBtnText = 'Download report';
  let consumptionDateLabel = '';

  if (isDraxBrand) {
    downloadBtnText = 'Download graph data';

    if (props.consumptionDate) {
      let label = '';

      if (props.selectedRange === Ranges.monthly) {
        label = 'Daily for';
      } else if (props.selectedRange === Ranges.daily) {
        label = 'Half-hourly interval data - ';
      }

      consumptionDateLabel = `${label} ${props.consumptionDate}`;
    }
  }

  const handleDateSelectorFilterChange = (option: Common.IOption) => {
    if (props.handleDateSelectorFilterChange) {
      props.handleDateSelectorFilterChange(option);
    }
  };

  const handleMpanMprnFilterChange = (option: Common.IOption, index: number) => {
    if (props.onMpanSelect) {
      props.onMpanSelect(option, index);
    }
  };

  const dateSelectorDataAccountView: Array<Common.IOption> = [
    { display: 'Last 24 months', value: '24Months' },
    { display: 'Last 12 months', value: '12Months' },
    { display: 'Last 6 months', value: '6Months' },
  ];

  const dateSelectorDataMPANView: Array<Common.IOption> = [
    { display: 'Last 24 months', value: '24Months' },
    { display: 'Last 12 months', value: '12Months' },
    { display: 'Last 6 months', value: '6Months' },
    { display: 'Monthly', value: 'Monthly' },
    { display: 'Daily', value: 'Daily' },
  ];

  const ChartFilterAdditionalItems = () => {
    const additionalItems = [
      <DownloadButtonsWrapper className='ml-auto' key={'downloadbtn'}>
        <ButtonDownload
          className='additional-buttons-spacing'
          disabled={!isDatasetsHaveData}
          title={downloadBtnText}
          options={filterMessageTitle}
          onClick={props.downloadReport}
          onButtonClick={handleDownloadButtonClick}
          outline={true}
          icon='upload'
          dontSaveSelectedIndex
        />
      </DownloadButtonsWrapper>,
    ];

    return additionalItems;
  };

  const selectedMpanHasHH = () => {
    if (props.mpanMprnOptions) {
      const mpan: any = props.mpanMprnOptions[props.selectedMpanIndex];
      if (mpan) {
        return mpan.hhOrNhh === 'HH';
      }
    }
    return false;
  };

  return (
    <ConfigurableChartWrapper className='types d-flex-column my-4'>
      <DownloadFileModal
        show={isShowingDownloadModal}
        onFileTypeSelect={props.onModalDownloadBtnClick!}
        hideModal={() => setIsShowingDownloadModal(false)}
      />

      <GridFilter
        showClearAll={false}
        showSites={false}
        showAccount={false}
        showMpanMprn={props.type == 'sites'}
        showPeriod={false}
        showDateSelector={true}
        dateSelectorData={
          props.type == 'sites' && selectedMpanHasHH() ? dateSelectorDataMPANView : dateSelectorDataAccountView
        }
        disableDateSelector={props.type == 'sites'}
        askForCustomerAccount={props.askForCustomerAccount}
        showDate={false}
        siteData={[]}
        mpanMprnData={props.mpanMprnOptions}
        selectedType={'Electricity'}
        handleSiteFilterChange={() => {}}
        accountData={[]}
        customerData={[]}
        handleClearAllClick={() => {}}
        handleAccountFilterChange={() => {}}
        handleCustomerFilterChange={props.onCustomerSelect}
        handleDateSelectorFilterChange={handleDateSelectorFilterChange}
        handleMpanMprnFilterChange={handleMpanMprnFilterChange}
        handleDateSelectorDayMonthFilterChange={props.handleDateSelect}
        previouslySelectedDate={props.previouslySelectedDate}
        renderAdditionalListItems={ChartFilterAdditionalItems}
      />

      <div className='position-relative chart__wrapper'>
        <Chart onBarClick={props.onBarClick} labels={chartLabels} datasets={chartDatasets} />
        {consumptionDateLabel && isDatasetsHaveData && (
          <div className='mt-2 d-flex justify-content-center bottom-date'>{consumptionDateLabel}</div>
        )}
        {props.type === 'sites' && (
          <div className='d-flex points mt-4'>
            <div className='d-flex actual ml-auto'>
              <div className='marker' />
              <p className='ml-2 mb-0'>Actual</p>
            </div>
            <div className='d-flex estimated ml-4'>
              <div className='marker' />
              <p className='ml-2 mb-0'>Estimated</p>
            </div>
            <div className='d-flex month-to-date ml-4 mr-auto'>
              <div className='marker' />
              <p className='ml-2 mb-0'>Month to date</p>
            </div>
          </div>
        )}
        {props.isLoadingData && <Loading />}
        {isDisplayingMessage && (
          <div className='select__customer position-absolute d-flex flex-column mb-5'>
            {title && <p className={`${isTitleBold ? 'font-weight-bold' : ''} m-0`}>{title}</p>}
            {message && <p className='mt-2'>{message}</p>}
          </div>
        )}
      </div>
    </ConfigurableChartWrapper>
  );
}
