import Modal from 'react-bootstrap/Modal';

import { ModalClose, ButtonPrimary, ButtonTerinary } from '../../../../common/components';
import { ActionsWrapper } from '../SubmitMeterReadingModals.styles';

type DateOptionModalProps = {
  onClose: () => void;
  onToday: () => void;
  onNotToday: () => void;
};

export const DateOptionModal = (props: DateOptionModalProps) => {
  const { onClose, onToday, onNotToday } = props;

  return (
    <>
      <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
        <Modal.Header>
          <Modal.Title>Is this read for today?</Modal.Title>
          <ModalClose onClick={onClose} />
        </Modal.Header>

        <Modal.Body>
          <ActionsWrapper>
            <ButtonPrimary
              title='Yes'
              onClick={(e: any) => {
                e.preventDefault();
                onToday();
              }}
            />
            <ButtonTerinary
              title='No'
              onClick={(e: any) => {
                e.preventDefault();
                onNotToday();
              }}
            />
          </ActionsWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
};
