// Modules
import { useContext, useEffect, useState, useReducer } from 'react';

// Context
import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

// Api
import { getContactSummary } from '../../common/api/contactApi';

// Components
import { GridFilter } from '../gridfilter/GridFilter';
import { StyledTitle, FilterRowWrapper, GridTypeSelector } from '../../common/components';
import { ContactSummaryGrid } from './ContactSummaryGrid';

// Utils
import { getInitialType } from '../../utils/getInitialTypes';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';
import { getSelectedCustomerId } from '../../helpers/customer';
import { formatDate } from '../utils/date/date';

// Types
import { EssAccountsTypes } from '../../types/account';
import moment from 'moment';
import { SearchDetailContext, SearchDetailContextProps } from '../../context/searchDetail/searchDetailContext';
import { RoutingToTypes } from '../search/SearchActions/SearchActions';
import { NotificationBox } from '../health/NotificationBox/NotificationBox';
import { ContractSummaryModal } from '../mymessages/modalTemplates/ContractSummaryModal';
import { temporary } from '../../helpers/axiosWrapper';

export const ContactSummary = () => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);

  const [gridTypes, setGridTypes] = useState<string[]>();
  const [isContractDueToExpire, setIsContractDueToExpire] = useState<boolean>(false);
  const [isContractDeemed, setIsContractDeemed] = useState<boolean>(false);
  const [isContractPending, setIsContractPending] = useState<boolean>(false);
  const [isDirectCustomer, setIsDirectCustomer] = useState<boolean>(false);
  const [isDirectSoldByBrokerCustomer, setIsDirectSoldByBrokerCustomer] = useState<boolean>(false);
  const [showContractSummaryModal, setShowContractSummaryModal] = useState<boolean>(false);

  const resolution = window.innerWidth;
  const isMobile = resolution <= 540;
  const isDesktop = !isMobile;
  const isTpiCustomer = (temporary.accessId && temporary.accessId.length > 0) || false;

  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(userDetail.hasElectricity, userDetail.hasGas),
    data: {},
    gridData: [],
    initialSortField: 'contract-end-date',
    initialSortOrder: 'ASC',
    currentSortField: 'contract-end-date',
    currentSortOrder: 'ASC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  const showWarningMessage = (isContractDeemed || isContractDueToExpire) && !isContractPending;

  useEffect(() => {
    let types = [];
    if (userDetail.hasElectricity) types.push(EssAccountsTypes.Electricity);
    if (userDetail.hasGas) types.push(EssAccountsTypes.Gas);

    setGridTypes(types);

    // When navigating directly to url
    initialState.currentType = getInitialType(userDetail.hasElectricity, userDetail.hasGas, userDetail.hasRenewable);

    if (userDetail.essCustomerId) {
      initialState.selectedCustomerId = getSelectedCustomerId(userDetail);

      if (userDetail.customerAccounts.length === 1) {
        dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
      }
    }

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.essCustomerId]);

  useEffect(() => {
    if (searchContext.searchDetail != null && searchContext.searchDetail.searchText.length > 0) {
      // filter by search result

      if (searchContext.searchDetail.type && searchContext.searchDetail.routingTo === RoutingToTypes.contracts) {
        const type = searchContext.searchDetail.type.toLowerCase();

        if (type === 'account') {
          dispatch({
            type: GRID_ACTIONS.FILTER_BY_CUSTOMER,
            payload: { essCustomerId: searchContext.searchDetail.accountId },
          });
        }
        // Clear Search Detail
        searchContext.setSearchDetail({ searchText: '' });
      }
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext.searchDetail.type]);

  // TODO: should be investigated!
  // const filterRowsByProductType = (data: Api.IContactSummaryData[], product: string) => {
  //   return data?.length ? data.filter(item => item.product === product) : [];
  // };

  const getContactSummaryRows = async (essCustomerId: string, sort?: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    let result: any = await getContactSummary(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      'Live',
      state.page,
      sort
    );

    if (result.length === 0) {
      result = await getContactSummary(
        process.env.REACT_APP_API_URL,
        authContext,
        essCustomerId,
        'Pending',
        state.page,
        sort
      );
    }

    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

    if (result) {
      let anyPendingContracts = result.some((type: { [x: string]: string }) => type['contract-status'] === 'Pending');

      result.map((item: Api.IContactSummaryPagedData) => {
        item.contractAccountTypeExt = `${item['contract-account-type']}`;
        item.contractAccountTypeExt += item['contract-account-sub-type']
          ? ` - ${item['contract-account-sub-type']}`
          : '';

        item['contract-start-date'] = formatDate(item['contract-start-date']);
        item['contract-end-date'] = formatDate(item['contract-end-date']);

        item.warningIcon = false;

        if (item['contract-account-type'] === 'Deemed') {
          item.warningIcon = true && !anyPendingContracts;
          setIsContractDeemed(true);
        }

        // Show Warning if 60 days or less left
        const now = moment();
        const contractEndDate = moment(item['contract-end-date'], 'DD/MM/YYYY');
        const diffDays = contractEndDate.diff(now, 'days');

        if (diffDays <= 60) {
          item.warningIcon = true && !anyPendingContracts;
          setIsContractDueToExpire(true);
        }

        if (item['contract-status'] === 'Pending') {
          setIsContractPending(true);
        }

        if (item['market-sector'] === 'Third Party Intermediaries') {
          setIsDirectSoldByBrokerCustomer(true);
        } else if (
          item['market-sector'] === 'Direct' ||
          item['market-sector'] === 'Face to Face' ||
          item['market-sector'] === 'Telesales'
        ) {
          setIsDirectCustomer(true);
        }
      });

      dispatch({ type: GRID_ACTIONS.INIT_FILTERS, payload: { essCustomerId, data: result } });

      return result;
    }

    return [];
  };

  const loadData = async (gridType: string, customerId: string, sort?: Common.ISort) => {
    setIsContractDeemed(false);
    setIsContractDueToExpire(false);
    setIsContractPending(false);

    const rawData = await getContactSummaryRows(customerId, sort);
    // const filteredData = filterRowsByProductType(rawData, gridType); TODO: should be investigated!
    dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: rawData, page: state.page } });
  };

  useEffect(() => {
    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.currentType, state.selectedCustomerId, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentType, state.selectedCustomerId, state.page, state.currentSortField, state.currentSortOrder]);

  const handleTypeChange = (type: string) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: type });
  };

  const handleSortChange = (sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
  };

  const handleClearAll = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const requestAQuoteClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowContractSummaryModal(true);
  };

  const handleRequestAQuoteHide = (event: any) => {
    setShowContractSummaryModal(false);
  };

  return (
    <>
      {/* The below FilterRowWrapper and GridFilter will only return for mobile */}
      {isMobile && (
        <div>
          <FilterRowWrapper>
            <StyledTitle>Contract summary</StyledTitle>
          </FilterRowWrapper>
          <GridFilter
            showClearAll={false}
            showSites={false}
            showAccount={false}
            siteData={state.data?.sites}
            selectedType={state.currentType}
            handleSiteFilterChange={() => {}}
            accountData={state.data?.accounts}
            customerData={state.data?.customers}
            handleClearAllClick={handleClearAll}
            handleAccountFilterChange={() => {}}
            handleCustomerFilterChange={handleCustomerFilterChange}
          />
        </div>
      )}
      <div className='container-fluid'>
        <div className='row'>
          {/* The below FilterRowWrapper and GridFilter will only return for desktop */}
          {isDesktop && (
            <div className='col-md-12 col-xl-4'>
              <FilterRowWrapper>
                <StyledTitle>Contract summary</StyledTitle>
              </FilterRowWrapper>
              <GridFilter
                showClearAll={false}
                showSites={false}
                showAccount={false}
                siteData={state.data?.sites}
                selectedType={state.currentType}
                handleSiteFilterChange={() => {}}
                accountData={state.data?.accounts}
                customerData={state.data?.customers}
                handleClearAllClick={handleClearAll}
                handleAccountFilterChange={() => {}}
                handleCustomerFilterChange={handleCustomerFilterChange}
              />
            </div>
          )}
          <div className={showWarningMessage ? 'col-md-12 col-xl-5 my-3' : 'col-md-12 col-xl-5'}>
            {showWarningMessage && (
              <NotificationBox
                showButton={true}
                buttonClassName='button'
                boxText='You have contracts that are due to expire or are on Deemed rates'
                buttonText='Request a Quote'
                onClick={requestAQuoteClick}
              ></NotificationBox>
            )}
          </div>
        </div>
      </div>

      <GridTypeSelector initialType={state.currentType} types={gridTypes} handleTypeChange={handleTypeChange} />

      <ContactSummaryGrid
        gridData={state.gridData}
        isLoading={state.isLoading}
        hasMoreData={state.hasMoreData}
        selectedType={state.currentType}
        handleSortChange={handleSortChange}
        handlePagingChange={handlePagingChange}
        currentSortField={state.currentSortField}
        currentSortOrder={state.currentSortOrder}
      />

      <ContractSummaryModal
        show={showContractSummaryModal}
        directCustomerContactEmail='renewals@drax.com'
        tpiContactEmail='core.brokers@drax.com'
        contactNumber='01473 237870'
        onHide={handleRequestAQuoteHide}
        isDirectCustomer={isDirectCustomer && !isTpiCustomer && !isDirectSoldByBrokerCustomer}
        isDirectSoldByBrokerCustomer={isDirectSoldByBrokerCustomer && !isTpiCustomer}
        isTpiCustomer={isTpiCustomer}
      ></ContractSummaryModal>
    </>
  );
};
