import { useHistory } from 'react-router-dom';

import { ButtonPrimary } from '../../../../common/components';
import { SetupNotificationWrapper } from './DDReview.styles';

export const SetupNotification = () => {
  const history = useHistory();

  const handleClick = (e: any) => {
    e.preventDefault();
    history.replace('/home/direct-debit');
  };

  return (
    <SetupNotificationWrapper>
      <div>
        <h4>Why not set up other accounts on Direct Debit</h4>
        <p>You can set up or amend your direct debit here</p>
      </div>

      <ButtonPrimary title='Set up accounts' onClick={handleClick} className='m-0' />
    </SetupNotificationWrapper>
  );
};
