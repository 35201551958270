import styled from 'styled-components';

import { respondTo } from '../../styles/mixins';
import { fontSizeLarge } from '../../styles/variables';

export const SubmitRegisterItemDraxWrapper = styled.div`
  .meter__reading__title {
    font-size: ${fontSizeLarge};
  }

  .meter__reading__input input {
    -moz-appearance: textfield;
    min-height: 48px;
    width: 100%;
    ${respondTo.desktopXl`
      max-width: 220px;
    `}

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
