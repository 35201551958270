import { useEffect, useState, useContext, useReducer } from 'react';
import { toast } from 'react-toastify';

import {
  getMyMessages,
  getUnreadMessageCount,
  myMessagesSaveAsRead,
  myMessagesSaveAsDeleted,
} from '../../common/api/myMessagesApi';
import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';
import { MyMessagesComponent } from './MyMessages';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';
import { isTpiUser } from '../../helpers/tpiUser';

export const MyMessages = () => {
  const authContext = useAuthState();
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const customerEmail = encodeURIComponent(userDetail.emailAddress);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalGridData, setOriginalGridData] = useState<Array<Messages.IAlertData>>([]);
  const [initialType] = useState<string>('all');
  const [filters, setFilters] = useState<Array<Common.IOption>>([]);

  const initialState: IGridReducerState = {
    page: 1,
    currentType: 'all',
    data: {},
    initialSortField: '',
    initialSortOrder: '',
    currentSortField: '',
    currentSortOrder: '',
    selectedCustomerId: userDetail.essCustomerId,
    hasMoreData: true,
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  useEffect(() => {
    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    loadData(state.currentType, currentSort);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentType, state.page, state.currentSortField, state.currentSortOrder, userDetail.customerAccounts]);

  const checkUnreadMessages = async () => {
    const accountReferences: Array<string> = [];
    let userType: string = 'Customers';
    let references: Array<string> = [];

    if (isTpiUser()) {
      userType = 'TPIUsers';
      userDetail.customerAccounts.map(x => accountReferences.push(x.essAccountID));
      let claims = authContext.getClaims();
      references.push(claims.tpiReferenceId);
    } else {
      let accounts = userDetail.customerAccounts.map(x => x.essAccountID);
      references.push(...accounts);
    }

    const messagesData = await getUnreadMessageCount(
      process.env.REACT_APP_API_URL,
      authContext,
      accountReferences,
      userType,
      references
    );

    if (messagesData?.data > 0) {
      setUserDetail({
        ...userDetail,
        hasUnreadMessages: true,
      });
    } else {
      setUserDetail({
        ...userDetail,
        hasUnreadMessages: false,
      });
    }
  };

  const getMessages = async (readStatus?: boolean, sort?: Common.ISort) => {
    if (userDetail.customerAccounts.length === 0) {
      return;
    }

    const accountReferences: Array<string> = [];
    let tpiCustomers = localStorage.getItem('cus');

    if (tpiCustomers) {
      const arr: Array<Common.IExpandedCustomerOption> = JSON.parse(tpiCustomers);
      arr.map(x => accountReferences.push(x.value));
    }

    let references: Array<string> = [];

    if (isTpiUser()) {
      let claims = authContext.getClaims();
      references.push(claims.tpiReferenceId);
    } else {
      let accounts = userDetail.customerAccounts.map(x => x.essAccountID);
      references.push(...accounts);
    }

    const messagesData: Common.IResult<Array<Messages.IAlertData>> = await getMyMessages(
      process.env.REACT_APP_API_URL,
      customerEmail,
      authContext,
      readStatus,
      state.page,
      references,
      sort,
      accountReferences
    );

    setIsLoading(false);

    if (messagesData && messagesData.success) {
      const data: Array<Messages.IAlertData> = messagesData.data;

      if (data) {
        dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: data, page: state.page } });

        let alertData = [];
        if (!state.page || state.page === 1) {
          alertData = data;
        } else {
          if (state.gridData) {
            alertData = state.gridData.concat(data);
          }
        }

        alertData.map((alertItem: Messages.IAlertData) => {
          // Check for BulkMessage and add the correct Modal
          if (alertItem.messageType === 'BulkMessage' && alertItem.name == null) {
            alertItem.name = 'BulkMessageModal';
          }

          // Check for account Reference
          if (alertItem.accountReference == null) {
            alertItem.accountReference = 'N/A';
          }
        });

        setOriginalGridData(alertData);
      } else {
        dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
        setOriginalGridData([]);
      }
    } else {
      dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
      setOriginalGridData([]);
      const message = 'API error calling: byuserid';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const putMarkAsRead = async (data: any | null | undefined) => {
    const putSaveAsReadData: any = await myMessagesSaveAsRead(process.env.REACT_APP_API_URL, authContext, data);
    if (putSaveAsReadData && putSaveAsReadData.success) {
      checkUnreadMessages();
    }
  };

  const putMarkAsDeleted = async (data: object | null | undefined) => {
    const putMarkAsDeletedData: any = await myMessagesSaveAsDeleted(process.env.REACT_APP_API_URL, authContext, data);
    if (putMarkAsDeletedData && putMarkAsDeletedData.data.success) {
      checkUnreadMessages();
    } else {
      const message = 'API error calling: markasdeleted';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const handleFilterChange = (filteredItems: Array<Common.IOption>) => {
    const filteredData: any = originalGridData.filter(item => {
      const filteredDataIndex = filteredItems.findIndex(elem => {
        return item.title === elem.value;
      });
      if (filteredDataIndex >= 0) {
        return true;
      } else {
        return false;
      }
    });
    if (filteredItems.length === 0) {
      dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: originalGridData, page: 1 } });
    } else {
      dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: filteredData, page: 1 } });
    }
  };

  const loadData = (type: string, sort?: Common.ISort) => {
    setIsLoading(true);

    if (type === 'all') {
      getMessages(undefined, sort);
    } else if (type === 'read') {
      getMessages(true, sort);
    } else if (type === 'unread') {
      getMessages(false, sort);
    }
  };

  const handleTypeChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: type });
  };

  const handleSortChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  return (
    <MyMessagesComponent
      initialType={initialType}
      handleTypeChange={handleTypeChange}
      handlePagingChange={handlePagingChange}
      handleSortChange={handleSortChange}
      handleFilterChange={handleFilterChange}
      handleMarkAsRead={putMarkAsRead}
      handleMarkAsDeleted={putMarkAsDeleted}
      gridData={state.gridData}
      options={filters}
      isLoading={isLoading}
      hasMoreData={state.hasMoreData}
    />
  );
};
