import styled from 'styled-components';

import * as colors from '../../../common/styles/colors';
import * as variables from '../../../common/styles/variables';
import { respondTo } from '../../../common/styles/mixins';

export const SearchItemWrapper = styled.div`
  .searchitem-row {
    cursor: pointer;
    padding: ${variables.spacingSmall} 0;
    border-radius: 15px;

    svg {
      fill: ${colors.black};
    }

    .hide {
      visibility: hidden;
    }
  }

  .icon-circle {
    width: 40px;
    height: 40px;
    background-color: ${colors.inputGrey};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    left: -3px;
    top: 6px;
  }

  .searchAction {
    display: none;
  }
`;

export const StyledRow = styled.div`
  ${respondTo.tablet`
      display: flex;
      justify-content: space-between;
    `};
`;

export const StyledTitle = styled.div`
  padding: 0 15px;
  margin-left: 25px;
`;
