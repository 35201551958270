import { useRef } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';

const textUtils = require('../../../components/utils/text/text');

type DownloadBulkInvoicesDatePickerProps = {
  className?: string;
  date?: Date | DateObject | DateObject[] | string | undefined;
  minDate?: Date | string | number | DateObject;
  maxDate?: Date | string | number | DateObject;
  format: string;
  months?: Array<Common.IFormatMonths>;
  onlyMonthPicker?: boolean;
  placeHolder?: string;
  onChange: (selectedDates: DateObject | DateObject[]) => void;
  customerstartdate?: Date | string | number | DateObject;
};

const defaultProps = {
  className: '',
  date: '',
  minDate: '',
  maxDate: new Date(),
  format: 'MM/YYYY',
  months: [],
  onlyMonthPicker: true,
  placeHolder: 'Select a month',
  customerstartdate: '',
};

export const DownloadBulkInvoicesDatePicker = (props: DownloadBulkInvoicesDatePickerProps) => {
  const datePickerRef = useRef<any>();

  return (
    <DatePicker
      className={props.className}
      format={props.format}
      months={textUtils.formatMonths}
      onChange={props.onChange}
      onlyMonthPicker={props.onlyMonthPicker}
      placeholder={props.placeHolder}
      minDate={props.minDate}
      maxDate={props.maxDate}
      ref={datePickerRef}
    >
      <span
        className='closeDatePicker'
        onClick={() => {
          datePickerRef.current.closeCalendar();
        }}
      />
    </DatePicker>
  );
};

DownloadBulkInvoicesDatePicker.defaultProps = defaultProps;
