import ReactSelect from 'react-select';
import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import * as variables from '../../styles/variables';

export const Label = styled.div`
  margin-bottom: ${variables.spacingXSmall};
`;

type InputProps = {
  hasError: boolean;
};

export const ReactSelectStyled = styled(ReactSelect).attrs((props: InputProps) => ({
  hasError: props.hasError,
}))<InputProps>`
  .FormSelect__control {
    background: ${({ theme }) => theme.input.bg};
    border: 1px solid ${({ theme, hasError }) => (hasError ? colors.errorLight : theme.input.border)};
    color: ${({ theme }) => theme.input.color};
    border-radius: ${variables.inputRadius};
    box-shadow: none;
    height: 54px;

    &:hover,
    &--is-focused {
      border-color: ${({ theme, hasError }) => (hasError ? colors.errorLight : theme.input.border)};
    }
  }

  .FormSelect__single-value {
    color: ${({ theme }) => theme.input.color};
  }

  .DashboardSelect__control {
    background: ${colors.white};
    border: 1px solid ${({ theme }) => theme.input.border};
    color: ${({ theme }) => theme.input.color};
    border-radius: 5px;
    box-shadow: none;

    &:hover,
    &--is-focused {
      border-color: ${({ theme }) => theme.input.border};
    }
  }

  .DashboardSelect__menu-list,
  .FormSelect__menu-list {
    ${mixins.scrollbars};
  }

  .FormSelect__value-container {
    cursor: text;
    padding: 0.65em 0 0.65em ${variables.spacingSmall};
  }

  .DashboardSelect__value-container {
    padding: 0.4em ${variables.spacingSmall};
  }

  .DashboardSelect__indicators,
  .FormSelect__indicators {
    cursor: pointer;
  }

  .DashboardSelect__indicator-separator,
  .FormSelect__indicator-separator {
    display: none;
  }

  .DashboardSelect__menu,
  .FormSelect__menu {
    background: ${colors.lightGrey};

    .DashboardSelect__option,
    .FormSelect__option {
      ${mixins.transition};
      background: ${colors.lightGrey};
      cursor: pointer;
      color: inherit;

      &:hover {
        ${mixins.transition};
        background: ${colors.white};
        color: ${({ theme }) => theme.formSelect.selected};
      }

      &.selected {
        background-color: ${colors.white};
        color: ${({ theme }) => theme.formSelect.selected};
      }

      &--is-focused {
        background: ${colors.white};
        color: ${({ theme }) => theme.formSelect.selected};
      }

      .custom-option-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span {
        font-size: ${variables.fontSizeSmall};
        display: inline-block;
        margin-left: ${variables.spacingSmall};
      }
    }
  }

  .FormSelect__dropdown-indicator {
    padding-left: 2px;
  }

  .FormSelect__placeholder {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
    overflow: hidden;
    width: calc(100% - 30px);
    margin: 0;
  }
`;

export const Error = styled.div`
  color: ${colors.errorLight};
  font-size: ${variables.fontSizeMedium};
  margin-top: ${variables.spacingXSmall};
`;
