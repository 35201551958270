// Modules
import { toast } from 'react-toastify';
// import { get, post } from '../../helpers/axiosWrapper';
// import { globalApiParams } from './globals';

export const getAccountUsers = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.IAccountUser>> => {
  // let headers: Api.ISitesForMovingPremisesParams = {
  //   sortField: sort?.field,
  //   sortDir: sort?.order,
  //   page,
  //   pageSize: globalApiParams.pageSize,
  // };

  toast.error('ENDPOINT NOT YET IMPLEMENTED (3)', { position: 'bottom-right' });
  // TODO - ENDPOINT NOT YET AVAILABLE
  // return await get(`${url}/userdetails/accountusers/${essCustomerId}`, { headers }, context);

  const data = {
    records: [
      {
        userID: 1,
        essCustomerID: '12345678',
        associatedcustomer: 'Mock Customer A',
        email: 'abc@test.com',
        firstname: 'Tom',
        jobtitle: 'Software Engineer',
        mobile: null,
        phoneNumber: 12345678900,
        surname: 'Willis',
        title: 'Mr',
        primaryContact: true,
        profilingOptIn: true,
        opusEmail: true,
        opusPost: true,
        opusPhone: true,
        opusSMS: true,
        thirdPartyEmail: false,
        thirdPartyPost: false,
        thirdPartyPhone: false,
        thirdPartySMS: false,
        marketResearchEmail: false,
        marketResearchPost: false,
        marketResearchPhone: false,
        marketResearchSMS: false,
      },
      {
        userID: 2,
        essCustomerID: '223456789',
        associatedcustomer: 'Mock Customer B',
        email: 'def@test.com',
        firstname: 'George',
        jobtitle: 'DBA',
        mobile: 22345678912,
        phoneNumber: 22345678900,
        surname: 'Smith',
        title: 'Mr',
        primaryContact: false,
        profilingOptIn: false,
        opusEmail: true,
        opusPost: false,
        opusPhone: false,
        opusSMS: true,
        thirdPartyEmail: true,
        thirdPartyPost: true,
        thirdPartyPhone: true,
        thirdPartySMS: false,
        marketResearchEmail: true,
        marketResearchPost: false,
        marketResearchPhone: false,
        marketResearchSMS: true,
      },
    ],
  };
  return Promise.resolve({
    success: true,
    data: {
      records: data.records,
    } as any,
    value: { data: { records: data } as any },
    errors: [],
  });
};

export const getAccountUsersDownload = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  responseFormat: 'csv' | 'xml'
): Promise<string> => {
  // const data: any = {
  //   responseFormat,
  // };
  toast.error('ENDPOINT NOT YET IMPLEMENTED (4)', { position: 'bottom-right' });

  // TODO - ENDPOINT NOT YET AVAILABLE
  // return await post(`${url}/userdetails/accountusersdownload/${essCustomerId}`, { headers }, context);
  return Promise.resolve('');
};

export const getLimitedAccessUsers = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  page: number,
  sort?: Common.ISort
): Promise<Common.IResult<Api.ILimitedAccessUser>> => {
  // let headers: Api.ISitesForMovingPremisesParams = {
  //   sortField: sort?.field,
  //   sortDir: sort?.order,
  //   page,
  //   pageSize: globalApiParams.pageSize,
  // };

  toast.error('ENDPOINT NOT YET IMPLEMENTED (5)', { position: 'bottom-right' });
  // TODO - ENDPOINT NOT YET AVAILABLE
  // return await get(`${url}/userdetails/limitedaccountusers/${essCustomerId}`, { headers }, context);

  const data = {
    records: [
      {
        userId: 1,
        greeting: 'Dave Willis',
        userName: 'dave.willis@test.com',
        essCustomerID: '12345',
        organisation: 'Mathew Band',
      },
      {
        userId: 2,
        greeting: 'John Smith',
        userName: 'john.smith@test.com',
        essCustomerID: '45678',
        organisation: 'Mathew Jones',
      },
    ],
  };
  return Promise.resolve({
    success: true,
    data: {
      records: data.records,
    } as any,
    value: { data: { records: data } as any },
    errors: [],
  });
};

export const getLimitedAccessUsersDownload = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  responseFormat: 'csv' | 'xml'
): Promise<string> => {
  // const data: any = {
  //   responseFormat,
  // };
  toast.error('ENDPOINT NOT YET IMPLEMENTED (6)', { position: 'bottom-right' });
  // TODO - ENDPOINT NOT YET AVAILABLE
  // return await post(`${url}/userdetails/limitedaccountusersDownload/${essCustomerId}`, { headers }, context);
  return Promise.resolve('');
};

export const revokeUser = async (
  url: string | undefined,
  context: any,
  userId: number,
  essCustomerId: string
): Promise<string> => {
  // const data: any = {
  //   responseFormat,
  // };
  toast.error('ENDPOINT NOT YET IMPLEMENTED (7)', { position: 'bottom-right' });
  // TODO - ENDPOINT NOT YET AVAILABLE
  // return await post(`${url}/userdetails/revokeUser/${essCustomerId}`, { headers }, context);
  return Promise.resolve('');
};

export const createUser = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  user: any
): Promise<string> => {
  // const data: any = {
  //   responseFormat,
  // };
  toast.error('ENDPOINT NOT YET IMPLEMENTED (8)', { position: 'bottom-right' });
  // TODO - ENDPOINT NOT YET AVAILABLE
  // return await post(`${url}/userdetails/createuser/${essCustomerId}`, { headers }, context);
  return Promise.resolve('');
};

export const updateUser = async (
  url: string | undefined,
  context: any,
  essCustomerId: string,
  user: any
): Promise<string> => {
  // const data: any = {
  //   responseFormat,
  // };
  toast.error('ENDPOINT NOT YET IMPLEMENTED (9)', { position: 'bottom-right' });
  // TODO - ENDPOINT NOT YET AVAILABLE
  // return await post(`${url}/userdetails/updateuser/${essCustomerId}`, { headers }, context);
  return Promise.resolve('');
};
