import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { DateObject } from 'react-multi-date-picker';

import { ButtonPrimary, DropDown, ModalClose } from '../../../common/components';
import { CustomDatePicker } from '../../../common/components';
import { razzmatazzPink, hydroBlue } from '../../../common/styles/colors';
import { REVERSED_DATE_FORMAT_HYPHEN } from '../../../common/constants';

type MeterReadsHistoryPeriodModalProps = {
  isOpusBrand?: boolean;
  title?: string;
  message?: string;
  modalSize?: 'sm' | 'lg' | 'xl' | undefined;
  show: boolean;
  onClose: () => void;
  onClick: (e: any, fileType: string) => Promise<void>;
  startDate?: DateObject;
  endDate?: DateObject;
  handleStartDateChange: (e: any) => void;
  handleEndDateChange: (e: any) => void;
  fuelOptions?: Array<Common.ITypedOption<string>> | undefined;
  selectedFuelIndex?: number | undefined;
  handleFuelTypeChange?: Function | undefined;
  resetFuelDropdownState?: boolean | undefined;
  afterResetFuelDropdownStateCallback?: Function | undefined;
  sitesOptionsEnabled?: boolean | undefined;
  sitesOptions?: Array<Common.ITypedOption<Object>> | undefined;
  selectedSiteIndex?: number | undefined;
  handleSiteChange?: Function | undefined;
  resetSiteDropdownState?: boolean | undefined;
  afterResetSiteDropdownStateCallback?: Function | undefined;
};

const defaultProps = {
  title: 'Download meter read history',
  message: 'Select date range',
  show: false,
};

const currentDate = new Date();
const startDateWindow = new DateObject({
  year: currentDate.getUTCFullYear() - 2,
  month: currentDate.getUTCMonth() + 1,
  day: 1,
});
const endDateWindow = new DateObject({
  year: currentDate.getUTCFullYear(),
  month: currentDate.getUTCMonth() + 1,
  day: currentDate.getUTCDate(),
});

const MeterReadsHistoryPeriodModal = (props: MeterReadsHistoryPeriodModalProps) => {
  const [startDateMaxDate, setStartDateMaxDate] = useState<any>(endDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN));
  const [endDateMinDate, setEndDateMinDate] = useState<any>(startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN));

  const onChangeStartDate = (e: any) => {
    setEndDateMinDate(e);
    props.handleStartDateChange(e);
  };

  const onChangeEndDate = (e: any) => {
    setStartDateMaxDate(e);
    props.handleEndDateChange(e);
  };

  return (
    <Modal
      animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
      className={'custom-modal'}
      show={props.show}
      centered={true}
    >
      <Modal.Header>
        <Modal.Title className='mx-auto'>
          {props.isOpusBrand ? 'Download meter read history' : 'Download meter read history report'}
        </Modal.Title>
        <ModalClose onClick={props.onClose} />
      </Modal.Header>
      <Modal.Body className='d-flex flex-column'>
        {props.isOpusBrand && (
          <>
            <div className='d-flex flex-column'>
              <p>Select a fuel type</p>
              <DropDown
                showValue={false}
                options={props.fuelOptions}
                selectText='Select a product'
                selectedIndex={props.selectedFuelIndex}
                handleChange={props.handleFuelTypeChange}
                resetState={props.resetFuelDropdownState}
                afterStateResetCallback={props.afterResetFuelDropdownStateCallback}
              />
            </div>
            <div className='d-flex flex-column'>
              <p>Select a site</p>
              <DropDown
                enabled={props.sitesOptionsEnabled}
                options={props.sitesOptions}
                selectText='Select a site or search ID'
                selectedIndex={props.selectedSiteIndex}
                handleChange={props.handleSiteChange}
                resetState={props.resetSiteDropdownState}
                afterStateResetCallback={props.afterResetSiteDropdownStateCallback}
              />
            </div>
          </>
        )}
        <p style={props.isOpusBrand ? { color: razzmatazzPink } : { color: hydroBlue }}>{props.message}</p>
        <div className='d-flex justify-content-between'>
          <div className='mr-1'>
            <span>From</span>
            <CustomDatePicker
              date={props.startDate}
              minDate={startDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
              maxDate={startDateMaxDate}
              showIcon={true}
              onChange={e => onChangeStartDate(e)}
            />
          </div>
          <div className='ml-1'>
            <span>To</span>
            <CustomDatePicker
              disabled={props.startDate == null}
              date={props.endDate}
              minDate={endDateMinDate}
              maxDate={endDateWindow.format(REVERSED_DATE_FORMAT_HYPHEN)}
              showIcon={true}
              onChange={e => onChangeEndDate(e)}
            />
          </div>
        </div>
        <div className='d-flex justify-content-around mt-5'>
          <ButtonPrimary onClick={e => props.onClick(e, 'XML')} title='XML' className='my-0' />
          <ButtonPrimary onClick={e => props.onClick(e, 'CSV')} title='CSV' className='my-0' />
        </div>
      </Modal.Body>
    </Modal>
  );
};

MeterReadsHistoryPeriodModal.defaultProps = defaultProps;

export default MeterReadsHistoryPeriodModal;
