import React from 'react';
import ReactSelect from 'react-select';
import { tableFilterStyles } from './TableFilterStyles';

export type TableFilterOption = {
  value: string | number | null;
  label: string;
};

type TableFilterOptionsData = TableFilterOption[];

interface ITableFilter {
  options: TableFilterOptionsData;
  placeholder?: string;
  icon?: string;
  value?: TableFilterOption | null;
  isClearable: boolean;
  isSearchable?: boolean;
  defaultValue?: TableFilterOption;
  onChange(el: TableFilterOption): void;
}

const TableFilter: React.FC<ITableFilter> = ({
  value,
  options,
  placeholder,
  isClearable,
  isSearchable,
  defaultValue,
  onChange,
  ...props
}) => (
  <div className='' {...props}>
    <ReactSelect
      value={value}
      styles={tableFilterStyles}
      options={options}
      placeholder={placeholder}
      autoFocus={false}
      onChange={onChange as any}
      isClearable={isClearable}
      blurInputOnSelect={true}
      isSearchable={isSearchable}
      defaultValue={defaultValue}
    />
  </div>
);

export default TableFilter;
