import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import { ButtonPrimary, Icon } from '../../../common/components';
import moment from 'moment';

type HalfHourlyDataFailureModalProps = {
  show: boolean;
  onHide: () => void;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  show: true,
};

const HalfHourlyDataFailureModalWrapper = styled.div``;

export const HalfHourlyDataFailureModal = (props: HalfHourlyDataFailureModalProps) => {
  const obj = props.messagevalues.json;

  const accountReference = obj.AccountReference;
  const requestDateTime = moment(obj.RequestDateTime);

  const title = 'Half-hourly data failure';
  const messageTop = `We weren't able to retrieve the data you requested for account ${accountReference} on ${requestDateTime.format(
    'DD MMM YYYY'
  )}.`;
  const messageBottom = 'Please re-request the data.';

  const handleClose = (e: any) => {
    e.preventDefault();
    props.onHide();
  };

  return (
    <HalfHourlyDataFailureModalWrapper>
      <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
        <Modal.Title>
          <span className='pr-4'>
            <Icon color={colors.draxBtn} size={50} icon='download2' />
          </span>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
        <p>{messageTop}</p>
        <p>{messageBottom}</p>
      </Modal.Body>

      <Modal.Footer className='p-0'>
        <div className='d-flex justify-content-end'>
          <ButtonPrimary className='m-0' title='OK' onClick={handleClose} />
        </div>
      </Modal.Footer>
    </HalfHourlyDataFailureModalWrapper>
  );
};

HalfHourlyDataFailureModal.defaultProps = defaultProps;
