// Components
import { Icon } from '../../../../../common/components';

// Styles
import { Setup } from './SetupButton.styles';

type SetupButtonProps = {
  onClick: () => void;
};

export const SetupButton = ({ onClick }: SetupButtonProps) => {
  const handleClick = (event: any) => {
    event.preventDefault();
    onClick();
  };

  return (
    <Setup onClick={handleClick}>
      <Icon size={32} icon='arrow-right' />
      Set up Direct Debit
    </Setup>
  );
};
