import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { ButtonPrimary, Icon, ModalClose } from '../../../common/components';
import { TagManager } from '../../utils/analytics/TagManager';

type ContactUsModalProps = {
  isDirectCustomer: boolean;
  isTpiCustomer: boolean;
  contactEmail: string;
  contactNumber: string;
  fixed?: boolean;
  show: boolean;
  onHide: any;
};

const defaultProps = {
  isDirectCustomer: true,
  isTpiCustomer: false,
  contactEmail: '',
  contactNumber: '',
  show: true,
};

export const ContactUsWrapper = styled.div`
  .paymentBtn {
    width: 300px;
    margin: auto;
    display: block;
  }
`;

export const ContactUsModal = (props: ContactUsModalProps) => {
  const { show } = props;

  const directUserTitleTop = 'Please use our online payment facility';
  const directUserTitleBottom = 'If you need help to pay your balance';
  const directUserHeaderTop = 'This will open an external web page to allow you to make a secure payment';
  const directUserHeaderBottom = 'Please contact our collections team to see how we can support you';
  const directUserFooter =
    "If you have a query regarding your latest invoice(s), or want to discuss a query you've previously raised, please get in touch using the contact details on your invoices.";

  const tpiUserTitle = 'If your Customer needs help to pay their balance';
  const tpiUserHeader = 'Please contact our collections team to see how we can support them';
  const tpiUserFooter =
    'If you have a query regarding their latest invoice(s), or want to discuss a query previously raised, please get in touch using the contact details on the invoice.';

  const paymentLink = 'https://drax.mysecurepay.co.uk/';

  const handlePaymentClick = () => {
    TagManager.pushData({
      event: 'External payment (Accounts & Sites)',
    });
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <ContactUsWrapper>
          <Modal.Header className='pt-0 pb-0 pr-0'>
            <Modal.Title>{props.isTpiCustomer && <>{tpiUserTitle}</>}</Modal.Title>
            <ModalClose onClick={() => props.onHide()} />
          </Modal.Header>
          <Modal.Body>
            {props.isDirectCustomer && (
              <p className='mb-1' style={{ fontWeight: 'bold' }}>
                {directUserTitleTop}
              </p>
            )}

            {props.isDirectCustomer && <p>{directUserHeaderTop}</p>}

            {props.isDirectCustomer && (
              <ButtonPrimary
                className='paymentBtn'
                href={paymentLink}
                onClick={handlePaymentClick}
                target='_blank'
                title='Make a payment'
              />
            )}

            {props.isDirectCustomer && (
              <p className='pt-3 mb-0' style={{ fontWeight: 'bold' }}>
                {directUserTitleBottom}
              </p>
            )}

            <p className='pt-2'>
              <>{props.isDirectCustomer && directUserHeaderBottom}</>
              <>{props.isTpiCustomer && tpiUserHeader}</>
            </p>

            <div className='pb-2'>
              <div className='pl-4' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                <Icon size={17} icon={'envelope'} />
                <a className='pl-2' href={`mailto:${props.contactEmail}`}>
                  {props.contactEmail}
                </a>
              </div>

              <div className='pl-4'>
                <Icon size={17} icon={'headset'} />
                <a className='pl-2' href={`tel:${props.contactNumber}`}>
                  {props.contactNumber}
                </a>
              </div>
            </div>

            <div className='pt-3' style={{ fontStyle: 'italic' }}>
              <p>
                <>{props.isDirectCustomer && directUserFooter}</>
                <>{props.isTpiCustomer && tpiUserFooter}</>
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonPrimary onClick={() => props.onHide()} className={'m-0'} title='Ok' route='#' />
          </Modal.Footer>
        </ContactUsWrapper>
      </Modal>
    </>
  );
};

ContactUsModal.defaultProps = defaultProps;
