// Modules
import { useContext, useState } from 'react';

// Styles
import { BrokerLandingSubmitLoaWrapper } from './BrokerLandingSubmitLoa.styles';

// Components
import { ButtonPrimary } from '../../../common/components';
import { UploadLoAModal } from '../../mymessages/modalTemplates/LoA/UploadLoAModal';
import { LoAUploadCompleteModal } from '../../mymessages/modalTemplates/LoA/LoAUploadCompleteModal';
import { SubmissionFailedModal } from '../../mymessages/modalTemplates/SubmissionFailedModal';

// Context
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

// Api
import { getTpiByTpiReference, uploadLoaFiles } from '../../../common/api/tpiApi';

// Utils
import { useAuthState } from '../../../providers/authProvider';
import { toast } from 'react-toastify';
import ProgressBarModal from '../../../common/components/ProgressBarModal/ProgressBarModal';
import { TagManager } from '../../utils/analytics/TagManager';

type BrokerLandingSubmitLoaProps = {};

const defaultProps = {};

export const BrokerLandingSubmitLoa = (props: BrokerLandingSubmitLoaProps) => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const [loAUploadCompleteSuccess, setLoAUploadCompleteSuccess] = useState<boolean>(false);
  const [showLoAUploadCompleteModal, setShowLoAUploadCompleteModal] = useState<boolean>(false);

  const [tpiReference, setTpiReference] = useState<string>('');
  const [tpiName, setTpiName] = useState<string>('');
  const [showUploadLoAModal, setShowUploadLoAModal] = useState<boolean>(false);
  const [showFailureModal, setShowFailureModal] = useState<boolean>(false);

  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressBarComplete, setProgressBarComplete] = useState<number>(0);

  const handleShowModalSubmitLoAClick = async (event: any) => {
    event.preventDefault();

    TagManager.pushData({
      event: 'Launch submit LoA (Landing page)',
    });

    const result = await getTpiByTpiReference(process.env.REACT_APP_API_URL, authContext);

    if (result && result.success) {
      const data = result.data;

      setTpiReference(data.tpiReference);
      setTpiName(data.description);
    } else {
      const message = 'Unable to get TPI details';
      toast.error(message, { position: 'bottom-right' });
      throw new Error(message);
    }

    setShowUploadLoAModal(true);
  };

  const onUploadProgress = (percentCompleted: number) => {
    setShowProgressBar(true);
    setProgressBarComplete(percentCompleted);
  };

  const handleUpload = async (params: Api.ILoaFileUpload) => {
    const result: any = await uploadLoaFiles(process.env.REACT_APP_API_URL, authContext, params, onUploadProgress);

    setShowProgressBar(false);

    let success = false;

    if (result && result.status == '200') {
      success = true;
    }
    
    if (success) {
      TagManager.pushData({
        event: 'Success LoA (Landing page)',
      });
      setLoAUploadCompleteSuccess(true);
      setShowLoAUploadCompleteModal(true);
    } else {
      TagManager.pushData({
        event: 'Failure LoA (Landing page)',
      });
      setLoAUploadCompleteSuccess(false);
      setShowFailureModal(true);
    }
  };

  return (
    <>
      <BrokerLandingSubmitLoaWrapper {...props}>
        <div className='brokerLandingPageSubmitLoa'>
          <div className='d-flex align-items-center'>
            <h4 className='mt-0 font-weight-bold'>Can't find your customer?</h4>
          </div>
          <div>
            <p className='access-message'>
              We'll provide access when we have a valid Letter of Authority.
              <br />
            </p>
            <p>
              Submit a copy of each Letter of Authority to our Servicing team. We'll <br />
              validate and process them when we receive them.
            </p>
          </div>
          <div className='d-flex justify-content-end usageLink'>
            <ButtonPrimary title='Submit LoA' className='mt-auto mb-auto' onClick={handleShowModalSubmitLoAClick} />
          </div>
        </div>

        <div className='brokerLandingPageSubmitLoaMobile'>
          <div className='d-flex align-items-center'>
            <h4 className='mt-0 font-weight-bold'>Can't find your customer?</h4>
          </div>
          <div>
            <p className='access-message'>
              We'll provide access when we have a valid Letter of Authority.
              <br />
            </p>
            <p>
              Submit a copy of each Letter of Authority to our Servicing team. We'll <br />
              validate and process them when we receive them.
            </p>
          </div>
          <div className='d-flex justify-content-end usageLink'>
            <ButtonPrimary title='Submit LoA' className='mt-auto mb-auto' onClick={handleShowModalSubmitLoAClick} />
          </div>
        </div>
      </BrokerLandingSubmitLoaWrapper>

      {showUploadLoAModal && (
        <UploadLoAModal
          show={true}
          tpiName={tpiName}
          tpiReference={tpiReference}
          submittedBy={userDetail.emailAddress}
          upload={handleUpload}
          onHide={() => {
            setShowUploadLoAModal(false);
          }}
        />
      )}

      {showLoAUploadCompleteModal && (
        <LoAUploadCompleteModal
          success={loAUploadCompleteSuccess}
          show={true}
          onHide={() => {
            setShowLoAUploadCompleteModal(false);
          }}
        />
      )}

      {showProgressBar && (
        <ProgressBarModal
          show={true}
          title={'Letter of Authority files'}
          message={['Uploading...']}
          onClose={() => {}}
          percentageComplete={progressBarComplete}
        />
      )}

      <SubmissionFailedModal
        show={showFailureModal}
        onHide={() => {
          setShowFailureModal(false);
        }}
      ></SubmissionFailedModal>
    </>
  );
};

BrokerLandingSubmitLoa.defaultProps = defaultProps;
