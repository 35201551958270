import React, { useContext, useState } from 'react';

import { GlobalContext, GlobalDetailContextProps } from '../../context/globalContext/globalContext';
import { Checkbox, Sort, Filter } from '../../common/components';

type MyMessagesFiltersProps = {
  options: Array<any>;
  selectAll: boolean;
  handleFilterChange: Function;
  handleSort: Function;
  handleSelectAll: Function;
};

const defaultProps = {
  options: [],
  selectAll: false,
};

const MyMessagesFilters = (props: MyMessagesFiltersProps) => {
  const { globalDetail } = useContext<GlobalDetailContextProps>(GlobalContext);
  const [sortDetails] = useState<Common.ISort>({ field: '', order: 'ASC' });

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const value = event.currentTarget.dataset.value || '';
    let details = sortDetails;
    details.field = value;
    if (details.order === 'ASC') {
      details.order = 'DESC';
    } else {
      details.order = 'ASC';
    }
    props.handleSort(details);
  };

  const handleSelectAll = () => {
    props.handleSelectAll();
  };

  return (
    <tr className='select-item'>
      <th className='p-0'></th>
      <th className='pl-0' scope='col'>
        <Checkbox id='selectAllMessages' onChange={handleSelectAll} checked={props.selectAll} />
      </th>
      <th scope='col' className='sort date'>
        <Sort
          title='Date received'
          dataValue={'Date'}
          sortDetails={sortDetails}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            handleSort(event);
          }}
        />
      </th>
      <th scope='col' className='sort accountReference'>
        <Sort
          title='Account reference'
          sortDetails={sortDetails}
          dataValue={'AccountReference'}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            handleSort(event);
          }}
        />
      </th>
      <th scope='col' className='title'>
        <span className='no-sort'>Title</span>
      </th>
      <th scope='col' className='sort subject'>
        <Sort
          title='Subject'
          sortDetails={sortDetails}
          dataValue={'Subject'}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            handleSort(event);
          }}
        />
      </th>
      <th scope='col'></th>
    </tr>
  );
};

MyMessagesFilters.defaultProps = defaultProps;

export default MyMessagesFilters;
