import styled from 'styled-components';
import * as mixins from '../../common/styles/mixins';

export const UserDetailsAboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 33px;

  .overviewMobile {
    ${mixins.widgetMobile};
  }

  .overviewDesktop {
    ${mixins.widgetDesktop};
  }

  a {
    color: ${({ theme }) => theme.text.secondary};
  }
`;
