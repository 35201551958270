// Components
import { ButtonPrimary } from '../../../../common/components';
import { Icon } from '../../../../common/components';
import { Upload, CircleExclamation } from '../../../icons';
import { SuccessSubmitBulkMetersModal } from './SuccessSubmitBulkMetersModal';
import { RestrictSubmitBulkMetersModal } from './RestrictSubmitBulkMetersModal';
import { FailureSubmitBulkMetersModal } from './FailureSubmitBulkMetersModal';

// Styles
import { SubmitMeterDownloadFormWrapper } from './SubmitMeterDownloadForm.styles';

// Context
import { useAuthState } from '../../../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../../../context/userDetail/userDetailContext';

// Utils
import { TagManager } from '../../../utils/analytics/TagManager';
import { useContext, useRef, useState } from 'react';

// Api
import { submitBulkMeterReads } from '../../../../common/api/meterReadsApi';
import { UploadingIssuesModal } from '../../../home/SubmitMeterReading/UploadingIssuesModal/UploadingIssuesModal';

export const DownloadForm = () => {
  const authContext = useAuthState();
  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const reportDownloadEvent = () => {
    TagManager.pushData({
      event: 'Download bulk meter read form',
    });
  };

  const inputFile = useRef<HTMLInputElement>(null);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showUploadingIssuesModal, setShowUploadingIssuesModal] = useState(false);
  const [showRestrictionModal, setShowRestrictionModal] = useState<boolean>(false);
  const [showFailureModal, setShowFailureModal] = useState<boolean>(false);
  const [failureMessages, setFailureMessage] = useState<Array<string>>();

  const [moreInformationRead, setMoreInformationRead] = useState<boolean>(false);
  const [moreInformationUploader, setMoreInformationUploader] = useState<boolean>(false);

  const selectFile = () => {

    TagManager.pushData({
      event: 'Upload bulk meter reads (meter read sub)'
    });

    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleTemplate = () => {
    TagManager.pushData({
      event: 'Download bulk read template (meter read sub)'
    });
  }

  const link = (
    <a href='/files/Meter_read_Template.csv' onClick={handleTemplate} download>
      template
    </a>
  );

  const handleUploadFile = async (e: any) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);

    setFailureMessage([]);

    const result = await submitBulkMeterReads(
      process.env.REACT_APP_API_URL,
      authContext,
      formData,
      userDetail.essCustomerId
    );

    if (result.status === 200) {
      setShowSuccessModal(true);
    } else if (result.status === 400) {
      setShowFailureModal(true);
      setFailureMessage(result.data);
    } else if (result.status === 503) {
      setShowRestrictionModal(true);
      setFailureMessage(result.data);
    }
    e.target.value = null;
  };

  const alignBaseline = moreInformationRead != moreInformationUploader;

  return (
    <SubmitMeterDownloadFormWrapper className={`${alignBaseline && 'align-items-baseline'}`}>
      <div className='info-block'>

        {/* xl desktop */}
        <div className='d-none d-xl-block'>
          <div className='d-flex'>
            <h4 className='mr-auto' style={{ textDecoration: 'underline' }}>Enter a read one MPAN at a time</h4>
            
            <div className='moreInformationBtn' onClick={() => setMoreInformationRead(!moreInformationRead)}>
              {moreInformationRead ? 'Less information ' : 'More information '}
                <Icon size={15} className='chevron' icon={moreInformationRead ? 'chevron-up' : 'chevron-down'} />
            </div>
          </div> 
          
          <p className='pt-3'>Use the tool below to submit individual reads by MPAN.</p>
          <br />
        </div>

        {/* mobile */}
        <div className='d-block d-xl-none'>
          <h4 className='' style={{ textDecoration: 'underline' }}>Enter a read one MPAN at a time</h4>

          <p className='pt-3 pb-2'>Use the tool below to submit individual reads by MPAN.</p>

          <div className='moreInformationBtn' onClick={() => setMoreInformationRead(!moreInformationRead)}>
            {moreInformationRead ? 'Less information ' : 'More information '}
              <Icon size={15} className='chevron' icon={moreInformationRead ? 'chevron-up' : 'chevron-down'} />
          </div>
        </div>

        {moreInformationRead ? <>
          <p>
            Select the read date from the popup calendar and enter the read in the box below the relevant 'Register ID'.
          </p>
          <br />
          <p>
            Select 'Confirm meter read' to submit - you'll see a popup message if there are any issues with your
            submission.
          </p>
        </> : <></>
        }
      </div>

      <div className='info-block'>

        {/* xl desktop */}
        <div className='d-none d-xl-block'>
          <div className='d-flex'>
            <h4 className='mr-auto' style={{ textDecoration: 'underline' }}>Use the bulk Meter read uploader</h4>
            
            <div className='moreInformationBtn' onClick={() => setMoreInformationUploader(!moreInformationUploader)}>
              {moreInformationUploader ? 'Less information ' : 'More information '}
                <Icon size={15} className='chevron' icon={moreInformationUploader ? 'chevron-up' : 'chevron-down'} />
            </div>
          </div>
          <div>
            <div className='d-flex pt-3'>
              <div className='mr-auto'>
                <a
                  href=''
                  onClick={e => {
                    TagManager.pushData({
                      event: 'Launch upload issue (meter read sub)'
                    });
                    setShowUploadingIssuesModal(true);
                    e.preventDefault();
                  }}
                >
                  {' '}
                  <Icon icon={CircleExclamation} size={40} className='mr-1' />
                  Having issues uploading a file?
                </a>
              </div>

              <div>
                <div className='action-row'>
                  <ButtonPrimary onClick={selectFile} route='#'>
                    <Icon icon={Upload} size={18} className='mr-2' />
                    Upload meter reads .csv file
                  </ButtonPrimary>
                  <input
                    type='file'
                    accept='.csv'
                    id='file'
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={handleUploadFile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className='d-block d-xl-none'>
          
          <h4 className='' style={{ textDecoration: 'underline' }}>Use the bulk Meter read uploader</h4>

          <div className='mr-auto pt-2 pb-3'>
            <a
              href=''
              onClick={e => {
                TagManager.pushData({
                  event: 'Launch upload issue (meter read sub)'
                });
                setShowUploadingIssuesModal(true);
                e.preventDefault();
              }}
            >
              {' '}
              <Icon icon={CircleExclamation} size={40} className='mr-1' />
              Having issues uploading a file?
            </a>
          </div>
                    
          <ButtonPrimary onClick={selectFile} route='#'>
            <Icon icon={Upload} size={18} className='mr-2' />
            Upload meter reads .csv file
          </ButtonPrimary>
          <input
            type='file'
            accept='.csv'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleUploadFile}
          />

          <div className='moreInformationBtn pt-4' onClick={() => setMoreInformationUploader(!moreInformationUploader)}>
            {moreInformationUploader ? 'Less information ' : 'More information '}
              <Icon size={15} className='chevron' icon={moreInformationUploader ? 'chevron-up' : 'chevron-down'} />
          </div>
        </div>

        <div>
          {showSuccessModal && <SuccessSubmitBulkMetersModal onClose={() => setShowSuccessModal(false)} />}
          {showUploadingIssuesModal && <UploadingIssuesModal onClose={() => setShowUploadingIssuesModal(false)} />}
          {showRestrictionModal && (
            <RestrictSubmitBulkMetersModal
              onClose={() => setShowRestrictionModal(false)}
              failureMessages={failureMessages}
            />
          )}
          {showFailureModal && (
            <FailureSubmitBulkMetersModal
              onClose={() => setShowFailureModal(false)}
              failureMessages={failureMessages}
            />
          )}
        </div>

        {moreInformationUploader ? <>
            <p className='pt-3'>
              To submit multiple meter reads at once, enter them in our handy {link} and then upload it using the button
              below.
            </p>
            <br />
            <p>
              If you'd prefer to use your own file, please make sure it contains the following columns:
              <strong> MPAN, Meter serial number, Register ID, Read date, Read.</strong>
            </p>
          </> : <></>
        }
      </div>
    </SubmitMeterDownloadFormWrapper>
  );
};
