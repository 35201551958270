const Account = ({ width, height, className, fillColor }: AccountProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.1919 23.1921L30.9999 31.0002'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M14 27.0001C21.1798 27.0001 27.0001 21.1798 27.0001 14C27.0001 6.82031 21.1798 1 14 1C6.82031 1 1 6.82031 1 14C1 21.1798 6.82031 27.0001 14 27.0001Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M20 20V19.0175C20 18.3075 19.6275 17.6545 19.0165 17.293C18.044 16.7175 16.358 16 14 16C11.6115 16 9.9365 16.713 8.974 17.2875C8.368 17.6495 8 18.3005 8 19.0065V20H20Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M14 13C15.6569 13 17 11.6569 17 10C17 8.34315 15.6569 7 14 7C12.3431 7 11 8.34315 11 10C11 11.6569 12.3431 13 14 13Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type AccountProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

Account.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2A3745',
};

export default Account;
