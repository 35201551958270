import styled from 'styled-components';

export const IndividualSitesWrapper = styled.div`
  .text {
    margin-bottom: 60px;

    h6 {
      margin-top: 40px !important;
    }
  }
`;
