import { IUserDetail } from '../context/userDetail/userDetail';
import { isDraxBrand } from '../utils/common';

export type meteringType = "AllHH" | "AllNHH" | "Mixed" | undefined;

export const getSelectedCustomerId = (userDetail: IUserDetail, showNhhAndMixed: boolean = false) => {
  let customerAccounts = userDetail.customerAccounts;
  if (showNhhAndMixed) {
    customerAccounts = customerAccounts.filter(user => (user.meteringPointType === 'Mixed' || user.meteringPointType === 'AllNHH'));
  }
  // DraxBrand - Multiple customers set the id to '' so that the grid doesn't auto populate and the 'Select customer account' appears.
  // OpusBrand - Multiple customers works differently due to the dropdown on the menu.
  const selectedCustomerId = isDraxBrand
    ? customerAccounts?.length === 1
      ? customerAccounts[0].essAccountID
      : ''
    : userDetail.essCustomerId;

  return selectedCustomerId;
};

export const getCustomerList = (customers: Array<Common.IOption | Common.IExpandedCustomerOption> ): Array<string> => {
  let customersList: Array<string> = [];
  customers.map((customer: Common.IOption) => {
    customersList.push(customer.value);
  });
  return customersList;
}

export const getCustomerListFromAccounts = (customers: Array<Api.IEssAccountData> ): Array<string> => {
  let customersList: Array<string> = [];
  customers.map((customer: Api.IEssAccountData) => {
    customersList.push(customer.essAccountID);
  });
  return customersList;
}

export const getHHandNHHforCustomers = (customersWithMeteringPointType: Array<Api.ICustomerMeteringPointType>): Common.ICustomersMeteringPointTypes => {
  let hhMeteringPointTypeOnly: boolean = true;
  let nhhMeteringPointTypeOnly: boolean = true;

  if (customersWithMeteringPointType) {
    customersWithMeteringPointType.map((customerMeteringPointType: Api.ICustomerMeteringPointType) => {
      const type = customerMeteringPointType['meter-point-type'];
      if (type == 'AllHH') {
        nhhMeteringPointTypeOnly = false;
      } else if (type == 'AllNHH') {
        hhMeteringPointTypeOnly = false;
      } else {
        hhMeteringPointTypeOnly = false;
        nhhMeteringPointTypeOnly = false;
      }
    });
  }

  return {
    hhMeteringPointTypeOnly,
    nhhMeteringPointTypeOnly
  }
}

export const getMeteringPointType = (customersWithMeteringPointType: Array<any>, customer: string): meteringType => {
  let meteringPointType: meteringType = undefined;
  if (customersWithMeteringPointType) {
    const customerMeteringType = customersWithMeteringPointType.find(t => t['customer-account-reference'] === customer);
    if (customerMeteringType) {
      meteringPointType = customerMeteringType?.['meter-point-type'] as meteringType;

      // If meteringPointType is null we presume it's Mixed
      if (!meteringPointType) {
        meteringPointType = 'Mixed';
      }
    }
  }
  return meteringPointType;
}