// Modules
import React, { useState, useEffect, useContext, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../common/styles/mixins';

// Components
import { ButtonPrimary, Loading, RowSelector, NoResults, Sort } from '../../../common/components';

// Context
import { MovingPremisesProcessContext } from '../movingPremisesProcess/movingPremisesProcessContext';

// Styles
import { ChooseSiteWrapper } from './ChooseSite.styles';

// Utils
import { TagManager } from '../../utils/analytics/TagManager';

// Api
import { globalApiParams } from '../../../common/api/globals';

const StyledTableWrapper = styled.div`
  ${respondTo.tablet`
    padding-right: 33px;
  `};
`;

type ChooseSiteComponentProps = {
  selectedType: string;
  gridData: Api.ISitesForMovingPremisesData[];
  handleSortChange: Function;
  handlePagingChange: any;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
};

const defaultProps = {
  isLoading: true,
  gridData: [],
  selectedType: 'Electricity',
  hasMoreData: true,
  currentSortField: 'essSiteID',
  currentSortOrder: 'DESC',
};

export const ChooseSite = (props: ChooseSiteComponentProps) => {
  const history = useHistory();
  const { gridData, selectedType, isLoading } = props;
  const { setSelectedSites } = useContext<any>(MovingPremisesProcessContext);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const scrollableTable = useRef<any>();

  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }
  }, [props.gridData]);

  useEffect(() => {
    clearSelection();
  }, [selectedType]);

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
    setCheckedItems([]);
  };

  const handleRowSelect = (row: number | null) => {
    if (checkedItems?.length > 0) {
      return;
    }

    if (row !== undefined) {
      setSelectedRow(row);
      setExpandRow(false);
      if (row !== null) {
        TagManager.pushData({
          event: 'Select Site to COT',
        });
      }
    }
  };

  const siteHeaders = () => {
    return (
      <thead>
        <tr>
          <th scope='col' className='sort account'>
            <Sort
              title='Site reference'
              dataValue={'site-reference'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'site-reference')}
            />
          </th>
          <th scope='col' className='sort address'>
            <Sort
              title='Site address'
              dataValue={'site-address'}
              sortDetails={sortDetails}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => handleSort(event, 'site-address')}
            />
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const onUpdateClick = (event: React.MouseEvent<HTMLAnchorElement>, index: number) => {
    handleUpdateStep(index);
    event.preventDefault();
  };

  const siteRows = () => {
    const sitesData: Api.ISitesForMovingPremisesData[] = gridData as Api.ISitesForMovingPremisesData[];
    const items = sitesData.map((item: Api.ISitesForMovingPremisesData, index: number) => {
      return (
        <React.Fragment key={index}>
          <RowSelector
            className={`item ${selectedRow === index ? 'selected' : ''} ${expandRow ? 'open' : ''}`}
            onClick={() => {
              if (selectedRow !== index) {
                handleRowSelect(index);
              } else {
                handleRowSelect(null);
              }
            }}
          >
            <td className='account-col'>{item.siteReference}</td>
            <td className='site-address-col'>{item.siteAddress}</td>
            <td className='actions-wrapper text-right'>
              <ButtonPrimary title='Update' className='col m-0' onClick={(event: any) => onUpdateClick(event, index)} />
            </td>
          </RowSelector>
        </React.Fragment>
      );
    });
    return items;
  };

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement>, typeId: string) => {
    clearSelection();
    let details = sortDetails;
    if (details.field === typeId) {
      details.order = details.order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }

    props.handleSortChange(details);
    event.preventDefault();
  };

  const getSelectedSites = (indexes: number[]) => {
    return gridData.filter((_item: any, index: number) => indexes.includes(index));
  };

  const handleUpdateStep = (singleAccountIndex?: number) => {
    const selectedItems = singleAccountIndex !== undefined ? [singleAccountIndex] : checkedItems;
    const sitesToProceed = getSelectedSites(selectedItems);

    setSelectedSites(sitesToProceed);
    history.push('moving-premises/newaddress');
  };

  const handlePagingChange = () => {
    props.handlePagingChange();
  };

  return (
    <ChooseSiteWrapper>
      {!isLoading && gridData.length === 0 && <NoResults />}

      {gridData.length > 0 && (
        <>
          <InfiniteScroll
            dataLength={props.gridData.length}
            next={handlePagingChange}
            hasMore={props.hasMoreData}
            loader={''}
            scrollableTarget='scrollableDiv'
            scrollThreshold={0.9}
          >
            <StyledTableWrapper
              id='scrollableDiv'
              ref={scrollableTable}
              className='table-responsive table-fixed fixed-column columns-1 pl-4'
            >
              <table className='list table plain'>
                <>
                  {siteHeaders()}
                  <tbody>{siteRows()}</tbody>
                </>
              </table>
            </StyledTableWrapper>
          </InfiniteScroll>
        </>
      )}

      {isLoading && <Loading overlay />}
    </ChooseSiteWrapper>
  );
};

ChooseSite.defaultProps = defaultProps;
