const WarningInverse = ({ width, height, className, fillColor, style }: WarningInverseProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='32' height='32' fill='white' fill-opacity="0" />
    <line x1="16" y1="12" x2="16" y2="16.8554" stroke="#216EF2" stroke-width="2" stroke-linecap="round"/>
    <circle cx="16" cy="20.2" r="1.2" fill="#216EF2"/>
    <path
      d="M16.1879 24.8119C21.0547 24.7082 24.9159 20.6787 24.8121 15.8119C24.7083 10.9452 20.6788 7.08401 15.8121 7.1878C10.9453 7.29159 7.08414 11.321 7.18793 16.1878C7.29172 21.0546 11.3212 24.9157 16.1879 24.8119Z"
      stroke="#216EF2"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"/>
  </svg>
);

export type WarningInverseProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
  style?: any;
};

WarningInverse.defaultProps = {
  width: 32,
  height: 32,
};

export default WarningInverse;
