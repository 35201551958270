import { useContext } from 'react';

import { DirectDebitProcessContext } from '../directDebitProcess/directDebitProcessContext';
import { AccountsWrapper } from './DDFillForm.styles';

const HeaderPart = (
  <>
    <h4>Selected account</h4>
    <p>Any credit applied to your account will be applied before any payment is taken.</p>
  </>
);

const FooterPart = ({ type }: { type: 'Electricity' | 'Gas' }) => (
  <>
    <p>
      The company name which will appear on your bank statement against the Direct debit will be Opus
      {type === 'Electricity' ? ' Energy Ltd.' : ' Gas Supply Ltd.'}
    </p>
    <p>
      Your Direct Debit instruction will be confirmed to you via email within 3 working days or not later than 10
      working days. Any changed to frequency or amount of your collections will be advised to you 5 days in advance.
    </p>
  </>
);

const DueBalanceInfo = ({ balance }: { balance: number }) => (
  <>
    <div className='balance-number'>£{balance === null ? '0' : balance < 0 ? '-' : balance}</div>
    <div className='balance-info'>
      {balance < 0 && 'This account is in credit'}
      {(balance === 0 || balance === null) && 'No outstanding balance'}
    </div>
  </>
);

const SingleAccountView = ({ account }: { account: Api.IAccountsForDDSetupData }) => (
  <div className='account-summary'>
    <div>
      {HeaderPart}

      <div className='single-acc-details'>
        <div>
          <div className='column-label'>Account</div>
          <div className='column-value'>{account.essAccountID}</div>
        </div>

        <div>
          <div className='column-label'>Account name</div>
          <div className='column-value'>{account.accountName}</div>
        </div>
      </div>
    </div>

    <div className='single-acc-payment-info'>
      <h4>Payment being made</h4>
      <DueBalanceInfo balance={account.dueBalance} />
    </div>
  </div>
);

const MultiAccountView = ({ accounts }: { accounts: Api.IAccountsForDDSetupData[] }) => (
  <>
    {HeaderPart}

    <div className='account-table'>
      <div className='table-header'>
        <div className='col-account column-label'>Account</div>
        <div className='col-account-name column-label'>Account name</div>
        <div className='col-balance column-label'>Payment being made</div>
      </div>

      {accounts.map(acc => (
        <div className='multi-acc-details' key={acc.essAccountID}>
          <div className='col-account column-value'>{acc.essAccountID}</div>
          <div className='col-account-name column-value'>{acc.accountName}</div>
          <div className='col-balance column-value'>
            <div className='label-mobile column-label'>Payment being made</div>
            <div className='balance-wrapper'>
              <DueBalanceInfo balance={acc.dueBalance} />
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
);

export const SelectedAccounts = () => {
  const { selectedAccounts: accounts } = useContext<any>(DirectDebitProcessContext);
  const isSingleAccount = accounts.length === 1;

  return (
    <AccountsWrapper>
      {isSingleAccount ? <SingleAccountView account={accounts[0]} /> : <MultiAccountView accounts={accounts} />}

      <FooterPart type={accounts[0].product} />
    </AccountsWrapper>
  );
};
