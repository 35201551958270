export const hideCharacters = (str: string, charsToShow: number) => {
  if (!str) {
    return null;
  }

  const splitted = str.split('');
  const length = splitted.length;

  if (length > charsToShow) {
    const hiddenPart = splitted.slice(0, length - charsToShow).map(() => '*');
    const visiblePart = splitted.slice(length - charsToShow, length);

    return [...hiddenPart, ...visiblePart].join('');
  }

  return str;
};
