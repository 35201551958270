import { AuthConsumer } from '../../providers/authProvider';
import { isDraxBrand, isOpusBrand } from '../../utils/common';

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return (
        <>
          {isDraxBrand && <div className='p-4'>Redirecting to Drax Energy...</div>}
          {isOpusBrand && <div className='p-4'>Redirecting to SSP...</div>}
        </>
      );
    }}
  </AuthConsumer>
);
