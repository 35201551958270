import { useEffect, useState } from 'react';

import { useAuthState } from './providers/authProvider';
import useEventListener from './hooks/useEventListener';

export const RefreshToken = () => {
  // how often the token is refreshed if user is active
  const REFRESH_TOKEN_INTERVAL_MILLISECONDS = 120000; // 2 minutes

  const authContext = useAuthState();
  const [timerFired, setTimerFired] = useState<boolean>(false);
  const [keyDownChanged, setKeyDownChanged] = useState<boolean>(false);
  const [mouseDownChanged, setMouseDownChanged] = useState<boolean>(false);

  const handler = (event: any) => {
    if (event.type === 'keydown') {
      setKeyDownChanged(true);
    } else if (event.type === 'mousedown') {
      setMouseDownChanged(true);
    }
  };

  useEventListener('keydown', handler);
  useEventListener('mousedown', handler);

  useEffect(() => {
    // if user is active refresh token
    if (keyDownChanged || mouseDownChanged) {
      if (authContext) {
        authContext.refreshToken();
      }
    }
    setKeyDownChanged(false);
    setMouseDownChanged(false);
    setTimerFired(false);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerFired]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerFired(true);
    }, REFRESH_TOKEN_INTERVAL_MILLISECONDS);

    return () => {
      window.clearInterval(timer);
    };
  }, []);

  return <></>;
};
