import { useContext, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, FilterDropDown } from '../../common/components';
import * as variables from '../../common/styles/variables';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

type SelectAccountModalProps = {
  accounts: Common.IOption[];
  onCustomerSelect: Function;
  customerSelectedIndex?: number;
};

export const ContentWrapper = styled.div`
  a {
    display: block;
    margin: ${variables.spacingMedium} 0 0;
  }
`;

export const SelectAccountModal = (props: SelectAccountModalProps) => {
  const [selectedAccount, setSelectedAccount] = useState<Common.IOption>(null as unknown as Common.IOption);
  const selectedIndex = props.customerSelectedIndex || 0;

  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const handleClick = (e: any) => {
    e.preventDefault();

    const essCustomerId = (selectedAccount && selectedAccount.value) || props.accounts[selectedIndex].value;

    setUserDetail({
      ...userDetail,
      essCustomerId,
    });

    props.onCustomerSelect(selectedAccount || props.accounts[selectedIndex]);
  };

  return (
    <>
      <Modal animation={false} className={'mid-size-modal'} show={true} size={'lg'} backdrop='static' centered>
        <Modal.Header>
          <Modal.Title>Select customer account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ContentWrapper>
            <FilterDropDown
              enabled={true}
              selectText={'Please select customer account'}
              showValue={false}
              options={props.accounts}
              showSelectedValue={true}
              handleChange={(item: Common.IOption) => setSelectedAccount(item)}
              selectedIndex={selectedIndex}
              selectedValue={props.accounts[selectedIndex]?.value}
            />
            <ButtonPrimary title='Continue' onClick={handleClick} className='submit-btn'></ButtonPrimary>
          </ContentWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
};
