import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, ModalClose } from '../../../common/components';
import { HelpSupport } from '../../header/HelpSupport';

type HelpSupportModalProps = {
  onHide: Function;
  show: boolean;
  title: string;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Error',
};

const HelpSupportModal = (props: HelpSupportModalProps) => {
  return (
    <>
      <Modal.Header>
        {/* <Modal.Title>{props.title}</Modal.Title> */}
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <HelpSupport
          isComponentVisible={props.show}
          handleClose={() => {
            props.onHide();
          }}
        ></HelpSupport>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          route='/'
          title='Close'
          onClick={event => {
            event.preventDefault();
            props.onHide();
          }}
          className='btn-primary m-0'
        ></ButtonPrimary>
      </Modal.Footer>
    </>
  );
};

HelpSupportModal.defaultProps = defaultProps;

export default HelpSupportModal;
