// Mixins
// @ts-nocheck
import { css } from 'styled-components';
import * as variables from './variables';
import * as colors from './colors';

export const respondTo = Object.keys(variables.breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${variables.breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {}) as any;

export const clearfix = css`
  &:after {
    clear: both;
    content: '';
    display: table;
  }
`;

export const noTouchSelect = css`
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* prevent tap highlight color*/
  -webkit-tap-highlight-color: transparent; /* prevent tap highlight color*/
  -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
  outline: none;

  &:focus {
    outline: none;
  }

  ${respondTo.tablet`
    -webkit-touch-callout: unset; /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: unset; /* prevent webkit from resizing text to fit */
    -webkit-user-select: text; /* prevent copy paste, to allow, change 'none' to 'text' */
    outline: initial;
  `};
`;

export const inputBorderFocus = css`
  border: 2px solid ${({ theme }) => theme.rowinput.border};
  &:focus {
    border: 2px solid ${({ theme }) => theme.primary.border};
    outline: none;
  }
`;

export const borderBox = css`
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

export const borderRadiusNone = css`
  border-radius: 0;
`;

export const borderNone = css`
  border: none;
`;

export const borderPrimary = css`
  border: 1px solid ${colors.midGrey};
`;

export const borderRadiusFull = css`
  border-radius: 500px;
`;

export const boxShadowPrimary = css`
  box-shadow: 0px 5px 5px 0px rgba(199, 199, 199, 1);
`;

export const boxShadowLight = css`
  box-shadow: 0px 2px 5px rgba(10, 20, 100, 0.2);
`;

export const dropShadow = css`
  filter: drop-shadow(1px 1px 3px ${colors.midGrey});
`;

export const textWrap = css`
  overflow-wrap: anywhere;
`;

// TODO
// convert to sc if possible
// @mixin selection {
//     ::selection { @content; }
// }

// TODO
// see if we can pass props to this
// speed, animationType etc
// currently the name of the mixin is not helpful
export const transition = css`
  transition: all 150ms ease-in;
`;

export const transitionFast = css`
  transition: all 80ms ease-in;
`;

export const scrollbars = css`
  /* chrome */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.primary.bg};
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.lightGreyAccent};
  }

  /* IE */
  & {
    scrollbar-face-color: ${({ theme }) => theme.primary.bg};
    scrollbar-track-color: ${colors.lightGreyAccent};
  }

  /* Firefox */
  & {
    scrollbar-color: ${({ theme }) => theme.primary.bg} #e0e0e0;
    scrollbar-width: thin;
  }
`;

export const hideScrollbars = css`
  /* firefox */
  scrollbar-width: none;

  /* ie and edge */
  -ms-overflow-style: none;

  /* chrome, safari, opera */
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const roundedPanel = css`
  ${boxShadowPrimary};
  background-color: ${({ theme }) => theme.bg.secondary};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingSmall};
  margin-bottom: ${variables.spacingMedium};

  ${respondTo.desktop`
    padding: ${variables.spacingMedium};
  `};
`;

export const gradientToBottom = css`
  &:after {
    content: ' ';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95) 100%);
    position: fixed;
    pointer-events: none;
    right: 0;
    height: 6em;
    bottom: 0;
    width: 100%;
    z-index: ${variables.zIndexHigh + 1};

    ${respondTo.tablet`
      width: calc(100% - ${variables.menuWidth});
    `};
  }
`;

export const widgetMobile = css`
  background-color: ${colors.lightGrey};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingSmall} ${variables.spacingMedium};

  ${respondTo.tablet`
    display: none;
  `};

  .hidden-dropdown {
    display: none;
  }

  .active-dropdown {
    z-index: ${variables.zIndexHighest};
  }

  .menu-list {
    margin-top: 10px;

    .item {
      padding-bottom: 0px;
    }
  }

  .menu-dropdown {
    .chevron {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }
`;

export const widget = css`
  ${boxShadowPrimary};
  display: none;
  background-color: ${({ theme }) => theme.bg.secondary};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingMedium};

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const widgetDesktop = css`
  ${boxShadowPrimary};
  display: none;
  background-color: ${({ theme }) => theme.bg.secondary};
  border-radius: ${variables.globalRadius};
  padding: ${variables.spacingMedium};

  ${respondTo.tablet`
    display: flex;
    flex-direction: column;
    flex: 1;
  `};
`;

export const flipHorizontal = css`
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
`;

export const textTruncate = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;
