import styled from 'styled-components';
import { Link } from 'react-router-dom';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const StyledLink = styled(
  ({ textOnly, disabled, enabledStatus, disabledStatus, lockedStatus, borderRadiusType, ...props }) => {
    if (typeof props.href === 'string') {
      // TODO:  Might want to remove this and revisit this problem later
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return <a {...props} />;
    }
    return <Link {...props} />;
  }
)`
  ${mixins.transition};
  font-weight: ${props => props.theme.button.fontWeight};
  color: ${colors.white} !important;
  text-decoration: none;
  padding: 0.5rem 2rem !important;
  white-space: nowrap;
  margin: 30px 0;
  text-align: center !important;
  border: 2px solid ${props => props.theme.primary.border};
  background: ${props => props.theme.primary.bg};
  --hovercolor: ${props => props.theme.primary.hovercolor};

  // disabledStatus
  ${({ disabledStatus, theme }) =>
    disabledStatus && {
      'border': `2px solid green`,
      'color': `green !important`,
      'background': colors.white,
      '--hovercolor': theme.primary.bg,
    }};
  // lockedStatus
  ${({ lockedStatus, theme }) =>
    lockedStatus && {
      'border': `2px solid red`,
      'color': `red !important`,
      'background': colors.white,
      '--hovercolor': theme.primary.bg,
    }};
  // Text only
  ${({ textOnly }) =>
    textOnly && {
      'border': 'none',
      'background': 'none',
      'color': 'unset!important',
      '--hovercolor': 'transparent',
    }}

  // Border radius
  ${({ borderRadiusType, theme }) => {
    let borderRadius = theme.primary.btnBorderRadius;

    if (borderRadiusType === 'squared') {
      borderRadius = variables.btnBorderRadiusSquare;
    } else if (borderRadiusType === 'none') {
      borderRadius = '0';
    }
    return { 'border-radius': borderRadius };
  }}

  &:hover {
    background: ${({ theme }) => theme.primary.hovercolor};
    border: 2px solid ${({ theme }) => theme.primary.hovercolor};
    color: ${colors.white};
    text-decoration: none;
    // disabledStatus
    ${({ disabledStatus }) =>
      disabledStatus && {
        color: `${colors.white}!important`,
      }};
    // lockedStatus
    ${({ lockedStatus }) =>
      lockedStatus && {
        color: `${colors.white}!important`,
      }};
    // Text only
    ${({ textOnly, theme }) =>
      textOnly && {
        color: `${theme.button.textOnlyHoverColor}!important`,
        background: 'none',
        border: 'none',
      }}

    span {
      border: 2px solid ${({ theme }) => theme.primary.border};
      color: ${colors.white};
      text-decoration: none;

      // disabledStatus
      ${({ disabledStatus, theme }) =>
        disabledStatus && {
          border: 'none',
          color: `green`,
        }};
      // lockedStatus
      ${({ lockedStatus, theme }) =>
        lockedStatus && {
          border: 'none',
          color: `red`,
        }};
      // Text only
      ${({ textOnly }) =>
        textOnly && {
          border: 'none',
          color: 'initial',
        }}
    }
  }

  // anchors dont have disabled so have to use props
  ${props =>
    props.disabled === true && {
      'opacity': 0.4,
      'cursor': 'default',
      'pointer-events': 'none',
    }};
`;
