import { useEffect, useState, useContext } from 'react';
// TODO:  waiting for BE integration
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { DirectDebitProcessContext } from '../directDebitProcess/directDebitProcessContext';
import { ButtonPrimary, FormInput, FormSelect, Checkbox } from '../../../../common/components';
import {
  accountNumberError,
  sortCodeError,
  ddGuarantee,
  ddGuaranteeAdditional,
  contactInfo,
  authorisedPersonConfirmation,
} from './BillingForm.messages';
import { BlockWrapper, BillingDetailsWrapper, CheckboxWrapper, ActionRowWrapper } from './DDFillForm.styles';
import { useAuthState } from '../../../../providers/authProvider';
import { getBanksList } from '../../../../common/api/directDebitApi';
import { DATE_FORMAT } from '../../../../common/constants';

const SERVICE_USER_NUMBER_ELEC = '948515';
const SERVICE_USER_NUMBER_GAS = '433605';

type Inputs = {
  serviceUserName: string;
  reference: string;
  date: string;
  nameOfHolder: string;
  societyAccountNumber: number;
  societySortCode: string;
  bankName: string;
  addressLine1: string;
  addressLine2: string;
  postcode: string;
};

export const BillingForm = () => {
  const history = useHistory();
  const { selectedAccounts: accounts, setAccountNumber, setSortCode } = useContext<any>(DirectDebitProcessContext);
  const authContext = useAuthState();
  const [bankOptions, setBankOptions] = useState<any>([]);

  const accountType = accounts[0].product;
  const companyName = `Opus ${accountType === 'Electricity' ? 'Energy' : 'Gas Supply'} Ltd`;
  const methods = useForm<Inputs>({
    defaultValues: {
      serviceUserName: accountType === 'Electricity' ? SERVICE_USER_NUMBER_ELEC : SERVICE_USER_NUMBER_GAS,
      reference: accounts.length > 1 ? '' : accounts[0].essAccountID,
      date: moment().format(DATE_FORMAT),
    },
  });

  // TODO finish integration with API
  // const submitHandler: SubmitHandler<Inputs> = data => {
  //   console.log('Data to submit: ', data);
  // };

  const getBankOptions = async () => {
    const result = await getBanksList(process.env.REACT_APP_API_URL, authContext);
    const options = result && result.map(bank => ({ value: bank.description, label: bank.description }));

    setBankOptions(options);
  };

  useEffect(() => {
    getBankOptions();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitClick = (event: any) => {
    event.preventDefault();

    const values = methods.getValues();

    setAccountNumber(values.societyAccountNumber);
    setSortCode(values.societySortCode);

    // TEMP: waiting for BE integration
    // methods.handleSubmit(submitHandler)();
    history.push('review');
  };

  return (
    <FormProvider {...methods}>
      <form>
        <BlockWrapper>
          <h4>Instruction to your bank or building society to pay via Direct Debit</h4>

          <div className='formRowWrapper'>
            <FormInput name='serviceUserName' label='Service user number' disabled={true} />
          </div>

          <div className='formRowWrapper'>
            <FormInput name='reference' label='Reference' disabled={true} />
          </div>

          <p>
            Please pay {companyName} Direct Debits from the account detailed in this Instruction subject to the
            safeguards assured by the Direct Debit Guarantee. I understand that this Instruction may remain with{' '}
            {companyName} and, if so, details will be passed electronically to my bank/building society.
          </p>

          <div className='formRowWrapper'>
            <FormInput name='date' label='Date' disabled={true} />
          </div>
        </BlockWrapper>

        <BlockWrapper>
          <h4>Billing details</h4>

          <BillingDetailsWrapper>
            <div>
              <div className='formRowWrapper'>
                <FormInput name='nameOfHolder' label='Name of account holder' params={{ required: true }} />
              </div>
              <div className='formRowWrapper'>
                <FormInput
                  name='societyAccountNumber'
                  label='Bank/Building society account number'
                  params={{ required: true, pattern: { value: /^\d{8}$/, message: accountNumberError } }}
                />
              </div>
              <div className='formRowWrapper'>
                <FormInput
                  name='societySortCode'
                  label='Bank/Building society sort code'
                  params={{ required: true, pattern: { value: /^\d{6}$/, message: sortCodeError } }}
                />
              </div>
              <div className='formRowWrapper'>
                <FormSelect
                  name='bankName'
                  label='Name and full postal address of Bank/Building society'
                  placeholder='Select or type bank name'
                  options={bankOptions}
                  isSearchable={true}
                  params={{ required: true }}
                />
              </div>
              <div className='formRowWrapper'>
                <FormInput name='addressLine1' label='Address line 1' params={{ required: true }} />
              </div>
              <div className='formRowWrapper'>
                <FormInput name='addressLine2' label='Address line 2' />
              </div>
              <div className='formRowWrapper'>
                <FormInput name='postcode' label='Postcode' params={{ required: true }} />
              </div>
              <CheckboxWrapper>
                <Checkbox
                  id='isAccountHolder'
                  label='Please can you confirm that you are the account holder and therefore the payer, and the only person required to authorise debits from this account.'
                  onChange={(event: any) => {}}
                  checked={false}
                />
              </CheckboxWrapper>
            </div>

            {contactInfo}
          </BillingDetailsWrapper>

          {authorisedPersonConfirmation}
        </BlockWrapper>

        <BlockWrapper>
          <h4>The Direct Debit Guarantee</h4>

          {ddGuarantee(companyName)}
          {ddGuaranteeAdditional}

          <ActionRowWrapper>
            <ButtonPrimary title='Submit' onClick={onSubmitClick} className='m-0 submit-btn'></ButtonPrimary>
          </ActionRowWrapper>
        </BlockWrapper>
      </form>
    </FormProvider>
  );
};
