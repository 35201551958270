import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';

export const UsersWrapper = styled.div`
  .users-list {
    tr {
      position: relative;

      &.open {
        + .hidden {
          display: table-row !important;
        }
      }
    }

    .sub-row {
      td {
        background-color: ${colors.lightGrey};
        border-top: 1px solid ${colors.midGrey};
      }

      p {
        margin: 0;
      }

      .primary-access-icon {
        padding-bottom: 4px;
      }
    }

    .trigger {
      position: absolute;
      right: ${variables.spacingSmall};
      top: ${variables.spacingSmall};
    }

    .btn-wrapper {
      padding: 0 3em 0 0;
    }

    .actions-wrapper {
      padding-right: 3em;
      position: relative;

      .cta-btn {
        pointer-events: none !important;
      }
    }
  }
`;
