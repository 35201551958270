import { useState } from 'react';
import { ModalTemplate } from '../mymessages/modalTemplates/ModalTemplate';
import { UserDetailsAboutWrapper } from './UserDetailsAbout.styles';

const defaultProps = {};

export const UserDetailsAbout = () => {
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  const handleShowHelpModal = () => {
    setShowHelpModal(!showHelpModal);
  };

  const handleCloseHelpModal = () => {
    setShowHelpModal(false);
  };

  return (
    <>
      <UserDetailsAboutWrapper>
        <div className='overviewDesktop'>
          <span>
            View contacts and manage their online and offline access to your energy account. If you require access,
            please contact your account's primary contact or get in touch with our&nbsp;
            <a
              href='/'
              title=' customer service team'
              onClick={event => {
                handleShowHelpModal();
                event.preventDefault();
              }}
            >
              customer service team
            </a>
          </span>
        </div>
        <div className='overviewMobile'>
          <span>
            View contacts and manage their online and offline access to your energy account. If you require access,
            please contact your account's primary contact or get in touch with our&nbsp;
            <a
              href='/'
              title=' customer service team'
              onClick={event => {
                handleShowHelpModal();
                event.preventDefault();
              }}
            >
              customer service team
            </a>
          </span>
        </div>
      </UserDetailsAboutWrapper>
      <ModalTemplate name={'HowToGetHelpModal'} show={showHelpModal} onHide={handleCloseHelpModal} />
    </>
  );
};

UserDetailsAbout.defaultProps = defaultProps;
