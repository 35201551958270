import { css } from 'styled-components';
import * as variables from '../variables';
import * as mixins from '../mixins';
import * as colors from '../colors';
import { respondTo } from '../mixins';
import { listStyles } from './list.styles';

export const modalStyles = css`
  /* Modal */
  .custom-modal {
    ${listStyles};
    background-color: ${colors.white};

    ${respondTo.mobile`
      background-color: transparent;
    `};
  }

  .calendar-modal {
    ${respondTo.tablet`
      .modal-dialog {
        max-width: fit-content;
      }
    `};

    .modal-content {
      padding: ${variables.spacingSmall};
    }
  }

  .mid-size-modal {
    ${respondTo.tablet`
      .modal-dialog {
        width: 500px;

        .wrapperdropdown .dropdown {
          width: calc(100% + 30px);
          max-width: 500px;
        }
      }
    `};
  }

  .modal-content {
    ${mixins.borderRadiusNone};
    ${mixins.borderNone};
    display: block;
    padding: ${variables.spacingLarge} ${variables.spacingMedium};
    height: 100%;

    ${respondTo.tablet`
      border-radius: 1em;
      padding: ${variables.spacingMedium};
    `};
  }

  .modal-header {
    ${mixins.borderRadiusNone};
    text-align: left;
    padding: 0.5em 0;
    border-bottom: none;
    font-weight: bold;

    ${respondTo.tablet`
      margin: 0;
      padding: 1em 0;
    `};
  }

  .modal-title {
    font-size: ${variables.fontSizeLargeX};
    line-height: 1.25;
    margin: 0 auto;
    text-align: center;

    ${respondTo.tablet`
      margin: 0 0 ${variables.spacingXSmall};
      text-align: left;
    `};
  }

  .modal-title-center {
    margin: 0px auto;
    text-align: center;
  }

  .modal-body {
    padding: 0;
    text-align: left;
  }

  .modal-footer {
    ${mixins.borderNone};
    background-color: ${colors.white};
    border-radius: 0;
    bottom: 0;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;
    
    padding-right: 15px;
    padding-bottom: 15px;

    ${respondTo.mobile`
      position: inherit;
    `};

    ${respondTo.tablet`
      justify-content: flex-end;
      padding-right: 0px;
      padding-bottom: 0px;
    `};

    a {
      margin: 0;
      text-align: center;
      width: 100%;

      ${respondTo.mobile`
        width: auto;
      `};

      &:first-child {
        margin: 0 0 ${variables.spacingSmall} 0;

        ${respondTo.mobile`
          margin: 0 ${variables.spacingSmall} 0 0;
        `};
      }
    }
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 1;

    &.close-mobile {
      right: auto;
      left: 0px;
    }
  }

  /*
  Mobile only media query
  This allows bootstrap to reset at larger screen sizes
  This would be better in a mixin/function
  */
  @media (min-width: ${variables.breakpoints.min + 1}px) and (max-width: ${variables.breakpoints.tablet}px) {
    .modal-dialog {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }

  .modal-backdrop {
    background-color: ${colors.oxfordBlue} !important;
  }

  .modal-open {
    // Need this to prevent page scroll position issue when modal is opened
    overflow: unset !important;

    .modal {
      ${mixins.scrollbars};
    }
  }

  .message-settings {
    height: 60vh;
    ${mixins.scrollbars};
  }

  .mymessages-settings-modal {
    tbody:after {
      background: none !important;
    }
    .modal-dialog {
      ${respondTo.tablet`
        max-width: calc(100% - 6em);
      `};

      ${respondTo.desktop`
        max-width: calc(${variables.breakpoints.desktop}px - 6em);
      `};

      ${respondTo.desktopXl`
        max-width: 1140px;
      `};
    }

    .modal-content {
      padding-left: 0.2em;
      padding-right: 0.2em;

      ${respondTo.tablet`
        padding: 3em;
      `};
    }
  }

  .user-modal {
    tbody:after {
      background: none !important;
    }
    .modal-dialog {
      ${respondTo.tablet`
        max-width: calc(100% - 6em);
      `};

      ${respondTo.desktop`
        max-width: calc(${variables.breakpoints.desktop}px - 6em);
      `};

      ${respondTo.desktopXl`
        max-width: 800px;
      `};
    }

    .modal-content {
      padding-left: 0.2em;
      padding-right: 0.2em;

      ${respondTo.tablet`
        padding: 3em;
      `};
    }
  }

  .health-modal {

    .modal-content {
      ${respondTo.tablet`
        min-width: 400px;
      `};

      .modal-header {
        padding: 0;
      }
    }

    &.healthy {
      .modal-content {
        border 4px solid ${colors.healthy};
      }
    }

    &.notHealthy {
      .modal-content {
        border 4px solid ${colors.unhealthy};
      }
    }

    &.loading {
      .modal-content {
        border 4px solid ${colors.trueblack};
      }
    }
  }

  .modal-full-width {
    padding: 0 !important;

    .modal-dialog {
      max-width: initial;
    }

    .modal-content {
      padding: ${variables.spacingSmall};
    }

    .modal-body {
      padding: 0;
    }
  }
`;
