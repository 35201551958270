import styled from 'styled-components';
import * as variables from '../../../common/styles/variables';
import * as mixins from '../../../common/styles/mixins';
import { respondTo } from '../../../common/styles/mixins';

export const YourGenerationText = {
  header: 'Generation',
  headerBtn: 'Generation',
  para: 'The energy generated across all of your sites in the previous quarter.',
  kWh: 'kWh',
  statementsBtn: 'Statements',
};

export const GenerationOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .generationOverviewMobile {
    ${mixins.widgetMobile};
  }

  .generationOverview {
    ${mixins.widgetDesktop};
    h2 {
      text-align: center;
    }
  }

  --electotalamountcolor: ${({ theme }) => theme.amount.color};

  .elecGeneration {
    .category {
      float: left;
      padding: 8px 0 0;

      svg {
        fill: ${props => props.theme.text.secondary};
      }

      i {
        top: 22px;
        font-size: 2.7rem;
      }
    }

    .elecTotal {
      float: left;
      font-weight: bold;
      padding-top: 5px;
      padding-left: ${variables.spacingMedium};

      .amount {
        color: var(--electotalamountcolor);
        font-size: 2rem;
        font-weight: bold;
        overflow-wrap: anywhere;

        ${respondTo.mobile`
          font-size: ${variables.fontSizeXLarge};
        `};
      }

      .amountType {
        padding-left: ${variables.spacingSmall};
      }
    }
  }

  .period {
    font-weight: bold;
  }
`;
