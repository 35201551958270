import { useRouteMatch, Switch, Route } from 'react-router-dom';

import { ProcessStepIndicator } from '../../common/components';
import { DDChooseAccount } from './SMEDirectDebit/accounts-step';
import { DDFillForm } from './SMEDirectDebit/form-step';
import { DDReview } from './SMEDirectDebit/review-step';
import { DirectDebitProcessProvider } from './SMEDirectDebit/directDebitProcess/directDebitProcessProvider';
import { ProcessStepsWrapper } from './DirectDebit.styles';
import MakeCardPaymentConfirmation from '../invoicesandpayments/MakeCardPaymentConfirmation';

export const SMECustomerDirectDebit = () => {
  const { url, path } = useRouteMatch();

  const ProcessIndicator = (
    <ProcessStepsWrapper>
      <ProcessStepIndicator path={`${url}`} step='1' label='Choose account' />
      <ProcessStepIndicator path={`${url}/form`} step='2' label='Fill in form' />
      <ProcessStepIndicator path={`${url}/review`} step='3' label='Review details' />
      <ProcessStepIndicator path={`${url}/confirmation`} step='4' label='Confirmation' />
    </ProcessStepsWrapper>
  );

  return (
    <DirectDebitProcessProvider>
      <Switch>
        <Route exact path={path}>
          <DDChooseAccount stepsBar={ProcessIndicator} />
        </Route>
        <Route exact path={`${path}/form`}>
          <DDFillForm stepsBar={ProcessIndicator} />
        </Route>
        <Route exact path={`${path}/review`}>
          <DDReview stepsBar={ProcessIndicator} />
        </Route>
        <Route exact path={`${path}/confirmation`}>
          <MakeCardPaymentConfirmation />
        </Route>
      </Switch>
    </DirectDebitProcessProvider>
  );
};
