// Modules
import React from 'react';
import ChartJS from 'chart.js/auto';
import {
  ChartItem,
  BubbleDataPoint,
  ScatterDataPoint,
  ChartConfiguration,
  Chart as TypeChart,
  ChartType,
  ChartDataset,
} from 'chart.js';

// Styles
import { ChartWrapper } from './Chart.styles';
import { razzmatazzPink, hydroBlue } from '../../styles/colors';
import { barThickness } from '../../styles/global/chart.styles';

// Utils
import { getCustomTooltipHandler } from './utils';

// Types
import { BRANDS } from '../../../consts';

export type OnBarClick = (
  index: number,
  value: number | ScatterDataPoint | BubbleDataPoint | null,
  label: string
) => void;
interface ChartProps {
  units: string;
  labels: string[];
  datasets: ChartDataset[];
  onBarClick?: OnBarClick;

  type: ChartType;
  itemWidth?: number;
}

Chart.defaultProps = {
  type: 'bar',
  units: 'kWh',
  itemWidth: 10,
};

export function Chart(props: ChartProps) {
  // Constants
  const DEFAULT_CANVAS_MIN_WIDTH = 800;
  const DEFAULT_CANVAS_MIN_HEIGHT = 516;

  // Refs
  const chartInstanceRef = React.useRef<TypeChart | null>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  // Props
  const { type, units, labels, datasets, itemWidth, onBarClick } = props;

  // Handlers
  const parseDatasets = (data: ChartDataset[]): ChartDataset<any>[] => {
    let defaultColor: string;

    // Select default color for chart
    switch (process.env.REACT_APP_BRAND) {
      case BRANDS.DRAX:
        defaultColor = hydroBlue;
        break;
      case BRANDS.HAVEN: // TODO: Add color for 'haven' brand
      default:
        defaultColor = razzmatazzPink;
    }

    return data.map(dataset => ({
      ...dataset,
      // Set default color for each dataset if it doesn't have any
      backgroundColor: dataset.backgroundColor || defaultColor,
      barThickness,
    }));
  };

  const onChartClick = (e: any) => {
    if (typeof onBarClick === 'function' && chartInstanceRef.current) {
      const points = chartInstanceRef.current.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);

      if (points.length) {
        const [firstPoint] = points;
        const { index, datasetIndex } = firstPoint;
        const label = chartInstanceRef.current.data.labels?.[index];
        const value = chartInstanceRef.current.data.datasets[datasetIndex].data[index];

        onBarClick(index, value, label as string);
      }
    }
  };

  React.useLayoutEffect(() => {
    const updatedDatasets = parseDatasets(datasets);
    const chartOptions: ChartConfiguration = {
      type,
      data: {
        labels,
        datasets: updatedDatasets,
      },
      options: {
        onClick: onChartClick,
        maintainAspectRatio: false,
        scales: {
          // `y` === `yAxis`
          y: {
            beginAtZero: true,
          },
          // `x` === `xAxis`
          x: {
            grid: {
              lineWidth: 0,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
              labelOffset: -6,
            }
          },
        },
        plugins: {
          tooltip: {
            // https://www.chartjs.org/docs/latest/configuration/tooltip.html
            enabled: false,
            mode: 'point',
            external: getCustomTooltipHandler(units),
          },
          legend: {
            // https://www.chartjs.org/docs/latest/configuration/legend.html
            // We don't need this because we render it by ourselves
            display: false,
          },
        },
      },
    };
    const chartInstance: TypeChart = new ChartJS(canvasRef.current as ChartItem, chartOptions);
    if (containerRef.current) {
      // Scroll chart to bottom on first render
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    // Save chart instance to ref
    chartInstanceRef.current = chartInstance;
    return () => {
      // Destroy chart instance on unmount
      chartInstance.destroy();
    };
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.data.datasets = parseDatasets(datasets);
      chartInstanceRef.current.data.labels = labels;
      chartInstanceRef.current.update();
    }
  }, [datasets, labels]);

  // Get canvas final min width based on number of items in dataset
  // with most items
  const canvasMinWidth = (() => {
    try {
      let maxItems: number = 0;

      datasets.forEach(dataset => {
        if (dataset.data.length > maxItems) {
          maxItems = dataset.data.length;
        }
      });
      return itemWidth! * maxItems;
    } catch (error) {
      console.error(`Error occurred during canvas width calculations.`, error);
      return DEFAULT_CANVAS_MIN_WIDTH;
    }
  })();

  return (
    <ChartWrapper ref={containerRef}>
      <div
        style={{ minWidth: canvasMinWidth, minHeight: DEFAULT_CANVAS_MIN_HEIGHT }}
        className='canvasWrapper pl-sm-4 pl-0'
      >
        {units && (
          <div className='units d-none d-sm-flex align-items-center'>
            <span>{units}</span>
          </div>
        )}
        <canvas ref={canvasRef} />
      </div>
    </ChartWrapper>
  );
}
