import moment from 'moment';
import { toast } from 'react-toastify';

import { DataType } from './ChartTypes';
import { getMonthString } from '../../helpers/dateHelper';
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { Ranges } from '../../common/components/DateFilterModal';

export function fillEmptyMonths(
  closestDate: string,
  oldestDate: string,
  consumptions: Api.IDraxConsumptionData[] = []
) {
  const data: Api.IDraxConsumptionData[] = [...consumptions];
  // Get array with already filled months
  const filledDates = new Set(
    consumptions.map(({ intervalStart }) => {
      const date = moment(intervalStart).startOf('month');

      return `${date.year()}/${date.month()}`;
    })
  );

  try {
    const closest = moment(closestDate);
    const oldest = moment(oldestDate);
    // Get difference
    const diff = closest.diff(oldest, 'months');

    // If it's Monthly or Daily view we don't need to fill out
    if (diff <= 1) {
      return data;
    }
    for (let i = 0; i < diff; i += 1) {
      const current = moment(closest).startOf('month');
      const dateString = `${current.year()}/${current.month()}`;

      // If given month already contains data, don't add empty month
      if (!filledDates.has(dateString)) {
        data.push({
          totalConsumption: 0,
          intervalEnd: current.endOf('month').format('YYYY-MM-DDThh:mm:ss'),
          intervalStart: current.format('YYYY-MM-DDThh:mm:ss'),
          estimatedConsumption: 0,
          actualConsumption: 0,
          сonsumptionSource: 'HH',
        });
      }
      // Iterate through months from closest to oldest date
      closest.subtract(1, 'month');
    }
  } catch (error) {
    const message = 'Failed to fill up empty months, caught error.';

    console.error(message, error);
    toast.error(message);
  } finally {
    return data;
  }
}

export const parseConsumptionData = (consumptionData: any[], selectedRange: Ranges, onlyActualData?: boolean) => {
  // We just need arrays with the same length so further we can override values by index
  const actualData: (number | null)[] = [...consumptionData];
  const estimatedData: (number | null)[] = [...consumptionData];
  const monthToDateData: (number | null)[] = [...consumptionData];
  const labels: string[] = [];
  const dataByDateString = new Map<
    any,
    {
      day: number;
      year: number;
      hours: number;
      month: number;
      string: string;
      minutes: number;
      dataType: DataType;
      consumption: number;
    }
  >();
  const isIntervalMonth =
    selectedRange === Ranges['1year'] || selectedRange === Ranges['2years'] || selectedRange === Ranges['6months'];
  const isIntervalDay = selectedRange === Ranges['monthly'];
  const isHalfHourly = selectedRange === Ranges['daily'];

  try {
    // Iterate through each account data object
    // and collect required data to Map object
    consumptionData.forEach(data => {
      let consYear = 0;
      let consMonth = 0;
      let consDay = 0;
      let consValue = 0;
      let consHours = 0;
      let consMinutes = 0;
      let date; // Will be defined only for DRAX flow
      let key = '';
      let dataType: DataType = 'actual';

      if (isOpusBrand) {
        const opusData = data as Api.IGasMonthlyConsumptionData | Api.IElecMonthlyConsumptionData;

        consYear = opusData.year;
        consMonth = opusData.month;
        consValue = opusData.consumption;
      } else if (isDraxBrand) {
        const draxData: Api.IDraxConsumptionData | Api.IDraxHHPeriod = data as any;
        const currentDate = moment();

        if ('intervalStart' in draxData) {
          date = moment(draxData.intervalStart);
          consValue = draxData.totalConsumption;
          if (!onlyActualData && draxData.estimatedConsumption > draxData.actualConsumption) {
            dataType = 'estimated';
          }
        } else {
          date = moment(draxData.periodStart);
          consValue = draxData.consumptionValue;
          if (!onlyActualData && draxData.isEstimated) {
            dataType = 'estimated';
          }
        }

        if (!onlyActualData) {
          const monthDifference = currentDate.diff(date, 'months');

          if (monthDifference < 1 && monthDifference > -1 && currentDate.month() === date.month()) {
            dataType = 'month-to-date';
          }
        }

        consYear = date.year();
        consMonth = date.month() + 1; // For OPUS months started from "1", so we should add 1 here to correctly get month string
        consDay = date.date();
        consHours = date.hours();
        consMinutes = date.minutes();

        if ('intervalStart' in draxData) {
          key = `${consYear}/${consMonth}/${consDay}`;
        } else {
          key = date?.format('HH:mm');
        }
      }

      if (!dataByDateString.has(key)) {
        // If object doesn't have data for given key, add it
        let label = '';
        const monthString = getMonthString(consMonth, true);

        if (isIntervalMonth) {
          const shortYear = `${consYear}`.substring(2, 4);

          label = `${monthString} ${shortYear}`;
        } else if (isIntervalDay) {
          const dateStr = consDay < 10 ? `0${consDay}` : `${consDay}`;

          label = `${dateStr} ${monthString}`;
        } else if (isHalfHourly) {
          label = date?.format('HH:mm')!;
        }

        dataByDateString.set(key, {
          dataType,
          day: consDay,
          string: label,
          year: consYear,
          month: consMonth,
          hours: consHours,
          minutes: consMinutes,
          consumption: consValue,
        });
      } else if (dataByDateString.has(key)) {
        // If data is present, update consumption number
        const prevValue = dataByDateString.get(key)!;

        dataByDateString.set(key, { ...prevValue, consumption: prevValue.consumption + consValue });
      }
    });
    // Convert Map object to array
    const dataArr = Array.from(dataByDateString, ([, data]) => data).sort((a, b) => {
      if (isIntervalMonth) {
        return a.year + a.month / 12 - (b.year + b.month / 12);
      } else if (isIntervalDay) {
        return a.day - b.day;
      } else if (isHalfHourly) {
        return a.hours * 60 + a.minutes - (b.hours * 60 + b.minutes);
      }
      return 0;
    });

    // Iterate throught array and build data and labels arrays
    dataArr.forEach((item, index) => {
      // Based on data type we're adding consumption value to corresponding array
      // And in other arrays we set value to `null` so it won't take space on graph
      if (item.dataType === 'actual') {
        actualData[index] = item.consumption;
        estimatedData[index] = null;
        monthToDateData[index] = null;
      } else if (item.dataType === 'estimated') {
        actualData[index] = null;
        estimatedData[index] = item.consumption;
        monthToDateData[index] = null;
      } else if (item.dataType === 'month-to-date') {
        actualData[index] = null;
        estimatedData[index] = null;
        monthToDateData[index] = item.consumption;
      }
      labels[index] = item.string;
    });
  } catch (error) {
    const message = 'Failed to parse consumption data';

    console.error(message, error);
    toast.error(message);
  } finally {
    return { actualData, estimatedData, monthToDateData, labels };
  }
};

export const parsePeriodConsumptionData = (consumptionData: any[]) => {
  const actualData: (number | null)[] = [...consumptionData];
  const estimatedData: (number | null)[] = [...consumptionData];
  const monthToDateData: (number | null)[] = [...consumptionData];
  const labels: string[] = [];

  try {
    const dataByPeriod = new Map<
      any,
      {
        period: string;
        dataType: DataType;
        consumption: number;
      }
    >();

    consumptionData.forEach(data => {
      let consValue = 0;
      let dataType: DataType = 'actual';
      let key = '';

      const draxData: Api.IDraxHHPeriod = data as any;
      consValue = draxData.consumptionValue;

      if (draxData.isEstimated) {
        dataType = 'estimated';
      }

      key = draxData.period.toString();

      if (!dataByPeriod.has(key)) {
        dataByPeriod.set(key, {
          dataType,
          period: key,
          consumption: consValue,
        });
      } else if (dataByPeriod.has(key)) {
        const prevValue = dataByPeriod.get(key)!;
        dataByPeriod.set(key, { ...prevValue, consumption: prevValue.consumption + consValue });
      }
    });
    const dataArr = Array.from(dataByPeriod, ([, data]) => data);
    dataArr.forEach((item, index) => {
      if (item.dataType === 'actual') {
        actualData[index] = item.consumption;
        estimatedData[index] = null;
        monthToDateData[index] = null;
      } else if (item.dataType === 'estimated') {
        actualData[index] = null;
        estimatedData[index] = item.consumption;
        monthToDateData[index] = null;
      }
      labels[index] = item.period;
    });
  } catch (error) {
    const message = 'Failed to parse consumption data';

    console.error(message, error);
    toast.error(message);
  } finally {
    return { actualData, estimatedData, monthToDateData, labels };
  }
};
