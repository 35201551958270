import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Favicon from 'react-favicon';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.min.css'; // Only import bootstrap here globally in the head of the doc. It can be imported if needed to stories, but it is not required in other components

import { RoutesAuthenticate } from './routes/routesAuthenticate';
import { AuthProvider } from './providers/authProvider';
import GlobalStyles from './common/styles/global.styles';
import { themeToUse } from './common/styles/themes';
import { AppStyles } from './components/app/App.styles';
import { Fonts } from './common/components';
import { isDev, isDraxBrand, isOpusBrand } from './utils/common';
import './common/scss/app.scss';

const tagManagerConfig: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID!,
};

if (typeof tagManagerConfig.gtmId === 'string') {
  const mockedTagManagerId = localStorage.getItem('ga_mock');

  if (mockedTagManagerId) {
    tagManagerConfig.gtmId = mockedTagManagerId;
  }
  TagManager.initialize(tagManagerConfig);
} else if (isDev) {
  throw new Error('Google Tag Manager ID is missing in ".env"');
}

function App() {
  const Font = Fonts[`${process.env.REACT_APP_BRAND}`];

  return (
    <ThemeProvider theme={themeToUse}>
      {isDraxBrand && <Favicon url='./favicon-drax.ico' />}
      {isOpusBrand && <Favicon url='./favicon-opus.ico' />}
      <Font />
      <GlobalStyles />
      <ToastContainer hideProgressBar={true} />
      <AppStyles>
        <AuthProvider>
          <BrowserRouter children={RoutesAuthenticate} basename={`${process.env.PUBLIC_URL}`} />
        </AuthProvider>
      </AppStyles>
    </ThemeProvider>
  );
}

export default App;
