import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { DownloadBulkInvoicesDatePicker } from './DownloadBulkInvoicesDatePicker';
import { ButtonPrimary } from '../../../common/components';
import { DownloadBulkInvoicesWrapper, DownloadBulkInvoicesText } from './DownloadBulkInvoices.styles';
import { DateObject } from 'react-multi-date-picker';
import { HomeContext, HomeContextProps } from '../context/homeContext';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

import { bulkdownloadinvoice, getzippeddocuments } from '../../../common/api/homeApi';
import { useAuthState } from '../../../providers/authProvider';

import IcomoonReact from 'icomoon-react';
import iconSet from '../../../assets/selection.json';

type DownloadBulkInvoicesProps = {
  minDate?: Date | string | number | DateObject;
  maxDate?: Date | string | number | DateObject;
  customerstartdate?: Date | string | number | DateObject;
};

const defaultProps = {
  minDate: '',
  maxDate: new Date(),
  customerstartdate: '',
};

const WIDGET_KEY = 'DownloadBulkInvoices';

export const DownloadBulkInvoices = (props: DownloadBulkInvoicesProps) => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  function toggleActive() {
    let active = true;
    if (homeState.selectedCard === WIDGET_KEY) active = !homeState.active;

    setHomeState({ selectedCard: WIDGET_KEY, active: active });
  }

  const submitBulkDownloadInvoice = async () => {
    const result: Common.IResult<number[]> = await bulkdownloadinvoice(
      process.env.REACT_APP_API_URL,
      authContext,
      userDetail.essCustomerId,
      selectedMonth,
      selectedYear
    );

    if (result && result.success) {
      await getzippeddocuments(process.env.REACT_APP_API_URL, authContext, result.data);
    } else {
      const message = 'API error calling: bulkdownloadinvoice';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const onDateChange = (date: any) => {
    setSelectedMonth(date.month.number);
    setSelectedYear(date.year);
  };

  const isDropdownActive = homeState.active && homeState.selectedCard === WIDGET_KEY;

  return (
    <DownloadBulkInvoicesWrapper {...props}>
      <div className='DownloadBulkInvoicesOverview'>
        <h2 className='mt-0'>{DownloadBulkInvoicesText.header}</h2>
        <p>{DownloadBulkInvoicesText.downloadText}</p>
        <div className='d-flex align-items-end no-border full-width mt-auto innerWrapper'>
          <DownloadBulkInvoicesDatePicker minDate={props.minDate} maxDate={props.maxDate} onChange={onDateChange} />
        </div>
        <div className='d-flex justify-content-end'>
          <ButtonPrimary
            onClick={event => {
              event.preventDefault();
              submitBulkDownloadInvoice();
            }}
            title={DownloadBulkInvoicesText.downloadBtn}
            route='/home/energy-usage'
            className='m-0'
          />
        </div>
      </div>
      <div className='DownloadBulkInvoicesOverviewMobile'>
        <div onClick={toggleActive}>
          <h2>{DownloadBulkInvoicesText.headerBtn}</h2>
          <div className='menu-dropdown'>
            <IcomoonReact
              className='itemicon chevron'
              iconSet={iconSet}
              size={15}
              icon={isDropdownActive ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        <div className={isDropdownActive ? 'active-dropdown' : 'hidden-dropdown'}>
          <div className='menu-list row'>
            <div className='col-12 p-0'>
              <p className='col'>{DownloadBulkInvoicesText.downloadText}</p>
              <div className='d-flex align-items-end no-border full-width mt-auto innerWrapper'>
                <DownloadBulkInvoicesDatePicker
                  minDate={props.minDate}
                  maxDate={props.maxDate}
                  onChange={onDateChange}
                  className='rmdp-mobile'
                />
              </div>
              <div className='d-flex justify-content-end'>
                <ButtonPrimary
                  onClick={event => {
                    event.preventDefault();
                    submitBulkDownloadInvoice();
                  }}
                  title={DownloadBulkInvoicesText.downloadBtn}
                  route='/home/energy-usage'
                  className='col m-0 mt-2'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DownloadBulkInvoicesWrapper>
  );
};

DownloadBulkInvoices.defaultProps = defaultProps;
