import React, { useContext } from 'react';
import iconSet from '../../../assets/selection.json';
import IcomoonReact from 'icomoon-react';

import { ButtonPrimary } from '../../../common/components/ButtonPrimary/ButtonPrimary';
import { SmeAccountOverviewWrapper, UsageText } from './Usage.styles';
import { HomeContext, HomeContextProps } from '../context/homeContext';

type UsageProps = {
  gas: string;
  electricity: string;
};

const defaultProps = {};

export const Usage = (props: UsageProps) => {
  const { homeState, setHomeState } = useContext<HomeContextProps>(HomeContext);

  function toggleActive() {
    let active = true;
    if (homeState.selectedCard === 'Usage') active = !homeState.active;
    setHomeState({ selectedCard: 'Usage', active: active });
  }

  const isDropdownOpened = homeState.active && homeState.selectedCard === 'Usage';

  return (
    <SmeAccountOverviewWrapper {...props}>
      <div className='usageOverview'>
        <h2>{UsageText.header}</h2>
        <div className='usage d-flex align-items-center mt-3'>
          <div className='category'>
            <i className='iconRenewablesPortal'>
              <IcomoonReact iconSet={iconSet} size={50} icon='electricity' />
            </i>
          </div>
          <div className='total'>
            <span className='amount'>{props.electricity}</span>
            <span className='amountType'>{UsageText.electricitykWh}</span>
          </div>
        </div>
        <div className='usage d-flex align-items-center'>
          <div className='category'>
            <div>
              <i className='iconGasPortal'>
                <IcomoonReact iconSet={iconSet} size={50} icon='gas-02' />
              </i>
            </div>
          </div>
          <div className='total'>
            <span className='amount'>{props.gas}</span>
            <span className='amountType'>{UsageText.electricitykWh}</span>
          </div>
        </div>
        <div className='d-flex justify-content-end mt-auto'>
          <ButtonPrimary title={UsageText.usageBtn} route='/home/energy-usage/data' className='m-0' />
        </div>
      </div>
      <div className='usageOverviewMobile'>
        <div onClick={toggleActive}>
          <h2>{UsageText.headerBtn}</h2>
          <div className='menu-dropdown'>
            <IcomoonReact
              className='itemicon chevron'
              iconSet={iconSet}
              size={15}
              icon={isDropdownOpened ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        <div className={isDropdownOpened ? 'active-dropdown' : 'hidden-dropdown'}>
          <div className='usage mt-3 d-flex align-items-center'>
            <div className='category'>
              <i className='iconRenewablesPortal'>
                <IcomoonReact iconSet={iconSet} size={50} icon='electricity' />
              </i>
            </div>
            <div className='total'>
              <span className='amount'>{props.electricity}</span>
              <span className='amountType'>{UsageText.electricitykWh}</span>
            </div>
          </div>
          <div className='usage d-flex align-items-center mb-3'>
            <div className='category'>
              <div>
                <i className='iconGasPortal'>
                  <IcomoonReact iconSet={iconSet} size={50} icon='gas-02' />
                </i>
              </div>
            </div>
            <div className='total'>
              <span className='amount'>{props.gas}</span>
              <span className='amountType'>{UsageText.electricitykWh}</span>
            </div>
          </div>
        </div>
        <div className='d-flex usageLink mt-auto'>
          <ButtonPrimary title={UsageText.usageBtn} route='/home/energy-usage/data' className='col m-0' />
        </div>
      </div>
    </SmeAccountOverviewWrapper>
  );
};

Usage.defaultProps = defaultProps;
