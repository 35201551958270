import styled from 'styled-components';

import * as variables from '../../../../common/styles/variables';
import { respondTo } from '../../../../common/styles/mixins';

import { BlockWrapper } from '../form-step/DDFillForm.styles';

export const ReviewBlockWrapper = styled(BlockWrapper)`
  display: 'flex';

  h5 {
    font-size: ${variables.fontSizeMedium};
    color: ${({ theme }) => theme.text.secondary};
    margin-top: ${variables.spacingMedium};
  }
`;

export const AccountsReviewGridWrapper = styled.div`
  .headers {
    font-size: ${variables.fontSizeMedium};
    color: ${({ theme }) => theme.text.secondary};
    display: flex;
    flex-direction: row;
    margin-bottom: ${variables.spacingXSmall};
  }

  .rows {
    margin-bottom: ${variables.spacingMedium};
  }

  .account-row {
    display: flex;
    flex-direction: row;
    margin-bottom: ${variables.spacingSmall};
  }

  .headers,
  .account-row {
    span {
      padding-right: ${variables.spacingXSmall};
    }
  }

  .account-col {
    width: 38%;

    ${respondTo.tablet`
      width: 50%;
    `};

    svg {
      margin-right: ${variables.spacingXSmall};
    }
  }

  .account-number-col,
  .sort-code-col {
    width: 31%;

    ${respondTo.tablet`
      width: 25%;
    `};
  }
`;

export const SetupNotificationWrapper = styled(BlockWrapper)`
  display: flex;
  flex-direction: column;

  ${respondTo.tablet`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `};

  & > div {
    padding-right: ${variables.spacingMedium};
    margin-bottom: ${variables.spacingMedium};

    ${respondTo.tablet`
      margin-bottom: 0;
    `};
  }

  h4 {
    font-size: ${variables.fontSizeLargeX};
    margin: 0 0 ${variables.spacingXSmall};
  }

  p {
    margin: 0;
    font-size: ${variables.fontSizeMedium};
  }
`;
