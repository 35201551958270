import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import { ButtonPrimary } from '../../../common/components';

type InvoiceDownloadModalProps = {
  onClose: () => void;
  show: boolean;
  invoiceCount?: any;
};

const defaultProps = {
  show: true,
};

export const InvoiceDownloadWrapper = styled.div`
  .modal-title {
    color: ${colors.hydroBlue};
  }
`;

export const InvoiceDownloadModal = (props: InvoiceDownloadModalProps) => {
  const { show } = props;
  const title = "We've received your request";
  const messageTop = 'The file will start downloading shortly.';
  const messageMiddle = 'Due to the number of documents in your request, download may take a few minutes.';
  const messageBottom = 'You can continue using the portal in the meantime.';

  const onClose = (e: any) => {
    e.preventDefault();
    props.onClose();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <InvoiceDownloadWrapper>
          <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
            <p>{messageTop}</p>
            {props.invoiceCount >= 250 && <p>{messageMiddle}</p>}
            <p>{messageBottom}</p>
          </Modal.Body>
          <Modal.Footer className='p-0' style={{ justifyContent: 'center', textAlign: 'center' }}>
            <div className='d-flex mt-3'>
              <ButtonPrimary onClick={e => onClose(e)} title='OK' className='my-0' />
            </div>
          </Modal.Footer>
        </InvoiceDownloadWrapper>
      </Modal>
    </>
  );
};

InvoiceDownloadModal.defaultProps = defaultProps;
