import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ButtonPrimary, ModalClose } from '../../../common/components';

type MeterReadReminderProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Time to submit your meter reading',
  messagevalues: {},
};

const MeterReadReminder = (props: MeterReadReminderProps) => {
  return (
    <>
      <span className='font-medium'>
        {props.messagevalues.date}
        <span className='ml-2'>{props.messagevalues.time}</span>
      </span>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>{props.messagevalues.subject}</p>
        <p>Regularly submitting meter readings will help make sure that your bill is accurate.</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonPrimary
          onClick={() => {
            props.onHide();
          }}
          title='Submit meter reading'
          route='/home/meter-reads'
          className='m-0'
        ></ButtonPrimary>
      </Modal.Footer>
    </>
  );
};

MeterReadReminder.defaultProps = defaultProps;

export default MeterReadReminder;
