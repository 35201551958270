const ControlPanel = ({ width, height, className, fillColor }: ControlPanelProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.5 20.004H6.5'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M16 8.50403V10.504'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M24.1315 11.8726L22.7175 13.2866'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M27.5 20.004H25.5'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M14.232 18.236L8 12.004'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M15.9995 22.504C17.3802 22.504 18.4995 21.3847 18.4995 20.004C18.4995 18.6233 17.3802 17.504 15.9995 17.504C14.6188 17.504 13.4995 18.6233 13.4995 20.004C13.4995 21.3847 14.6188 22.504 15.9995 22.504Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
    <path
      d='M28.686 28.0041C30.1177 25.735 30.9145 23.1234 30.9935 20.4416C31.0725 17.7598 30.4307 15.1058 29.1351 12.7564C27.8395 10.407 25.9374 8.44809 23.6271 7.08386C21.3169 5.71962 18.683 5 16 5C13.317 5 10.6831 5.71962 8.37286 7.08386C6.0626 8.44809 4.16054 10.407 2.86491 12.7564C1.56928 15.1058 0.927515 17.7598 1.0065 20.4416C1.08549 23.1234 1.88234 25.735 3.314 28.0041H28.686Z'
      stroke={fillColor}
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
      style={{ fill: 'none' }}
    />
  </svg>
);

export type ControlPanelProps = {
  fillColor: string;
  className?: string;
  width: string | number;
  height: string | number;
};

ControlPanel.defaultProps = {
  width: 32,
  height: 32,
  fillColor: '#2A3745',
};

export default ControlPanel;
