import React from 'react';

import { StyledExternalLink } from './ExternalLinkStyles';

type ExternalLinkProps = {
  title: string;
  href: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  rel: string;
  className: string;
  target?: string;
};

const defaultProps = {
  title: '',
  href: '/',
  onClick: undefined,
  rel: 'noopener noreferrer',
  className: 'btn',
  target: '',
};

export const ExternalLink = (props: ExternalLinkProps) => (
  <StyledExternalLink {...props} title={''}>
    {props.title}
  </StyledExternalLink>
);

ExternalLink.defaultProps = defaultProps;
