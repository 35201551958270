import React from 'react';

const NoMatch = () => {
    return (
      <div className='col-12 no_stuff'>
          <h4 className='primary'>Page Not Found</h4>
          <p>This page can't be found please check the URL you entered and try again</p>
      </div>
    );
}
export default NoMatch;
