import Modal from 'react-bootstrap/Modal';

import { ModalClose } from '../../../common/components';

type MeterExpiredProps = {
  onHide: Function;
  show: boolean;
  title: string;
  messagevalues: Messages.IMessagesValues;
  phoneNumber?: string;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'Meter expiration alert',
  messagevalues: {},
  phoneNumber: '0845 263 7210', // TODO get this from API endpoint
};

export const MeterExpired = (props: MeterExpiredProps) => {
  return (
    <>
      <span className='font-medium'>
        {props.messagevalues.date}
        <span className='ml-2'>{props.messagevalues.time}</span>
      </span>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>We'd like to upgrade your meter</p>
        <p>
          Your meter needs to be replaced and we'd like to make arrangements to do this at a time that is convenient for
          you.
        </p>
        <p>{props.messagevalues.subject}</p>
        <div className='modal-title h4 text-bold'>To upgrade your meter</div>
        <p className='text-bold mt-3'>{`Contact our metering team on ${props.phoneNumber}`}</p>
      </Modal.Body>
      <Modal.Footer className='hidden'></Modal.Footer>
    </>
  );
};

MeterExpired.defaultProps = defaultProps;
