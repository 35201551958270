import { get } from '../../helpers/axiosWrapper';

interface IHildebrandParams {
  accountReferences: Array<string>;
}

export const getHildebrandTermsAndConditions = async (
  url: string | undefined,
  context: any
): Promise<Array<HildebrandApi.RootObject>> => {
  const headers: any = {};

  const apiUrl = 'hildebrand/termsconditions/current';

  return await get(`${url}/${apiUrl}`, { headers }, context);
};

export const getHildebrandRegister = async (
  url: string | undefined,
  context: any,
  accountReferences: Array<string>
): Promise<any> => {
  const headers: IHildebrandParams = {
    accountReferences: accountReferences,
  };

  var getHildebrandRegisterUrl = '/consumption/consent-health';

  return await get(url + getHildebrandRegisterUrl, { headers }, context);
};
