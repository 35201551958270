// Styles
import { ButtonPrimary } from '../../../common/components';
import { NotificationBoxWrapper } from './NotificationBox.styles';

type NotificationBoxProps = {
  boxText: string;
  buttonClassName: string;
  center: boolean;
  buttonText: string;
  onClick?: Function;
  showButton: boolean;
};

const defaultProps = {
  text: '',
  center: false,
};

export const NotificationBox = (props: NotificationBoxProps) => {
  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    props.onClick?.(event);
  };

  return (
    <NotificationBoxWrapper className='d-flex flex-column'>
        <>
          {!props.center &&
            <>
              <div className='notification-box'>
                <p className='mb-1'>{props.boxText}</p>
              </div>
              <div className='mt-auto'>
                {props.showButton &&
                  <ButtonPrimary className={props.buttonClassName} title={props.buttonText} onClick={onClick}></ButtonPrimary>}
              </div>
            </>
          }
          {props.center &&
            <>
              <div className='notification-box'>
                <div className='d-flex'>
                  <p className='pt-2'>{props.boxText}</p>
                  <div className='ml-auto mt-auto mb-auto'>
                    <div>
                      {props.showButton &&
                        <ButtonPrimary className={props.buttonClassName} title={props.buttonText} onClick={onClick}></ButtonPrimary>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
    </NotificationBoxWrapper>
  );
};

NotificationBox.defaultProps = defaultProps;
