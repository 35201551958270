import styled from 'styled-components';
import * as variables from '../../styles/variables';
import * as colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';

export const ButtonDownloadWrapper = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  position: relative;

  .icon {
    margin-top: -5px;

    &--disabled {
      opacity: 0.4;
    }
  }

  .nooutline {
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0rem 0.2em !important;
    font-weight: 400;
    color: ${({ theme }) => theme.text.secondary} !important;
  }

  a {
    background-color: ${colors.white};
    text-decoration: none;

    &.selected {
      color: ${({ theme }) => theme.text.secondary};
    }
  }

  .open {
    ~ ul {
      min-width: 60px;
      height: auto;
      overflow: auto;
    }
  }

  li {
    li {
      &:first-child a {
        border-top: 1px solid ${colors.lightGrey};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child a {
        border-bottom: 1px solid ${colors.lightGrey};
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  ul {
    ${mixins.boxShadowPrimary};
    border-radius: 0 0 5px 5px;
    border-bottom: none;
    border-top: none;
    height: 0;
    overflow: hidden;
    padding: 0;
    list-style: none;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: ${variables.zIndexHigh};

    a {
      color: ${colors.oxfordBlue};
      display: block;
      font-weight: bold;
      padding: 0.75em ${variables.spacingSmall};

      &:hover {
        ${mixins.transition};
        background-color: ${({ theme }) => theme.primary.bg};
        color: ${colors.white};
        text-decoration: none;
      }
    }
  }

  .dropdown__list {
    background-color: ${colors.white};
    z-index: ${variables.zIndexHighest + 1};
    margin-top: 6px;
  }
`;
