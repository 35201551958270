import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';

import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { ModalClose } from '../../../common/components';
import Contacts from '../../contacts/Contacts';
import { HowToGetHelpModalWrapper } from '../HowToGetHelpModal.styles';

type HowToGetHelpModalProps = {
  onHide: Function;
  show: boolean;
  title: string;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  title: 'How to get help',
};

const HowToGetHelpModal = (props: HowToGetHelpModalProps) => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  return (
    <HowToGetHelpModalWrapper>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <ModalClose onClick={() => props.onHide()} />
      </Modal.Header>
      <Modal.Body>
        <p>
          <p>
            <span>For technical help please visit the </span>
            <a href='http://www.opusenergy.com/MOE-User-Guide' target='_blank' rel='noopener noreferrer'>
              how to guide.
            </a>
          </p>
          <p>
            <span>For help with your account please see the </span>
            <a href='https://www.opusenergy.com/help/' target='_blank' rel='noopener noreferrer'>
              FAQ
            </a>
          </p>
        </p>
        <p>
          Still having trouble? Our Customer Service team is on hand to answer any of your questions or advise on
          concerns
        </p>
        <Contacts hasRenewableAccount={userDetail.hasRenewable} />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </HowToGetHelpModalWrapper>
  );
};

HowToGetHelpModal.defaultProps = defaultProps;

export default HowToGetHelpModal;
