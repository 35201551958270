import styled from 'styled-components';

export const DateFilterModalBody = styled.div`
  .date__btn {
    width: 100%;
    max-width: 150px;
    margin: 5px;
    border-radius: 8px;
    border-width: 1px;
    font-weight: 400;
  }
`;
