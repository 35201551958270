import styled from 'styled-components';
import * as mixins from '../../../common/styles/mixins';

export const DownloadBulkInvoicesText = {
  header: 'Download bulk invoices',
  headerBtn: 'Bulk invoice download',
  downloadBtn: 'Download',
  downloadText: 'Download your invoices in bulk by using this tool.',
};

export const DownloadBulkInvoicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .DownloadBulkInvoicesOverviewMobile {
    ${mixins.widgetMobile};
  }

  .DownloadBulkInvoicesOverview {
    ${mixins.widgetDesktop};

    p {
      margin-bottom: 22px;
    }
  }
`;
