import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import moment from 'moment';

import { useAuthState } from '../../../../providers/authProvider';
import { Icon } from '../../../../common/components/Icon/Icon';
import { ButtonPrimary } from '../../../../common/components';
import * as colors from '../../../../common/styles/colors';
import { downloadBrokerTpiReport } from '../../../../common/api/tpiApi';

type TpiDataSuccessModalProps = {
  show: boolean;
  onHide: () => void;
  onError: (type?: string) => void;
  messagevalues: Messages.IMessagesValues;
};

const defaultProps = {
  show: true,
};

const TpiDataSuccessModalWrapper = styled.div``;

export const TpiDataSuccessModal = (props: TpiDataSuccessModalProps) => {
  const authContext = useAuthState();

  const obj = props.messagevalues.json;
  const requestId = obj.RequestId;

  const title = 'Broker/TPI data success';
  const messageTop = 'Your Broker/TPI report is available to download.';

  const handleDownload = (e: any) => {
    e.preventDefault();

    const result = downloadBrokerTpiReport(process.env.REACT_APP_API_URL, authContext, requestId);
    result
      .then((result: any) => {
        props.onHide();
      })
      .catch((error: any) => {
        props.onError('brokerTpiReport');
      });
  };

  return (
    <TpiDataSuccessModalWrapper>
      <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
        <Modal.Title>
          <span className='pr-4'>
            <Icon color={colors.draxBtn} size={50} icon='download2' />
          </span>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
        <p>{messageTop}</p>
      </Modal.Body>
      <Modal.Footer className='p-0'>
        <div className='d-flex justify-content-end'>
          <ButtonPrimary className='m-0' title='Download' onClick={handleDownload} />
        </div>
      </Modal.Footer>
    </TpiDataSuccessModalWrapper>
  );
};

TpiDataSuccessModal.defaultProps = defaultProps;
