import styled from 'styled-components';
import * as variables from '../../common/styles/variables';
import * as mixins from '../../common/styles/mixins';
import { respondTo } from '../../common/styles/mixins';

export const MyMessagesActionsPanelWrapper = styled.ul`
  padding: 10px 0px 10px 20px;

  li {
    ${mixins.transition};
    cursor: pointer;
    font-size: ${variables.fontSizeMedium};
    margin-right: ${variables.spacingSmall};

    ${respondTo.tablet`
      margin-right: ${variables.spacingMedium};
    `};

    svg.primary {
      fill: ${props => props.theme.text.secondary};
    }

    &:hover {
      ${mixins.transition};
      color: ${props => props.theme.text.secondary};

      [class^='icon'] path {
        ${mixins.transition};
        fill: ${props => props.theme.text.secondary} !important;
      }
    }

    &:last-child {
      margin-right: 0;

      [class^='icon'] {
        margin-right: 0;

        ${respondTo.tablet`
          margin-right: ${variables.spacingSmall};
        `};
      }
    }
  }

  a {
    ${mixins.transition};
    cursor: pointer;
    margin-right: ${variables.spacingSmall};

    ${respondTo.tablet`
      margin-right: ${variables.spacingSmall};
    `};

    svg.primary {
      fill: ${props => props.theme.text.secondary};
    }
  }

  [class^='icon'] {
    margin-right: 10px;

    path {
      ${mixins.transition};
    }
  }

  .badge {
    position: absolute;
    top: -18px;
    right: 10px;
  }

  .badge-pill {
    padding: 0.4em 0.7em;
    border-radius: 20rem;
    background-color: white;
    color: ${props => props.theme.primary.border};
    border: 2px solid ${props => props.theme.primary.border};
  }
`;
