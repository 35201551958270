// Modules
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, ReactElement } from 'react';

// Components
import { ButtonPrimary, FilterRowWrapper } from '../../../common/components';

// Context
import { MovingPremisesProcessContext } from '../movingPremisesProcess/movingPremisesProcessContext';

// Styles
import {
  MovingPremisesWrapper,
  BlockWrapper,
  FinalReadWrapper,
  ReviewInfoRow,
} from './MovingPremisesNewAddress.styles';

// Utils
import { TagManager } from '../../utils/analytics/TagManager';

type MovingPremisesConfirmationProps = {
  stepsBar: ReactElement;
  pageTitle: ReactElement;
};

export const MovingPremisesConfirmation = ({ stepsBar, pageTitle }: MovingPremisesConfirmationProps) => {
  const history = useHistory();
  const { selectedSites, filledSitesData } = useContext<any>(MovingPremisesProcessContext);

  const navigateToSubmitReads = (event: any) => {
    event.preventDefault();
    history.push('/home/meter-reads');
  };

  useEffect(() => {
    // Navigate to 1st process step in case page reload
    if (!selectedSites?.length) {
      history.push('/home/moving-premises');
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSites]);
  useEffect(() => {
    TagManager.pushData({
      event: 'COT Confirmation',
    });
  }, []);

  return (
    <>
      <MovingPremisesWrapper>
        <FilterRowWrapper>{pageTitle}</FilterRowWrapper>

        {selectedSites?.length && (
          <div className='selectedAccountDisplay'>{`Acc - ${selectedSites[0]['customer-account-reference']}`}</div>
        )}

        <div className='pt-4'>{stepsBar}</div>

        <BlockWrapper>
          {selectedSites?.length && (
            <h4>{`Thanks for letting us know about your move relevant to account ${selectedSites[0]['customer-account-reference']}`}</h4>
          )}

          {filledSitesData?.sites?.map((item: any, index: number) => (
            <ReviewInfoRow key={item.siteId}>
              <p>
                Our Service team will check your supplied details and contact you within 5 working days if they have any
                queries.
              </p>
              <p>We'll aim to confirm your change of premises within 21 days.</p>
              <p>If you have any questions, contact us on 01473 632598.</p>
            </ReviewInfoRow>
          ))}
        </BlockWrapper>

        <FinalReadWrapper>
          <div>
            <h4>Submit a final meter reading</h4>
            <p className='m-0'>
              Supply a final reading for your old premises so we can make sure your bill's accurate.
            </p>
          </div>

          <p className='m-0'>
            <ButtonPrimary
              title={`Submit meter read`}
              className='col m-10 pt-20'
              onClick={(event: any) => navigateToSubmitReads(event)}
            />
          </p>
        </FinalReadWrapper>
      </MovingPremisesWrapper>
    </>
  );
};
