import { ButtonPrimary, ButtonTerinary } from '../../common/components';
import { DirectDebitWidget, DownloadActionsRow, DirectDebitHeader } from './DirectDebit.styles';

import directdebit from '../../assets/directdebit.png';

export const CorporateUsersDirectDebit = () => (
  <DirectDebitWidget className='my-4'>
    <DirectDebitHeader>
      <h4>Download Direct Debit forms</h4>
      <img src={directdebit} alt='Direct Debit' />
    </DirectDebitHeader>

    <p>
      Paying your energy bill doesn't have to be a hassle. Sign up for Direct Debit and you can ensure your bill is
      always paid on time without having to lift a finger.
    </p>

    <p>
      Please select and download the Direct Debit form for your energy type. Simply fill in the relevant details and
      send the completed form to your Account Manager. They'll handle everything else.
    </p>

    <DownloadActionsRow>
      <ButtonPrimary title='Gas Direct Debit form' route='/files/dd-gas-form.pdf' target='_blank' />
      <ButtonTerinary title='Electricity Direct Debit form' route='/files/dd-elec-form.pdf' target='_blank' />
    </DownloadActionsRow>
  </DirectDebitWidget>
);
