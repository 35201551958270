import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const Wrapper = styled.div`
  width: 100%;

  &.hidden {
    display: none;
  }

  .label {
    margin-bottom: ${variables.spacingXSmall};
  }
`;

type InputProps = {
  hasError: boolean;
};

export const Input = styled.input.attrs((props: InputProps) => ({
  hasError: props.hasError,
}))<InputProps>`
  ${mixins.inputBorderFocus}
  background: ${({ theme }) => theme.input.bg};
  border: 1px solid ${({ theme, hasError }) => (hasError ? colors.errorLight : theme.input.border)};
  border-radius: ${variables.inputRadius};
  color: ${({ theme }) => theme.input.color};
  -webkit-text-fill-color: ${({ theme }) => theme.input.color};
  opacity: 1;
  padding: 0.85em ${variables.spacingSmall};
  height: 54px;
  width: 100%;
  outline: none;
`;

export const Error = styled.div`
  color: ${colors.errorLight};
  font-size: ${variables.fontSizeMedium};
  margin-top: ${variables.spacingXSmall};
`;
