import { useContext, ReactElement } from 'react';
import ReactDOM from 'react-dom';

import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';
import { SideMenuFilterWrapper } from './SideMenuFilter.styles';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';

type SideMenuFilterProps = {
  className?: string;
  title?: string;
  children: ReactElement;
};

const defaultProps = {
  className: '',
  title: 'Filter',
};

export const SideMenuFilter = (props: SideMenuFilterProps) => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);

  const handleFilterClosed = (event: any) => {
    event.preventDefault();
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      mobileFilterActive: false,
    });
  };

  const content = (
    <SideMenuFilterWrapper className={`filter-menu-wrapper open slide-in`}>
      <div className='innerWrapper'>
        <h4>{props.title}</h4>
        {props.children}
        <div className='text-center'>
          <ButtonPrimary route='/' title='Apply' onClick={handleFilterClosed} />
        </div>
      </div>
    </SideMenuFilterWrapper>
  );

  if (!globalContext.globalDetail.mobileFilterActive) return null;

  return ReactDOM.createPortal(content, document.body);
};

SideMenuFilter.defaultProps = defaultProps;
