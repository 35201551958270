import styled from 'styled-components';

import * as colors from '../../../../common/styles/colors';
import * as variables from '../../../../common/styles/variables';
import * as mixins from '../../../../common/styles/mixins';
import { respondTo } from '../../../../common/styles/mixins';

export const ChooseAccountWrapper = styled.div`
  position: relative;
  min-height: 200px;

  ${respondTo.tablet`
    padding-left: 0px;
    padding-right: 0px;
  `};

  .primary {
    text-align: center;

    ${respondTo.tablet`
      text-align: left;
    `};
  }

  .list {
    ${mixins.textWrap};

    .heading {
      color: ${({ theme }) => theme.text.secondary};
    }

    tbody {
      border-top: none;

      .actions-wrapper {
        opacity: 0;

        a {
          pointer-events: none;
        }
      }

      tr {
        ${mixins.transitionFast};
        cursor: pointer;
        padding: ${variables.spacingXSmall} 0;

        ${respondTo.tablet`
          padding: 0;
        `};

        &.selected {
          background-color: ${colors.lightGrey};

          .actions-wrapper {
            opacity: 1;
            pointer-events: all;

            a {
              pointer-events: all;
            }
          }
        }

        .item {
          cursor: pointer;
        }
      }
    }

    .checkbox {
      label {
        display: inline;
      }

      span {
        bottom: 0;
      }
    }

    .energyTypeIcon {
      position: relative;
      top: -0.2em;
    }
  }
`;
