import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';
import { respondTo } from '../../common/styles/mixins';

export const AddUserWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .rowWrapper {
    margin-bottom: ${variables.spacingSmall};
  }

  .rowWrapper:last-child {
    margin-bottom: 0;
  }

  .addUserError {
    color: ${colors.errorLight};
    font-size: ${variables.fontSizeMedium};
    margin-top: ${variables.spacingXSmall};
  }
`;

export const ActionRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${variables.spacingMedium};

  ${respondTo.tablet`
    flex-direction: row;
  `};

  .submit-btn {
    width: 100%;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .icon-btn {
    margin-top: ${variables.spacingSmall};
    border: none;
    background: none;
    color: ${({ theme }) => theme.text.secondary};

    span {
      padding-right: ${variables.spacingXSmall};
    }
  }
`;
