import { get } from '../../helpers/axiosWrapper';

type BankOption = {
  id: number;
  description: string;
};

export const getBanksList = async (url: string | undefined, context: any): Promise<BankOption[]> => {
  return await get(`${url}/bank/banks`, {}, context);
};
