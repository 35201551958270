const convertNullValues = value => {
  // checks for null and undefined
  return value == null ? '' : value;
};

const trimLeadingAndTrailingSpaces = value => {
  if (value == null) {
    return '';
  }
  return value.trim();
};

const sortByKey = (array, property) => {
  // sort arrays by number or string
  return array.sort(function (a, b) {
    let x = a[property];
    let y = b[property];
    if (typeof x === 'string') {
      x = ('' + x).toLowerCase();
    }
    if (typeof y === 'string') {
      y = ('' + y).toLowerCase();
    }
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

const groupByKey = (array, property) => {
  // split arrays by a common property
  var obj = {};
  for (var i = 0; i < array.length; i++) {
    if (!obj[array[i][property]]) obj[array[i][property]] = [];
    obj[array[i][property]].push(array[i]);
  }
  return obj;
};

export { convertNullValues, sortByKey, groupByKey, trimLeadingAndTrailingSpaces };
